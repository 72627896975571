import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PageTitle, PatientsAppointmentsTable, PatientsAssignmentsTable } from '../../components'
import { getCurrentUserAllAppointment, getCurrentUserAllAssignment } from '../../state/actions/patient.actions'

const PatientAppointments = () => {

    const [activeLink, setActiveLink] = useState('Assignments')
    const dispatch = useDispatch()
    const { current_user_appointment, current_user_assignment } = useSelector(state => state.patient)
    const [showTables, setShowTables] = useState({
        assignments: true,
        appointments: true,
    })
    useEffect(() => {
        dispatch(getCurrentUserAllAppointment())
        dispatch(getCurrentUserAllAssignment())

    }, [])

    return (
        <div>
            <PageTitle type={'patient-appointments'} />

            <div>
                <div className="flex space-x-5 items-center border-b-2 border-gray-500 pb-2 w-1/2">
                    <button
                        type="button"
                        className={`${activeLink === 'Assignments' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => setActiveLink(e.target.textContent)}
                    >
                        Assignments
                    </button>
                    <button
                        type="button"
                        className={`${activeLink === 'Appointments' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => setActiveLink(e.target.textContent)}
                    >
                        Appointments
                    </button>
                </div>

                {activeLink === 'Assignments' && (
                    <PatientsAssignmentsTable
                        showList={showTables.assignments}
                        data = {current_user_assignment}
                    />
                )}

                {activeLink === 'Appointments' && (
                    <PatientsAppointmentsTable
                        showList={showTables.appointments}
                        data = {current_user_appointment}
                    />
                )}
            </div>
        </div>
    )
}

export default PatientAppointments