import { createAsyncThunk } from '@reduxjs/toolkit'

import {
    AddPatient, getAllPatients, getAllDoctors, assignPatientDoctor,
    BookPatientAppointment, AllBirthRecords, AllDeathRecords, UpdateBirthRecord,
    AddDeathRecord, AllWards, WardReport, getBirthRecord, getDeathRecordData, generateCard, getBillings,
    getABilling, generateInvoice, getInvoices, getAppointments, assignPatientToDoctor, getAssignedDoctors,
    getWardReports,
    fetchAllDoctorsAndOtherFunctionaries
} from "../routes/records.route"


export const createPatient = createAsyncThunk(
    'records/createPatient',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await AddPatient(formData, token)

            toast.success("Patient created successfully")
            setModals({ ...modals, showRecordsAddPatientModal: !modals.showRecordsAddPatientModal })

            return data
        } catch (error) {
            console.log(error)

            toast.warning("An error occurred while creating patient")
            return rejectWithValue(error.response.data)
        }
    }
)


export const getPatients = createAsyncThunk(
    'records/getPatients',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getAllPatients(token)

            return data
        } catch (error) {
            console.log(error.response)
            // toast.warning("Getting patients failed")
            return rejectWithValue(error.response.data)
        }
    }
)

export const getDoctors = createAsyncThunk(
    'records/getDoctors',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getAllDoctors(token)

            return data
        } catch (error) {
            console.log(error.response)
            // toast.warning("Getting patients failed")
            return rejectWithValue(error.response.data)
        }
    }
)


export const assignDoctorToPatient = createAsyncThunk(
    'records/assignDoctorToPatient',
    async ({ formData, toast, setModals, modals, dispatch, assignDoctor }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const { data } = await assignPatientDoctor(formData, token)

            toast.success("Doctor changed successfully")
            setModals({ ...modals, showAssignDoctorModal: !modals.showAssignDoctorModal })
            dispatch(assignDoctor({ data: null }))

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occurred on Assigning Doctor")
            return rejectWithValue(error.response.data)
        }
    }
)

export const bookPatientForAppointment = createAsyncThunk(
    'records/BookAppointment',
    async ({ formData, toast, setModals, modals, socket }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            // const { socket } = useStateContext()

            const _data = {
                notification_type: "Appointment notification",
                notification_title: `Appointment: on ${formData['date']} by ${formData['time']}`,
                receiver: formData['patient_pkid'],
                notification: "An appointment was booked on your behalf",
                sender: '',
                subject: 'Appointment',
                message: `You are booked for an appointment with Dr.${formData.doctors_name} on ${formData.date} by ${formData.time}`,
                id: formData['doc_id'],
                patient_id: formData['pat_id'],
                doctors_name: formData['doctors_name'],
                notification_recipient: formData['notification_recipient']
            }
            const { data } = await BookPatientAppointment(formData, token)
            socket.emit("book_patient_appointment", _data, () => { })

            toast.success("Appointment Booked")
            setModals({ ...modals, showBookAppointmentModal: !modals.showBookAppointmentModal })


            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("Doctor has reached maximum booking for today")
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllBirthRecords = createAsyncThunk(
    'records/getBirthRecords',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await AllBirthRecords(token)


            // toast.success("Patient created successfully")
            // setModals({ ...modals, showUpdateDirectorateModal: !modals.showUpdateDirectorateModal })
            console.log(data)


            return data
        } catch (error) {
            console.log(error.response)
            // toast.warning("Getting patients failed")
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllDeathRecords = createAsyncThunk(
    'records/getDeathRecords',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await AllDeathRecords(token)


            // toast.success("Patient created successfully")
            // setModals({ ...modals, showUpdateDirectorateModal: !modals.showUpdateDirectorateModal })



            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const AddBirthRecords = createAsyncThunk(
    'records/AddBirthRecords',
    async ({ formData, toast, modals, setModals, dispatch, removeSingleBirthRecord }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await UpdateBirthRecord(formData, token)

            toast.success("Record Added successfully")

            setModals({ ...modals, showAddBirthRecordModal: !modals.showAddBirthRecordModal })
            dispatch(removeSingleBirthRecord({ data: null }))

            return data
        } catch (error) {
            console.log(error.response)
            // toast.warning("Getting patients failed")
            return rejectWithValue(error.response.data)
        }
    }
)

export const AddDeathRecords = createAsyncThunk(
    'records/AddBirthRecords',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await AddDeathRecord(formData, token)

            toast.success("Record Added successfully")
            setModals({ ...modals, showAddDeathRecordModal: !modals.showAddDeathRecordModal })

            return data
        } catch (error) {
            console.log(error.response)
            if (error.response.status == 403) {
                toast.warning("Record Already Exist")
            }
            else {
                toast.warning("Adding death record failed")
            }


            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllWards = createAsyncThunk(
    'records/getAllWards',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await AllWards(token)

            return data
        } catch (error) {
            console.log(error.response)
            // toast.warning("Getting patients failed")
            return rejectWithValue(error.response.data)
        }
    }
)


export const WriteWardReport = createAsyncThunk(
    'records/writeReport',
    async ({ formData, toast, setModals, modals, dispatch, setWardReport }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await WardReport(formData, token)

            toast.success("Report Added successfully")
            setModals({ ...modals, showWardInfoModal: !modals.showWardInfoModal })
            dispatch(setWardReport({ data: null }))

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occured.")
            return rejectWithValue(error.response.data)
        }
    }
)

export const getABirthRecord = createAsyncThunk(
    'records/getABirthRecord',
    async ({ patient_num, toast }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getBirthRecord(patient_num, token)

            if (data.date_of_birth === null) {
                return data

            }
            else {
                toast.warning("Record already exist")
            }


        } catch (error) {
            console.log(error.response)
            toast.warning("No patient found")
            return rejectWithValue(error.response.data)
        }
    }
)

export const removeSingleBirthRecord = createAsyncThunk(
    'records/removeSingleBirthRecord',
    async ({ data }, { rejectWithValue }) => {
        try {

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const getADeathRecordData = createAsyncThunk(
    'records/getADeathRecordData',
    async ({ patient_num }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getDeathRecordData(patient_num, token)
            console.log(data);



            return data
        } catch (error) {
            console.log(error.response)
            // toast.warning("No patient found")
            return rejectWithValue(error.response.data)
        }
    }

)
export const removeSingleDeathRecord = createAsyncThunk(
    'records/removeSingleDeathRecord',
    async ({ data }, { rejectWithValue }) => {
        try {
            console.log("data", data);
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)


export const generatePatientCard = createAsyncThunk(
    'records/generateCard',
    async ({ patient_id, toast }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateCard(patient_id, token)
            console.log(data);
            toast.success("Card generated")
            // setModals({ ...modals, showUpdateDirectorateModal: !modals.showUpdateDirectorateModal })
            // console.log(data)


            return data
        } catch (error) {
            console.log(error.response)
            // toast.warning("Getting patients failed")
            return rejectWithValue(error.response.data)
        }
    }
)


export const getAllBillings = createAsyncThunk(
    'records/getAvailableBillings',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getBillings(token)

            return data
        } catch (error) {
            console.log(error.response)
            // toast.warning("Getting patients failed")
            return rejectWithValue(error.response.data)
        }
    }
)



export const getABillingDetails = createAsyncThunk(
    'records/getAbill',
    async ({ billing_id, toast }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getABilling(billing_id, token)



            return data
        } catch (error) {
            console.log(error.response)
            // toast.warning("Getting patients failed")
            return rejectWithValue(error.response.data)
        }
    }
)



export const removeSingleBillingDetails = createAsyncThunk(
    'records/removeSingleBillingDetail',
    async ({ data }, { rejectWithValue }) => {
        try {
            console.log("data", data);
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)

export const generatePatientInvoice = createAsyncThunk(
    'records/generateInvoice',
    async ({ formData, toast, setModals, modals, dispatch, removeSingleBillingDetails }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateInvoice(formData, token)
            // console.log(formData)
            toast.success("Invoice generated successfully")
            setModals({ ...modals, showGenerateInvoiceModal: !modals.showGenerateInvoiceModal })
            dispatch(removeSingleBillingDetails({ data: null }))

            return data
        } catch (error) {
            // console.log(error.response)
            toast.warning("An error occurred while generating invoice")
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllInvoice = createAsyncThunk(
    'records/getAllInvoice',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getInvoices(token)
            // console.log(formData)


            return data
        } catch (error) {
            // console.log(error.response)

            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllAppointments = createAsyncThunk(
    'records/getAllAppointment',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getAppointments(token)
            // console.log(formData)


            return data
        } catch (error) {
            // console.log(error.response)

            return rejectWithValue(error.response.data)
        }
    }
)

export const assignAPatientToDoctor = createAsyncThunk(
    'records/assignDoctorToPatient',
    async ({ formData, toast, setModals, modals, dispatch, assignDoctor, socket }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            // const { socket } = useStateContext()

            const _data = {
                notification_type: "Patient daily visit",
                notification_title: `Patient daily visit:  ${formData['date']} by ${formData['time']}`,
                receiver: formData['patient_pkid'],
                notification: "A patient was assigned to you",
                sender: '',
                subject: 'Patient daily visit',
                message: `You were assigned to see Dr.${formData.doctors_name} on ${formData.date} by ${formData.time}`,
                id: formData['doc_id'],
                patient_id: formData['pat_id'],
                notification_recipient: formData['notification_recipient'],
                doctors_name: formData['doctors_name']
            }

            const { data } = await assignPatientToDoctor(formData, token)
            socket.emit("assign_patient_to_doctor", _data, () => { })

            toast.success("Assigned successfully")
            setModals({ ...modals, showChangeDoctorModal: !modals.showChangeDoctorModal })
            dispatch(assignDoctor({ data: null }))

            return data
        } catch (error) {
            console.log(error.response, "responses")
            if (error.response.data.data === "double record exist") {
                toast.warning("This patient is already assigned to a doctor for today")
            }
            else {
                toast.warning("An error occurred while assigning doctor to a patient.")
            }
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllAssignedDoctors = createAsyncThunk(
    'records/getAssignedDoctor',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getAssignedDoctors(token)
            // console.log(formData)


            return data
        } catch (error) {
            // console.log(error.response)

            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllWardReports = createAsyncThunk(
    'records/getAllWardReports',
    async ({ id }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getWardReports(id, token)

            return data
        } catch (error) {
            console.log(error.response)
            // toast.warning("Getting patients failed")
            return rejectWithValue(error.response.data)
        }
    }
)

export const setBookAppointmentToNUll = createAsyncThunk(
    'records/setBookAppointmentToNUll',
    async (_, { rejectWithValue }) => {
        try {
            console.log("rannnnnnn");
            return null
        } catch (error) {

            return rejectWithValue(null)
        }
    }
)

export const getAllDoctorsAndOtherFunctionaries = createAsyncThunk(
    'records/getAllDoctorsAndOtherFunctionaries',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchAllDoctorsAndOtherFunctionaries(token)
            // console.log(formData)


            return data
        } catch (error) {
            // console.log(error.response)

            return rejectWithValue(error.response.data)
        }
    }
)