/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FaTimesCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { useStateContext } from "../../../state/context";
import { setBilling, setCurrentPrescriptionInvoice, setCurrentPrescriptions } from "../../../state/actions/actions";
import { createPrescriptionsInvoice, dispenseDrug, getDrugPrices, getPaidPrescriptions, resetPaidPrescriptions } from "../../../state/actions/pharmacist.actions";
import { payForAGeneratedInvoice, flutterwavePatientPayment } from "../../../state/actions/patient.actions";
import { createPayStackConfig } from "../../../state/actions/collection.actions";
import ButtonLoader from "../../utils/ButtonLoader";

const PatientPayInvoiceModal = () => {
    const dispatch = useDispatch();
    const { modals, setModals } = useStateContext()

    const { current_patient_profile } = useSelector((state) => state.patient)
    const { set_paystack_payment_details } = useSelector((state) => state.root)
    const { paystack_config, collectionLoading } = useSelector((state) => state.collection)

    const [formData, setFormData] = useState({
        email: '',
        amount: '',
        invoice_id: '', 
        type: '',
        payment_gateway: '',
        phone: ''
    })

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }
    const handleSubmit = (e) => {
        e.preventDefault()

        console.log(set_paystack_payment_details)
        if (formData.payment_gateway === 'Paystack') {
            dispatch(createPayStackConfig({ formData }))
            // dispatch(payForAGeneratedInvoice({ formData, toast, setModals, modals }))
        }
    }

    useEffect(() => {
        if (paystack_config?.authorization_url) {
            localStorage.setItem('payment__invoice', JSON.stringify({ ...set_paystack_payment_details, type: 'patient_payment' }))

            window.location.href = paystack_config?.authorization_url
        }
    }, [paystack_config])


    useEffect(() => {
        setFormData({
            ...formData,
            email: set_paystack_payment_details?.email,
            amount: set_paystack_payment_details?.amount,
            // amount: parseInt(set_paystack_payment_details?.amount) * 100,
            type: set_paystack_payment_details?.type,
            invoice_id: set_paystack_payment_details?.invoice_id,
            phone: current_patient_profile?.patient_phone
        })
    }, [set_paystack_payment_details])


    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className={`${modals.showPatientPayForInvoicesModal ? 'w-[600px]' : 'w-[1200px]'} bg-white px-[20px] py-[15px]`}>
                <div className="flex flex-col space-y-2">
                    <div className="flex justify-between items-center">
                        <h4 className={`${modals.showPatientPayForInvoicesModal ? 'text-sky-500' : 'text-dark-green'} text-[20px] font-bold capitalize`}>
                            Payment
                        </h4>
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                setModals({ ...modals, showPatientPayForInvoicesModal: !modals.showPatientPayForInvoicesModal })
                                window.localStorage.setItem("Invoice_details", null)
                            }}
                            size={20}
                        />
                    </div>
                    <select
                        name="payment_gateway"
                        onChange={(e) =>
                            handleChange(e, e.target.name)
                        }
                        className='border rounded mt-2 pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 '
                    >
                        <option value="">Select Gateway </option>
                        <option value="Paystack">PayStack</option>
                    </select>

                    <div className='mt-4 flex justify-center items-center'>
                        {!collectionLoading ? (
                            <button
                                type='submit'
                                className='inline-block w-[175px] px-6 py-2.5 bg-sky-500 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        ) : (
                            <button
                                className='inline-block w-[175px] px-6 py-2.5 bg-sky-500 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
                            >
                                <ButtonLoader />
                            </button>
                        )}
                    </div>

                </div>
            </div>
        </div>
    );
}

export default PatientPayInvoiceModal