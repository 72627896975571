import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BsEye } from 'react-icons/bs'
import { useStateContext } from '../../../state/context'
import { setFollowUpRecords } from '../../../state/actions/actions'

const PatientMedicalRecordDetailsTable = () => {
    const { all_patient_medical_record } = useSelector((state) => state.nurse)
    const { set_patient_id } = useSelector((state) => state.root)
    const { modals, setModals } = useStateContext()
    const dispatch = useDispatch()

    useEffect(() => {

    }, [all_patient_medical_record])

    return (
        <div>

            <div className='flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1'>

                <h4 className='text-dark-blue text-[16px] font-semibold'>
                    Medical History - {set_patient_id?.first_name} {set_patient_id?.last_name} [{set_patient_id?.patient_number}]
                </h4>
            </div>


            <table className='border rounded w-full cursor-default'>

                <thead className=''>
                    <tr>
                        <th
                            scope='col'
                            className='text-[10px] font-medium text-gray-900 pr-[2em] px-4 py-4 text-left'
                        >
                            S/N
                        </th>

                        <th
                            scope='col'
                            className='text-[10px] font-medium text-gray-900 pr-[2em] px-4 py-4 text-left'
                        >
                            Heart Disease
                        </th>

                        <th
                            scope='col'
                            className='text-[10px] font-medium text-gray-900 pr-[2em] px-4 py-4 text-left'
                        >
                            Hypertension
                        </th>
                        <th
                            scope='col'
                            className='text-[10px] font-medium text-gray-900 pr-[5em] py-4 text-left'
                        >
                            Kidney Disease
                        </th>
                        <th
                            scope='col'
                            className='text-[10px] font-medium text-gray-900 pr-[5em] py-4 text-left'
                        >
                            Tuberculosis
                        </th>
                        <th
                            scope='col'
                            className='text-[10px] font-medium text-gray-900 pr-[5em] py-4 text-left'
                        >
                            Liver Disease
                        </th>
                        <th
                            scope='col'
                            className='text-[10px] font-medium text-gray-900 pr-[5em] py-4 text-left'
                        >
                            Blood Transaction
                        </th>
                        <th
                            scope='col'
                            className='text-[10px] font-medium text-gray-900 py-4 text-left'
                        >
                            Date
                        </th>
                        <th
                            scope='col'
                            className='text-[10px] font-medium text-gray-900 pr-[5em] py-4 text-left'
                        >
                            Actions
                        </th>
                    </tr>
                </thead>

                <tbody>

                    {all_patient_medical_record?.map((item, index) => (
                        <tr
                            className='relative'
                            key={index}

                        >
                            <td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
                                {index + 1}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.heart_disease}         </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.hypertension}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.kidney_disease}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.tuberculosis}
                            </td>

                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.liver_disease}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.blood_transaction}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.date}
                            </td>
                            <td className=' text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                <div className='flex space-x-4 relative '>
                                    <BsEye
                                        color='blue'
                                        className='hover:cursor-pointer ml-4'

                                        size={35}
                                        onClick={() => {
                                            const data = {
                                                heart_disease: item?.heart_disease,
                                                hypertension: item?.hypertension,
                                                kidney_disease: item?.kidney_disease,
                                                tuberculosis: item?.tuberculosis,
                                                liver_disease: item?.liver_disease,
                                                blood_transaction: item?.blood_transaction,
                                                date: item?.date,
                                                others: item?.others
                                            }
                                            dispatch(setFollowUpRecords({ data }))
                                            setModals({ ...modals, showPatientMedicalHistoryModal: !modals.showPatientMedicalHistoryModal })

                                        }}


                                    />

                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>

            </table>
        </div>
    )
}

export default PatientMedicalRecordDetailsTable