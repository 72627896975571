import { Fragment, useState, useEffect } from 'react'
import { FaUserMd } from 'react-icons/fa'
import Pagination from '../../../components/utils/Pagination'
import { getAllAppointments, getAllAssignedDoctors } from '../../../state/actions/records.actions'
import { useSelector, useDispatch } from 'react-redux'
import { useStateContext } from '../../../state/context'


const AssignedDoctorsList = ({ showAssignedDoctorList, setShowAssignedDoctorList }) => {
    const { all_assigned_doctors } = useSelector(state => state.records)

    const [currentData, setCurrentData] = useState([])
    const { modals, setModals } = useStateContext()
    const dispatch = useDispatch()
    useEffect(() => {

        dispatch(getAllAppointments())
        dispatch(getAllAssignedDoctors())
    }, [modals.showBookAppointmentModal])


    return (
        <div className='space-y-5'>
            <div className='flex justify-between items-center w-full px-1'>
                <div className='flex flex-col w-full cursor-pointer'>
                    <div className='py-2'>
                        <div className='flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1'
                            onClick={() => setShowAssignedDoctorList(!showAssignedDoctorList)}
                        >
                            <h4 className='text-dark-blue text-[14px] font-bold'>
                                Designated Doctors
                            </h4>
                            <FaUserMd
                                className='text-dark-blue'
                                size={20}
                            />
                        </div>
                        {showAssignedDoctorList && (
                            <Fragment>
                                <table className='border rounded w-full cursor-default'>
                                    <thead className=''>
                                        <tr>
                                            <th
                                                scope='col'
                                                className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                                            >
                                                S/N
                                            </th>
                                            <th
                                                scope='col'
                                                className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                                            >
                                                Patient Name
                                            </th>
                                            <th
                                                scope='col'
                                                className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
                                            >
                                                Doctor
                                            </th>
                                            <th
                                                scope='col'
                                                className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
                                            >
                                                Date
                                            </th>
                                            <th
                                                scope='col'
                                                className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
                                            >
                                                Time
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentData?.map((appointment, index) => (
                                            <tr
                                                className='relative'
                                                key={`${appointment.id}`}
                                            >
                                                <td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
                                                    {index + 1}
                                                </td>
                                                <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                                    {appointment.patient.first_name} {appointment.patient.last_name}
                                                </td>
                                                <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                                    {appointment.patient_doctor.first_name} {appointment.patient_doctor.last_name}

                                                </td>
                                                <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                                    {appointment.date}
                                                </td>
                                                <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                                    {appointment.time}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <Pagination
                                    data={all_assigned_doctors}
                                    currentData={currentData}
                                    setCurrentData={setCurrentData}
                                />
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssignedDoctorsList