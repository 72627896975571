/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useStateContext } from '../../../state/context'
import { useSelector, useDispatch } from 'react-redux'
import { setcurrentBill } from '../../../state/actions/actions'
import { getABillingDetails, removeSingleBillingDetails, generatePatientInvoice, getAllBillings } from '../../../state/actions/records.actions'
import { toast } from 'react-toastify'
import ButtonLoader from '../../utils/ButtonLoader'



const RecordsGenerateInvoiceModal = (props) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { assign_doctor } = useSelector((state) => state.root)
    const { all_billings, single_billing_details, patientLoading } = useSelector((state) => state.records)

    const [currentBillPrice, setCurrentBillPrice] = useState('')
    const [formData, setFormData] = useState({ patient_id: '', billing_type: '', amount: '', id: '', status: false })

    useEffect(() => {
        dispatch(getAllBillings())
    }, [])

    useEffect(() => {
        setFormData({
            ...formData, patient_id: assign_doctor?.patient_id,
            billing_type: single_billing_details?.name,
            amount: single_billing_details?.price

        })
    }, [single_billing_details])

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        setFormData({
            ...formData, patient_id: assign_doctor?.patient_id,
            billing_type: single_billing_details?.name,
            amount: single_billing_details?.price
        })

        dispatch(generatePatientInvoice({ formData, toast, setModals, modals, dispatch, removeSingleBillingDetails }))
    }

    return (
        <div className="absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center">
            <div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className='text-dark-green text-[20px] font-bold'>Generate Invoice</h4>
                        <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                const data = null
                                dispatch(removeSingleBillingDetails({ data }))
                                setModals({ ...modals, showGenerateInvoiceModal: !modals.showGenerateInvoiceModal })
                            }}
                            size={20}
                        />
                    </div>
                    <div className='flex justify-between items-start'>
                        <img src={`${process.env.REACT_APP_API_ROOT}${assign_doctor?.photo}`} alt="" className='h-[100px] w-[100px] mx-auto rounded-full mr-16' />
                        <div className='flex flex-col w-[400px] mt-2'>
                            <div className='pl-2 py-2 mr-2 font-medium'><h3> Name: {`${assign_doctor?.first_name}
                                    ${assign_doctor?.last_name}`}</h3>
                                <h3 className='font-medium py-2'>Patient Number: {assign_doctor?.patient_no}</h3>
                            </div>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="w-full mb-6 md:mb-0">
                            <div className="relative w-full">
                                <select
                                    className="appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state"
                                    name='id'
                                    onChange={(e) => handleChange(e, e.target.name)}
                                    onClick={() => {
                                        setCurrentBillPrice()
                                        const billing_id = formData?.id

                                        dispatch(getABillingDetails({ billing_id }))
                                    }}
                                >
                                    <option value="###">Billing Type</option>
                                    {all_billings?.map((billings, index) => (
                                        <option value={billings.id} key={index}>{billings.name}</option>
                                    ))}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                </div>
                            </div>
                        </div>

                        <div>
                            <input
                                type="number"
                                name="amount"
                                placeholder="price"
                                value={single_billing_details?.price}
                                disabled
                                className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                onChange={(e) => handleChange(e, e.target.name)}
                            />
                        </div>

                        <div>
                            <textarea
                                cols="30"
                                name="description"
                                disabled
                                value={single_billing_details?.description}
                                placeholder='Description'
                                className="border rounded w-full px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue resize-none"
                                onChange={(e) => handleChange(e, e.target.name)}
                            >
                            </textarea>
                        </div>
                        <div className='mt-4'>
                            <button
                                type='submit'
                                className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >
                                {!patientLoading ? (
                                    'Generate'
                                ) : (
                                    <ButtonLoader />
                                )}
                            </button>
                        </div>
                    </form>
                </div>


            </div>
        </div>
    )
}

export default RecordsGenerateInvoiceModal