/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'
import { FaBaby } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

import { PageTitle } from '../../components'
import { useStateContext } from '../../state/context'
import { getAllProfiles } from '../../state/actions/nurse.actions'
import { setShowBirthRecord, setShowDeathRecord, } from '../../state/actions/actions'
import { getAllBirthRecords, getAllDeathRecords, } from '../../state/actions/records.actions'
import { BsPersonBoundingBox } from 'react-icons/bs'
import Pagination from '../../components/utils/Pagination'


const RecordsRecords = (props) => {
	const dispatch = useDispatch()

	const { modals, setModals } = useStateContext()

	const { birth_records, death_records } = useSelector((state) => state.records)

	const [birthRecords, setBirthRecords] = useState([])
	const [deathRecords, setDeathRecords] = useState([])
	const [showBirthRecords, setShowBirthRecords] = useState(true)
	const [showDeathRecords, setShowDeathRecords] = useState(false)

	useEffect(() => {
		dispatch(getAllBirthRecords())
		dispatch(getAllDeathRecords())
		dispatch(getAllProfiles())
	}, [modals.showAddBirthRecordModal, modals.showAddDeathRecordModal])

	return (
		<div>
			<PageTitle
				type={'records-records'}
				showAddBirthRecordModal={props.showAddBirthRecordModal}
				setShowAddBirthRecordModal={props.setShowAddBirthRecordModal}
				showAddDeathRecordModal={props.showAddDeathRecordModal}
				setShowAddDeathRecordModal={props.setShowAddDeathRecordModal}
			/>

			<div className='space-y-5'>
				<div className='flex justify-between items-center w-full px-1'>
					<div className='flex flex-col w-full cursor-pointer'>
						<div className='py-2'>
							<div className='flex justify-between items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1'>
								<div
									className='flex justify-start items-center space-x-4'
									onClick={() =>
										setShowBirthRecords(!showBirthRecords)
									}
								>
									<h4 className='text-dark-blue text-[14px] font-bold'>
										Birth Records
									</h4>
									<FaBaby
										className='text-dark-blue'
										size={20}
									/>
								</div>
							</div>
							{showBirthRecords && (
								<Fragment>
									<table className='border rounded w-full cursor-default'>
										<thead className=''>
											<tr>
												<th
													scope='col'
													className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
												>
													S/N
												</th>
												<th
													scope='col'
													className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
												>
													Full name
												</th>
												<th
													scope='col'
													className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
												>
													Gurdian
												</th>
												<th
													scope='col'
													className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
												>
													Gender
												</th>
												<th
													scope='col'
													className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
												>
													Phone
												</th>
												<th
													scope='col'
													className='text-sm font-medium text-gray-900 py-4 text-left'
												>
													Action
												</th>
											</tr>
										</thead>
										<tbody>
											{birthRecords?.map((user, index) => (
												<tr className='' key={index}>
													<td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
														{index + 1}
													</td>
													<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
														{user.first_name}{' '}
														{user.last_name}
													</td>
													<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
														{user.guardian_name}
													</td>
													<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
														{user.gender}
													</td>
													<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
														{user.phone_number}
													</td>
													<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
														<button
															type='button'
															className='inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease'
															onClick={() => {
																setModals({ ...modals, showBirthRecordModal: !modals.showBirthRecordModal })

																const data = {
																	patient_number: user.patient_number,
																	first_name: user.first_name,
																	last_name: user.last_name,
																	gender: user.gender,
																	time: user.time,
																	date: user.date_of_birth,
																	address: user.address,
																	guardian: user.guardian_name,
																	phone_number: user.phone_number,
																}
																dispatch(setShowBirthRecord({ data }))
															}}
														>
															View
														</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>

									<Pagination
										data={birth_records}
										currentData={birthRecords}
										setCurrentData={setBirthRecords}
									/>
								</Fragment>
							)}
						</div>
					</div>
				</div>

				<div className='flex justify-between items-center w-full px-1'>
					<div className='flex flex-col w-full cursor-pointer'>
						<div className='py-2'>
							<div className='flex justify-between items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1'>
								<div
									className='flex justify-start items-center space-x-4'
									onClick={() =>
										setShowDeathRecords(!showDeathRecords)
									}
								>
									<h4 className='text-dark-blue text-[14px] font-bold'>
										Death Records
									</h4>
									<BsPersonBoundingBox
										className='text-dark-blue'
										size={20}
									/>
								</div>

							</div>
							{showDeathRecords && (
								<Fragment>
									<table className='border rounded w-full cursor-default'>
										<thead className=''>
											<tr>
												<th
													scope='col'
													className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
												>
													S/N
												</th>
												<th
													scope='col'
													className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
												>
													Full name
												</th>
												<th
													scope='col'
													className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
												>
													Gurdian
												</th>
												<th
													scope='col'
													className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
												>
													Gender
												</th>
												<th
													scope='col'
													className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
												>
													Phone
												</th>
												<th
													scope='col'
													className='text-sm font-medium text-gray-900 py-4 text-left'
												>
													Action
												</th>
											</tr>
										</thead>
										<tbody>
											{deathRecords?.map((user, index) => (
												<tr className='' key={index}>
													<td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
														{index + 1}
													</td>
													<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
														{user.first_name}{' '}
														{user.last_name}
													</td>
													<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
														{user.guardian_name}
													</td>
													<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
														{user.gender}
													</td>
													<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
														{user.phone_number}
													</td>
													<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
														<button
															type='button'
															className='inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease'
															onClick={() => {
																setModals({ ...modals, showDeathRecordModal: !modals.showDeathRecordModal })

																const data = {
																	patient_number: user.patient_number,
																	first_name: user.first_name,
																	last_name: user.last_name,
																	gender: user.gender,
																	time: user.time_of_death,
																	date: user.date_of_death,
																	address: user.address,
																	guardian: user.guardian_name,
																	phone_number: user.phone_number,
																	cause_of_death: user.cause_of_death,
																}
																dispatch(setShowDeathRecord({ data }))
															}}
														>
															View
														</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>

									<Pagination
										data={death_records}
										currentData={deathRecords}
										setCurrentData={setDeathRecords}
									/>
								</Fragment>
							)}
						</div>
					</div>
				</div>
			</div>
		</div >
	)
}

export default RecordsRecords
