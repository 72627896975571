import React, { useState, useEffect } from "react";
import { FaUser } from "react-icons/fa";
import { HiMail } from "react-icons/hi"
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { PageTitle, UserPosition } from "../../components";
import { setEmailReceiver } from "../../state/actions/actions";
import { sendAnEmailMessage } from "../../state/actions/admin.actions";
import { useStateContext } from "../../state/context";
import { getCurrentPatientProfile } from "../../state/actions/patient.actions";


const PatientMessages = (props) => {
    const dispatch = useDispatch();

    const { modals, setModals } = useStateContext()

    const [showUsers, setShowUsers] = useState(true);
    const [showSent, setShowSent] = useState(false);
    const [showReceived, setShowReceived] = useState(false);

    const { employees, all_sent_emails, all_received_emails, } = useSelector((state) => state.admin);
    const { current_patient_profile } = useSelector((state) => state.patient);
    const filtered_doctor = employees?.filter(item => {
        return item.user_id === current_patient_profile?.patient_doctor?.id
    })
    useEffect(() => {
        dispatch(getCurrentPatientProfile())
    }, [])

    return (
        <div>
            <PageTitle type={"emails"} />

            <div className="space-y-5">
                <div className="flex justify-between items-center w-full px-1">
                    <div className="flex flex-col w-full cursor-pointer">
                        <div className="py-2">
                            <div className="flex justify-between items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1">
                                <div
                                    className="flex justify-start items-center space-x-4"
                                    onClick={() => setShowUsers(!showUsers)}
                                >
                                    <h4 className="text-dark-blue text-[14px] font-bold">
                                        Personal Doctor
                                    </h4>
                                    <FaUser className="text-dark-blue" size={20} />
                                </div>

                                <div className="">
                                    <input
                                        type="text"
                                        className="border rounded text-[12px] px-4 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                    />
                                </div>
                            </div>
                            {showUsers && (
                                <table className="border rounded w-full cursor-default">
                                    <thead className="">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left"
                                            >
                                                S/N
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                            >
                                                Full name
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                            >
                                                Position
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                            >
                                                Gender
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                            >
                                                Mail
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 py-4 text-left"
                                            >
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filtered_doctor?.map((user, index) => (
                                            <tr className="" key={user.id}>
                                                <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    {index + 1}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                    {user.first_name} {user.last_name}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                    <UserPosition user={user} />
                                                </td>
                                                <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                    {user.gender}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                    {user.email}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                    <button
                                                        type="button"
                                                        className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                                        onClick={() => {
                                                            const data = {
                                                                first_name: user.first_name,
                                                                last_name: user.last_name,
                                                                id: user.user_id,
                                                                phone: user.patient_phone,
                                                                email: user.email,
                                                                user_pkid: user.pkid,
                                                            }
                                                            dispatch(setEmailReceiver({ data }))

                                                            setModals({ ...modals, showSendMailModal: !modals?.showSendMailModal });
                                                        }}
                                                    >
                                                        Send Mail
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex justify-between items-center w-full px-1">
                    <div className="flex flex-col w-full cursor-pointer">
                        <div className="py-2">
                            <div
                                className="flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1"
                                onClick={() => setShowSent(!showSent)}
                            >
                                <h4 className="text-dark-blue text-[14px] font-bold">Sent</h4>
                                <HiMail className="text-dark-blue" size={20} />
                            </div>
                            {showSent && (
                                <table className="border rounded w-full cursor-default">
                                    <thead className="">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left"
                                            >
                                                S/N
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                            >
                                                Full name
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                            >
                                                Email
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                            >
                                                Message
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {all_sent_emails?.map((mails, index) => (
                                            <tr className="" key={all_sent_emails.pkid}>
                                                <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    {index + 1}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                    {mails.receiver?.first_name}{" "}
                                                    {mails.receiver?.last_name}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                    {mails.receiver?.email}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                    {mails.message}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex justify-between items-center w-full px-1">
                    <div className="flex flex-col w-full cursor-pointer">
                        <div className="py-2">
                            <div
                                className="flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1"
                                onClick={() => setShowReceived(!showReceived)}
                            >
                                <h4 className="text-dark-blue text-[14px] font-bold">
                                    Received
                                </h4>
                                <HiMail className="text-dark-blue" size={20} />
                            </div>
                            {showReceived && (
                                <table className="border rounded w-full cursor-default">
                                    <thead className="">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left"
                                            >
                                                S/N
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                            >
                                                Full name
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                            >
                                                Email
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                            >
                                                Message
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {all_received_emails?.map((mails, index) => (
                                            <tr className="" key={all_received_emails.pkid}>
                                                <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    {index + 1}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                    {mails.sender?.first_name} {mails.sender?.last_name}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                    {mails.sender?.email}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                    {mails.message}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PatientMessages;
