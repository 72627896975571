import React, { useState } from 'react'
import RadiologyPagination from '../RadiologyPagination'

function RadiologyEmployeesTable({ table_title, data }) {
    const [currentPage, setCurrentPage] = useState(1)
    const [postPerPage, setPostPerPage] = useState(10)
    //Pagination
    const indexOfLastPage = currentPage * postPerPage
    const indexOfFirstPage = indexOfLastPage - postPerPage
    const currentPages = data?.slice(indexOfFirstPage, indexOfLastPage)
    //ChangePage
    const paginate = pageNumber => setCurrentPage(pageNumber)
    return (
        <div className="flex flex-col w-[450px]">
            <div className="py-2">
                <div className="flex justify-start items-center space-x-4 border-2 border-gray-200 rounded-t-lg p-1 w-full">
                    <h4 className='text-dark-blue text-[18px] font-bold'>{table_title}</h4>
                    {/* <GrTest
                        className='text-dark-blue'
                    /> */}
                </div>
                <table className="w-full border-l-2 border-r-2 border-b-2 rounded-b-md">
                    <thead className="border-1">
                        <tr>
                            <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-4 text-left">
                                Staff ID
                            </th>
                            <th scope="col" className="text-sm font-medium text-gray-900 mx-4 py-4 text-left">
                                Fullname
                            </th>
                            {/* <th scope="col" className="text-sm font-medium text-gray-900 px-4 py-4 text-left">
                                Phone
                            </th> */}
                            <th scope="col" className="text-sm font-medium text-gray-900 px-4 py-4 text-left">
                                Gender
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentPages?.map((employee, index) => (
                            <tr
                                key={employee.id}
                                className=""
                            >
                                <td className="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {employee.nhis_number}
                                </td>
                                <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                    {employee.user_details.first_name}  {employee.user_details.last_name}
                                </td>
                                {/* <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                    {employee.patient_phone}
                                </td> */}
                                <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                    {employee.gender}
                                </td>
                            </tr>
                        ))}
                        <RadiologyPagination postPerPage={postPerPage} totalPost={data?.length} paginate={paginate} />
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default RadiologyEmployeesTable