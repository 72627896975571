/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FaTimesCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { useStateContext } from "../../../state/context";
import { setCurrentPrescriptionInvoice, setCurrentPrescriptions } from "../../../state/actions/actions";
import { multipleDispenseDrug, getMultiplePaidPrescriptions, setCurrentMultipleInvoices } from "../../../state/actions/pharmacist.actions";


const PharmacistMultipleDrugDispenseModal = () => {
    const dispatch = useDispatch();

    const { modals, setModals } = useStateContext()

    const { user } = useSelector(state => state.auth)
    const { prescription_invoice_update, supplypoint_update } = useSelector(state => state.root)
    const { multiple_invoices_dispense, multiple_paid_prescriptions, paid_prescriptions, supplypoint_inventory } = useSelector(state => state.pharmacist)

    useEffect(() => {
        if (multiple_invoices_dispense?.length > 0) {
            dispatch(getMultiplePaidPrescriptions({ formData: { invoices: multiple_invoices_dispense } }))
        }
    }, [multiple_invoices_dispense])

    const handleDispense = (e) => {
        e.preventDefault()

        // console.log(params)
        const formData = {
            items: multiple_paid_prescriptions,
            supplypoint: supplypoint_update ? supplypoint_update?.pkid : user?.data?.assigned_supplypoint_details?.pkid,
        }
        dispatch(multipleDispenseDrug({ formData, toast, modals, setModals, dispatch, setCurrentMultipleInvoices }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className={`w-[800px] bg-white px-[20px] py-[15px]`}>
                <div className="flex flex-col space-y-2 w-full">
                    <div className="flex justify-between items-center">
                        <h4 className='text-teal-600 text-[18px] font-medium capitalize'>
                            Multiple Dispense
                        </h4>
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                setModals({ ...modals, showPharmacistDispenseMultipleInvoiceModal: !modals.showPharmacistDispenseMultipleInvoiceModal })
                            }}
                            size={20}
                        />
                    </div>

                    {/* user info */}

                    <div className="flex items-center space-x-5">
                        <img
                            src={`${process.env.REACT_APP_API_ROOT}${multiple_invoices_dispense ? multiple_invoices_dispense[0]?.invoice_patient?.profile_photo : ''}`}
                            alt=""
                            className="w-[80px] h-[80px] rounded-[50%]"
                        />

                        <div className="flex flex-col space-y-1">
                            <p className="text-[14px] text-gray-600 font-bold">{multiple_invoices_dispense ? multiple_invoices_dispense[0]?.invoice_patient?.last_name : ''} {multiple_invoices_dispense ? multiple_invoices_dispense[0]?.invoice_patient?.first_name : ''}</p>
                            <p className="text-[12px] text-gray-300 font-medium">{multiple_invoices_dispense ? multiple_invoices_dispense[0]?.invoice_patient_id : ''}</p>
                        </div>
                    </div>
                    <div className="max-h-[400px] overflow-y-auto patient__prescriptions">
                        <table className="rounded w-full cursor-default h-full">
                            <thead className="border-b-2 border-b-white">

                                <tr>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pl-2 pr-[2px] py-4 text-left bg-yellow-200"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pl-2 pr-[2px] py-4 text-left bg-yellow-200"
                                    >
                                        Route
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2px] py-4 text-left bg-yellow-200"
                                    >
                                        Drug
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2px] py-4 text-left bg-yellow-200"
                                    >
                                        Units
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2px] py-4 text-left bg-yellow-200"
                                    >
                                        Frequency
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2px] py-4 text-left bg-yellow-200"
                                    >
                                        Duration/Times
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left bg-sky-200"
                                    >
                                        Quantity
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-white py-4 text-left bg-sky-600"
                                    >
                                        Type
                                    </th>
                                    {/* <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 py-4 text-left bg-sky-200"
                                >

                                </th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {multiple_paid_prescriptions?.map((item, index) => (
                                    <tr
                                        key={`${item.id}`}
                                    >
                                        <td className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">
                                            {index + 1}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light pl-2 py-2 whitespace-nowrap">
                                            {item.routes}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.drugs}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.units}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.frequency}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap bg-yellow-200">
                                            {item.duration}/{item.times}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light pl-4 py-2 whitespace-nowrap bg-sky-200">
                                            {item.drug_quantity}
                                        </td>
                                        <td className="text-sm text-white font-bold py-2 whitespace-nowrap bg-sky-600">
                                            {item.dispense_type.toUpperCase()}
                                        </td>
                                        {/* <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap flex justify-end bg-sky-200">
                                        {supplypoint_inventory?.filter((inventory, index) => inventory.supplypoint_druginventory.bincard_drug.name === item.drugs)[0] && (
                                            <button
                                                type="submit"
                                                onClick={(e) => {
                                                    const params = {
                                                        quantity: item.drug_quantity,
                                                        drug: supplypoint_inventory?.filter((inventory, index) => inventory.supplypoint_druginventory.bincard_drug.name === item.drugs)[0]?.supplypoint_druginventory.bincard_drug.pkid,
                                                        supplypoint: supplypoint_update ? supplypoint_update?.pkid : user?.data?.assigned_supplypoint_details?.pkid,
                                                        prescription_id: item.pkid,
                                                    }
                                                    handleDispense(e, params)
                                                }}
                                                disabled={item.is_dispensed}
                                                className={`inline-block w-[100px] px-6 py-2.5 ${item.is_dispensed ? 'bg-teal-600' : 'bg-sky-600'} text-white font-medium text-xs leading-tight shadow-md hover:scale-[.98] transition duration-500 ease`}
                                            >
                                                {item.is_dispensed ? 'Dispensed' : 'Dispense'}
                                            </button>
                                        )}
                                    </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="flex justify-end mt-10">
                        <button
                            type="submit"
                            onClick={handleDispense}
                            className={`inline-block w-full px-6 py-2.5 bg-teal-600 text-white font-medium text-xs leading-tight shadow-md hover:scale-[.98] transition duration-500 ease`}
                        >
                            Dispense
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PharmacistMultipleDrugDispenseModal

