/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { FaEye, FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import { useStateContext } from "../../../state/context"
import { createGatewaySetting, updateGatewaySetting } from "../../../state/actions/admin.actions"


const AddGatewaySettingModal = () => {
	const dispatch = useDispatch()

	const { modals, setModals } = useStateContext()
	const { current_admin_data } = useSelector(state => state.admin)

	const [config, setConfig] = useState({ showSecretKey: false, showPrivateKey: false })
	const [formData, setFormData] = useState({ title: "", secret_key: "", })

	useEffect(() => {
		setFormData({
			...formData,
			title: current_admin_data?.title,
			secret_key: current_admin_data?.secret_key,
		})
	}, [current_admin_data])

	const handleChange = (e, name) => {
		setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		console.log(formData)
		dispatch(createGatewaySetting({ formData, toast, modals, setModals }))
	}

	const handleUpdate = (e) => {
		e.preventDefault()

		console.log(formData)
		dispatch(updateGatewaySetting({ formData: { ...formData, id: current_admin_data?.id }, toast, setModals: setModals, modals: modals }))
	}

	return (
		<div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
			<div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
				<div className="flex flex-col space-y-5">
					<div className="flex justify-between items-center">
						<h4 className={`${modals.showAdminAddGatewaySettingModal ? 'text-dark-green' : 'text-sky-600'} text-[16px] font-medium capitalize`}>
							{modals.showAdminAddGatewaySettingModal ? 'Add Gateway Setting' : 'Update Gateway Setting'}
						</h4>
						<FaTimesCircle
							size={20}
							className="text-dark-yellow cursor-pointer"
							onClick={() => {
								modals.showAdminAddGatewaySettingModal && setModals({ ...modals, showAdminAddGatewaySettingModal: !modals.showAdminAddGatewaySettingModal })
								modals.showAdminUpdateGatewaySettingModal && setModals({ ...modals, showAdminUpdateGatewaySettingModal: !modals.showAdminUpdateGatewaySettingModal })
							}}
						/>
					</div>

					{modals.showAdminAddGatewaySettingModal && (
						<form onSubmit={handleSubmit}>
							<div className="relative">
								<select
									className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none "
									name="title"
									onChange={(e) => {
										handleChange(e, e.target.name)
									}}
								>
									<option >Item Type</option>
									{['Paystack','SquadCo']?.map((item, index) => (
										<option
											key={`${item}-${index}`}
											value={item}
										>
											{item}
										</option>
									))}
								</select>
								<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
									<svg
										className="fill-current h-4 w-4"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
									>
										<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
									</svg>
								</div>
							</div>

							{formData.title !== 'SMS' && (
								<Fragment>
									<div className="relative">
										<FaEye
											className={`absolute right-4 top-5 cursor-pointer ${config.showSecretKey ? 'text-teal-600' : 'text-slate-600'}`}
											onClick={() => setConfig({ ...config, showSecretKey: !config.showSecretKey })}
										/>
										<input
											type={`${config.showSecretKey ? 'text' : 'password'}`}
											name="secret_key"
											placeholder="Secret Key"
											className="border rounded w-full h-5 px-4 pr-10 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
											onChange={(e) => handleChange(e, e.target.name)}
										/>
									</div>

								</Fragment>
							)}

							<div className="mt-4">
								<button
									type="submit"
									className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
								>
									Submit
								</button>
							</div>
						</form>
					)}

					{modals.showAdminUpdateGatewaySettingModal && (
						<form onSubmit={handleUpdate}>
							<div className="relative">
								<select
									className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none "
									name="title"
									onChange={(e) => {
										handleChange(e, e.target.name)
									}}
								>
									<option >{formData.title}</option>
									{['Paystack',]?.map((item, index) => (
										<option
											key={`${item}-${index}`}
											value={item}
										>
											{item}
										</option>
									))}
								</select>
								<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
									<svg
										className="fill-current h-4 w-4"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
									>
										<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
									</svg>
								</div>
							</div>

							<div className="relative">
								<FaEye
									className={`absolute right-4 top-5 cursor-pointer ${config.showSecretKey ? 'text-teal-600' : 'text-slate-600'}`}
									onClick={() => setConfig({ ...config, showSecretKey: !config.showSecretKey })}
								/>
								<input
									type={`${config.showSecretKey ? 'text' : 'password'}`}
									name="secret_key"
									placeholder="Secret Key"
									className="border rounded w-full h-5 px-4 pr-10 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
									value={formData.secret_key}
									onChange={(e) => handleChange(e, e.target.name)}
								/>
							</div>

							<div className="mt-4">
								<button
									type="submit"
									className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
								>
									Update
								</button>
							</div>
						</form>
					)}
				</div>
			</div>
		</div>
	)
}

export default AddGatewaySettingModal
