import { API } from "../config"


export const fetchAllInvoices = (token) => API.get('/accountant/all_invoices/', { headers: { Authorization: `Bearer ${token}` } })


export const fetchLaboratoryInvoices = (token) => API.get('/accountant/laboratory_invoices/', { headers: { Authorization: `Bearer ${token}` } })


export const fetchAllDailyReports = (params, token) => API.get(`/accountant/all_daily_reports/${params.start_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchAllMonthlyReports = (params, token) => API.get(`/accountant/all_monthly_reports/${params.start_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchAllYearlyReports = (params, token) => API.get(`/accountant/all_yearly_reports/${params.start_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchAllReportsRange = (params, token) => API.get(`/accountant/all_reports_range/${params.start_date}/${params.end_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchAllReportsAuthor = (params, token) => API.get(`/accountant/all_reports_author/${params.author}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchAllPaidReports = (token) => API.get(`/accountant/all_paid_reports/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchAllUnpaidReports = (token) => API.get(`/accountant/all_unpaid_reports/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchLaboratoryDailyReports = (params, token) => API.get(`/accountant/laboratory_daily_reports/${params.start_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchLaboratoryMonthlyReports = (params, token) => API.get(`/accountant/laboratory_monthly_reports/${params.start_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchLaboratoryYearlyReports = (params, token) => API.get(`/accountant/laboratory_yearly_reports/${params.start_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchLaboratoryReportsRange = (params, token) => API.get(`/accountant/laboratory_reports_range/${params.start_date}/${params.end_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchLaboratoryReportsAuthor = (params, token) => API.get(`/accountant/laboratory_reports_author/${params.author}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchPharmacyInvoices = (token) => API.get('/accountant/pharmacy_invoices/', { headers: { Authorization: `Bearer ${token}` } })


export const fetchPharmacyDailyReports = (params, token) => API.get(`/accountant/pharmacy_daily_reports/${params.start_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchPharmacyMonthlyReports = (params, token) => API.get(`/accountant/pharmacy_monthly_reports/${params.start_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchPharmacyYearlyReports = (params, token) => API.get(`/accountant/pharmacy_yearly_reports/${params.start_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchPharmacyReportsRange = (params, token) => API.get(`/accountant/pharmacy_reports_range/${params.start_date}/${params.end_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchPharmacyReportsAuthor = (params, token) => API.get(`/accountant/pharmacy_reports_author/${params.author}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchRadiologyInvoices = (token) => API.get('/accountant/radiology_invoices/', { headers: { Authorization: `Bearer ${token}` } })


export const fetchRadiologyDailyReports = (params, token) => API.get(`/accountant/radiology_daily_reports/${params.start_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchRadiologyMonthlyReports = (params, token) => API.get(`/accountant/radiology_monthly_reports/${params.start_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchRadiologyYearlyReports = (params, token) => API.get(`/accountant/radiology_yearly_reports/${params.start_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchRadiologyReportsRange = (params, token) => API.get(`/accountant/radiology_reports_range/${params.start_date}/${params.end_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchRadiologyReportsAuthor = (params, token) => API.get(`/accountant/radiology_reports_author/${params.author}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchRecordsInvoices = (token) => API.get('/accountant/records_invoices/', { headers: { Authorization: `Bearer ${token}` } })


export const fetchRecordsDailyReports = (params, token) => API.get(`/accountant/records_daily_reports/${params.start_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchRecordsMonthlyReports = (params, token) => API.get(`/accountant/records_monthly_reports/${params.start_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchRecordsYearlyReports = (params, token) => API.get(`/accountant/records_yearly_reports/${params.start_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchRecordsReportsRange = (params, token) => API.get(`/accountant/records_reports_range/${params.start_date}/${params.end_date}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchRecordsReportsAuthor = (params, token) => API.get(`/accountant/records_reports_author/${params.author}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchPharmacyNHISDiscounts = (token) => API.get(`/accountant/nhis_discounts_pharmacy/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchNHISDiscounts = (token) => API.get(`/accountant/nhis_discounts/`, { headers: { Authorization: `Bearer ${token}` } })


export const patchNHISDiscounts = (formData, token) => API.patch(`/accountant/remit_nhis_discounts/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchRecordsNHISDiscounts = (token) => API.get(`/accountant/nhis_discounts_records/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchLaboratoryNHISDiscounts = (token) => API.get(`/accountant/nhis_discounts_laboratory/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchRadiologyNHISDiscounts = (token) => API.get(`/accountant/nhis_discounts_radiology/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchRecordsUnPaidReports = (token) => API.get(`/accountant/records_unpaid_report_viewset/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchLabUnPaidReports = (token) => API.get(`/accountant/lab_unpaid_report_viewset/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchPharmacyUnPaidReports = (token) => API.get(`/accountant/pharm_unpaid_report_viewset/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchRadiologyUnPaidReports = (token) => API.get(`/accountant/rad_unpaid_report_viewset/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchRadiologyPaidReports = (token) => API.get(`/accountant/rad_paid_report_viewset/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchPharmacyPaidReports = (token) => API.get(`/accountant/pharm_paid_report_viewset/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchLabPaidReports = (token) => API.get(`/accountant/lab_paid_report_viewset/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchRecordsPaidReports = (token) => API.get(`/accountant/records_paid_report_viewset/`, { headers: { Authorization: `Bearer ${token}` } })
