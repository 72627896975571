/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { PageTitle } from '../../components'
import { BsPersonPlus } from 'react-icons/bs'
import FamilyPlanningTable from '../../components/tables/nursesTable/FamilyPlanningTable'
import { useStateContext } from '../../state/context'
import { useEffect } from 'react'
import FollowUpRecordsTable from '../../components/tables/nursesTable/FollowUpRecordsTable'
import { useDispatch, useSelector } from 'react-redux'
import { getAllPatients, getAllFamilyPlanningBookings, deleteFamilyPlanningFollowUpRecord } from '../../state/actions/nurse.actions'
import { setPatientID } from '../../state/actions/actions'

const NurseFamilyPlanning = () => {
	const dispatch = useDispatch()

	const { opdState, setOpdState, modals, setModals } = useStateContext()



	useEffect(() => {
		return () => {
			setOpdState({ ...opdState, showFamilyPlanningFollowUpRecords: false })
		}
	}, [])

	useEffect(() => {
		dispatch(getAllPatients())
		dispatch(getAllFamilyPlanningBookings())
	}, [modals.showBookPatientForFamilyPlanningModal])


	return (
		<div className='flex flex-col w-full cursor-pointer'>

			<PageTitle type={"nurse_family_planning"} />
			<div></div>
			{!opdState.showFamilyPlanningFollowUpRecords && (
				<div className='py-2'>
					<div className='flex justify-start items-center space-x-4 bg-gray-200 p-1'>
						<h4 className='text-dark-blue text-[14px] font-bold'>
							Patients
						</h4>
						<BsPersonPlus
							className='text-dark-blue'
							size={20}
						/>
						{/* <input type="text" name="filter" placeholder='Search' id="" className='ml-2 pl-4 text-sm rounded h-[30px] w-[400px]' onChange={(e) => handleChange(e, e.target.name)} /> */}
					</div>

					<FamilyPlanningTable />
				</div>

			)}
			{opdState.showFamilyPlanningFollowUpRecords && (

				<div className='py-2'>
					<h3 className='text-sm font-semibold'>Follow up records</h3>
					<div className='flex justify-between'>
						<button
							type="button"
							className="inline-block px-6 py-2.5 my-4 bg-sky-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
							onClick={() => (
								setModals({
									...modals,
									showAddFamilyPlanningRecords:
										!modals.showAddFamilyPlanningRecords,
								})
							)}
						>
							Add +
						</button>
						<button
							type="button"
							className="inline-block px-6 py-2.5 my-4 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
							onClick={() => {
								setOpdState({
									...opdState,
									showFamilyPlanningFollowUpRecords: false

								})
								dispatch(deleteFamilyPlanningFollowUpRecord({ data: null }))
								dispatch(setPatientID({ data: null }))

							}}
						>
							Back
						</button>
					</div>


					<FollowUpRecordsTable />
				</div>

			)}

		</div>
	)
}

export default NurseFamilyPlanning