import { createSlice } from '@reduxjs/toolkit'

import {
    cancelAdmitPatient,
    createDiagnosis,
    createOtherPatientLabTest,
    createOtherPatientRadioTest,
    createPatientAppointment,
    createPatientLabTest,
    createPatientOtherPrescription,
    createPatientPrescription,
    createPatientRadioTest,
    createTeam, deleteAssignedPatient, deleteTeam, dischargeAdmittedPatient, getAppointments, getAssignedPatients, getDashboardData, getDoctorPatients, getDoctors, getLabInvestigationCategories, getLabInvestigationSamples, getLabInvestigationTypes, getPatientDependants, getPatientDiagnosis,
    getPatientFileUploads,
    getPatientLabTests,
    getPatientOtherPrescriptions,
    getPatientPrescriptions,
    getPatientRadioTests,
    getRadiologyInvestigationCategories,
    getRadiologyInvestigationTypes,
    getTeam, getTeams, getWardBedSpaces, requestAdmitPatient, resetCurrentTeam, resetNewDiagnosis, setCurrentDiagnosis, transferPatient, updateDiagnosis, updatePatientAppointment, updatePatientLabTest, updatePatientRadioTest, updateTeam, uploadOtherPatientLabTest, uploadOtherPatientRadioTest, uploadPatientFile
} from '../actions/doctor.actions'


const doctorSlice = createSlice({
    name: 'doctor',
    initialState: {
        team: null,
        teams: null,
        doctors: null,
        dashboard_data: null,
        doctor_patients: null,
        patient_dependants: null,
        patient_diagnoses: null,
        new_diagnosis: null,
        patient_prescriptions: null,
        patient_other_prescriptions: null,
        lab_investigation_types: null,
        lab_investigation_categories: null,
        lab_investigation_samples: null,
        patient_laboratory_tests: null,
        radiology_investigation_types: null,
        radiology_investigation_categories: null,
        patient_radiology_tests: null,
        patient_files: null,
        patient_transfer: null,
        inpatient_request: null,
        ward_bedspaces: null,
        doctor_appointments: null,
        updated_appointment: null,
        labtest_update: null,
        radiologytest_update: null,
        doctor_assignedpatients: null,
        doctorLoading: null,
        doctorErrors: null,
    },
    extraReducers: {
        [getDashboardData.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [getDashboardData.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.dashboard_data = action.payload
        },
        [getDashboardData.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },
       
        [getDoctors.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [getDoctors.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.doctors = action.payload
        },
        [getDoctors.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [getTeams.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [getTeams.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.teams = action.payload
        },
        [getTeams.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [createTeam.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [createTeam.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.teams = [action.payload, ...state.teams]
        },
        [createTeam.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [updateTeam.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [updateTeam.fulfilled]: (state, action) => {
            state.doctorLoading = false
            const index = state.teams.findIndex(team => team.id === action.payload.id)
            state.teams[index] = action.payload
        },
        [updateTeam.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [deleteTeam.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [deleteTeam.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.teams = state.teams.filter(team => team.id !== action.payload)
        },
        [deleteTeam.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [getTeam.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [getTeam.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.team = action.payload
        },
        [getTeam.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [resetCurrentTeam.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [resetCurrentTeam.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.team = action.payload
        },
        [resetCurrentTeam.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [getDoctorPatients.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [getDoctorPatients.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.doctor_patients = action.payload
        },
        [getDoctorPatients.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [getPatientDependants.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [getPatientDependants.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.patient_dependants = action.payload
        },
        [getPatientDependants.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [getPatientDiagnosis.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [getPatientDiagnosis.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.patient_diagnoses = action.payload
        },
        [getPatientDiagnosis.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [createDiagnosis.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [createDiagnosis.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.new_diagnosis = action.payload
        },
        [createDiagnosis.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [updateDiagnosis.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [updateDiagnosis.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.new_diagnosis = action.payload
            console.log(action)
        },
        [updateDiagnosis.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [resetNewDiagnosis.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [resetNewDiagnosis.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.new_diagnosis = action.payload
        },
        [resetNewDiagnosis.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [getPatientPrescriptions.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [getPatientPrescriptions.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.patient_prescriptions = action.payload
        },
        [getPatientPrescriptions.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [getPatientOtherPrescriptions.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [getPatientOtherPrescriptions.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.patient_other_prescriptions = action.payload
        },
        [getPatientOtherPrescriptions.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [createPatientPrescription.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [createPatientPrescription.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.patient_prescriptions = [...action.payload, ...state.patient_prescriptions]
        },
        [createPatientPrescription.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [createPatientOtherPrescription.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [createPatientOtherPrescription.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.patient_other_prescriptions = [...action.payload, ...state.patient_other_prescriptions]
        },
        [createPatientOtherPrescription.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [createPatientOtherPrescription.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [createPatientOtherPrescription.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.patient_other_prescriptions = [...action.payload, ...state.patient_other_prescriptions]
        },
        [createPatientOtherPrescription.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [getLabInvestigationTypes.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [getLabInvestigationTypes.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.lab_investigation_types = action.payload
        },
        [getLabInvestigationTypes.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [getLabInvestigationCategories.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [getLabInvestigationCategories.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.lab_investigation_categories = action.payload
        },
        [getLabInvestigationCategories.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [getLabInvestigationSamples.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [getLabInvestigationSamples.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.lab_investigation_samples = action.payload
        },
        [getLabInvestigationSamples.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [getPatientLabTests.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [getPatientLabTests.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.patient_laboratory_tests = action.payload
        },
        [getPatientLabTests.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [createPatientLabTest.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [createPatientLabTest.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.patient_laboratory_tests = [action.payload, ...state.patient_laboratory_tests]
        },
        [createPatientLabTest.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [updatePatientLabTest.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [updatePatientLabTest.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.labtest_update = action.payload
        },
        [updatePatientLabTest.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [createOtherPatientLabTest.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [createOtherPatientLabTest.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.patient_laboratory_tests = [action.payload, ...state.patient_laboratory_tests]
        },
        [createOtherPatientLabTest.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [uploadOtherPatientLabTest.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [uploadOtherPatientLabTest.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.labtest_update = action.payload
        },
        [uploadOtherPatientLabTest.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [getRadiologyInvestigationTypes.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [getRadiologyInvestigationTypes.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.radiology_investigation_types = action.payload
        },
        [getRadiologyInvestigationTypes.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [getRadiologyInvestigationCategories.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [getRadiologyInvestigationCategories.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.radiology_investigation_categories = action.payload
        },
        [getRadiologyInvestigationCategories.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [getPatientRadioTests.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [getPatientRadioTests.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.patient_radiology_tests = action.payload
        },
        [getPatientRadioTests.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [createPatientRadioTest.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [createPatientRadioTest.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.patient_radiology_tests = [action.payload, ...state.patient_radiology_tests]
        },
        [createPatientRadioTest.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [updatePatientRadioTest.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [updatePatientRadioTest.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.radiologytest_update = action.payload
        },
        [updatePatientRadioTest.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [createOtherPatientRadioTest.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [createOtherPatientRadioTest.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.patient_radiology_tests = [action.payload, ...state.patient_radiology_tests]
        },
        [createOtherPatientRadioTest.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [uploadOtherPatientRadioTest.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [uploadOtherPatientRadioTest.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.radiologytest_update = action.payload
        },
        [uploadOtherPatientRadioTest.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [getPatientFileUploads.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [getPatientFileUploads.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.patient_files = action.payload
        },
        [getPatientFileUploads.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [uploadPatientFile.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [uploadPatientFile.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.patient_files = [action.payload, ...state.patient_files]
        },
        [uploadPatientFile.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [transferPatient.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [transferPatient.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.patient_transfer = action.payload
        },
        [transferPatient.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [setCurrentDiagnosis.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [setCurrentDiagnosis.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.new_diagnosis = action.payload
        },
        [setCurrentDiagnosis.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [requestAdmitPatient.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [requestAdmitPatient.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.inpatient_request = action.payload
        },
        [requestAdmitPatient.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [cancelAdmitPatient.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [cancelAdmitPatient.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.inpatient_request = action.payload
        },
        [cancelAdmitPatient.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [dischargeAdmittedPatient.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [dischargeAdmittedPatient.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.inpatient_request = action.payload
        },
        [dischargeAdmittedPatient.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [getWardBedSpaces.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [getWardBedSpaces.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.ward_bedspaces = action.payload
        },
        [getWardBedSpaces.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [getAppointments.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [getAppointments.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.doctor_appointments = action.payload
        },
        [getAppointments.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [createPatientAppointment.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [createPatientAppointment.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.doctor_appointments = [action.payload, ...state.doctor_appointments]
        },
        [createPatientAppointment.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [updatePatientAppointment.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [updatePatientAppointment.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.updated_appointment = action.payload
            const index = state.doctor_appointments.findIndex(appointment => appointment.id === action.payload.id)
            state.doctor_appointments[index] = action.payload
        },
        [updatePatientAppointment.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [getAssignedPatients.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [getAssignedPatients.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.doctor_assignedpatients = action.payload
        },
        [getAssignedPatients.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },

        [deleteAssignedPatient.pending]: (state, action) => {
            state.doctorLoading = true
        },
        [deleteAssignedPatient.fulfilled]: (state, action) => {
            state.doctorLoading = false
            state.doctor_assignedpatients = state.doctor_assignedpatients.filter(patient => patient.pkid !== action.payload)
        },
        [deleteAssignedPatient.rejected]: (state, action) => {
            state.doctorLoading = false
            state.doctorErrors = action.payload
        },
    }
})


export default doctorSlice.reducer