/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useStateContext } from '../../state/context'
import PatientAppointment from './components/PatientAppointment'
import { PageTitle, RecordsPatientsTable } from '../../components'
import AssignedDoctorsList from './components/AssignedDoctorsList'
import { getPatients, getDoctors, getAllAppointments, getAllAssignedDoctors } from '../../state/actions/records.actions'


const RecordsPatients = (props) => {
	const { modals, setModals } = useStateContext()

	const [showActionsDropdown, setShowActionsDropdown] = useState(false)
	const [currentPatientIndex, setCurrentPatientIndex] = useState(0)
	const [showPatientAppointmentTable, setShowPatientAppointmentTable] = useState(false)
	const [showAssignedDoctorList, setShowAssignedDoctorList] = useState(false)

	const dispatch = useDispatch()
	const { patients, assign_a_patient_to_dctor,  create_patient, appointment} = useSelector((state) => state.records)

	useEffect(() => {
		dispatch(getPatients())
		dispatch(getDoctors())
	}, [assign_a_patient_to_dctor, create_patient])

	useEffect(() => {
		dispatch(getAllAppointments())
		dispatch(getAllAssignedDoctors())
		if (patients != null) {
			setCurrentPatientIndex(patients[0]?.pkid)
		}
	}, [assign_a_patient_to_dctor, appointment])


	return (
		<div>
			<PageTitle
				type={'records-patients'}
				showRecordsAddPatientModal={props?.showRecordsAddPatientModal}
				setShowRecordsAddPatientModal={
					props?.setShowRecordsAddPatientModal
				}
			/>

			<div className='space-y-5'>
				<RecordsPatientsTable
					data={patients}
					showActionsDropdown={showActionsDropdown}
					setShowActionsDropdown={setShowActionsDropdown}
					currentPatientIndex={currentPatientIndex}
					setCurrentPatientIndex={setCurrentPatientIndex}
				/>

				<PatientAppointment
					showPatientAppointmentTable={showPatientAppointmentTable}
					setShowPatientAppointmentTable={setShowPatientAppointmentTable}
				/>


				<AssignedDoctorsList
					showAssignedDoctorList={showAssignedDoctorList}
					setShowAssignedDoctorList={setShowAssignedDoctorList}
				/>
			</div>
		</div>
	)
}
export default RecordsPatients
