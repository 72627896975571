import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useStateContext } from '../../../state/context'
import { BsPersonPlus } from 'react-icons/bs'
import { getAllPatientPerformanceData } from '../../../state/actions/nurse.actions'

const PatientInitialPerformnaceTable = () => {
    const { all_patient_performanceData } = useSelector((state) => state.nurse)
    const { set_patient_id } = useSelector((state) => state.root)
    const { modals, setModals } = useStateContext()
    const dispatch = useDispatch()
    useEffect(() => {

    }, [])
    useEffect(() => {
        const patient_id = set_patient_id

        dispatch(getAllPatientPerformanceData({ patient_id }))
    }, [modals.showNurseAddPerformanceDataModal])
    return (
        <>
            <div className='flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1'>
                <h4 className='text-dark-blue text-[16px] font-semibold'>
                    Initial Performance
                </h4>
                <BsPersonPlus
                    className='text-dark-blue'
                    size={20}
                />

            </div>
            <table className='border rounded w-full cursor-default'>
                <thead className=''>
                    <tr>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                        >
                            S/N
                        </th>

                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                        >
                            Temperature
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                        >
                            Pulse
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                        >
                            Respirations
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
                        >
                            Bp(sys/dia)

                        </th>

                        {/* <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
                        >
                            Period

                        </th> */}
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
                        >
                            Date

                        </th>

                    </tr>
                </thead>

                <tbody>

                    {all_patient_performanceData?.map((item, index) => (
                        <tr
                            className='relative'
                            key={index}
                        >
                            <td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
                                {index + 1}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.temperature}    </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.pulse}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.respiration}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.blood_pressure_sys} / {item.blood_pressure_dia} mmHg
                            </td>

                            {/* <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.period}
                            </td> */}
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.date}
                            </td>



                            <td className=' text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                <div className='flex space-x-4 relative '>


                                </div>
                            </td>
                        </tr>
                    ))}


                </tbody>


            </table>


        </>
    )
}

export default PatientInitialPerformnaceTable