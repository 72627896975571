import React, { useState, useEffect } from 'react'
import { useStateContext } from '../../../state/context'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { createARadiologyCategoryTest, updateARadiologyCategoryTest } from '../../../state/actions/radiologist.actions'
import { setDeleteRadiologyCategory } from '../../../state/actions/actions'
import { toast } from 'react-toastify'

const RadiologyAddInvestigationCategoryModal = () => {
    const { modals, setModals } = useStateContext()
    const [formData, setFormData] = useState({
        title: '',
        id: '',
        investigation_type: ''
    })
    const { set_delete_radiology_cat } = useSelector((state) => state.root)
    const { all_radiology_category_test, all_radiology_investigation_type } = useSelector((state) => state.radiologist)
    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if (!formData.title) return toast.warning('Title field is required!')
        if (modals.showAddRadiologyInvestigationCategoryModal) {
            dispatch(createARadiologyCategoryTest(({ formData, toast, setModals: setModals, modals: modals })))
        }
        if (modals.showUpdateRadiologyCategoryTestModal) {
            dispatch(updateARadiologyCategoryTest({ formData, toast, setModals: setModals, modals: modals }))
        }

    }

    useEffect(() => {
        setFormData({ ...formData, title: set_delete_radiology_cat?.cat_name, id: set_delete_radiology_cat?.cat_id })

    }, [set_delete_radiology_cat])


    const dispatch = useDispatch()
    return (
        <div className="absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center">
            <div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        {modals.showAddRadiologyInvestigationCategoryModal && (
                            <>
                                <h4 className='text-dark-green text-[20px] font-bold'>Add Investigation Category</h4>
                                <FaTimesCircle
                                    className='text-dark-yellow cursor-pointer'
                                    onClick={() => {
                                        const data = null

                                        setModals({ ...modals, showAddRadiologyInvestigationCategoryModal: !modals.showAddRadiologyInvestigationCategoryModal })
                                    }}
                                    size={20}
                                />
                            </>
                        )}
                        {modals.showUpdateRadiologyCategoryTestModal && (
                            <>
                                <h4 className='text-dark-green text-[20px] font-bold'>Update Investigation Category</h4>
                                <FaTimesCircle
                                    className='text-dark-yellow cursor-pointer'
                                    onClick={() => {
                                        const data = null
                                        dispatch(setDeleteRadiologyCategory({ data }))
                                        setModals({ ...modals, showUpdateRadiologyCategoryTestModal: !modals.showUpdateRadiologyCategoryTestModal })
                                    }}
                                    size={20}
                                />
                            </>
                        )}

                    </div>

                    <form
                        onSubmit={handleSubmit}
                    >


                        <div>
                            {modals.showAddRadiologyInvestigationCategoryModal && (
                                <>

                                    {/* <select name="investigation_type" placeholder='Type' id="" className='border rounded mt-2 pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 '
                                        onChange={(e) =>
                                            handleChange(e, e.target.name)
                                        }
                                    >
                                        <option value={formData.investigation_type}>Select Investigation Type</option>
                                        {all_radiology_investigation_type?.map((type, index) => (
                                            <option value={type.pkid}>{type.title}</option>
                                        ))}

                                    </select> */}
                                    <input
                                        type="title"
                                        name="title"
                                        placeholder="Title"

                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </>
                            )}
                            {modals.showUpdateRadiologyCategoryTestModal && (
                                <>

                                    <input
                                        type="title"
                                        name="title"
                                        value={formData.title}
                                        placeholder="Title"

                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </>
                            )}
                        </div>

                        {modals.showAddRadiologyInvestigationCategoryModal && (
                            <div className='mt-4 '>
                                <button
                                    type='submit'
                                    className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                >Add</button>
                            </div>
                        )}
                        {modals.showUpdateRadiologyCategoryTestModal && (
                            <div className='mt-4 '>
                                <button
                                    type='submit'
                                    className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                >Update</button>
                            </div>
                        )}

                    </form>
                </div>


            </div>
        </div>
    )
}

export default RadiologyAddInvestigationCategoryModal