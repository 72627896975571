/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

import Pagination from '../utils/Pagination'
import PaymentStatus from '../utils/PaymentStatus'
import { useStateContext } from '../../state/context'
import { SetCollectionInvoiceType } from '../../state/actions/actions'
import { setCurrentInvoice, setCurrentLabInvoice, setCurrentPharmacyInvoice, setCurrentRadiologyInvoice, setCurrentRecordsInvoice } from '../../state/actions/collection.actions'
import { setCurrentAllInvoices } from '../../state/actions/collection.actions'


const CollectionsAllPaidInvoicesTable = ({ data, showList }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [currentData, setCurrentData] = useState([])
    const [accumulatedList, setAccumulatedList] = useState([])
    const [filterState, setFilterState] = useState({ search: '', invoices: [] })

    const handleFilter = () => {
        const filtered = data?.filter(item => {
            if (item.invoice_type === 'Prescription') {
                return item.patient_details.patient_number.toLowerCase().includes(filterState.search.toLowerCase())
                    || item.invoice_number.toLowerCase().includes(filterState.search.toLowerCase())
                    || item.patient_details.first_name.toLowerCase().includes(filterState.search.toLowerCase())
                    || item.patient_details.last_name.toLowerCase().includes(filterState.search.toLowerCase())
            } else {
                return item.patient_number.toLowerCase().includes(filterState.search.toLowerCase())
                    || item.invoice_number.toLowerCase().includes(filterState.search.toLowerCase())
                    || item.patient_details.first_name.toLowerCase().includes(filterState.search.toLowerCase())
                    || item.patient_details.last_name.toLowerCase().includes(filterState.search.toLowerCase())
            }
        })

        setFilterState({ ...filterState, invoices: filtered })

        if (filterState.search === '') {
            setFilterState({ ...filterState, invoices: data })
        }
    }

    useEffect(() => {
        setFilterState({ ...filterState, invoices: data })
    }, [data])

    useEffect(() => {
        handleFilter()
    }, [filterState.search])

    return (
        <div>
            <div className='flex justify-between mb-2'>
                <input
                    type="text"
                    name="search"
                    // disabled
                    placeholder='Search'
                    className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                    onChange={(e) => setFilterState({ ...filterState, [e.target.name]: e.target.value })}
                />

                <button
                    type="button"
                    className="w-[150px]  mb-2 mt-2 inline-block px-6 py-2 bg-sky-500 text-white font-medium text-[10px] leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                    onClick={() => {
                        if (accumulatedList.length > 0) {
                            dispatch(setCurrentAllInvoices({ data: accumulatedList }))
                            dispatch(SetCollectionInvoiceType({ data: "laboratory" }))
                            setModals({ ...modals, showCollectionPrintMultipleReceiptTypesModal: !modals.showCollectionPrintMultipleReceiptTypesModal })
                        }
                    }}
                >
                    Print All
                </button>

            </div>

            <div className="flex justify-between items-center w-full">

                <div
                    className="flex flex-col w-full cursor-pointer"
                >
                    <div className="">
                        {showList && (
                            <Fragment>
                                <table className="border-b border-l border-r rounded w-full cursor-default">
                                    <thead className="">
                                        <tr className='bg-gray-100'>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 px-4 py-4 text-left"
                                            >
                                                S/N
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 px-4 py-4 text-left"
                                            >

                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 py-4 text-left"
                                            >
                                                Patient ID
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 py-4 text-left"
                                            >
                                                Invoice ID
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 py-4 text-left"
                                            >
                                                Item
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 py-4 text-left"
                                            >
                                                Type
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 py-4 text-left"
                                            >
                                                Amount
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 py-4 text-left"
                                            >
                                                Date
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 py-4 text-left"
                                            >
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 py-4 text-left"
                                            >
                                                <input type="checkbox"
                                                    onChange={() => {
                                                        if (accumulatedList.length === 0) {
                                                            setAccumulatedList(currentData)
                                                        } else {
                                                            setAccumulatedList([])
                                                        }
                                                    }}
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentData?.map((invoice, index) => (
                                            <Fragment>
                                                {invoice?.invoice_type === "Laboratory" && (
                                                    <tr key={`${invoice.id}`}>
                                                        <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                                            {index + 1}
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            <input
                                                                type="checkbox"
                                                                checked={accumulatedList?.find((item) => item.id === invoice.id)}
                                                                onChange={(e) => {
                                                                    const myIndex = accumulatedList.findIndex(e => e.id === invoice.id)

                                                                    if (myIndex >= 0) {
                                                                        setAccumulatedList([...accumulatedList.filter(inv => inv.pkid !== invoice.pkid)])
                                                                    } else {
                                                                        setAccumulatedList([...accumulatedList, invoice])
                                                                    }
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            <Tippy content={`${invoice.patient_details?.last_name} ${invoice.patient_details?.first_name}`}>
                                                                <span>{invoice.patient_number}</span>
                                                            </Tippy>
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            {invoice.invoice_number}
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            {invoice.invoice_type}
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            {invoice.billing_type}
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            &#8358; {invoice.amount}
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            {invoice.date_generated}
                                                        </td>
                                                        <td className="flex justify-between items-center space-x-4 text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            <PaymentStatus status={invoice.is_paid} />
                                                            
                                                            <button
                                                                type="button"
                                                                className=" inline-block px-2 py-2 bg-teal-500 text-white font-medium text-xs leading-tight uppercase  shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                                onClick={() => {
                                                                    dispatch(setCurrentLabInvoice({ data: invoice }))
                                                                    setModals({ ...modals, showCollectionsViewLabInvoiceModal: !modals.showCollectionsViewLabInvoiceModal })
                                                                }}
                                                            >
                                                                View
                                                            </button>

                                                            <button
                                                                type="button"
                                                                className=" inline-block px-2 py-2 bg-blue-500 text-white font-medium text-xs leading-tight uppercase  shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                                onClick={() => {
                                                                    dispatch(setCurrentInvoice({ data: { ...invoice, invoice_type: 'laboratory' } }))
                                                                    setModals({ ...modals, showCollectionPrintInvoiceModal: !modals.showCollectionPrintInvoiceModal })
                                                                }}
                                                            >
                                                                print
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}
                                                {invoice?.invoice_type === "Prescription" && (
                                                    <tr key={`${invoice.id}`}>
                                                        <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                                            {index + 1}
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            <input
                                                                type="checkbox"
                                                                checked={accumulatedList?.find((item) => item.id === invoice.id)}
                                                                onChange={(e) => {
                                                                    const myIndex = accumulatedList.findIndex(e => e.id === invoice.id)
                                                                    console.log(e.target.value)
                                                                    if (myIndex >= 0) {
                                                                        setAccumulatedList([...accumulatedList.filter(inv => inv.pkid !== invoice.pkid)])
                                                                    } else {
                                                                        setAccumulatedList([...accumulatedList, invoice])
                                                                    }
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            <Tippy content={`${invoice.patient_details?.last_name} ${invoice.patient_details?.first_name}`}>
                                                                <span>{invoice.patient_details.patient_number}</span>
                                                            </Tippy>
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            {invoice.invoice_number}
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            {invoice.invoice_type}
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            {invoice.prescription_details.drugs}
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            &#8358; {invoice.invoice_amount}
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            {invoice.created_at.slice(0, 10)}
                                                        </td>
                                                        <td className="flex justify-between items-center space-x-4 text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            <PaymentStatus status={invoice.is_paid} />

                                                            <button
                                                                type="button"
                                                                className=" inline-block px-2 py-2 bg-teal-500 text-white font-medium text-xs leading-tight uppercase  shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                                onClick={() => {
                                                                    dispatch(setCurrentPharmacyInvoice({ data: invoice }))
                                                                    setModals({ ...modals, showCollectionsViewPharmacyInvoiceModal: !modals.showCollectionsViewPharmacyInvoiceModal })
                                                                }}
                                                            >
                                                                View
                                                            </button>

                                                            <button
                                                                type="button"
                                                                className=" inline-block px-2 py-2 bg-blue-500 text-white font-medium text-xs leading-tight uppercase  shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                                onClick={() => {
                                                                    dispatch(setCurrentInvoice({ data: { ...invoice, invoice_type: 'pharmacy' } }))
                                                                    setModals({ ...modals, showCollectionPrintInvoiceModal: !modals.showCollectionPrintInvoiceModal })
                                                                }}
                                                            >
                                                                print
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}
                                                {invoice?.invoice_type === "Radiology" && (
                                                    <tr key={`${invoice.id}`}>
                                                        <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                                            {index + 1}
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            <input
                                                                type="checkbox"
                                                                checked={accumulatedList?.find((item) => item.id === invoice.id)}
                                                                onChange={(e) => {
                                                                    const myIndex = accumulatedList.findIndex(e => e.id === invoice.id)

                                                                    if (myIndex >= 0) {
                                                                        setAccumulatedList([...accumulatedList.filter(inv => inv.pkid !== invoice.pkid)])
                                                                    } else {
                                                                        setAccumulatedList([...accumulatedList, invoice])
                                                                    }
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            <Tippy content={`${invoice.patient_details?.last_name} ${invoice.patient_details?.first_name}`}>
                                                                <span>{invoice.patient_number}</span>
                                                            </Tippy>
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            {invoice.invoice_number}
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            {invoice.invoice_type}
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            {invoice.billing_type}
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            &#8358; {invoice.amount}
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            {invoice.date_generated}
                                                        </td>
                                                        <td className="flex justify-between items-center space-x-4 text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            <PaymentStatus status={invoice.is_paid} />

                                                            <button
                                                                type="button"
                                                                className=" inline-block px-2 py-2 bg-teal-500 text-white font-medium text-xs leading-tight uppercase  shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                                onClick={() => {
                                                                    dispatch(setCurrentRadiologyInvoice({ data: invoice }))
                                                                    setModals({ ...modals, showCollectionsViewRadiologyInvoiceModal: !modals.showCollectionsViewRadiologyInvoiceModal })
                                                                }}
                                                            >
                                                                View
                                                            </button>

                                                            <button
                                                                type="button"
                                                                className=" inline-block px-2 py-2 bg-blue-500 text-white font-medium text-xs leading-tight uppercase  shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                                onClick={() => {
                                                                    dispatch(setCurrentInvoice({ data: { ...invoice, invoice_type: 'radiology' } }))
                                                                    setModals({ ...modals, showCollectionPrintInvoiceModal: !modals.showCollectionPrintInvoiceModal })
                                                                }}
                                                            >
                                                                print
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}
                                                {invoice?.invoice_type === "Records" && (
                                                    <tr key={`${invoice.id}`}>
                                                        <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                                            {index + 1}
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            <input
                                                                type="checkbox"
                                                                checked={accumulatedList?.find((item) => item.id === invoice.id)}
                                                                onChange={(e) => {
                                                                    const myIndex = accumulatedList.findIndex(e => e.id === invoice.id)

                                                                    if (myIndex >= 0) {
                                                                        setAccumulatedList([...accumulatedList.filter(inv => inv.pkid !== invoice.pkid)])
                                                                    } else {
                                                                        setAccumulatedList([...accumulatedList, invoice])
                                                                    }
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            <Tippy content={`${invoice.patient_details?.last_name} ${invoice.patient_details?.first_name}`}>
                                                                <span>{invoice.patient_number}</span>
                                                            </Tippy>
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            {invoice.invoice_number}
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            {invoice.invoice_type}
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            {invoice.billing_type}
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            &#8358; {invoice.amount}
                                                        </td>
                                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            {invoice.date}
                                                        </td>
                                                        <td className="flex justify-between items-center space-x-4 text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                            <PaymentStatus status={invoice.is_paid} />

                                                            <button
                                                                type="button"
                                                                className=" inline-block px-2 py-2 bg-teal-500 text-white font-medium text-xs leading-tight uppercase  shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                                onClick={() => {
                                                                    dispatch(setCurrentRecordsInvoice({ data: invoice }))
                                                                    setModals({ ...modals, showCollectionsViewRecordsInvoiceModal: !modals.showCollectionsViewRecordsInvoiceModal })
                                                                }}
                                                            >
                                                                View
                                                            </button>

                                                            <button
                                                                type="button"
                                                                className=" inline-block px-2 py-2 bg-blue-500 text-white font-medium text-xs leading-tight uppercase  shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                                onClick={() => {
                                                                    dispatch(setCurrentInvoice({ data: { ...invoice, invoice_type: 'records' } }))
                                                                    setModals({ ...modals, showCollectionPrintInvoiceModal: !modals.showCollectionPrintInvoiceModal })
                                                                }}
                                                            >
                                                                print
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </Fragment>
                                        ))}
                                    </tbody>
                                </table>

                                <Pagination
                                    data={filterState.invoices}
                                    currentData={currentData}
                                    setCurrentData={setCurrentData}
                                />
                            </Fragment>
                        )}
                    </div>
                </div>
            </div >
        </div>
    )
}

export default CollectionsAllPaidInvoicesTable
