import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { PageTitle } from '../../components'
import NursesList from './components/NursesList'
import RecordsList from './components/RecordsList'
import DoctorsList from './components/DoctorsList'
import PatientList from './components/Patient_list'
import PharmacistList from './components/PharmacistList'
import RadiologistList from './components/Radiologist_List'
import { getPatients, getDoctors, getAllBillings } from '../../state/actions/records.actions'


const RecordsDashboard = () => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getDoctors())
	}, [])

	return (
		<div>
			<PageTitle type={'records-dashboard'} />
			
			<div className='grid grid-cols-2'>
				<RecordsList />
				<PatientList />
				<RadiologistList />
				<DoctorsList />
				<PharmacistList />
				<NursesList />
			</div>
		</div>
	)
}

export default RecordsDashboard
