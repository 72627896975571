import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { verifyPatientFlutterWavePayment } from '../state/actions/patient.actions'
import { changeAnInvoiceStatus } from '../state/actions/patient.actions'


const FlutterwaveVerifyPayment = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [triggerInvoiceStatus, setTriggerInvoiceStatus] = useState(false)
    const params = new URLSearchParams(window.location.href)
    const transaction_status = window.location.href.split('?')[1].split('=')[1].split('&')[0]

    const transaction_id = params.get('transaction_id')
    const { set_paystack_payment_details } = useSelector((state) => state.root)
    const invoice_details = JSON.parse(localStorage.getItem("Invoice_details"))

    useEffect(() => {
        if (transaction_status === 'successful') {
            dispatch(verifyPatientFlutterWavePayment({ transaction_id, toast, triggerInvoiceStatus, setTriggerInvoiceStatus }))
        }
        if (transaction_status === 'cancelled') {
            toast.info('Transaction cancelled')
            navigate('/dashboard')
        }
        console.log(transaction_status);


    }, [])

    useEffect(() => {
        const formData = {

        }
        const type = invoice_details?.type
        const id = invoice_details?.invoice_id

        if (triggerInvoiceStatus === true) {
            dispatch(changeAnInvoiceStatus({ formData, type, id }));
            navigate('/dashboard')
        }

    }, [triggerInvoiceStatus])

    return (
        <div>

        </div>
    )
}

export default FlutterwaveVerifyPayment