import {
    IconHome, IconTeam, IconPatient, IconTime, IconAppointment, IconAdmission,
    IconCalender, IconChat, IconMessage, IconSms, IconProfile, IconDepartment,
    IconPeople, IconBed, IconOPD, IconAntenatal, IconFamilyPlanning,
    IconImmunization, IconInventory, IconSupplyPoint, IconDrugs, IconCategory,
    IconEquipment, IconReport, IconInvestigation, IconFile, IconPatient2, IconFiles,
    IconWards, IconMeeting, IconCheckList, IconSettings, IconInPatient, IconDiscount, IconClinicalData
} from '../assets'

export const bloodgroups = [
    'A+',
    'A-',
    'AB-',
    'AB+',
    'B+',
    'B-',
    'O+',
    'O-',
]

export const maritalstatus = [
    'Married',
    'Single',
    'Divorced',
    'Widow',
    'Widower'
]

export const genotypes = [
    'AA',
    'AS',
    'AC',
    'SS'
]


export const drug__types = [
    { title: 'Pack', value: 'pack' },
    { title: 'Card', value: 'card' },
    { title: 'Bottle', value: 'bottle' },
    { title: 'Container', value: 'container' }
]

export const drug__compositions = [
    { title: 'None', value: 'none' },
    { title: 'Tablet', value: 'tablet' },
    { title: 'Powder', value: 'powder' },
    { title: 'Liquid', value: 'liquid' }
]

export const drug__frequency = [
    { title: 'Twice Daily', value: 'twice daily' },
    { title: 'At bed time', value: 'at bed time' },
    { title: 'Once a day', value: 'Once a day' },
    { title: 'When necessary', value: 'when necessary' },
    { title: 'Four times daily', value: 'four times daily' },
    { title: 'At once', value: 'at once' },
    { title: 'Thrice daily', value: 'thrice daily' },
]

export const drug__dosage = [
    { title: 'Amp', value: 'amp' },
    { title: 'Cap', value: 'cap' },
    { title: 'sc', value: 'sc' },
    { title: 'supp', value: 'supp' },
    { title: 'tab', value: 'tab' },
    { title: 'tbsp', value: 'tbsp' },
    { title: 'tsp', value: 'tsp' },
]

export const drug__units = [
    { title: 'g', value: 'g' },
    { title: 'kg', value: 'kg' },
    { title: 'mg', value: 'mg' },
    { title: 'mg/kg', value: 'mg/kg' },
    { title: 'ml', value: 'ml' },
    { title: 'mu', value: 'mu' },
]

export const drug__routes = [
    { title: 'amp', value: 'amp' },
    { title: 'au', value: 'au' },
    { title: 'buc', value: 'buc' },
    { title: 'cap', value: 'cap' },
    { title: 'epi', value: 'epi' },
    { title: 'ia', value: 'ia' },
    { title: 'iart', value: 'iart' },
    { title: 'icar', value: 'icar' },
    { title: 'iduc', value: 'iduc' },
    { title: 'ifol', value: 'ifol' },
    { title: 'igas', value: 'igas' },
    { title: 'iles', value: 'iles' },
    { title: 'im', value: 'im' },
    { title: 'imam', value: 'imam' },
    { title: 'imrs', value: 'imrs' },
    { title: 'inf', value: 'inf' },
    { title: 'inh', value: 'inh' },
    { title: 'ip', value: 'ip' },
    { title: 'isin', value: 'isin' },
    { title: 'isyn', value: 'isyn' },
    { title: 'it', value: 'it' },
    { title: 'ites', value: 'ites' },
    { title: 'iu', value: 'iu' },
    { title: 'iv', value: 'iv' },
    { title: 'ives', value: 'ives' },
    { title: 'nas', value: 'nas' },
    { title: 'ng', value: 'ng' },
    { title: 'ou', value: 'ou' },
    { title: 'par', value: 'par' },
    { title: 'pneu', value: 'pneu' },
    { title: 'po', value: 'po' },
    { title: 'pv', value: 'pv' },
    { title: 'rec', value: 'rec' },
    { title: 'sc', value: 'sc' },
    { title: 'SC', value: 'SC' },
    { title: 'SCi', value: 'SCi' },
    { title: 'SL', value: 'SL' },
    { title: 'supp', value: 'supp' },
    { title: 'TOP', value: 'TOP' },
    { title: 'TOPp', value: 'TOPp' },
    { title: 'TD', value: 'TD' },
    { title: 'tab', value: 'tab' },
    { title: 'tbsp', value: 'tbsp' },
    { title: 'tsp', value: 'tsp' },
]

export const Antigens = [
    'BCG',
    'HepB',
    'OPV-0',
    'OPV-1',
    'OPV-2',
    'OPV-3',
    'PCV-1',
    'PCV-2',
    'PCV-3',
    'PENTA-1',
    'PENTA-2',
    'PENTA-3',
    'ROTA-1',
    'ROTA-2',
    'ROTA-3',
    'IPV',
    'Measles-1',
    'Measles-2',
    'Vitamin-A1',
    'Vitamin-A2',
    'Yellow Fever',
    'Meningitis',
]

export const payment__methods = [
    { id: 1, title: 'Cash', value: 'cash' },
    { id: 2, title: 'Gt Squad', value: 'squadco' },
    { id: 3, title: 'Paystack', value: 'paystack' },
    // { id: 4, title: 'Flutterwave', value: 'flutterwave' },
    { id: 5, title: 'Point Of Sale (POS)', value: 'pos' },
]

export const admin__payment__methods = [
    { id: 3, title: 'Paystack', value: 'paystack' },
]
export const patient__payment__methods = [
    { id: 3, title: 'Paystack', value: 'paystack' },
]

export const admin__remittance__payment__methods = [
    // { id: 3, title: 'Bank Transfer', value: 'transfer' },
    { id: 3, title: 'Paystack', value: 'paystack' },
]

export const admin__remittance__payment__banks = [
    { id: 3, bank: 'Guaranty Trust Bank Plc', account_number: '0741491748', account_name: 'Zuumatek Limited' },
    { id: 3, bank: 'FCMB', account_number: '9236087019', account_name: 'Zuumatek Limited' },
]

export const menus__admin = [
    { title: 'Admin Dashboard', icon: IconHome },
    { title: 'Departments', icon: IconDepartment },
    { title: 'Human Resources', icon: IconPeople },
    { title: 'Invoices', icon: IconFile },
    { title: 'Bed Manager', icon: IconBed },
    { title: 'Schedule', icon: IconCalender },
    { title: 'Chat', icon: IconChat },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    // { title: 'Notifications', icon: 'icon__notification'},
    { title: 'Profile', icon: IconProfile },
    { title: 'Settings', icon: IconSettings },
]

export const menus__doctor = [
    { title: 'Doctor Dashboard', icon: IconHome },
    { title: 'Team', icon: IconTeam },
    { title: 'Doctor Clinical Data', icon: IconClinicalData },
    { title: 'Manage Patients', icon: IconPatient },
    { title: 'Assigned Patients', icon: IconTime },
    { title: 'Appointments', icon: IconAppointment },
    { title: 'Admissions', icon: IconAdmission },
    { title: 'Schedule', icon: IconCalender },
    { title: 'Chat', icon: IconChat },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    // { title: 'Notifications', icon: 'icon__notification'},
    { title: 'Profile', icon: IconProfile },
]

export const menus__ophthalmologist = [
    { title: 'Ophthalmologist Dashboard', icon: IconHome },
    { title: 'Ophthalmologist Team', icon: IconTeam },
    { title: 'Doctor Clinical Data', icon: IconClinicalData },
    { title: 'Ophthalmologist Manage Patients', icon: IconPatient },
    { title: 'Ophthalmologist Assigned Patients', icon: IconTime },
    { title: 'Ophthalmologist Appointments', icon: IconAppointment },
    { title: 'Ophthalmologist Admissions', icon: IconAdmission },
    { title: 'Schedule', icon: IconCalender },
    { title: 'Chat', icon: IconChat },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    { title: 'Profile', icon: IconProfile },
]

export const menus__dentist = [
    { title: 'Dentist Dashboard', icon: IconHome },
    { title: 'Dentist Team', icon: IconTeam },
    { title: 'Doctor Clinical Data', icon: IconClinicalData },
    { title: 'Dentist Manage Patients', icon: IconPatient },
    { title: 'Dentist Assigned Patients', icon: IconTime },
    { title: 'Dentist Appointments', icon: IconAppointment },
    { title: 'Dentist Admissions', icon: IconAdmission },
    { title: 'Schedule', icon: IconCalender },
    { title: 'Chat', icon: IconChat },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    { title: 'Profile', icon: IconProfile },
]

export const menus__physiotherapist = [
    { title: 'Physiotherapist Dashboard', icon: IconHome },
    { title: 'Physiotherapist Team', icon: IconTeam },
    { title: 'Doctor Clinical Data', icon: IconClinicalData },
    { title: 'Physiotherapist Manage Patients', icon: IconPatient },
    { title: 'Physiotherapist Assigned Patients', icon: IconTime },
    { title: 'Physiotherapist Appointments', icon: IconAppointment },
    { title: 'Physiotherapist Admissions', icon: IconAdmission },
    { title: 'Schedule', icon: IconCalender },
    { title: 'Chat', icon: IconChat },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    { title: 'Profile', icon: IconProfile },
]

export const menus__otolaryngologist = [
    { title: 'Otolaryngologists Dashboard', icon: IconHome },
    { title: 'Otolaryngologists Team', icon: IconTeam },
    { title: 'Doctor Clinical Data', icon: IconClinicalData },
    { title: 'Otolaryngologists Manage Patients', icon: IconPatient },
    { title: 'Otolaryngologists Assigned Patients', icon: IconTime },
    { title: 'Otolaryngologists Appointments', icon: IconAppointment },
    { title: 'Otolaryngologists Admissions', icon: IconAdmission },
    { title: 'Schedule', icon: IconCalender },
    { title: 'Chat', icon: IconChat },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    { title: 'Profile', icon: IconProfile },
]

export const menus__nurse = [
    { title: 'Nurse Dashboard', icon: IconHome },
    { title: 'OPD', icon: IconOPD },
    { title: 'Inpatients', icon: IconInPatient },
    { title: 'Antenatal', icon: IconAntenatal },
    { title: 'Family Planning', icon: IconFamilyPlanning },
    { title: 'Child Immunization', icon: IconImmunization },
    { title: 'Birth Records', icon: IconInventory },
    { title: 'Schedule', icon: IconCalender },
    { title: 'Chat', icon: IconChat },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    // { title: 'Notifications', icon: 'icon__notification'},
    { title: 'Profile', icon: IconProfile },
]

export const menus__pharmacist = [
    { title: 'Pharmacist Dashboard', icon: IconHome },
    { title: 'Clinical Data', icon: IconFile },
    { title: 'Supply Point', icon: IconSupplyPoint },
    { title: 'Schedule', icon: IconCalender },
    { title: 'Chat', icon: IconChat },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    { title: 'Profile', icon: IconProfile },
]

export const menus__pharmacist__departmentalhead = [
    { title: 'Pharmacist Dashboard', icon: IconHome },
    { title: 'Drugs', icon: IconDrugs },
    { title: 'Clinical Data', icon: IconFile },
    { title: 'Drugs Category', icon: IconCategory },
    { title: 'Supply Points', icon: IconSupplyPoint },
    // { title: 'Prescriptions', icon: 'icon__inventory' },
    // { title: 'Bills/Dispense', icon: 'icon__wallet' },
    { title: 'Schedule', icon: IconCalender },
    { title: 'Chat', icon: IconChat },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    { title: 'Profile', icon: IconProfile },
]

export const menus__radiologist = [
    { title: 'Radiologist Dashboard', icon: IconHome },
    { title: 'Radiology Equipments', icon: IconEquipment },
    { title: 'Radiology Reports', icon: IconReport },
    { title: 'Radiology Billings', icon: IconCalender },
    { title: 'Radiology Investigations', icon: IconInvestigation },
    { title: 'Radiology Invoices', icon: IconFile },
    { title: 'Schedule', icon: IconCalender },
    { title: 'Chat', icon: IconChat },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    // { title: 'Notifications', icon: 'icon__notification'},
    { title: 'Profile', icon: IconProfile },
]

export const menus__laboratorist = [
    { title: 'Laboratorist Dashboard', icon: IconHome },
    { title: 'Laboratory Equipments', icon: IconEquipment },
    { title: 'Laboratory Billings', icon: IconCalender },
    { title: 'Laboratory Reports', icon: IconReport },
    { title: 'Laboratory Investigations', icon: IconInvestigation },
    { title: 'Laboratory Invoices', icon: IconFile },
    { title: 'Schedule', icon: IconCalender },
    { title: 'Chat', icon: IconChat },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    // { title: 'Notifications', icon: 'icon__notification'},
    { title: 'Profile', icon: IconProfile },
]

export const menus__records = [
    { title: 'Records Dashboard', icon: IconHome },
    { title: 'Patients', icon: IconPatient2 },
    { title: 'Records Invoices', icon: IconFile },
    { title: 'Records', icon: IconFiles },
    { title: 'Wards/Beds', icon: IconWards },
    { title: 'Schedule', icon: IconCalender },
    { title: 'Chat', icon: IconChat },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    // { title: 'Notifications', icon: 'icon__notification'},
    { title: 'Profile', icon: IconProfile },
]

export const menus__patient = [
    { title: 'Patient Dashboard', icon: IconHome },
    { title: 'Payments', icon: IconFile },
    { title: 'Patient Appointments', icon: IconMeeting },
    { title: 'Chat', icon: IconChat },
    // { title: 'Messages', icon: IconMessage },
    { title: 'Patient Message', icon: IconMessage },
    // { title: 'SMS', icon: IconSms },
    { title: 'Patient SMS', icon: IconSms },
    // { title: 'Notifications', icon: 'icon__notification'},
    { title: 'Profile', icon: IconProfile },
]

export const menus__accounts__small__hospital = [
    { title: 'Accounts Dashboard', icon: IconHome },
    { title: 'Accounts Invoices', icon: IconFile },
    { title: 'Accounts Paid Invoices', icon: IconCheckList },
    { title: 'Accounts Reports', icon: IconReport },
    { title: 'Accounts NHIS Discounts', icon: IconDiscount },
    { title: 'Accounts NHIS Paid Discounts', icon: IconDiscount },
    { title: 'Chat', icon: IconChat },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    // { title: 'Notifications', icon: 'icon__notification'},
    { title: 'Profile', icon: IconProfile },
]

export const menus__accounts__large__hospital = [
    { title: 'Accounts Dashboard', icon: IconHome },
    { title: 'Accounts Reports', icon: IconReport },
    // { title: 'Accounts Reports', icon: 'icon__report__2' },
    { title: 'Accounts NHIS Discounts', icon: IconDiscount },
    { title: 'Accounts NHIS Paid Discounts', icon: IconDiscount },
    { title: 'Chat', icon: IconChat },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    // { title: 'Notifications', icon: 'icon__notification'},
    { title: 'Profile', icon: IconProfile },
]

export const menus__collections = [
    { title: 'Collections Dashboard', icon: IconHome },
    { title: 'Collections Invoices', icon: IconFile },
    { title: 'Collections Paid Invoices', icon: IconCheckList },
    { title: 'Chat', icon: IconChat },
    { title: 'Messages', icon: IconMessage },
    { title: 'SMS', icon: IconSms },
    // { title: 'Notifications', icon: 'icon__notification'},
    { title: 'Profile', icon: IconProfile },
]

export const countries = [
    {
        title: 'Afghanistan',
        value: 'AF',
    },
    {
        title: 'Albania',
        value: 'AL',
    },
    {
        title: 'Algeria',
        value: 'DZ',
    },
    {
        title: 'American Samoa',
        value: 'AS',
    },
    {
        title: 'Andorra',
        value: 'AD',
    },
    {
        title: 'Angola',
        value: 'AO',
    },
    {
        title: 'Anguilla',
        value: 'AI',
    },
    {
        title: 'Argentina',
        value: 'AR',
    },
    {
        title: 'Armenia',
        value: 'AM',
    },
    {
        title: 'Aruba',
        value: 'AW',
    },
    {
        title: 'Australia',
        value: 'AU',
    },
    {
        title: 'Azerbaijan',
        value: 'AZ',
    },
    {
        title: 'Bahamas',
        value: 'BS',
    },
    {
        title: 'Bahrain',
        value: 'BH',
    },
    {
        title: 'Bangladesh',
        value: 'BD',
    },
    {
        title: 'Barbados',
        value: 'BB',
    },
    {
        title: 'Belarus',
        value: 'BY',
    },
    {
        title: 'Belgium',
        value: 'BE',
    },
    {
        title: 'Belize',
        value: 'BZ',
    },
    {
        title: 'Benin',
        value: 'BJ',
    },
    {
        title: 'Bermuda',
        value: 'BM',
    },
    {
        title: 'Bhutan',
        value: 'BT',
    },
    {
        title: 'Bolivia',
        value: 'BO',
    },
    {
        title: 'Bosnia and Herzegovina',
        value: 'BA',
    },
    {
        title: 'Botswana',
        value: 'BW',
    },
    {
        title: 'Brazil',
        value: 'BR',
    },
    {
        title: 'British Virgin Islands',
        value: 'VG',
    },
    {
        title: 'Brunei',
        value: 'BN',
    },
    {
        title: 'Bulgaria',
        value: 'BG',
    },
    {
        title: 'Burkina Faso',
        value: 'BF',
    },
    {
        title: 'Burundi',
        value: 'BI',
    },
    {
        title: 'Cambodia',
        value: 'KH',
    },
    {
        title: 'Cameroon',
        value: 'CM',
    },
    {
        title: 'Canada',
        value: 'CA',
    },
    {
        title: 'Cape Verde',
        value: 'CV',
    },
    {
        title: 'Cayman Islands',
        value: 'KY',
    },
    {
        title: 'Central African Republic',
        value: 'CF',
    },
    {
        title: 'Chad',
        value: 'TD',
    },
    {
        title: 'Chile',
        value: 'CL',
    },
    {
        title: 'China',
        value: 'CN',
    },
    {
        title: 'Columbia',
        value: 'CO',
    },
    {
        title: 'Comoros',
        value: 'KM',
    },
    {
        title: 'Cook Islands',
        value: 'CK',
    },
    {
        title: 'Costa Rica',
        value: 'CR',
    },
    {
        title: 'Croatia',
        value: 'HR',
    },
    {
        title: 'Cuba',
        value: 'CU',
    },
    {
        title: 'Curacao',
        value: 'CW',
    },
    {
        title: 'Cyprus',
        value: 'CY',
    },
    {
        title: 'Czech Republic',
        value: 'CZ',
    },
    {
        title: 'Democratic Republic of the Congo',
        value: 'CD',
    },
    {
        title: 'Denmark',
        value: 'DK',
    },
    {
        title: 'Djibouti',
        value: 'DJ',
    },
    {
        title: 'Dominica',
        value: 'DM',
    },
    {
        title: 'Dominican Republic',
        value: 'DO',
    },
    {
        title: 'East Timor',
        value: 'TL',
    },
    {
        title: 'Ecuador',
        value: 'EC',
    },
    {
        title: 'Egypt',
        value: 'EG',
    },
    {
        title: 'El Salvador',
        value: 'SV',
    },
    {
        title: 'Eritrea',
        value: 'ER',
    },
    {
        title: 'Estonia',
        value: 'EE',
    },
    {
        title: 'Faroe Islands',
        value: 'FO',
    },
    {
        title: 'Fiji',
        value: 'FJ',
    },
    {
        title: 'Finland',
        value: 'FI',
    },
    {
        title: 'France',
        value: 'FR',
    },
    {
        title: 'French Polynesia',
        value: 'PF',
    },
    {
        title: 'Gabon',
        value: 'GA',
    },
    {
        title: 'Gambia',
        value: 'GM',
    },
    {
        title: 'Georgia',
        value: 'GE',
    },
    {
        title: 'Germany',
        value: 'DE',
    },
    {
        title: 'Ghana',
        value: 'GH',
    },
    {
        title: 'Greece',
        value: 'GR',
    },
    {
        title: 'Greenland',
        value: 'GL',
    },
    {
        title: 'Grenada',
        value: 'GD',
    },
    {
        title: 'Guam',
        value: 'GU',
    },
    {
        title: 'Guatemala',
        value: 'GT',
    },
    {
        title: 'Guernsey',
        value: 'GG',
    },
    {
        title: 'Guinea',
        value: 'GN',
    },
    {
        title: 'Guinea-Bissau',
        value: 'GW',
    },
    {
        title: 'Guyana',
        value: 'GY',
    },
    {
        title: 'Haiti',
        value: 'HT',
    },
    {
        title: 'Honduras',
        value: 'HN',
    },
    {
        title: 'Hong Kong',
        value: 'HK',
    },
    {
        title: 'Hungary',
        value: 'HU',
    },
    {
        title: 'Iceland',
        value: 'IS',
    },
    {
        title: 'India',
        value: 'IN',
    },
    {
        title: 'Indonesia',
        value: 'ID',
    },
    {
        title: 'Iran',
        value: 'IR',
    },
    {
        title: 'Iraq',
        value: 'IQ',
    },
    {
        title: 'Ireland',
        value: 'IE',
    },
    {
        title: 'Isle of Man',
        value: 'IM',
    },
    {
        title: 'Israel',
        value: 'IL',
    },
    {
        title: 'Italy',
        value: 'IT',
    },
    {
        title: 'Ivory Coast',
        value: 'CI',
    },
    {
        title: 'Jamaica',
        value: 'JM',
    },
    {
        title: 'Japan',
        value: 'JP',
    },
    {
        title: 'Jersey',
        value: 'JE',
    },
    {
        title: 'Jordan',
        value: 'JO',
    },
    {
        title: 'Kazakhstan',
        value: 'KZ',
    },
    {
        title: 'Kenya',
        value: 'KE',
    },
    {
        title: 'Kiribati',
        value: 'KI',
    },
    {
        title: 'Kosovo',
        value: 'XK',
    },
    {
        title: 'Kuwait',
        value: 'KW',
    },
    {
        title: 'Kyrgyzstan',
        value: 'KG',
    },
    {
        title: 'Laos',
        value: 'LA',
    },
    {
        title: 'Latvia',
        value: 'LV',
    },
    {
        title: 'Lebanon',
        value: 'LB',
    },
    {
        title: 'Lesotho',
        value: 'LS',
    },
    {
        title: 'Liberia',
        value: 'LB',
    },
    {
        title: 'Libya',
        value: 'LY',
    },
    {
        title: 'Liechtenstein',
        value: 'LI',
    },
    {
        title: 'Lithuania',
        value: 'LT',
    },
    {
        title: 'Luxembourg',
        value: 'LU',
    },
    {
        title: 'Macau',
        value: 'MO',
    },
    {
        title: 'Macedonia',
        value: 'MK',
    },
    {
        title: 'Madagascar',
        value: 'MG',
    },
    {
        title: 'Malawi',
        value: 'MW',
    },
    {
        title: 'Malaysia',
        value: 'MY',
    },
    {
        title: 'Maldives',
        value: 'MV',
    },
    {
        title: 'Mali',
        value: 'ML',
    },
    {
        title: 'Malta',
        value: 'MT',
    },
    {
        title: 'Marshall Islands',
        value: 'MH',
    },
    {
        title: 'Mauritania',
        value: 'MR',
    },
    {
        title: 'Mauritius',
        value: 'MU',
    },
    {
        title: 'Mayotte',
        value: 'YT',
    },
    {
        title: 'Mexico',
        value: 'MX',
    },
    {
        title: 'Micronesia',
        value: 'FM',
    },
    {
        title: 'Moldova',
        value: 'MD',
    },
    {
        title: 'Monaco',
        value: 'MC',
    },
    {
        title: 'Mongolia',
        value: 'MN',
    },
    {
        title: 'Montenegro',
        value: 'ME',
    },
    {
        title: 'Morocco',
        value: 'MA',
    },
    {
        title: 'Mozambique',
        value: 'MZ',
    },
    {
        title: 'Myanmar',
        value: 'MM',
    },
    {
        title: 'Namibia',
        value: 'NA',
    },
    {
        title: 'Nepal',
        value: 'NP',
    },
    {
        title: 'Netherlands',
        value: 'NL',
    },
    {
        title: 'Netherlands Antilles',
        value: 'AN',
    },
    {
        title: 'New Caledonia',
        value: 'NC',
    },
    {
        title: 'New Zealand',
        value: 'NZ',
    },
    {
        title: 'Nicaragua',
        value: 'NI',
    },
    {
        title: 'Niger',
        value: 'NE',
    },
    {
        title: 'Nigeria',
        value: 'NG',
    },
    {
        title: 'North Korea',
        value: 'KP',
    },
    {
        title: 'Northern Mariana Islands',
        value: 'MP',
    },
    {
        title: 'Norway',
        value: 'NO',
    },
    {
        title: 'Oman',
        value: 'OM',
    },
    {
        title: 'Pakistan',
        value: 'PK',
    },
    {
        title: 'Palestine',
        value: 'PS',
    },
    {
        title: 'Panama',
        value: 'PA',
    },
    {
        title: 'Papua New Guinea',
        value: 'PG',
    },
    {
        title: 'Paraguay',
        value: 'PY',
    },
    {
        title: 'Peru',
        value: 'PE',
    },
    {
        title: 'Philippines',
        value: 'PH',
    },
    {
        title: 'Poland',
        value: 'PL',
    },
    {
        title: 'Portugal',
        value: 'PT',
    },
    {
        title: 'Puerto Rico',
        value: 'PR',
    },
    {
        title: 'Qatar',
        value: 'QA',
    },
    {
        title: 'Republic of the Congo',
        value: 'CG',
    },
    {
        title: 'Reunion',
        value: 'RE',
    },
    {
        title: 'Romania',
        value: 'RO',
    },
    {
        title: 'Russia',
        value: 'RU',
    },
    {
        title: 'Rwanda',
        value: 'RW',
    },
    {
        title: 'Saint Kitts and Nevis',
        value: 'KN',
    },
    {
        title: 'Saint Lucia',
        value: 'LC',
    },
    {
        title: 'Saint Martin',
        value: 'MF',
    },
    {
        title: 'Saint Pierre and Miquelon',
        value: 'PM',
    },
    {
        title: 'Saint Vincent and the Grenadines',
        value: 'VC',
    },
    {
        title: 'Samoa',
        value: 'WS',
    },
    {
        title: 'San Marino',
        value: 'SM',
    },
    {
        title: 'Sao Tome and Principe',
        value: 'ST',
    },
    {
        title: 'Saudi Arabia',
        value: 'SA',
    },
    {
        title: 'Senegal',
        value: 'SN',
    },
    {
        title: 'Serbia',
        value: 'RS',
    },
    {
        title: 'Seychelles',
        value: 'SC',
    },
    {
        title: 'Sierra Leone',
        value: 'SL',
    },
    {
        title: 'Singapore',
        value: 'SG',
    },
    {
        title: 'Sint Maarten',
        value: 'SX',
    },
    {
        title: 'Slovakia',
        value: 'SK',
    },
    {
        title: 'Slovenia',
        value: 'SI',
    },
    {
        title: 'Solomon Islands',
        value: 'SB',
    },
    {
        title: 'Somalia',
        value: 'SO',
    },
    {
        title: 'South Africa',
        value: 'ZA',
    },
    {
        title: 'South Korea',
        value: 'KR',
    },
    {
        title: 'South Sudan',
        value: 'SS',
    },
    {
        title: 'Spain',
        value: 'ES',
    },
    {
        title: 'Sri Lanka',
        value: 'LK',
    },
    {
        title: 'Sudan',
        value: 'SD',
    },
    {
        title: 'Suriname',
        value: 'SR',
    },
    {
        title: 'Swaziland',
        value: 'SZ',
    },
    {
        title: 'Sweden',
        value: 'SE',
    },
    {
        title: 'Switzerland',
        value: 'CH',
    },
    {
        title: 'Syria',
        value: 'SY',
    },
    {
        title: 'Taiwan',
        value: 'TW',
    },
    {
        title: 'Tajikistan',
        value: 'TJ',
    },
    {
        title: 'Tanzania',
        value: 'TZ',
    },
    {
        title: 'Thailand',
        value: 'TH',
    },
    {
        title: 'Togo',
        value: 'TG',
    },
    {
        title: 'Tonga',
        value: 'TO',
    },
    {
        title: 'Trinidad and Tobago',
        value: 'TT',
    },
    {
        title: 'Tunisia',
        value: 'TN',
    },
    {
        title: 'Turkey',
        value: 'TR',
    },
    {
        title: 'Turkmenistan',
        value: 'TM',
    },
    {
        title: 'Turks and Caicos Islands',
        value: 'TC',
    },
    {
        title: 'Tuvalu',
        value: 'TV',
    },
    {
        title: 'U.S. Virgin Islands',
        value: 'VI',
    },
    {
        title: 'Uganda',
        value: 'UG',
    },
    {
        title: 'Ukraine',
        value: 'UA',
    },
    {
        title: 'United Arab Emirates',
        value: 'AE',
    },
    {
        title: 'United Kingdom',
        value: 'GB',
    },
    {
        title: 'United States',
        value: 'US',
    },
    {
        title: 'Uruguay',
        value: 'UY',
    },
    {
        title: 'Uzbekistan',
        value: 'UZ',
    },
    {
        title: 'Vanuatu',
        value: 'VU',
    },
    {
        title: 'Venezuela',
        value: 'VE',
    },
    {
        title: 'Vietnam',
        value: 'VN',
    },
    {
        title: 'Western Sahara',
        value: 'EH',
    },
    {
        title: 'Yemen',
        value: 'YE',
    },
    {
        title: 'Zambia',
        value: 'ZM',
    },
    {
        title: 'Zimbabwe',
        value: 'ZW',
    },
]


export const root__modals = {
    showAddDirectorateModal: false,
    showUpdateDirectorateModal: false,
    showDeleteDirectorateModal: false,
    showAddDepartmentModal: false,
    showUpdateDepartmentModal: false,
    showDeleteDepartmentModal: false,
    showAddEmployeeModal: false,
    showUpdateEmployeeModal: false,
    showDeleteEmployeeModal: false,
    showAddBillingModal: false,
    showUpdateBillingModal: false,
    showDeleteBillingModal: false,
    showRecordsAddPatientModal: false,
    showAssignDoctorModal: false,
    showBookAppointmentModal: false,
    showGenerateCardModal: false,
    showGenerateInvoiceModal: false,
    showAddBirthRecordModal: false,
    showBirthRecordModal: false,
    showAddDeathRecordModal: false,
    showDeathRecordModal: false,
    showCreateWardModal: false,
    showUpdateWardModal: false,
    showDeleteWardModal: false,
    showAddBedSpaceModal: false,
    showUpdateBedSpaceModal: false,
    showDeleteBedSpaceModal: false,
    showWardInfoModal: false,
    showViewInvoiceModal: false,
    showSendSmsModal: false,
    showSendMailModal: false,
    updatePasswordModal: false,
    showWebCamModal: false,
    showCapturePhotoModal: false,
    showChangeDoctorModal: false,
    showRenewSubscriptionPlanModal: false,
    showPayMonthlyRemittanceModal: false,
    showPharmacistAddDrugCategoryModal: false,
    showPharmacistUpdateDrugCategoryModal: false,
    showPharmacistDeleteDrugCategoryModal: false,
    showPharmacistAddSupplyPointModal: false,
    showPharmacistUpdateSupplyPointModal: false,
    showPharmacistDeleteSupplyPointModal: false,
    showPharmacistAddDrugModal: false,
    showPharmacistUpdateDrugModal: false,
    showPharmacistViewDrugModal: false,
    showPharmacistDeleteDrugModal: false,
    showPharmacistBinCardModal: false,
    showPharmacistBinCardSummaryModal: false,
    showPharmacistIssueOutDrugModal: false,
    showPharmacistLendRefundDrugModal: false,
    showPharmacistDrugSupplyPointModal: false,
    showPharmacistDispenseDrugModal: false,
    showPharmacistViewPrescriptionModal: false,
    showPharmacistViewInvoiceModal: false,
    showPharmacistAssignSupplyPointModal: false,
    showPharmacistSupplyPointAssignStaffModal: false,
    showPharmacistSupplyPointStaffModal: false,
    showPharmacistGenerateMultipleInvoiceModal: false,
    showPharmacistDispenseMultipleInvoiceModal: false,
    showDoctorCreateTeamModal: false,
    showDoctorViewTeamModal: false,
    showDoctorUpdateTeamModal: false,
    showDoctorDeleteTeamModal: false,
    showDoctorUploadPatientFileModal: false,
    showDoctorViewPatientFileModal: false,
    showDoctorTransferPatientModal: false,
    showDoctorAddPrescriptionModal: false,
    showDoctorAddOtherPrescriptionModal: false,
    showDoctorAddTestModal: false,
    showDoctorAddOtherTestModal: false,
    showDoctorAddRadiologyTestModal: false,
    showDoctorAddOtherRadiologyTestModal: false,
    showDoctorRequestPatientAdmissionModal: false,
    showDoctorDischargePatientAdmissionModal: false,
    showDoctorCancelRequestPatientAdmissionModal: false,
    showDoctorBookAppointmentModal: false,
    showDoctorUpdateAppointmentModal: false,
    showDoctorViewLabTestModal: false,
    showDoctorWriteLabReportModal: false,
    showDoctorUploadOtherLabReportModal: false,
    showDoctorViewRadiologyTestModal: false,
    showDoctorWriteRadiologyReportModal: false,
    showDoctorUploadOtherRadiologyReportModal: false,
    showAddRadiologyEquipment: false,
    showUpdateRadiologyEquipment: false,
    showDeleteRadiologyEquipment: false,
    showRadiologyViewReportModal: false,
    showAddRadiologyInvestigationModal: false,
    showAddRadiologyInvestigationCategoryModal: false,
    showAddRadiologyInvestigationTypeModal: false,
    showUpdateRadiologyInvestigationModal: false,
    showViewRadiologyInvestigationModal: false,
    showRadiologyGenerateInvoiceModal: false,
    showViewRadiologyInvoice: false,
    showDeleteRadiologyCategoryTestModal: false,
    showUpdateRadiologyCategoryTestModal: false,
    showDeleteRadiologyInvestigationTypeModal: false,
    showUpdateRadiologyInvestigationTypeModal: false,
    showAddLaboratoryEquipmentModal: false,
    showUpdateLaboratoryEquipmentModal: false,
    showDeleteLaboratoryEquipmentModal: false,
    showLaboratoryGenerateInvoiceModal: false,
    showLaboratoryUploadReportModal: false,
    showLaboratoryViewReportModal: false,
    showViewLaboratoryInvoiceModal: false,
    showLaboratoryAddCategoryModal: false,
    showLaboratoryUpdateCategoryModal: false,
    showLaboratoryDeleteCategoryModal: false,
    showLaboratoryAddInvestigationType: false,
    showLaboratoryUpdateInvestigationType: false,
    showDeleteLaboratoryInvestigationTypeModal: false,
    showAddLaboratoryTextReportModal: false,
    showAddLaboratorySamplesModal: false,
    showUpdateLaboratorySamplesModal: false,
    showDeleteLaboratorySampleModal: false,
    showNurseAddPatientModal: false,
    showNurseAddPerformanceDataModal: false,
    showNurseAdministerOPDModal: false,
    showAdmitPatientModal: false,
    showAddFamilyPlanningRecords: false,
    showBookPatientForFamilyPlanningModal: false,
    showAddChildForImmunizationModal: false,
    showAddVaccinationRecordModal: false,
    showBookAntenatalModal: false,
    showAddPatientMedicalRecordModal: false,
    showAddPatientPregnancyRecordModal: false,
    showAddPatientVisitRecordModal: false,
    showAddPatientInvestigationRecordModal: false,
    showAddPatientLabourSummaryRecordModal: false,
    showDeleteLabourSummaryModal: false,
    showDeleteInvestigationRecordModal: false,
    showDeleteVisitRecordModal: false,
    showGenerateAntenatalCardModal: false,
    showViewVisitRecordModal: false,
    showAddAdmitedPatientDailyReportModal: false,
    showAddAdmitedPatientTreatmentReportModal: false,
    showTemperatureChartModal: false,
    showPulseChartModal: false,
    showBloodPressureChartModal: false,
    showRespiratoryChartModal: false,
    showVitalScienceChartModal: false,
    showNursesAddBirthRecordModal: false,
    showNursesAddDeathRecordModal: false,
    showNurseViewBirthRecordModal: false,
    showNurseViewDeathRecordModal: false,
    showViewPatientFollowUpRecordModal: false,
    showImmunizationCardModal: false,
    showFamilyPlanningCardModal: false,
    showPatientMedicalHistoryModal: false,
    showPatientLabourSummaryModal: false,
    showPatientFamilyPlanningCardModal: false,
    showPatientViewLabInvoiceModal: false,
    showPatientPayForInvoicesModal: false,
    showAccountsViewLabInvoiceModal: false,
    showAccountsViewDiscountInvoiceModal: false,
    showCreateScheduleModal: false,
    showEditAndDeleteScheduleModal: false,
    showListOfSchedulesByDateModal: false,
    showDeleteScheduleModal: false,
    showEditDeleteOptionalModal: false,
    showViewScheduleModal: false,
    showAccountsViewPharmacyInvoiceModal: false,
    showAccountsViewLabReportsModal: false,
    showAccountsViewPharmacyReportsModal: false,
    showAccountsViewRadiologyReportsModal: false,
    showAccountsRemitDiscountsModal: false,
    showAccountsViewRecordsReportsModal: false,
    showCollectionsLabPaymentModal: false,
    showCollectionsPharmacyPaymentModal: false,
    showCollectionRadiologyPaymentModal: false,
    showCollectionRecordsPaymentModal: false,
    showCollectionsViewLabInvoiceModal: false,
    showCollectionsViewPharmacyInvoiceModal: false,
    showCollectionsViewRadiologyInvoiceModal: false,
    showCollectionsViewRecordsInvoiceModal: false,
    showCollectionPrintInvoiceModal: false,
    showCollectionPrintMultipleReceiptModal: false,
    showCollectionPrintMultipleReceiptTypesModal: false,
    showCollectionsMultipleInvoiceTypePaymentModal: false,
    showCollectionsViewPharmacyReportsModal: false,
    showChatCreateGroupModal: false,
    showAddGroupParticipantModal: false,
    showViewWardReportModal: false,
    showCollectionsMultiplePaymentModal: false,
    showIncomingCallModal: false,
    //CreateVideoCallRoomModal
    showCreateRoomModal: false,
    showPatientMultiplePaymentModal: false,
    showCallNotification: false,
}

export const root__appstate = {
    showDrugInventory: false,
    showSupplyPoint: false,
    showManageBinCard: false,
    showDoctorManagePatient: false,
    showDoctorPatientDependants: false,
    showDoctorPerformNewDiagnosis: false,
    showDoctorCompleteDiagnosis: false,
    showSettingsGateway: false,
    showSettingsSms: false,
}

export const root__chatstate = {
    newChat: false,
    showVideoCallUI: true
}

export const root__opdstate = {
    showOpdTable: true,
    showPatientDiagnosisHistory: false,
    showDiagnosisPrescription: false,
    showFamilyPlanningFollowUpRecords: false,
    showChildImmunizationRecords: false,
    showPatientAntenatalRecords: false,
    showAdmittedPatientManagement: false,
}