import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { AdminVerifyPayStackPayment, Login, NotFound, UpdatePassword, UpdateProfile, PrivateRoute, Dashboard, ResetPassword, PatchPassword, ActivateAccount, CollectionsVerifyPayStackPayment, CollectionsVerifyFlutterWavePayment, PaymentVerification, FlutterwaveVerifyPayment, CollectionsVerifySquadCoPayment } from "./pages"


console.log(process.env.REACT_APP_API_ROOT)

const App = () => {

	return (
		<BrowserRouter>
			<ToastContainer />

			<Routes>
				{/* <Route path="" element={<ResetPassword />} /> */}
				<Route path="" element={<Login />} />
				<Route path="/resetpassword" element={<ResetPassword />} />
				<Route path="/patchpassword/:token" element={<PatchPassword />} />
				<Route path="/account/activate/:token/" element={<ActivateAccount />} />

				<Route path="/updatepassword" element={
					<PrivateRoute>
						<UpdatePassword />
					</PrivateRoute>
				} />

				<Route path="/updateprofile" element={
					<PrivateRoute>
						<UpdateProfile />
					</PrivateRoute>
				} />

				<Route path="/dashboard" element={
					<PrivateRoute>
						<Dashboard />
					</PrivateRoute>
				} />
				<Route path="/payment/verify" element={
					<PrivateRoute>
						<PaymentVerification />
					</PrivateRoute>
				} />

				<Route path="/flutter_wave_payment/verify/" element={
					<PrivateRoute>
						<FlutterwaveVerifyPayment />
					</PrivateRoute>
				} />


				<Route path="/zuumatek/payments_paystack/verify" element={
					<PrivateRoute>
						<AdminVerifyPayStackPayment />
					</PrivateRoute>
				} />


				<Route path="/collections/payments_paystack/verify" element={
					<PrivateRoute>
						<CollectionsVerifyPayStackPayment />
					</PrivateRoute>
				} />


				<Route path="/collections/payments_squadco/verify" element={
					<PrivateRoute>
						<CollectionsVerifySquadCoPayment />
					</PrivateRoute>
				} />

				<Route path="/collections/payments_flutterwave/verify" element={
					<PrivateRoute>
						<CollectionsVerifyFlutterWavePayment />
					</PrivateRoute>
				} />

				<Route path='*' element={<NotFound />} />
			</Routes>
		</BrowserRouter>
	)
}

export default App
