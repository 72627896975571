import React, { useState } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useStateContext } from '../../../state/context'
import { toast } from 'react-toastify'
import { AddBirthRecords, getABirthRecord, removeSingleBirthRecord, } from '../../../state/actions/records.actions'
import { setSingleBirthRecord } from '../../../state/actions/actions'
import { useEffect } from 'react'
import { addABirthRecord } from '../../../state/actions/nurse.actions'

const RecordsAddBirthModal = (props) => {
	const { modals, setModals } = useStateContext()
	const dispatch = useDispatch()

	const { show_birth_records, set_single_birth_record } = useSelector((state) => state.root)
	const { all_profiles } = useSelector((state) => state.nurse)
	const { single_birth_record } = useSelector((state) => state.records)

	const [newBornDetails, setNewBornDetails] = useState(false)
	const [searchButton, setSearchButton] = useState(true)

	const [formData, setFormData] = useState({
		patient: '',
		patient_number: '',
		first_name: '',
		last_name: '',
		guardian_name: '',
		address: '',
		date_of_birth: '',
		phone_number: '',
		time: '',
		id: '',
	})

	const handleChange = (e, name) => {
		setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		console.log(formData)
		dispatch(addABirthRecord({ formData, toast, setModals, modals }))

	}
	const filtered = all_profiles?.filter((item) => {
		return item.patient_number === `${formData.patient_number}`
	})[0]


	useEffect(() => {

		setFormData({
			...formData, patient_number: filtered?.patient_number, first_name: filtered?.first_name,
			last_name: filtered?.last_name, address: filtered?.address, gender: filtered?.gender,
			phone_number: filtered?.patient_phone, id: filtered?.user_id

		})

	}, [filtered])


	return (
		<div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
			<div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
				<div className='flex flex-col space-y-5'>
					<div className='flex justify-between items-center'>
						<h4 className='text-dark-green text-[20px] font-bold'>
							{modals.showBirthRecordModal
								? 'Birth Record'
								: 'Add Birth Record'}
						</h4>
						<FaTimesCircle
							className='text-dark-yellow cursor-pointer'
							onClick={() => {
								modals.showBirthRecordModal &&
									setModals({
										...modals,
										showBirthRecordModal:
											!modals.showBirthRecordModal,
									})
								modals.showAddBirthRecordModal &&
									setModals({
										...modals,
										showAddBirthRecordModal:
											!modals.showAddBirthRecordModal,
									})
								const data = null

								dispatch(removeSingleBirthRecord({ data }))
								setNewBornDetails(false)
							}}
							size={20}
						/>
					</div>
					{modals.showBirthRecordModal && (
						<form>
							<div>
								<p className='text-sm font-semibold'>Patient Number</p>
								<input
									type='text'
									name='patient_num'
									placeholder='Patient Number'
									value={show_birth_records?.patient_number}
									className='bg-slate-300 border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
									onChange={(e) =>
										handleChange(e, e.target.name)
									}
								/>
							</div>
							<div className='flex justify-between-items-center space-x-10'>
								<div className='space-y-5 w-full'>
									<div>
										<p className='text-sm font-semibold'>First name</p>
										<input
											type='text'
											name='first_name'
											value={
												show_birth_records?.first_name
											}
											placeholder='First name'
											className='border bg-slate-300 rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
											onChange={(e) =>
												handleChange(e, e.target.name)
											}
										/>
									</div>

									<div>
										<p className='text-sm font-semibold'>Last name</p>
										<input
											type='text'
											name='last_name'
											placeholder='Last name'
											value={
												show_birth_records?.last_name
											}
											className='border bg-slate-300 rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
											onChange={(e) =>
												handleChange(e, e.target.name)
											}
										/>
									</div>

									<div>
										<p className='text-sm font-semibold'>Date of birth</p>
										<input
											type='date'
											name='date_of_birth'
											placeholder='Date'
											value={show_birth_records?.date}
											className='border bg-slate-300 rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
											onChange={(e) =>
												handleChange(e, e.target.name)
											}
										/>
									</div>

									<div>
										<p className='text-sm font-semibold'>Time of birth</p>
										<input
											type='time'
											name='time'
											placeholder='Time'
											value={show_birth_records?.time}
											className='border bg-slate-300 rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
											onChange={(e) =>
												handleChange(e, e.target.name)
											}
										/>
									</div>
								</div>

								<div className='space-y-5 w-full'>
									<div>
										<p className='text-sm font-semibold'>Gender</p>
										<input
											type='text'
											name='gender'
											placeholder='Gender'
											value={show_birth_records?.gender}
											className='border bg-slate-300 rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
											onChange={(e) =>
												handleChange(e, e.target.name)
											}
										/>
									</div>
									<div>
										<p className='text-sm font-semibold'>Guardian phonenumber</p>
										<input
											type='number'
											name='phone_number'
											value={
												show_birth_records?.phone_number
											}
											placeholder='Phone number'
											className='border bg-slate-300 rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
											onChange={(e) =>
												handleChange(e, e.target.name)
											}
										/>
									</div>

									<div>
										<p className='text-sm font-semibold'>Address</p>
										<input
											type='text'
											name='address'
											placeholder='Address'
											value={show_birth_records?.address}
											className='border bg-slate-300 rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
											onChange={(e) =>
												handleChange(e, e.target.name)
											}
										/>
									</div>

									<div>
										<p className='text-sm font-semibold'>Guardian </p>
										<input
											type='text'
											name='guardian_name'
											value={show_birth_records?.guardian}
											placeholder='Guardian'
											className='border bg-slate-300 rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
											onChange={(e) =>
												handleChange(e, e.target.name)
											}
										/>
									</div>
								</div>
							</div>

						</form>
					)}

					{modals.showAddBirthRecordModal && (
						<form
							onSubmit={handleSubmit}
						>
							<div className='flex'>
								<input
									type='text'
									name='patient_number'
									placeholder='Enter patient Number'

									className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
									onChange={(e) =>
										handleChange(e, e.target.name)
									}
								/>



							</div>
							{filtered?.pkid && filtered?.patient_number && (<>

								<div className='flex justify-between-items-center space-x-10'>
									<div className='space-y-5 w-full'>
										<div>
											<input
												type='text'
												name='first_name'
												placeholder='First name'
												readOnly
												value={formData.first_name}
												onChange={(e) =>
													handleChange(
														e,
														e.target.name
													)
												}

												className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'

											/>
										</div>

										<div>
											<input
												type='text'
												name='last_name'
												placeholder='Last name'
												value={formData.last_name}
												readOnly

												className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
												onChange={(e) =>
													handleChange(
														e,
														e.target.name
													)
												}
											/>
										</div>

										<div>
											<p className='text-sm font-semibold'>Date of Birth</p>
											<input
												type='date'

												name='date_of_birth'
												placeholder='Date'
												className='border rounded w-full  h-5 px-4 py-5 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
												onChange={(e) =>
													handleChange(
														e,
														e.target.name
													)
												}
											/>
										</div>

										<div>
											<p className='text-sm font-semibold'>Time of Birth</p>
											<input
												type='time'
												name='time'
												placeholder='Time'

												className='border rounded w-full h-5 px-4 py-5 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
												onChange={(e) =>
													handleChange(
														e,
														e.target.name
													)
												}
											/>
										</div>
									</div>

									<div className='space-y-5 w-full'>
										<div>
											<input
												type='text'
												name='gender'
												placeholder='Gender'
												value={formData.gender}
												readOnly

												className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
												onChange={(e) =>
													handleChange(
														e,
														e.target.name
													)
												}
											/>
										</div>
										<div>
											<input
												type='number'
												name='phone_number'
												readOnly
												placeholder='Phone number'
												value={formData.phone_number}
												className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
												onChange={(e) =>
													handleChange(
														e,
														e.target.name
													)
												}
											/>
										</div>

										<div>
											<input
												type='text'
												name='address'
												placeholder='Address'
												value={formData.address}
												className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
												readOnly

											/>
										</div>

										<div>

											<input
												type='text'
												name='guardian_name'
												required
												placeholder='Enter Guardian name'
												className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
												onChange={(e) =>
													handleChange(
														e,
														e.target.name
													)
												}

											/>
										</div>
									</div>
								</div>



								<div className='mt-4 flex justify-center items-center'>
									<button
										type='submit'
										className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'

									>
										Submit
									</button>
								</div>
							</>)}

						</form>
					)}
				</div>
			</div>
		</div>
	)
}

export default RecordsAddBirthModal
