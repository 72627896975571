import React, { Fragment, useState } from 'react'
import 'tippy.js/dist/tippy.css'
import Tippy from '@tippyjs/react'
import { useDispatch } from 'react-redux'

import Pagination from '../utils/Pagination'
import PaymentStatus from '../utils/PaymentStatus'
import { useStateContext } from '../../state/context'
import { setCurrentAccountsInvoice, setCurrentAccountsPharmacyInvoice } from '../../state/actions/actions'


const AccountsAllInvoicesTable = ({ data, showList }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [currentData, setCurrentData] = useState([])

    return (
        <div className="flex justify-between items-center w-full">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="">
                    {showList && (
                        <Fragment>

                            <table className="border-b border-l border-r rounded w-full cursor-default">
                                <thead className="">
                                    <tr className='bg-gray-100'>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[2em] px-4 py-4 text-left"
                                        >
                                            S/N
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Patient ID
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Invoice ID
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Type
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Item
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Amount
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Date
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                        >
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData?.map((invoice, index) => (
                                        <Fragment key={`${invoice.id}`}>
                                            {invoice?.invoice_type === 'Prescription' && (
                                                <tr key={`${invoice.id}`}>
                                                    <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                                        {index + 1}
                                                    </td>
                                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        <Tippy content={`${invoice.patient_details?.last_name} ${invoice.patient_details?.first_name}`}>
                                                            <span>{invoice.patient_details.patient_number}</span>
                                                        </Tippy>
                                                    </td>
                                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        {invoice.invoice_number}
                                                    </td>
                                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        {invoice.invoice_type}
                                                    </td>
                                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        {invoice.prescription_details.drugs}
                                                    </td>
                                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        &#8358; {invoice.invoice_amount}
                                                    </td>
                                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        {invoice.created_at.slice(0, 10)}
                                                    </td>
                                                    <td className="flex justify-between space-x-5 text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        <PaymentStatus status={invoice.is_paid} />
                                                        <button
                                                            type="button"
                                                            className="inline-block px-2 py-2 bg-teal-500 text-white font-medium text-[10px] leading-tight uppercase shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                            onClick={() => {
                                                                dispatch(setCurrentAccountsPharmacyInvoice({ data: invoice }))
                                                                setModals({ ...modals, showAccountsViewPharmacyInvoiceModal: !modals.showAccountsViewPharmacyInvoiceModal })
                                                            }}
                                                        >
                                                            View
                                                        </button>
                                                    </td>
                                                </tr>
                                            )}
                                            {invoice?.invoice_type === 'Laboratory' && (
                                                <tr key={`${invoice.id}`}>
                                                    <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                                        {index + 1}
                                                    </td>
                                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        {/* {invoice.patient_number} */}
                                                        <Tippy content={`${invoice.patient_details?.last_name} ${invoice.patient_details?.first_name}`}>
                                                            <span>{invoice.patient_number}</span>
                                                        </Tippy>
                                                    </td>
                                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        {invoice.invoice_number}
                                                    </td>
                                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        {invoice.invoice_type}
                                                    </td>
                                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        {invoice.billing_type}
                                                    </td>
                                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        &#8358; {invoice.amount}
                                                    </td>
                                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        {invoice.date_generated}
                                                    </td>
                                                    <td className="flex justify-between items-center space-x-4 text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        <PaymentStatus status={invoice.is_paid} />

                                                        <button
                                                            type="button"
                                                            className="inline-block px-2 py-2 bg-teal-500 text-white font-medium text-[10px] leading-tight uppercase shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                            onClick={() => {
                                                                dispatch(setCurrentAccountsInvoice({ data: invoice }))
                                                                setModals({ ...modals, showAccountsViewLabInvoiceModal: !modals.showAccountsViewLabInvoiceModal })
                                                            }}
                                                        >
                                                            View
                                                        </button>
                                                    </td>
                                                </tr>
                                            )}
                                            {invoice?.invoice_type === 'Radiology' && (
                                                <tr key={`${invoice.id}`}>
                                                    <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                                        {index + 1}
                                                    </td>
                                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        {/* {invoice.patient_number} */}
                                                        <Tippy content={`${invoice.patient_details?.last_name} ${invoice.patient_details?.first_name}`}>
                                                            <span>{invoice.patient_number}</span>
                                                        </Tippy>
                                                    </td>
                                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        {invoice.invoice_number}
                                                    </td>
                                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        {invoice.invoice_type}
                                                    </td>
                                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        {invoice.billing_type}
                                                    </td>
                                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        &#8358; {invoice.amount}
                                                    </td>
                                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        {invoice.date_generated}
                                                    </td>
                                                    <td className="flex justify-between text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        <PaymentStatus status={invoice.is_paid} />
                                                        <button
                                                            type="button"
                                                            className="inline-block px-2 py-2 bg-teal-500 text-white font-medium text-[10px] leading-tight uppercase shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                            onClick={() => {
                                                                dispatch(setCurrentAccountsInvoice({ data: invoice }))
                                                                setModals({ ...modals, showAccountsViewLabInvoiceModal: !modals.showAccountsViewLabInvoiceModal })
                                                            }}
                                                        >
                                                            View
                                                        </button>
                                                    </td>
                                                </tr>
                                            )}
                                            {invoice?.invoice_type === 'Records' && (
                                                <tr key={`${invoice.id}`}>
                                                    <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                                        {index + 1}
                                                    </td>
                                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        {/* {invoice.patient_number} */}
                                                        <Tippy content={`${invoice.patient_details?.last_name} ${invoice.patient_details?.first_name}`}>
                                                            <span>{invoice.patient_number}</span>
                                                        </Tippy>
                                                    </td>
                                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        {invoice.invoice_number}
                                                    </td>
                                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        {invoice.invoice_type}
                                                    </td>
                                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        {invoice.billing_type}
                                                    </td>
                                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        &#8358; {invoice.amount}
                                                    </td>
                                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        {invoice.date}
                                                    </td>
                                                    <td className="flex justify-between text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                        <PaymentStatus status={invoice.is_paid} />

                                                        <button
                                                            type="button"
                                                            className="inline-block px-2 py-2 bg-teal-500 text-white font-medium text-[10px] leading-tight uppercase shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                            onClick={() => {
                                                                dispatch(setCurrentAccountsInvoice({ data: invoice }))
                                                                setModals({ ...modals, showAccountsViewLabInvoiceModal: !modals.showAccountsViewLabInvoiceModal })
                                                            }}
                                                        >
                                                            View
                                                        </button>
                                                    </td>
                                                </tr>
                                            )}
                                        </Fragment>
                                    ))}
                                </tbody>
                            </table>

                            <Pagination
                                data={data}
                                currentData={currentData}
                                setCurrentData={setCurrentData}
                            />
                        </Fragment>
                    )}
                </div>
            </div>
        </div >
    )
}

export default AccountsAllInvoicesTable
