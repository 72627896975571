import { createSlice } from '@reduxjs/toolkit'
import {
    getAllDirectorates, createDirectorate, getAllDepartments,
    createDepartment, createEmployee, getAllEmployees, getAllBillings,
    createBilling, getAllWards, createWard, getAllSentSMS,
    getAllReceivedSMS, sendAnSMS, sendAnEmailMessage,
    getAllSentEmails, getAllReceivedEmails, updateDirectorate,
    deleteDirectorate, updateDepartment, deleteDepartment, updateEmployee,
    deleteEmployee, updateBilling, deleteBilling, updateWard, deleteWard, addBedSpaceToWard,
    getAllBedspaces, createSmsConfig, updateSmsConfig, getSmsConfig, createHospitalConfig,
    getHospitalConfig, getWardBedSpaces, updateBedSpace, deleteBedSpace, fetchAllUsersByDepartment,
    setUsersByDeparmentToNull, getAllSchedules, createASchedule, createFlutterwaveConfig, updateHospitalConfig, updateHospitalLogo,
     getGatewaySettings, createGatewaySetting, updateGatewaySetting, deleteGatewaySetting, setCurrentAdminData, getSMSBalance, sendSmsTopupNotification, 
     getSubscriptionPlans, getSubscriptionPlan, setCurrentSubscriptionPlan, updateSubscriptionPlan, ugradeSubscriptionPlan, getMonthlyRemittances, setCurrentInvoice,
      updateMonthlyRemittance, getOpdPrescriptions, updateWebHookPayment, initializeZuumPayCredentials
    ,
} from '../actions/admin.actions'


const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        doctors: null,
        nurses: null,
        pharmacists: null,
        laboratorists: null,
        administrator: null,
        records: null,
        patients: null,
        radiologists: null,
        directorates: null,
        departments: null,
        employees: null,
        billings: null,
        wards: null,
        create_directorate: null,
        create_employee: null,
        create_department: null,
        getSentMessages: null,
        getReceivedMessages: null,
        create_billing: null,
        create_ward: null,
        sent_sms: null,
        sent_email: null,
        all_sent_emails: null,
        all_received_emails: null,
        addedBedSpace: null,
        updatedBedSpace: null,
        deletedBedSpace: null,
        bedspaces: null,
        new_schedule: null,
        sms_config: null,
        hospital_config: null,
        flutterwave_config: null,
        users_by_department: null,
        all_schedules: null,
        gateway_settings: null,
        sms_subscriptions: null,
        subscription_plan: null,
        subscription_plans: null,
        current_plan: null,
        monthly_remittances: null,
        current_invoice: null,
        opd_prescriptions: null,
        confirm_webhook_payment:null,
        adminErrors: null,
        adminLoading: false,
        adminRequestStatus: null,
    },
    extraReducers: {
        [getOpdPrescriptions.pending]: (state, action) => {
            state.adminLoading = true
        },
        [getOpdPrescriptions.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.opd_prescriptions = action.payload
        },
        [getOpdPrescriptions.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [getAllDirectorates.pending]: (state, action) => {
            state.adminLoading = true
        },
        [getAllDirectorates.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.directorates = action.payload
        },

        [getAllDirectorates.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [createDirectorate.pending]: (state, action) => {
            state.adminLoading = true
        },
        [createDirectorate.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.create_directorate = action.payload
            state.directorates = [...state.directorates, action.payload]
        },
        [createDirectorate.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [updateDirectorate.pending]: (state, action) => {
            state.adminLoading = true
        },
        [updateDirectorate.fulfilled]: (state, action) => {
            state.adminLoading = false
            const index = state.directorates.findIndex(directorate => directorate.id === action.payload.id)
            state.directorates[index] = action.payload
        },
        [updateDirectorate.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [deleteDirectorate.pending]: (state, action) => {
            state.adminLoading = true
        },
        [deleteDirectorate.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.directorates = state.directorates.filter(directorate => directorate.id !== action.payload)
        },
        [deleteDirectorate.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [getAllDepartments.pending]: (state, action) => {
            state.adminLoading = true
        },
        [getAllDepartments.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.departments = action.payload
        },
        [getAllDepartments.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [createDepartment.pending]: (state, action) => {
            state.adminLoading = true
        },
        [createDepartment.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.create_department = action.payload
            state.departments = [...state.departments, action.payload]
        },
        [createDepartment.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [updateDepartment.pending]: (state, action) => {
            state.adminLoading = true
        },
        [updateDepartment.fulfilled]: (state, action) => {
            state.adminLoading = false
            const index = state.departments.findIndex(department => department.id === action.payload.id)
            state.departments[index] = action.payload
        },
        [updateDepartment.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [deleteDepartment.pending]: (state, action) => {
            state.adminLoading = true
        },
        [deleteDepartment.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.departments = state.departments.filter(department => department.id !== action.payload)
        },
        [deleteDepartment.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [createEmployee.pending]: (state, action) => {
            state.adminLoading = true
            state.adminRequestStatus = "pending"
        },
        [createEmployee.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.adminRequestStatus = "success"
            state.create_employee = action.payload
            // state.create_employee = [...state.create_employee, action.payload]
        },
        [createEmployee.rejected]: (state, action) => {
            state.adminLoading = false
            state.adminRequestStatus = "failed"
        },

        [updateEmployee.pending]: (state, action) => {
            state.adminLoading = true
            state.adminRequestStatus = "pending"
        },
        [updateEmployee.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.adminRequestStatus = "success"
            const index = state.employees.findIndex(employee => employee.id === action.payload.id)
            state.employees[index] = action.payload
        },
        [updateEmployee.rejected]: (state, action) => {
            state.adminLoading = false
            state.adminRequestStatus = "failed"
        },

        [deleteEmployee.pending]: (state, action) => {
            state.adminLoading = true
            state.adminRequestStatus = "pending"
        },
        [deleteEmployee.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.adminRequestStatus = "success"
            state.employees = state.employees.filter(employee => employee.user_details?.id !== action.payload)
            // state.create_employee = action.payload
            // state.create_employee = [...state.create_employee, action.payload]
        },
        [deleteEmployee.rejected]: (state, action) => {
            state.adminLoading = false
            state.adminRequestStatus = "failed"
        },

        [getAllEmployees.pending]: (state, action) => {
            state.adminLoading = true
        },
        [getAllEmployees.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.employees = action.payload
            // state.create_employee= [...state.create_employee, action.payload]
        },
        [getAllEmployees.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [getAllBillings.pending]: (state, action) => {
            state.adminLoading = true
        },
        [getAllBillings.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.billings = action.payload
            // state.create_employee= [...state.create_employee, action.payload]
        },
        [getAllBillings.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [createBilling.pending]: (state, action) => {
            state.adminLoading = true
        },
        [createBilling.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.create_billing = action.payload
            state.billings = [...state.billings, action.payload]
        },
        [createBilling.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [updateBilling.pending]: (state, action) => {
            state.adminLoading = true
        },
        [updateBilling.fulfilled]: (state, action) => {
            state.adminLoading = false
            const index = state.billings.findIndex(billing => billing.id === action.payload.id)
            state.billings[index] = action.payload
        },
        [updateBilling.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [deleteBilling.pending]: (state, action) => {
            state.adminLoading = true
            state.adminRequestStatus = "pending"
        },
        [deleteBilling.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.adminRequestStatus = "success"
            state.billings = state.billings.filter(billing => billing.id !== action.payload)
        },
        [deleteBilling.rejected]: (state, action) => {
            state.adminLoading = false
            state.adminRequestStatus = "failed"
        },

        [getAllWards.pending]: (state, action) => {
            state.adminLoading = true
        },
        [getAllWards.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.wards = action.payload
            // state.create_billing= [...state.billings, action.payload]
        },
        [getAllWards.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [createWard.pending]: (state, action) => {
            state.adminLoading = true
        },
        [createWard.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.wards = [...state.wards, action.payload]
        },
        [createWard.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [updateWard.pending]: (state, action) => {
            state.adminLoading = true
        },
        [updateWard.fulfilled]: (state, action) => {
            state.adminLoading = false
            const index = state.wards.findIndex(ward => ward.id === action.payload.id)
            state.wards[index] = action.payload
        },
        [updateWard.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [deleteWard.pending]: (state, action) => {
            state.adminLoading = true
            state.adminRequestStatus = "pending"
        },
        [deleteWard.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.adminRequestStatus = "success"
            state.wards = state.wards.filter(ward => ward.pkid !== action.payload)
        },
        [deleteWard.rejected]: (state, action) => {
            state.adminLoading = false
            state.adminRequestStatus = "failed"
        },

        [getAllBedspaces.pending]: (state, action) => {
            state.adminLoading = true
        },
        [getAllBedspaces.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.bedspaces = action.payload
        },
        [getAllBedspaces.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [getWardBedSpaces.pending]: (state, action) => {
            state.adminLoading = true
        },
        [getWardBedSpaces.fulfilled]: (state, action) => {
            state.adminLoading = false
            // state.bedspaces = [...state.bedspaces, action.payload]   
        },
        [getWardBedSpaces.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [addBedSpaceToWard.pending]: (state, action) => {
            state.adminLoading = true
        },
        [addBedSpaceToWard.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.addedBedSpace = action.payload
            state.bedspaces = [...state.bedspaces, action.payload]
        },
        [addBedSpaceToWard.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [updateBedSpace.pending]: (state, action) => {
            state.adminLoading = true
            state.adminRequestStatus = "pending"
        },
        [updateBedSpace.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.adminRequestStatus = "success"
            state.updatedBedSpace = action.payload
            // state.bedspaces = [...state.bedspaces, action.payload]
        },
        [updateBedSpace.rejected]: (state, action) => {
            state.adminLoading = false
            state.adminRequestStatus = "failed"
        },

        [deleteBedSpace.pending]: (state, action) => {
            state.adminLoading = true
            state.adminRequestStatus = "pending"
        },
        [deleteBedSpace.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.adminRequestStatus = "bedspace__deleted"
            state.deletedBedSpace = action.payload
        },
        [deleteBedSpace.rejected]: (state, action) => {
            state.adminLoading = false
            state.adminRequestStatus = "failed"
        },

        [getAllSentSMS.pending]: (state, action) => {
            state.adminLoading = true
        },
        [getAllSentSMS.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.getSentMessages = action.payload
            // state.create_ward= [...state.create_ward, action.payload]
        },
        [getAllSentSMS.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [getAllReceivedSMS.pending]: (state, action) => {
            state.adminLoading = true
        },
        [getAllReceivedSMS.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.getReceivedMessages = action.payload
            // state.create_ward= [...state.create_ward, action.payload]
        },
        [getAllReceivedSMS.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [sendAnSMS.pending]: (state, action) => {
            state.adminLoading = true
        },
        [sendAnSMS.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.sent_sms = action.payload
            // state.create_ward= [...state.create_ward, action.payload]
        },
        [sendAnSMS.rejected]: (state, action) => {
            state.adminLoading = false
        },
        [sendAnEmailMessage.pending]: (state, action) => {
            state.adminLoading = true
        },
        [sendAnEmailMessage.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.sent_email = action.payload
            // state.sent_email= [...state.sent_email, action.payload]
        },
        [sendAnEmailMessage.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [getAllSentEmails.pending]: (state, action) => {
            state.adminLoading = true
        },
        [getAllSentEmails.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.all_sent_emails = action.payload
            // state.all_sent_emails = [...state.all_sent_emails, action.payload]
        },
        [getAllSentEmails.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [getAllReceivedEmails.pending]: (state, action) => {
            state.adminLoading = true
        },
        [getAllReceivedEmails.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.all_received_emails = action.payload
            // state.all_received_emails = [...state.all_received_emails, action.payload]
        },
        [getAllReceivedEmails.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [getSmsConfig.pending]: (state, action) => {
            state.adminLoading = true
        },
        [getSmsConfig.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.sms_config = action.payload[0]
        },
        [getSmsConfig.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [createSmsConfig.pending]: (state, action) => {
            state.adminLoading = true
        },
        [createSmsConfig.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.sms_config = action.payload
        },
        [createSmsConfig.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [updateSmsConfig.pending]: (state, action) => {
            state.adminLoading = true
        },
        [updateSmsConfig.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.sms_config = { ...action.payload }
        },
        [updateSmsConfig.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [createHospitalConfig.pending]: (state, action) => {
            state.adminLoading = true
        },
        [createHospitalConfig.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.hospital_config = action.payload
        },
        [createHospitalConfig.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [updateHospitalConfig.pending]: (state, action) => {
            state.adminLoading = true
        },
        [updateHospitalConfig.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.hospital_config = action.payload
        },
        [updateHospitalConfig.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [updateHospitalLogo.pending]: (state, action) => {
            state.adminLoading = true
        },
        [updateHospitalLogo.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.hospital_config = action.payload
        },
        [updateHospitalLogo.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [getHospitalConfig.pending]: (state, action) => {
            state.adminLoading = true
        },
        [getHospitalConfig.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.hospital_config = action.payload
        },
        [getHospitalConfig.rejected]: (state, action) => {
            state.adminLoading = false
            state.hospital_config = null
        },

        [getGatewaySettings.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getGatewaySettings.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.gateway_settings = action.payload
        },
        [getGatewaySettings.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createGatewaySetting.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createGatewaySetting.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.gateway_settings = [action.payload, ...state.gateway_settings]
        },
        [createGatewaySetting.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [updateGatewaySetting.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [updateGatewaySetting.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            const index = state.gateway_settings.findIndex(type => type.id === action.payload.id)
            state.gateway_settings[index] = action.payload
        },
        [updateGatewaySetting.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [deleteGatewaySetting.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [deleteGatewaySetting.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.gateway_settings = state.gateway_settings.filter(type => type.id !== action.payload)
        },
        [deleteGatewaySetting.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [fetchAllUsersByDepartment.pending]: (state, action) => {
            state.adminLoading = true
        },
        [fetchAllUsersByDepartment.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.users_by_department = action.payload
        },
        [fetchAllUsersByDepartment.rejected]: (state, action) => {
            state.adminLoading = false

        },
        [setUsersByDeparmentToNull.pending]: (state, action) => {
            state.adminLoading = true
        },
        [setUsersByDeparmentToNull.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.users_by_department = null
        },
        [setUsersByDeparmentToNull.rejected]: (state, action) => {
            state.adminLoading = false

        },
        [getAllSchedules.pending]: (state, action) => {
            state.adminLoading = true
        },
        [getAllSchedules.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.all_schedules = action.payload
        },
        [getAllSchedules.rejected]: (state, action) => {
            state.adminLoading = false

        },

        [createASchedule.pending]: (state, action) => {
            state.adminLoading = true
        },
        [createASchedule.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.new_schedule = true
        },
        [createASchedule.rejected]: (state, action) => {
            state.adminLoading = false

        },

        [createFlutterwaveConfig.pending]: (state, action) => {
            state.adminLoading = true
        },
        [createFlutterwaveConfig.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.flutterwave_config = true
        },
        [createFlutterwaveConfig.rejected]: (state, action) => {
            state.adminLoading = false
        },

        [setCurrentAdminData.pending]: (state, action) => {
            state.adminLoading = true
        },
        [setCurrentAdminData.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.current_admin_data = action.payload
        },
        [setCurrentAdminData.rejected]: (state, action) => {
            state.adminLoading = false
        },
     
        [getSMSBalance.pending]: (state, action) => {
            state.authLoading = true
        },
        [getSMSBalance.fulfilled]: (state, action) => {
            state.authLoading = false
            state.sms_subscriptions = action.payload
        },
        [getSMSBalance.rejected]: (state, action) => {
            state.authLoading = false
        },
               
        [sendSmsTopupNotification.pending]: (state, action) => {
            state.adminLoading = true
        },
        [sendSmsTopupNotification.fulfilled]: (state, action) => {
            state.adminLoading = false
        },
        [sendSmsTopupNotification.rejected]: (state, action) => {
            state.adminLoading = false
        },
               
        [getSubscriptionPlan.pending]: (state, action) => {
            state.adminLoading = true
        },
        [getSubscriptionPlan.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.subscription_plan = action.payload
        },
        [getSubscriptionPlan.rejected]: (state, action) => {
            state.adminLoading = false
        },
               
        [getSubscriptionPlans.pending]: (state, action) => {
            state.adminLoading = true
        },
        [getSubscriptionPlans.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.subscription_plans = action.payload
        },
        [getSubscriptionPlans.rejected]: (state, action) => {
            state.adminLoading = false
        },
               
        [setCurrentSubscriptionPlan.pending]: (state, action) => {
            state.adminLoading = true
        },
        [setCurrentSubscriptionPlan.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.current_plan = action.payload
        },
        [setCurrentSubscriptionPlan.rejected]: (state, action) => {
            state.adminLoading = false
        },
               
        [updateSubscriptionPlan.pending]: (state, action) => {
            state.adminLoading = true
        },
        [updateSubscriptionPlan.fulfilled]: (state, action) => {
            state.adminLoading = false
            // state.current_plan = action.payload
        },
        [updateSubscriptionPlan.rejected]: (state, action) => {
            state.adminLoading = false
        },
               
        [ugradeSubscriptionPlan.pending]: (state, action) => {
            state.adminLoading = true
        },
        [ugradeSubscriptionPlan.fulfilled]: (state, action) => {
            state.adminLoading = false
            // state.current_plan = action.payload
        },
        [ugradeSubscriptionPlan.rejected]: (state, action) => {
            state.adminLoading = false
        },
               
        [getMonthlyRemittances.pending]: (state, action) => {
            state.adminLoading = true
        },
        [getMonthlyRemittances.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.monthly_remittances = action.payload
        },
        [getMonthlyRemittances.rejected]: (state, action) => {
            state.adminLoading = false
        },
               
        [setCurrentInvoice.pending]: (state, action) => {
            state.adminLoading = true
        },
        [setCurrentInvoice.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.current_invoice = action.payload
        },
        [setCurrentInvoice.rejected]: (state, action) => {
            state.adminLoading = false
        },
               
        [updateMonthlyRemittance.pending]: (state, action) => {
            state.adminLoading = true
        },
        [updateMonthlyRemittance.fulfilled]: (state, action) => {
            state.adminLoading = false
            // state.current_invoice = action.payload
        },
        [updateMonthlyRemittance.rejected]: (state, action) => {
            state.adminLoading = false
        },
        [updateWebHookPayment.pending]: (state, action) => {
            state.adminLoading = true
        },
        [updateWebHookPayment.fulfilled]: (state, action) => {
            state.adminLoading = false
            state.confirm_webhook_payment = action.payload
        },
        [updateWebHookPayment.rejected]: (state, action) => {
            state.adminLoading = false
        },
     
        [initializeZuumPayCredentials.pending]: (state, action) => {
            state.adminLoading = true
        },
        [initializeZuumPayCredentials.fulfilled]: (state, action) => {
            state.adminLoading = false
        },
        [initializeZuumPayCredentials.rejected]: (state, action) => {
            state.adminLoading = false
        }
    }
})


export default adminSlice.reducer;