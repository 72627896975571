import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from "react-redux";

import { Logo } from '../../../assets'
import { useStateContext } from "../../../state/context";
import { remitNHISDiscounts } from "../../../state/actions/accountant.actions";



const AccountsRemitDiscountsModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { hospital_config } = useSelector(state => state.admin)
    const { current_discounts } = useSelector(state => state.accountant)

    const [total, setTotal] = useState(0)

    useEffect(() => {
        let total = 0
        current_discounts?.forEach(discount => {
            total += parseInt(discount.amount)
        })
        setTotal(total)
    }, [current_discounts])

    const handleSubmit = (e) => {
        e.preventDefault()

        dispatch(remitNHISDiscounts({ formData: { invoices: current_discounts }, toast, modals, setModals }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div
                className="absolute right-[4em] top-0 text-[60px] font-medium text-white cursor-pointer"
                onClick={() => {
                    setModals({ ...modals, showAccountsRemitDiscountsModal: !modals.showAccountsRemitDiscountsModal })
                }}
            >
                x
            </div>
            <div className="bg-white w-[500px] rounded-t">
                <div className="h-[5px] bg-indigo-800 rounded-t"></div>
                <div className="flex justify-between space-y-5 px-[10px]">
                    <div className="flex flex-col items-center space-y-2">
                        <img
                            src={hospital_config ? `${process.env.REACT_APP_API_ROOT}${hospital_config?.logo}` : Logo}
                            alt="logo"
                            className="w-[50px]"
                        />
                        <p className="text-[20px] text-gray-800 font-bold font-montserrat">{hospital_config?.name}</p>
                    </div>

                    <div>
                        <p className="text-[12px] text-gray-600 font-medium">
                            {hospital_config?.address}
                        </p>
                        <p className="text-[12px] text-gray-600 font-medium">
                            {hospital_config?.email}
                        </p>
                        <p className="text-[12px] text-gray-600 font-medium">

                        </p>
                    </div>
                </div>

                <div className="space-y-4 px-[10px] mt-5">
                    {/* <div className="flex justify-start space-x-4">
                        <p className="text-[12px] font-medium text-gray-500">
                            <span className="text-gray-800">Patient:</span> {current_discounts?.invoice_details.patient}
                        </p>
                        <p className="text-[12px] font-medium text-gray-500">
                            <span className="text-gray-800">Patient ID:</span> {current_discounts?.invoice_details.patient_nhis_id}
                        </p>
                    </div>
                    <div className="flex justify-start space-x-4">

                        <p className="text-[12px] font-medium text-gray-500">
                            <span className="text-gray-800">Type:</span> {current_discounts?.invoice_details.invoice_type}
                        </p>
                        <p className="text-[12px] font-medium text-gray-500">
                            <span className="text-gray-800">Date:</span> {current_discounts?.created_at}
                        </p>
                    </div> */}

                    <table className="rounded w-full cursor-default">
                        <thead className="">
                            <tr className='bg-yellow-100'>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pl-2 pr-[5em] py-2 text-left"
                                >
                                    Item
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[5em] py-2 text-left"
                                >
                                    Amount(#)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {current_discounts?.map((discount, index) => (
                                <tr>
                                    <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                        {discount?.invoice_details.invoice_item}
                                    </td>
                                    <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap bg-yellow-200">
                                        {discount?.amount}
                                    </td>
                                </tr>
                            ))}
                            {/* <tr>
                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                </td>
                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                </td>
                                <td className="border-t border-b text-[12px] text-gray-900 font-bold py-2 whitespace-nowrap bg-yellow-200">
                                    Subtotal: &#8358;
                                </td>
                                <td className="border-t border-b text-[12px] text-gray-900 font-medium underline underline-offset-1 py-2 whitespace-nowrap bg-yellow-200">
                                    {current_discounts?.amount}
                                </td>
                            </tr> */}
                            <tr>
                                <td className="border-t border-b text-[14px] text-white font-bold py-2 whitespace-nowrap bg-teal-900">
                                    Total:
                                </td>
                                <td className="border-t border-b text-[14px] text-white font-bold underline underline-offset-1 py-2 whitespace-nowrap bg-teal-900">
                                    {total}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="flex justify-end">
                        <button
                            onClick={handleSubmit}
                            className={`inline-block px-6 py-2.5 bg-teal-600 text-white font-medium text-xs leading-tight shadow-md hover:scale-[.98] transition duration-500 ease`}
                        >
                            Remit
                        </button>
                    </div>

                    <div className="flex flex-col place-content-end w-1/2 italic text-[10px]">
                        <p className="underline font-bold">Terms And Conditions.</p>
                        <p>All payments finalized have no return policy.</p>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default AccountsRemitDiscountsModal;
