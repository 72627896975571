/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import BirthRecordsTable from '../../components/tables/nursesTable/BirthRecordsTable'
import { PageTitle } from '../../components'
import DeathRecordTable from '../../components/tables/nursesTable/DeathRecordTable'
import { getAllProfiles, getAllBirthRecords } from '../../state/actions/nurse.actions'
import { useDispatch } from 'react-redux'

const NurseBirthAndDeathRecord = () => {
    const dispatch = useDispatch()
    
    const [showBirthRecordTable, setShowBirthRecordTable] = useState(true)

    useEffect(() => {
        dispatch(getAllProfiles())
        dispatch(getAllBirthRecords())
    }, [])

    return (
        <div>
            <PageTitle type={"nurse_birth_death_records"} showBirthRecordTable={showBirthRecordTable} setShowBirthTable={setShowBirthRecordTable} />

            <BirthRecordsTable />
        </div>
    )
}

export default NurseBirthAndDeathRecord