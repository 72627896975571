import { useEffect } from 'react'
import Lottie from 'lottie-react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import loadingJSON from '../assets/animations/searching__1.json'
import { updateLabInvoice, updatePharmacyInvoice, updateRadiologyInvoice, updateRecordsInvoice, verifyFlutterWavePayment } from '../state/actions/collection.actions'


const CollectionsVerifyFlutterWavePayment = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const options = {
        // renderer: 'svg',
        loop: true,
        // autoplay: true,
        animationData: loadingJSON,
        // rendererSettings: {
        //     preserveAspectRatio: 'xMidYMid slice',
        // },
    }

    const ref = window.location.href.split('transaction_id=')[1]
    const status = window.location.href.split('?status=')[1].split('&tx_ref=')[0]

    const { flutterwave_payment_status } = useSelector(state => state.collection)

    useEffect(() => {
        if (!ref && status === 'cancelled') {
            toast.info('Transaction cancelled')
            navigate('/dashboard')
        }

        if (ref && status === 'successful') {
            dispatch(verifyFlutterWavePayment({ params: { ref } }))
        }
    }, [ref])

    useEffect(() => {
        const payment__invoice = localStorage.getItem('payment__invoice') ? JSON.parse(localStorage.getItem('payment__invoice')) : null

        if (payment__invoice.type === 'laboratory_payment' && flutterwave_payment_status?.status === 'successful') {
            const formData = {
                id: payment__invoice?.id,
                mode_of_payment: flutterwave_payment_status?.payment_type
            }
            dispatch(updateLabInvoice({ formData, toast, navigate }))
        }

        if (payment__invoice.type === 'pharmacy_payment' && flutterwave_payment_status?.status === 'successful') {
            const formData = {
                id: payment__invoice?.id,
                mode_of_payment: flutterwave_payment_status?.payment_type
            }
            dispatch(updatePharmacyInvoice({ formData, toast, navigate }))
        }

        if (payment__invoice.type === 'radiology_payment' && flutterwave_payment_status?.status === 'successful') {
            const formData = {
                id: payment__invoice?.id,
                mode_of_payment: flutterwave_payment_status?.payment_type
            }
            dispatch(updateRadiologyInvoice({ formData, toast, navigate }))
        }

        if (payment__invoice.type === 'records_payment' && flutterwave_payment_status?.status === 'successful') {
            const formData = {
                id: payment__invoice?.id,
                mode_of_payment: flutterwave_payment_status?.payment_type
            }
            dispatch(updateRecordsInvoice({ formData, toast, navigate }))
        }
    }, [flutterwave_payment_status])

    return (
        <div className='h-screen flex justify-center items-center'>
            <Lottie options={options} width={1000} height={600} />
        </div>
    )
}

export default CollectionsVerifyFlutterWavePayment
