import React, { Fragment, useEffect, useState, useRef } from 'react'
import { FaTimesCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useStateContext } from '../../state/context';
import { AiFillVideoCamera, AiFillPhone, AiFillSetting } from 'react-icons/ai'
import ringtone from '../../assets/audio/ringtone.mp3'

const ShowIncomingCallModal = ({ activeLink, setActiveLink }) => {

    const { me, setMe, getUserMedia, setGetUserMedia, name, setName, callAccepted, myVideo, userVideo, callEnded, stream, call, answerCall, leaveCall, modals, setModals, chatState, setChatState } = useStateContext()
    const [ringTonePlaying, setRingTonePlaying] = useState(true)
    const audioRef = useRef(new Audio(ringtone));

    const offRingTone = () => {
        audioRef.current.pause()
        setRingTonePlaying(false)
    }
    useEffect(() => {
        audioRef.current[ringTonePlaying ? "play" : "pause"]()

    }, [ringTonePlaying])


    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[450px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className="text-dark-blue italic text-[16px] font-bold">Incoming call </h4>
                        {/* <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => setModals({ ...modals, showIncomingCallModal: !modals.showIncomingCallModal })}
                            size={20}
                        /> */}
                    </div>
                    <div className='' >
                        <div className="flex justify-center items-center">

                            {call?.isReceivingCall && !callAccepted && (
                                <div className='mx-auto  text-black text-[20px] italic w-full pl-2 mt-4 font-semibold'>{call?.name}  is calling</div>
                            )}



                        </div>

                        <div className='flex justify-between mt-2'>

                            <div>

                                {call.isReceivingCall && (
                                    <AiFillPhone
                                        size={50}
                                        className="cursor-pointer py-1 ml-4 text-white bg-red-600 rounded-md"
                                        style={{ 'transform': 'rotate(90deg)' }}
                                        onClick={() => {
                                            offRingTone()
                                            setModals({ ...modals, showIncomingCallModal: !modals.showIncomingCallModal });
                                            // leaveCall()
                                        }}
                                    />
                                )}
                            </div>
                            <div>
                                {call.isReceivingCall && !callAccepted && (
                                    <Fragment>
                                        <AiFillPhone
                                            size={50}
                                            className="cursor-pointer py-1 ml-4 text-white bg-green-600 rounded-md"
                                            style={{ 'transform': 'rotate(90deg)' }}
                                            onClick={() => {
                                                offRingTone()
                                                setActiveLink("Chat")
                                                setChatState({ ...chatState, showVideoCallUI: true })

                                                setModals({ ...modals, showIncomingCallModal: !modals.showIncomingCallModal })

                                                answerCall()


                                            }}
                                        />
                                    </Fragment>
                                )}

                            </div>
                        </div>
                    </div>




                </div>
            </div>
        </div>
    )
}

export default ShowIncomingCallModal