/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AiFillEye } from 'react-icons/ai'
import { FaPencilAlt, FaTrash } from 'react-icons/fa'

import Pagination from '../utils/Pagination'
import { useStateContext } from '../../state/context'
import { setCurrentTeam } from '../../state/actions/actions'


const DoctorTeamsTable = ({ data, showList, setShowList, title }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { user } = useSelector(state => state.auth)

    const [currentData, setCurrentData] = useState([])
    const [filterState, setFilterState] = useState({ search: '', teams: [] })

    const handleFilter = () => {
        const filtered = data?.filter(item => {
            return item.name.toLowerCase().includes(filterState.search.toLowerCase())
                || item.team_lead.last_name.toLowerCase().includes(filterState.search.toLowerCase())
                || item.team_lead.first_name.toLowerCase().includes(filterState.search.toLowerCase())
        })

        setFilterState({ ...filterState, teams: filtered })

        if (filterState.search === '') {
            setFilterState({ ...filterState, teams: data })
        }
    }

    useEffect(() => {
        setFilterState({ ...filterState, teams: data })
    }, [data])

    useEffect(() => {
        handleFilter()
    }, [filterState.search])


    return (
        <div className="flex justify-between items-center w-full">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="py-2">
                    <div
                        className="flex justify-start items-center space-x-4 rounded-t-lg"
                    // onClick={() => setShowList(!showList)}
                    >
                        <input
                            type="text"
                            name="search"
                            placeholder='Search'
                            className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                            onChange={(e) => setFilterState({ ...filterState, [e.target.name]: e.target.value })}
                        />
                    </div>
                    <Fragment>
                        <table className="border rounded w-full cursor-default">
                            <thead className="bg-gray-100">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] py-4 text-left"
                                    >
                                        Team Name
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Team Lead
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentData?.map((team, index) => (
                                    <tr key={`${team.id}`}>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {team.name}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {team.team_lead.last_name} {team.team_lead.first_name}
                                        </td>
                                        <td className=" flex text-2xl space-x-4 text-gray-900 font-semibold py-2 whitespace-nowrap">
                                            <AiFillEye
                                                size={20}
                                                className='text-gray-600 cursor-pointer'
                                                onClick={() => {
                                                    dispatch(setCurrentTeam({ data: team }))
                                                    setModals({ ...modals, showDoctorViewTeamModal: !modals.showDoctorViewTeamModal })
                                                }}
                                            />
                                            {user?.data?.is_department_head && (
                                                <Fragment>
                                                    <FaPencilAlt
                                                        size={20}
                                                        className='text-green-600 cursor-pointer'
                                                        onClick={() => {
                                                            dispatch(setCurrentTeam({ data: team }))
                                                            setModals({ ...modals, showDoctorUpdateTeamModal: !modals.showDoctorUpdateTeamModal })
                                                        }}
                                                    />
                                                    <FaTrash
                                                        onClick={() => {
                                                            dispatch(setCurrentTeam({ data: team }))
                                                            setModals({ ...modals, showDoctorDeleteTeamModal: !modals.showDoctorDeleteTeamModal })
                                                        }}
                                                        className="text-orange-400 cursor-pointer"
                                                        size={20}
                                                    />
                                                </Fragment>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <Pagination
                            data={filterState.teams}
                            currentData={currentData}
                            setCurrentData={setCurrentData}
                        />
                    </Fragment>
                </div>
            </div>
        </div >
    )
}

export default DoctorTeamsTable