/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DoctorSectionsSummary, InventorySummary, PageTitle } from '../../components'
import { getDashboardData } from '../../state/actions/doctor.actions'


const DoctorDashboard = () => {
	const dispatch = useDispatch()

	const { dashboard_data } = useSelector(state => state.doctor)

	useEffect(() => {
		dispatch(getDashboardData())
	}, [])

	return (
		<div>
			<PageTitle type={'doctor-dashboard'} />

			<DoctorSectionsSummary />


			<div className="flex flex-wrap gap-6 justify-between items-start mt-4">
				<InventorySummary
					title={'Diagnosis'}
					left_count={dashboard_data?.diagnosis}
					left_title={'Total number of diagnosis'}
					right_count={dashboard_data?.prescriptions}
					right_title={'Drugs Prescribed'}
				/>

				<InventorySummary
					title={'Prescriptions'}
					left_count={dashboard_data?.prescriptions}
					left_title={'Total number of prescriptions'}
				/>

				<InventorySummary
					title={'Admitted Patients'}
					left_count={dashboard_data?.admissions}
					left_title={'Total number of admitted patients'}
					// right_count={92}
					// right_title={'Patients discharged'}
				/>

				<InventorySummary
					title={'Appointments'}
					left_count={dashboard_data?.appointments}
					left_title={'Today'}
					right_count={dashboard_data?.tomorows_appointments}
					right_title={'Tommorow'}
				/>
			</div>
		</div>
	)
}

export default DoctorDashboard