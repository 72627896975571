import React, { useState } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useStateContext } from '../../../state/context'
import { addEquipment, updateARadiologyEquipment } from '../../../state/actions/radiologist.actions'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { setRadiologyEquipment } from '../../../state/actions/actions'

const RadiologyAddEquipmentModal = () => {
	const { modals, setModals } = useStateContext()
	const [formData, setFormData] = useState({
		id: '',
		title: '',
		type: '',
		status: '',


	})
	const { set_radiology_equipment } = useSelector((state) => state.root)
	const dispatch = useDispatch()
	const handleChange = (e, name) => { setFormData((prevState) => ({ ...prevState, [name]: e.target.value })) }

	const handleSubmit = (e) => {
		e.preventDefault()
		if (modals.showAddRadiologyEquipment) {
			if (!formData.title) return toast.warning('Title is required!')
			if (!formData.type) return toast.warning('Type is required!')
			if (!formData.status) return toast.warning('Status is required!')
			dispatch(addEquipment({ formData, toast, setModals: setModals, modals: modals }))
		}

		if (modals.showUpdateRadiologyEquipment) {
			dispatch(updateARadiologyEquipment({ formData, toast, setModals: setModals, modals: modals }))
		}

		console.log(formData);

	}
	useEffect(() => {
		if (modals.showUpdateRadiologyEquipment) {
			setFormData({
				...formData, id: set_radiology_equipment?.id,
				title: set_radiology_equipment?.title,
				type: set_radiology_equipment?.type,
				status: set_radiology_equipment?.status
			})
		}
	}, [set_radiology_equipment])

	return (
		<div>
			<div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
				<div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
					<div className='flex flex-col space-y-2'>
						<div className='flex justify-between items-center'>
							{modals.showAddRadiologyEquipment && (
								<>
									<h4 className='text-dark-green text-[20px] font-bold'>
										Add new equipment or reagent
									</h4>
									<FaTimesCircle
										className='text-dark-yellow cursor-pointer'
										onClick={() => (
											setModals({
												...modals,
												showAddRadiologyEquipment:
													!modals.showAddRadiologyEquipment,
											})
										)

										}
										size={20}
									/>
								</>
							)}
							{modals.showUpdateRadiologyEquipment && (
								<>
									<h4 className='text-dark-green text-[20px] font-bold'>
										Update equipment or reagent
									</h4>
									<FaTimesCircle
										className='text-dark-yellow cursor-pointer'
										onClick={() => {
											setModals({
												...modals,
												showUpdateRadiologyEquipment:
													!modals.showUpdateRadiologyEquipment,
											})
											dispatch(setRadiologyEquipment({ data: null }))
										}



										}
										size={20}
									/>
								</>
							)}
						</div>


						{modals.showUpdateRadiologyEquipment && (
							<form onSubmit={handleSubmit}>
								<div className='flex'>
									<input

										type='text'
										name='title'
										placeholder='Enter Name'
										value={formData.title}



										className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
										onChange={(e) =>
											handleChange(e, e.target.name)
										}
									/>


								</div>

								<div className='flex justify-between-items-center space-x-10'>

									<div className='space-y-5 w-full'>
										<h4 className='text-sm font-semibold pt-4'>Type</h4>
										<select name="type" placeholder='Type' id="" className='border rounded pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 '
											onChange={(e) =>
												handleChange(e, e.target.name)
											}
											value={formData.type}
										>
											<option value="">Select Type</option>
											<option value="Equipment">Equipment</option>
											<option value="Reagent">Reagent</option>

										</select>


										<div>
											<h4 className='text-sm font-semibold py-2'>Status</h4>
											<select name="status" placeholder='Type' id="" className='border rounded pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 '

												onChange={(e) =>
													handleChange(e, e.target.name)

												}
												value={formData.status}
											>
												<option value="">Select Status</option>
												<option value="Available">Available</option>
												<option value="Unavailable">Unavailable</option>
												<option value="Broken">Broken</option>
											</select>

										</div>

									</div>


								</div>



								<div className='mt-4 flex justify-center items-center'>
									<button
										type='submit'
										className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
									// onClick={handleSubmit}
									>
										Submit
									</button>
								</div>

							</form>
						)}

						{modals.showAddRadiologyEquipment && (
							<form onSubmit={handleSubmit}>
								<div className='flex justify-between items-center space-x-10'>
									<div className='w-full'>
										<h4 className='text-sm font-semibold pt-4'>Type</h4>
										<select name="type" placeholder='Type' id="" className='border rounded pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 '
											onChange={(e) =>
												handleChange(e, e.target.name)
											}
										>
											<option value="">Select Type</option>
											<option value="Equipment">Equipment</option>
											<option value="Reagent">Reagent</option>
										</select>

										<div>
											<h4 className='text-sm font-semibold'>Status</h4>
											<select name="status" placeholder='Type' id="" className='border rounded pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 '
												onChange={(e) =>
													handleChange(e, e.target.name)
												} >
												<option value="">Select Status</option>
												<option value="Available">Available</option>
												<option value="Unavailable">Unavailable</option>
												<option value="Broken">Broken</option>
											</select>

										</div>

									</div>


								</div>
								<div className='flex'>
									<input

										type='text'
										name='title'
										placeholder='Enter Name'
										className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
										onChange={(e) =>
											handleChange(e, e.target.name)
										}
									/>


								</div>




								<div className='mt-4 flex justify-center items-center'>
									<button
										type='submit'
										className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
									// onClick={handleSubmit}
									>
										Submit
									</button>
								</div>

							</form>
						)}

					</div>
				</div>

			</div>
		</div>
	)
}

export default RadiologyAddEquipmentModal