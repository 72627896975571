/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from 'react'
import { MdPanTool } from 'react-icons/md'
import { FaPencilAlt, FaTrash } from 'react-icons/fa'

import { PageTitle } from '../../components'
import { useStateContext } from '../../state/context'
import { useDispatch, useSelector } from 'react-redux'
import RadiologyPagination from './RadiologyPagination'
import { setRadiologyEquipment } from '../../state/actions/actions'
import { getAllRadiologistEquipment } from '../../state/actions/radiologist.actions'
import Pagination from '../../components/utils/Pagination'

const RadiologistEquipments = () => {
	const { modals, setModals } = useStateContext()
	const dispatch = useDispatch()
	const { all_radiologist_equipment } = useSelector((state) => state.radiologist)
	const { user } = useSelector(state => state.auth)

	const [currentData, setCurrentData] = useState([])

	useEffect(() => {
		dispatch(getAllRadiologistEquipment())
	}, [modals.showAddRadiologyEquipment, modals.showUpdateRadiologyEquipment, modals.showDeleteRadiologyEquipment])

	return (
		<div>
			<PageTitle type={"radiologist-equipments"} />

			<div className='flex flex-col w-full cursor-pointer'>
				<div className='py-2'>
					<div className='flex justify-start items-center space-x-4 bg-gray-200 p-1'>
						<h4 className='text-dark-blue text-[14px] font-bold'>
							Equipments
						</h4>
						<MdPanTool
							className='text-dark-blue'
							size={20}
						/>
						{/* <input type="text" name="filter" placeholder='Search equipment ' id="" className='ml-2 pl-4 text-sm rounded h-[30px] w-[400px]' onChange={(e) => handleChange(e, e.target.name)} /> */}
					</div>
					<table className='border rounded w-full cursor-default'>
						<thead className=''>
							<tr>
								<th
									scope='col'
									className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
								>
									S/N
								</th>

								<th
									scope='col'
									className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
								>
									Name
								</th>
								<th
									scope='col'
									className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
								>
									Type
								</th>
								<th
									scope='col'
									className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
								>
									Status
								</th>

								{user?.data?.is_department_head && <th
									scope='col'
									className='text-sm font-medium text-gray-900 py-4 text-left'
								>
									Actions
								</th>}
							</tr>
						</thead>
						<tbody>
							{currentData?.map((equipment, index) => (
								<tr
									className='relative'
									key={index}
								>
									<td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
										{index + 1}
									</td>
									<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
										{equipment.title}
									</td>
									<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
										{equipment.type}
									</td>
									<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
										{equipment.status}
									</td>
									{user?.data?.is_department_head && <td className=' text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
										<div className='flex space-x-4 relative'>
											< FaPencilAlt
												className='text-green-600 cursor-pointer '
												onClick={() => {
													const data = {
														id: equipment.id,
														title: equipment.title,
														type: equipment.type,
														status: equipment.status,

													}
													dispatch(setRadiologyEquipment({ data }))
													setModals({
														...modals,
														showUpdateRadiologyEquipment:
															!modals.showUpdateRadiologyEquipment,
													})
												}}

												size={20} />
											<FaTrash
												className='text-orange-400 cursor-pointer'
												onClick={() => {
													const data = {
														id: equipment.id,
														title: equipment.title,
														type: equipment.type,
														status: equipment.status,

													}
													dispatch(setRadiologyEquipment({ data }))
													setModals({
														...modals,
														showDeleteRadiologyEquipment:
															!modals.showDeleteRadiologyEquipment,
													})
												}}
												size={30} />

										</div>
									</td>}
								</tr>

							))}
						</tbody>
					</table>

					<Pagination
						data={all_radiologist_equipment}
						currentData={currentData}
						setCurrentData={setCurrentData}
					/>
				</div>
			</div>
		</div>
	)
}

export default RadiologistEquipments