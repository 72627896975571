import React, { useState, useEffect } from 'react'
import { useStateContext } from '../../../state/context'
import { FaTimesCircle } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { addAPatientVisitRecord } from '../../../state/actions/nurse.actions'

const NurseAddPatientVisitRecordModal = () => {
    const { modals, setModals } = useStateContext()
    const dispatch = useDispatch()
    const { set_patient_id } = useSelector((state) => state.root)

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))

    }

    const [formData, setFormData] = useState({
        height_fundus: '',
        weight: '',
        position_and_presentation: '',
        pcv: '',
        relation_presenting_part_of_brim: '',
        odema: '',
        foetal_heart: '',
        remark: '',
        blood_pressure: '',
        urine: '',
        return_date: '',
        id: '',



    })

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(formData);
        dispatch(addAPatientVisitRecord({ formData, toast, setModals, modals }))


    }

    useEffect(() => {
        setFormData({ ...formData, id: set_patient_id?.id })
    }, [])

    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>

                            <>
                                <h4 className='text-dark-green text-[12px] font-semibold'>
                                    Add Follow Up Record - {set_patient_id?.first_name} {set_patient_id?.last_name} [{set_patient_id?.patient_number}]
                                </h4>
                                <FaTimesCircle
                                    className='text-dark-yellow cursor-pointer'
                                    onClick={() => (
                                        setModals({
                                            ...modals,
                                            showAddPatientVisitRecordModal:
                                                !modals.showAddPatientVisitRecordModal,
                                        })
                                    )

                                    }
                                    size={20}
                                />
                            </>


                        </div>

                        <form onSubmit={handleSubmit} >
                            <div className='flex justify-between-items-center space-x-10'>
                                <div className='space-y-2 w-full'>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Height of fundus</p>
                                            <input
                                                type="title"
                                                name="height_fundus"


                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                        <div>
                                            <p className='text-sm font-semibold'>Weight</p>
                                            <input
                                                type="title"
                                                name="weight"


                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                    </div>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Presentaion & position</p>
                                            <input
                                                type="title"
                                                name="position_and_presentation"


                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>PCV</p>
                                            <input
                                                type="title"
                                                name="pcv"


                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                    </div>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Relation of Presenting Part of Brim</p>
                                            <input
                                                type="title"
                                                name="relation_presenting_part_of_brim"


                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Foetal heart</p>
                                            <input
                                                type="title"
                                                name="foetal_heart"


                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                    </div>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Blood Pressure</p>
                                            <input
                                                type="title"
                                                name="blood_pressure"


                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Odema</p>
                                            <input
                                                type="title"
                                                name="odema"


                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                    </div>

                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Remark</p>
                                            <input
                                                type="text"
                                                name="remark"


                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Urine</p>
                                            <input
                                                type="text"
                                                name="urine"



                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>


                                    </div>
                                    <div>
                                        <p className='text-sm font-semibold'>Return Date</p>
                                        <input
                                            type="date"
                                            name="return_date"

                                            className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            onChange={(e) => handleChange(e, e.target.name)}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className='mt-4 flex justify-center items-center'>
                                <button
                                    type='submit'
                                    className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'

                                >
                                    Submit
                                </button>
                            </div>

                        </form>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default NurseAddPatientVisitRecordModal