import { useState } from 'react'
import { useSelector } from 'react-redux'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'

import { Logo } from '../../assets'
import { menus__accounts__large__hospital, menus__accounts__small__hospital, menus__admin, menus__collections, menus__dentist, menus__doctor, menus__laboratorist, menus__nurse, menus__ophthalmologist, menus__otolaryngologist, menus__patient, menus__pharmacist, menus__pharmacist__departmentalhead, menus__physiotherapist, menus__radiologist, menus__records } from '../../data'

const SideBar = ({ activeLink, setActiveLink }) => {
	const [open, setOpen] = useState(true)

	const { user } = useSelector(state => state.auth)
	const { hospital_config } = useSelector(state => state.admin)

	return (
		<div className={`${open ? 'w-72' : 'w-20'} h-screen duration-500 bg-sidebar p-5 pt-4 sticky top-0 left-0`}>
			<BsFillArrowLeftCircleFill
				className={`${!open && 'rotate-180'} absolute cursor-pointer -right-1 top-9 shadow-sm`}
				color='white'
				size={20}
				onClick={() => setOpen(!open)}
			/>

			<div className='flex gap-x-4 items-center '>
				<img
					src={hospital_config ? `${process.env.REACT_APP_API_ROOT}${hospital_config?.logo}` : Logo}
					alt=""
					className={`cursor-pointer w-8 duration-500 ${open && 'rotate-[360deg]'}`}
				/>
				<h1 className={` ${!open && 'scale-0'} text-white origin-left font-medium text-[18px] duration-300`}>{hospital_config ? hospital_config?.name : 'Hospital'}</h1>
			</div>

			<ul className='pt-6 relative'>
				{user?.data?.is_administrator && menus__admin.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em] 
							cursor-pointer p-2 hover:bg-gray-500  mt-2
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							width={23}
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200`}>{menu.title === 'Admin Dashboard' ? 'Dashboard' : menu.title}</span>
					</li>
				))}

				{user?.data?.is_doctor && menus__doctor.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em] 
							cursor-pointer p-2 hover:bg-gray-500  mt-3"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							width={23}
						// className={`${menu.icon === 'icon__clinical__data' && 'w-[18px]'} ${menu.icon === 'icon__sms' && 'w-[18px]'}`}
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200`}>{menu.title === 'Doctor Dashboard' ? 'Dashboard' : menu.title === 'Doctor Clinical Data' ? 'Clinical Data' : menu.title}</span>
					</li>
				))}

				{user?.data?.is_ophthalmologists && menus__ophthalmologist.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em]
							cursor-pointer p-3 hover:bg-gray-500  mt-4"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							width={23}
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200`}>
							{
								menu.title === 'Ophthalmologist Dashboard' ? 'Dashboard' :
									menu.title === 'Ophthalmologist Team' ? 'Team' :
										menu.title === 'Ophthalmologist Manage Patients' ? 'Manage Patients' :
											menu.title === 'Ophthalmologist Assigned Patients' ? 'Assigned Patients' :
												menu.title === 'Ophthalmologist Appointments' ? 'Appointments' :
													menu.title === 'Ophthalmologist Admissions' ? 'Admissions' :
														menu.title === 'Doctor Clinical Data' ? 'Clinical Data' :
															menu.title
							}
						</span>
					</li>
				))}

				{user?.data?.is_otolaryngologists && menus__otolaryngologist.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em]
							cursor-pointer p-3 hover:bg-gray-500 m-4"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							width={23}
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200`}>
							{
								menu.title === 'Otolaryngologists Dashboard' ? 'Dashboard' :
									menu.title === 'Otolaryngologists Team' ? 'Team' :
										menu.title === 'Otolaryngologists Manage Patients' ? 'Manage Patients' :
											menu.title === 'Otolaryngologists Assigned Patients' ? 'Assigned Patients' :
												menu.title === 'Otolaryngologists Appointments' ? 'Appointments' :
													menu.title === 'Otolaryngologists Admissions' ? 'Admissions' :
														menu.title === 'Doctor Clinical Data' ? 'Clinical Data' :
															menu.title
							}
						</span>
					</li>
				))}

				{user?.data?.is_dentist && menus__dentist.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em]
							cursor-pointer p-3 hover:bg-gray-500 m-4"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							width={23}
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200`}>
							{
								menu.title === 'Dentist Dashboard' ? 'Dashboard' :
									menu.title === 'Dentist Team' ? 'Team' :
										menu.title === 'Dentist Manage Patients' ? 'Manage Patients' :
											menu.title === 'Dentist Assigned Patients' ? 'Assigned Patients' :
												menu.title === 'Dentist Appointments' ? 'Appointments' :
													menu.title === 'Dentist Admissions' ? 'Admissions' :
														menu.title === 'Doctor Clinical Data' ? 'Clinical Data' :
															menu.title
							}
						</span>
					</li>
				))}

				{user?.data?.is_physiotherapists && menus__physiotherapist.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em]
							cursor-pointer p-3 hover:bg-gray-500 m-4"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							width={23}
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200`}>
							{
								menu.title === 'Physiotherapist Dashboard' ? 'Dashboard' :
									menu.title === 'Physiotherapist Team' ? 'Team' :
										menu.title === 'Physiotherapist Manage Patients' ? 'Manage Patients' :
											menu.title === 'Physiotherapist Assigned Patients' ? 'Assigned Patients' :
												menu.title === 'Physiotherapist Appointments' ? 'Appointments' :
													menu.title === 'Physiotherapist Admissions' ? 'Admissions' :
														menu.title === 'Doctor Clinical Data' ? 'Clinical Data' :
															menu.title
							}
						</span>
					</li>
				))}

				{user?.data?.is_nurse && menus__nurse.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em] 
							cursor-pointer p-3 hover:bg-gray-500  mt-4"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							width={23}
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200`}>{menu.title === 'Nurse Dashboard' ? 'Dashboard' : menu.title === 'Birth Records' ? 'Birth Records' : menu.title}</span>
					</li>
				))}

				{user?.data?.is_pharmacist && !user?.data?.is_department_head && menus__pharmacist.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em] 
							cursor-pointer p-5 hover:bg-gray-500 mt-4"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							width={23}
						// className={`${menu.icon === 'icon__chat' && 'w-[18px]'} ${menu.icon === 'icon__sms' && 'w-[18px]'}`}
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200 text-[12px]`}>{menu.title === 'Pharmacist Dashboard' ? 'Dashboard' : menu.title}</span>
					</li>
				))}

				{user?.data?.is_pharmacist && user?.data?.is_department_head && menus__pharmacist__departmentalhead.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em] 
							cursor-pointer p-4 hover:bg-gray-500 mt-4"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							width={23}
						// className={`${menu.icon === 'icon__chat' && 'w-[18px]'} ${menu.icon === 'icon__sms' && 'w-[18px]'}`}
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200 text-[12px]`}>{menu.title === 'Pharmacist Dashboard' ? 'Dashboard' : menu.title}</span>
					</li>
				))}

				{user?.data?.is_radiologist && menus__radiologist.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em] 
							cursor-pointer p-4 hover:bg-gray-500  mt-4"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							width={23}
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200`}>{menu.title === 'Radiologist Dashboard' ? 'Dashboard' : menu.title === 'Radiology Equipments' ? 'Equipments' : menu.title === 'Radiology Reports' ? 'Reports' : menu.title === 'Radiology Investigations' ? 'Investigations' : menu.title === 'Radiology Invoices' ? 'Invoices' : menu.title === "Radiology Billings" ? "Billings" : menu.title}</span>
					</li>
				))}

				{user?.data?.is_laboratorist && menus__laboratorist.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em] 
							cursor-pointer p-3 hover:bg-gray-500  mt-4"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							width={23}
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200`}>{menu.title === 'Laboratorist Dashboard' ? 'Dashboard' : menu.title === 'Laboratory Equipments' ? 'Equipments' : menu.title === 'Laboratory Reports' ? 'Reports' : menu.title === 'Laboratory Investigations' ? 'Investigations' : menu.title === 'Laboratory Invoices' ? 'Invoices' : menu.title === 'Laboratory Billings' ? 'Billings' : menu.title} </span>
					</li>
				))}

				{user?.data?.is_records && menus__records.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em] 
							cursor-pointer p-4 hover:bg-gray-500  mt-4"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							width={23}
						// width={`${menu.icon === 'icon__file' ? 15 : 20}`} 
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200`}>{menu.title === 'Records Dashboard' ? 'Dashboard' : menu.title === 'Records Invoices' ? 'Invoices' : menu.title}</span>
					</li>
				))}

				{user?.data?.is_patient && menus__patient.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em] 
							cursor-pointer p-4 hover:bg-gray-500  mt-4"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							width={23}
						// width={`${menu.icon === 'icon__bills__dispense' ? 20 : menu.icon === 'icon__file' ? 20 : 25}`} 
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200`}>{menu.title === 'Patient Dashboard' ? 'Dashboard' : menu.title === 'Patient Appointments' ? 'Appointments' : menu.title === 'Patient Message' ? 'Patient Messages' : menu.title === 'Patient SMS' ? 'Patient SMS' : menu.title}</span>
					</li>
				))}

				{user?.data?.is_accountant && hospital_config?.hospital_size === 'Small' && menus__accounts__small__hospital.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em] 
							cursor-pointer p-4 mb-2 hover:bg-gray-500"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							width={23}
						// width={`${menu.icon === 'icon__report__2' ? 30 : menu.icon === 'icon__file' ? 20 : 25}`} 
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200`}>{menu.title === 'Accounts Dashboard' ? 'Dashboard' : menu.title === 'Accounts Invoices' ? 'Invoices' : menu.title === 'Accounts Reports' ? 'Reports' : menu.title === 'Accounts NHIS Discounts' ? 'Discounts' : menu.title === 'Accounts NHIS Paid Discounts' ? 'Paid Discounts' : menu.title === 'Accounts Paid Invoices' ? 'Paid Invoices' : menu.title}</span>
					</li>
				))}

				{user?.data?.is_accountant && hospital_config?.hospital_size === 'Big' && menus__accounts__large__hospital.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em] 
							cursor-pointer p-3 mb-5 hover:bg-gray-500"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							width={23}
						// width={`${menu.icon === 'icon__report__2' ? 30 : menu.icon === 'icon__file' ? 20 : 25}`} 
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200`}>{menu.title === 'Accounts Dashboard' ? 'Dashboard' : menu.title === 'Accounts Invoices' ? 'Invoices' : menu.title === 'Accounts Reports' ? 'Reports' : menu.title === 'Accounts NHIS Discounts' ? 'Discounts' : menu.title === 'Accounts NHIS Paid Discounts' ? 'Paid Discounts' : menu.title === 'Accounts Reports' ? 'Reports' : menu.title === 'Accounts Paid Invoices' ? 'Paid Invoices' : menu.title}</span>
					</li>
				))}

				{user?.data?.is_collections && menus__collections.map((menu, index) => (
					<li
						key={index}
						className={`
							text-gray-300 text-[12px]
							grid grid-cols-2 items-center space-x-[-4em] 
							cursor-pointer p-4 mb-5 hover:bg-gray-500  mt-4"
							${activeLink === menu.title && "bg-light-white"}
						`}
						onClick={() => setActiveLink(menu.title)}
					>
						<img
							src={menu.icon}
							alt=""
							width={23}
						// width={`${menu.icon === 'icon__bills__dispense' ? 20 : menu.icon === 'icon__file' ? 20 : 25}`} 
						/>
						<span className={`${!open && 'hidden'} uppercase font-medium origin-left duration-200`}>{menu.title === 'Collections Dashboard' ? 'Dashboard' : menu.title === 'Collections Invoices' ? 'Invoices' : menu.title === 'Collections Paid Invoices' ? 'Paid Invoices' : menu.title}</span>
					</li>
				))}
			</ul>
		</div>
	)
}

export default SideBar