import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RiBodyScanFill } from 'react-icons/ri'
import { GiFizzingFlask } from 'react-icons/gi'
import { GoFileSubmodule } from 'react-icons/go'
import { FaPencilAlt, FaTrash, FaUserMd, FaBed, FaUserNurse } from 'react-icons/fa'
import { MdLocalPharmacy, MdAccountBalanceWallet } from 'react-icons/md'

import { useStateContext } from '../../state/context'
import { setEmployee } from '../../state/actions/actions'
import Pagination from '../utils/Pagination'


const Icon = ({ title }) => (
    <Fragment>
        {title === 'Accountants' && <MdAccountBalanceWallet className="text-sidebar" size={20} />}
        {title === 'Doctors' && <FaUserMd className="text-sidebar" size={20} />}
        {title === 'Nurses' && <FaUserNurse className="text-sidebar" size={20} />}
        {title === 'Patients' && <FaBed className="text-sidebar" size={20} />}
        {title === 'Records' && <GoFileSubmodule className="text-sidebar" size={20} />}
        {title === 'Pharmacists' && <MdLocalPharmacy className="text-sidebar" size={20} />}
        {title === 'Radiologists' && <RiBodyScanFill className="text-sidebar" size={20} />}
        {title === 'Laboratorists' && <GiFizzingFlask className="text-sidebar" size={20} />}
    </Fragment>
)


const EmployeeTable = ({ data }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [_data, setData] = useState([])
    const [currentData, setCurrentData] = useState([])

    useEffect(() => {
        setData(data)
    }, [data])

    // const handleShowList = () => {
    //     title === 'Doctors' && setShowList({ ...showList, showDoctors: !showList.showDoctors })
    //     title === 'Nurses' && setShowList({ ...showList, showNurses: !showList.showNurses })
    //     title === 'Radiologists' && setShowList({ ...showList, showRadiologists: !showList.showRadiologists })
    //     title === 'Laboratorists' && setShowList({ ...showList, showLaboratorists: !showList.showLaboratorists })
    //     title === 'Patients' && setShowList({ ...showList, showPatients: !showList.showPatients })
    //     title === 'Records' && setShowList({ ...showList, showRecords: !showList.showRecords })
    //     title === 'Pharmacists' && setShowList({ ...showList, showPharmacists: !showList.showPharmacists })
    //     title === 'Accountants' && setShowList({ ...showList, showAccountants: !showList.showAccountants })
    // }

    return (
        <div className="flex justify-between items-center w-full">
            <div className="flex flex-col w-full cursor-pointer">
                <div className="">
                    {/* <div className="flex justify-start items-center space-x-4 bg-gray-200 p-1"
                        onClick={handleShowList}
                    >
                        <h4 className="text-sidebar text-[14px] font-bold">
                            {title}
                        </h4>
                        <Icon title={title} />
                    </div> */}
                    <Fragment>

                        <table className="border rounded w-full cursor-default">
                            <thead className="bg-gray-100">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left"
                                    >
                                        Staff ID
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Full name
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Email
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Phone
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Status
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >

                                    </th>
                                </tr>
                            </thead>
                            <tbody className=''>
                                {currentData?.map((item, index) => (
                                    <tr
                                        key={`${item.pkid}-${index}`}
                                        className={`${item.user_details.is_department_head && 'bg-purple-100'}`}
                                    >
                                        <td
                                            className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                                            value={item.pkid}
                                        >
                                            {index + 1}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-4 whitespace-nowrap">
                                            {item.first_name} {item.last_name} {item.user_details.is_department_head && '(Head)'}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-4 whitespace-nowrap">
                                            {item.email}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-4 whitespace-nowrap">
                                            {item.patient_phone != null ? item.patient_phone : "No phone yet"}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-4 whitespace-nowrap">
                                            {item.user_details.is_active ? "Active" : "Inactive"}
                                        </td>
                                        <td className={`flex text-2xl space-x-4 text-gray-900 font-semibold py-4 whitespace-nowrap`}>
                                            <FaPencilAlt
                                                size={20}
                                                className='text-green-600 cursor-pointer'
                                                onClick={() => {
                                                    dispatch(setEmployee({ data: item }))
                                                    setModals({ ...modals, showUpdateEmployeeModal: !modals.showUpdateEmployeeModal })
                                                }}
                                            />
                                            <FaTrash
                                                onClick={() => {
                                                    dispatch(setEmployee({ data: item }))
                                                    setModals({ ...modals, showDeleteEmployeeModal: !modals.showDeleteEmployeeModal })
                                                }}
                                                className="text-orange-400 cursor-pointer"
                                                size={20}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <Pagination
                            data={_data}
                            currentData={currentData}
                            setCurrentData={setCurrentData}
                        />
                    </Fragment>

                </div>
            </div>
        </div>
    )
}

export default EmployeeTable