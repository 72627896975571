import React from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { setPatientDiagnosisDetails, setPatientID } from '../../../state/actions/actions'
import { getAllPatientDiagnosis, getAllPatientPerformanceData } from '../../../state/actions/nurse.actions'


const OPDTable = (props) => {
    const { opdState, setOpdState } = useStateContext()
    const dispatch = useDispatch()
    const prescriptions = _.groupBy(props.data, prescription => prescription.patient_prescribed.id)

    return (
        <tbody>
            {Object.values(prescriptions)?.map((opd, index) => (
                <tr
                    className='relative'
                    key={index}
                >
                    <td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
                        {index + 1}
                    </td>
                    <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                        {opd[0].patient_profile.number}           </td>
                    <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                        {opd[0].diagnosis.diagnosed_patient.first_name} {opd[0].diagnosis.diagnosed_patient.last_name}
                    </td>
                    <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                        {opd[0].patient_profile.phone}
                    </td>
                    <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                        {opd[0].patient_profile.gender}
                    </td>
                    <td className=' text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                        <div className='flex space-x-4 relative '>
                            <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => {
                                    const data = {
                                        first_name: opd[0].diagnosis.diagnosed_patient.first_name,
                                        last_name: opd[0].diagnosis.diagnosed_patient.last_name,
                                        patient_number: opd[0].patient_profile.number,
                                        gender: opd[0].patient_profile.gender,
                                        blood_group: opd[0].patient_profile.blood_group,
                                        genotype: opd[0].patient_profile.genotype,
                                        photo: opd[0].patient_profile.photo,
                                        patient_pkid: opd[0].diagnosis.diagnosed_patient.pkid,
                                        dob: opd[0].patient_profile.dob
                                    }
                                    const patient_id = opd[0].diagnosis.diagnosed_patient.pkid
                                    const set_patient_id = opd[0].patient_prescribed.id

                                    dispatch(setPatientDiagnosisDetails({ data }))
                                    dispatch(getAllPatientDiagnosis({ patient_id }))


                                    dispatch(setPatientID({ data: set_patient_id }))
                                    dispatch(getAllPatientPerformanceData({ patient_id: set_patient_id }))

                                    setOpdState({ ...opdState, showOpdTable: false, showPatientDiagnosisHistory: true })
                                }}
                            >
                                Manage
                            </button>


                        </div>
                    </td>
                </tr>
            ))}
        </tbody>
    )
}

export default OPDTable