/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

import { payment__methods } from '../../../data'
import { useStateContext } from '../../../state/context'
import {
    createFlutterWaveConfig, createPayStackConfig, createSquadCoConfig, setCurrentRecordsInvoice,
    updateRecordsInvoiceViaCash, updateRecordsInvoiceViaPOSTransaction
} from '../../../state/actions/collection.actions'


const CollectionsRecordsPaymentMethodModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { data } = useSelector(state => state.auth.user)
    const {
        collectionLoading, flutterwave_config,
        records_invoice, paystack_config, squadco_config,
    } = useSelector(state => state.collection)
    const [formData, setFormData] = useState({ payment_method: '', email: '', amount: 0, phone: '', fullname: '', bank: '', account_number: '', payment_reference: '' })

    useEffect(() => {
        setFormData({
            ...formData,
            email: data?.email,
            amount: records_invoice?.amount,
            phone: '09030391123',
            fullname: data?.last_name.concat(' ').concat(data?.first_name)
        })
    }, [data, records_invoice])

    useEffect(() => {
        if (paystack_config?.authorization_url) {
            localStorage.setItem('payment__invoice', JSON.stringify({ ...records_invoice, type: 'records_payment' }))

            window.location.href = paystack_config?.authorization_url
        }
        if (squadco_config?.checkout_url) {
            localStorage.setItem('payment__invoice', JSON.stringify({ ...records_invoice, type: 'records_payment' }))

            window.location.href = squadco_config?.checkout_url
        }
        if (flutterwave_config?.link) {
            localStorage.setItem('payment__invoice', JSON.stringify({ ...records_invoice, type: 'records_payment' }))

            window.location.href = flutterwave_config?.link
        }
    }, [paystack_config, flutterwave_config, squadco_config])

    const handleChange = (name, e) => {
        setFormData({ ...formData, [name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        console.log(formData)

        if (formData.payment_method === 'paystack') {
            dispatch(createPayStackConfig({ formData }))
        }

        if (formData.payment_method === 'squadco') {
            dispatch(createSquadCoConfig({ formData }))
        }

        if (formData.payment_method === 'flutterwave') {
            dispatch(createFlutterWaveConfig({ formData }))
        }
    }

    const handlePOSTransaction = (e) => {
        e.preventDefault()
        const data = {
            id: records_invoice?.id,
            mode_of_payment: 'POS',
            ...formData
        }
        if (window.confirm('Are you sure you want to confirm the receipt for this transaction via pos.')) {
            dispatch(updateRecordsInvoiceViaPOSTransaction({ formData: { ...data }, toast, dispatch, setCurrentRecordsInvoice, modals, setModals }))
        }
    }

    const handleCashTransaction = (e) => {
        e.preventDefault()
        const formData = {
            id: records_invoice?.id,
            mode_of_payment: 'Cash'
        }
        if (window.confirm(`Please verify that you have received cash in the sum of #${records_invoice?.amount}.`)) {
            dispatch(updateRecordsInvoiceViaCash({ formData, toast, dispatch, setCurrentRecordsInvoice, modals, setModals }))
        }
    }


    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div
                className="absolute right-[4em] top-0 text-[60px] font-medium text-white cursor-pointer"
                onClick={() => {
                    dispatch(setCurrentRecordsInvoice({ data: null }))
                    setModals({ ...modals, showCollectionRecordsPaymentModal: !modals.showCollectionRecordsPaymentModal })
                }}
            >
                x
            </div>

            <div className="bg-white w-[500px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-2">
                    <div className="flex justify-between items-center">
                        <h4 className='text-sky-500 text-[20px] font-bold'>
                            {formData.payment_method ? formData.payment_method[0].toUpperCase().concat(formData.payment_method.slice(1, formData.payment_method.length)) : 'Payment Method'}
                        </h4>
                        {/* <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            size={20}
                        /> */}
                    </div>
                    <div className="flex flex-col items-end">
                        <div className="grid grid-cols-2">
                            <span className="text-gray-800 px-[10px] text-[10px] font-bold py-[1px] rounded">Invoice ID:</span>
                            <span className="text-gray-600 px-[10px] text-[10px] font-medium py-[1px] underline underline-offset-1 rounded">{records_invoice?.invoice_number}</span>
                            <span className="text-gray-800 px-[10px] text-[10px] font-bold py-[1px] rounded">Patient ID:</span>
                            <span className="text-gray-600 px-[10px] text-[10px] font-medium py-[1px] rounded">{records_invoice?.patient_number}</span>
                            <span className="text-gray-800 px-[10px] text-[10px] font-bold py-[1px] rounded">Invoice Amount:</span>
                            <span className="text-gray-800 px-[10px] text-[10px] font-medium py-[1px] rounded">&#8358;{records_invoice?.amount}</span>
                        </div>
                    </div>


                    {formData.payment_method !== 'cash' && formData.payment_method !== 'pos' && (
                        <form onSubmit={handleSubmit}>
                            <div className='space-y-2'>
                                <div className="relative">
                                    <select
                                        className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-1 px-4 pr-8 rounded leading-tight focus:outline-none "
                                        name="payment_method"
                                        onChange={(e) => handleChange(e.target.name, e)}
                                    >
                                        <option>Payment Method...</option>
                                        {payment__methods?.map((type, index) => (
                                            <option
                                                key={`${type.id}-${index}`}
                                                value={type.value}
                                            >
                                                {type.title}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg
                                            className="fill-current h-4 w-4"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                        >
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-start mt-4'>
                                <button
                                    type='submit'
                                    className="inline-block w-full px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded-full shadow-md hover:scale-90 transition duration-500 ease"
                                >
                                    {!collectionLoading ? (
                                        'Submit'
                                    ) : (
                                        <svg className="flex justify-center w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
                                            viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                                            </path>
                                        </svg>
                                    )}
                                </button>
                            </div>
                        </form>
                    )}

                    {formData.payment_method === 'pos' && (
                        <div>
                            <form onSubmit={handlePOSTransaction}>
                                <div className='space-y-2'>
                                    <div>
                                        {/* <label className="bg-gray-600 text-white px-[5px] py-[1px] text-[10px] rounded">Bank</label> */}
                                        <input
                                            type="text"
                                            name='bank'
                                            placeholder='Bank'
                                            className="border rounded w-full h-5 px-4 py-5 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            onChange={(e) => handleChange(e.target.name, e)}
                                        />
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            name='account_number'
                                            placeholder='Account Number'
                                            className="border rounded w-full h-5 px-4 py-5 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            onChange={(e) => handleChange(e.target.name, e)}
                                        />
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            name='payment_reference'
                                            placeholder='Payment Refrence'
                                            className="border rounded w-full h-5 px-4 py-5 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            onChange={(e) => handleChange(e.target.name, e)}
                                        />
                                    </div>
                                </div>
                                <div className='flex justify-start mt-4'>
                                    <button
                                        type='submit'
                                        className="inline-block w-full px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                    >
                                        {!collectionLoading ? (
                                            'Confirm Transaction Receipt Via POS'
                                        ) : (
                                            <svg className="flex justify-center w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor"
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                                                </path>
                                            </svg>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}

                    {formData.payment_method === 'cash' && (
                        <div>
                            <form onSubmit={handleCashTransaction}>
                                <div className='mt-4'>
                                    <p className="text-left">Ensure you collect the above amount for this invoice before processing this <strong className='italic'>transaction!</strong> </p>
                                </div>
                                <div className='flex justify-start mt-4'>
                                    <button
                                        type='submit'
                                        className="inline-block w-full px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                    >
                                        {!collectionLoading ? (
                                            'Confirm Transaction Via Cash'
                                        ) : (
                                            <svg className="flex justify-center w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor"
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                                                </path>
                                            </svg>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
export default CollectionsRecordsPaymentMethodModal