import React, { useState, useEffect } from 'react'
import { Doughnut } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2'
import { FaTimesCircle } from 'react-icons/fa'
import { useStateContext } from '../../../state/context';
import { useSelector } from 'react-redux';
import { Chart as ChartJs, Title, Tooltip, LineElement, Legend, CategoryScale, LinearScale, PointElement } from 'chart.js'
ChartJs.register(
    Title, Tooltip, LineElement, Legend,
    CategoryScale, LinearScale, PointElement
)

const PatientTemperatureChartModal = () => {
    const { modals, setModals } = useStateContext()
    const { all_patient_performanceData } = useSelector((state) => state.nurse)
    const temperature_data = []
    const date_data = []
    const getTemperatureData = () => {
        all_patient_performanceData?.map((item, index) => {
            let temperature = parseInt(item.temperature)
            temperature_data.push(temperature)
        })
    }
    const getDateData = () => {
        all_patient_performanceData?.map((item, index) => {
            let date = item.date
            date_data.push(date)
        })
    }
    console.log(temperature_data);
    const [data, setData] = useState({
        labels: date_data,
        datasets: [
            {
                label: "Temperature (C)",
                data: temperature_data,
                backgrounColor: "yellow",
                borderColor: "blue",
                tension: 0.4,
                fill: true,

            }]
    })

    useEffect(() => {

        getTemperatureData()
        getDateData()
    }, [])

    return (
        <>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] h-[500px] w-[800px]'>
                    <h1 className='font-semibold text-blue-400'>Temperature Chart</h1>
                    <div className='flex justify-end'>
                        <p></p>
                        <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => (
                                setModals({
                                    ...modals,
                                    showTemperatureChartModal:
                                        !modals.showTemperatureChartModal,
                                })
                            )

                            }
                            size={20}
                        />
                    </div>

                    <div className='flex flex-col space-y-5 justify-end'>

                        <div className='flex justify-between items-center'>

                            <Line data={data}>PatientTemperatureChartModal</Line>

                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

export default PatientTemperatureChartModal