import { React, useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { FaTimesCircle } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'

import { API } from '../../../state/config'
import { useStateContext } from '../../../state/context'
import { setWardReport } from '../../../state/actions/actions'
import { WriteWardReport } from '../../../state/actions/records.actions'


const RecordsWardModal = () => {
	const dispatch = useDispatch()
	const [formData, setFormData] = useState({
		ward: '',
		report: '',
	})

	const { modals, setModals } = useStateContext()
	const { user } = useSelector((state) => state.auth)
	const { ward_report } = useSelector((state) => state.root)

	const [bedSpaces, setBedSpaces] = useState(null)

	useEffect(() => {
		const fetchBeds = async () => {
			const { data } = await API.get(`/administrator/bed_space/${ward_report?.name}/`, { headers: { Authorization: `Bearer ${user?.access}` } })
			return setBedSpaces([...data])
		}
		if (ward_report?.name) {
			fetchBeds()
		}
	}, [ward_report?.name])

	useEffect(() => {
		setFormData({ ...formData, ward: ward_report?.pkid })
	}, [ward_report])

	const handleChange = (e, name) => {
		setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		dispatch(WriteWardReport({ formData, toast, setModals, modals, dispatch, setWardReport }))
	}

	return (
		<div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
			<div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
				<div className='flex flex-col space-y-5'>
					<div className='flex justify-between items-center'>
						<h4 className='text-dark-green text-[20px] font-bold'>
							{ward_report?.name}
						</h4>
						<FaTimesCircle
							className='text-dark-yellow cursor-pointer'
							onClick={() => {
								dispatch(setWardReport({ data: null }))
								setModals({ ...modals, showWardInfoModal: !modals.showWardInfoModal })
							}}
							size={20}
						/>
					</div>

					<div className='flex flex-col justify-between space-y-2 w-full bg-white border-[1px] border-gray-100 rounded p-2 max-h-[200px] overflow-auto ward__list'>
						{bedSpaces?.map((bedspace => (
							<div key={`${bedspace.id}`} className='flex justify-between items-center rounded bg-gray-100 px-2 py-1'>
								<p className='text-[10px] text-gray-600'>Bed {bedspace.bed_number}</p>
								<p className={`text-[10px] text-white rounded px-2 py-1 ${bedspace.allocated ? 'bg-indigo-500' : 'bg-green-500'}`}>
									{bedspace.allocated ? 'Occupied' : 'Available'}
								</p>
							</div>
						)))}
					</div>

					<form>
						<div className='flex justify-between items-center space-x-10'>
							<div className='w-full'>
								<textarea
									name='report'
									placeholder='Write report'
									cols='30'
									rows='3'
									onChange={(e) =>
										handleChange(e, e.target.name)
									}
									className='resize-none border rounded w-full px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
								></textarea>
							</div>
						</div>

						<div className='mt-4 flex justify-center items-center'>
							<button
								type='submit'
								className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
								onClick={handleSubmit}
							>
								Save Report
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}

export default RecordsWardModal
