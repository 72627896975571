import React, { useState, useEffect } from 'react'
import { useStateContext } from '../../../state/context'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { uploadAPatientLaboratoryReport } from '../../../state/actions/laboratorist.actions'
import { toast } from 'react-toastify'
import { setLaboratoryReport } from '../../../state/actions/actions'
import { setRadiologyGenerateInvoice } from '../../../state/actions/actions'

const LaboratoryUploadReport = () => {
    const dispatch = useDispatch()
    const { set_laboratory_reports } = useSelector((state) => state.root)
    const { modals, setModals } = useStateContext()
    const [formData, setFormData] = useState({
        id: '',
        report_id: '',
        image_report: {},
        photo_display: '',
        text_report: ''



    })
    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))

    }


    const handleSubmit = (e) => {
        e.preventDefault()


        dispatch(uploadAPatientLaboratoryReport({ formData, toast, setModals: setModals, modals: modals }))

    }
    const handleImageChange = (e) => {

        setFormData({ ...formData, image_report: e.target.files[0], photo_display: URL.createObjectURL(e.target.files[0]) })

    };

    useEffect(() => {
        setFormData({
            ...formData, report_id: set_laboratory_reports?.report_id,

        })
    }, [set_laboratory_reports])

    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>


                            {modals.showLaboratoryUploadReportModal && (
                                <>
                                    <h4 className='text-dark-green text-[16px] font-bold'>
                                        Upload reports - {set_laboratory_reports?.first_name} {set_laboratory_reports?.last_name} [{set_laboratory_reports?.patient_no}]
                                    </h4>
                                    <FaTimesCircle
                                        className='text-dark-yellow cursor-pointer'
                                        onClick={() => {
                                            dispatch(setRadiologyGenerateInvoice({ data: null }))
                                            setModals({
                                                ...modals,
                                                showLaboratoryUploadReportModal:
                                                    !modals.showLaboratoryUploadReportModal,
                                            })
                                        }


                                        }
                                        size={20}
                                    />
                                </>
                            )}
                            {modals.showLaboratoryViewReportModal && (
                                <div className='flex flex-col'>
                                    <div className='flex justify-between'>
                                        <h4 className='text-dark-green text-[15px] font-bold'>
                                            Investigation - {set_laboratory_reports?.first_name} {set_laboratory_reports?.last_name} [{set_laboratory_reports?.patient_no}]
                                        </h4>
                                        <FaTimesCircle
                                            className='text-dark-yellow cursor-pointer'
                                            onClick={() => {

                                                dispatch(setLaboratoryReport({ data: null }))
                                                setModals({
                                                    ...modals,
                                                    showLaboratoryViewReportModal:
                                                        !modals.showLaboratoryViewReportModal,
                                                })
                                            }

                                            }
                                            size={20}
                                        />
                                    </div>
                                    <div className='mt-2'>
                                        {modals.showLaboratoryViewReportModal && (
                                            <>
                                                <div className='w-[500px] h-[200px] ml-4 border rounded-lg bg-slate-300'>
                                                    <p className='text-lg font-light ml-2 my-2'> Investgiation Category: {set_laboratory_reports?.test_cat}</p>
                                                    <p className='text-lg font-light ml-2 my-2'> Investgiation Type: {set_laboratory_reports?.test_type}</p>
                                                    <p className='text-lg font-light ml-2 my-2'> Investgiation Samples: {set_laboratory_reports?.test_sample}</p>

                                                </div>

                                                {/* <img src={`http://localhost:8000${set_radiology_view_report?.image}`} alt="" className='rounded-md' /> */}
                                            </>
                                        )}

                                    </div>
                                </div>

                            )}





                        </div>
                        {formData.photo_display.length > 0 && (
                            <img src={formData.photo_display} alt="" srcset="" className='h-[200px] w-full' />
                        )}
                        {modals.showLaboratoryUploadReportModal && (

                            <form onSubmit={handleSubmit} >
                                <div className=''>
                                    <input
                                        type='file'

                                        placeholder='Upload'
                                        name="image_report"


                                        className='border rounded h-[50px] mb-4 w-full px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
                                        onChange={handleImageChange}
                                    />
                                    <textarea className='w-full mt-1 border pt-4 pl-2' name="text_report" id="" cols="30" rows="5" placeholder='Add test report or test conducted'
                                        onChange={(e) =>
                                            handleChange(e, e.target.name)
                                        }
                                    >

                                    </textarea>
                                </div>


                                <div className='mt-4 flex justify-center items-center'>
                                    <button
                                        type='submit'
                                        className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'

                                    >
                                        Submit
                                    </button>
                                </div>

                            </form>

                        )}


                    </div>
                </div>
            </div>
        </div>
    )
}

export default LaboratoryUploadReport