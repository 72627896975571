import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import * as XLSX from 'xlsx'
import { FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import { useStateContext } from "../../../state/context"
import PaymentStatus from "../../utils/PaymentStatus"
import { AiOutlineFileExcel, AiOutlineFilePdf } from "react-icons/ai"
import { getHospitalConfig } from "../../../state/actions/admin.actions"


const AccountsViewLaboratoryReportsModal = (props) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()
    const { laboratory_reports } = useSelector(state => state.accountant)
    const { user } = useSelector(state => state.auth)
    const { hospital_config } = useSelector(state => state.admin)
    const [logobase64, setLogobase64] = useState('')

    useEffect(() => {
        dispatch(getHospitalConfig())
    }, [])

    const getBase64FromUrl = async (url) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                setLogobase64(base64data)
                resolve(base64data);
            }
        });
    }

    const exportXLSX = () => {
        let json_data = []
        laboratory_reports?.forEach((invoice, index) => {
            const tableData = {
                'S/N': (index + 1),
                'Patient ID': invoice.patient_number,
                'Invoice ID': invoice.invoice_number,
                'Type': invoice.billing_type,
                'Amount': invoice.amount,
                'Date': invoice.date_generated,
                'Payment Author': invoice.updated_by,
                'Status': invoice.is_paid ? 'Paid' : 'Pending'
            }

            json_data.push(tableData)
        })

        console.log(json_data)
        const wb = XLSX.utils.book_new()
        const ws = XLSX.utils.json_to_sheet(json_data)

        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1')
        XLSX.writeFile(wb, `Daily Report - ${laboratory_reports[0]?.created_at}.xlsx`)
    }
    useEffect(() => {
        const logo = `${process.env.REACT_APP_API_ROOT}${hospital_config?.logo}`

        getBase64FromUrl(logo)
    }, [hospital_config])


    const exportPdf = (reports) => {
        const doc = new jsPDF({
            orientation: 'landscape'
        })
        doc.addImage(logobase64, 'JPEG', 15, 4, 8, 8)
        let total = 0
        const tableRows = []
        const tableColumns = ['S/N', 'Patient ID', 'Invoice ID', 'Type', 'Amount', 'Date', 'Author', 'Status', 'Total']

        laboratory_reports?.forEach((invoice, index) => {
            const tableData = [
                (index + 1),
                invoice.patient_number,
                invoice.invoice_number,
                invoice.billing_type,
                invoice.amount,
                invoice.date_generated,
                invoice.updated_by,
                invoice.is_paid ? 'Paid' : 'Pending'
            ]

            total += parseInt(invoice.amount)
            tableRows.push(tableData)
        })

        const tableTotal = [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            `NGN ${total}`
        ]
        const emptyRow1 = [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
        ]
        const emptyRow2 = [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
        ]
        const emptyRow3 = [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
        ]
        const signatureRow = [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '________________________',
        ]
        const authorRow = [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            user?.data?.first_name + ' ' + user?.data?.last_name,
        ]



        // alert(laboratory_reports[0]?.created_at)
        tableRows.push(tableTotal, emptyRow1, emptyRow2, emptyRow3, signatureRow, authorRow)
        doc.autoTable(tableColumns, tableRows)
        doc.text(`${hospital_config?.name} Daily Laboratory Report: ${laboratory_reports[0]?.created_at}`, 30, 10)
        doc.save(`Daily Laboratory Report - ${laboratory_reports[0]?.created_at}`)
        // // doc.text("Hello world!", 10, 10);
        // doc.save("a4.pdf")
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[1000px] px-[5px] py-[5px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className="text-dark-green text-[20px] font-bold">
                            {/* Laboratory Reports */}
                        </h4>
                        <div className="flex space-x-2 px-2 h-[25px] justify-center items-center rounded-full bg-gray-300 cursor-pointer"
                            onClick={() => {
                                setModals({ ...modals, showAccountsViewLabReportsModal: !modals.showAccountsViewLabReportsModal })
                            }}
                        >
                            <span className='w-2 h-2 rounded-full bg-orange-400'></span>
                            <p className='text-[10px] font-medium'>Close</p>
                        </div>
                    </div>

                    <div className="flex justify-end items-center space-x-4">
                        <button
                            type="button"
                            className="flex items-center px-2 py-1 bg-sky-600 shadow-inner text-white font-bold text-[10px] leading-tight capitalize focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                            onClick={exportPdf}
                        >
                            <span className="">Export Pdf</span>
                            <span className=""><AiOutlineFilePdf size={15} /></span>
                        </button>
                        <button
                            type="button"
                            className="flex items-center px-2 py-1 bg-indigo-600 shadow-inner text-white font-bold text-[10px] leading-tight capitalize focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                            onClick={exportXLSX}
                        >
                            <span className="">Export Excel</span>
                            <span className=""><AiOutlineFileExcel size={15} /></span>
                        </button>
                    </div>

                    <table className="border-b border-l border-r rounded w-full cursor-default">
                        <thead className="">
                            <tr className='bg-gray-100'>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[2em] px-4 py-4 text-left"
                                >
                                    S/N
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                >
                                    Patient ID
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                >
                                    Invoice ID
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                >
                                    Type
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                >
                                    Amount
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                >
                                    Date
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                >
                                    Author
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                >

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {laboratory_reports?.map((invoice, index) => (
                                <tr key={`${invoice.id}`}>
                                    <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                        {index + 1}
                                    </td>
                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                        {invoice.patient_number}
                                    </td>
                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                        {invoice.invoice_number}
                                    </td>
                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                        {invoice.billing_type}
                                    </td>
                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                        &#8358; {invoice.amount}
                                    </td>
                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                        {invoice.date_generated}
                                    </td>
                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                        {invoice.updated_by ? 'Abdullahi Muhammed' : '-'}
                                    </td>
                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                        <PaymentStatus status={invoice.is_paid} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        {/* <PaginationButtons
                                pages={pages}
                                currentPage={currentPage}
                                handlePagination={handlePagination}
                            /> */}
                    </table>
                </div>
            </div>
        </div>
    )
}

export default AccountsViewLaboratoryReportsModal