import React, { Fragment, useState } from 'react'
import { FaPencilAlt } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import { useStateContext } from '../../../state/context'
import { useDispatch } from 'react-redux'
import { setLabInvestigationType } from '../../../state/actions/actions'
import Pagination from '../../../components/utils/Pagination'


const LaboratoryInvestigationsTypesTable = (props) => {
    const dispatch = useDispatch()
    const { modals, setModals } = useStateContext()

    const [currentData, setCurrentData] = useState([])

    return (
        <Fragment>
            <table className='border rounded w-full cursor-default'>
                <thead className=''>
                    <tr>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                        >
                            S/N
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
                        >
                            Investigation Type
                        </th>


                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 py-4 text-left'
                        >
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {currentData?.map((types, index) => (
                        <tr
                            className='relative' key={index}

                        >
                            <td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
                                {index + 1}
                            </td>

                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {types.type_title}
                            </td>
                            <td className=' text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                <div className='flex space-x-4 relative '>
                                    <FaPencilAlt
                                        className='text-green-600 cursor-pointer '
                                        onClick={() => {
                                            const data = {
                                                title: types.type_title,
                                                category_id: types.investigation_category,
                                                investigation_id: types.id,
                                                cat_title: types.investigation_catergory,
                                                status: types.is_available


                                            }
                                            dispatch(setLabInvestigationType({ data }))
                                            setModals({
                                                ...modals,
                                                showLaboratoryUpdateInvestigationType:
                                                    !modals.showLaboratoryUpdateInvestigationType,
                                            })
                                        }}

                                        size={24} />

                                    <MdDelete
                                        className='text-orange-600 cursor-pointer mr-4'
                                        onClick={() => {
                                            const data = {
                                                title: types.type_title,
                                                category_id: types.investigation_category,
                                                investigation_id: types.id,
                                                cat_title: types.investigation_catergory,
                                                status: types.is_available

                                            }
                                            dispatch(setLabInvestigationType({ data }))
                                            setModals({
                                                ...modals,
                                                showDeleteLaboratoryInvestigationTypeModal:
                                                    !modals.showDeleteLaboratoryInvestigationTypeModal,
                                            })
                                        }}
                                        size={24} />

                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <Pagination
                data={props.all_lab_investigation_type}
                currentData={currentData}
                setCurrentData={setCurrentData}
            />
        </Fragment>
    )
}

export default LaboratoryInvestigationsTypesTable