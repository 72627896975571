import React, { Fragment, useState, useEffect } from 'react'
import { FaPencilAlt } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import { useStateContext } from '../../../state/context'
import { useDispatch } from 'react-redux'
import { setLabCategoryTest } from '../../../state/actions/actions'
import Pagination from '../../../components/utils/Pagination'

const LaboratoryInvestigationCategoryTable = (props) => {
	const dispatch = useDispatch()

	const { modals, setModals } = useStateContext()

	const [currentData, setCurrentData] = useState([])

	return (
		<Fragment>
			<table className='border rounded w-full cursor-default'>
				<thead className=''>
					<tr>
						<th
							scope='col'
							className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
						>
							S/N
						</th>

						<th
							scope='col'
							className='text-sm font-medium text-gray-900 pr-[4em] py-4 text-left'
						>
							Investigation category
						</th>
						<th
							scope='col'
							className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
						>
							Created by
						</th>


						<th
							scope='col'
							className='text-sm font-medium text-gray-900 py-4 text-left'
						>
							Actions
						</th>
					</tr>
				</thead>
				<tbody>
					{currentData?.map((category, index) => (
						<tr
							className='relative' key={index}

						>
							<td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
								{index + 1}
							</td>
							<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
								{category?.title}
							</td>
							<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
								{category?.created_by.first_name}  {category?.created_by?.last_name}
							</td>
							<td className=' text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
								<div className='flex space-x-4 relative '>
									<FaPencilAlt
										size={24}
										className='text-green-600 cursor-pointer '
										onClick={() => {
											const data = {
												cat_id: category.id,
												cat_name: category.title
											}
											dispatch(setLabCategoryTest({ data }))
											setModals({
												...modals,

												showLaboratoryUpdateCategoryModal:
													!modals.showLaboratoryUpdateCategoryModal,
											})
										}}
									/>

									<MdDelete
										size={20}
										className='text-orange-500 cursor-pointer mr-4'
										onClick={() => {
											const data = {
												cat_id: category.id,
												cat_name: category.title
											}
											dispatch(setLabCategoryTest({ data }))
											setModals({
												...modals,
												showLaboratoryDeleteCategoryModal:
													!modals.showLaboratoryDeleteCategoryModal,
											})
										}}
									/>
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>

			<Pagination
				data={props.all_lab_category_test}
				currentData={currentData}
				setCurrentData={setCurrentData}
			/>
		</Fragment>
	)
}

export default LaboratoryInvestigationCategoryTable