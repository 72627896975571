import React, { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FaTimesCircle } from 'react-icons/fa'
import { useReactToPrint } from 'react-to-print'

import { useStateContext } from '../../../state/context'


const RecordsGenerateCardModal = (props) => {
	const { modals, setModals } = useStateContext()

	const cardRef = useRef()

	const { set_records_generate_card } = useSelector((state) => state.root)
	console.log(set_records_generate_card)

	const handlePrint = useReactToPrint({
		content: () => cardRef.current,
	})


	return (
		<div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
			<div
				className='bg-white rounded px-[10px] py-[10px] w-[250px]'

			>
				<div className='flex justify-between mb-2'>
					<p className='text-[15px] font-semibold italic ml-2'>JoyLand Hospital</p>
					<FaTimesCircle
						className='text-dark-yellow cursor-pointer mb-2'
						onClick={() =>
							setModals({
								...modals,
								showGenerateCardModal:
									!modals.showGenerateCardModal,
							})
						}
						size={20}
					/>
				</div>
				<div className='flex flex-col items-center'
					ref={cardRef}
				>

					<img
						className='rounded-md  w-[200px]'
						src={`${process.env.REACT_APP_API_ROOT}${set_records_generate_card?.photo}`}
						alt=''
					/>

					<div className='mt-4  '>
						<div>
							<p className='text-[12px] text-gray-600 font-bold text-center italic'>

								Patient Name: {set_records_generate_card?.first_name} {set_records_generate_card?.last_name}

							</p>
							<p className='text-[12px] text-gray-600  font-bold text-center italic'>
								Patient Number: {set_records_generate_card?.patient_no}
							</p>
						</div>
						<div className="flex flex-col items-center">
							<p className='text-[16px]  text-white bg-orange-300 px-2 py-1 rounded-lg'>
								Patient Doctor
							</p>
							<p className='text-[16px]  text-gray-600'>
								Dr. {set_records_generate_card?.doctor?.first_name} {set_records_generate_card?.doctor?.last_name}
							</p>

						</div>

					</div>


				</div>
				<div className='mt-4 flex justify-center '>
					<button
						type='button'
						className='inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease'
						onClick={handlePrint}
					>
						Print
					</button>
				</div>
			</div>

		</div>
	)
}

export default RecordsGenerateCardModal
