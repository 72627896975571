import React, { Fragment } from 'react'

const Role = ({ user, type }) => {
    return (
        <Fragment>
            {type !== 'group' && type !== 'banner' && (
                <p className={`text-[8px] ${type === 'chat' ? 'text-gray-400 font-medium' : 'text-blue-500'}`}>
                    {user.is_accountant && !user.is_department_head && 'Accountant'}
                    {user.is_accountant && user.is_department_head && 'Accountant (Head)'}
                    {user.is_administrator && 'Admin'}
                    {user.is_collections && !user.is_department_head && 'Collections'}
                    {user.is_collections && user.is_department_head && 'Collections (Head)'}
                    {user.is_doctor && !user.is_department_head && 'Doctor'}
                    {user.is_doctor && user.is_department_head && 'Doctor (Head)'}
                    {user.is_ophthalmologists && !user.is_department_head && 'Ophthalmologist'}
                    {user.is_ophthalmologists && user.is_department_head && 'Ophthalmologist (Head)'}
                    {user.is_otolaryngologists && !user.is_department_head &&  'Otolaryngologist'}
                    {user.is_otolaryngologists && user.is_department_head &&  'Otolaryngologist (Head)'}
                    {user.is_laboratorist && !user.is_department_head && 'Laboratorist'}
                    {user.is_laboratorist && user.is_department_head && 'Laboratorist (Head)'}
                    {user.is_nurse && !user.is_department_head && 'Nurse'}
                    {user.is_nurse && user.is_department_head && 'Nurse (Head)'}
                    {user.is_patient && !user.is_department_head && 'Patient'}
                    {user.is_patient && user.is_department_head && 'Patient (Head)'}
                    {user.is_pharmacist && !user.is_department_head && 'Pharmacist'}
                    {user.is_pharmacist && user.is_department_head && 'Pharmacist (Head)'}
                    {user.is_radiologist && !user.is_department_head && 'Radiologist'}
                    {user.is_radiologist && user.is_department_head && 'Radiologist (Head)'}
                    {user.is_records && !user.is_department_head && 'Records'}
                    {user.is_records && user.is_department_head && 'Records (Head)'}
                    {user.is_dentist && !user.is_department_head && 'Dentist'}
                    {user.is_dentist && user.is_department_head && 'Dentist (Head)'}
                    {user.is_physiotherapists && !user.is_department_head && 'Physiotherapist'}
                    {user.is_physiotherapists && user.is_department_head && 'Physiotherapist (Head)'}
                </p>
            )}
            {type === 'banner' && type !== 'group' && (
                <p className={`text-[8px] font-medium ${type === 'chat' ? 'text-gray-400 font-medium' : 'text-blue-600'}`}>
                    {user.is_accountant && !user.is_department_head && 'Accountant'}
                    {user.is_accountant && user.is_department_head && 'Accountant (Head)'}
                    {user.is_administrator && 'Admin'}
                    {user.is_collections && !user.is_department_head && 'Collections'}
                    {user.is_collections && user.is_department_head && 'Collections (Head)'}
                    {user.is_doctor && !user.is_department_head && 'Doctor'}
                    {user.is_doctor && user.is_department_head && 'Doctor (Head)'}
                    {user.is_ophthalmologists && !user.is_department_head && 'Ophthalmologist'}
                    {user.is_ophthalmologists && user.is_department_head && 'Ophthalmologist (Head)'}
                    {user.is_otolaryngologists && !user.is_department_head &&  'Otolaryngologist'}
                    {user.is_otolaryngologists && user.is_department_head &&  'Otolaryngologist (Head)'}
                    {user.is_laboratorist && !user.is_department_head && 'Laboratorist'}
                    {user.is_laboratorist && user.is_department_head && 'Laboratorist (Head)'}
                    {user.is_nurse && !user.is_department_head && 'Nurse'}
                    {user.is_nurse && user.is_department_head && 'Nurse (Head)'}
                    {user.is_patient && !user.is_department_head && 'Patient'}
                    {user.is_patient && user.is_department_head && 'Patient (Head)'}
                    {user.is_pharmacist && !user.is_department_head && 'Pharmacist'}
                    {user.is_pharmacist && user.is_department_head && 'Pharmacist (Head)'}
                    {user.is_radiologist && !user.is_department_head && 'Radiologist'}
                    {user.is_radiologist && user.is_department_head && 'Radiologist (Head)'}
                    {user.is_records && !user.is_department_head && 'Records'}
                    {user.is_records && user.is_department_head && 'Records (Head)'}
                    {user.is_dentist && !user.is_department_head && 'Dentist'}
                    {user.is_dentist && user.is_department_head && 'Dentist (Head)'}
                    {user.is_physiotherapists && !user.is_department_head && 'Physiotherapist'}
                    {user.is_physiotherapists && user.is_department_head && 'Physiotherapist (Head)'}
                </p>
            )}
        </Fragment>
    )
}

export default Role