import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    getRadiologistEquipment, addRadiologyEquipment, updateRadiologyEquipment,
    deleteRadiologyEquipment, getRadiologistReportRequest, generateRadiologyInvoice, uploadRadiologyReport,
    getRadiologyInvoicesByMe, createRadiologyCategoryTest, getRadiologyCategoryTest, deleteRadiologyCategoryTest,

    updateRadiologyCategoryTest, createRadiologyInvestigationType, getRadiologyInvestigationTypes,
    deleteRadiologyInvestigationType, updateRadiologyInvestigationType, getEmployees, fetchRadiologyTypesByCategory
} from '../routes/radiologist.routes'

export const getAllEmployeesForRadiologist = createAsyncThunk(
    'radiologist/getAllEmployeesForRadiologist',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getEmployees(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllRadiologistEquipment = createAsyncThunk(
    'radiologist/AllEquipment',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getRadiologistEquipment(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(error.response.data)
        }
    }
)
export const addEquipment = createAsyncThunk(
    'radiologist/addRadiologyEquipment',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await addRadiologyEquipment(formData, token)
            toast.success("Equipment added successfully")
            setModals({ ...modals, showAddRadiologyEquipment: !modals.showAddRadiologyEquipment })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occurred while adding equipment")
            return rejectWithValue(error.response.data)
        }
    }
)

export const updateARadiologyEquipment = createAsyncThunk(
    'radiologist/updateRadiologyEquipment',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await updateRadiologyEquipment(formData, token)
            toast.success("Updated successfully")
            setModals({ ...modals, showUpdateRadiologyEquipment: !modals.showUpdateRadiologyEquipment })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occurred while updating equipment")
            return rejectWithValue(error.response.data)
        }
    }
)

export const deleteARadiologyEquipment = createAsyncThunk(
    'radiologist/deleteRadiologyEquipment',
    async ({ equipment_id, type, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await deleteRadiologyEquipment(equipment_id, token)
            toast.success(`${type} Deleted successfully`)
            setModals({ ...modals, showDeleteRadiologyEquipment: !modals.showDeleteRadiologyEquipment })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occurred while deleting equipment")
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllRadiologyReportRequest = createAsyncThunk(
    'radiologist/AllRadiologyRequest',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getRadiologistReportRequest(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(error.response.data)
        }
    }
)

export const generateRadInvoice = createAsyncThunk(
    'radiologist/generateRadInvoice',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateRadiologyInvoice(formData, token)
            toast.success("Invoice generated successfully")
            setModals({ ...modals, showRadiologyGenerateInvoiceModal: !modals.showRadiologyGenerateInvoiceModal })



            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(error.response.data)
        }
    }
)

export const uploadPatientRadiologyReport = createAsyncThunk(
    'radiologist/uploadPatientReport',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await uploadRadiologyReport(formData, token)

            toast.success("Report uploaded successfully")
            setModals({ ...modals, showUploadReportModal: !modals.showUploadReportModal })



            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("Upload not successful")
            return rejectWithValue(error.response.data)
        }
    }
)


export const getAllRadiologyInvoicesByMe = createAsyncThunk(
    'radiologist/AllRadiologyInvoiceByMe',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getRadiologyInvoicesByMe(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(error.response.data)
        }
    }
)

export const createARadiologyCategoryTest = createAsyncThunk(
    'radiologist/CreateInvestigation_catergory',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await createRadiologyCategoryTest(formData, token)
            toast.success("Category created successfully")

            setModals({ ...modals, showAddRadiologyInvestigationCategoryModal: !modals.showAddRadiologyInvestigationCategoryModal })


            return data
        } catch (error) {
            toast.warning("Error occured while creating category")
            console.log(error.response)

            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllRadiologyCategoryTest = createAsyncThunk(
    'radiologist/getAllRadiologyCategoryTest',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getRadiologyCategoryTest(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(error.response.data)
        }
    }
)


export const deleteARadiologyCategory = createAsyncThunk(
    'radiologist/deleteRadiologyCategory',
    async ({ cat_id, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await deleteRadiologyCategoryTest(cat_id, token)
            toast.success("Category deleted successfully")

            setModals({ ...modals, showDeleteRadiologyCategoryTestModal: !modals.showDeleteRadiologyCategoryTestModal })


            return data
        } catch (error) {
            toast.warning("Error occured while deleting category")
            console.log(error.response)

            return rejectWithValue(error.response.data)
        }
    }
)

export const updateARadiologyCategoryTest = createAsyncThunk(
    'radiologist/updateRadiologyCategoryTest',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await updateRadiologyCategoryTest(formData, token)
            toast.success("Update successful")


            setModals({ ...modals, showUpdateRadiologyCategoryTestModal: !modals.showUpdateRadiologyCategoryTestModal })


            return data
        } catch (error) {
            toast.warning("Error occured while updating category")
            console.log(error.response)

            return rejectWithValue(error.response.data)
        }
    }
)

export const createARadiologyInvestigationType = createAsyncThunk(
    'radiologist/createARadiologyInvestigationType',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await createRadiologyInvestigationType(formData, token)
            toast.success("Investigation Type added successfully")
            setModals({ ...modals, showAddRadiologyInvestigationTypeModal: !modals.showAddRadiologyInvestigationTypeModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occurred while adding categorype type")
            return rejectWithValue(error.response.data)
        }
    }
)


export const getAllRadiologyInvestigationTypes = createAsyncThunk(
    'radiologist/getRadiologyInvestigationTypes',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getRadiologyInvestigationTypes(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(error.response.data)
        }
    }
)

export const deletePatientRadiologyInvestigationType = createAsyncThunk(
    'radiologist/deleteRadiologyInvestigationType',
    async ({ id, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await deleteRadiologyInvestigationType(id, token)
            toast.success("Investigation type deleted successfully")

            setModals({ ...modals, showDeleteRadiologyInvestigationTypeModal: !modals.showDeleteRadiologyInvestigationTypeModal })


            return data
        } catch (error) {
            toast.warning("Error occured while deleting investigation type")
            console.log(error.response)

            return rejectWithValue(error.response.data)
        }
    }
)

export const updateARadiologyInvestigationType = createAsyncThunk(
    'radiologist/updateARadiologyInvestigationType',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await updateRadiologyInvestigationType(formData, token)
            toast.success("Investigation type updated successfully")
            setModals({ ...modals, showUpdateRadiologyInvestigationTypeModal: !modals.showUpdateRadiologyInvestigationTypeModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occurred while updating categorype type")
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllRadiologyTypesByCategory = createAsyncThunk(
    'radiologist/getAllRadiologyTypesByCategory',
    async ({ id }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRadiologyTypesByCategory(id, token)

            return data
        } catch (error) {
            console.log(error.response)

            return rejectWithValue(error.response.data)
        }
    }
)
