import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'

import Pagination from '../utils/Pagination'
import { useStateContext } from '../../state/context'
import { BsFillCalendar2CheckFill } from 'react-icons/bs'
import { setCurrentAppointment, setCurrentPatient } from '../../state/actions/actions'


const DoctorBookedApppointmentsTable = ({ data, showList, setShowList, title }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [currentData, setCurrentData] = useState([])

    return (
        <div className="flex justify-between items-center w-full">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="">
                    {/* <div
                        className="flex justify-start items-center space-x-4 bg-gray-200 p-1"
                        onClick={() => setShowList(!showList)}
                    >
                        <h4 className="text-dark-blue text-[14px] font-bold">
                            {title}
                        </h4>
                        <BsFillCalendar2CheckFill className="text-dark-blue" size={20} />
                    </div> */}
                    <Fragment>
                        <table className="border rounded w-full cursor-default">
                            <thead className="bg-gray-100">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] py-4 text-left"
                                    >
                                        Patient ID
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Fullname
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Picture
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Gender
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentData?.map((appointment, index) => (
                                    <tr key={`${appointment.id}`}>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {appointment.patient.patient_number}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {appointment.patient.last_name} {appointment.patient.first_name}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light whitespace-nowrap">
                                            <img
                                                src={`${process.env.REACT_APP_API_ROOT}${appointment.patient.profile_photo}`}
                                                alt=""
                                                className='w-[50px] h-[50px] rounded-[100%]'
                                            />
                                        </td>
                                        <td className="text-sm text-gray-900 font-light whitespace-nowrap">
                                            {appointment.patient.gender}
                                        </td>
                                        <td className=" flex text-2xl space-x-4 text-gray-900 font-semibold py-2 whitespace-nowrap">
                                            <button
                                                type="button"
                                                className="inline-block px-6 py-2 bg-sky-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                onClick={() => {
                                                    const data = {
                                                        id: appointment.patient.id,
                                                        pkid: appointment.patient.pkid,
                                                        profile_photo: appointment.patient.profile_photo,
                                                        patient_number: appointment.patient.patient_number,
                                                        details: {
                                                            first_name: appointment.patient.first_name,
                                                            last_name: appointment.patient.last_name,
                                                        }
                                                    }
                                                    dispatch(setCurrentPatient({ data }))
                                                    dispatch(setCurrentAppointment({ data: appointment }))
                                                    setModals({ ...modals, showDoctorUpdateAppointmentModal: !modals.showDoctorUpdateAppointmentModal })
                                                }}
                                            >
                                                Update
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <Pagination
                            data={data}
                            currentData={currentData}
                            setCurrentData={setCurrentData}
                        />
                    </Fragment>

                </div>
            </div>
        </div >
    )
}

export default DoctorBookedApppointmentsTable