/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { AiOutlineCaretDown } from 'react-icons/ai'
import { TbReportAnalytics } from 'react-icons/tb'
import { useDispatch, useSelector } from 'react-redux'

import { PageTitle } from '../../components'
import { useStateContext } from '../../state/context'
import Pagination from '../../components/utils/Pagination'
import { setLaboratoryReport } from '../../state/actions/actions'
import { getAllLabReports } from '../../state/actions/laboratorist.actions'
import { getAllLabBillings } from '../../state/actions/laboratorist.actions'


const LaboratoristReports = () => {
	const dispatch = useDispatch()

	const { modals, setModals } = useStateContext()

	const { all_lab_report_requests } = useSelector((state) => state.laboratorist)

	const [currentData, setCurrentData] = useState([])
	const [showActionsDropdown, setShowActionsDropdown] = useState()
	const [currentRadInvestigationIndex, setcurrentRadInvestigationIndex] = useState(0)

	useEffect(() => {
		dispatch(getAllLabReports())
		dispatch(getAllLabBillings())

		if (all_lab_report_requests != null) {
			setcurrentRadInvestigationIndex(all_lab_report_requests[0]?.pkid)
		}
	}, [modals.showLaboratoryUploadReportModal, modals.showLaboratoryGenerateInvoiceModal])

	return (
		<div>
			<PageTitle type={'laboratorist-reports'} />

			<div className='flex flex-col w-full cursor-pointer'>
				<div className='py-2'>
					<div className='flex justify-start items-center space-x-4 bg-gray-200 p-1'>
						<h4 className='text-dark-blue text-[14px] font-bold'>
							Reports
						</h4>
						<TbReportAnalytics
							className='text-dark-blue'
							size={20}
						/>
					</div>
					<table className='border rounded w-full cursor-default'>
						<thead className=''>
							<tr>
								<th
									scope='col'
									className='text-[12px] font-medium text-gray-900 px-4 text-left capitalize'
								>
									S/N
								</th>

								<th
									scope='col'
									className='text-[12px] font-medium text-gray-900 text-left capitalize'
								>
									Patient Name
								</th>
								{/* <th
									scope='col'
									className='text-[12px] font-medium text-gray-900 pr-[5em] text-left capitalize'
								>
									Patient Number
								</th> */}
								<th
									scope='col'
									className='text-[12px] font-medium text-gray-900 text-left capitalize'
								>
									Doctor Making request
								</th>
								<th
									scope='col'
									className='text-[12px] font-medium text-gray-900 text-left capitalize'
								>
									Investigation Type
								</th>
								<th
									scope='col'
									className='text-[12px] font-medium text-gray-900 text-left capitalize'
								>
									Invoice
								</th>

								<th
									scope='col'
									className='text-[12px] font-medium text-gray-900 text-left capitalize'
								>
									
								</th>
							</tr>
						</thead>
						<tbody>
							{currentData?.map((report, index) => (
								<tr
									// className='relative'
									className={`${report?.patient_details?.nhis_id ? 'bg-indigo-00 text-gray-900' : 'text-gray-900'} relative'`}
									key={index}
								>
									<td className='px-4 py-2 whitespace-nowrap text-xs font-medium text-gray-900'>
										{index + 1}
									</td>
									<td className='text-xs text-gray-900 font-light py-2 whitespace-nowrap'>
										{report.patient.first_name} {report.patient.last_name} {report?.patient_details?.nhis_id && <sub className="font-medium text-[8px]">(NHIS)</sub>}
									</td>
									{/* <td className='text-xs text-gray-900 font-light py-2 whitespace-nowrap'>
										{report.patient_number}
									</td> */}
									<td className='text-xs text-gray-900 font-light py-2 whitespace-nowrap'>
										{report.request_doctor.first_name} {report.request_doctor.last_name}
									</td>
									<td className='text-xs text-gray-900 font-light py-2 whitespace-nowrap'>
										{report?.investigation_sample?.investigation_type_details?.type}
									</td>
									<td className='text-xs text-gray-900 font-light py-2 whitespace-nowrap'>
										{report.invoice_status != null && (
											report?.invoice_status?.is_paid ? "Paid" : "Pending"
										)}
										{report.invoice_status == null && (
											<p>Not generated</p>
										)}
									</td>


									<td className=' text-xs text-gray-900 font-light py-2 whitespace-nowrap'>
										<div className='relative'>
											<button
												type='button'
												className='inline-block px-6 py-2.5  bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease'
												onClick={() => {
													setShowActionsDropdown(!showActionsDropdown)

													setcurrentRadInvestigationIndex(report.pkid)
												}}
											>
												<span className='flex items-center justify-between'>
													Actions <AiOutlineCaretDown />
												</span>
											</button>

											{showActionsDropdown && currentRadInvestigationIndex === report.pkid && (
												<div className='absolute z-20 top-[30px] left-[-60px] p-1 bg-white shadow-lg border border-gray-200 rounded w-[150px]'>
													<div className='flex flex-col justify-start space-y-5'>

														{report.invoice_status == null && (
															<button
																onClick={() => {
																	setShowActionsDropdown(!showActionsDropdown)
																	const data = {
																		patient_id: report.user_id,
																		first_name: report.patient.first_name,
																		last_name: report.patient.last_name,
																		patient_no: report.patient_number,
																		investigation_pkid: report.pkid
																	}

																	dispatch(setLaboratoryReport({ data }))
																	setModals({
																		...modals,
																		showLaboratoryGenerateInvoiceModal:
																			!modals.showLaboratoryGenerateInvoiceModal,
																	})
																}}
																className='hover:bg-green-200 hover:text-gray-600 rounded p-2'
															>
																Generate Invoice
															</button>
														)}
														{report.invoice_status != null && (
															<button disabled>Invoice generated</button>
														)}
														{report.investigation_conducted == false && (
															<button
																onClick={() => {
																	setShowActionsDropdown(!showActionsDropdown)
																	const data = {
																		patient_id: report.user_id,
																		first_name: report.patient.first_name,
																		last_name: report.patient.last_name,
																		patient_no: report.patient_number,
																		report_id: report.id
																	}

																	dispatch(setLaboratoryReport({ data }))
																	setModals({
																		...modals,
																		showLaboratoryUploadReportModal:
																			!modals.showLaboratoryUploadReportModal,
																	})
																}}


																className='hover:bg-green-200 hover:text-gray-600 rounded p-2'
															>
																Add Report
															</button>

														)}
														{report.investigation_conducted == true && (
															<button disabled>Report uploaded</button>
														)}

														<button
															onClick={() => {
																const data = {
																	patient_id: report.user_id,
																	first_name: report.patient.first_name,
																	last_name: report.patient.last_name,
																	patient_no: report.patient_number,
																	image: report.image_report,
																	report_id: report.id,
																	test_cat: report.investigation_sample?.investigation_type_details?.category,
																	test_type: report.investigation_sample?.investigation_type_details?.type,
																	test_sample: report.investigation_sample.sample_type

																}

																dispatch(setLaboratoryReport({ data }))
															setShowActionsDropdown(!showActionsDropdown)
																setModals({
																	...modals,
																	showLaboratoryViewReportModal:
																		!modals.showLaboratoryViewReportModal,
																})
															}}

															className='hover:bg-green-200 hover:text-gray-600 rounded p-2'
														>
															See Investigation
														</button>

													</div>
												</div>
											)}
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>

					<Pagination
						data={all_lab_report_requests}
						currentData={currentData}
						setCurrentData={setCurrentData}
					/>
				</div>
			</div>
		</div>
	)
}

export default LaboratoristReports