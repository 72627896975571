import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useStateContext } from '../../../state/context'
import { getAPatientInvestigationRecord } from '../../../state/actions/nurse.actions'
import { setNursesDeleteDetails } from '../../../state/actions/actions'

const InvestigationsTable = () => {
    const { opdState, setOpdState } = useStateContext()
    const { modals, setModals } = useStateContext()
    const dispatch = useDispatch()

    const { set_patient_id } = useSelector((state) => state.root)
    const { all_patient_investigation_record } = useSelector((state) => state.nurse)

    useEffect(() => {
        dispatch(getAPatientInvestigationRecord({ id: set_patient_id?.id }))
    }, [modals.showAddPatientInvestigationRecordModal, modals.showDeleteInvestigationRecordModal])

    return (
        <div className='flex flex-col'>
            <div className='flex justify-end space-x-2'>
                <button
                    type="button"
                    className=" px-6 py-2.5 bg-sky-600 text-white w-[100px] mb-2 font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                    onClick={() => (
                        setModals({
                            ...modals,
                            showAddPatientInvestigationRecordModal: !modals.showAddPatientInvestigationRecordModal

                        })
                    )}
                >
                    Add +
                </button>
                <button
                    type="button"
                    className=" px-6 py-2.5 bg-dark-green text-white w-[100px] mb-2 font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                    onClick={() => (
                        setOpdState({
                            ...opdState,
                            showPatientAntenatalRecords: false

                        })
                    )}
                >
                    Back
                </button>

            </div>
            <div>

                <div className='flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1'>

                    <h4 className='text-dark-blue text-[16px] font-semibold'>
                        Investigations History - {set_patient_id?.first_name} {set_patient_id?.last_name} [{set_patient_id?.patient_number}]
                    </h4>
                </div>


                <table className='border rounded w-full cursor-default'>

                    <thead className=''>
                        <tr>
                            <th
                                scope='col'
                                className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                            >
                                S/N
                            </th>

                            <th
                                scope='col'
                                className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                            >
                                Special Investigations
                            </th>
                            <th
                                scope='col'
                                className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
                            >
                                Nurse
                            </th>


                            <th
                                scope='col'
                                className='text-sm font-medium text-gray-900 py-4 text-left'
                            >
                                Date
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {all_patient_investigation_record?.map((item, index) => (
                            <tr
                                className='relative'
                                key={index}

                            >
                                <td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
                                    {index + 1}
                                </td>
                                <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                    {item.description}          </td>
                                <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                    {item.investigation_nurse.first_name} {item.investigation_nurse.last_name}
                                </td>

                                <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                    {item.date}
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </table>
            </div>
        </div>
    )
}

export default InvestigationsTable