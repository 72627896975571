/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { SetCollectionInvoiceType, setPayStackPaymentDetails } from '../../state/actions/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useStateContext } from '../../state/context'
import { setPatientInvoiceModal } from '../../state/actions/actions'
import Pagination from '../utils/Pagination'
import { setAccumulatedPharmacyInvoice } from '../../state/actions/collection.actions'


const PatientPharmacyInvoicesTable = ({ data, showList }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { current_patient_profile } = useSelector((state) => state.patient)

    const [currentData, setCurrentData] = useState([])
    const [accumulatedList, setAccumulatedList] = useState([])
    const [filterState, setFilterState] = useState({ search: '', invoices: [] })

    const handleFilter = () => {
        const filtered = data?.filter(item => {
            return item.invoice_number.toLowerCase().includes(filterState.search.toLowerCase())
                || item.invoice_amount === filterState.search
        })

        setFilterState({ ...filterState, invoices: filtered })

        if (filterState.search === '') {
            setFilterState({ ...filterState, invoices: data })
        }
    }

    useEffect(() => {
        setFilterState({ ...filterState, invoices: data })
    }, [data])

    useEffect(() => {
        handleFilter()
    }, [filterState.search])

    return (
        <div className="flex justify-between items-center w-full">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="">
                    <div className='flex justify-between mb-2'>
                        <input
                            type="text"
                            name="search"
                            // disabled
                            placeholder='Search'
                            className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                            onChange={(e) => setFilterState({ ...filterState, [e.target.name]: e.target.value })}
                        />

                        {accumulatedList.length > 0 && (
                            <button
                                type="button"
                                className="mb-2 mt-2 inline-block px-6 py-2 bg-sky-500 text-white font-medium text-[10px] leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                onClick={() => {
                                    dispatch(setAccumulatedPharmacyInvoice({ data: accumulatedList }))
                                    dispatch(SetCollectionInvoiceType({ data: "pharmacy" }))
                                    setModals({ ...modals, showPatientMultiplePaymentModal: !modals.showPatientMultiplePaymentModal })
                                }}
                            >
                                Pay All
                            </button>
                        )}
                    </div>
                    {showList && (
                        <table className="border-b border-l border-r rounded w-full cursor-default">
                            <thead className="">
                                <tr className='bg-gray-100'>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Invoice ID
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Drug
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Quantity
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Amount
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Date
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        <input type="checkbox"
                                            onChange={() => {
                                                if (accumulatedList.length === 0) {
                                                    setAccumulatedList(currentData.filter(item => item.is_paid === false))
                                                } else {
                                                    setAccumulatedList([])
                                                }
                                            }}
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentData?.map((item, index) => (
                                    <tr key={index}>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.invoice_number}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.diagnosis.diagnosis_name}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            &#8358; {item.invoice_amount}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.created_at.slice(0, 10)}
                                        </td>

                                        <td className="flex space-x-2 text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {!item.is_paid && (<div className="flex space-x-2 px-2 h-[25px] justify-center items-center rounded-full bg-gray-100">
                                                <span className='w-2 h-2 rounded-full bg-orange-400'></span>
                                                <p className='text-[10px]'>Pending</p>
                                            </div>)}
                                            {item.is_paid && (<div className="flex space-x-2 px-2 h-[25px] justify-center items-center rounded-full bg-gray-100 mt-2">
                                                <span className='w-2 h-2 rounded-full bg-blue-400'></span>
                                                <p className='text-[10px] px-2'>Paid</p>
                                            </div>)}
                                            {!item.is_paid && (
                                                <button
                                                    type="button"
                                                    className="w-[80px] inline-block px-6 py-2 bg-purple-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                    onClick={() => {
                                                        const data = {
                                                            invoice_type: "Pharmacy",
                                                            email: current_patient_profile?.email,
                                                            amount: item.invoice_amount,
                                                            invoice_id: item.id

                                                        }
                                                        localStorage.setItem("Invoice_details", JSON.stringify(data))
                                                        dispatch(setPayStackPaymentDetails({ data: data }))

                                                        // setAppState({ ...appState, showManageBinCard: !appState.showManageBinCard })
                                                        setModals({ ...modals, showPatientPayForInvoicesModal: !modals.showPatientPayForInvoicesModal })
                                                    }}
                                                >
                                                    Pay
                                                </button>
                                            )}
                                            {item.is_paid && (
                                                <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                    <button
                                                        type="button"
                                                        className="w-[80px] inline-block px-6 py-2 bg-teal-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                        onClick={() => {
                                                            const data = {
                                                                department: "Prescription",
                                                                title: item.diagnosis.diagnosis_name,
                                                                invoice_number: item.invoice_number,
                                                                amount: item.invoice_amount,
                                                                payment_author: { first_name: item.payment_author.first_name, last_name: item.payment_author.last_name },
                                                                date_generated: item.created_at.slice(0, 10),
                                                                date_paid: item.date_paid

                                                            }
                                                            dispatch(setPatientInvoiceModal({ data }))
                                                            setModals({ ...modals, showPatientViewLabInvoiceModal: !modals.showPatientViewLabInvoiceModal })
                                                        }}
                                                    >
                                                        View
                                                    </button>
                                                </td>
                                            )}
                                        </td>
                                        <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                            {!item.is_paid && (
                                                <input
                                                    type="checkbox"
                                                    checked={accumulatedList?.find((_item) => _item.id === item.id)}
                                                    onChange={(e) => {
                                                        const myIndex = accumulatedList.findIndex(e => e.id === item.id)
                                                        console.log(e.target.value)
                                                        if (myIndex >= 0) {
                                                            setAccumulatedList([...accumulatedList.filter(inv => inv.pkid !== item.pkid)])
                                                        } else {
                                                            setAccumulatedList([...accumulatedList, item])
                                                        }
                                                    }}
                                                />
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    <Pagination
                        data={filterState.invoices}
                        currentData={currentData}
                        setCurrentData={setCurrentData}
                    />
                </div>
            </div>
        </div >
    )
}

export default PatientPharmacyInvoicesTable