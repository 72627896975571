/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { BsPersonPlus } from 'react-icons/bs'
import { useStateContext } from '../../state/context'
import { useDispatch, useSelector } from 'react-redux'
import { PageTitle } from '../../components'
import AdmittedPatientTable from '../../components/tables/nursesTable/AdmittedPatientTable'
import PatientWaitingAdmissionTable from '../../components/tables/nursesTable/PatientWaitingAdmissionTable'
import { getAllPatientsToBeAdmitted, getAllAdmittedPatient } from '../../state/actions/nurse.actions'
import NurseAdmittedPatientManagementPage from './NurseAdmittedPatientManagementPage'
import { setPatientID } from '../../state/actions/actions'
import Pagination from '../../components/utils/Pagination'

const NurseInpatients = () => {
	const dispatch = useDispatch()
	
	const { modals, setModals, opdState, setOpdState } = useStateContext()

	const { patients_to_be_admitted, all_admitted_patient } = useSelector((state) => state.nurse)

	const [currentData, setCurrentData] = useState([])


	useEffect(() => {
		dispatch(getAllPatientsToBeAdmitted())
		dispatch(getAllAdmittedPatient())

	}, [modals.showAdmitPatientModal])

	useEffect(() => {
		return () => {
			setOpdState({ ...opdState, showAdmittedPatientManagement: false })
			dispatch(setPatientID({ data: null }))
		}
	}, [])


	return (
		<div className='flex flex-col w-full cursor-pointer'>

			<PageTitle type={"nurse_inpatient"} />
			{!opdState.showAdmittedPatientManagement && (
				<>
					<div></div>
					<div className='py-2'>
						<div className='flex justify-start items-center space-x-4 bg-gray-200 p-1'>
							<h4 className='text-dark-blue text-[14px] font-bold'>
								Patients
							</h4>
							<BsPersonPlus
								className='text-dark-blue'
								size={20}
							/>
							{/* <input type="text" name="filter" placeholder='Search' id="" className='ml-2 pl-4 text-sm rounded h-[30px] w-[400px]' onChange={(e) => handleChange(e, e.target.name)} /> */}
						</div>
						<table className='border rounded w-full cursor-default'>
							<thead className=''>
								<tr>
									<th
										scope='col'
										className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
									>
										S/N
									</th>

									<th
										scope='col'
										className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
									>
										Patient Number
									</th>
									<th
										scope='col'
										className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
									>
										Full name
									</th>
									<th
										scope='col'
										className='text-sm font-medium text-gray-900 py-4 text-left'
									>
										Doctor requesting
									</th>
									<th
										scope='col'
										className='text-sm font-medium text-gray-900 py-4 text-left'
									>
										Gender
									</th>


									<th
										scope='col'
										className='text-sm font-medium text-gray-900 py-4 text-left'
									>
										Action
									</th>
								</tr>
							</thead>

							<PatientWaitingAdmissionTable data={currentData} />

						</table>

						<Pagination
                                data={patients_to_be_admitted}
                                currentData={currentData}
                                setCurrentData={setCurrentData}
                            />
					</div>

					<AdmittedPatientTable data={all_admitted_patient} />
				</>
			)}
			{opdState.showAdmittedPatientManagement && (
				<NurseAdmittedPatientManagementPage />
			)}

		</div>
	)
}

export default NurseInpatients