import React, { Fragment } from 'react'

import PharmacistAddDrugCategoryModal from '../../modals/pharmacist/PharmacistAddDrugCategoryModal'
import PharmacistAddSupplyPointModal from '../../modals/pharmacist/PharmacistAddSupplyPointModal'
import PharmacistAddDrugModal from '../../modals/pharmacist/PharmacistAddDrugModal'
import PharmacistDeleteDrugCategoryModal from '../../modals/pharmacist/PharmacistDeleteDrugCategoryModal'
import PharmacistDeleteDrugModal from '../../modals/pharmacist/PharmacistDeleteDrugModal'
import PharmacistDeleteSupplyPointModal from '../../modals/pharmacist/PharmacistDeleteSupplyPoint'
import PharmacistDrugBinCardModal from '../../tables/PharmacistDrugBinCardModal'
import PharmacistDrugSupplyPointModal from '../../modals/pharmacist/PharmacistDrugSupplyPointModal'
import PharmacistDrugDispenseModal from '../../modals/pharmacist/PharmacistDrugDispenseModal'
import PharmacistBinCardSummaryModal from '../../modals/pharmacist/PharmacistBinCardSummaryModal'
import PharmacistIssueOutDrugModal from '../../modals/pharmacist/PharmacistIssueOutDrugModal'
import PharmacistLendRefundDrugModal from '../../modals/pharmacist/PharmacistLendRefundDrugModal'
import PharmacistViewPrescriptionModal from '../../modals/pharmacist/PharmacistViewPrescriptionModal'
import PharmacistViewInvoiceModal from '../../modals/pharmacist/PharmacistViewInvoiceModal'
import PharmacistAssignSupplyPointModal from '../../modals/pharmacist/PharmacistAssignSupplyPointModal'
import PharmacistSupplyPointStaffModal from '../../modals/pharmacist/PharmacistSupplyPointStaffModal'
import PharmacistSupplyPointAssignStaffModal from '../../modals/pharmacist/PharmacistSupplyPointAssignStaffModal'
import PharmacistGenerateInvoiceModal from '../../modals/pharmacist/PharmacistGenerateInvoiceModal'
import PharmacistMultipleDrugDispenseModal from '../../modals/pharmacist/PharmacistMultipleDrugDispenseModal'
import PharmacistGenerateMultipleInvoicesModal from '../../modals/pharmacist/PharmacistGenerateMultipleInvoicesModal'

const PharmacistFormModals = (props) => {
    return (
        <Fragment>
            {props.type === 'pharmacist__add__drugcategory' && (
                <PharmacistAddDrugCategoryModal />
            )}

            {props.type === 'pharmacist__update__drugcategory' && (
                <PharmacistAddDrugCategoryModal />
            )}

            {props.type === 'pharmacist__delete__drugcategory' && (
                <PharmacistDeleteDrugCategoryModal />
            )}

            {props.type === 'pharmacist__add__drug' && (
                <PharmacistAddDrugModal />
            )}

            {props.type === 'pharmacist__update__drug' && (
                <PharmacistAddDrugModal />
            )}

            {props.type === 'pharmacist__view__drug' && (
                <PharmacistAddDrugModal />
            )}

            {props.type === 'pharmacist__delete__drug' && (
                <PharmacistDeleteDrugModal />
            )}

            {props.type === 'pharmacist__assign__supplypoint__to__user' && (
                <PharmacistAssignSupplyPointModal />
            )}

            {props.type === 'pharmacist__add__supplypoint' && (
                <PharmacistAddSupplyPointModal />
            )}

            {props.type === 'pharmacist__update__supplypoint' && (
                <PharmacistAddSupplyPointModal />
            )}

            {props.type === 'pharmacist__delete__supplypoint' && (
                <PharmacistDeleteSupplyPointModal />
            )}

            {props.type === 'pharmacist__view__supplypoint__staff' && (
                <PharmacistSupplyPointStaffModal />
            )}

            {props.type === 'pharmacist__supplypoint__assignstaff' && (
                <PharmacistSupplyPointAssignStaffModal />
            )}

            {props.type === 'pharmacist__drug__bincard' && (
                <PharmacistDrugBinCardModal />
            )}

            {props.type === 'pharmacist__drug__bincard__summary' && (
                <PharmacistBinCardSummaryModal />
            )}

            {props.type === 'pharmacist__drug__supplypoint' && (
                <PharmacistDrugSupplyPointModal />
            )}

            {props.type === 'pharmacist__generate__prescription__invoice' && (
                <PharmacistGenerateInvoiceModal />
            )}

            {props.type === 'pharmacist__dispense__drug' && (
                <PharmacistDrugDispenseModal />
            )}

            {props.type === 'pharmacist__issueout__drug' && (
                <PharmacistIssueOutDrugModal />
            )}

            {props.type === 'pharmacist__lend__refund__drug' && (
                <PharmacistLendRefundDrugModal />
            )}

            {props.type === 'pharmacist__view__prescription' && (
                <PharmacistViewPrescriptionModal />
            )}

            {props.type === 'pharmacist__view__invoice' && (
                <PharmacistViewInvoiceModal />
            )}

            {props.type === 'pharmacist__dispense__multipleinvoice__modal' && (
                <PharmacistMultipleDrugDispenseModal />
            )}

            {props.type === 'pharmacist__generate__multipleinvoice__modal' && (
                <PharmacistGenerateMultipleInvoicesModal />
            )}
        </Fragment>
    )
}

export default PharmacistFormModals