import { configureStore } from '@reduxjs/toolkit'

import AuthReducer from './reducers/auth.reducer'
import adminReducer from './reducers/admin.reducer'
import reducers from './reducers/reducers'
import recordReducer from './reducers/records.reducer'
import pharmacistReducer from './reducers/pharmacist.reducer'
import radiologistReducer from './reducers/radiologist.reducer'
import laboratoristReducer from './reducers/laboratorist.reducer'
import nurseReducer from './reducers/nurse.reducer'
import doctorReducer from './reducers/doctor.reducer'
import patientReducer from './reducers/patient.reducer'
import accountsReducer from './reducers/accountant.reducer'
import collectionReducer from './reducers/collection.reducer'
import chatReducer from './reducers/chat.reducer'
import notificationsReducer from './reducers/notifications.reducer'
import roomReducer from './reducers/roomReducer'


export default configureStore({
    reducer: {
        accountant: accountsReducer,
        admin: adminReducer,
        auth: AuthReducer,
        chat: chatReducer,
        collection: collectionReducer,
        root: reducers,
        records: recordReducer,
        pharmacist: pharmacistReducer,
        radiologist: radiologistReducer,
        laboratorist: laboratoristReducer,
        nurse: nurseReducer,
        patient: patientReducer,
        doctor: doctorReducer,
        notification: notificationsReducer,
        room: roomReducer
    }
})