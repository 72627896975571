import React, { Fragment, useState } from 'react'
import { FaBed } from 'react-icons/fa'

import Pagination from '../utils/Pagination'


const DoctorWardsBedSpaceTable = ({ data, showList, setShowList, title }) => {
    const [currentData, setCurrentData] = useState([])

    return (
        <div className="flex justify-between items-center w-full">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="p">
                    {/* <div
                        className="flex justify-start items-center space-x-4 bg-gray-200 p-1"
                        onClick={() => setShowList(!showList)}
                    >
                        <h4 className="text-dark-blue text-[14px] font-bold">
                            {title}
                        </h4>
                        <FaBed className="text-dark-blue" size={20} />
                    </div> */}
                    <Fragment>
                        <table className="border rounded w-full cursor-default">
                            <thead className="bg-gray-100">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] py-4 text-left"
                                    >
                                        Ward
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        BedSpace
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentData?.map((bedspace, index) => (
                                    <tr key={`${bedspace.id}`}>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {bedspace.ward_name}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {bedspace.bed_number}
                                        </td>
                                        <td className="text-sm font-light py-2 whitespace-nowrap">
                                            {bedspace.allocated ? (
                                                <span className="text-sky-600">Occupied</span>
                                            ) : (
                                                <span className="text-green-600">Available</span>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <Pagination
                            data={data}
                            currentData={currentData}
                            setCurrentData={setCurrentData}
                        />
                    </Fragment>

                </div>
            </div>
        </div >
    )
}

export default DoctorWardsBedSpaceTable