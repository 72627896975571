/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react'
import { RiBodyScanFill, RiQrScan2Fill } from 'react-icons/ri'
import { useStateContext } from '../../state/context'
import { useDispatch, useSelector } from 'react-redux'
import LaboratoryInvestigationCategoryTable from './LabEmployees/LaboratoryInvestigationCategoryTable'
import { getAllLaboratoryInvestigationCategory, getAllLaboratoryInvestigationTypes, getAllLabSampleTest } from '../../state/actions/laboratorist.actions'
import LaboratoryInvestigationsTypesTable from './LabEmployees/LaboratoryInvestigationsTypesTable'
import LaboratorySampleTestTable from './LabEmployees/LaboratorySampleTestTable'
import { PageTitle } from '../../components'
import Pagination from '../../components/utils/Pagination'


const LaboratoristInvestigations = () => {
	const dispatch = useDispatch()

	const { modals, setModals } = useStateContext()

	const { all_lab_category_test, all_lab_investigation_type, all_lab_sample_test } = useSelector((state) => state.laboratorist)

	const [showSampleTest, setShowSampleTest] = useState(false)
	const [showInvestigationTypes, setShowInvestigationTypes] = useState(false)
	const [showInvestigationCategory, setShowInvestigationCategory] = useState(false)
	const [investigationSamples, setInvestigationSamples] = useState([])

	useEffect(() => {
		dispatch(getAllLaboratoryInvestigationCategory())
		dispatch(getAllLaboratoryInvestigationTypes())
		dispatch(getAllLabSampleTest())
	}, [
		modals.showLaboratoryAddCategoryModal,
		modals.showLaboratoryDeleteCategoryModal,
		modals.showLaboratoryUpdateCategoryModal,
		modals.showLaboratoryAddInvestigationType,
		modals.showLaboratoryUpdateInvestigationType,
		modals.showDeleteLaboratoryInvestigationTypeModal,
		modals.showLaboratoryUpdateInvestigationType,
		modals.showAddLaboratorySamplesModal,
		modals.showUpdateLaboratorySamplesModal,
		modals.showDeleteLaboratorySampleModal
	])

	return (
		<div>
			<PageTitle type={'laboratorist-investigations'} />

			<div className='flex flex-col w-full'>
				<div className=''>
					<div className='flex flex-col w-full cursor-pointer'>
						<div className='flex justify-start items-center space-x-4 bg-gray-200 p-1 cursor-pointer'
							onClick={() => {
								setShowInvestigationCategory(!showInvestigationCategory)
							}}
						>
							<h4 className='text-dark-blue text-[14px] font-bold'>
								Investigations Categories
							</h4>
							<RiQrScan2Fill
								className='text-dark-blue'


								size={20}
							/>
						</div>

						{showInvestigationCategory && (
							<LaboratoryInvestigationCategoryTable all_lab_category_test={all_lab_category_test} />
						)}
						<div className='py-2'>
							<div className='flex justify-start items-center space-x-4 bg-gray-200 p-1'
								onClick={() => setShowInvestigationTypes(!showInvestigationTypes)}
							>
								<h4 className='text-dark-blue text-[14px] font-bold'>
									Investigation Types
								</h4>
								<RiBodyScanFill
									className='text-dark-blue'
									size={20}
								/>
							</div>
							{showInvestigationTypes && (
								<LaboratoryInvestigationsTypesTable all_lab_investigation_type={all_lab_investigation_type} />
							)}

						</div>
					</div>

				</div>
			</div>


			{/* Smaple Table */}
			<div className='flex flex-col w-full cursor-pointer'>
				<div className='py-2'>
					<div className='flex justify-start items-center space-x-4 bg-gray-200 p-1'
						onClick={() => setShowSampleTest(!showSampleTest)}
					>
						<h4 className='text-dark-blue text-[14px] font-bold'>
							Investigation Samples
						</h4>
						<RiBodyScanFill
							className='text-dark-blue'
							size={20}
						/>
					</div>
					{showSampleTest && (
						<Fragment>
							<table className='border rounded w-full cursor-default'>
								<thead className=''>
									<tr>
										<th
											scope='col'
											className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
										>
											S/N
										</th>

										<th
											scope='col'
											className='text-sm font-medium text-gray-900 pr-[4em] py-4 text-left'
										>
											Investigation type
										</th>
										<th
											scope='col'
											className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
										>
											Test Samples
										</th>


										<th
											scope='col'
											className='text-sm font-medium text-gray-900 py-4 text-left'
										>
											Actions
										</th>
									</tr>
								</thead>
								<LaboratorySampleTestTable data={investigationSamples} />
							</table>

							<Pagination
								data={all_lab_sample_test}
								currentData={investigationSamples}
								setCurrentData={setInvestigationSamples}
							/>
						</Fragment>
					)}
				</div>
			</div>
		</div>
	)
}

// {showSampleTest && (
// 	<div></div>
// )}

export default LaboratoristInvestigations