import React, { useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import { FaTimesCircle } from 'react-icons/fa'
import { useStateContext } from '../../../state/context'
import { setcurrentBill } from '../../../state/actions/actions'


const RecordsViewInvoiceModal = (props) => {
	const { modals, setModals } = useStateContext()

	const cardRef = useRef()

	const { profile, user } = useSelector((state) => state.auth)
	const { view_invoice } = useSelector((state) => state.root)

	const [printInvoice, setPrintInvoice] = useState(false)

	const handlePrint = useReactToPrint({
		content: () => cardRef.current,
	})

	return (
		<div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
			<div className='bg-white rounded px-[10px] py-[10px] w-[450px]'>
				<div className='flex justify-between items-center'>
					<h4 className='text-dark-green text-[20px] font-bold'>
						Invoice
					</h4>
					<FaTimesCircle
						className='text-dark-yellow cursor-pointer mb-2'
						onClick={() =>
							setModals({
								...modals,
								showViewInvoiceModal:
									!modals.showViewInvoiceModal,
							})
						}
						size={20}
					/>
				</div>

				<div
					className={`${printInvoice && 'p-4'
						} flex flex-col space-y-4 mt-4 bg-sky-100 rounded p-1`}
					// onClick={()=> setPrintInvoice(!printInvoice)}
					ref={cardRef}
				>
					<div className='grid grid-cols-2 gap-10'>
						<p className='text-gray-800'>Amount</p>
						<p className='text-gray-800'>#{view_invoice?.amount}</p>
						<p className='text-gray-800'>Invoice number</p>
						<p className='text-gray-800'>{view_invoice?.invoice_number}</p>
						<p className='text-gray-800'>Patient number</p>
						<p className='text-gray-800'>{view_invoice?.patient_number}</p>
						<p className='text-gray-800'>Patient name</p>
						<p className='text-gray-800'>
							{view_invoice?.patient.first_name} {view_invoice?.patient.last_name}
						</p>
						<p className='text-gray-800'>Purpose</p>
						<p className='text-gray-800'>{view_invoice?.purpose}</p>
						<p className='text-gray-800'>Proccessed by</p>
						<p className='text-gray-800'>{view_invoice?.processed_by?.first_name} {view_invoice?.processed_by?.last_name}</p>
						<p className='text-gray-800'>Generated by</p>
						<p className='text-gray-800'>{view_invoice?.generated_by.first_name} {view_invoice?.generated_by.last_name}</p>
					</div>
				</div>

				<div className='mt-4 mx-auto'>
					<button
						type='button'
						className='inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease'
						onClick={handlePrint}
					>
						Print
					</button>
				</div>
			</div>
		</div>
	)
}

export default RecordsViewInvoiceModal
