/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import Lottie from 'lottie-react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { GifWallet } from '../assets'
import { updateLabInvoice, UpdateMultipleInvoices, updateMultipleInvoicesTypes, updatePharmacyInvoice, updateRadiologyInvoice, updateRecordsInvoice, verifyPayStackPayment } from '../state/actions/collection.actions'
import { getHospitalConfig } from '../state/actions/admin.actions'


const CollectionsVerifyPayStackPayment = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { hospital_config } = useSelector(state => state.admin)
    const { paystack_payment_status } = useSelector(state => state.collection)

    const [ref, setRef] = useState(null)

    useEffect(() => {
        dispatch(getHospitalConfig())

        const ref = window.location.href.split('reference=')[1]
        setRef(ref)
        console.log(ref)
    }, [])

    useEffect(() => {
        document.title = hospital_config?.name
        let link = document.querySelector("link[rel~='icon']");

        link.href = `${process.env.REACT_APP_API_ROOT}${hospital_config?.logo}`;
    }, [hospital_config])

    useEffect(() => {
        if (ref) {
            dispatch(verifyPayStackPayment({ params: { ref } }))
        }
    }, [ref])

    useEffect(() => {
        const payment__invoice = localStorage.getItem('payment__invoice') ? JSON.parse(localStorage.getItem('payment__invoice')) : null
        const multiple_payment = localStorage.getItem('multiple') ? JSON.parse(localStorage.getItem('multiple')) : null
        const patient_multiple_payment = localStorage.getItem('multiple_patient_invoice') ? JSON.parse(localStorage.getItem('multiple_patient_invoice')) : null

        if (payment__invoice?.type === 'patient_payment' && paystack_payment_status?.status === 'success') {
            const formData = {
                id: payment__invoice?.invoice_id,
                mode_of_payment: paystack_payment_status?.channel,
                payment_reference: ref
            }
            console.log(formData, payment__invoice)
            
            if(payment__invoice?.invoice_type === "Laboratory"){
                dispatch(updateLabInvoice({ formData, toast, navigate }))
            }
            
            if(payment__invoice?.invoice_type === "Pharmacy"){
                dispatch(updatePharmacyInvoice({ formData, toast, navigate }))
            }
            
            if(payment__invoice?.invoice_type === "Radiology"){
                dispatch(updateRadiologyInvoice({ formData, toast, navigate }))
            }
            
            if(payment__invoice?.invoice_type === "Records"){
                dispatch(updateRecordsInvoice({ formData, toast, navigate }))
            }
        }

        if (patient_multiple_payment?.type === 'patient_multiple_payment' && paystack_payment_status?.status === 'success') {
            const formData = {
                mode_of_payment: patient_multiple_payment.invoice.payment_method,
                invoices: patient_multiple_payment.invoice.invoices,
                invoice_type: patient_multiple_payment.invoice.invoice_type,
                payment_reference: ref
            }
            console.log(formData, ':: ')

            dispatch(UpdateMultipleInvoices({ formData, toast, navigate }))
        }
        
        if (payment__invoice?.type === 'multiple_payment' && paystack_payment_status?.status === 'success') {
            const formData = {
                mode_of_payment: multiple_payment.invoice.payment_method,
                invoices: multiple_payment.invoice.invoices,
                invoice_type: multiple_payment.invoice.invoice_type,
                payment_reference: ref
            }
            console.log(formData)

            dispatch(UpdateMultipleInvoices({ formData, toast, navigate }))
        }

        if (payment__invoice?.type === 'multiple_invoice_type_payment' && paystack_payment_status?.status === 'success') {
            const formData = {
                mode_of_payment: paystack_payment_status?.channel,
                invoices: payment__invoice.invoices,
                payment_reference: ref
            }
            console.log(formData)

            dispatch(updateMultipleInvoicesTypes({ formData, toast, navigate }))
        }

        if (payment__invoice?.type === 'laboratory_payment' && paystack_payment_status?.status === 'success') {
            const formData = {
                id: payment__invoice?.id,
                mode_of_payment: paystack_payment_status?.channel,
                payment_reference: ref
            }
            dispatch(updateLabInvoice({ formData, toast, navigate }))
        }

        if (payment__invoice?.type === 'pharmacy_payment' && paystack_payment_status?.status === 'success') {
            const formData = {
                id: payment__invoice?.id,
                mode_of_payment: paystack_payment_status?.channel,
                payment_reference: ref
            }
            dispatch(updatePharmacyInvoice({ formData, toast, navigate }))
        }

        if (payment__invoice?.type === 'radiology_payment' && paystack_payment_status?.status === 'success') {
            const formData = {
                id: payment__invoice?.id,
                mode_of_payment: paystack_payment_status?.channel,
                payment_reference: ref
            }
            dispatch(updateRadiologyInvoice({ formData, toast, navigate }))
        }

        if (payment__invoice?.type === 'records_payment' && paystack_payment_status?.status === 'success') {
            const formData = {
                id: payment__invoice?.id,
                mode_of_payment: paystack_payment_status?.channel,
                payment_reference: ref
            }
            dispatch(updateRecordsInvoice({ formData, toast, navigate }))
        }

    }, [paystack_payment_status])

    return (
        <div className='h-screen flex justify-center items-center'>
            <img
                src={GifWallet}
                alt='wallet'
                className='w-1/2'
            />
        </div>
    )
}

export default CollectionsVerifyPayStackPayment