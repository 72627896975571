import { Fragment } from 'react'

import CollectionsLaboratoryPaymentMethodModal from '../../modals/collections/CollectionsLaboratoryPaymentMethodModal'
import CollectionsPayMulitpleInvoiceModal from '../../modals/collections/CollectionsPayMulitpleInvoiceModal'
import CollectionsPayMulitpleInvoiceTypesModal from '../../modals/collections/CollectionsPayMulitpleInvoiceTypesModal'
import CollectionsPharmacyPaymentMethodModal from '../../modals/collections/CollectionsPharmacyPaymentMethodModal'
import CollectionsPrintInvoiceModal from '../../modals/collections/CollectionsPrintInvoiceModal'
import CollectionsPrintMultipleInvoiceModal from '../../modals/collections/CollectionsPrintMultipleInvoiceModal'
import CollectionsPrintMultipleInvoiceTypesModal from '../../modals/collections/CollectionsPrintMultipleInvoiceTypesModal'
import CollectionsRadiologyPaymentMethodModal from '../../modals/collections/CollectionsRadiologyPaymentMethodModal'
import CollectionsRecordsPaymentMethodModal from '../../modals/collections/CollectionsRecordsPaymentMethodModal'
import CollectionsViewLabInvoiceModal from '../../modals/collections/CollectionsViewLabInvoiceModal'
import CollectionsViewPharmacyInvoiceModal from '../../modals/collections/CollectionsViewPharmacyInvoiceModal'
import CollectionsViewRadiologyInvoiceModal from '../../modals/collections/CollectionsViewRadiologyInvoiceModal'
import CollectionsViewRecordsInvoiceModal from '../../modals/collections/CollectionsViewRecordsInvoiceModal'


const CollectionsFormModals = (props) => {
    return (
        <Fragment>
            {props.type === 'collections__laboratory__paymentmethod' && (
                <CollectionsLaboratoryPaymentMethodModal />
            )}

            {props.type === 'collections__pharmacy__paymentmethod' && (
                <CollectionsPharmacyPaymentMethodModal />
            )}

            {props.type === 'collections__radiology__paymentmethod' && (
                <CollectionsRadiologyPaymentMethodModal />
            )}

            {props.type === 'collections__records__paymentmethod' && (
                <CollectionsRecordsPaymentMethodModal />
            )}

            {props.type === 'collections__view__labinvoice' && (
                <CollectionsViewLabInvoiceModal />
            )}

            {props.type === 'collections__view__pharmacyinvoice' && (
                <CollectionsViewPharmacyInvoiceModal />
            )}

            {props.type === 'collections__view__radiologyinvoice' && (
                <CollectionsViewRadiologyInvoiceModal />
            )}

            {props.type === 'collections__view__recordsinvoice' && (
                <CollectionsViewRecordsInvoiceModal />
            )}

            {props.type === 'collections__pay__multiple_invoice' && (
                <CollectionsPayMulitpleInvoiceModal />
            )}

            {props.type === 'collections__pay__multiple__invoice__type__modal' && (
                <CollectionsPayMulitpleInvoiceTypesModal />
            )}

            {props.type === 'collections__print__invoice' && (
                <CollectionsPrintInvoiceModal />
            )}

            {props.type === 'collections__print__multiple__invoices' && (
                <CollectionsPrintMultipleInvoiceModal />
            )}

            {props.type === 'collections__print__multiple__types__invoices__modal' && (
                <CollectionsPrintMultipleInvoiceTypesModal />
            )}
        </Fragment>
    )
}

export default CollectionsFormModals