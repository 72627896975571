import React, { useState, useEffect } from 'react'
import { useStateContext } from '../../../state/context'
import { FaTimesCircle } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { addAPatientVisitRecord } from '../../../state/actions/nurse.actions'

const NurseViewVisitRecordModal = () => {
    const { modals, setModals } = useStateContext()
    const dispatch = useDispatch()
    const { set_nurses_delete_details } = useSelector((state) => state.root)

    // const handleChange = (e, name) => {
    //     setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))

    // }



    // const handleSubmit = (e) => {
    //     e.preventDefault()
    //     console.log(formData);
    //     // dispatch(addAPatientVisitRecord({ formData, toast, setModals, modals }))


    // }

    // useEffect(() => {
    //     setFormData({ ...formData, id: set_patient_id?.id })
    // }, [])

    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>

                            <>
                                <h4 className='text-dark-green text-[12px] font-semibold'>
                                    Visit Record History
                                </h4>
                                <FaTimesCircle
                                    className='text-dark-yellow cursor-pointer'
                                    onClick={() => (
                                        setModals({
                                            ...modals,
                                            showViewVisitRecordModal:
                                                !modals.showViewVisitRecordModal,
                                        })
                                    )

                                    }
                                    size={20}
                                />
                            </>


                        </div>

                        <form>
                            <div className='flex justify-between-items-center space-x-10'>
                                <div className='space-y-2 w-full'>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Height of fundus</p>
                                            <input
                                                type="title"
                                                name="height_fundus"
                                                disabled
                                                value={set_nurses_delete_details?.height_fundus}


                                                className="border bg-slate-300 rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                // onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                        <div>
                                            <p className='text-sm font-semibold'>Weight</p>
                                            <input
                                                type="title"
                                                name="weight"
                                                value={set_nurses_delete_details?.weight}


                                                className="border bg-slate-300 rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                // onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                    </div>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Presentaion & position</p>
                                            <input
                                                type="title"
                                                name="position_and_presentation"
                                                disabled
                                                value={set_nurses_delete_details?.position_presentation}


                                                className="border bg-slate-300  rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                // onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>PCV</p>
                                            <input
                                                type="title"
                                                name="pcv"
                                                value={set_nurses_delete_details?.pcv}
                                                disabled

                                                className="border bg-slate-300 rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                // onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                    </div>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Relation of Presenting Part of Brim</p>
                                            <input
                                                type="title"
                                                disabled
                                                name="relation_presenting_part_of_brim"
                                                value={set_nurses_delete_details?.relation_presenting}

                                                className="border bg-slate-300 rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                // onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Foetal heart</p>
                                            <input
                                                type="title"
                                                disabled
                                                name="foetal_heart"
                                                value={set_nurses_delete_details?.foetal_heart}


                                                className="border bg-slate-300 rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                // onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                    </div>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Blood Pressure</p>
                                            <input
                                                type="title"
                                                name="blood_pressure"
                                                value={set_nurses_delete_details?.blood_pressure}
                                                disabled


                                                className="border bg-slate-300 rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                // onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Odema</p>
                                            <input
                                                type="title"
                                                value={set_nurses_delete_details?.odema}
                                                name="odema"
                                                disabled


                                                className="border bg-slate-300 rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                // onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                    </div>

                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Remark</p>
                                            <input
                                                type="text"
                                                name="remark"
                                                disabled
                                                value={set_nurses_delete_details?.remark}



                                                className="border bg-slate-300 rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                // onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Urine</p>
                                            <input
                                                type="text"
                                                value={set_nurses_delete_details?.urine}
                                                disabled
                                                name="urine"



                                                className="border bg-slate-300 rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                // onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>


                                    </div>
                                    <div>
                                        <p className='text-sm font-semibold'>Return Date</p>
                                        <input
                                            type="title"
                                            name="return_date"
                                            value={set_nurses_delete_details?.return_date}
                                            disabled

                                            className="border bg-slate-300 rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            // onChange={(e) => handleChange(e, e.target.name)}
                                        />
                                    </div>

                                </div>
                            </div>
                            {/* <div className='mt-4 flex justify-center items-center'>
                                <button
                                    type='submit'
                                    className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'

                                >
                                    Submit
                                </button>
                            </div> */}

                        </form>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default NurseViewVisitRecordModal