import React, { useState, useEffect } from 'react'
import { PageTitle } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getAllEmployeesForLaboratorist } from '../../state/actions/laboratorist.actions'
import LaboratoryEmployeesTable from './LabEmployees/LaboratoryEmployeesTable'
const LaboratoristDashboard = () => {
	const dispatch = useDispatch()


	const { all_employees } = useSelector(state => state.laboratorist)
	const radiologists = all_employees?.filter(employee => employee.user_details.is_radiologist === true)
	const laboratorists = all_employees?.filter(employee => employee.user_details.is_laboratorist === true)
	const patients = all_employees?.filter(employee => employee.user_details.is_patient === true)
	const doctors = all_employees?.filter(employee => employee.user_details.is_doctor === true)
	const nurses = all_employees?.filter(employee => employee.user_details.is_nurse === true)
	const records = all_employees?.filter(employee => employee.user_details.is_records === true)
	const pharmacists = all_employees?.filter(employee => employee.user_details.is_pharmacist === true)
	const accountants = all_employees?.filter(employee => employee.user_details.is_accountant === true)


	// const active_accountants = employees?.filter(employee => employee.user_details.is_accountant === true && employee.user_details.is_active === true).length
	// const active_patients = employees?.filter(employee => employee.user_details.is_patient === true && employee.user_details.is_active === true).length
	// const active_doctors = employees?.filter(employee => employee.user_details.is_doctor === true && employee.user_details.is_active === true).length
	// const active_pharmacists = employees?.filter(employee => employee.user_details.is_pharmacist === true && employee.user_details.is_active === true).length
	// const active_nurses = employees?.filter(employee => employee.user_details.is_nurse === true && employee.user_details.is_active === true).length
	// const active_radiologists = employees?.filter(employee => employee.user_details.is_radiologist === true && employee.user_details.is_active === true).length
	// const active_laboratorists = employees?.filter(employee => employee.user_details.is_laboratorist === true && employee.user_details.is_active === true).length
	// const active_records = employees?.filter(employee => employee.user_details.is_records === true && employee.user_details.is_active === true).length
	// const active_admins = employees?.filter(employee => employee.user_details.is_administrator === true && employee.user_details.is_active === true).length

	useEffect(() => {
		dispatch(getAllEmployeesForLaboratorist())
	}, [])


	return (
		<div>
			<PageTitle type={'laboratorist-dashboard'} />

			<div className='flex flex-wrap justify-between'>
				<LaboratoryEmployeesTable table_title={"Laboratorist List"} data={laboratorists} />
				<LaboratoryEmployeesTable table_title={"Radiologist List"} data={radiologists} />
				<LaboratoryEmployeesTable table_title={"Pharmacist List"} data={pharmacists} />
				<LaboratoryEmployeesTable table_title={"Doctors List"} data={doctors} />
				<LaboratoryEmployeesTable table_title={"Nurses List"} data={nurses} />
				<LaboratoryEmployeesTable table_title={"Records List"} data={records} />
			</div>
		</div>
	)

}

export default LaboratoristDashboard