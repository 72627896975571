import React, { useState, useEffect } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useStateContext } from '../../../state/context'
import { useSelector, useDispatch } from 'react-redux'
import { addALaboratoryInvestigationSamples, updateALabSampleTest } from '../../../state/actions/laboratorist.actions'
import { toast } from 'react-toastify'


const LaboratoryAddSampleModal = () => {
    const { modals, setModals } = useStateContext()
    const { all_lab_category_test, all_lab_investigation_type } = useSelector((state) => state.laboratorist)
    const { set_lab_sample_test } = useSelector((state) => state.root)
    const [formData, setFormData] = useState({
        investigation_category: '',
        investigation_type: '',
        sample_type: '',
        id: ''
    })
    const dispatch = useDispatch()
    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(formData)
        if (modals.showAddLaboratorySamplesModal) {
            dispatch(addALaboratoryInvestigationSamples({ formData, toast, setModals: setModals, modals: modals }))
        }
        if (modals.showUpdateLaboratorySamplesModal) {
            dispatch(updateALabSampleTest({ formData, toast, setModals: setModals, modals: modals }))
        }

    }
    useEffect(() => {
        if (modals.showUpdateLaboratorySamplesModal) {
            setFormData({ ...formData, sample_type: set_lab_sample_test?.title, id: set_lab_sample_test?.id })
        }

    }, [set_lab_sample_test])

    return (
        <div>
            <div className='fixed grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>

                            {modals.showAddLaboratorySamplesModal && (
                                <>
                                    <h4 className='text-dark-green text-[20px] font-bold'>
                                        Add new Sample
                                    </h4>
                                    <FaTimesCircle
                                        className='text-dark-yellow cursor-pointer'
                                        onClick={() => (
                                            setModals({
                                                ...modals,
                                                showAddLaboratorySamplesModal:
                                                    !modals.showAddLaboratorySamplesModal,
                                            })
                                        )

                                        }
                                        size={20}
                                    />
                                </>

                            )}
                            {modals.showUpdateLaboratorySamplesModal && (
                                <>
                                    <h4 className='text-dark-green text-[20px] font-bold'>
                                        Update sample test
                                    </h4>
                                    <FaTimesCircle
                                        className='text-dark-yellow cursor-pointer'
                                        onClick={() => (
                                            setModals({
                                                ...modals,
                                                showUpdateLaboratorySamplesModal:
                                                    !modals.showUpdateLaboratorySamplesModal,
                                            })
                                        )

                                        }
                                        size={20}
                                    />
                                </>

                            )}

                        </div>




                        <form
                            onSubmit={handleSubmit}
                        >

                            {/* {modals.showAddLaboratorySamplesModal && (
                                <h4 className='text-sm font-semibold py-2'>Investigation category</h4>
                            )} */}
                            {modals.showAddLaboratorySamplesModal && (
                                <div className='flex justify-between-items-center space-x-10'>

                                    <div className='space-y-2 w-full'>
                                        {/* <select name="investigation_category" placeholder='Type' id="" className='border rounded mt-2 pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 '
                                            onChange={(e) =>
                                                handleChange(e, e.target.name)
                                            }
                                        >
                                            <option value="">Select Category</option>
                                            {all_lab_category_test?.map((category, index) => (
                                                <option value={category.pkid} key={index}>{category.title}</option>
                                            ))}

                                        </select> */}

                                        <h4 className='text-sm font-semibold py-2'>Select Investigation type</h4>
                                        <div>
                                            <select name="investigation_type" placeholder='Type' id="" className='border rounded pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 '
                                                onChange={(e) =>
                                                    handleChange(e, e.target.name)
                                                }>
                                                <option value="">Select Type</option>
                                                {all_lab_investigation_type?.map((types, index) => (
                                                    <option value={types.pkid} key={index}>{types.type_title}</option>
                                                ))}
                                            </select>

                                        </div>


                                    </div>


                                </div>
                            )}
                            <div className='flex'>
                                <textarea type='text'
                                    name='sample_type'
                                    placeholder='Enter sample ype'
                                    value={formData.sample_type}
                                    onChange={(e) =>
                                        handleChange(e, e.target.name)
                                    }


                                    className='border rounded w-full h-[100px] px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
                                    id="" cols="50" rows="20">

                                </textarea>



                            </div>



                            <div className='mt-4 flex justify-center items-center'>
                                <button
                                    type='submit'
                                    className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
                                // onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>

                        </form>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default LaboratoryAddSampleModal