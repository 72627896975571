import React, { useState, useEffect } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useStateContext } from '../../../state/context'
import { useDispatch, useSelector } from 'react-redux'
import { addPatientPerformanceData } from '../../../state/actions/nurse.actions'
import { toast } from 'react-toastify'
import { getAllPatientPerformanceData } from '../../../state/actions/nurse.actions'



const NurseAddPatientPerfomanceModal = () => {
    const { modals, setModals } = useStateContext()
    const dispatch = useDispatch()
    const { set_patient_id } = useSelector((state) => state.root)
    const { all_patient_performanceData } = useSelector((state) => state.nurse)

    const [formData, setFormData] = useState({
        temperature: '',
        pulse: '',
        respiration: '',
        period: '',
        blood_pressure_sys: '',
        // blood_pressure_sys: '',
        patient_id: '',
        remark: ''

    })
    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))

    }
    const handleSubmit = (e) => {
        e.preventDefault()

        dispatch(addPatientPerformanceData({ formData, toast, setModals: setModals, modals: modals }))

    }

    useEffect(() => {
        const id = set_patient_id


        dispatch(getAllPatientPerformanceData({ patient_id: id }))

    }, [modals.NurseAddPatientPerfomanceModal])

    useEffect(() => {
        setFormData({ ...formData, patient_id: set_patient_id })


    }, [set_patient_id])

    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>

                            <>
                                <h4 className='text-dark-green text-[20px] font-bold'>
                                    Add perfomance data
                                </h4>
                                <FaTimesCircle
                                    className='text-dark-yellow cursor-pointer'
                                    onClick={() => (
                                        setModals({
                                            ...modals,
                                            showNurseAddPerformanceDataModal:
                                                !modals.showNurseAddPerformanceDataModal,
                                        })
                                    )

                                    }
                                    size={20}
                                />
                            </>


                        </div>




                        <form onSubmit={handleSubmit} >

                            {/* <h4 className='text-sm font-semibold py-2'>Investigation category</h4> */}
                            <div className='flex justify-between-items-center space-x-10'>
                                <div className='space-y-2 w-full'>
                                    <input
                                        type="title"
                                        name="temperature"
                                        placeholder="Temperature"

                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                    <input
                                        type="title"
                                        name="pulse"
                                        placeholder="Pulse"

                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                    <input
                                        type="title"
                                        name="respiration"
                                        placeholder="Respiration"

                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                    <input
                                        type="title"
                                        name="blood_pressure_sys"
                                        placeholder="Blood pressure (Systolic)"

                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                    <input
                                        type="title"
                                        name="blood_pressure_dia"
                                        placeholder="Blood pressure (Diastolic)"

                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                    <select name="period" placeholder='Type' id="" className='border rounded mt-2 pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500
                                     '
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    >

                                        <option value="###">Select Period</option>
                                        <option value="2.00 AM">2.00 AM</option>
                                        <option value="6.00 AM">6.00 AM</option>
                                        <option value="10.00 AM">10.00 AM</option>
                                        <option value="2.00 PM">2.00 PM</option>
                                        <option value="6.00.PM">6.00.PM</option>
                                        <option value="10.00 PM">10.00 PM</option>

                                    </select>
                                    <textarea type='text'
                                        name='remark'
                                        placeholder='Remark'
                                        onChange={(e) => handleChange(e, e.target.name)}

                                        className='border rounded w-full h-[100px] px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
                                        id="" cols="30" rows="20">

                                    </textarea>



                                </div>


                            </div>

                            <div className='mt-4 flex justify-center items-center'>
                                <button
                                    type='submit'
                                    className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
                                // onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>

                        </form>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default NurseAddPatientPerfomanceModal