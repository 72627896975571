import React, { useEffect, useState } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { useStateContext } from '../../../state/context'
import { deleteAPatientLabourRecord, deleteAPatientInvestigationRecord, deleteAPatientVisitRecord } from '../../../state/actions/nurse.actions'
import { setNursesDeleteDetails } from '../../../state/actions/actions'
import { toast } from 'react-toastify'

const NurseDeleteLabourSummaryModal = () => {
    const dispatch = useDispatch()
    const { modals, setModals } = useStateContext()
    const { set_nurses_delete_details } = useSelector((state) => state.root)

    const handleSubmit = (e) => {
        e.preventDefault()
        const id = set_nurses_delete_details?.id
        if (modals.showDeleteLabourSummaryModal) {
            dispatch(deleteAPatientLabourRecord({ id, toast, setModals, modals }))
        }
        if (modals.showDeleteInvestigationRecordModal) {
            dispatch(deleteAPatientInvestigationRecord({ id, toast, setModals, modals }))
        }
        if (modals.showDeleteVisitRecordModal) {
            dispatch(deleteAPatientVisitRecord({ id, toast, setModals, modals }))
        }
        dispatch(setNursesDeleteDetails({ data: null }))

    }
    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>

                            <>
                                <h4 className='text-dark-green text-[16px] font-semibold'>
                                    Are you sure you want to delete this record ?
                                </h4>
                                <FaTimesCircle
                                    className='text-dark-yellow cursor-pointer'
                                    onClick={() => {

                                        {
                                            modals.showDeleteLabourSummaryModal && (
                                                setModals({
                                                    ...modals,
                                                    showDeleteLabourSummaryModal:
                                                        !modals.showDeleteLabourSummaryModal,
                                                })
                                            )
                                        }
                                        {
                                            modals.showDeleteInvestigationRecordModal && (
                                                setModals({
                                                    ...modals,
                                                    showDeleteInvestigationRecordModal:
                                                        !modals.showDeleteInvestigationRecordModal,
                                                })
                                            )
                                        }
                                        {
                                            modals.showDeleteVisitRecordModal && (
                                                setModals({
                                                    ...modals,
                                                    showDeleteVisitRecordModal:
                                                        !modals.showDeleteVisitRecordModal,
                                                })
                                            )
                                        }

                                    }

                                    }
                                    size={20}
                                />
                            </>


                        </div>

                        <form
                            onClick={handleSubmit}
                        >
                            <div className='mt-4 flex justify-center items-center'>
                                <button
                                    type='submit'
                                    className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'

                                >
                                    Delete
                                </button>
                            </div>

                        </form>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default NurseDeleteLabourSummaryModal