import React, { useEffect } from 'react'
import { useStateContext } from '../../../state/context'
import { useSelector, useDispatch } from 'react-redux'
import { setGetDiagnosisPrescriptions } from '../../../state/actions/actions'
import { getAllPatientDiagnosisPrescriptions } from '../../../state/actions/nurse.actions'

const NurseDiagnosisTable = (props) => {
    const { opdState, setOpdState } = useStateContext()
    const { modals, setModals } = useStateContext()
    const { all_patient_diagnosis } = useSelector((state) => state.nurse)
    const { set_administer_opd, set_get_diagnosisPrescriptions } = useSelector((state) => state.root)
    const dispatch = useDispatch()
    // useEffect(() => {

    //     const id = set_get_diagnosisPrescriptions?.diagnosis_id

    //     dispatch(getAllPatientDiagnosisPrescriptions({ id }))

    // }, [set_get_diagnosisPrescriptions, modals.showNurseAdministerOPDModal])

    return (
        <tbody>
            {props.data?.map((item, index) => (
                <tr
                    className='relative'
                    key={index}

                >
                    <td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
                        {index + 1}
                    </td>
                    <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                        {item.diagnosis_name}          </td>
                    <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                        {item.created_at.slice(0,10)}
                    </td>

                    <td className=' text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                        <div className='flex space-x-4 relative '>
                            <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => {
                                    const id = item.id
                                    const data = {
                                        diagnosis_id: item.id,
                                        description: item.description,
                                        title: item.diagnosis_name
                                    }
                                    dispatch(setGetDiagnosisPrescriptions({ data }))
                                    dispatch(getAllPatientDiagnosisPrescriptions({ id }))
                                    setOpdState({
                                        ...opdState, showOpdTable: false,
                                        showDiagnosisPrescription: true
                                    })


                                }}
                            >
                                Manage
                            </button>


                        </div>
                    </td>
                </tr>
            ))}
        </tbody>
    )
}

export default NurseDiagnosisTable