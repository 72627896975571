import { createSlice } from '@reduxjs/toolkit'

import {
    createDrugCategory, createSupplyPoint,
    deleteDrugCategory, deleteSupplyPoint,
    getDrugCategories, getSupplyPoints,
    updateDrugCategory, updateSupplyPoint,
    createDrug, getDrugs, updateDrug, deleteDrug,
    getDrugBinCards, createDrugBinCard, dispenseDrugToSupplyPoint,
    resetDrugBinCards, getSupplyPointInventory,
    getSupplyPointDrugBinCards, resetSupplyPointDrugBinCards,
    lendDrugToSupplyPoint, issueOutDrug, getSupplyPointBinCards,
    resetSupplyPointBinCards, getPrescriptions, getDrugPrices,
    createPrescriptionsInvoice, getPrescriptionsInvoices, getPaidPrescriptions,
    resetPaidPrescriptions, getPharmacistProfiles, assignPharmacistToSupplyPoint, getSupplyPointStaff, resetSupplyPointStaff, resetSupplyPointInventory, dispenseDrug, getClinicalData, createMultiplePrescriptionsInvoice, setCurrentMultipleInvoices, getMultiplePaidPrescriptions, multipleDispenseDrug, setCurrentMultiplePrescriptions, getDashboardData
} from '../actions/pharmacist.actions'



const pharmacistSlice = createSlice({
    name: 'pharmacist',
    initialState: {
        drugs: null,
        drug_bincards: null,
        drugCategories: null,
        supplyPoints: null,
        dispensed_drug: null,
        dashboard_data: null,
        supplypoint_inventory: null,
        supplypoint_inventory_drug_bincards: null,
        supplypoint_bincards: null,
        prescriptions: null,
        drugs_price: null,
        prescriptions_invoice: null,
        prescriptions_invoices: null,
        paid_prescriptions: null,
        multiple_paid_prescriptions: null,
        pharmacist_profiles: null,
        assigned_supplypoint: null,
        supplypoint_staff: null,
        patient_drugdispensed: null,
        clinical_data: null,
        multiple_invoices_dispense: null,
        multiple_prescriptions: null,
        pharmacistLoading: null,
        pharmacistErrors: null
    },
    extraReducers: {
        [getDashboardData.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getDashboardData.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.dashboard_data = action.payload
        },
        [getDashboardData.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },
   
        [getDrugCategories.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getDrugCategories.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.drugCategories = action.payload
        },
        [getDrugCategories.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createDrugCategory.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createDrugCategory.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.drugCategories = [...state.drugCategories, action.payload]
        },
        [createDrugCategory.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [updateDrugCategory.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [updateDrugCategory.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            const index = state.drugCategories.findIndex(drugCategory => drugCategory.id === action.payload.id)
            state.drugCategories[index] = action.payload
        },
        [updateDrugCategory.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [deleteDrugCategory.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [deleteDrugCategory.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.drugCategories = state.drugCategories.filter(drugCategory => drugCategory.id !== action.payload)
        },
        [deleteDrugCategory.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getSupplyPoints.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getSupplyPoints.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplyPoints = action.payload
        },
        [getSupplyPoints.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createSupplyPoint.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createSupplyPoint.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplyPoints = [...state.supplyPoints, action.payload]
        },
        [createSupplyPoint.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [updateSupplyPoint.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [updateSupplyPoint.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            const index = state.supplyPoints.findIndex(supplyPoint => supplyPoint.id === action.payload.id)
            state.supplyPoints[index] = action.payload
        },
        [updateSupplyPoint.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [deleteSupplyPoint.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [deleteSupplyPoint.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplyPoints = state.supplyPoints.filter(supplyPoint => supplyPoint.id !== action.payload)
        },
        [deleteSupplyPoint.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getDrugs.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getDrugs.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.drugs = action.payload
        },
        [getDrugs.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createDrug.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createDrug.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.drugs = [action.payload, ...state.drugs]
        },
        [createDrug.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [updateDrug.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [updateDrug.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            const index = state.drugs.findIndex(drug => drug.id === action.payload.id)
            state.drugs[index] = action.payload
        },
        [updateDrug.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [deleteDrug.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [deleteDrug.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.drugs = state.drugs.filter(drug => drug.id !== action.payload)
        },
        [deleteDrug.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getDrugBinCards.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getDrugBinCards.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.drug_bincards = action.payload
        },
        [getDrugBinCards.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createDrugBinCard.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createDrugBinCard.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.drug_bincards = [action.payload, ...state.drug_bincards]
        },
        [createDrugBinCard.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [dispenseDrugToSupplyPoint.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [dispenseDrugToSupplyPoint.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.dispensed_drug = action.payload.data
            state.drug_bincards = [action.payload.inventory, ...state.drug_bincards]
            // state.drug_bincards = state.drug_bincards.unshift(action.payload.inventory)
        },
        [dispenseDrugToSupplyPoint.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [dispenseDrug.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [dispenseDrug.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.patient_drugdispensed = action.payload
        },
        [dispenseDrug.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [multipleDispenseDrug.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [multipleDispenseDrug.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.patient_drugdispensed = action.payload
        },
        [multipleDispenseDrug.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [resetDrugBinCards.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [resetDrugBinCards.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.drug_bincards = action.payload
        },
        [resetDrugBinCards.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getSupplyPointInventory.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getSupplyPointInventory.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplypoint_inventory = action.payload
        },
        [getSupplyPointInventory.rejected]: (state, action) => {
            state.pharmacistLoading = false
            // state.pharmacistErrors = action.payload
        },

        [resetSupplyPointInventory.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [resetSupplyPointInventory.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplypoint_inventory = action.payload
        },
        [resetSupplyPointInventory.rejected]: (state, action) => {
            state.pharmacistLoading = false
            // state.pharmacistErrors = action.payload
        },

        [getSupplyPointDrugBinCards.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getSupplyPointDrugBinCards.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplypoint_inventory_drug_bincards = action.payload
        },
        [getSupplyPointDrugBinCards.rejected]: (state, action) => {
            state.pharmacistLoading = false
            // state.pharmacistErrors = action.payload
        },

        [resetSupplyPointDrugBinCards.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [resetSupplyPointDrugBinCards.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplypoint_inventory_drug_bincards = action.payload
        },
        [resetSupplyPointDrugBinCards.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [lendDrugToSupplyPoint.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [lendDrugToSupplyPoint.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplypoint_inventory_drug_bincards = [action.payload, ...state.supplypoint_inventory_drug_bincards]
        },
        [lendDrugToSupplyPoint.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [issueOutDrug.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [issueOutDrug.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplypoint_inventory_drug_bincards = [action.payload, ...state.supplypoint_inventory_drug_bincards]
        },
        [issueOutDrug.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getSupplyPointBinCards.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getSupplyPointBinCards.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplypoint_bincards = action.payload
        },
        [getSupplyPointBinCards.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [resetSupplyPointBinCards.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [resetSupplyPointBinCards.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplypoint_bincards = action.payload
        },
        [resetSupplyPointBinCards.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getPrescriptions.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getPrescriptions.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.prescriptions = action.payload
        },
        [getPrescriptions.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getDrugPrices.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getDrugPrices.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.drugs_price = action.payload
        },
        [getDrugPrices.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createPrescriptionsInvoice.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createPrescriptionsInvoice.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.prescriptions_invoice = action.payload
        },
        [createPrescriptionsInvoice.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [createMultiplePrescriptionsInvoice.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [createMultiplePrescriptionsInvoice.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.prescriptions_invoice = action.payload
        },
        [createMultiplePrescriptionsInvoice.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getPrescriptionsInvoices.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getPrescriptionsInvoices.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.prescriptions_invoices = action.payload
        },
        [getPrescriptionsInvoices.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getPaidPrescriptions.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getPaidPrescriptions.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.paid_prescriptions = action.payload
        },
        [getPaidPrescriptions.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getMultiplePaidPrescriptions.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getMultiplePaidPrescriptions.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.multiple_paid_prescriptions = action.payload
        },
        [getMultiplePaidPrescriptions.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [resetPaidPrescriptions.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [resetPaidPrescriptions.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.paid_prescriptions = action.payload
        },
        [resetPaidPrescriptions.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [setCurrentMultipleInvoices.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [setCurrentMultipleInvoices.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.multiple_invoices_dispense = action.payload
        },
        [setCurrentMultipleInvoices.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [setCurrentMultiplePrescriptions.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [setCurrentMultiplePrescriptions.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.multiple_prescriptions = action.payload
        },
        [setCurrentMultiplePrescriptions.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getPharmacistProfiles.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getPharmacistProfiles.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacist_profiles = action.payload
        },
        [getPharmacistProfiles.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [assignPharmacistToSupplyPoint.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [assignPharmacistToSupplyPoint.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.assigned_supplypoint = action.payload
        },
        [assignPharmacistToSupplyPoint.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getSupplyPointStaff.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getSupplyPointStaff.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplypoint_staff = action.payload
        },
        [getSupplyPointStaff.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [resetSupplyPointStaff.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [resetSupplyPointStaff.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.supplypoint_staff = action.payload
        },
        [resetSupplyPointStaff.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

        [getClinicalData.pending]: (state, action) => {
            state.pharmacistLoading = true
        },
        [getClinicalData.fulfilled]: (state, action) => {
            state.pharmacistLoading = false
            state.clinical_data = action.payload
        },
        [getClinicalData.rejected]: (state, action) => {
            state.pharmacistLoading = false
            state.pharmacistErrors = action.payload
        },

    }
})


export default pharmacistSlice.reducer