import _ from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'
import { MdOutlineInventory } from 'react-icons/md'

import { useStateContext } from '../../state/context'
import { setCurrentPrescriptions } from '../../state/actions/actions'
import PaginationButtons from '../utils/PaginationButtons'


const PharmacistPrescriptionsTable = ({ data, showList, setShowList, title, pages, currentPage, handlePagination }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const prescriptions = _.groupBy(data, item => item?.patient_prescribed?.first_name)
    // console.log(typeof (prescriptions), prescriptions);

    // const groupByCategory = data?.groupBy(item=> item.patient_prescribed.id)
    // console.log(groupByCategory);

    return (
        <div className="flex justify-between items-center w-full px-1">
            <div className="flex flex-col w-full cursor-pointer">
                <div className="py-2">
                    <div
                        className="flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1"
                        onClick={() => setShowList(!showList)}
                    >
                        <h4 className="text-dark-blue text-[14px] font-bold">
                            {title}
                        </h4>
                        <MdOutlineInventory className="text-dark-blue" size={20} />
                    </div>
                    {showList && (
                        <table className="border rounded w-full cursor-default">
                            <thead className="">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] py-4 text-left"
                                    >
                                        Patient ID
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Patient Name
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Prescriptions Count
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Doctor
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] py-4 text-left"
                                    >
                                        Picture
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        Invoice
                                    </th>
                                    {/* <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        Result
                                    </th> */}
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.values(prescriptions)?.map((item, index) => (
                                    <tr key={`${index}`}>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item[0]?.patient_id}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item[0]?.patient_prescribed?.first_name} {item[0]?.patient_prescribed?.last_name}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.length}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item[0]?.author?.first_name} {item[0]?.author?.last_name}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            <img
                                                src={`${process.env.REACT_APP_API_ROOT}${item[0].patient_prescribed.profile_photo}`}
                                                alt=""
                                                className='w-[40px] h-[40px] rounded-[50%] mt-[-5px]'
                                            />
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item[0]?.invoice_generated ? (
                                                <span className="bg-teal-500 rounded text-white text-[10px] px-3 py-1 font-medium">Generated</span>
                                            ) : (

                                                <button
                                                    type="button"
                                                    className="inline-block px-2 py-2 bg-yellow-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                    onClick={() => {
                                                        dispatch(setCurrentPrescriptions({ data: item }))
                                                        setModals({ ...modals, showPharmacistDrugInvoiceModal: !modals.showPharmacistDrugInvoiceModal })
                                                    }}
                                                >
                                                    Generate
                                                </button>
                                            )}
                                        </td>
                                        <td className=" flex text-2xl space-x-4 text-gray-900 font-semibold py-2 whitespace-nowrap">
                                            <button
                                                type="button"
                                                className="inline-block px-4 py-2 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                onClick={() => {
                                                    dispatch(setCurrentPrescriptions({ data: item }))
                                                    setModals({ ...modals, showPharmacistViewPrescriptionModal: !modals.showPharmacistViewPrescriptionModal })
                                                }}
                                            >
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            {/* <PaginationButtons
                                pages={pages}
                                currentPage={currentPage}
                                handlePagination={handlePagination}
                            /> */}
                        </table>
                    )}
                </div>
            </div>
        </div >
    )
}

export default PharmacistPrescriptionsTable