import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../state/context'
import { getAllMessages, getUsers, resetChatState } from '../../state/actions/chat.actions'
import { PageTitle, TextChat, VideoCall } from '../../components'


const Chat = () => {
    const dispatch = useDispatch()

    const { chatState, setChatState } = useStateContext()

    const [showVideoCallUI, setShowVideoCallUI] = useState(false)
    const [showUserProfile, setShowUserProfile] = useState(false)
    const [showProfileDropDown, setShowProfileDropDown] = useState(false)


    const { users } = useSelector(state => state.chat)
    const { data } = useSelector(state => state.auth.user)

    useEffect(() => {
        dispatch(getUsers())

        return () => {
            dispatch(resetChatState({ data: null }))
        }
    }, [])


    return (
        <div>
            <PageTitle
                type={'chat'}

                showVideoCallUI={showVideoCallUI}
                setShowVideoCallUI={setShowVideoCallUI}
            />

            <div className="flex justify-between gap-1 mt-2">
                {!chatState.showVideoCallUI && (
                    <TextChat
                        showUserProfile={showUserProfile}
                        setShowUserProfile={setShowUserProfile}

                        showProfileDropDown={showProfileDropDown}
                        setShowProfileDropDown={setShowProfileDropDown}
                        users={users?.filter(user => user.id !== data?.id)}
                    />
                )}

                {chatState.showVideoCallUI && (
                    <VideoCall
                        showVideoCallUI={showVideoCallUI}
                        setShowVideoCallUI={setShowVideoCallUI}
                        users={users?.filter(user => user.id !== data?.id)}
                    />
                )}
            </div>
        </div >
    )
}

export default Chat