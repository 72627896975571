import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useStateContext } from '../../../state/context'
import { FaTimesCircle } from 'react-icons/fa'
import { addAPatientTreatmentReport } from '../../../state/actions/nurse.actions'
import { toast } from 'react-toastify'


const NurseImmunizationCardModal = () => {
    const { all_nurse_patients } = useSelector((state) => state.nurse)
    const { set_patient_id, set_immunization_patient } = useSelector((state) => state.root)
    const dispatch = useDispatch()
    const { modals, setModals } = useStateContext()


    return (
        <div>
            <div className='fixed grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[1200px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>
                            <>
                                <h4 className='text-dark-green text-[16px] font-light'>
                                    Baby Immunization Card
                                </h4>
                                <FaTimesCircle
                                    className='text-dark-yellow cursor-pointer'
                                    onClick={() => (
                                        setModals({
                                            ...modals,
                                            showImmunizationCardModal:
                                                !modals.showImmunizationCardModal,
                                        })
                                    )}
                                    size={20}
                                />
                            </>
                        </div>


                        <form >
                            <div className=''>
                                <div className=' flex space-x-4 width-full bg-slate-300  pl-2 pt-2 rounded-md'>
                                    <img src={`${process.env.REACT_APP_API_ROOT}${set_immunization_patient?.photo}`} alt="" srcset="" className='h-[50px] w-[50px] mt-1 mb-2' />
                                    <div className='flex flex-col'>
                                        <p className='text-lg font-semibold text-blue-500 '>{set_immunization_patient?.first_name} {set_immunization_patient?.last_name} [{set_immunization_patient?.patient_number}]</p>
                                        <p>Immunization Card</p>
                                    </div>

                                </div>
                                <div className='flex justify-between items-center space-x-4'>
                                    <div className='w-full'>
                                        <div className=''>
                                            <div className='mt-4'>
                                                <div className='flex space-x-14'>
                                                    <p className='text-sm font-semibold'>Name:</p>
                                                    <p className='text-sm font-semibold'>{set_immunization_patient?.first_name} {set_immunization_patient?.last_name}</p>

                                                </div>
                                                <div className='flex space-x-10'>
                                                    <p className='text-sm font-semibold'>Address:</p>
                                                    <p className='text-sm font-semibold'>{set_immunization_patient?.address}</p>

                                                </div>
                                                <div className='flex space-x-2'>
                                                    <p className='text-sm font-semibold'>Date of Birth:</p>
                                                    <p className='text-sm font-semibold'>{set_immunization_patient?.dob}</p>

                                                </div>


                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            <p>Next Appointment : </p>
                                            <p className='py-2'>1.  .......................................................................................................</p>
                                            <p className='py-2'>2.  .......................................................................................................</p>
                                            <p className='py-2' >3.  .......................................................................................................</p>
                                            <p className='py-2' >4.  .......................................................................................................</p>
                                            <p className='py-2' >5.  .......................................................................................................</p>
                                            <p className='py-2' >6.  .......................................................................................................</p>
                                            <p className='py-2' >7.  .......................................................................................................</p>

                                        </div>
                                    </div>
                                    <div className='w-full'>
                                        <div className='flex flex-col'>
                                            <p className='text-sm font-light'>Date ______________________________________________</p>
                                            <div className='flex justify-between'>
                                                <p className='text-sm font-light'>At Birth</p>
                                                <div>
                                                    <p className='text-sm font-light' >B.C.G</p>
                                                    <p className='text-sm font-light '>OPV 0</p>
                                                    <p className='text-sm font-light'>Hepatitis B1</p>
                                                </div>

                                            </div>

                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='text-sm font-light'>Date ______________________________________________</p>
                                            <div className='flex justify-between'>
                                                <p className='text-sm font-light'>6 weeks later</p>
                                                <div>
                                                    <p className='text-sm font-light' >OPV 1</p>
                                                    <p className='text-sm font-light '>DPT 1</p>
                                                    <p className='text-sm font-light'>Hepatitis B2</p>
                                                </div>

                                            </div>

                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='text-sm font-light'>Date ______________________________________________</p>
                                            <div className='flex justify-between'>
                                                <p className='text-sm font-light'>10 weeks</p>
                                                <div>
                                                    <p className='text-sm font-light' >OPV 2</p>
                                                    <p className='text-sm font-light'>DPT 2</p>
                                                </div>

                                            </div>

                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='text-sm font-light'>Date ______________________________________________</p>
                                            <div className='flex justify-between'>
                                                <p className='text-sm font-light'>14 weeks</p>
                                                <div>
                                                    <p className='text-sm font-light' >OPV 3</p>
                                                    <p className='text-sm font-light '>DPT 3</p>
                                                    <p className='text-sm font-light'>Hepatitis B3</p>
                                                </div>

                                            </div>

                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='text-sm font-light'>Date ______________________________________________</p>
                                            <div className='flex justify-between'>
                                                <p className='text-sm font-light'>9 - 11 months</p>
                                                <div>
                                                    <p className='text-sm font-light' >Measles</p>
                                                    <p className='text-sm font-light '>Yellow fever</p>
                                                    <p className='text-sm font-light'>Vitamin A1</p>
                                                </div>

                                            </div>

                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='text-sm font-light'>Date ______________________________________________</p>
                                            <div className='flex justify-between py-2'>
                                                <p className='text-sm font-light'>At 15 months</p>
                                                <div>
                                                    <p className='text-sm font-light' >Vitamin A2</p>

                                                </div>

                                            </div>

                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='text-sm font-light'>Date ______________________________________________</p>
                                            <div className='flex justify-between py-2'>
                                                <p className='text-sm font-light'>At school Age (5 years) Booster Dose</p>
                                                <div>
                                                    <p className='text-sm font-light' >Tetanus toxoid</p>

                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>


                        </form>

                    </div>
                    <div className='mt-2 flex justify-center items-center'>
                        <button
                            type='submit'
                            className='inline-block w-[175px] px-6 py-2.5 bg-blue-400 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
                            onClick={() => {
                                // print()
                            }}
                        >
                            Print
                        </button>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default NurseImmunizationCardModal