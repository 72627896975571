import React, { useState, useEffect } from 'react'
import { BsPersonPlus } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'

import Pagination from '../../utils/Pagination'
import { useStateContext } from '../../../state/context'
import { setManageAdmittedPatient, setPatientID } from '../../../state/actions/actions'

const AdmittedPatientTable = (props) => {
    const dispatch = useDispatch()

    const { opdState, setOpdState } = useStateContext()

    const [currentData, setCurrentData] = useState([])

    return (
        <div className='py-2'>
            <div className='flex justify-start items-center space-x-4 bg-gray-200 p-1'>
                <h4 className='text-dark-blue text-[14px] font-bold'>
                    Admitted Patients
                </h4>
                <BsPersonPlus
                    className='text-dark-blue'
                    size={20}
                />
                {/* <input type="text" name="filter" placeholder='Search' id="" className='ml-2 pl-4 text-sm rounded h-[30px] w-[340px]' onChange={(e) => handleChange(e, e.target.name)} /> */}
            </div>
            <table className='border rounded w-full cursor-default'>
                <thead className=''>
                    <tr>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                        >
                            S/N
                        </th>

                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                        >
                            Patient Number
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
                        >
                            Full name
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 py-4 text-left'
                        >
                            Gender
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 py-4 text-left'
                        >
                            Ward
                        </th>


                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 py-4 text-left'
                        >
                            Bed Number
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 py-4 text-left'
                        >
                            Actions
                        </th>
                    </tr>
                </thead>


                <tbody>
                    {currentData?.map((item, index) => (
                        <tr
                            className='relative'
                            key={index}

                        >
                            <td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
                                {index + 1}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item?.patient_profile?.patient_number}       </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.patient?.first_name} {item.patient?.last_name}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item?.patient_profile?.gender}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item?.ward?.name}
                            </td>


                            <td className=' text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                <div className='flex space-x-4 relative '>
                                    {item?.bed_number?.bed_number}
                                </div>
                            </td>
                            <td className=' text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                <div className='flex space-x-4 relative '>
                                    <button
                                        type="button"
                                        className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                        onClick={() => {
                                            setOpdState({
                                                ...opdState,
                                                showAdmittedPatientManagement: true

                                            })

                                            const mydata = {
                                                first_name: item?.patient?.first_name,
                                                last_name: item?.patient?.last_name,
                                                id: item?.patient?.id,
                                                patient_number: item?.patient_profile?.patient_number
                                            }
                                            const data = item.patient?.id


                                            dispatch(setPatientID({ data }))

                                            dispatch(setManageAdmittedPatient({ mydata }))
                                        }
                                        }
                                    >
                                        Manage
                                    </button>


                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <Pagination
                data={props?.data}
                currentData={currentData}
                setCurrentData={setCurrentData}
            />
        </div>
    )
}

export default AdmittedPatientTable