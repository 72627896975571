import React, { useEffect, useState } from 'react'
import { useStateContext } from '../../../state/context'
import { FaTimesCircle } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { bookAPatientForImmunization } from '../../../state/actions/nurse.actions'
import { toast } from 'react-toastify'
import { Antigens } from '../../../data'

const NurseAddChildForImmunizationModal = () => {
    const { modals, setModals } = useStateContext()
    const { all_nurse_patients } = useSelector((state) => state.nurse)
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({
        patient: '',
        filter: '',
        id: ''


    })
    const filtered = all_nurse_patients?.filter((item) => {
        return item.patient_number === `${formData.filter}`
    })

    const handleChange = (e, name) => { setFormData((prevState) => ({ ...prevState, [name]: e.target.value })) }

    const handleSubmit = (e) => {
        e.preventDefault()
        const id = filtered[0]?.user_id

        dispatch(bookAPatientForImmunization({ id, formData, toast, setModals, modals }))

    }
    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>

                            <>
                                <h4 className='text-dark-green text-[20px] font-bold'>
                                    Add  a  child for immunization
                                </h4>
                                <FaTimesCircle
                                    className='text-dark-yellow cursor-pointer'
                                    onClick={() => (
                                        setModals({
                                            ...modals,
                                            showAddChildForImmunizationModal:
                                                !modals.showAddChildForImmunizationModal,
                                        })
                                    )

                                    }
                                    size={20}
                                />
                            </>


                        </div>




                        <form
                            onSubmit={handleSubmit}
                        >

                            <div className='flex justify-between-items-center space-x-10'>
                                <div className='space-y-2 w-full'>
                                    {filtered != '' && (
                                        <input
                                            type="title"
                                            value={`${filtered[0]?.first_name} ${filtered[0]?.last_name} - [${filtered[0]?.patient_number}]`}


                                            className="border text-sm font-semibold bg-slate-300 rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            onChange={(e) => handleChange(e, e.target.name)}
                                        />
                                    )}

                                    <input
                                        type="title"
                                        name="filter"
                                        placeholder="Enter patient number"

                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />

                                </div>

                            </div>

                            <div className='mt-4 flex justify-center items-center'>
                                <button
                                    type='submit'
                                    className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'

                                >
                                    Submit
                                </button>
                            </div>

                        </form>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default NurseAddChildForImmunizationModal