import React from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useStateContext } from '../../../state/context'
import { deletePatientRadiologyInvestigationType } from '../../../state/actions/radiologist.actions'
import { toast } from 'react-toastify'
const RadiologyDeleteInvestigationTypeModal = () => {
    const dispatch = useDispatch()
    const { modals, setModals } = useStateContext()
    const { set_delete_radiology_investigation_type } = useSelector((state) => state.root)

    const handleSubmit = (e) => {
        const id = set_delete_radiology_investigation_type?.investigation_id
        dispatch(deletePatientRadiologyInvestigationType({ id, toast, setModals: setModals, modals: modals }))

    }
    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>



                            <h4 className='text-dark-green text-[20px] font-bold'>
                                Are you sure you want to delete - {set_delete_radiology_investigation_type?.title}
                            </h4>




                            <FaTimesCircle
                                className='text-dark-yellow cursor-pointer'
                                onClick={() => {

                                    // dispatch(setDeleteRadiologyCategory({ data: null }))
                                    setModals({
                                        ...modals,
                                        showDeleteRadiologyInvestigationTypeModal:
                                            !modals.showDeleteRadiologyInvestigationTypeModal,
                                    })
                                }


                                }
                                size={20}
                            />

                        </div>
                        <button
                            type='button'
                            className=' px-6 py-2.5 mx-auto bg-dark-green  text-white font-medium text-xs w-[200px] rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease'
                            onClick={handleSubmit}
                        >
                            <span className='flex items-center justify-between pl-12'>
                                Delete

                            </span>
                        </button>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default RadiologyDeleteInvestigationTypeModal