import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { HiMail } from 'react-icons/hi'
import { GoBell } from 'react-icons/go'
import { getCurrentUserNotifications, UsersAllNotifications, readANotification } from '../../state/actions/notifications.actions'
import { useEffect, useState } from 'react'
import { userLogout } from '../../state/actions/auth.actions'
import Role from '../utils/Role'
// import { socket } from '../../config/SocketService'
import { socket } from '../../config/SocketService'

const TopBar = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [showEmailMessagesNotifications, setShowEmailMessagesNotifications] = useState(false)
    const [showOtherNotifications, setShowOtherNotifications] = useState(false)
    const [formData, setFormData] = useState({})
    const [colorIndex, setColorIndex] = useState(-1)
    const [showNotificationBody, setShowNotificationBody] = useState(false)
    const [notificationBody, setNotificationBody] = useState('')

    const { profile, user } = useSelector(state => state.auth)
    const { notifications, create_notification } = useSelector(state => state.notification)

    const email_notifications = notifications?.filter(item => {
        return item.notification_type === 'email notification' || item.notification_type === 'message notification'
    })

    const other_notifications = notifications?.filter(item => {
        return item.notification_type !== 'email notification' && item.notification_type !== 'message notification'
    })
    useEffect(() => {
        dispatch(UsersAllNotifications())
    }, [create_notification])

    useEffect(() => {
        dispatch(UsersAllNotifications())
    }, [])


    return (
        <div className="flex py-1 justify-between border-b border-gray-200 sticky top-0 z-[5] bg-white">
            <div className="flex justify-between items-center pl-2">
                <img
                    className='w-[1.7em] h-[1.7em] rounded-full'
                    src={profile?.profile_photo}
                    alt="profile__photo"
                />
                <div className="flex flex-col items-start ml-[5px]">
                    <p className='text-[12px] mb-[-16px]'>{user?.data?.first_name} {user?.data?.last_name}</p>
                    <Role
                        user={user?.data}
                    />
                </div>
            </div>

            <div className="flex px-5 pr-2 justify-between items-center space-x-[60px]">
                <div className="flex justify-between items-center space-x-5">
                    <div className="relative"
                        onClick={() => {
                            setShowEmailMessagesNotifications(!showEmailMessagesNotifications)
                        }}
                    >
                        <span className='absolute bottom-4 right-[-5px] text-[12px] text-red-200 bg-dark-yellow p-[2px] rounded-full h-4 w-4 flex items-center justify-center hover:cursor-pointer'>{email_notifications?.length}</span>
                        <HiMail
                            size={30}
                            color={'#19212E'}
                            className="cursor-pointer"
                        />
                    </div>
                    <div className="relative"
                        onClick={() => {
                            setShowOtherNotifications(!showOtherNotifications)
                        }}
                    >
                        <span className='absolute bottom-4 right-[-5px] text-[10px] text-red-200 bg-dark-yellow p-[2px] rounded-full h-4 w-4 flex items-center justify-center hover:cursor-pointer'>{other_notifications?.length}</span>
                        <GoBell
                            size={20}
                            color={'#19212E'}
                            className="cursor-pointer"
                        />
                    </div>
                    {showEmailMessagesNotifications && (
                        <div className='absolute z-30 rounded-md shadow-lg right-4 top-10 w-[200px] border bg-white py-2 max-h-[400px] overflow-y-auto patient__prescriptions'>
                            <p className='text-[9px] px-2 '>Emails & messages notifications</p>
                            {email_notifications?.map((item, index) => (

                                <p className={`${colorIndex === index ? 'bg-slate-400' : ''} text-[10px] font-light hover:cursor-pointer px-2`}
                                    onClick={() => {
                                        setColorIndex(index)
                                        dispatch(readANotification({ formData, id: item.id }))
                                    }}
                                >{item.notification_title}</p>


                            ))}

                        </div>)}
                    {showOtherNotifications && (
                        <div className='absolute z-30 rounded-md shadow-lg right-4 top-10 w-[200px] border bg-white py-2 max-h-[400px] overflow-y-auto patient__prescriptions'>
                            <p className='text-[9px] px-2 '>Other notifications</p>
                            {other_notifications?.map((item, index) => (

                                <p className={`${colorIndex === index ? 'bg-slate-400' : ''} text-[10px] font-light hover:cursor-pointer px-2`}
                                    onClick={() => {
                                        setColorIndex(index)
                                        dispatch(readANotification({ formData, id: item.id }))
                                        setShowNotificationBody(false)
                                    }}
                                    onMouseEnter={() => {
                                        setNotificationBody(item.notification)
                                        setShowNotificationBody(!showNotificationBody)

                                    }}
                                    onMouseLeave={() => {
                                        setShowNotificationBody(false)
                                    }}

                                >{item.notification_title}</p>


                            ))}

                        </div>)}
                    {showNotificationBody && (
                        <div className='absolute z-30 right-[9em] top-[2em] w-[200px] border bg-white py-2'>
                            <p className='text-[10px] px-2 leading-[16px] font-normal'>{notificationBody}</p>


                        </div>
                    )}
                </div>
                <button
                    type="button"
                    className="inline-block px-2 py-2 bg-gray-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-700 hover:shadow-lg focus:bg-gray-800 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => dispatch(userLogout({ toast, navigate }))}
                >
                    Logout
                </button>
            </div>
        </div>
    )
}

export default TopBar