import React from 'react'
import { useStateContext } from '../../../state/context'
import { FaTimesCircle } from 'react-icons/fa'
import { useSelector } from 'react-redux'


const NurseGenerateAntenatalCardModal = () => {
    const { modals, setModals } = useStateContext()
    const { set_patient_id } = useSelector((state) => state.root)
    return (
        <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
            <div className='bg-white w-[800px] rounded px-8 py-[10px]'>
                <div className='flex flex-col space-y- w-full'>
                    <div className='flex justify-between items-center'>
                        <h4 className='text-dark-green text-[20px] font-bold'>
                            Antenatal card
                        </h4>
                        <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            onClick={() =>
                                setModals({
                                    ...modals,
                                    showGenerateAntenatalCardModal:
                                        !modals.showGenerateAntenatalCardModal,
                                })
                            }
                            size={20}
                        />
                    </div>
                    <button
                        type="button"
                        className=" px-6 py-2.5 bg-sky-600 text-white w-[100px] mb-2 font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                        onClick={() => {
                            // print()
                        }
                        }
                    >
                        Print
                    </button>
                    <div className='w-full h-[80px] bg-slate-300 mt-1 mb-3 rounded-sm'>
                        <p className='font-semibold mx-2 mt-2'>Patient Name: {set_patient_id?.first_name} {set_patient_id?.last_name}</p>
                        <p className='font-semibold mx-2 mt-2'>Patient Number : {set_patient_id?.patient_number}</p>

                    </div>
                    <p className='text-sm italic font-semibold text-indigo-500'>When coming in for delivery, please come along with the underlisted items</p>
                </div>

                <form
                    // onSubmit={handleSubmit}
                    className='bg-white rounded'>
                    <div className='flex justify-between w-full'>
                        <div className='flex flex-col w-[350px]'>
                            <div className='flex flex-col space-y-1'>
                                <div className='relative'>
                                    <input
                                        type='text'
                                        disabled
                                        placeholder='Baby'
                                        className='border bg-slate-300  text-black rounded w-full px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue'

                                    />

                                </div>

                                <div className='my-2 py-1'>
                                    <p>Scissors/Blade</p>
                                    <hr className='bg-indigo-500 h-[5]' />
                                </div>
                                <div className='my-2 py-1'>
                                    <p>Powder </p>
                                    <hr className='bg-indigo-500 h-[5]' />

                                </div>

                                <div className='my-2 py-1'>
                                    <p>Cotton Wool</p>
                                    <hr className='bg-indigo-500 h-[5]' />
                                </div>
                                <div className='my-2 py-1'>
                                    <p>Olive oil</p>
                                    <hr className='bg-indigo-500 h-[5]' />

                                </div>
                            </div>
                            <div className='my-2 py-1'>
                                <p>Methylated spirit</p>
                                <hr className='bg-indigo-500 h-[5]' />
                            </div>
                            <div className='my-2 py-1'>
                                <p>Baby Soap</p>
                                <hr className='bg-indigo-500 h-[5]' />

                            </div>

                            <div className='my-2 py-1'>
                                <p>Blanket  </p>
                                <hr className='bg-indigo-500 h-[5]' />
                            </div>
                            <div className='my-2 py-1'>
                                <p>Dresses/Inner vest</p>
                                <hr className='bg-indigo-500 h-[5]' />

                            </div>

                            <div className='my-2 py-1'>
                                <p>Antiseptic wash</p>
                                <hr className='bg-indigo-500 h-[5]' />
                            </div>
                            <div className='my-2 py-1'>
                                <p>Napkin/Pampers</p>
                                <hr className='bg-indigo-500' />

                            </div>
                        </div>

                        <div className='flex flex-col w-[350px]'>
                            <div className='flex flex-col space-y-1'>
                                <div className='relative'>
                                    <input
                                        type='text'
                                        disabled
                                        placeholder='Mother'
                                        className='border bg-orange-300 rounded w-full px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue'

                                    />

                                </div>

                                <div className='my-2 py-1'>
                                    <p>Towel</p>
                                    <hr className='bg-indigo-500 h-[5]' />
                                </div>
                                <div className='my-2 py-1'>
                                    <p>Wrapper (2 sets)</p>
                                    <hr className='bg-indigo-500 h-[5]' />

                                </div>

                                <div className='my-2 py-1'>
                                    <p>Sweater</p>
                                    <hr className='bg-indigo-500 h-[5]' />
                                </div>
                                <div className='my-2 py-1'>
                                    <p>Pants (2)</p>
                                    <hr className='bg-indigo-500 h-[5]' />

                                </div>
                            </div>
                            <div className='my-2 py-1'>
                                <p>Pads (3 kit)</p>
                                <hr className='bg-indigo-500 h-[5]' />
                            </div>
                            <div className='my-2 py-1'>
                                <p>Soap</p>
                                <hr className='bg-indigo-500 h-[5]' />

                            </div>

                            <div className='my-2 py-1'>
                                <p>Slippers </p>
                                <hr className='bg-indigo-500 h-[5]' />
                            </div>
                            <div className='my-2 py-1'>
                                <p>Brush and Paste</p>
                                <hr className='bg-indigo-500 h-[5]' />

                            </div>

                            <div className='my-2 py-1'>
                                <p>Antiseptic wash</p>
                                <hr className='bg-indigo-500 h-[5]' />
                            </div>
                            <div className='my-2 py-1'>
                                <p>Gluscose</p>
                                <hr className='bg-indigo-500' />

                            </div>
                        </div>
                    </div>


                </form>
            </div>
        </div>
    )
}

export default NurseGenerateAntenatalCardModal