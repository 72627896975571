/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { PageTitle } from '../../components'
import { TbReportAnalytics } from 'react-icons/tb'
import { useStateContext } from '../../state/context'
import { useDispatch, useSelector } from 'react-redux'
import { getAllInvoiceGeneratedByUser } from '../../state/actions/laboratorist.actions'
import { setLabInvoice } from '../../state/actions/actions'
import LaboratoryPagination from './LaboratoryPagination'
import Pagination from '../../components/utils/Pagination'

const LaboratoryInvoices = () => {
    const dispatch = useDispatch()
    const { modals, setModals } = useStateContext()

    const { all_lab_invoice_by_me } = useSelector((state) => state.laboratorist)

    const [currentData, setCurrentData] = useState([])

    useEffect(() => {
        dispatch(getAllInvoiceGeneratedByUser())
    }, [])


    return (
        <div>
            <PageTitle type={'laboratorist-invoices'} />

            <div className="space-y-5">
                <div className='flex justify-between items-center w-full px-1'>
                    <div
                        className="flex flex-col w-full cursor-pointer"
                    >
                        <div className="py-2">
                            <div className="flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1">
                                <h4 className='text-dark-blue text-[14px] font-bold'>Invoices</h4>
                                <TbReportAnalytics
                                    className='text-dark-blue'
                                    size={20}
                                />
                                {/* <input type="text" name="filter" placeholder='Search by patient number or date ' id="" className='ml-2 pl-4 text-sm rounded h-[30px] w-[400px]' onChange={(e) => handleChange(e, e.target.name)} /> */}
                            </div>
                            <table className="border rounded w-full cursor-default">
                                <thead className="">
                                    <tr>
                                        <th scope="col" className="text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left">
                                            S/N
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left">
                                            Patient ID
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left">
                                            Full name
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left">
                                            Date generated
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left">
                                            Invoice Number
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left">
                                            Status
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 py-4 text-left">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData?.map((invoice, index) => (
                                        <tr className="" key={index}>
                                            <td className="text-sm text-gray-900 font-light py-2 pl-8 whitespace-nowrap">
                                                {index + 1}
                                            </td>
                                            <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">{invoice.patient_number}</td>
                                            <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                {invoice.patient.first_name} {invoice.patient.last_name}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                {invoice.date_generated}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                {invoice.invoice_number}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                {invoice.is_paid ? "Paid" : "Pending"}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                <div className="relative">
                                                    <button
                                                        type="button"
                                                        className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                                        onClick={() => {
                                                            const data = {
                                                                amount: invoice.amount,
                                                                patient_no: invoice.patient_number,
                                                                invoice_number: invoice.invoice_number,
                                                                patient_name: { first_name: invoice.patient.first_name, last_name: invoice.patient.last_name },
                                                                generated_by: { first_name: invoice.generated_by.first_name, last_name: invoice.generated_by.last_name },
                                                                processed_by: { first_name: invoice.updated_by?.first_name, last_name: invoice.updated_by?.last_name },
                                                                date: invoice.date_generated,
                                                                purpose: invoice.billing_type,
                                                                status: invoice.is_paid
                                                            }

                                                            dispatch(setLabInvoice({ data }))
                                                            setModals({
                                                                ...modals,
                                                                showViewLaboratoryInvoiceModal:
                                                                    !modals.showViewLaboratoryInvoiceModal,
                                                            })
                                                        }}
                                                    >
                                                        View
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <Pagination
                                data={all_lab_invoice_by_me}
                                currentData={currentData}
                                setCurrentData={setCurrentData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LaboratoryInvoices