import { createAsyncThunk } from "@reduxjs/toolkit"

import {
    fetchLaboratoryDailyReports, fetchLaboratoryInvoices, fetchLaboratoryMonthlyReports, fetchLaboratoryReportsAuthor,
    fetchLaboratoryReportsRange, fetchLaboratoryYearlyReports, fetchPharmacyInvoices, fetchPharmacyMonthlyReports,
    fetchPharmacyReportsAuthor, fetchPharmacyReportsRange, fetchPharmacyYearlyReports, fetchRadiologyDailyReports,
    fetchRadiologyInvoices, fetchRadiologyMonthlyReports, fetchRadiologyReportsAuthor, fetchRadiologyReportsRange,
    fetchRadiologyYearlyReports, fetchRecordsDailyReports, fetchRecordsInvoices, fetchRecordsMonthlyReports, fetchRecordsReportsAuthor,
    fetchRecordsReportsRange, fetchRecordsYearlyReports, fetchRecordsUnPaidReports, fetchLabUnPaidReports, fetchPharmacyUnPaidReports,
    fetchRadiologyUnPaidReports, fetchRadiologyPaidReports, fetchLabPaidReports, fetchPharmacyPaidReports, fetchRecordsPaidReports,
    fetchLaboratoryNHISDiscounts, fetchPharmacyNHISDiscounts, fetchRadiologyNHISDiscounts, fetchRecordsNHISDiscounts, fetchPharmacyDailyReports, fetchAllInvoices, fetchAllDailyReports, fetchAllMonthlyReports, fetchAllYearlyReports, fetchAllReportsRange, fetchAllReportsAuthor, fetchAllPaidReports, fetchAllUnpaidReports, fetchNHISDiscounts, patchNHISDiscounts,
} from "../routes/accountant.routes"


export const getAllInvoices = createAsyncThunk(
    'accountant/getAllInvoices',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchAllInvoices(token)

            return data.invoices
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getLaboratoryInvoices = createAsyncThunk(
    'accountant/getLaboratoryInvoices',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchLaboratoryInvoices(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getAllDailyReports = createAsyncThunk(
    'accountant/getAllDailyReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchAllDailyReports(params, token)

            return data.invoices
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getAllMonthlyReports = createAsyncThunk(
    'accountant/getAllMonthlyReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchAllMonthlyReports(params, token)

            return data.invoices
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getLaboratoryDailyReports = createAsyncThunk(
    'accountant/getLaboratoryDailyReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchLaboratoryDailyReports(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getLaboratoryMonthlyReports = createAsyncThunk(
    'accountant/getLaboratoryMonthlyReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchLaboratoryMonthlyReports(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getAllYearlyReports = createAsyncThunk(
    'accountant/getAllYearlyReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchAllYearlyReports(params, token)

            return data.invoices
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getLaboratoryYearlyReports = createAsyncThunk(
    'accountant/getLaboratoryYearlyReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchLaboratoryYearlyReports(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getAllReportsRange = createAsyncThunk(
    'accountant/getAllReportsRange',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchAllReportsRange(params, token)

            return data.invoices
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getLaboratoryReportsRange = createAsyncThunk(
    'accountant/getLaboratoryReportsRange',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchLaboratoryReportsRange(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getAllReportsAuthor = createAsyncThunk(
    'accountant/getAllReportsAuthor',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchAllReportsAuthor(params, token)

            return data.invoices
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getLaboratoryReportsAuthor = createAsyncThunk(
    'accountant/getLaboratoryReportsAuthor',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchLaboratoryReportsAuthor(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getPharmacyInvoices = createAsyncThunk(
    'accountant/getPharmacyInvoices',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPharmacyInvoices(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getPharmacyDailyReports = createAsyncThunk(
    'accountant/getPharmacyDailyReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPharmacyDailyReports(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getPharmacyMonthlyReports = createAsyncThunk(
    'accountant/getPharmacyMonthlyReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPharmacyMonthlyReports(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getPharmacyYearlyReports = createAsyncThunk(
    'accountant/getPharmacyYearlyReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPharmacyYearlyReports(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getPharmacyReportsRange = createAsyncThunk(
    'accountant/getPharmacyReportsRange',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPharmacyReportsRange(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getPharmacyReportsAuthor = createAsyncThunk(
    'accountant/getPharmacyReportsAuthor',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPharmacyReportsAuthor(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getRadiologyInvoices = createAsyncThunk(
    'accountant/getRadiologyInvoices',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRadiologyInvoices(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getRadiologyDailyReports = createAsyncThunk(
    'accountant/getRadiologyDailyReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRadiologyDailyReports(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getRadiologyMonthlyReports = createAsyncThunk(
    'accountant/getRadiologyMonthlyReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRadiologyMonthlyReports(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getRadiologyYearlyReports = createAsyncThunk(
    'accountant/getRadiologyYearlyReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRadiologyYearlyReports(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getRadiologyReportsRange = createAsyncThunk(
    'accountant/getRadiologyReportsRange',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRadiologyReportsRange(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getRadiologyReportsAuthor = createAsyncThunk(
    'accountant/getRadiologyReportsAuthor',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRadiologyReportsAuthor(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getRecordsInvoices = createAsyncThunk(
    'accountant/getRecordsInvoices',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRecordsInvoices(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getRecordsDailyReports = createAsyncThunk(
    'accountant/getRecordsDailyReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRecordsDailyReports(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getRecordsMonthlyReports = createAsyncThunk(
    'accountant/getRecordsMonthlyReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRecordsMonthlyReports(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getRecordsYearlyReports = createAsyncThunk(
    'accountant/getRecordsYearlyReports',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRecordsYearlyReports(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getRecordsReportsRange = createAsyncThunk(
    'accountant/getRecordsReportsRange',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRecordsReportsRange(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getRecordsReportsAuthor = createAsyncThunk(
    'accountant/getRecordsReportsAuthor',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRecordsReportsAuthor(params, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getNHISDiscounts = createAsyncThunk(
    'accountant/getNHISDiscounts',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchNHISDiscounts(token)

            return data.discounts
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getPharmacyNHISDiscounts = createAsyncThunk(
    'accountant/getPharmacyNHISDiscounts',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPharmacyNHISDiscounts(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getRecordsNHISDiscounts = createAsyncThunk(
    'accountant/getRecordsNHISDiscounts',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRecordsNHISDiscounts(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getLaboratoryNHISDiscounts = createAsyncThunk(
    'accountant/getLaboratoryNHISDiscounts',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchLaboratoryNHISDiscounts(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getRadiologyNHISDiscounts = createAsyncThunk(
    'accountant/getRadiologyNHISDiscounts',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRadiologyNHISDiscounts(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const setCurrentInvoice = createAsyncThunk(
    'accountant/setCurrentInvoice',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const setCurrentDiscounts = createAsyncThunk(
    'accountant/setCurrentDiscounts',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const getRecordsUnPaidReports = createAsyncThunk(
    'accountant/fetchRecordsUnPaidReports',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRecordsUnPaidReports(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)


export const getLabUnPaidReports = createAsyncThunk(
    'accountant/getLabUnPaidReports',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchLabUnPaidReports(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)

export const getPharmacyUnPaidReports = createAsyncThunk(
    'accountant/getPharmacyUnPaidReports',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPharmacyUnPaidReports(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)

export const getRadiologyUnPaidReports = createAsyncThunk(
    'accountant/getRadiologyUnPaidReports',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRadiologyUnPaidReports(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)


export const getRadiologyPaidReports = createAsyncThunk(
    'accountant/getRadiologyPaidReports',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRadiologyPaidReports(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllPaidReports = createAsyncThunk(
    'accountant/getAllPaidReports',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchAllPaidReports(token)

            return data.invoices
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllUnpaidReports = createAsyncThunk(
    'accountant/getAllUnpaidReports',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchAllUnpaidReports(token)

            return data.invoices
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)

export const getLabPaidReports = createAsyncThunk(
    'accountant/getLabPaidReports',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchLabPaidReports(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)

export const getPharmacyPaidReports = createAsyncThunk(
    'accountant/getPharmacyPaidReports',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPharmacyPaidReports(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)

export const getRecordsPaidReports = createAsyncThunk(
    'accountant/getRecordsPaidReports',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRecordsPaidReports(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)

export const setCurrentReportsType = createAsyncThunk(
    'accountant/setCurrentReportsType',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const remitNHISDiscounts = createAsyncThunk(
    'accountant/remitNHISDiscounts',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchNHISDiscounts(formData, token)

            toast.success('Discounts remitted sucessfully')
            setModals({ ...modals, showAccountsRemitDiscountsModal: !modals.showAccountsRemitDiscountsModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating discounts')
            return rejectWithValue(null)
        }
    }
)
