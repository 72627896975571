import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'


import Pagination from '../utils/Pagination'
import PaymentStatus from '../utils/PaymentStatus'
import { useStateContext } from '../../state/context'
import { setCurrentInvoice } from '../../state/actions/admin.actions'


const MonthlyRemittanceTable = ({ data }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    // const { sms_balance } = useSelector(state => state.admin)
    const [currentData, setCurrentData] = useState([])

    return (
        <div className="flex justify-between items-center w-full">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="">
                    <Fragment>
                        <table className="border-b border-l border-r rounded w-full cursor-default">
                            <thead className="">
                                <tr className='bg-gray-100'>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        Amount
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        Reference
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        Author
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >

                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentData?.map((receipt, index) => (
                                    <tr >
                                        <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                            {index + 1}
                                            {/* 1 */}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {receipt?.amount}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {receipt?.payment_reference}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {receipt?.payment_author_details?.fullname}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            <PaymentStatus status={receipt?.is_paid} />

                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {!receipt?.is_paid && index === 0 && (
                                                <button
                                                    type="button"
                                                    className="w-[80px] inline-block px-6 py-2 bg-purple-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                    onClick={() => {
                                                        dispatch(setCurrentInvoice({ data: receipt }))
                                                        setModals({ ...modals, showPayMonthlyRemittanceModal: !modals.showPayMonthlyRemittanceModal })
                                                    }}
                                                >
                                                    Pay
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <Pagination
                            data={data}
                            currentData={currentData}
                            setCurrentData={setCurrentData}
                        />
                    </Fragment>
                </div>
            </div>
        </div >
    )
}

export default MonthlyRemittanceTable
