import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Antigens } from '../../../data'
import { getAPatientVaccinationRecords } from '../../../state/actions/nurse.actions'
import { useStateContext } from '../../../state/context'


const VaccinationRecordsTable = () => {
    const { set_immunization_patient } = useSelector((state) => state.root)
    const { vaccination_record_for_a_patient } = useSelector((state) => state.nurse)
    const { modals, setModals } = useStateContext()
    const dispatch = useDispatch()
    useEffect(() => {

        dispatch(getAPatientVaccinationRecords({ id: set_immunization_patient?.patient_id }))

    }, [set_immunization_patient, modals.showAddVaccinationRecordModal])

    return (
        <>
            <div className='flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1'>
                <h4 className='text-dark-blue text-[16px] font-semibold'>
                    Vaccination records history - {set_immunization_patient?.first_name} {set_immunization_patient?.last_name} [{set_immunization_patient?.patient_number}]
                </h4>
                {/* <BsPersonPlus
                    className='text-dark-blue'
                    size={20}
                /> */}
                {/* <input type="text" name="filter" placeholder='Search' id="" className='ml-2 pl-4 text-sm rounded h-[30px] w-[400px]' onChange={(e) => handleChange(e, e.target.name)} /> */}
            </div>
            <table className='border rounded w-full cursor-default'>
                <thead className=''>
                    <tr>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-1 py-4 text-left'
                        >
                            S/N
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-1 py-4 text-left'
                        >
                            Antigen
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-1 py-4 text-left'
                        >
                            Batch number
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-1 py-4 text-left'
                        >
                            Date
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-1 py-4 text-left'
                        >
                            Signature
                        </th>



                    </tr>
                </thead>


                {vaccination_record_for_a_patient?.map((item, index) => (
                    <tr
                        className='relative'
                        key={index}

                    >
                        <td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
                            {index + 1}
                        </td>
                        <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                            {item.antigen}      </td>
                        <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                            {item.batch_number}
                        </td>
                        <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                            {item.date.slice(0, 10)}
                        </td>
                        <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                            {item?.signature?.first_name} {item?.signature?.last_name}
                        </td>



                    </tr>
                ))}

            </table>
        </>
    )
}

export default VaccinationRecordsTable