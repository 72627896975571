/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { GoMail } from 'react-icons/go'
import { AiOutlineLock } from 'react-icons/ai'

import { ButtonLoader } from '../../components'
import { LoginLeftImg, Logo } from '../../assets'
import { authLogin } from '../../state/actions/auth.actions'
import { getHospitalConfig } from '../../state/actions/admin.actions'


const Login = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user, authLoading } = useSelector((state) => state.auth)
    const { hospital_config } = useSelector((state) => state.admin)

    const [formData, setFormData] = useState({ email: '', password: '' })

    useEffect(() => {
        dispatch(getHospitalConfig())
    }, [])

    useEffect(() => {
        document.title = hospital_config?.name
        let link = document.querySelector("link[rel~='icon']");

        link.href = `${process.env.REACT_APP_API_ROOT}${hospital_config?.logo}`;
    }, [hospital_config])


    useEffect(() => {
        if (!user) return navigate('/')

        if (user && !user?.data?.is_password_reset) {
            toast.info('First login, please update your password.')
            navigate('/updatepassword')
        }

        if (user && user?.data?.is_password_reset) return navigate('/updateprofile')
    }, [user])

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }

    const handleLogin = (e) => {
        e.preventDefault()

        if (!formData.email && !formData.password) return toast.warning('All fields are required!')
        if (!formData.email) return toast.warning('Email is required!')
        if (!formData.password) return toast.warning('Password is required!')

        dispatch(authLogin({ formData, navigate, toast }))
    }


    return (
        <div className="main__login relative min-h-screen text-gray-800 antialiased px-4 py-6 flex flex-col justify-center sm:py-12 font-poppins">
            <div className="absolute right-[220px] top-[40px]">
                <div className="w-[100px] h-[100px] bg-white  bg-opacity-50 backdrop-blur-sm rounded-full"></div>
            </div>
            <div className="absolute right-[250px] top-[60px]">
                <div className="w-[100px] h-[100px] bg-white bg-opacity-50 backdrop-blur-sm rounded-full"></div>
            </div>
            <div className="relative py-3 sm:w-[800px] mx-auto text-center sm:auto">

                <div className="mt-4 bg-white shadow-md">
                    <div className="flex justify-between items-center">
                        <div className="px-8 py-[4em] w-[400px] border-r-2 border-light-gray">
                            <img src={LoginLeftImg} alt="art__login" />
                        </div>

                        <div className="px-8 w-[400px]">
                            <div className="space-y-10 mb-4">
                                <div className="">
                                    <img
                                        src={hospital_config ? `${process.env.REACT_APP_API_ROOT}${hospital_config?.logo}` : Logo}
                                        alt="logo"
                                        className='w-[40px] h-[40px] '
                                    />
                                </div>

                                <h4 className="text-center text-[30px] font-bold">
                                    Welcome Back
                                </h4>
                            </div>

                            <div className="space-y-3">
                                <div className="relative">
                                    <GoMail
                                        size={20}
                                        color={'rgba(40, 51, 66, 0.8)'}
                                        className='absolute top-[16px] left-3'
                                    />
                                    <input
                                        type="text"
                                        name="email"
                                        placeholder="Email"
                                        className="border rounded w-full h-5 px-5 pl-12 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div className="relative">
                                    <AiOutlineLock
                                        size={20}
                                        color={'rgba(40, 51, 66, 0.8)'}
                                        className='absolute top-[16px] left-3'
                                    />
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        className="border w-full rounded h-5 px-5 pl-12 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col justify-between items-center space-y-5">
                                <button
                                    type="submit"
                                    className="mt-4 w-[175px] bg-light-blue text-white py-2 px-6 rounded-md hover:bg-light-blue"
                                    onClick={handleLogin}
                                >
                                    {!authLoading ? (
                                        "Login"
                                    ) : (
                                        <ButtonLoader />
                                    )}
                                </button>

                                <Link to="/resetpassword" className="text-sm hover:underline">Forgot password?</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
