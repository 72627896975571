/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { setDepartment } from '../../../state/actions/actions'
import { createDepartment, updateDepartment } from '../../../state/actions/admin.actions'


const CreateDepartmentModal = (props) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [checked, setChecked] = useState(false)
    const [formData, setFormData] = useState({ directorate_name: '', department_name: '', description: '', status: false })
    const { directorates } = useSelector((state) => state.admin);
    const { department_update } = useSelector((state) => state.root);


    useEffect(() => {
        setChecked(department_update?.status ? department_update?.status : checked)
        setFormData({ ...formData, directorate_name: department_update?.directorate_name, department_name: department_update?.department_name, description: department_update?.description, status: department_update?.status })
    }, [department_update])

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }
    const handleCheckboxChange = () => {
        setChecked(!checked)
        setFormData({ ...formData, status: !checked })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!formData.department_name || !formData.description) return toast.warning('Please fill all fields')
        dispatch(createDepartment({ formData, toast, setModals: setModals, modals: modals }));
    }

    const handleUpdate = (e) => {
        e.preventDefault()

        if (!formData.department_name || !formData.description) return toast.warning('Please fill all fields')
        dispatch(updateDepartment({ formData: { ...formData, id: department_update.id }, toast, setModals: setModals, modals: modals }));
    }
    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className='text-dark-green text-[16px] font-medium'>
                            {modals.showUpdateDepartmentModal ? 'Update Department' : 'Create Department'}
                        </h4>
                        <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                modals.showAddDepartmentModal && setModals({ ...modals, showAddDepartmentModal: !modals.showAddDepartmentModal })
                                modals.showUpdateDepartmentModal && setModals({ ...modals, showUpdateDepartmentModal: !modals.showUpdateDepartmentModal })
                                // props.showUpdateDepartmentModal && props.setShowUpdateDepartmentModal(!props.showUpdateDepartmentModal)
                                // !props.showUpdateDepartmentModal && props.setShowAddDepartmentModal(!props.showAddDepartmentModal)
                            }}
                            size={20}
                        />
                    </div>

                    {modals.showAddDepartmentModal && (
                        <form onSubmit={handleSubmit}>
                            <div className="relative">
                                <select
                                    className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none " id="grid-state"
                                    name='directorate_name'
                                    onChange={(e) => handleChange(e, e.target.name)}
                                >
                                    <option >Directorate</option>
                                    {directorates?.map((item, index) => (
                                        <option value={item.directorate_name} key={index}>{item.directorate_name}</option>
                                    ))}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                </div>
                            </div>

                            <div>
                                <input
                                    type="text"
                                    name="department_name"
                                    placeholder="Department name"
                                    className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                />
                            </div>
                            <div>
                                <textarea
                                    name="description"
                                    cols="30"
                                    rows="7"
                                    placeholder='Description'
                                    className="border rounded w-full px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue resize-none"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                >
                                </textarea>
                            </div>
                            <div className='flex items-center space-x-3'>
                                <input
                                    className='rounded border-4 w-5 h-5 border-red-400'
                                    type="checkbox"
                                    name='status'
                                    checked={checked}
                                    onChange={handleCheckboxChange}
                                />
                                <p className='text-[16px] text-gray-700 font-bold'>Status</p>
                            </div>

                            <div className='mt-4'>
                                <button
                                    type='submit'
                                    className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                >Create Department</button>
                            </div>
                        </form>
                    )}

                    {modals.showUpdateDepartmentModal && (
                        <form onSubmit={handleUpdate}>
                            <div className="relative">
                                <select
                                    className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none " id="grid-state"
                                    name='directorate_name'
                                    onChange={(e) => handleChange(e, e.target.name)}
                                >
                                    <option value={formData.directorate_name}>{formData.directorate_name}</option>
                                    {directorates?.map((item, index) => (
                                        <option value={item.directorate_name} key={index}>{item.directorate_name}</option>
                                    ))}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                </div>
                            </div>

                            <div>
                                <input
                                    type="text"
                                    name="department_name"
                                    placeholder="Department name"
                                    className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    value={formData.department_name}
                                    onChange={(e) => handleChange(e, e.target.name)}
                                />
                            </div>
                            <div>
                                <textarea
                                    name="description"
                                    cols="30"
                                    rows="7"
                                    placeholder='Description'
                                    className="border rounded w-full px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue resize-none"
                                    value={formData.description}
                                    onChange={(e) => handleChange(e, e.target.name)}
                                >
                                </textarea>
                            </div>
                            <div className='flex items-center space-x-3'>
                                <input
                                    className='rounded border-4 w-5 h-5 border-red-400'
                                    type="checkbox"
                                    name='status'
                                    checked={checked}
                                    onChange={handleCheckboxChange}
                                />
                                <p className='text-[16px] text-gray-700 font-bold'>Status</p>
                            </div>

                            <div className='mt-4'>
                                <button
                                    type='submit'
                                    className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                >Update</button>
                            </div>
                        </form>
                    )}
                </div>


            </div>
        </div>
    )
}

export default CreateDepartmentModal