import _ from "lodash"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

import { PageTitle, SmsTable, SmsUsersTable, UserPosition } from "../../components"
import { setSmsReceiver } from "../../state/actions/actions"
import { useStateContext } from "../../state/context"
import { getCurrentPatientProfile } from "../../state/actions/patient.actions"
import PatientSmsUsersTable from "../../components/tables/PatientSMSUserTable"


const PatientSMS = (props) => {
  const dispatch = useDispatch()

  const { modals, setModals } = useStateContext()
  const { getSentMessages, getReceivedMessages, employees } = useSelector((state) => state.admin)

  const [showUsers, setShowUsers] = useState(true)
  const [showSent, setShowSent] = useState(false)
  const [showReceived, setShowReceived] = useState(false)
  const [paginatedData, setPaginatedData] = useState({ users: [], sent_sms: [], received_sms: [] })
  const [currentPage, setCurrentPage] = useState({ users: 1, sent_sms: 1, received_sms: 1 })

  const pageSize = 5
  const users_page_count = employees ? Math.ceil(employees.length / pageSize) : 0
  const sentsms_page_count = getSentMessages ? Math.ceil(getSentMessages.length / pageSize) : 0
  const receivedsms_page_count = getReceivedMessages ? Math.ceil(getReceivedMessages.length / pageSize) : 0
  const users_pages = _.range(1, users_page_count + 1)
  const sentsms_pages = _.range(1, sentsms_page_count + 1)
  const receivedsms_pages = _.range(1, receivedsms_page_count + 1)
  const { current_patient_profile } = useSelector((state) => state.patient);
  const filtered_doctor = employees?.filter(item => {
    return item.user_id === current_patient_profile?.patient_doctor?.id
  })

  useEffect(() => {
    setPaginatedData({
      ...paginatedData,
      users: _(filtered_doctor).slice(0).take(pageSize).value(),
      sent_sms: _(getSentMessages).slice(0).take(pageSize).value(),
      received_sms: _(getReceivedMessages).slice(0).take(pageSize).value(),
    })
  }, [employees, getReceivedMessages, getSentMessages])

  const handleUsersPagination = (page) => {
    setCurrentPage({ ...currentPage, users: page })

    const startIndex = (page - 1) * pageSize
    const _data = _(employees).slice(startIndex).take(pageSize).value()
    setPaginatedData({ ...paginatedData, users: _data })
  }

  const handleSentSmsPagination = (page) => {
    setCurrentPage({ ...currentPage, sent_sms: page })

    const startIndex = (page - 1) * pageSize
    const _data = _(getSentMessages).slice(startIndex).take(pageSize).value()
    setPaginatedData({ ...paginatedData, sent_sms: _data })
  }

  const handleReceivedSmsPagination = (page) => {
    setCurrentPage({ ...currentPage, received_sms: page })

    const startIndex = (page - 1) * pageSize
    const _data = _(getReceivedMessages).slice(startIndex).take(pageSize).value()
    setPaginatedData({ ...paginatedData, received_sms: _data })
  }


  useEffect(() => {
    dispatch(getCurrentPatientProfile())
  }, [])

  return (
    <div>
      <PageTitle type={"messages"} />

      <div className="space-y-5">
        <PatientSmsUsersTable
          data={paginatedData.users}
          showList={showUsers}
          setShowList={setShowUsers}
        // pages={users_pages}
        // currentPage={currentPage.users}
        // handlePagination={handleUsersPagination}
        />

        <SmsTable
          title={'Sent'}
          data={paginatedData.sent_sms}
          showList={showSent}
          setShowList={setShowSent}
          pages={sentsms_pages}
          currentPage={currentPage.sent_sms}
          handlePagination={handleSentSmsPagination}
        />
        <SmsTable
          title={'Received'}
          data={paginatedData.received_sms}
          showList={showReceived}
          setShowList={setShowReceived}
          pages={sentsms_pages}
          currentPage={currentPage.received_sms}
          handlePagination={handleReceivedSmsPagination}
        />
      </div>
    </div>
  )
}

export default PatientSMS
