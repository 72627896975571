import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';

import { useStateContext } from '../../../state/context'
import { assignDoctor, bookAppointment } from '../../../state/actions/actions'
import { getDoctors, bookPatientForAppointment, assignAPatientToDoctor, getAllDoctorsAndOtherFunctionaries } from '../../../state/actions/records.actions'


const RecordsBookAppointmentModal = () => {
	const dispatch = useDispatch()

	const { modals, setModals, socket } = useStateContext()
	const { assign_doctor, book_appointment } = useSelector((state) => state.root)
	const [patientPKID, setPatientPKID] = useState('')

	const { doctors } = useSelector((state) => state.records)

	const [formData, setFormData] = useState({
		pat_id: '',
		doc_id: '',
		date: '',
		time: '',
		patient_pkid: '',
		doctors_name: '',
		notification_recipient: ''

	})


	useEffect(() => {
		dispatch(getAllDoctorsAndOtherFunctionaries())
	}, [])

	useEffect(() => {
		setFormData({
			...formData,
			pat_id: assign_doctor?.patient_id,
			doc_id: book_appointment?.doc_id,
			patient_pkid: assign_doctor?.patient_pkid
		})



	}, [assign_doctor, book_appointment])


	useEffect(() => {
		const filtered_doctor = doctors?.filter(item => {
			return item.id === book_appointment?.doc_id
		})

		const name = {
			first_name: filtered_doctor[0]?.first_name,
			last_name: filtered_doctor[0]?.last_name,
			recipient: filtered_doctor[0]?.pkid
		}
		setFormData({
			...formData,
			doctors_name: `${name.first_name} ${name.last_name}`,
			notification_recipient: name.recipient
		})

	}, [book_appointment])




	const handleChange = (e, name) => {
		setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		console.log(formData)
		if (modals.showBookAppointmentModal) {
			dispatch(bookPatientForAppointment({ formData, toast, setModals, modals, dispatch, assignDoctor, bookAppointment, socket }))
		} else {
			setFormData({ ...formData, pat_id: assign_doctor?.patient_id, doc_id: book_appointment?.doc_id })

			dispatch(assignAPatientToDoctor({ formData, toast, setModals, modals, dispatch, assignDoctor, socket }))
		}

	}

	return (
		<div className='fixed grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
			<div className='bg-white rounded px-[30px] py-[20px] w-[500px]'>
				<div className='flex flex-col space-y-5'>
					{modals.showBookAppointmentModal && (
						<div className='flex justify-between items-center'>
							<h4 className='text-dark-green text-[20px] font-bold'>Book Appointment</h4>

							<FaTimesCircle
								className='text-dark-yellow cursor-pointer'
								onClick={() => {
									setModals({ ...modals, showBookAppointmentModal: !modals.showBookAppointmentModal })
									dispatch(assignDoctor({ data: null }))
								}}
								size={20}
							/>
						</div>
					)}
					{modals.showChangeDoctorModal && (
						<div className='flex justify-between items-center'>
							<h4 className='text-dark-green text-[20px] font-bold'>Assign Doctor</h4>
							<FaTimesCircle
								className='text-dark-yellow cursor-pointer'
								onClick={() => {
									setModals({ ...modals, showChangeDoctorModal: !modals.showChangeDoctorModal, })
									dispatch(assignDoctor({ data: null }))
								}}
								size={20}
							/>
						</div>
					)}

					<div className='flex justify-between items-start'>
						<img src={`${process.env.REACT_APP_API_ROOT}${assign_doctor?.photo}`} alt="" className='h-[80px] w-[100px] mx-auto rounded-[50%] mr-2' />
						<div className='flex flex-col w-[400px] mt-2'>
							<div className='pl-2 py-2 mr-2 font-medium'><h3> Name: {`${assign_doctor?.first_name}
                                    ${assign_doctor?.last_name}`}</h3>
								<h3 className='font-medium py-2'>Patient Number: {assign_doctor?.patient_no}</h3>
							</div>
						</div>
					</div>

					<form onSubmit={handleSubmit}>
						<div className='flex justify-between-items-center space-x-10'>
							<div className='space-y-5 w-full'>
								<div className='relative'>

									<select
										className='appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none '
										id='grid-state'
										name='doc_id'
										onChange={(e) => {
											handleChange(e, e.target.name)
										}}
										onClick={() => {
											const data = {
												doc_id: formData.doc_id,

											}
											dispatch(bookAppointment({ data }))


										}}
									>
										<option>Select A Doctor</option>
										{doctors?.map((doctor, index) => (
											<option
												value={doctor.id}
												key={index}
											>
												{doctor.first_name}{' '}
												{doctor.last_name}  {
													doctor?.is_doctor ? "(Doctor)" :
														doctor?.is_ophthalmologists ? '(Ophthalmologists)' :
															doctor?.is_otolaryngologists ? '(Otolaryngologists)' : '(Physiotherapist)'}
											</option>
										))}
									</select>
									<div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
										<svg
											className='fill-current h-4 w-4'
											xmlns='http://www.w3.org/2000/svg'
											viewBox='0 0 20 20'
										>
											<path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
										</svg>
									</div>
								</div>

								{modals.showBookAppointmentModal && <div>
									<input
										type='date'
										name='date'
										placeholder='Last name'
										min={new Date().toISOString().split('T')[0]}
										className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
										onChange={(e) => handleChange(e, e.target.name)}
									/>
								</div>}

								{modals.showChangeDoctorModal && <div>

									<input
										type='date'
										name='date'
										id="date"

										placeholder='Last name'
										min={new Date().toISOString().split('T')[0]}
										max={moment().format("YYYY-MM-DD")}
										className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
										onChange={(e) => handleChange(e, e.target.name)}
									/>
								</div>}
								<div>
									<input
										type='time'
										name='time'
										placeholder='Last name'
										className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
										onChange={(e) => handleChange(e, e.target.name)}
									/>
								</div>
							</div>
						</div>

						<div className='mt-4 flex justify-center items-center'>
							<button
								type='submit'
								className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
							>
								Submit
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}

export default RecordsBookAppointmentModal
