import React, { useEffect } from 'react'
import { setPatientID } from '../../../state/actions/actions'
import { useStateContext } from '../../../state/context'
import { useDispatch, useSelector } from 'react-redux'
import { getAPatientMedicalRecord } from '../../../state/actions/nurse.actions'

const AntenatalTable = (props) => {
    const dispatch = useDispatch()
    const { opdState, setOpdState } = useStateContext()
    const { modals, setModals } = useStateContext()
    const { set_patient_id } = useSelector((state) => state.root)

  
    return (
        <tbody>
            {props.data?.map((item, index) => (
                <tr
                    className='relative'
                    key={index}

                >
                    <td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
                        {index + 1}
                    </td>
                    <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                        {item.patient_number}         </td>
                    <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                        {item.booked_patient.first_name} {item.booked_patient.last_name}
                    </td>
                    <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                        {item.patient_profile.gender}
                    </td>
                    <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                        {item.date}
                    </td>


                    <td className=' text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                        <div className='flex space-x-4 relative '>
                            <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => {
                                    const data = {
                                        id: item.booked_patient.id,
                                        first_name: item.booked_patient.first_name,
                                        last_name: item.booked_patient.last_name,
                                        patient_number: item.patient_profile.patient_number
                                    }
                                    dispatch(getAPatientMedicalRecord({ id: data.id }))
                                    dispatch(setPatientID({ data }))

                                    setOpdState({
                                        ...opdState,
                                        showPatientAntenatalRecords: true

                                    })
                                }}
                            >
                                Manage
                            </button>


                        </div>
                    </td>
                </tr>

            ))}
        </tbody>
    )
}

export default AntenatalTable