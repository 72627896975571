import React, { useState, useEffect } from 'react'
import { PageTitle } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import RadiologyEmployeesTable from './employeesTable/RadiologyEmployeesTable'
import { getAllEmployeesForRadiologist } from '../../state/actions/radiologist.actions'


const RadiologistDashboard = () => {
	const dispatch = useDispatch()


	const { all_employees } = useSelector(state => state.radiologist)
	const radiologists = all_employees?.filter(employee => employee.user_details.is_radiologist === true)
	const laboratorists = all_employees?.filter(employee => employee.user_details.is_laboratorist === true)
	const doctors = all_employees?.filter(employee => employee.user_details.is_doctor === true)
	const nurses = all_employees?.filter(employee => employee.user_details.is_nurse === true)
	const records = all_employees?.filter(employee => employee.user_details.is_records === true)
	const pharmacists = all_employees?.filter(employee => employee.user_details.is_pharmacist === true)
	

	useEffect(() => {
		dispatch(getAllEmployeesForRadiologist({}))
	}, [dispatch])


	return (
		<div>
			<PageTitle type={'radiologist-dashboard'} />

			<div className='flex flex-wrap justify-between'>
				<RadiologyEmployeesTable table_title={"Radiology List"} data={radiologists} />
				<RadiologyEmployeesTable table_title={"Laboratorist List"} data={laboratorists} />
				<RadiologyEmployeesTable table_title={"Pharmacist List"} data={pharmacists} />
				<RadiologyEmployeesTable table_title={"Doctors List"} data={doctors} />
				<RadiologyEmployeesTable table_title={"Nurses List"} data={nurses} />
				<RadiologyEmployeesTable table_title={"Records List"} data={records} />
			</div>
		</div>
	)

}

export default RadiologistDashboard