import { createSlice } from '@reduxjs/toolkit'
import {
    createPatient, getPatients, getDoctors, bookPatientForAppointment, getAllBirthRecords,
    getAllDeathRecords, AddBirthRecords, AddDeathRecords, getAllWards, getABirthRecord,
    removeSingleBirthRecord, getADeathRecordData, removeSingleDeathRecord, generatePatientCard,
    getAllBillings, getABillingDetails, removeSingleBillingDetails, getAllInvoice,
    getAllAppointments, getAllAssignedDoctors, assignDoctorToPatient, getAllWardReports, assignAPatientToDoctor, setBookAppointmentToNUll, getAllDoctorsAndOtherFunctionaries
} from '../actions/records.actions'





const recordsSlice = createSlice({
    name: 'records',
    initialState: {
        create_patient: null,
        patients: null,
        doctors: null,
        patientErrors: null,
        patientLoading: false,
        birth_records: null,
        death_records: null,
        create_birth_records: null,
        create_death_records: null,
        all_wards: null,
        single_birth_record: null,
        single_death_record: null,
        appointment: null,
        all_billings: null,
        single_billing_details: null,
        generate_card: null,
        assign_a_patient_to_dctor: null,
        all_invoices: null,
        all_appointment: null,
        all_assigned_doctors: null,
        all_ward_reports: null

    },
    extraReducers: {
        [createPatient.pending]: (state, action) => {
            state.patientLoading = true
        },
        [createPatient.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.create_patient = action.payload
            // state.create_patient= [...state.create_patient, action.payload]
        },

        [createPatient.rejected]: (state, action) => {
            state.patientLoading = false
        },
        [getPatients.pending]: (state, action) => {
            state.patientLoading = true
        },
        [getPatients.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.patients = action.payload
            // state.patients= [...state.patients, action.payload]
        },

        [getPatients.rejected]: (state, action) => {
            state.patientLoading = false
        },
        [getDoctors.pending]: (state, action) => {
            state.patientLoading = true
        },
        [getDoctors.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.doctors = action.payload
            state.doctors = [...state.doctors, action.payload]
        },

        [getDoctors.rejected]: (state, action) => {
            state.patientLoading = false
        },
        [bookPatientForAppointment.pending]: (state, action) => {
            state.patientLoading = true
        },
        [bookPatientForAppointment.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.appointment = action.payload
            // state.appointment= [...state.appointment, action.payload]
        },

        [bookPatientForAppointment.rejected]: (state, action) => {
            state.patientLoading = false
        },
        [getAllBirthRecords.pending]: (state, action) => {
            state.patientLoading = true
        },
        [getAllBirthRecords.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.birth_records = action.payload
            // state.birth_records= [...state.birth_records, action.payload]
        },

        [getAllBirthRecords.rejected]: (state, action) => {
            state.patientLoading = false
        },
        [getAllDeathRecords.pending]: (state, action) => {
            state.patientLoading = true
        },
        [getAllDeathRecords.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.death_records = action.payload
            // state.birth_records= [...state.birth_records, action.payload]
        },

        [getAllDeathRecords.rejected]: (state, action) => {
            state.patientLoading = false
        },
        [AddBirthRecords.pending]: (state, action) => {
            state.patientLoading = true
        },
        [AddBirthRecords.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.create_birth_records = action.payload
            state.birth_records = [...state.birth_records, action.payload]
        },

        [AddBirthRecords.rejected]: (state, action) => {
            state.patientLoading = false
        },

        [AddDeathRecords.pending]: (state, action) => {
            state.patientLoading = true
        },
        [AddDeathRecords.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.create_death_records = action.payload
            state.death_records = [...state.birth_records, action.payload]
        },

        [AddDeathRecords.rejected]: (state, action) => {
            state.patientLoading = false
        },
        [getAllWards.pending]: (state, action) => {
            state.patientLoading = true
        },
        [getAllWards.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.all_wards = action.payload
            // state.death_records= [...state.birth_records, action.payload]
        },

        [getAllWards.rejected]: (state, action) => {
            state.patientLoading = false
        },

        [getABirthRecord.pending]: (state, action) => {
            state.patientLoading = true
        },
        [getABirthRecord.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.single_birth_record = action.payload
            // state.death_records= [...state.birth_records, action.payload]
            state.patientLoading = false
        },

        [getABirthRecord.rejected]: (state, action) => {
            state.patientLoading = false
        },
        [removeSingleBirthRecord.pending]: (state, action) => {
            state.patientLoading = true
        },
        [removeSingleBirthRecord.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.single_birth_record = null
            // state.death_records= [...state.birth_records, action.payload]
            state.patientLoading = false
        },

        [removeSingleBirthRecord.rejected]: (state, action) => {
            state.patientLoading = false
        },
        [getADeathRecordData.pending]: (state, action) => {
            state.patientLoading = true
        },
        [getADeathRecordData.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.single_death_record = action.payload
            // state.death_records= [...state.birth_records, action.payload]
            state.patientLoading = false
        },

        [getADeathRecordData.rejected]: (state, action) => {
            state.patientLoading = false
        },
        [removeSingleDeathRecord.pending]: (state, action) => {
            state.patientLoading = true
        },
        [removeSingleDeathRecord.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.single_death_record = null
            // state.death_records= [...state.birth_records, action.payload]
            state.patientLoading = false
        },

        [removeSingleDeathRecord.rejected]: (state, action) => {
            state.patientLoading = false
        },
        [generatePatientCard.pending]: (state, action) => {
            state.patientLoading = true
        },
        [generatePatientCard.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.generate_card = null
            // state.death_records= [...state.birth_records, action.payload]
            state.patientLoading = false
        },

        [generatePatientCard.rejected]: (state, action) => {
            state.patientLoading = false
        },
        [getAllBillings.pending]: (state, action) => {
            state.patientLoading = true
        },
        [getAllBillings.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.all_billings = action.payload

        },

        [getAllBillings.rejected]: (state, action) => {
            state.patientLoading = false
        },
        [getABillingDetails.pending]: (state, action) => {
            state.patientLoading = true
        },
        [getABillingDetails.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.single_billing_details = action.payload

        },

        [getABillingDetails.rejected]: (state, action) => {
            state.patientLoading = false
        },
        [removeSingleBillingDetails.pending]: (state, action) => {
            state.patientLoading = true
        },
        [removeSingleBillingDetails.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.single_billing_details = null

        },

        [removeSingleBillingDetails.rejected]: (state, action) => {
            state.patientLoading = false
        },

        [getAllInvoice.pending]: (state, action) => {
            state.patientLoading = true
        },
        [getAllInvoice.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.all_invoices = action.payload

        },

        [getAllInvoice.rejected]: (state, action) => {
            state.patientLoading = false
        },
        [getAllAppointments.pending]: (state, action) => {
            state.patientLoading = true
        },
        [getAllAppointments.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.all_appointment = action.payload

        },
        [getAllAppointments.rejected]: (state, action) => {
            state.patientLoading = false
        },

        [getAllAssignedDoctors.pending]: (state, action) => {
            state.patientLoading = true
        },
        [getAllAssignedDoctors.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.all_assigned_doctors = action.payload

        },
        [getAllAssignedDoctors.rejected]: (state, action) => {
            state.patientLoading = false
        },

        [assignDoctorToPatient.pending]: (state, action) => {
            state.patientLoading = true
        },
        [assignDoctorToPatient.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.all_assigned_doctors = [action.payload, ...state.all_assigned_doctors]

        },
        [assignDoctorToPatient.rejected]: (state, action) => {
            state.patientLoading = false
        },

        [getAllWardReports.pending]: (state, action) => {
            state.patientLoading = true
        },
        [getAllWardReports.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.all_ward_reports = action.payload

        },
        [getAllWardReports.rejected]: (state, action) => {
            state.patientLoading = false
        },
        [assignAPatientToDoctor.pending]: (state, action) => {
            state.patientLoading = true
        },
        [assignAPatientToDoctor.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.assign_a_patient_to_dctor = action.payload

        },
        [assignAPatientToDoctor.rejected]: (state, action) => {
            state.patientLoading = false
        },
        [setBookAppointmentToNUll.pending]: (state, action) => {
            state.patientLoading = true
        },
        [setBookAppointmentToNUll.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.appointment = null

        },
        [setBookAppointmentToNUll.rejected]: (state, action) => {
            state.patientLoading = false
        },
        [getAllDoctorsAndOtherFunctionaries.pending]: (state, action) => {
            state.patientLoading = true
        },
        [getAllDoctorsAndOtherFunctionaries.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.doctors = action.payload

        },
        [getAllDoctorsAndOtherFunctionaries.rejected]: (state, action) => {
            state.patientLoading = false
        },
    }

})


export default recordsSlice.reducer;