/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DoctorTeamsTable, PageTitle } from '../../components'
import { getTeams } from '../../state/actions/doctor.actions'


const DoctorTeam = () => {
	const dispatch = useDispatch()

	const { teams } = useSelector(state => state.doctor)
	const [showList, setShowList] = useState(true)

	useEffect(() => {
		dispatch(getTeams())
	}, [])

	return (
		<div>
			<PageTitle type={'doctor-team'} />

			<div className="space-y-5">
				<DoctorTeamsTable
					title={'Teams'}
					data={teams}
					showList={showList}
					setShowList={setShowList}
				/>
			</div>
		</div>
	)
}

export default DoctorTeam