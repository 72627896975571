/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../state/context'
import DentistManagePatient from './DentistManagePatient'
import { DoctorPatientDependantsTable, DoctorPatientsTable, PageTitle } from '../../components'
import { getDoctorPatients, getPatientDependants } from '../../state/actions/doctor.actions'


const DentistPatients = () => {
	const dispatch = useDispatch()

	const { appState, setAppState } = useStateContext()

	const { data } = useSelector(state => state.auth.user)
	const { patient_update } = useSelector(state => state.root)
	const { doctor_patients, patient_transfer, patient_dependants } = useSelector(state => state.doctor)

	const [showList, setShowList] = useState(true)
	const [showPatientDependants, setShowPatientDependants] = useState(false)
	const [activeLink, setActiveLink] = useState('Patients')
	const [filterState, setFilterState] = useState({ search: '', patients: [], other_patients: [] })

	const handlePatientsFilter = () => {
		const filtered = doctor_patients
			?.filter(patient => patient.patient_doctor === data.pkid)
			?.filter(item => {
				return item.patient_number.toLowerCase().includes(filterState.search.toLowerCase())
					|| item.details.first_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| item.details.last_name.toLowerCase().includes(filterState.search.toLowerCase())
			})

		setFilterState({ ...filterState, patients: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, patients: doctor_patients?.filter(patient => patient.patient_doctor === data.pkid) })
		}
	}
	const handleOtherPatientsFilter = () => {
		const filtered = doctor_patients
			?.filter(patient => patient.patient_doctor !== data.pkid)
			?.filter(item => {
				return item.patient_number.toLowerCase().includes(filterState.search.toLowerCase())
					|| item.details.first_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| item.details.last_name.toLowerCase().includes(filterState.search.toLowerCase())
			})

		setFilterState({ ...filterState, other_patients: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, other_patients: doctor_patients?.filter(patient => patient.patient_doctor !== data.pkid) })
		}
	}

	useEffect(() => {
		dispatch(getDoctorPatients({ doc_name: data.pkid }))
	}, [patient_transfer])

	useEffect(() => {
		setFilterState({
			...filterState,
			patients: doctor_patients?.filter(patient => patient.patient_doctor === data.pkid),
			other_patients: doctor_patients?.filter(patient => patient.patient_doctor !== data.pkid)
		})
	}, [doctor_patients])

	useEffect(() => {
		if (activeLink === 'Patients') {
			handlePatientsFilter()
		}
		if (activeLink === 'Other Patients') {
			handleOtherPatientsFilter()
		}
	}, [filterState.search])

	useEffect(() => {
		if (showPatientDependants) {
			console.log(patient_update?.patient_number)
			dispatch(getPatientDependants({ his_id: patient_update?.patient_number }))
		}
	}, [showPatientDependants, patient_update])


	return (
		<div>
			<PageTitle type={'doctor-patients'} />

			<div className="space-y-5">
				{!appState.showDoctorManagePatient && !appState.showDoctorPatientDependants && (
					<div className="">
						<div className="flex justify-between items-center border-b-2 border-gray-500 pb-2 w-full">
							<div className="space-x-5">
								<button
									type="button"
									className={`${activeLink === 'Patients' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
									onClick={(e) => setActiveLink(e.target.textContent)}
								>
									Patients
								</button>
								<button
									type="button"
									className={`${activeLink === 'Other Patients' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
									onClick={(e) => setActiveLink(e.target.textContent)}
								>
									Other Patients
								</button>
							</div>

							<input
								type="text"
								name="search"
								placeholder='Search'
								className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
								onChange={(e) => setFilterState({ ...filterState, [e.target.name]: e.target.value })}
							/>

						</div>

						{activeLink === 'Patients' && (
							<DoctorPatientsTable
								title={'Patients'}
								data={filterState.patients}
								showPatientDependants={showPatientDependants}
								setShowPatientDependants={setShowPatientDependants}
							/>
						)}

						{activeLink === 'Other Patients' && (
							<DoctorPatientsTable
								title={'Other Patients'}
								data={filterState.other_patients}
								showPatientDependants={showPatientDependants}
								setShowPatientDependants={setShowPatientDependants}
							/>
						)}

					</div>
				)}

				{!appState.showDoctorManagePatient && appState.showDoctorPatientDependants && (
					<DoctorPatientDependantsTable
						data={patient_dependants}
						title={'Dependants'}
					/>
				)}

				{appState.showDoctorManagePatient && !appState.showDoctorPatientDependants && (
					<DentistManagePatient />
				)}
			</div>
		</div>
	)
}

export default DentistPatients