import React, { Fragment } from 'react'
import { useStateContext } from '../../../state/context'
import LaboratoryAddEquipmentModal from '../../modals/laboratorist/LaboratoryAddEquipmentModal'
import DeleteLaboratoryEquipmentModal from '../../modals/laboratorist/DeleteLaboratoryEquipmentModal'
import LaboratoryGenerateInvoiceModal from '../../modals/laboratorist/LaboratoryGenerateInvoiceModal'
import LaboratoryUploadReport from '../../modals/laboratorist/LaboratoryUploadReport'
import LaboratoryViewInvoice from '../../modals/laboratorist/LaboratoryViewInvoice'
import LaboratoryAddCategoryModal from '../../modals/laboratorist/LaboratoryAddCategoryModal'
import LaboratoryDeleteCategoryModal from '../../modals/laboratorist/LaboratoryDeleteInvestigationCategoryModal'
import LaboratoryAddInvestigationTypeModal from '../../modals/laboratorist/LaboratoryAddInvestigationTypeModal'
import LaboratoryDeleteInvestigationTypeModal from '../../modals/laboratorist/LaboratoryDeleteInvestigationModal'
import LaboratoryAddSampleModal from '../../modals/laboratorist/LaboratoryAddSampleModal'
import LaboratoryDeleteSampleTypeModal from '../../modals/laboratorist/LaboratoryDeleteSampleTypeModal'

const LaboratoryFormModal = (props) => {
    const { modals, setModals } = useStateContext()
    return (
        <Fragment>
            {props.type === "laboratory_add_equipment" && (
                <LaboratoryAddEquipmentModal />)}
            {props.type === "laboratory_update_equipment" && (
                <LaboratoryAddEquipmentModal />)}

            {props.type === "laboratory_delete_equipment" && (
                <DeleteLaboratoryEquipmentModal />)}
            {props.type === "laboratory_generate_invoice" && (
                <LaboratoryGenerateInvoiceModal />)}
            {props.type === "laboratory_upload_report" && (
                <LaboratoryUploadReport />)}
            {props.type === "laboratory_view_report" && (
                <LaboratoryUploadReport />)}
            {props.type === "laboratory_view_invoice" && (
                <LaboratoryViewInvoice />)}
            {props.type === "laboratory_add_category_modal" && (
                <LaboratoryAddCategoryModal />)}
            {props.type === "laboratory_update_category_modal" && (
                <LaboratoryAddCategoryModal />)}
            {props.type === "laboratory_delete_category_modal" && (
                <LaboratoryDeleteCategoryModal />)}

            {props.type === "laboratory_add_investigation_type" && (
                <LaboratoryAddInvestigationTypeModal />)}
            {props.type === "laboratory_update_investigation_type" && (
                <LaboratoryAddInvestigationTypeModal />)}
            {props.type === "laboratory_delete_investigation_type" && (
                <LaboratoryDeleteInvestigationTypeModal />)}
            {props.type === "laboratory_add_sample_type" && (
                <LaboratoryAddSampleModal />)}
            {props.type === "laboratory_update_sample_type" && (
                <LaboratoryAddSampleModal />)}
            {props.type === "laboratory_delete_sample_type" && (
            <LaboratoryDeleteSampleTypeModal  />)}






        </Fragment>
    )
}


export default LaboratoryFormModal

