/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getLaboratoryInvoices, getPharmacyInvoices, getRadiologyInvoices, getRecordsInvoices } from '../../state/actions/collection.actions'
import { CollectionsAllInvoicesTable, CollectionsAllPaidInvoicesTable, CollectionsPaidLaboratoryInvoicesTable, CollectionsPaidPharmacyInvoicesTable, CollectionsPaidRadiologyInvoicesTable, CollectionsPaidRecordsInvoicesTable, PageTitle } from '../../components'
import { getAllInvoices } from '../../state/actions/accountant.actions'


const CollectionsPaidInvoices = () => {
    const dispatch = useDispatch()

    const { all_invoices } = useSelector(state => state.accountant)
    const { laboratory_invoices, pharmacy_invoices, radiology_invoices, records_invoices } = useSelector(state => state.collection)

    const [activeLink, setActiveLink] = useState('All')
    const [showTables, setShowTables] = useState({ laboratory: true, pharmacy: true, radiology: true, records: true, })
    const [filterState, setFilterState] = useState({ display: false, filter: 'Day', staff_patient_email: '', inventory_item: '' })

    useEffect(() => {
        dispatch(getAllInvoices())
        dispatch(getLaboratoryInvoices())
        dispatch(getPharmacyInvoices())
        dispatch(getRadiologyInvoices())
        dispatch(getRecordsInvoices())
    }, [])

    return (
        <div>
            <PageTitle type={'collections-paid-invoices'} />

            <div className="">
                <div className="flex space-x-5 items-center border-b-2 border-gray-500 pb-2 w-1/2">
                    <button
                        type="button"
                        className={`${activeLink === 'All' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => setActiveLink(e.target.textContent)}
                    >
                        All
                    </button>
                    <button
                        type="button"
                        className={`${activeLink === 'Laboratory' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => setActiveLink(e.target.textContent)}
                    >
                        Laboratory
                    </button>
                    <button
                        type="button"
                        className={`${activeLink === 'Pharmacy' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => setActiveLink(e.target.textContent)}
                    >
                        Pharmacy
                    </button>
                    <button
                        type="button"
                        className={`${activeLink === 'Radiology' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => setActiveLink(e.target.textContent)}
                    >
                        Radiology
                    </button>
                    <button
                        type="button"
                        className={`${activeLink === 'Records' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => setActiveLink(e.target.textContent)}
                    >
                        Records
                    </button>
                </div>

                {activeLink === 'All' && (
                    <CollectionsAllPaidInvoicesTable
                        showList={showTables.laboratory}
                        // data={all_invoices}
                        data={all_invoices?.filter(invoice => invoice.is_paid === true)}

                    />
                )}

                {activeLink === 'Laboratory' && (
                    <CollectionsPaidLaboratoryInvoicesTable
                        showList={showTables.laboratory}
                        data={laboratory_invoices?.filter(invoice => invoice.is_paid === true)}
                    />
                )}

                {activeLink === 'Pharmacy' && (
                    <CollectionsPaidPharmacyInvoicesTable
                        showList={showTables.pharmacy}
                        data={pharmacy_invoices?.filter(invoice => invoice.is_paid === true)}
                    />
                )}

                {activeLink === 'Radiology' && (
                    <CollectionsPaidRadiologyInvoicesTable
                        showList={showTables.radiology}
                        data={radiology_invoices?.filter(invoice => invoice.is_paid === true)}
                    />
                )}

                {activeLink === 'Records' && (
                    <CollectionsPaidRecordsInvoicesTable
                        showList={showTables.records}
                        data={records_invoices?.filter(invoice => invoice.is_paid === true)}
                    />
                )}
            </div>
        </div>
    )
}

export default CollectionsPaidInvoices