/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FaTimesCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useStateContext } from "../../../state/context";
import { setBilling } from "../../../state/actions/actions";
import { createBilling, getAllBillings, updateBilling, } from "../../../state/actions/admin.actions";
import { getAllLaboratoryInvestigationCategory, getLaboratoryTypesByCatgeory } from "../../../state/actions/laboratorist.actions";
import { getAllRadiologyCategoryTest, getAllRadiologyTypesByCategory } from "../../../state/actions/radiologist.actions";


const AddBillingModal = (props) => {
	const dispatch = useDispatch();

	const { modals, setModals } = useStateContext()

	const [checked, setChecked] = useState(false);
	const [formData, setFormData] = useState({ name: "", price: "", description: "", status: false, investigation_category: "" });
	const { billing_update } = useSelector(state => state.root)
	const { user } = useSelector(state => state.auth)
	const { all_lab_category_test, lab_types_by_category } = useSelector((state) => state.laboratorist)
	const { all_radiology_category_test, all_rad_types_by_category } = useSelector((state) => state.radiologist)
	
	useEffect(() => {
		setChecked(billing_update?.status ? billing_update?.status : checked)
		setFormData({ ...formData, name: billing_update?.name, price: billing_update?.price, description: billing_update?.description, status: billing_update?.status })
	}, [billing_update])

	useEffect(() => {
		dispatch(getAllLaboratoryInvestigationCategory())
		dispatch(getAllRadiologyCategoryTest())
	}, [])

	const handleChange = (e, name) => {
		setFormData((prevState) => ({ ...prevState, [name]: e.target.value }));
	};
	const handleCheckboxChange = () => {
		setChecked(!checked);
		setFormData({ ...formData, status: !checked });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(formData);

		if (!formData.name || !formData.price || !formData.description) return toast.warning("Please fill all fields");
		dispatch(createBilling({ formData, toast, setModals: setModals, modals: modals }));
		// dispatch(getAllBillings({ navigate, toast }));
	};

	const handleUpdate = (e) => {
		e.preventDefault()

		console.log(formData)
		if (!formData.name || !formData.price || !formData.description) return toast.warning("Please fill all fields")
		dispatch(updateBilling({ formData: { ...formData, id: billing_update?.id }, toast, setModals: setModals, modals: modals }));
	}

	return (
		<div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
			<div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
				<div className="flex flex-col space-y-5">
					<div className="flex justify-between items-center">
						<h4 className={`${modals.showAddBillingModal ? 'text-dark-green' : 'text-sky-600'} text-[18px] font-medium`}>
							{modals.showAddBillingModal ? 'Add Billing' : 'Update Billing'}
						</h4>
						<FaTimesCircle
							className="text-dark-yellow cursor-pointer"
							onClick={() => {
								dispatch(setBilling({ data: null }))
								modals.showAddBillingModal && setModals({ ...modals, showAddBillingModal: !modals.showAddBillingModal })
								modals.showUpdateBillingModal && setModals({ ...modals, showUpdateBillingModal: !modals.showUpdateBillingModal })
							}}
							size={20}
						/>
					</div>

					{modals.showAddBillingModal && (
						<form onSubmit={handleSubmit}>
							{user?.data?.is_laboratorist && <div className='space-y-5 w-full mb-2'>
								<select name={formData.investigation_category} placeholder='Type' id="" className='border rounded mt-2 pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 '
									onClick={(e) => {
										dispatch(getLaboratoryTypesByCatgeory({ id: e.target.value }))
									}}
								>
									<option value={null}>Select Category</option>
									{all_lab_category_test?.map((category, index) => (
										<option value={category.pkid}>{category.title}</option>
									))}

								</select>
								<select name="name" placeholder='Type' id="" className='border rounded italic mt-2 pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 '

									onChange={(e) => handleChange(e, e.target.name)}
								>
									<option value={null}>Select Test type</option>
									{lab_types_by_category?.map((test, index) => (
										<option value={test.type_title}>{test.type_title}</option>
									))}

								</select>

							</div>}

							{user?.data?.is_radiologist && <div className='space-y-5 w-full mb-2'>
								<select name={formData.investigation_category} placeholder='Type' id="" className='border italic rounded mt-2 pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 '
									onClick={(e) => {
										console.log(e.target.value);
										dispatch(getAllRadiologyTypesByCategory({ id: e.target.value }))
									}}
								>
									<option value={null}>Select Category</option>
									{all_radiology_category_test?.map((category, index) => (
										<option value={category.pkid}>{category.title}</option>
									))}

								</select>
								<select name="name" placeholder='Type' id="" className='border italic rounded mt-2 pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 '

									onChange={(e) => handleChange(e, e.target.name)}
								>
									<option value={null}>Select Test type</option>
									{all_rad_types_by_category?.map((test, index) => (
										<option value={test.title}>{test.title}</option>
									))}

								</select>

							</div>}
							{user?.data?.is_administrator === true && <div>
								<input
									type="text"
									name="name"
									placeholder="Billing name"
									className="border rounded w-full h-5 px-4 py-5 mt-2 italic hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
									onChange={(e) => handleChange(e, e.target.name)}
								/>
							</div>}

							<div>
								<input
									type="number"
									name="price"
									placeholder="Price"
									className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
									onChange={(e) => handleChange(e, e.target.name)}
								/>
							</div>

							<div>
								<textarea
									cols="30"
									rows="7"
									name="description"
									placeholder="Description"
									className="border rounded w-full px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue resize-none"
									onChange={(e) => handleChange(e, e.target.name)}
								></textarea>
							</div>
							<div className="flex items-center space-x-3">
								<input
									className="rounded border-4 w-5 h-5 border-red-400"
									type="checkbox"
									name="status"
									checked={checked}
									onChange={handleCheckboxChange}
								/>
								<p className="text-[16px] text-gray-700 font-bold">Status</p>
							</div>

							<div className="mt-4">
								<button
									type="submit"
									className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
								>
									Submit
								</button>
							</div>
						</form>
					)}

					{modals.showUpdateBillingModal && (
						<form onSubmit={handleUpdate}>
							<div>
								<input
									type="text"
									name="name"
									placeholder="Billing name"
									className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
									value={formData.name}
									onChange={(e) => handleChange(e, e.target.name)}
								/>
							</div>

							<div>
								<input
									type="number"
									name="price"
									placeholder="Price"
									className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
									value={formData.price}
									onChange={(e) => handleChange(e, e.target.name)}
								/>
							</div>

							<div>
								<textarea
									cols="30"
									rows="7"
									name="description"
									placeholder="Description"
									className="border rounded w-full px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue resize-none"
									value={formData.description}
									onChange={(e) => handleChange(e, e.target.name)}
								></textarea>
							</div>
							<div className="flex items-center space-x-3">
								<input
									className="rounded border-4 w-5 h-5 border-red-400"
									type="checkbox"
									name="status"
									checked={checked}
									onChange={handleCheckboxChange}
								/>
								<p className="text-[16px] text-gray-700 font-bold">Status</p>
							</div>

							<div className="mt-4">
								<button
									type="submit"
									className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
								>
									Update
								</button>
							</div>
						</form>
					)}
				</div>
			</div>
		</div>
	);
};

export default AddBillingModal;
