import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { BiWebcam } from 'react-icons/bi'
import { BsFillPencilFill } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'

import { PageTitle } from '../../components'
import { bloodgroups, countries, genotypes, maritalstatus } from '../../data'
import { profileUpdate, updateProfilePhoto, updateUserProfile } from '../../state/actions/auth.actions'
import { useStateContext } from '../../state/context'


const Profile = (props) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()
    const { profile } = useSelector(state => state.auth)

    const [photo, setPhoto] = useState({
        profile_photo: {}, profile_photo_preview: '',
    })
    const [formData, setFormData] = useState({
        // profile_photo: {}, profile_photo_preview: '',
        first_name: '', last_name: '',
        email: '', nhis_number: '',
        patient_phone: '', country: '',
        state: '', lga_or_province: '',
        address: '', next_kin_name: '',
        next_kin_address: '', next_kin_phone_number: '',
        relationship_with_next_of_kin: '', genotype: '',
        blood_group: '', maritalstatus: '',
        is_profile_updated: true
    })

    useEffect(() => {
        setPhoto({
            ...photo,
            profile_photo: profile?.profile_photo,
        })

        setFormData({
            ...formData,
            first_name: profile?.user_details.first_name,
            last_name: profile?.user_details.last_name,
            email: profile?.user_details.email,
            nhis_number: profile?.nhis_number,
            patient_phone: profile?.patient_phone,
            country: profile?.country,
            state: profile?.state,
            lga_or_province: profile?.lga_or_province,
            address: profile?.address,
            next_kin_name: profile?.next_kin_name,
            next_kin_address: profile?.next_kin_address,
            next_kin_phone_number: profile?.next_kin_phone_number,
            relationship_with_next_of_kin: profile?.relationship_with_next_of_kin,
            genotype: profile?.genotype,
            blood_group: profile?.blood_group,
        marital_status: profile?.marital_status,
            is_profile_updated: true
        })
    }, [profile])

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }));
    };

    const handleImageChange = (e) => {
        return setPhoto({ ...photo, profile_photo: e.target.files[0], profile_photo_preview: URL.createObjectURL(e.target.files[0]) })
    }

    const handleUpdate = (e) => {
        e.preventDefault()

        // console.log(formData)
        dispatch(profileUpdate({ formData, toast }))
    }

    const handleUpdatePhoto = (e) => {
        if (!photo.profile_photo_preview) return
        console.log(photo);
        dispatch(updateProfilePhoto({ formData: { ...photo }, toast, setModals, modals, type: 'upload' }))
    }

    console.log(profile)

    return (
        <div>
            <PageTitle
                type={'profile'}

                modals={props.modals}
                setModals={props.setModals}
            />

            <div className="flex flex-col space-y-5 pl-5">
                <div className="flex justify-start items-center">
                    <div className="relative">
                        <img
                            className='w-[10em] h-[10em] rounded-full'
                            src={photo.profile_photo_preview ? photo.profile_photo_preview : photo.profile_photo}
                            alt="profile__photo"
                        />
                        <div className='absolute bottom-2 right-4 bg-blue-400 h-10 w-10 rounded-full flex items-center justify-center cursor-pointer shadow-md'>
                            <div className="relative">
                                <BsFillPencilFill
                                    className='text-white absolute right-[10.5em] top-[5px] cursor-pointer'
                                />
                                <input type="file" className='hover:file:cursor-pointer opacity-0' onChange={handleImageChange} />
                            </div>
                        </div>
                        <div className='absolute bottom-14 right-[-15px] bg-blue-600 h-10 w-10 rounded-full flex items-center justify-center cursor-pointer shadow-md'>
                            <div className="relative">
                                <BiWebcam
                                    size={28}
                                    onClick={() => setModals({ ...modals, showWebCamModal: !modals.showWebCamModal })}
                                    className='text-white absolute right-[-14px] bottom-[-13px] cursor-pointer'
                                />
                            </div>
                        </div>
                    </div>

                    <div className="ml-[4em]">
                        <div className="flex flex-col justify-start">
                            <button
                                type="submit"
                                disabled={!photo.profile_photo_preview}
                                className=" bg-indigo-500 text-[12px] text-white py-1 px-8 rounded-md hover:bg-indigo-500 shadow-md hover:scale-[.92] transition duration-500 ease"
                                onClick={handleUpdatePhoto}
                            >
                                Update Photo
                            </button>
                        </div>
                    </div>
                </div>

                <form onSubmit={handleUpdate}>
                    <div className="w-full flex flex-wrap justify-between pb-5">
                        <div className="space-y-5 pb-10">
                            <div className='flex justify-between items-center w-[450px]'>
                                <div className='relative w-[220px]'>
                                    <label className='absolute bottom-6 text-[12px] text-dark-blue'>First name</label>
                                    <input
                                        type="text"
                                        name="first_name"
                                        value={formData.first_name}
                                        className="border rounded w-full h-5 px-4 py-4 mt-2 text-[12px] hover:outline-none focus:outline-none focus:ring-blue-400 focus:ring-blue"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div className='relative w-[220px]'>
                                    <label className='absolute bottom-6 text-[12px] text-dark-blue'>Last name</label>
                                    <input
                                        type="text"
                                        name="last_name"
                                        value={formData.last_name}
                                        className="border rounded w-full h-5 px-4 py-4 mt-2 text-[12px] hover:outline-none focus:outline-none focus:ring-blue-400 focus:ring-blue"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>
                            </div>

                            <div className='flex justify-between items-center w-[450px]'>
                                <div className='relative w-full'>
                                    <label className='absolute bottom-6 text-[12px] text-dark-blue'>Email</label>
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        className="border rounded w-full h-5 px-4 py-4 mt-2 text-[12px] hover:outline-none focus:outline-none focus:ring-blue-400 focus:ring-blue"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>
                            </div>

                            <div className='flex justify-between items-center w-[450px]'>
                                <div className='relative w-full'>
                                    <label className='absolute bottom-6 text-[12px] text-dark-blue'>NHIS ID</label>
                                    <input
                                        type="text"
                                        name="nhis_number"
                                        disabled
                                        value={formData.nhis_number}
                                        className="border rounded w-full h-5 px-4 py-4 mt-2 text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>
                            </div>

                            <div className='flex justify-between items-center w-[450px]'>
                                <div className='relative w-full'>
                                    <label className='absolute bottom-6 text-[12px] text-dark-blue'>Phone</label>
                                    <input
                                        type="text"
                                        name="patient_phone"
                                        value={formData.patient_phone}
                                        className="border rounded w-full h-5 px-4 py-4 mt-2 text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>
                            </div>

                            <div className="w-[450px] mb-6 md:mb-0">
                                <div className="relative">
                                    <select
                                        className="appearance-none w-[450px] text-sm bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state"
                                        name='country'
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    >
                                        <option>{formData.country}</option>
                                        {countries.map((country, index) => (
                                            <option
                                                key={index}
                                                value={country.title}
                                            >
                                                {country.title}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                    </div>
                                </div>
                            </div>

                            <div className='flex justify-between items-center w-[450px]'>
                                <div className='relative w-full'>
                                    <label className='absolute bottom-6 text-[12px] text-dark-blue'>State</label>
                                    <input
                                        type="text"
                                        name="state"
                                        value={formData.state}
                                        className="border rounded w-full h-5 px-4 py-4 mt-2 text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>
                            </div>

                            <div className='flex justify-between items-center w-[450px]'>
                                <div className='relative w-full'>
                                    <label className='absolute bottom-6 text-[12px] text-dark-blue'>LGA or Province</label>
                                    <input
                                        type="text"
                                        name="lga_or_province"
                                        value={formData.lga_or_province}
                                        className="border rounded w-full h-5 px-4 py-4 mt-2 text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>
                            </div>

                            <div className='flex justify-between items-center w-[450px]'>
                                <div className='relative w-full'>
                                    <label className='absolute bottom-6 text-[12px] text-dark-blue'>Address</label>
                                    <input
                                        type="text"
                                        name="address"
                                        value={formData.address}
                                        className="border rounded w-full h-5 px-4 py-4 mt-2 text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="space-y-5">
                            <div className='flex justify-between items-center w-[450px]'>
                                <div className='relative w-full'>
                                    <label className='absolute bottom-6 text-[12px] text-dark-blue'>Next of kin</label>
                                    <input
                                        type="text"
                                        name="next_kin_name"
                                        value={formData.next_kin_name}
                                        className="border rounded w-full h-5 px-4 py-4 mt-2 text-[12px] hover:outline-none focus:outline-none focus:ring-blue-400 focus:ring-blue"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>
                            </div>

                            <div className='flex justify-between items-center w-[450px]'>
                                <div className='relative w-full'>
                                    <label className='absolute bottom-6 text-[12px] text-dark-blue'>Next of kin address</label>
                                    <input
                                        type="text"
                                        name="next_kin_address"
                                        value={formData.next_kin_address}
                                        className="border rounded w-full h-5 px-4 py-4 mt-2 text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>
                            </div>

                            <div className='flex justify-between items-center w-[450px]'>
                                <div className='relative w-full'>
                                    <label className='absolute bottom-6 text-[12px] text-dark-blue'>Next of kin phone</label>
                                    <input
                                        type="text"
                                        name="next_kin_phone_number"
                                        value={formData.next_kin_phone_number}
                                        className="border rounded w-full h-5 px-4 py-4 mt-2 text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>
                            </div>

                            <div className='flex justify-between items-center w-[450px]'>
                                <div className='relative w-full'>
                                    <label className='absolute bottom-6 text-[12px] text-dark-blue'>Next of kin relationship</label>
                                    <input
                                        type="text"
                                        name="relationship_with_next_of_kin"
                                        value={formData.relationship_with_next_of_kin}
                                        className="border rounded w-full h-5 px-4 py-4 mt-2 text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>
                            </div>

                            <div className="w-full pt-[8px]">
                                <div className="relative">
                                    <label className='absolute bottom-6 text-[12px] text-dark-blue'>Genotype</label>
                                    <select
                                        className="appearance-none text-[16px] w-full bg-gray-200 border border-gray-200 text-gray-700 py-[5px] px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state"
                                        name='genotype'
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    >
                                        <option>{formData.genotype}</option>
                                        {genotypes?.map((item, index) => (
                                            <option
                                                key={index}
                                                value={item}
                                            >
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full pt-[8px]">
                                <div className="relative">
                                    <label className='absolute bottom-6 text-[12px] text-dark-blue'>Blood Group</label>
                                    <select
                                        className="appearance-none text-[16px] w-full bg-gray-200 border border-gray-200 text-gray-700 py-[5px] px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state"
                                        name='blood_group'
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    >
                                        <option>{formData.blood_group}</option>
                                        {bloodgroups?.map((item, index) => (
                                            <option
                                                key={index}
                                                value={item}
                                            >
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full pt-[8px]">
                                <div className="relative">
                                    <label className='absolute bottom-6 text-[12px] text-dark-blue'>Marital Status</label>
                                    <select
                                        className="appearance-none text-[16px] w-full bg-gray-200 border border-gray-200 text-gray-700 py-[5px] px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state"
                                        name='marital_status'
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    >
                                        <option>{formData.marital_status}</option>
                                        {maritalstatus?.map((item, index) => (
                                            <option
                                                key={index}
                                                value={item}
                                            >
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col justify-start">
                        <button
                            type="submit"
                            className="w-[175px] bg-dark-green text-[12px] text-white py-1 px-6 rounded-md hover:bg-dark-green"
                        >
                            Update Profile
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Profile