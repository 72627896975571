/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'
import { FaUser } from 'react-icons/fa'
import { useDispatch } from 'react-redux'

import Pagination from '../utils/Pagination'
import PaymentStatus from '../utils/PaymentStatus'
import { useStateContext } from '../../state/context'
import { setCurrentDiscounts, setCurrentInvoice } from '../../state/actions/accountant.actions'


const AccountsInvoiceDiscountTable = ({ data }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [currentData, setCurrentData] = useState([])
    const [accumulatedList, setAccumulatedList] = useState([])
    const [filterState, setFilterState] = useState({ search: '', discounts: [] })

    const handleFilter = () => {
        const filtered = data?.filter(item => {
            return item.invoice_details.invoice_number.toLowerCase().includes(filterState.search.toLowerCase())
                || item.invoice_details.patient.toLowerCase().includes(filterState.search.toLowerCase())
                || item.discount_type.toLowerCase().includes(filterState.search.toLowerCase())
                || item.amount.toLowerCase().includes(filterState.search.toLowerCase())
        })

        setFilterState({ ...filterState, discounts: filtered })

        if (filterState.search === '') {
            setFilterState({ ...filterState, discounts: data })
        }
    }

    useEffect(() => {
        setFilterState({ ...filterState, discounts: data })
    }, [data])

    useEffect(() => {
        handleFilter()
    }, [filterState.search])



    return (
        <div className="flex justify-between items-center w-full">
            <div className="flex flex-col w-full cursor-pointer">
                <div className="">
                    <div className='flex justify-between mb-2'>
                        <input
                            type="text"
                            name="search"
                            // disabled
                            placeholder='Search'
                            className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                            onChange={(e) => setFilterState({ ...filterState, [e.target.name]: e.target.value })}
                        />

                        <button
                            type="button"
                            className="mb-2 mt-2 inline-block px-4 py-2 bg-sky-500 text-white font-medium text-[10px] leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                            onClick={() => {
                                if (accumulatedList.length > 0) {
                                    dispatch(setCurrentDiscounts({ data: accumulatedList }))
                                    setModals({ ...modals, showAccountsRemitDiscountsModal: !modals.showAccountsRemitDiscountsModal })
                                }
                            }}
                        >
                            Remit
                        </button>

                    </div>
                    <Fragment>
                        <table className="border rounded w-full cursor-default">
                            <thead className="bg-gray-100">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pl-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >

                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        Invoice ID
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        Patient
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        Invoice
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        Amount
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >

                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pl-4 py-4 text-left"
                                    >
                                        <input
                                            type="checkbox"
                                            onChange={() => {
                                                if (accumulatedList.length === 0) {
                                                    setAccumulatedList(currentData)
                                                } else {
                                                    setAccumulatedList([])
                                                }
                                            }}
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentData?.map((invoice, index) => (
                                    <tr className="" key={invoice.id}>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                            <input
                                                type="checkbox"
                                                checked={accumulatedList?.find((item) => item.id === invoice.id)}
                                                onChange={(e) => {
                                                    const myIndex = accumulatedList.findIndex(e => e.id === invoice.id)
                                                    console.log(e.target.value)
                                                    if (myIndex >= 0) {
                                                        setAccumulatedList([...accumulatedList.filter(inv => inv.pkid !== invoice.pkid)])
                                                    } else {
                                                        setAccumulatedList([...accumulatedList, invoice])
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {invoice.invoice_details.invoice_number}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">

                                            {invoice.invoice_details.patient}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">

                                            {invoice.discount_type}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {invoice.amount}
                                        </td>
                                        <td className="text-sm w-[100px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {/* {invoice.patient_phone ? invoice.patient_phone : '-'} */}
                                            <PaymentStatus status={invoice.is_paid} />
                                        </td>
                                        <td className="text-sm text-gray-900 font-light pl-4 py-2 whitespace-nowrap">
                                            <button
                                                type="button"
                                                className="inline-block px-2 py-2 bg-teal-500 text-white font-medium text-xs leading-tight uppercase shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                onClick={() => {
                                                    dispatch(setCurrentInvoice({ data: invoice }))
                                                    setModals({ ...modals, showAccountsViewDiscountInvoiceModal: !modals.showAccountsViewDiscountInvoiceModal })
                                                }}
                                            >
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <Pagination
                            data={filterState.discounts}
                            currentData={currentData}
                            setCurrentData={setCurrentData}
                        />
                    </Fragment>

                </div>
            </div>
        </div>
    )
}

export default AccountsInvoiceDiscountTable