/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { BsPersonPlus } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'

import { PageTitle } from '../../components'
import { useStateContext } from '../../state/context'
import Pagination from '../../components/utils/Pagination'
import NursePatientDiagnosis from './NursePatientDiagnosis'
import { getAllOpds } from '../../state/actions/nurse.actions'
import OPDTable from '../../components/tables/nursesTable/OPDTable'


const NurseOPD = () => {
	const { opdState, setOpdState } = useStateContext()
	const dispatch = useDispatch()
	const { all_opds } = useSelector((state) => state.nurse)
	const [currentData, setCurrentData] = useState([])

	useEffect(() => {
		dispatch(getAllOpds())

		return () => {
			setOpdState({ ...opdState, showOpdTable: true, showDiagnosisPrescription: false, showPatientDiagnosisHistory: false, })
		}
	}, [])

	return (
		<div>
			<PageTitle type={'nurse-addPatient'} />
			{opdState.showOpdTable && (
				<div className='flex flex-col w-full cursor-pointer'>
					<div className='py-2'>
						<div className='flex justify-start items-center space-x-4 bg-gray-200 p-1'>
							<h4 className='text-dark-blue text-[14px] font-bold'>
								Patients
							</h4>
							<BsPersonPlus
								className='text-dark-blue'
								size={20}
							/>
						</div>
						<table className='border rounded w-full cursor-default'>
							<thead className=''>
								<tr>
									<th
										scope='col'
										className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
									>
										S/N
									</th>

									<th
										scope='col'
										className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
									>
										Patient Number
									</th>
									<th
										scope='col'
										className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
									>
										Full name
									</th>
									<th
										scope='col'
										className='text-sm font-medium text-gray-900 py-4 text-left'
									>
										Phone number
									</th>
									<th
										scope='col'
										className='text-sm font-medium text-gray-900 py-4 text-left'
									>
										Gender
									</th>


									<th
										scope='col'
										className='text-sm font-medium text-gray-900 py-4 text-left'
									>
										Actions
									</th>
								</tr>
							</thead>


							<OPDTable data={currentData} />

						</table>

						<Pagination
							data={all_opds}
							currentData={currentData}
							setCurrentData={setCurrentData}
						/>
					</div>
				</div>
			)}
			{opdState.showPatientDiagnosisHistory && (
				<NursePatientDiagnosis />
			)}


		</div>
	)
}

export default NurseOPD