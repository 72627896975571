/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import { toast } from 'react-toastify'
import { FaTimesCircle } from 'react-icons/fa'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { updateHospitalLogo } from '../../../state/actions/admin.actions'


const UpdateHospitalLogoModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { hospital_config } = useSelector(state => state.admin)

    const logoRef = useRef(null)
    const [formData, setFormData] = useState({ id: '', name: '', phone: '', sms_tag: '', email: '', logo: '', address: '', logo_preview: ''})


    useEffect(() => {
        setFormData({
            ...formData,
            id: hospital_config?.pkid,
            name: hospital_config?.name,
            phone: hospital_config?.phone,
            sms_tag: hospital_config?.sms_tag,
            email: hospital_config?.email,
            logo_preview: `${process.env.REACT_APP_API_ROOT}${hospital_config?.logo}`,
            address: hospital_config?.address
        })

    }, [hospital_config])

    const handleFileChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.files[0], logo_preview: URL.createObjectURL(e.target.files[0]) })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        console.log(formData)
        dispatch(updateHospitalLogo({ formData, toast, modals, setModals }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className={`text-sky-600 text-[16px] font-medium capitalize`}>
                            Update Pharmacy Logo
                        </h4>
                        <FaTimesCircle
                            size={20}
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                setModals({ ...modals, showAdminUpdateHospitalLogoModal: !modals.showAdminUpdateHospitalLogoModal })
                            }}
                        />
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className='flex justify-center space-x-5 items-center w-full py-4'>
                            <img
                                src={formData.logo_preview}
                                alt=""
                                className='w-[100px] h-[100px] rounded-full'
                            />
                            <div
                                onClick={() => logoRef.current.click()}
                                className="border p-2 rounded-md cursor-pointer"
                            >
                                <AiOutlineCloudUpload
                                    size={80}
                                />
                            </div>
                            <input
                                ref={logoRef}
                                type="file"
                                name="logo"
                                className="hidden"
                                accept='image/jpeg, image/png, image/jpg'
                                onChange={(e) => handleFileChange(e)}
                            />
                        </div>

                        <div className="mt-4">
                            <button
                                type="submit"
                                className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default UpdateHospitalLogoModal
