import React from 'react'
import { useStateContext } from '../../../state/context'
import { FaTimesCircle } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { setLabCategoryTest } from '../../../state/actions/actions'
import { deleteALaboratoryCategoryTest } from '../../../state/actions/laboratorist.actions'


const LaboratoryDeleteCategoryModal = () => {
  const dispatch = useDispatch()
  const { modals, setModals } = useStateContext()
  const { set_laboratory_category_test } = useSelector((state) => state.root)
  const handleSubmit = (e) => {

    e.preventDefault()
    // if (modals.showDeleteRadiologyEquipment) {
    //   dispatch(deleteARadiologyEquipment({ equipment_id, type, toast, setModals: setModals, modals: modals }))
    //   dispatch(setRadiologyEquipment({ data: null }))
    // }
    if (modals.showLaboratoryDeleteCategoryModal) {
      const cat_id = set_laboratory_category_test?.cat_id
      dispatch(deleteALaboratoryCategoryTest({ cat_id, toast, setModals: setModals, modals: modals }))
      dispatch(setLabCategoryTest({ data: null }))
    }

  }
  return (
    <div>
      <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
        <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
          <div className='flex flex-col space-y-5'>
            <div className='flex justify-between items-center'>

              <>
                {/* {modals.showDeleteRadiologyEquipment && (
                  <h4 className='text-dark-green text-[20px] font-bold'>
                    Are you sure you want to delete - "{set_radiology_equipment?.title}"
                  </h4>
                )} */}
                {modals.showLaboratoryDeleteCategoryModal && (
                  <h4 className='text-dark-green text-[20px] font-bold'>
                    Are you sure you want to delete - "{set_laboratory_category_test?.cat_name}"
                  </h4>
                )}


              </>


              <FaTimesCircle
                className='text-dark-yellow cursor-pointer'
                onClick={() => {
                  // if (modals.showDeleteRadiologyEquipment) {
                  //   dispatch(setRadiologyEquipment({ data: null }))
                  //   setModals({
                  //     ...modals,
                  //     showDeleteRadiologyEquipment:
                  //       !modals.showDeleteRadiologyEquipment,
                  //   })
                  // }
                  if (modals.showLaboratoryDeleteCategoryModal) {

                    setModals({
                      ...modals,
                      showLaboratoryDeleteCategoryModal:
                        !modals.showLaboratoryDeleteCategoryModal,
                    })
                    dispatch(setLabCategoryTest({ data: null }))

                  }
                }


                }
                size={20}
              />

            </div>
            <button
              type='button'
              className=' px-6 py-2.5 mx-auto bg-dark-green  text-white font-medium text-xs w-[200px] rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease'
              onClick={handleSubmit}
            >
              <span className='flex items-center justify-between pl-12'>
                Delete

              </span>
            </button>


          </div>
        </div>
      </div>
    </div>
  )
}

export default LaboratoryDeleteCategoryModal