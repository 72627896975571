/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { FaTimesCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import ButtonLoader from "../../utils/ButtonLoader";
import { useStateContext } from '../../../state/context'
import { setEmployee } from "../../../state/actions/actions";
import { createEmployee, getAllDepartments, getAllDirectorates, getAllEmployees, updateEmployee } from "../../../state/actions/admin.actions";

const CreateEmployeeModal = (props) => {
	const { modals, setModals } = useStateContext()
	const dispatch = useDispatch();

	const { employee_update } = useSelector((state) => state.root)
	const { adminLoading, employees, hospital_config } = useSelector((state) => state.admin)

	const [checkActive, setCheckActive] = useState(false);
	const [checkLaboratorist, setCheckLaboratorist] = useState(false);
	const [checkRadiologist, setCheckRadiologist] = useState(false);
	const [checkPatient, setCheckPatient] = useState(false);
	const [checkRecords, setcheckRecords] = useState(false);
	const [checkNurse, setCheckNurse] = useState(false);
	const [checkDoctor, setCheckDoctor] = useState(false);
	const [checkPharmacist, setCheckPharmacist] = useState(false);
	const [checkAccountant, setCheckAccountant] = useState(false);
	const [checkCollections, setCheckCollections] = useState(false);
	const [checkSecretory, setCheckSecretory] = useState(false);
	const [checkAudit, setCheckAudit] = useState(false);
	const [checkDentist, setCheckDentist] = useState(false);
	const [checkOpthamologist, setCheckOpthamologist] = useState(false);
	const [checkOtolaryngologists, setcheckotolaryngologists] = useState(false);
	const [checkMidwife, setCheckMidwife] = useState(false);
	const [checkPhysiotherapist, setCheckPhysiotherapist] = useState(false);
	const [checkIsDepartmentHead, setCheckIsDepartmentHead] = useState(false);
	const [checkAdministrator, setCheckAdministrator] = useState(false);

	const { directorates, departments } = useSelector((state) => state.admin);

	const [formData, setFormData] = useState({
		first_name: "",
		last_name: "",
		email: "",
		directorate: "",
		department: "",
		specialization: "",
		designation: "",
		is_active: false,
		is_laboratorist: false,
		is_radiologist: false,
		is_doctor: false,
		is_patient: false,
		is_pharmacist: false,
		is_records: false,
		is_nurse: false,
		is_accountant: false,
		is_collections: false,
		is_secretory: false,
		is_audit: false,
		is_dentist: false,
		is_ophthalmologists: false,
		is_otolaryngologists: false,
		is_mid_wife: false,
		is_physiotherapists: false,
		is_department_head: false,
		is_administrator: false,
	})
	const [config, setConfig] = useState({ showHeadButton: true })

	useEffect(() => {
		dispatch(getAllDirectorates())
		dispatch(getAllDepartments())
	}, [])

	useEffect(() => {
		setFormData({
			...formData,
			directorate: employee_update?.user_details?.directorate,
			department: employee_update?.user_details?.department,
			first_name: employee_update?.user_details?.first_name,
			last_name: employee_update?.user_details?.last_name,
			email: employee_update?.user_details?.email,
			specialization: employee_update?.user_details?.specialization,
			designation: employee_update?.user_details?.designation,
			is_active: employee_update?.user_details?.is_active ? employee_update?.user_details?.is_active : checkActive,
			is_administrator: employee_update?.user_details?.is_administrator ? employee_update?.user_details?.is_administrator : checkAdministrator,
			is_laboratorist: employee_update?.user_details?.is_laboratorist ? employee_update?.user_details?.is_laboratorist : checkLaboratorist,
			is_radiologist: employee_update?.user_details?.is_radiologist ? employee_update?.user_details?.is_radiologist : checkRadiologist,
			is_doctor: employee_update?.user_details?.is_doctor ? employee_update?.user_details?.is_doctor : checkDoctor,
			is_patient: employee_update?.user_details?.is_patient ? employee_update?.user_details?.is_patient : checkPatient,
			is_pharmacist: employee_update?.user_details?.is_pharmacist ? employee_update?.user_details?.is_pharmacist : checkPharmacist,
			is_records: employee_update?.user_details?.is_records ? employee_update?.user_details?.is_records : checkRecords,
			is_nurse: employee_update?.user_details?.is_nurse ? employee_update?.user_details?.is_nurse : checkNurse,
			is_accountant: employee_update?.user_details?.is_accountant ? employee_update?.user_details?.is_accountant : checkAccountant,
			is_collections: employee_update?.user_details?.is_collections ? employee_update?.user_details?.is_collections : checkCollections,
			is_secretory: employee_update?.user_details?.is_secretory ? employee_update?.user_details?.is_secretory : checkSecretory,
			is_audit: employee_update?.user_details?.is_audit ? employee_update?.user_details?.is_audit : checkAudit,
			is_dentist: employee_update?.user_details?.is_dentist ? employee_update?.user_details?.is_dentist : checkDentist,
			is_ophthalmologists: employee_update?.user_details?.is_ophthalmologists ? employee_update?.user_details?.is_ophthalmologists : checkOpthamologist,
			is_otolaryngologists: employee_update?.user_details?.is_otolaryngologists ? employee_update?.user_details?.is_otolaryngologists : checkOtolaryngologists,
			is_mid_wife: employee_update?.user_details?.is_mid_wife ? employee_update?.user_details?.is_mid_wife : checkMidwife,
			is_physiotherapists: employee_update?.user_details?.is_physiotherapists ? employee_update?.user_details?.is_physiotherapists : checkPhysiotherapist,
			is_department_head: employee_update?.user_details?.is_department_head ? employee_update?.user_details?.is_department_head : checkIsDepartmentHead,
		})

	}, [employee_update])

	useEffect(() => {
		// check if departmental head exists in the department
		// if (checkAccountant) {
		// 	const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_accountant === true)
		// 	if (head.length > 0) {
		// 		console.log('ACCOUNT HEAD EXISTS')
		// 		setConfig({ ...config, showHeadButton: false })
		// 	}
		// }
		if (checkDoctor) {
			const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_doctor === true)
			if (head.length > 0) {
				// console.log('DOCTOR HEAD EXISTS')
				setConfig({ ...config, showHeadButton: false })
			}
		}
	}, [checkIsDepartmentHead, checkAccountant, checkActive, checkAudit, checkCollections, checkDentist, checkDoctor, checkLaboratorist, checkMidwife, checkNurse, checkOpthamologist, checkOtolaryngologists, checkPatient, checkPharmacist, checkPhysiotherapist, checkRecords, checkRadiologist, checkSecretory])

	const handleChange = (e, name) => {
		setFormData((prevState) => ({ ...prevState, [name]: e.target.value }));
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(formData)

		if (!formData.first_name || !formData.last_name || !formData.email || !formData.directorate || !formData.department || !formData.specialization || !formData.designation) return toast.warning("All fields are required")
		dispatch(createEmployee({ formData, toast, setModals: setModals, modals: modals }));
	}

	const handleUpdate = (e) => {
		e.preventDefault();

		dispatch(updateEmployee({ formData: { ...formData, id: employee_update?.user_details.id }, toast, setModals: setModals, modals: modals }));
	}

	return (
		<div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
			<div className="bg-white rounded px-[30px] py-[20px]">
				<div className="flex flex-col space-y-5">
					<div className="flex justify-between items-center">
						<h4 className="text-dark-green text-[18px] font-medium">
							{modals.showAddEmployeeModal ? 'Add Employee' : 'Update Employee'}
						</h4>
						<FaTimesCircle
							className="text-dark-yellow cursor-pointer"
							onClick={() => {
								dispatch(setEmployee({ data: null }))

								modals.showAddEmployeeModal && setModals({ ...modals, showAddEmployeeModal: !modals.showAddEmployeeModal })
								modals.showUpdateEmployeeModal && setModals({ ...modals, showUpdateEmployeeModal: !modals.showUpdateEmployeeModal })
							}}
							size={20}
						/>
					</div>

					{modals.showAddEmployeeModal && (
						<form onSubmit={handleSubmit} className="space-y-3">
							<div className="flex justify-between-items-center space-x-10">
								<div className="space-y-2">
									<div className="relative">
										<select
											className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none "
											name="directorate"
											onChange={(e) => handleChange(e, e.target.name)}
										>
											<option value="">Select directorate</option>
											{directorates != null &&
												directorates.map((e) => (
													<option value={e.directorate_name} key={e.pkid}>
														{e.directorate_name}
													</option>
												))}
										</select>
										<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
											<svg
												className="fill-current h-4 w-4"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
											>
												<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
											</svg>
										</div>
									</div>

									<div>
										<input
											type="text"
											name="first_name"
											placeholder="First name"
											className="border rounded w-full h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
											onChange={(e) => handleChange(e, e.target.name)}
										/>
									</div>

									<div>
										<input
											type="text"
											name="last_name"
											placeholder="Last name"
											className="border rounded w-full h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
											onChange={(e) => handleChange(e, e.target.name)}
										/>
									</div>

									<div>
										<input
											type="email"
											name="email"
											placeholder="Email address"
											className="border rounded w-full h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
											onChange={(e) => handleChange(e, e.target.name)}
										/>
									</div>

									{/* <div>
										<input
											type="password"
											name="password"
											placeholder="Generate Password"
											className="border rounded w-full h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
											onChange={(e) => handleChange(e, e.target.name)}
										/>
									</div>

									<div>
										<input
											type="password"
											name="confirm_password"
											placeholder="Confirm Password"
											className="border rounded w-full h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
											onChange={(e) => handleChange(e, e.target.name)}
										/>
									</div> */}
								</div>

								<div className="space-y-2">
									<div className="relative">
										<select
											className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none "
											name="department"
											onChange={(e) => handleChange(e, e.target.name)}
										>
											<option value="">Select department</option>
											{departments != null &&
												departments.map((e) => (
													<option value={e.department_name} key={e.pkid}>
														{e.department_name}
													</option>
												))}
										</select>
										<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
											<svg
												className="fill-current h-4 w-4"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
											>
												<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
											</svg>
										</div>
									</div>

									<div>
										<input
											type="text"
											name="specialization"
											placeholder="Specialization"
											className="border rounded w-full h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
											onChange={(e) => handleChange(e, e.target.name)}
										/>
									</div>

									<div>
										<input
											type="text"
											name="designation"
											placeholder="Designation"
											className="border rounded w-full h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
											onChange={(e) => handleChange(e, e.target.name)}
										/>
									</div>
								</div>
							</div>

							<div className="flex">
								<div className="flex-[0.3] space-y-3">
									{/* <div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_active"
											checked={checkActive}
											onChange={() => {
												setCheckActive(!checkActive);
												setFormData({ ...formData, is_active: !checkActive });
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">Status</p>
									</div> */}

									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_radiologist"
											checked={checkDentist}
											onChange={() => {
												setCheckDentist(!checkDentist);
												setFormData({
													...formData,
													is_dentist: !checkDentist,
												});

												if (!checkDentist) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_dentist === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">Dentist</p>
									</div>

									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_laboratorist"
											checked={checkLaboratorist}
											onChange={() => {
												setCheckLaboratorist(!checkLaboratorist);
												setFormData({
													...formData,
													is_laboratorist: !checkLaboratorist,
												});

												if (!checkLaboratorist) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_laboratorist === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">
											Laboratorist
										</p>
									</div>

									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_nurse"
											checked={checkNurse}
											onChange={() => {
												setCheckNurse(!checkNurse);
												setFormData({ ...formData, is_nurse: !checkNurse })

												if (!checkNurse) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_nurse === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">Nurse</p>
									</div>

									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_pharmacist"
											checked={checkPharmacist}
											onChange={() => {
												setCheckPharmacist(!checkPharmacist);
												setFormData({
													...formData,
													is_pharmacist: !checkPharmacist,
												})

												if (!checkPharmacist) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_pharmacist === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">
											Pharmacist
										</p>
									</div>

									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_accountant"
											checked={checkAccountant}
											onChange={() => {
												setCheckAccountant(!checkAccountant)
												setFormData({
													...formData,
													is_accountant: !checkAccountant,
												});

												if (!checkAccountant) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_accountant === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">
											Accountant
										</p>
									</div>
								</div>

								<div className="flex-[0.3] space-y-3">
									{/* <div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_audit"
											checked={checkAudit}
											onChange={() => {
												setCheckAudit(!checkAudit);
												setFormData({ ...formData, is_audit: !checkAudit });

												if (!checkAudit) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_audit === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">Audit</p>
									</div> */}
									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_doctor"
											checked={checkDoctor}
											onChange={() => {
												setCheckDoctor(!checkDoctor);
												setFormData({ ...formData, is_doctor: !checkDoctor })

												if (!checkDoctor) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_doctor === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">Doctor</p>
									</div>

									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_records"
											checked={checkRecords}
											onChange={() => {
												setcheckRecords(!checkRecords);
												setFormData({ ...formData, is_records: !checkRecords })

												if (!checkRecords) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_records === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">Records</p>
									</div>

									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_radiologist"
											checked={checkRadiologist}
											onChange={() => {
												setCheckRadiologist(!checkRadiologist);
												setFormData({
													...formData,
													is_radiologist: !checkRadiologist,
												})

												if (!checkRadiologist) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_radiologist === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">
											Radiologist
										</p>
									</div>

									<div className="flex items-center space-x-3">
									<input
										className="rounded border-4 w-5 h-5 border-red-400"
										type="checkbox"
										name="is_administrator"
										checked={checkAdministrator}
										onChange={() => {
											setCheckAdministrator(!checkAdministrator);
											setFormData({
												...formData,
												is_administrator: !checkAdministrator,
											})

											if (!checkAdministrator) setConfig({ ...config, showHeadButton: true })

											const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_administrator === true)
											if (head.length > 0) {
												console.log('ACCOUNT HEAD EXISTS')
												setConfig({ ...config, showHeadButton: false })
											} else {
												setConfig({ ...config, showHeadButton: true })
											}
										}}
									/>
									<p className="text-[12px] text-gray-700 font-bold">
										Adminstrator
									</p>
								</div>

									{hospital_config?.hospital_size !== 'Small' && (
										<div className="flex items-center space-x-3">
											<input
												className="rounded border-4 w-5 h-5 border-red-400"
												type="checkbox"
												name="is_collections"
												checked={checkCollections}
												onChange={() => {
													setCheckCollections(!checkCollections);
													setFormData({
														...formData,
														is_collections: !checkCollections,
													})

													if (!checkCollections) setConfig({ ...config, showHeadButton: true })

													const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_collections === true)
													if (head.length > 0) {
														console.log('ACCOUNT HEAD EXISTS')
														setConfig({ ...config, showHeadButton: false })
													} else {
														setConfig({ ...config, showHeadButton: true })
													}
												}}
											/>
											<p className="text-[12px] text-gray-700 font-bold">
												Collections
											</p>
										</div>
									)}

									{/* <div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_radiologist"
											checked={checkSecretory}
											onChange={() => {
												setCheckSecretory(!checkSecretory);
												setFormData({
													...formData,
													is_secretory: !checkSecretory,
												})

												if (!checkSecretory) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_secretory === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">
											Secretary
										</p>
									</div> */}
								</div>

								<div className="flex-[0.3] space-y-3">
									{/* <div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_patient"
											checked={checkPatient}
											onChange={() => {
												setCheckPatient(!checkPatient);
												setFormData({ ...formData, is_patient: !checkPatient });
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">Patient</p>
									</div> */}

									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_ophthalmologists"
											checked={checkOpthamologist}
											onChange={() => {
												setCheckOpthamologist(!checkOpthamologist);
												setFormData({
													...formData,
													is_ophthalmologists: !checkOpthamologist,
												})

												if (!checkOpthamologist) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_ophthalmologists === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">
											Opthalmologist
										</p>
									</div>

									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_radiologist"
											checked={checkOtolaryngologists}
											onChange={() => {
												setcheckotolaryngologists(!checkOtolaryngologists);
												setFormData({
													...formData,
													is_otolaryngologists: !checkOtolaryngologists,
												})

												if (!checkOtolaryngologists) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_otolaryngologists === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">
											Otolaryngologists
										</p>
									</div>

									{/* <div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_mid_wife"
											checked={checkMidwife}
											onChange={() => {
												setCheckMidwife(!checkMidwife);
												setFormData({
													...formData,
													is_mid_wife: !checkMidwife,
												})

												if (!checkMidwife) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_mid_wife === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">
											Mid-Wife
										</p>
									</div> */}

									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_physiotherapists"
											checked={checkPhysiotherapist}
											onChange={() => {
												setCheckPhysiotherapist(!checkPhysiotherapist);
												setFormData({
													...formData,
													is_physiotherapists: !checkPhysiotherapist,
												})

												if (!checkPhysiotherapist) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_physiotherapists === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">
											Physiotherapist
										</p>
									</div>

									{config.showHeadButton && (
										<div className="flex gap-[55px]">
											<div className="flex items-center space-x-3">
												<input
													className="rounded border-4 w-5 h-5 border-red-400"
													type="checkbox"
													name="is_department_head"
													checked={checkIsDepartmentHead}
													onChange={() => {
														setCheckIsDepartmentHead(!checkIsDepartmentHead);
														setFormData({
															...formData,
															is_department_head: !checkIsDepartmentHead,
														});
													}}
												/>
												<p className="text-[12px] text-gray-700 font-bold">
													Department Head
												</p>
											</div>
										</div>
									)}
								</div>
							</div>

							<div className="mt-4 flex justify-center">
								<button
									type="submit"
									className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
								>
									{!adminLoading ? (
										'Submit'
									) : (
										<ButtonLoader />
									)}
								</button>
							</div>
						</form>
					)}

					{modals.showUpdateEmployeeModal && (
						<form onSubmit={handleUpdate} className="space-y-3">
							<div className="flex justify-between-items-center space-x-10">
								<div className="space-y-2">
									<div className="relative">
										<select
											className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none "
											name="directorate"
											onChange={(e) => handleChange(e, e.target.name)}
										>
											<option>{formData.directorate}</option>
											<option value="">Select directorate</option>
											{directorates != null &&
												directorates.map((e) => (
													<option value={e.directorate_name} key={e.pkid}>
														{e.directorate_name}
													</option>
												))}
										</select>
										<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
											<svg
												className="fill-current h-4 w-4"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
											>
												<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
											</svg>
										</div>
									</div>

									<div>
										<input
											type="text"
											name="first_name"
											placeholder="First name"
											className="border rounded w-full h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
											value={formData.first_name}
											onChange={(e) => handleChange(e, e.target.name)}
										/>
									</div>

									<div>
										<input
											type="text"
											name="last_name"
											placeholder="Last name"
											className="border rounded w-full h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
											value={formData.last_name}
											onChange={(e) => handleChange(e, e.target.name)}
										/>
									</div>

									<div>
										<input
											type="email"
											name="email"
											placeholder="Email address"
											className="border rounded w-full h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
											value={formData.email}
											onChange={(e) => handleChange(e, e.target.name)}
										/>
									</div>
								</div>

								<div className="space-y-2">
									<div className="relative">
										<select
											className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none "
											name="department"
											onChange={(e) => handleChange(e, e.target.name)}
										>
											<option>{formData.department}</option>
											{departments != null &&
												departments.map((e) => (
													<option value={e.department_name} key={e.pkid}>
														{e.department_name}
													</option>
												))}
										</select>
										<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
											<svg
												className="fill-current h-4 w-4"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
											>
												<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
											</svg>
										</div>
									</div>

									<div>
										<input
											type="text"
											name="specialization"
											placeholder="Specialization"
											className="border rounded w-full h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
											value={formData.specialization}
											onChange={(e) => handleChange(e, e.target.name)}
										/>
									</div>

									<div>
										<input
											type="text"
											name="designation"
											placeholder="Designation"
											className="border rounded w-full h-5 px-4 py-4 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
											value={formData.designation}
											onChange={(e) => handleChange(e, e.target.name)}
										/>
									</div>
								</div>
							</div>

							<div className="flex">
								<div className="flex-[0.3] space-y-3">
									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_active"
											checked={formData.is_active}
											onChange={() => {
												setCheckActive(!checkActive);
												setFormData({ ...formData, is_active: !checkActive });
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">Status</p>
									</div>

									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_radiologist"
											checked={formData.is_dentist}
											onChange={() => {
												setCheckDentist(!checkDentist);
												setFormData({
													...formData,
													is_dentist: !checkDentist,
												})

												if (!checkDentist) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_dentist === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">Dentist</p>
									</div>

									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_laboratorist"
											checked={formData.is_laboratorist}
											onChange={() => {
												setCheckLaboratorist(!checkLaboratorist);
												setFormData({
													...formData,
													is_laboratorist: !checkLaboratorist,
												})

												if (!checkLaboratorist) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_laboratorist === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">
											Laboratorist
										</p>
									</div>

									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_nurse"
											checked={formData.is_nurse}
											onChange={() => {
												setCheckNurse(!checkNurse);
												setFormData({ ...formData, is_nurse: !checkNurse });

												if (!checkNurse) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_nurse === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">Nurse</p>
									</div>

									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_pharmacist"
											checked={formData.is_pharmacist}
											onChange={() => {
												setCheckPharmacist(!checkPharmacist);
												setFormData({
													...formData,
													is_pharmacist: !checkPharmacist,
												});

												if (!checkPharmacist) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_pharmacist === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">
											Pharmacist
										</p>
									</div>
								</div>

								<div className="flex-[0.3] space-y-3">
									{/* <div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_audit"
											checked={formData.is_audit}
											onChange={() => {
												setCheckAudit(!checkAudit);
												setFormData({ ...formData, is_audit: !checkAudit });

												if (!checkAudit) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_audit === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">Audit</p>
									</div> */}
									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_doctor"
											checked={formData.is_doctor}
											onChange={() => {
												setCheckDoctor(!checkDoctor);
												setFormData({ ...formData, is_doctor: !checkDoctor });

												if (!checkDoctor) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_doctor === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">Doctor</p>
									</div>

									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_records"
											checked={formData.is_records}
											onChange={() => {
												setcheckRecords(!checkRecords);
												setFormData({ ...formData, is_records: !checkRecords });

												if (!checkRecords) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_doctor === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">Records</p>
									</div>

									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_radiologist"
											checked={formData.is_radiologist}
											onChange={() => {
												setCheckRadiologist(!checkRadiologist);
												setFormData({
													...formData,
													is_radiologist: !checkRadiologist,
												});

												if (!checkRadiologist) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_radiologist === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">
											Radiologist
										</p>
									</div>

									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_administrator"
											checked={formData.is_administrator}
											onChange={() => {
												setCheckDentist(!checkAdministrator);
												setFormData({
													...formData,
													is_administrator: !checkAdministrator,
												})

												if (!checkAdministrator) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_administrator === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">Adminstrator</p>
									</div>

									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_accountant"
											checked={formData.is_accountant}
											onChange={() => {
												setCheckAccountant(!checkAccountant);
												setFormData({
													...formData,
													is_accountant: !checkAccountant,
												})

												if (!checkAccountant) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_accountant === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">
											Accountant
										</p>
									</div>

									{hospital_config?.hospital_size !== 'Small' && (
										<div className="flex items-center space-x-3">
											<input
												className="rounded border-4 w-5 h-5 border-red-400"
												type="checkbox"
												name="is_collections"
												checked={formData.is_collections}
												onChange={() => {
													setCheckCollections(!checkCollections);
													setFormData({
														...formData,
														is_collections: !checkCollections,
													})

													if (!checkCollections) setConfig({ ...config, showHeadButton: true })

													const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_collections === true)
													if (head.length > 0) {
														console.log('ACCOUNT HEAD EXISTS')
														setConfig({ ...config, showHeadButton: false })
													} else {
														setConfig({ ...config, showHeadButton: true })
													}
												}}
											/>
											<p className="text-[12px] text-gray-700 font-bold">
												Collections
											</p>
										</div>
									)}

									{/* <div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_secretory"
											checked={formData.is_secretory}
											onChange={() => {
												setCheckSecretory(!checkSecretory);
												setFormData({
													...formData,
													is_secretory: !checkSecretory,
												});

												if (!checkSecretory) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_secretory === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">
											Secretary
										</p>
									</div> */}
								</div>

								<div className="flex-[0.3] space-y-3">
									{/* <div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_patient"
											checked={formData.is_patient}
											onChange={() => {
												setCheckPatient(!checkPatient);
												setFormData({ ...formData, is_patient: !checkPatient });
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">Patient</p>
									</div> */}

									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_ophthalmologists"
											checked={formData.is_ophthalmologists}
											onChange={() => {
												setCheckOpthamologist(!checkOpthamologist);
												setFormData({
													...formData,
													is_ophthalmologists: !checkOpthamologist,
												});

												if (!checkOpthamologist) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_ophthalmologists === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">
											Opthalmologist
										</p>
									</div>

									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_otolaryngologists"
											checked={formData.is_otolaryngologists}
											onChange={() => {
												setcheckotolaryngologists(!checkOtolaryngologists);
												setFormData({
													...formData,
													is_otolaryngologists: !checkOtolaryngologists,
												})

												if (!checkOtolaryngologists) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_otolaryngologists === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">
											Otolaryngologists
										</p>
									</div>

									{/* <div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_mid_wife"
											checked={formData.is_mid_wife}
											onChange={() => {
												setCheckMidwife(!checkMidwife);
												setFormData({
													...formData,
													is_mid_wife: !checkMidwife,
												})

												if (!checkMidwife) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_mid_wife === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">
											Mid-Wife
										</p>
									</div> */}

									<div className="flex items-center space-x-3">
										<input
											className="rounded border-4 w-5 h-5 border-red-400"
											type="checkbox"
											name="is_physiotherapists"
											checked={formData.is_physiotherapists}
											onChange={() => {
												setCheckPhysiotherapist(!checkPhysiotherapist);
												setFormData({
													...formData,
													is_physiotherapists: !checkPhysiotherapist,
												})

												if (!checkPhysiotherapist) setConfig({ ...config, showHeadButton: true })

												const head = employees?.filter((employee) => employee?.user_details?.is_department_head === true && employee?.user_details?.is_physiotherapists === true)
												if (head.length > 0) {
													console.log('ACCOUNT HEAD EXISTS')
													setConfig({ ...config, showHeadButton: false })
												} else {
													setConfig({ ...config, showHeadButton: true })
												}
											}}
										/>
										<p className="text-[12px] text-gray-700 font-bold">
											Physiotherapist
										</p>
									</div>

									{config.showHeadButton && (
										<div className="flex gap-[55px]">
											<div className="flex items-center space-x-3">
												<input
													className="rounded border-4 w-5 h-5 border-red-400"
													type="checkbox"
													name="is_department_head"
													checked={formData.is_department_head}
													onChange={() => {
														setCheckIsDepartmentHead(!checkIsDepartmentHead);
														setFormData({
															...formData,
															is_department_head: !checkIsDepartmentHead,
														})
													}}
												/>
												<p className="text-[12px] text-gray-700 font-bold">
													Department Head
												</p>
											</div>
										</div>
									)}
								</div>
							</div>

							<div className="mt-4 flex justify-center">
								<button
									type="submit"
									className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
								>
									{!adminLoading ? (
										'Submit'
									) : (
										<ButtonLoader />
									)}
								</button>
							</div>
						</form>
					)}
				</div>
			</div>
		</div>
	);
};

export default CreateEmployeeModal;
