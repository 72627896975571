import { Fragment, useState } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { setSupplyPoint } from '../../../state/actions/actions'
import { deleteSupplyPoint } from '../../../state/actions/pharmacist.actions'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { createPatientAppointment, updatePatientAppointment } from '../../../state/actions/doctor.actions'
import { useEffect } from 'react'


const DoctorBookAppointmentModal = () => {
    const dispatch = useDispatch();

    const { modals, setModals } = useStateContext()

    const { data } = useSelector(state => state.auth.user)
    const { appointment_update, patient_update } = useSelector(state => state.root)

    const [formData, setFormData] = useState({ date: '', time: '' })

    useEffect(() => {
        setFormData({
            ...formData,
            patient: patient_update?.pkid,
            doctor: data?.pkid
        })
    }, [patient_update, data])

    useEffect(() => {
        setFormData({
            ...formData,
            date: appointment_update?.date,
            old_date: appointment_update?.date,
            time: appointment_update?.time,
            patient: patient_update?.pkid,
            doctor: data?.pkid
        })
    }, [appointment_update, patient_update, data])

    const handleChange = (e, name) => {
        setFormData({ ...formData, [name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        console.log(formData)
        dispatch(createPatientAppointment({ formData, toast, setModals, modals, dispatch, setSupplyPoint }));
    }

    const handleUpdate = (e) => {
        e.preventDefault()

        console.log(formData)
        dispatch(updatePatientAppointment({ formData, toast, setModals, modals, dispatch, setSupplyPoint }));
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[500px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className={`${modals.showDoctorBookAppointmentModal ? 'text-dark-green' : 'text-sky-500'} text-[20px] font-bold`}>
                            {modals.showDoctorBookAppointmentModal ? 'Book Appointment' : 'Update Appointment'}
                        </h4>
                        <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                // dispatch(setSupplyPoint({ data: null }))
                                modals.showDoctorBookAppointmentModal && setModals({ ...modals, showDoctorBookAppointmentModal: !modals.showDoctorBookAppointmentModal })
                                modals.showDoctorUpdateAppointmentModal && setModals({ ...modals, showDoctorUpdateAppointmentModal: !modals.showDoctorUpdateAppointmentModal })
                            }}
                            size={20}
                        />
                    </div>

                    <div className="flex items-center space-x-5">
                        <img
                            src={`${process.env.REACT_APP_API_ROOT}${patient_update?.profile_photo}`}
                            alt=""
                            className="w-[80px] h-[80px] rounded-[50%]"
                        />

                        <div className="flex flex-col space-y-1">
                            <p className="text-[14px] text-gray-600 font-bold">{patient_update?.details?.last_name} {patient_update?.details?.first_name}</p>
                            <p className="text-[12px] text-gray-300 font-medium">{patient_update?.patient_number}</p>
                        </div>
                    </div>

                    {modals.showDoctorBookAppointmentModal && (
                        <form onSubmit={handleSubmit}>
                            <div className="space-y-2">
                                <div>
                                    <input
                                        type="date"
                                        name="date"
                                        min={new Date().toISOString().split('T')[0]}
                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div>
                                    <input
                                        type="time"
                                        name="time"
                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                            </div>

                            <div className='mt-4'>
                                <button
                                    type='submit'
                                    className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                >Submit</button>
                            </div>
                        </form>
                    )}

                    {modals.showDoctorUpdateAppointmentModal && (
                        <form onSubmit={handleUpdate}>
                            <div className="space-y-2">
                                <div>
                                    <input
                                        type="date"
                                        name="date"
                                        min={new Date().toISOString().split('T')[0]}
                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        value={formData.date}
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div>
                                    <input
                                        type="time"
                                        name="time"
                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        value={formData.time}
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                            </div>

                            <div className='mt-4'>
                                <button
                                    type='submit'
                                    className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                >Update</button>
                            </div>
                        </form>
                    )}

                </div>
            </div>
        </div >
    )
}
export default DoctorBookAppointmentModal
