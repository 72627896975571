import { useState } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useStateContext } from '../../../state/context'
import { createPatient } from '../../../state/actions/records.actions'
import { countries } from '../../../data'
import { toast } from 'react-toastify'
import ButtonLoader from '../../utils/ButtonLoader'

const RecordsAddPatientModal = (props) => {
	const { modals, setModals } = useStateContext()
	const { doctors, patientLoading } = useSelector((state) => state.records)

	const dispatch = useDispatch()

	const [formData, setFormData] = useState({
		first_name: '',
		last_name: '',
		password: 'xnturteywe',
		email: '',
		nhis_number: '',
		date_of_birth: '',
		marital_status: '',
		blood_group: '',
		genotype: '',
		country: '',
		state: '',
		lga_or_province: '',
		relationship_with_next_of_kin: '',
		patient_phone: '',
		address: '',
		next_kin_name: '',
		next_kin_phone_number: '',
		next_kin_address: '',
		guardian_nhis: '',
		gender: '',
		is_active: false,
		is_patient: true,
	})
	const handleChange = (e, name) => {
		setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
	}
	const closeModal = () => {
		setModals({
			...modals,
			showRecordsAddPatientModal: !modals.showRecordsAddPatientModal,
		})
	}
	const handleSubmit = (e) => {
		e.preventDefault()

		if (!formData.first_name) return toast.warning('First Name is required!')
		if (!formData.last_name) return toast.warning('Last Name is required!')
		if (!formData.email) return toast.warning('Email is required!')
		if (!formData.date_of_birth) return toast.warning('Date of Birth is required!')
		if (!formData.blood_group) return toast.warning('Blood group is required!')
		if (!formData.genotype) return toast.warning('Genotype is required!')
		if (!formData.patient_phone) return toast.warning('Phone number is required!')
		if (!formData.next_kin_address) return toast.warning('Next of Kin Address is required!')
		if (!formData.next_kin_phone_number) return toast.warning('Next of Kin Phone number is required!')
		if (!formData.relationship_with_next_of_kin) return toast.warning('Next of Kin relationship is required!')
		if (!formData.next_kin_name) return toast.warning('Next of Kin Name is required!')
		if (!formData.address) return toast.warning('Address is required!')
		if (!formData.gender) return toast.warning('Gender field is required!')
		if (!formData.lga_or_province) return toast.warning('Local government or province is required!')
		dispatch(createPatient({ formData, toast, setModals, modals }))


	}
	return (
		<div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
			<div className='bg-white w-[800px] rounded px-8 py-[10px]'>
				<div className='flex flex-col space-y- w-full'>
					<div className='flex justify-between items-center'>
						<h4 className='text-dark-green text-[20px] font-bold'>
							Add Patient
						</h4>
						<FaTimesCircle
							className='text-dark-yellow cursor-pointer'
							onClick={() =>
								setModals({
									...modals,
									showRecordsAddPatientModal:
										!modals.showRecordsAddPatientModal,
								})
							}
							size={20}
						/>
					</div>
				</div>

				<form onSubmit={handleSubmit} className='bg-white rounded'>
					<div className='flex justify-between w-full'>
						<div className='flex flex-col w-[350px]'>
							<div>
								<input
									type='text'
									name='first_name'
									placeholder='First name'
									className='border rounded w-full px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue'
									onChange={(e) =>
										handleChange(e, e.target.name)
									}
								/>
							</div>
							<div>
								<input
									type='text'
									name='last_name'
									placeholder='Last name'
									className='border rounded w-full px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue'
									onChange={(e) =>
										handleChange(e, e.target.name)
									}
								/>
							</div>
							<div>
								<input
									type='text'
									name='email'
									placeholder='Email'
									className='border rounded w-full px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue'
									onChange={(e) =>
										handleChange(e, e.target.name)
									}
								/>
							</div>
							<div>
								<input
									type='text'
									name='patient_phone'
									placeholder='Phone'
									className='border rounded w-full px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue'
									onChange={(e) =>
										handleChange(e, e.target.name)
									}
								/>
							</div>
							<div>
								<input
									type='text'
									name='occupation'
									placeholder='Occupation'
									className='border rounded w-full px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue'
									onChange={(e) =>
										handleChange(e, e.target.name)
									}
								/>
							</div>
							{/* <p className='text-sm font-semibold py-2'>Country</p> */}

							<div>
								<input
									type='text'
									name='state'
									placeholder='State'
									className='border rounded w-full px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue'
									onChange={(e) =>
										handleChange(e, e.target.name)
									}
								/>
							</div>

							<div>
								<input
									type='text'
									name='lga_or_province'
									placeholder='LGA or province'
									className='border rounded w-full px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue'
									onChange={(e) =>
										handleChange(e, e.target.name)
									}
								/>
							</div>

							<div>
								<input
									type='text'
									name='nhis_number'
									placeholder='NHIS ID'
									className='border rounded w-full px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue'
									onChange={(e) =>
										handleChange(e, e.target.name)
									}
								/>
							</div>

							<div>
								<h5 className='mt-2 font-semibold'>
									Date of Birth
								</h5>
								<input
									type='date'
									name='date_of_birth'
									className='border rounded w-full px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue'
									onChange={(e) =>
										handleChange(e, e.target.name)
									}
								/>
							</div>
							<div>
								<div>
									<input
										type='text'
										name='next_kin_address'
										placeholder='Next of kin address'
										className='border rounded w-full px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue'
										onChange={(e) =>
											handleChange(e, e.target.name)
										}
									/>
								</div>
								<div className='flex space-x-8 mt-4'>
									<div className='flex justify-between items-center'>
										<div className='flex justify-between items-center space-x-2'>
											<input
												type='radio'
												name='gender'
												className='w-[50px]'
												value='Male'
												onChange={(e) =>
													handleChange(e, e.target.name)
												}
											/>
											<p className='text-sm'>Male</p>
										</div>
										<div className='flex justify-between items-center space-x-2'>
											<input
												type='radio'
												name='gender'
												value='Female'
												className='w-[50px]'
												onChange={(e) =>
													handleChange(e, e.target.name)
												}
											/>
											<p className='text-sm'>Female</p>
										</div>
									</div>

								</div>
							</div>
						</div>

						<div className='flex flex-col w-[350px]'>
							<div className='flex flex-col mt-2 space-y-1'>
								<div className="relative">
									<select
										className="appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state"
										name='country'
										onChange={(e) => handleChange(e, e.target.name)}
									>
										<option>Country</option>
										{countries.map((country, index) => (
											<option
												key={index}
												value={country.title}
											>
												{country.title}
											</option>
										))}
									</select>
									<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
										<svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
									</div>
								</div>


								<div className='relative'>
									<select
										className='appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none '
										name='marital_status'
										onChange={(e) =>
											handleChange(e, e.target.name)
										}
									>
										<option value=''>
											Martital Status
										</option>
										<option value='Single'>Single</option>
										<option value='Married'>Married</option>
										<option value='Divorced'>
											Divorced
										</option>
										<option value='Widow'>Widow</option>
										<option value='Widower'>Widower</option>
									</select>
									<div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
										<svg
											className='fill-current h-4 w-4'
											xmlns='http://www.w3.org/2000/svg'
											viewBox='0 0 20 20'
										>
											<path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
										</svg>
									</div>
								</div>
								<div className='relative'>
									<select
										className='appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none '
										name='blood_group'
										onChange={(e) =>
											handleChange(e, e.target.name)
										}
									>
										<option value=''>Blood Group</option>
										<option value='A+'>A+</option>
										<option value='A-'>A-</option>
										<option value='AB-'>AB-</option>
										<option value='AB+'>AB</option>
										<option value='B+'>B+</option>
										<option value='B-'>B-</option>
										<option value='O+'>O+</option>
										<option value='O-'>O-</option>
									</select>
									<div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
										<svg
											className='fill-current h-4 w-4'
											xmlns='http://www.w3.org/2000/svg'
											viewBox='0 0 20 20'
										>
											<path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
										</svg>
									</div>
								</div>

								<div className='relative'>
									<select
										className='appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none '
										name='genotype'
										onChange={(e) =>
											handleChange(e, e.target.name)
										}
									>
										<option value=''>Genotype</option>
										<option value='AA'>AA</option>
										<option value='AS'>AS</option>
										<option value='AC'>AC</option>
										<option value='SS'>SS</option>
									</select>
									<div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
										<svg
											className='fill-current h-4 w-4'
											xmlns='http://www.w3.org/2000/svg'
											viewBox='0 0 20 20'
										>
											<path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
										</svg>
									</div>
								</div>

								<div className='relative'>
									<select
										className='appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none '
										name='patient_doctor'
										onChange={(e) =>
											handleChange(e, e.target.name)
										}
									>
										<option value=''>Assign Doctor</option>
										{doctors?.map((doctor, index) => (
											<option
												value={doctor.pkid}
												key={index}
											>
												{doctor.first_name}{' '}
												{doctor.last_name}
											</option>
										))}
									</select>
									<div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
										<svg
											className='fill-current h-4 w-4'
											xmlns='http://www.w3.org/2000/svg'
											viewBox='0 0 20 20'
										>
											<path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
										</svg>
									</div>
								</div>
							</div>
							<div>
								<input
									type='text'
									name='address'
									placeholder='Address'
									className='border rounded w-full px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue'
									onChange={(e) =>
										handleChange(e, e.target.name)
									}
								/>
							</div>

							<div>
								<input
									type='text'
									name='next_kin_name'
									placeholder='Next of kin'
									className='border rounded w-full px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue'
									onChange={(e) =>
										handleChange(e, e.target.name)
									}
								/>
							</div>

							<div>
								<input
									type='text'
									name='guardian_nhis'
									placeholder='GUARDIAN NHIS'
									className='border rounded w-full px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue'
									onChange={(e) =>
										handleChange(e, e.target.name)
									}
								/>
							</div>

							<div>
								<input
									type='text'
									name='next_kin_phone_number'
									placeholder='Next of kin phone'
									className='border rounded w-full px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue'
									onChange={(e) =>
										handleChange(e, e.target.name)
									}
								/>
							</div>
							<div>
								<input
									type='text'
									name='relationship_with_next_of_kin'
									placeholder='Next of kin relationship'
									className='border rounded w-full px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue'
									onChange={(e) =>
										handleChange(e, e.target.name)
									}
								/>
							</div>
						</div>
					</div>

					<div className='pb-2 flex justify-start mt-4'>
						<button
							type='submit'
							className='inline-block w-[200px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
						>
							{!patientLoading ? (
								'Create'
							) : (
								<ButtonLoader />
							)}
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default RecordsAddPatientModal
