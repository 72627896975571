import React from 'react'
import { toast } from 'react-toastify'
import { FaTimesCircle } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { setLaboratoryEquipment } from '../../../state/actions/actions'
import { deleteALaboratoryEquipment } from '../../../state/actions/laboratorist.actions'


const DeleteLaboratoryEquipmentModal = () => {
    const dispatch = useDispatch()
    const { modals, setModals } = useStateContext()
    const { set_laboratory_equipment, } = useSelector((state) => state.root)

    const handleSubmit = (e) => {
        const equipment_id = set_laboratory_equipment?.id
        const type = set_laboratory_equipment?.type
        console.log(equipment_id)
        if (modals.showDeleteLaboratoryEquipmentModal) {
            dispatch(deleteALaboratoryEquipment({ equipment_id, type, toast, setModals: setModals, modals: modals }))
            // dispatch(setLaboratoryEquipment({ data: null }))
        }
        // if (modals.showDeleteRadiologyCategoryTestModal) {
        //     const cat_id = set_delete_radiology_cat?.cat_id
        //     dispatch(deleteARadiologyCategory({ cat_id, type, toast, setModals: setModals, modals: modals }))
        //     dispatch(setDeleteRadiologyCategory({ data: null }))
        // }

    }

    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>

                            <>
                                {modals.showDeleteLaboratoryEquipmentModal && (
                                    <h4 className='text-dark-green text-[20px] font-bold'>
                                        Are you sure you want to delete - "{set_laboratory_equipment?.title}"
                                    </h4>
                                )}
                                {/* {modals.showDeleteRadiologyCategoryTestModal && (
                                    <h4 className='text-dark-green text-[20px] font-bold'>
                                        Are you sure you want to delete - "{set_delete_radiology_cat?.cat_name}"
                                    </h4>
                                )} */}


                            </>


                            <FaTimesCircle
                                className='text-dark-yellow cursor-pointer'
                                onClick={() => {
                                    if (modals.showDeleteLaboratoryEquipmentModal) {
                                        dispatch(setLaboratoryEquipment({ data: null }))
                                        setModals({
                                            ...modals,
                                            showDeleteLaboratoryEquipmentModal:
                                                !modals.showDeleteLaboratoryEquipmentModal,
                                        })
                                    }

                                }


                                }
                                size={20}
                            />

                        </div>
                        <button
                            type='button'
                            className=' px-6 py-2.5 mx-auto bg-dark-green  text-white font-medium text-xs w-[200px] rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease'
                            onClick={handleSubmit}
                        >
                            <span className='flex items-center justify-between pl-12'>
                                Delete

                            </span>
                        </button>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteLaboratoryEquipmentModal