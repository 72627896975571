/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { useStateContext } from "../../state/context"
import { EmployeeTable, PageTitle } from "../../components"
import { getAllEmployees } from "../../state/actions/admin.actions"


const AdminHumanResources = ({ showAddEmployeeModal, setShowAddEmployeeModal, ...props }) => {
	const dispatch = useDispatch()

	// const { modals } = useStateContext()

	const { employees, create_employee, hospital_config } = useSelector((state) => state.admin)

	const [activeLink, setActiveLink] = useState('Admin')
	// const [showList, setShowList] = useState({
	// 	showDoctors: true,
	// 	showNurses: false,
	// 	showRadiologists: false,
	// 	showLaboratorists: false,
	// 	showPatients: false,
	// 	showRecords: false,
	// 	showPharmacists: false,
	// 	showAccountants: false,
	// })
	const [filterState, setFilterState] = useState({
		search: '', admin: [], doctors: [], dentists: [],
		ophthalmologists: [], otolaryngologists: [], physiotherapists: [],
		nurses: [], pharmacists: [], records: [], laboratorists: [],
		radiologists: [], collections: [], accountants: []
	})

	const handleAdminFilter = () => {
		const filtered = employees
			?.filter(employee => employee.user_details.is_administrator === true)
			?.filter(employee => {
				return employee.first_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.last_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.email.toLowerCase().includes(filterState.search.toLowerCase())
			})

		setFilterState({ ...filterState, admin: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, admin: employees?.filter(employee => employee.user_details.is_administrator === true) })
		}
	}

	const handleDoctorFilter = () => {
		const filtered = employees
			?.filter(employee => employee.user_details.is_doctor === true)
			?.filter(employee => {
				return employee.first_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.last_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.email.toLowerCase().includes(filterState.search.toLowerCase())
			})

		setFilterState({ ...filterState, doctors: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, doctors: employees?.filter(employee => employee.user_details.is_doctor === true) })
		}
	}

	const handleDentistFilter = () => {
		const filtered = employees
			?.filter(employee => employee.user_details.is_dentist === true)
			?.filter(employee => {
				return employee.first_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.last_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.email.toLowerCase().includes(filterState.search.toLowerCase())
			})

		setFilterState({ ...filterState, dentists: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, dentists: employees?.filter(employee => employee.user_details.is_dentist === true) })
		}
	}

	const handleOphthalmologistFilter = () => {
		const filtered = employees
			?.filter(employee => employee.user_details.is_ophthalmologists === true)
			?.filter(employee => {
				return employee.first_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.last_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.email.toLowerCase().includes(filterState.search.toLowerCase())
			})

		setFilterState({ ...filterState, ophthalmologists: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, ophthalmologists: employees?.filter(employee => employee.user_details.is_ophthalmologists === true) })
		}
	}

	const handleOtolaryngologistFilter = () => {
		const filtered = employees
			?.filter(employee => employee.user_details.is_otolaryngologists === true)
			?.filter(employee => {
				return employee.first_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.last_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.email.toLowerCase().includes(filterState.search.toLowerCase())
			})

		setFilterState({ ...filterState, otolaryngologists: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, otolaryngologists: employees?.filter(employee => employee.user_details.is_otolaryngologists === true) })
		}
	}

	const handlePhysiotherapistFilter = () => {
		const filtered = employees
			?.filter(employee => employee.user_details.is_physiotherapists === true)
			?.filter(employee => {
				return employee.first_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.last_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.email.toLowerCase().includes(filterState.search.toLowerCase())
			})

		setFilterState({ ...filterState, physiotherapists: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, physiotherapists: employees?.filter(employee => employee.user_details.is_physiotherapists === true) })
		}
	}

	const handleNurseFilter = () => {
		const filtered = employees
			?.filter(employee => employee.user_details.is_nurse === true)
			?.filter(employee => {
				return employee.first_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.last_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.email.toLowerCase().includes(filterState.search.toLowerCase())
			})

		setFilterState({ ...filterState, nurses: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, nurses: employees?.filter(employee => employee.user_details.is_nurse === true) })
		}
	}

	const handlePharmacistFilter = () => {
		const filtered = employees
			?.filter(employee => employee.user_details.is_pharmacist === true)
			?.filter(employee => {
				return employee.first_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.last_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.email.toLowerCase().includes(filterState.search.toLowerCase())
			})

		setFilterState({ ...filterState, pharmacists: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, pharmacists: employees?.filter(employee => employee.user_details.is_pharmacist === true) })
		}
	}

	const handleRecordsFilter = () => {
		const filtered = employees
			?.filter(employee => employee.user_details.is_records === true)
			?.filter(employee => {
				return employee.first_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.last_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.email.toLowerCase().includes(filterState.search.toLowerCase())
			})

		setFilterState({ ...filterState, records: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, records: employees?.filter(employee => employee.user_details.is_records === true) })
		}
	}

	const handleLaboratoristFilter = () => {
		const filtered = employees
			?.filter(employee => employee.user_details.is_laboratorist === true)
			?.filter(employee => {
				return employee.first_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.last_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.email.toLowerCase().includes(filterState.search.toLowerCase())
			})

		setFilterState({ ...filterState, laboratorists: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, laboratorists: employees?.filter(employee => employee.user_details.is_laboratorist === true) })
		}
	}

	const handleRadiologistFilter = () => {
		const filtered = employees
			?.filter(employee => employee.user_details.is_radiologist === true)
			?.filter(employee => {
				return employee.first_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.last_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.email.toLowerCase().includes(filterState.search.toLowerCase())
			})

		setFilterState({ ...filterState, radiologists: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, radiologists: employees?.filter(employee => employee.user_details.is_radiologist === true) })
		}
	}

	const handleCollectionsFilter = () => {
		const filtered = employees
			?.filter(employee => employee.user_details.is_collections === true)
			?.filter(employee => {
				return employee.first_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.last_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.email.toLowerCase().includes(filterState.search.toLowerCase())
			})

		setFilterState({ ...filterState, collections: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, collections: employees?.filter(employee => employee.user_details.is_collections === true) })
		}
	}

	const handleAccountantsFilter = () => {
		const filtered = employees
			?.filter(employee => employee.user_details.is_accountant === true)
			?.filter(employee => {
				return employee.first_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.last_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| employee.email.toLowerCase().includes(filterState.search.toLowerCase())
			})

		setFilterState({ ...filterState, accountants: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, accountants: employees?.filter(employee => employee.user_details.is_accountant === true) })
		}
	}

	useEffect(() => {
		dispatch(getAllEmployees())
	}, [create_employee])

	useEffect(() => {
		setFilterState({
			...filterState,
			admin: employees?.filter(employee => employee.user_details.is_administrator === true),
			doctors: employees?.filter(employee => employee.user_details.is_doctor === true),
			dentists: employees?.filter(employee => employee.user_details.is_dentist === true),
			ophthalmologists: employees?.filter(employee => employee.user_details.is_ophthalmologists === true),
			otolaryngologists: employees?.filter(employee => employee.user_details.is_otolaryngologists === true),
			physiotherapists: employees?.filter(employee => employee.user_details.is_physiotherapists === true),
			nurses: employees?.filter(employee => employee.user_details.is_nurse === true),
			pharmacists: employees?.filter(employee => employee.user_details.is_pharmacist === true),
			records: employees?.filter(employee => employee.user_details.is_records === true),
			laboratorists: employees?.filter(employee => employee.user_details.is_laboratorist === true),
			radiologists: employees?.filter(employee => employee.user_details.is_radiologist === true),
			collections: employees?.filter(employee => employee.user_details.is_collections === true),
			accountants: employees?.filter(employee => employee.user_details.is_accountant === true),
		})
	}, [employees])

	useEffect(() => {
		if (activeLink === 'Admin') {
			handleAdminFilter()
		}
		if (activeLink === 'Doctor') {
			handleDoctorFilter()
		}
		if (activeLink === 'Dentist') {
			handleDentistFilter()
		}
		if (activeLink === 'Ophthalmologist') {
			handleOphthalmologistFilter()
		}
		if (activeLink === 'Otolaryngologist') {
			handleOtolaryngologistFilter()
		}
		if (activeLink === 'Physiotherapist') {
			handlePhysiotherapistFilter()
		}
		if (activeLink === 'Nurse') {
			handleNurseFilter()
		}
		if (activeLink === 'Pharmacist') {
			handlePharmacistFilter()
		}
		if (activeLink === 'Records') {
			handleRecordsFilter()
		}
		if (activeLink === 'Laboratorist') {
			handleLaboratoristFilter()
		}
		if (activeLink === 'Radiologist') {
			handleRadiologistFilter()
		}
		if (activeLink === 'Collections') {
			handleCollectionsFilter()
		}
		if (activeLink === 'Accountants') {
			handleAccountantsFilter()
		}
	}, [filterState.search])

	return (
		<div>
			<PageTitle
				type={"admin-humanresources"}
				showAddEmployeeModal={showAddEmployeeModal}
				setShowAddEmployeeModal={setShowAddEmployeeModal}
			/>

			<div className="space-y">
				<div className="flex justify-between items-center border-b-2 border-gray-500 w-full">
					<div className='flex space-x-5 max-w-[70%] overflow-x-auto horizontal__scrollbar'>
						<button
							type="button"
							className={`${activeLink === 'Admin' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => {
								setFilterState({ ...filterState, search: '' })
								setActiveLink(e.target.textContent)
							}}
						>
							Admin
						</button>
						<button
							type="button"
							className={`${activeLink === 'Doctor' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => {
								setFilterState({ ...filterState, search: '' })
								setActiveLink(e.target.textContent)
							}}
						>
							Doctor
						</button>
						<button
							type="button"
							className={`${activeLink === 'Dentist' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => {
								setFilterState({ ...filterState, search: '' })
								setActiveLink(e.target.textContent)
							}}
						>
							Dentist
						</button>
						<button
							type="button"
							className={`${activeLink === 'Ophthalmologist' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => {
								setFilterState({ ...filterState, search: '' })
								setActiveLink(e.target.textContent)
							}}
						>
							Ophthalmologist
						</button>
						<button
							type="button"
							className={`${activeLink === 'Otolaryngologist' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => {
								setFilterState({ ...filterState, search: '' })
								setActiveLink(e.target.textContent)
							}}
						>
							Otolaryngologist
						</button>
						<button
							type="button"
							className={`${activeLink === 'Physiotherapist' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => {
								setFilterState({ ...filterState, search: '' })
								setActiveLink(e.target.textContent)
							}}
						>
							Physiotherapist
						</button>
						<button
							type="button"
							className={`${activeLink === 'Nurse' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => {
								setFilterState({ ...filterState, search: '' })
								setActiveLink(e.target.textContent)
							}}
						>
							Nurse
						</button>
						<button
							type="button"
							className={`${activeLink === 'Pharmacist' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => {
								setFilterState({ ...filterState, search: '' })
								setActiveLink(e.target.textContent)
							}}
						>
							Pharmacist
						</button>
						<button
							type="button"
							className={`${activeLink === 'Records' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => {
								setFilterState({ ...filterState, search: '' })
								setActiveLink(e.target.textContent)
							}}
						>
							Records
						</button>
						<button
							type="button"
							className={`${activeLink === 'Laboratorist' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => {
								setFilterState({ ...filterState, search: '' })
								setActiveLink(e.target.textContent)
							}}
						>
							Laboratorist
						</button>
						<button
							type="button"
							className={`${activeLink === 'Radiologist' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => {
								setFilterState({ ...filterState, search: '' })
								setActiveLink(e.target.textContent)
							}}
						>
							Radiologist
						</button>
						{hospital_config?.hospital_size !== 'Small' && (
							<button
								type="button"
								className={`${activeLink === 'Collections' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
								onClick={(e) => {
									setFilterState({ ...filterState, search: '' })
									setActiveLink(e.target.textContent)
								}}
							>
								Collections
							</button>
						)}
						<button
							type="button"
							className={`${activeLink === 'Accountants' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => {
								setFilterState({ ...filterState, search: '' })
								setActiveLink(e.target.textContent)
							}}
						>
							Accountants
						</button>
					</div>

					<div className="max-w-[20%]">
						<input
							type="text"
							name="search"
							placeholder='Search'
							className="border border-gray-400 w-full px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
							onChange={(e) => setFilterState({ ...filterState, [e.target.name]: e.target.value })}
						/>
					</div>
				</div>

				{activeLink === 'Admin' && (
					<EmployeeTable
						data={filterState.admin}
					/>
				)}

				{activeLink === 'Doctor' && (
					<EmployeeTable
						data={filterState.doctors}
					/>
				)}

				{activeLink === 'Dentist' && (
					<EmployeeTable
						data={filterState.dentists}
					/>
				)}

				{activeLink === 'Ophthalmologist' && (
					<EmployeeTable
						data={filterState.ophthalmologists}
					/>
				)}

				{activeLink === 'Otolaryngologist' && (
					<EmployeeTable
						data={filterState.otolaryngologists}
					/>
				)}

				{activeLink === 'Physiotherapist' && (
					<EmployeeTable
						data={filterState.physiotherapists}
					/>
				)}

				{activeLink === 'Nurse' && (
					<EmployeeTable
						data={filterState.nurses}
					/>
				)}

				{activeLink === 'Pharmacist' && (
					<EmployeeTable
						data={filterState.pharmacists}
					/>
				)}

				{activeLink === 'Records' && (
					<EmployeeTable
						data={filterState.records}
					/>
				)}

				{activeLink === 'Laboratorist' && (
					<EmployeeTable
						data={filterState.laboratorists}
					/>
				)}

				{activeLink === 'Radiologist' && (
					<EmployeeTable
						data={filterState.radiologists}
					/>
				)}

				{activeLink === 'Collections' && (
					<EmployeeTable
						data={filterState.collections}
					/>
				)}

				{activeLink === 'Accountants' && (
					<EmployeeTable
						data={filterState.accountants}
					/>
				)}

				{/* <EmployeeTable
					data={nurses}
					title={'Nurses'}
					showList={showList}
					display={showList.showNurses}
					setShowList={setShowList}
				/>

				<EmployeeTable
					data={radiologists}
					title={'Radiologists'}
					showList={showList}
					display={showList.showRadiologists}
					setShowList={setShowList}
				/>

				<EmployeeTable
					data={laboratorists}
					title={'Laboratorists'}
					showList={showList}
					display={showList.showLaboratorists}
					setShowList={setShowList}
				/>

				<EmployeeTable
					data={records}
					title={'Records'}
					showList={showList}
					display={showList.showRecords}
					setShowList={setShowList}
				/>

				<EmployeeTable
					data={pharmacists}
					title={'Pharmacists'}
					showList={showList}
					display={showList.showPharmacists}
					setShowList={setShowList}
				/>

				<EmployeeTable
					data={accountants}
					title={'Accountants'}
					showList={showList}
					display={showList.showAccountants}
					setShowList={setShowList}
				/> */}
			</div>
		</div>
	)
}

export default AdminHumanResources
