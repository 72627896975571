import { useState } from 'react'
import { AiOutlineDeliveredProcedure } from 'react-icons/ai'

const DoctorPatientPrescriptionsTable = ({ title, data }) => {

    const [showList, setShowList] = useState(true)

    return (
        <div className="flex justify-between items-center w-full px-1">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="py-2">
                    <div
                        className="flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1"
                        onClick={() => setShowList(!showList)}
                    >
                        <h4 className="text-dark-blue text-[14px] font-bold">
                            {title}
                        </h4>
                        <div className='flex justify-between items-center space-x-5'>
                            <AiOutlineDeliveredProcedure className="text-dark-blue" size={20} />
                        </div>
                    </div>
                    {showList && (
                        <table className="border w-full cursor-default">
                            <thead className="">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-[10px] font-bold text-gray-900 pr-[2px] px-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-bold text-gray-900 pr-[2px] text-left"
                                    >
                                        Drug
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-bold text-gray-900 pr-[2px] text-left"
                                    >
                                        Route
                                    </th>
                                    {/* <th
                                        scope="col"
                                        className="text-[12px] font-bold text-gray-900 pr-[2px] text-left"
                                    >
                                        Strength
                                    </th> */}
                                    <th
                                        scope="col"
                                        className="text-[12px] font-bold text-gray-900 pr-[2px] text-left"
                                    >
                                        Units
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-bold text-gray-900 pr-[2px] text-left"
                                    >
                                        Frequency
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-bold text-gray-900 pr-[2px] text-left"
                                    >
                                        Duration
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-bold text-gray-900 pr-[2px] text-left"
                                    >
                                        Duration/Time
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((prescription, index) => (
                                    <tr key={`${prescription.id}`}>
                                        <td className="px-4 py-2 whitespace-nowrap text-[10px] font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {prescription.drugs}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {prescription.routes}
                                        </td>
                                        {/* <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            5
                                        </td> */}
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {prescription.units}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {prescription.frequency}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {prescription.duration}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {prescription.duration_time}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            {/* <PaginationButtons
                                pages={pages}
                                currentPage={currentPage}
                                handlePagination={handlePagination}
                            /> */}
                        </table>
                    )}
                </div>
            </div>
        </div >
    )
}

export default DoctorPatientPrescriptionsTable
