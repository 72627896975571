	/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PageTitle, PharmacistSectionsSummary, InventorySummary } from '../../components'
import { getDashboardData, getDrugCategories, getSupplyPoints } from '../../state/actions/pharmacist.actions'


const PharmacistDashboard = () => {
	const dispatch = useDispatch()

	const { dashboard_data } = useSelector(state => state.pharmacist)

	useEffect(() => {
		dispatch(getDashboardData())
	}, [])

	return (
		<div>
			<PageTitle type={'pharmacist-dashboard'} />

			<PharmacistSectionsSummary />

			<div className="flex flex-wrap justify-between gap-8 items-start mt-5">
				<InventorySummary
					title={'Inventory'}
					left_count={dashboard_data?.drugs}
					left_title={'Total number of medicines'}
					right_count={dashboard_data?.drug_categories}
					right_title={'Medicine Groups'}
				/>

				<InventorySummary
					title={'Quick Report'}
					left_count={dashboard_data?.drugs_sold}
					left_title={'Medicines Sold'}
					right_count={dashboard_data?.invoices}
					right_title={'Invoices Generated'}
				/>

				<InventorySummary
					title={'Suppliers'}
					left_count={`03`}
					left_title={'Total no. of Suppliers'}
					// right_count={`05`}
					// right_title={'Total no. of Users'}
				/>

				<InventorySummary
					title={'Staff'}
					type={2}
					left_count={dashboard_data?.staff}
					left_title={'Total no. of Pharmacists'}
					right_count={`05`}
					right_title={'Total no. of Users'}
				/>
			</div>
		</div>
	)
}

export default PharmacistDashboard