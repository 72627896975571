/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { AiOutlineFieldTime, AiOutlineFileDone } from 'react-icons/ai'
import { BsCardChecklist } from 'react-icons/bs'
import { GrFormNext } from 'react-icons/gr'
import { useDispatch, useSelector } from 'react-redux'

import { getDashboardInfo } from '../../state/actions/patient.actions'


const PatientDashboard = () => {
	const dispatch = useDispatch()

	const { dashboard_info } = useSelector(state => state.patient)

	useEffect(() => {
		dispatch(getDashboardInfo())
	}, [])

	return (
		<div className="flex flex-wrap justify-between items-center py-5">
			<div className="border-t border-l border-r rounded w-[300px] h-[150px] cursor-pointer shadow-green-200 shadow-lg hover:shadow-green-300 hover:translate-x-1 ease-in-out duration-500">
				<div className="flex flex-col justify-between h-full">
					<div className="py-4 flex flex-col items-center">
						<BsCardChecklist
							className='text-green-600'
						/>

						<p className='text-[18px] text-dark-blue'>Appointments</p>
						<h4 className='text-[20px] text-dark-blue font-bold'>{dashboard_info?.appointments}</h4>
					</div>

					<div className="text-[14px] text-dark-blue font-normal flex justify-center items-center bg-green-200 w-full">
						View Detailed Report <span className="flex space-x-[-10px]"><GrFormNext /><GrFormNext /><GrFormNext /></span>
					</div>
				</div>
			</div>

			<div className="border-t border-l border-r rounded w-[300px] h-[150px] cursor-pointer shadow-purple-200 shadow-lg hover:shadow-purple-300 hover:translate-x-1 ease-in-out duration-500">
				<div className="flex flex-col justify-between h-full">
					<div className="py-4 flex flex-col items-center">
						<AiOutlineFieldTime
							className='text-purple-600'
						/>

						<p className='text-[18px] text-dark-blue'>Designations</p>
						<h4 className='text-[20px] text-dark-blue font-bold'>{dashboard_info?.assignments}</h4>
					</div>

					<div className="text-[14px] text-dark-blue font-normal flex justify-center items-center bg-purple-200 w-full">
						View Detailed Report <span className="flex space-x-[-10px]"><GrFormNext /><GrFormNext /><GrFormNext /></span>
					</div>
				</div>
			</div>

			<div className="border-t border-l border-r rounded w-[300px] h-[150px] cursor-pointer shadow-yellow-200 shadow-lg hover:shadow-yellow-300 hover:translate-x-1 ease-in-out duration-500">
				<div className="flex flex-col justify-between h-full">
					<div className="py-4 flex flex-col items-center">
						<AiOutlineFileDone
							className='text-yellow-600'
						/>

						<p className='text-[18px] text-dark-blue'>Invoices</p>
						<h4 className='text-[20px] text-dark-blue font-bold'>{dashboard_info?.invoices}</h4>
					</div>

					<div className="text-[14px] text-dark-blue font-normal flex justify-center items-center bg-yellow-200 w-full">
						View Detailed Report <span className="flex space-x-[-10px]"><GrFormNext /><GrFormNext /><GrFormNext /></span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PatientDashboard