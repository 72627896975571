import React, { useState, useEffect } from 'react'
import { FaPencilAlt } from 'react-icons/fa'
import { GiTestTubes } from 'react-icons/gi'
import { MdDelete } from 'react-icons/md'
import { useSelector, useDispatch } from 'react-redux'

import { PageTitle } from '../../components'
import { useStateContext } from '../../state/context'
import LaboratoryPagination from './LaboratoryPagination'
import { setLaboratoryEquipment } from '../../state/actions/actions'
import { getAllLabEquipments } from '../../state/actions/laboratorist.actions'
import Pagination from '../../components/utils/Pagination'


const LaboratoristEquipments = () => {
	const dispatch = useDispatch()

	const { modals, setModals } = useStateContext()

	const { all_laboratorist_equipment } = useSelector((state) => state.laboratorist)

	const [currentData, setCurrentData] = useState([])
	const { user } = useSelector(state => state.auth)

	useEffect(() => {
		dispatch(getAllLabEquipments())
	}, [modals.showAddLaboratoryEquipmentModal, modals.showUpdateLaboratoryEquipmentModal, modals.showDeleteLaboratoryEquipmentModal])

	return (
		<div>
			<PageTitle type={'laboratorist-equipments'} />

			<div className='flex flex-col w-full cursor-pointer'>
				<div className='py-2'>
					<div className='flex justify-start items-center space-x-4 bg-gray-200 p-1'>
						<h4 className='text-dark-blue text-[14px] font-bold'>
							Equipments
						</h4>
						<GiTestTubes
							className='text-dark-blue'
							size={20}
						/>
					</div>
					<table className='border rounded w-full cursor-default'>
						<thead className=''>
							<tr>
								<th
									scope='col'
									className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
								>
									S/N
								</th>

								<th
									scope='col'
									className='text-sm font-medium text-gray-900 pr-[4em] py-4 text-left'
								>
									Name
								</th>
								<th
									scope='col'
									className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
								>
									Type
								</th>
								<th
									scope='col'
									className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
								>
									Status
								</th>

								{user?.data?.is_department_head && <th
									scope='col'
									className='text-sm font-medium text-gray-900 py-4 text-left'
								>
									Actions
								</th>}
							</tr>
						</thead>
						<tbody>
							{currentData?.map((equipment, index) => (
								<tr
									className='relative'
									key={index}
								>
									<td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
										{index + 1}
									</td>
									<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
										{equipment.title}
									</td>
									<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
										{equipment.type}
									</td>
									<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
										{equipment.status}
									</td>
									{user?.data?.is_department_head && <td className=' text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
										<div className='flex space-x-4 relative'>
											<FaPencilAlt
												size={24}
												className='text-green-600 cursor-pointer '
												onClick={() => {
													const data = {
														id: equipment.id,
														title: equipment.title,
														type: equipment.type,
														status: equipment.status,

													}

													dispatch(setLaboratoryEquipment({ data }))
													setModals({
														...modals,
														showUpdateLaboratoryEquipmentModal:
															!modals.showUpdateLaboratoryEquipmentModal,
													})
												}}
											/>
											<MdDelete
												size={24}
												className='text-dark-yellow cursor-pointer'
												onClick={() => {
													const data = {
														id: equipment.id,
														title: equipment.title,
														type: equipment.type,
														status: equipment.status,

													}

													dispatch(setLaboratoryEquipment({ data }))
													setModals({
														...modals,
														showDeleteLaboratoryEquipmentModal:
															!modals.showDeleteLaboratoryEquipmentModal,
													})
												}}
											/>
										</div>
									</td>}
								</tr>
							))}
						</tbody>
					</table>

					<Pagination
						data={all_laboratorist_equipment}
						currentData={currentData}
						setCurrentData={setCurrentData}
					/>
				</div>
			</div>
		</div>
	)
}

export default LaboratoristEquipments