import { API } from "../config";


export const fetchDashboardData = (token) => API.get('/pharmacist/dashboard/', { headers: { Authorization: `Bearer ${token}` } })


export const fetchDrugCategories = (token) => API.get('/pharmacist/drug_categories/', { headers: { Authorization: `Bearer ${token}` } })


export const generateDrugCategory = (formData, token) => API.post('/pharmacist/add_drug_category/', formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchDrugCategory = (formData, token) => API.put(`/pharmacist/drug_category/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const removeDrugCategory = (id, token) => API.delete(`/pharmacist/drug_category/${id}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchSupplyPoints = (token) => API.get(`/pharmacist/supply_points/`, { headers: { Authorization: `Bearer ${token}` } })


export const generateSupplyPoint = (formData, token) => API.post(`/pharmacist/add_supply_point/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchSupplyPoint = (formData, token) => API.put(`/pharmacist/supply_point/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const removeSupplyPoint = (id, token) => API.delete(`/pharmacist/supply_point/${id}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchDrugs = (token) => API.get(`/pharmacist/drugs/`, { headers: { Authorization: `Bearer ${token}` } })


export const generateDrug = (formData, token) => API.post(`/pharmacist/add_drug/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchDrug = (formData, token) => API.put(`/pharmacist/drug/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const removeDrug = (id, token) => API.delete(`/pharmacist/drug/${id}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchDrugBinCards = (id, token) => API.get(`/pharmacist/drug_inventory/${id}/`, { headers: { Authorization: `Bearer ${token}` } })


export const generateDrugBinCard = (formData, token) => API.post(`/pharmacist/add_drug_inventory/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const stockDrugToSupplyPoint = (formData, token) => API.post(`/pharmacist/supplypoint_inventory/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchDrugInventory = (formData, token) => API.post(`/pharmacist/druginventory_dispatch/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchSupplyPointInventories = (supplypoint_name, token) => API.get(`/pharmacist/supplypoint_inventories/${supplypoint_name}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchSupplyPointInventoryBinCards = (params, token) => API.get(`/pharmacist/supplypoint_inventories/${params.spoint}/${params.drug}/`, { headers: { Authorization: `Bearer ${token}` } })


export const generateInventoryForSupplyPoint = (formData, token) => API.post(`/pharmacist/dispense_to_supplypoint/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchInventoryForSupplyPoint = (formData, token) => API.patch(`/pharmacist/dispense_to_supplypoint/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const issueDrugOut = (formData, token) => API.patch(`/pharmacist/issue_out_drug/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const generateDrugDispense = (formData, token) => API.patch(`/pharmacist/dispense_drug/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const generateMultipleDrugDispense = (formData, token) => API.patch(`/pharmacist/multiple_dispense_drug/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchSupplyPointBinCards = (params, token) => API.post(`/pharmacist/supplypoint_bincards/`, params, { headers: { Authorization: `Bearer ${token}` } })


export const fetchPrescriptions = (token) => API.get(`/pharmacist/non_opd_prescriptions/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchDrugPrices = (params, token) => API.post(`/pharmacist/drug_prices/`, params, { headers: { Authorization: `Bearer ${token}` } })


export const generatePrescriptionsInvoice = (formData, token) => API.post(`/pharmacist/prescriptions_invoice/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const generateMultiplePrescriptionsInvoice = (formData, token) => API.post(`/pharmacist/multiple_prescriptions_invoice/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchPrescriptionInvoices = (token) => API.get(`/pharmacist/prescriptions_invoice/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchPaidPrescriptions = (params, token) => API.get(`/pharmacist/paid_prescriptions/${params.prescription}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchMultiplePaidPrescriptions = (formData, token) => API.post(`/pharmacist/multiple_paid_prescriptions/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchPharmacistProfiles = (token) => API.get(`/pharmacist/assign_supply_point/`, { headers: { Authorization: `Bearer ${token}` } })


export const patchPharmacistSupplyPoint = (formData, token) => API.patch(`/pharmacist/assign_supply_point/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchSupplyPointStaff = (params, token) => API.get(`/pharmacist/supplypoint_staff/${params.spoint}/`, { headers: { Authorization: `Bearer ${token}` } })

export const fetchClinicalData = (formData, token) => API.post(`/pharmacist/db/`, formData, { headers: { Authorization: `Bearer ${token}` } })  