import { API } from "../config";

export const getEmployees = (token) => API.get('/laboratorist/fetch_all_employees/', { headers: { Authorization: `Bearer ${token}` } })
export const getLabEquipment = (token) => API.get('/laboratorist/laboratory_equipments/', { headers: { Authorization: `Bearer ${token}` } })
export const addLaboratoryEquipment = (formData, token) => API.post('/laboratorist/laboratory_equipments/', formData, { headers: { Authorization: `Bearer ${token}` } })
export const updateLaboratoryEquipment = (formData, token) => API.put(`/laboratorist/laboratory_equipments/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })
export const deleteLaboratoryEquipment = (equipment_id, token) => API.delete(`/laboratorist/laboratory_equipments/${equipment_id}/`, { headers: { Authorization: `Bearer ${token}` } })
export const getLaboratoryReport = (token) => API.get('/laboratorist/lab_reports/', { headers: { Authorization: `Bearer ${token}` } })
export const getALabBillingDetails = (billing_id, token) => API.get(`/administrator/billings/${billing_id}/`, { headers: { Authorization: `Bearer ${token}` } })
export const getAllLabBilling = (token) => API.get('/laboratorist/billings/', { headers: { Authorization: `Bearer ${token}` } })
export const generateLabInvoice = (formData, token) => API.post(`/laboratorist/lab_invoice/${formData.patient_id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })
export const uploadPatientLaboratoryReport = (formData, token) => API.put(`/laboratorist/lab_reports/${formData.report_id}/`, formData, { headers: { Authorization: `Bearer ${token}`, "content-type": "multipart/form-data" } })
export const getInvoiceGeneratedByUser = (token) => API.get('/laboratorist/lab_invoice_by_me/', { headers: { Authorization: `Bearer ${token}` } })
export const getLaboratoryInvestigationCategory = (token) => API.get('/laboratorist/all_lab_investigation_cat/', { headers: { Authorization: `Bearer ${token}` } })
export const createLaboratoryCategoryTest = (formData, token) => API.post('/laboratorist/create_lab_investigation_cat/', formData, { headers: { Authorization: `Bearer ${token}` } })
export const deleteLaboratoryCategoryTest = (cat_id, token) => API.delete(`/laboratorist/get_lab_investigation_cat/${cat_id}/`, { headers: { Authorization: `Bearer ${token}` } })
export const updateLaboratoryCategoryTest = (formData, token) => API.put(`/laboratorist/get_lab_investigation_cat/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })
export const getLaboratoryInvestigationTypes = (token) => API.get('/laboratorist/all_lab_investigation_type/', { headers: { Authorization: `Bearer ${token}` } })
export const addLaboratoryInvestigationType = (formData, token) => API.post('/laboratorist/create_lab_investigation_type/', formData, { headers: { Authorization: `Bearer ${token}` } })
export const updateLaboratoryInvestigationType = (formData, token) => API.put(`/laboratorist/lab_investigation_type/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })
export const deleteLaboratoryInvestigationType = (investigation_id, token) => API.delete(`/laboratorist/lab_investigation_type/${investigation_id}/`, { headers: { Authorization: `Bearer ${token}` } })
export const addLaboratoryInvestigationSamples = (formData, token) => API.post('/laboratorist/create_lab_sample/', formData, { headers: { Authorization: `Bearer ${token}` } })
export const getLabSampleTest = (token) => API.get('/laboratorist/get_lab_samples/', { headers: { Authorization: `Bearer ${token}` } })
export const updateLabSampleTest = (formData, token) => API.put(`/laboratorist/lab_sample_test/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })
export const deleteLaboratorySampleTest = (id, token) => API.delete(`/laboratorist/lab_sample_test/${id}/`, { headers: { Authorization: `Bearer ${token}` } })
export const fetchLabTypesByCategory = (id, token) => API.get(`/laboratorist/lab_types_by_cat/${id}/`, { headers: { Authorization: `Bearer ${token}` } })
