/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import { useStateContext } from "../../../state/context"
import { setCurrentPrescriptionInvoice, setCurrentPrescriptions } from "../../../state/actions/actions"
import { createPrescriptionsInvoice, getDrugPrices, getPaidPrescriptions, resetPaidPrescriptions } from "../../../state/actions/pharmacist.actions"


const PharmacistGenerateInvoiceModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { prescriptions_update, prescription_invoice_update } = useSelector(state => state.root)
    const { drugs_price, patient_drugdispensed } = useSelector(state => state.pharmacist)
    const [formData, setFormData] = useState({ price: 0, })
    const [total, setTotal] = useState(0)
    const [invoicePrescriptions, setInvoicePrescriptions] = useState(0)

    useEffect(() => {
        const drugs = []
        drugs.push(prescriptions_update?.drugs)

        const params = [...new Set(drugs)]

        setInvoicePrescriptions(prescriptions_update?.pkid)

        dispatch(getDrugPrices({ params }))

    }, [prescriptions_update])

    useEffect(() => {
        if (prescription_invoice_update?.pkid) {
            const params = {
                patient: prescription_invoice_update?.invoice_patient?.pkid,
                diagnosis: prescription_invoice_update?.diagnosis
            }
            dispatch(getPaidPrescriptions({ params }))
        }
    }, [prescription_invoice_update, patient_drugdispensed])

    const handleChange = (e, name) => {
        setFormData({ ...formData, [name]: e.target.value })
    }

    const calcTotal = () => {
        let sum = 0
        const drugs = Object.keys(formData).filter(item => !item.includes('price'))

        drugs.forEach((data, index) => {
            sum += drugs_price?.filter(a => a.drug_name === data)[0]?.selling_price * formData[data]
        })

        setTotal(sum)
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        dispatch(createPrescriptionsInvoice({ formData: { ...formData, prescription: invoicePrescriptions, total }, toast, setModals, modals, dispatch, setCurrentPrescriptions }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className={`w-[600px] bg-white px-[20px] py-[15px]`}>
                <div className="flex flex-col space-y-2">
                    <div className="flex justify-between items-center">
                        <h4 className={`text-sky-500 text-[20px] font-bold capitalize`}>
                            Generate Invoice
                        </h4>
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                dispatch(resetPaidPrescriptions())
                                dispatch(setCurrentPrescriptions({ data: null }))
                                dispatch(setCurrentPrescriptionInvoice({ data: null }))
                                modals.showPharmacistDrugInvoiceModal && setModals({ ...modals, showPharmacistDrugInvoiceModal: !modals.showPharmacistDrugInvoiceModal })
                            }}
                            size={20}
                        />
                    </div>

                    {/* user info */}
                    <div className="flex items-center space-x-5">
                        <img
                            src={`${process.env.REACT_APP_API_ROOT}${prescriptions_update && prescriptions_update?.patient_prescribed?.profile_photo}`}
                            alt=""
                            className="w-[80px] h-[80px] rounded-[50%]"
                        />

                        <div className="flex flex-col space-y-1">
                            <p className="text-[14px] text-gray-600 font-bold">{prescriptions_update && prescriptions_update?.patient_prescribed?.last_name} {prescriptions_update && prescriptions_update?.patient_prescribed?.first_name}</p>
                            <p className="text-[12px] text-gray-300 font-medium">{prescriptions_update && prescriptions_update?.patient_id}</p>
                        </div>
                    </div>

                    <form>
                        <div className="flex justify-between">
                            <div className="space-y-1 w-[48%]">
                                <div className="">
                                    <label className="bg-gray-600 text-white px-[5px] py-[1px] text-[10px] rounded">Drug</label>
                                    <input
                                        type="text"
                                        disabled
                                        value={prescriptions_update?.drugs}
                                        className="border rounded w-full h-5 px-4 py-5 mb-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    />
                                </div>
                                <div>
                                    <label className="bg-gray-600 text-white px-[5px] py-[1px] text-[10px] rounded">Route</label>
                                    <input
                                        type="text"
                                        value={prescriptions_update?.routes}
                                        disabled
                                        className="border rounded w-full h-5 px-4 py-5 mb-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    />
                                </div>
                                <div>
                                    <label className="bg-gray-600 text-white px-[5px] py-[1px] text-[10px] rounded">Unit</label>
                                    <input
                                        type="text"
                                        disabled
                                        value={prescriptions_update?.units}
                                        className="border rounded w-full h-5 px-4 py-5 mb-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    />
                                </div>
                            </div>

                            <div className="space-y-1 w-[48%]">
                                <div>
                                    <label className="bg-gray-600 text-white px-[5px] py-[1px] text-[10px] rounded">Frequency</label>
                                    <input
                                        type="text"
                                        disabled
                                        value={prescriptions_update?.frequency}
                                        className="border rounded w-full h-5 px-4 py-5 mb-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    />
                                </div>

                                <div>
                                    <label className="bg-gray-600 text-white px-[5px] py-[1px] text-[10px] rounded">Duration/Times</label>
                                    <input
                                        type="text"
                                        value={prescriptions_update?.duration + '/' + prescriptions_update?.duration_time}
                                        className="border rounded w-full h-5 px-4 py-5 mb-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    />
                                </div>

                                <div>
                                    <label className="bg-gray-600 text-white px-[5px] py-[1px] text-[10px] rounded">Price</label>
                                    <input
                                        type="number"
                                        name="price"
                                        placeholder="Cost per unit"
                                        className="border rounded w-full h-5 px-4 py-5 mb-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        disabled
                                        value={drugs_price?.filter(a => a.drug_name === prescriptions_update?.drugs)[0]?.selling_price}
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div>
                                    <label className="bg-gray-600 text-white px-[5px] py-[1px] text-[10px] rounded">Quantity</label>
                                    <input
                                        type="number"
                                        name={`${prescriptions_update?.drugs}`}
                                        min={"0"}
                                        placeholder="Quantity"
                                        className="border border-orange-500 rounded w-full h-5 px-4 py-5 mb-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                            </div>
                        </div>
                    </form>


                    <div className="flex justify-start">
                        <button
                            type="button"
                            className="w-[270px] inline-block px-6 py-2 border border-teal-500 text-teal-600 font-bold text-sm leading-tight uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-500 ease-in-out"
                            onClick={calcTotal}
                        >
                            &#x20A6; {total === 0 ? 'Subtotal' : `${total}`}
                        </button>
                    </div>


                    <div className="mt-4 flex justify-start">
                        <button
                            type="submit"
                            onClick={handleSubmit}
                            disabled={total === 0}
                            className="inline-block w-[270px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight shadow-md transition duration-500 ease"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PharmacistGenerateInvoiceModal