import React from 'react'
import { useStateContext } from '../../../state/context'
import { FaTimesCircle } from 'react-icons/fa'
import { useSelector } from 'react-redux'

const RecordsViewWardReportModal = () => {
    const { modals, setModals } = useStateContext()

    const { records_view_ward_report } = useSelector((state) => state.root)
    return (
        <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
            <div className='bg-white rounded px-[10px] py-[10px] w-[full]'>
                <div className='flex justify-between items-center'>
                    <h4 className='text-black text-[15px] font-bold italic'>
                        Ward Report - for {records_view_ward_report?.created_at.slice(0, 10)}
                    </h4>'

                    <FaTimesCircle
                        className='text-dark-yellow cursor-pointer mb-2'
                        onClick={() =>
                            setModals({
                                ...modals,
                                showViewWardReportModal:
                                    !modals.showViewWardReportModal,
                            })
                        }
                        size={20}
                    />
                </div>
                <p className='text-black text-[10px] font-semibold italic'> Reported by -   {records_view_ward_report?.reported_by?.first_name} {records_view_ward_report?.reported_by?.last_name}</p>
                <textarea
                    className='mt-2 mx-2'
                    disabled
                    value={records_view_ward_report?.report}
                    name="" id="" cols="30" rows="10"></textarea>




            </div>
        </div>
    )
}

export default RecordsViewWardReportModal