import { Fragment } from "react";

import { CapturePhotoModal, UpdatePasswordModal, WebCamModal } from "..";
import SendMailModal from '../modals/SendMailModal'
import SendSmsModal from '../modals/SendSmsModal'
import AdminFormModals from "./modals/AdminFormModals";
import DoctorFormModals from "./modals/DoctorFormModals";
import PharmacistFormModals from "./modals/PharmacistFormModals";
import RecordsFormModals from "./modals/RecordsFormModals";
import RadiologyFormModal from "./modals/RadiologyFormModal";
import LaboratoryFormModal from "./modals/LaboratoryFormModal";
import NurseFormModal from "./modals/NurseFormModal";
import PatientFormModals from "./modals/PatientFormModals";
import AccountsFormModals from "./modals/AccountsFormModals";
import CollectionsFormModals from "./modals/CollectionsFormModals";
import ChatCreateGroupModal from "../modals/ChatCreateGroupModal";
import ChatAddGroupParticipantModal from "../modals/ChatAddGroupParticipantModal";
import ShowIncomingCallModal from "../modals/showIncomingCallModal";
import CreateRoomModal from "../modals/CreateRoomModal";
import ShowCallNotificationsModal from "../modals/showCallNotificationsModal";


const FormModal = (props) => {
	// nurse_add_patient
	return (
		<Fragment>
			<AdminFormModals type={props.type} />

			<RecordsFormModals type={props.type} />

			<RadiologyFormModal type={props.type} />

			<LaboratoryFormModal type={props.type} />

			<NurseFormModal type={props.type} />

			<PharmacistFormModals type={props.type} />

			<DoctorFormModals type={props.type} />

			<PatientFormModals type={props.type} />

			<AccountsFormModals type={props.type} />

			<CollectionsFormModals type={props.type} />


			{props.type === 'chat__creategroup' && (
				<ChatCreateGroupModal />
			)}

			{props.type === 'chat__addgroup_participant' && (
				<ChatAddGroupParticipantModal />
			)}

			{props.type === 'send__sms__modal' && (
				<SendSmsModal />
			)}

			{props.type === 'send__mail__modal' && (
				<SendMailModal />
			)}

			{props.type === "profile__update__password" && (
				<UpdatePasswordModal />
			)}

			{props.type === "profile__photo__webcam" && (
				<WebCamModal />
			)}
			{props.type === "show_incoming_call" && (
				<ShowIncomingCallModal
					activeLink={props.activeLink}
					setActiveLink={props.setActiveLink}

				/>

			)}
			{props.type === "create_room_modal" && (
				<CreateRoomModal />

			)}
			{props.type === "show_call_notifications" && (
				<ShowCallNotificationsModal
				/>

			)}


		</Fragment >
	)
}

export default FormModal