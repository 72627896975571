import React, { useEffect } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useStateContext } from '../../../state/context'
import { useSelector, useDispatch } from 'react-redux'
import { setAdministerOpd } from '../../../state/actions/actions'
import { getAllPatientDiagnosisPrescriptions } from '../../../state/actions/nurse.actions'


const NursePrescriptionTable = () => {
    const { modals, setModals } = useStateContext()
    const { all_patient_diagnosis_prescriptions } = useSelector((state) => state.nurse)
    const { set_administer_opd, set_get_diagnosisPrescriptions } = useSelector((state) => state.root)

    const filtered = all_patient_diagnosis_prescriptions?.filter((item) => {
        return item.other_prescription === false
    })
    const dispatch = useDispatch()

    useEffect(() => {
        const id = set_get_diagnosisPrescriptions?.diagnosis_id
        dispatch(getAllPatientDiagnosisPrescriptions({ id }))

    }, [modals.showNurseAdministerOPDModal])


    useEffect(() => {

    }, [all_patient_diagnosis_prescriptions])

    return (
        <>
            <div className='flex justify-start items-center w-full space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1'>
                <h4 className='text-dark-blue text-[16px] font-semibold'>
                    Prescription
                </h4>


            </div>
            <table className='border rounded cursor-default w-full'>
                <thead className=''>
                    <tr>
                        <th
                            scope='col'
                            className='text-[10px] font-medium text-gray-900  px-1 py-4 text-left'
                        >
                            S/N
                        </th>

                        <th
                            scope='col'
                            className='text-[10px] font-medium text-gray-900  px-1 py-4 text-left'
                        >
                            Routes
                        </th>
                        <th
                            scope='col'
                            className='text-[10px] font-medium text-gray-900  px-1 py-4 text-left'
                        >
                            Drugs
                        </th>
                        <th
                            scope='col'
                            className='text-[10px] font-medium text-gray-900  px-1 py-4 text-left'
                        >
                            Frequency
                        </th>
                        <th
                            scope='col'
                            className='text-[10px] font-medium text-gray-900 py-4 text-left'
                        >
                            Duration

                        </th>

                        <th
                            scope='col'
                            className='text-[10px] font-medium text-gray-900 py-4 text-left'
                        >
                            Injection Count

                        </th>
                        <th
                            scope='col'
                            className='text-[10px] font-medium text-gray-900 py-4 text-left'
                        >
                            Times administered

                        </th>
                        <th
                            scope='col'
                            className='text-[10px] font-medium text-gray-900 py-4 text-left'
                        >
                            Actions
                        </th>

                    </tr>
                </thead>

                <tbody>
                    {filtered?.map((item, index) => (
                        <tr
                            className='relative'
                            key={index}

                        >
                            <td className='px-1 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
                                {index + 1}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.routes}         </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.drugs}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.frequency}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.duration}/{item.duration_time}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.injection_count}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.times}
                            </td>


                            <td className=' text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                <div className='flex space-x-4 relative '>
                                    {item.injection_count == item.times && (
                                        <button
                                            type="button"
                                            className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"

                                        >
                                            Completed
                                        </button>
                                    )}
                                    {item.injection_count != item.times && (
                                        <button
                                            type="button"
                                            className="inline-block px-6 py-2.5 bg-dark-blue text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                            onClick={() => {
                                                const data = {
                                                    id: item?.id,
                                                    drug: item?.drugs,
                                                    frequency: item?.frequency,
                                                    dosage: item?.dosage_form,
                                                    dose: item?.dosage_form,
                                                    unit: item?.units,
                                                    opd_count: item?.times,
                                                    route: item?.routes,
                                                    duration: `${item?.duration}/${item?.duration_time}`




                                                }
                                                dispatch(setAdministerOpd({ data }))
                                                setModals({
                                                    ...modals,
                                                    showNurseAdministerOPDModal:
                                                        !modals.showNurseAdministerOPDModal,
                                                })
                                            }}
                                        >
                                            Administer
                                        </button>

                                    )}

                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>

            </table>

        </>
    )
}

export default NursePrescriptionTable