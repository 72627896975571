import { setOpenRoom, setRoomDetails, setActiveRooms, setLocalStream, setRemoteStreams, setScreenSharingStream } from '../../state/actions/roomActions';
import reducer from '../../state/store';
import store from '../../state/store';
import { io } from 'socket.io-client'
import { useDispatch, useSelector } from 'react-redux';
import * as webRTCHanlder from './webRTCHandler';

const socketConnection = require('./socketConnection');





export const createNewRoom = (data) => {
    const successCallbackFunc = () => {
        store.dispatch(setOpenRoom(true, true));
        socketConnection.createNewRoom(data)

    };

    const audioOnly = reducer.getState().room.audioOnly;
    webRTCHanlder.getLocalStreamPreview(audioOnly, successCallbackFunc);
    // webRTCHanlder.getLocalStreamPreview(false, successCallbackFunc);


}



export const updateActiveRooms = (data) => {
    const { activeRooms } = data;
    const rooms = []
    activeRooms.forEach(room => {
        rooms.push({ ...room });

    });
    reducer.dispatch(setActiveRooms(rooms));

    console.log('new active rooms from store')
    console.log(activeRooms);
}



export const newRoomCreated = (data) => {
    const { roomDetails } = data;
    reducer.dispatch(setRoomDetails(roomDetails));

};




export const joinRoom = (roomId, userData) => {
    const successCallBackFunc = () => {
        reducer.dispatch(setRoomDetails({ roomId }));
        reducer.dispatch(setOpenRoom(false, true));
        socketConnection.joinRoom({ roomId, userData })

    };

    const audioOnly = reducer.getState().room.audioOnly;
    webRTCHanlder.getLocalStreamPreview(audioOnly, successCallBackFunc);




}


export const leaveRoom = () => {
    const roomId = reducer.getState().room.roomDetails.roomId;
    const localStream = store.getState().room.localStream;

    //Remove media access when user leaves room

    // if (localStream) {
    //     localStream.getTracks().forEach(track => track.stop());
    //     reducer.dispatch(setLocalStream(null));
    // }

    const screenSharingStream = reducer.getState().room.screenSharingStream;
    // Stop stream sharing whenver the sharer leaves the room
    // if (screenSharingStream) {
    //     screenSharingStream.getTracks().forEach(track => track.stop());
    //     reducer.dispatch(setScreenSharingStream(null));
    // }
    reducer.dispatch(setRemoteStreams([])); // Remove media stream on closure

    webRTCHanlder.closeAllConnections();


    console.log('rooooooooomID', roomId)
    socketConnection.leaveRoom({ roomId });
    reducer.dispatch(setRoomDetails(null));
    store.dispatch(setOpenRoom(false, false))
}







