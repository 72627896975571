import React, { useState, useEffect } from 'react'
import AntigensTable from './AntigensTable'
import { useSelector } from 'react-redux'

const VaccinationScheduleTable = () => {
	const { vaccination_record_for_a_patient } = useSelector((state) => state.nurse)
	const [BCG, setBCG] = useState(false)
	const [HepB, setHepB] = useState(false)
	const [OPV0, setOPV0] = useState(false)
	const [OPV1, setOPV1] = useState(false)
	const [OPV2, setOPV2] = useState(false)
	const [OPV3, setOPV3] = useState(false)
	const [PENTA1, setPENTA1] = useState(false)
	const [PENTA2, setPENTA2] = useState(false)
	const [PENTA3, setPENTA3] = useState(false)
	const [PCV1, setPCV1] = useState(false)
	const [PCV2, setPCV2] = useState(false)
	const [PCV3, setPCV3] = useState(false)
	const [ROTA1, setROTA1] = useState(false)
	const [ROTA2, setROTA2] = useState(false)
	const [ROTA3, setROTA3] = useState(false)
	const [IPV, setIPV] = useState(false)
	const [Measles1, setMeasles1] = useState(false)
	const [Measles2, setMeasles2] = useState(false)
	const [VitaminA1, setVitaminA1] = useState(false)
	const [VitaminA2, setVitaminA2] = useState(false)
	const [yellowFever, setYellowFever] = useState(false)
	const [meningitis, setMenigitis] = useState(false)

	useEffect(() => {
		vaccination_record_for_a_patient?.map((e, index) => {
			if (e.antigen === "BCG") {

				setBCG(true);

			}
			if (e.antigen === "HepB") {
				setHepB(true)
			}
			if (e.antigen === "OPV-0") {
				setOPV0(true)
			}
			if (e.antigen === "OPV-1") {
				setOPV1(true)
			}

			if (e.antigen === "OPV-2") {
				setOPV2(true)
			}

			if (e.antigen === "OPV-3") {
				setOPV3(true)
			}
			if (e.antigen === "OPV-3") {
				setOPV3(true)
			}
			if (e.antigen === "PENTA-1") {
				setPENTA1(true)
			}
			if (e.antigen === "PENTA-2") {
				setPENTA2(true)
			}
			if (e.antigen === "PENTA-3") {
				setPENTA3(true)
			}
			if (e.antigen === "PCV-1") {
				setPCV1(true)
			}
			if (e.antigen === "PCV-2") {
				setPCV2(true)
			}
			if (e.antigen === "PCV-3") {
				setPCV3(true)
			}
			if (e.antigen === "ROTA-1") {
				setROTA1(true)
			}
			if (e.antigen === "ROTA-2") {
				setROTA2(true)
			}
			if (e.antigen === "ROTA-3") {
				setROTA3(true)
			}
			if (e.antigen === "IPV") {
				setIPV(true)
			}
			if (e.antigen === "Measles-1") {
				setMeasles1(true)
			}
			if (e.antigen === "Measles-2") {
				setMeasles2(true)
			}
			if (e.antigen === "Vitamin-A1") {
				setVitaminA1(true)
			}

			if (e.antigen === "Vitamin-A2") {
				setVitaminA2(true)
			}
			if (e.antigen === "Yellow Fever") {
				setYellowFever(true)
			}
			if (e.antigen === "Meningitis") {
				setMenigitis(true)
			}



		})

	}, [])

	console.log(BCG)

	return (
		<>
			<div className='bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1'>
				<h4 className='text-dark-blue text-[16px] font-semibold'>
					Vaccination schedule
				</h4>
			</div>
			<div className='flex justify-between space-x-2 mt-1'>

				<table className='border rounded w-full cursor-default'>
					<thead className=''>
						<tr>
							<th
								scope='col'
								className='text-[10px] font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
							>
								S/N
							</th>

							<th
								scope='col'
								className='text-[10px] font-medium text-gray-900 px-4 py-4 text-left'
							>
								Antigen
							</th>
							<th
								scope='col'
								className='text-[10px] font-medium text-gray-900 py-4 text-left'
							>
								Birth
							</th>
							<th
								scope='col'
								className='text-[10px] font-medium text-gray-900 py-4 text-left'
							>
								6weeks
							</th>
							<th
								scope='col'
								className='text-[10px] font-medium text-gray-900 py-4 text-left'
							>
								10weeks
							</th>

							<th
								scope='col'
								className='text-[10px] font-medium text-gray-900 py-4 text-left'
							>
								14weeks
							</th>    <th
								scope='col'
								className='text-[10px] font-medium text-gray-900 py-4 text-left'
							>
								6months
							</th>
							<th
								scope='col'
								className='text-[10px] font-medium text-gray-900 py-4 text-left'
							>
								9months
							</th>
							<th
								scope='col'
								className='text-[10px] font-medium text-gray-900 py-4 text-left'
							>
								12months
							</th>
							<th
								scope='col'
								className='text-[10px] font-medium text-gray-900 py-4 text-left'
							>
								18months
							</th>
						</tr>
					</thead>

					<tbody>
						<tr className=''>
							<td className='px-4 text-[12px]'>1</td>
							<td className='px-4 py-1 whitespace-nowrap text-[10px] font-medium text-gray-900'>
								<div className='flex'>
									<label >BCG</label>
								</div>
							</td>
							<td>
								<input type="checkbox" name="" id=""

									className="w-[20px] h-[20px]"
									checked={BCG}
								/>
							</td>

						</tr>
						<tr className='bg-gray-300 w-full'>
							<td className='px-4 text-[12px]'>2</td>
							<td className='px-4 py-1 whitespace-nowrap text-[10px] font-medium text-gray-900'>
								<div className='flex'>
									<label >HepB</label>
								</div>
							</td>
							<td>
								<input type="checkbox" name="" id=""
									className="w-[20px] h-[20px]"
									checked={HepB}
								/>
							</td>

						</tr>
						<tr className=''>
							<td className='px-4 text-[12px]'>3</td>
							<td className='px-4 py-1 whitespace-nowrap text-[10px] font-medium text-gray-900'>
								<div className='flex'>
									<label >OPV</label>
								</div>
							</td>
							<td>
								<input type="checkbox" name="" id=""
									className="w-[20px] h-[20px]"
									checked={OPV0}
								/>
							</td>
							<td>
								<input type="checkbox" name="" id=""
									className="w-[20px] h-[20px]"
									checked={OPV1}
								/>
							</td>
							<td>
								<input type="checkbox" name="" id=""
									className="w-[20px] h-[20px]"
									checked={OPV2}
								/>
							</td>
							<td>
								<input type="checkbox" name="" id=""
									className="w-[20px] h-[20px]"
									checked={OPV3}
								/>
							</td>

						</tr>
						<tr className='bg-gray-300'>
							<td className='px-4 text-[12px]'>4</td>
							<td className='px-4 py-1 whitespace-nowrap text-[10px] font-medium text-gray-900'>
								<div className='flex'>
									<label >PENTA</label>
								</div>
							</td>
							<td>

							</td>
							<td>
								<input type="checkbox" name="" id=""
									className="w-[20px] h-[20px]"
									checked={PENTA1}
								/>
							</td>
							<td>
								<input type="checkbox" name="" id=""
									className="w-[20px] h-[20px]"
									checked={PENTA2}
								/>
							</td>
							<td>
								<input type="checkbox" name="" id=""
									checked={PENTA3}
									className="w-[20px] h-[20px]" />

							</td>

						</tr>
						<tr className=''>
							<td className='px-4 text-[12px]'>5</td>
							<td className='px-4 py-1 whitespace-nowrap text-[10px] font-medium text-gray-900'>
								<div className='flex'>
									<label >PCV</label>
								</div>
							</td>
							<td>

							</td>
							<td>
								<input type="checkbox" name="" id=""
									className="w-[20px] h-[20px]"
									checked={PCV1}

								/>
							</td>
							<td>
								<input type="checkbox" name="" id=""
									className="w-[20px] h-[20px]"
									checked={PCV2}

								/>
							</td>
							<td>
								<input type="checkbox" name="" id=""
									className="w-[20px] h-[20px]"
									checked={PCV3}
								/>
							</td>

						</tr>
						<tr className='bg-gray-300'>
							<td className='px-4 text-[12px]'>6</td>
							<td className='px-4 py-1 whitespace-nowrap text-[10px] font-medium text-gray-900'>
								<div className='flex'>
									<label >ROTA</label>
								</div>
							</td>
							<td>

							</td>
							<td>
								<input type="checkbox" name="" id=""
									checked={ROTA1}
									className="w-[20px] h-[20px]" />

							</td>
							<td>
								<input type="checkbox" name="" id=""
									checked={ROTA2}
									className="w-[20px] h-[20px]" />
							</td>
							<td>
								<input type="checkbox" name="" id=""
									checked={ROTA3}
									className="w-[20px] h-[20px]" />
							</td>

						</tr>
						<tr className=''>
							<td className='px-4 text-[12px]'>7</td>
							<td className='px-4 py-1 whitespace-nowrap text-[10px] font-medium text-gray-900'>
								<div className='flex'>
									<label >IPV</label>
								</div>
							</td>
							<td>

							</td>
							<td>

							</td>
							<td>

							</td>
							<td>
								<input type="checkbox" name="" id=""
									checked={IPV}
									className="w-[20px] h-[20px]"
								/>
							</td>

						</tr>
						<tr className='bg-gray-300'>
							<td className='px-4 text-[12px]'>8</td>
							<td className='px-4 py-1 whitespace-nowrap text-[10px] font-medium text-gray-900'>
								<div className='flex'>
									<label >Measles</label>
								</div>
							</td>
							<td>

							</td>
							<td>

							</td>
							<td>

							</td>
							<td>

							</td>
							<td>

							</td>
							<td>
								<input type="checkbox" name="" id=""
									checked={Measles1}
									className="w-[20px] h-[20px]" />

							</td>
							<td>

							</td>
							<td>
								<input type="checkbox" name="" id=""
									checked={Measles2}
									className="w-[20px] h-[20px]" />

							</td>

						</tr>
						<tr className=''>
							<td className='px-4 text-[12px]'>9</td>
							<td className='px-4 py-1 whitespace-nowrap text-[10px] font-medium text-gray-900'>
								<div className='flex'>
									<label >Vitamin A</label>
								</div>
							</td>
							<td>

							</td>
							<td>

							</td>
							<td>

							</td>

							<td>

							</td>
							<td>
								<input type="checkbox" name="" id=""
									checked={VitaminA1}
									className="w-[20px] h-[20px]" />

							</td>
							<td>

							</td>
							<td>
								<input type="checkbox" name="" id=""
									className="w-[20px] h-[20px]"
									checked={VitaminA2}
								/>
							</td>

						</tr>
						<tr className='bg-gray-300'>
							<td className='px-4 text-[12px]'>10</td>
							<td className='px-4 py-1 whitespace-nowrap text-[10px] font-medium text-gray-900'>
								<div className='flex'>
									<label >Yellow fever</label>
								</div>
							</td>
							<td>

							</td>
							<td>

							</td>
							<td>

							</td>

							<td>

							</td>
							<td>

							</td>
							<td>
								<input type="checkbox" name="" id=""
									checked={yellowFever}
									className="w-[20px] h-[20px]" />
							</td>
							<td>

							</td>

						</tr>
						<tr className=''>
							<td className='px-4 text-[12px]'>11</td>
							<td className='px-4 py-1 whitespace-nowrap text-[10px] font-medium text-gray-900'>
								<div className='flex'>
									<label >Meningitis</label>
								</div>
							</td>
							<td>

							</td>
							<td>

							</td>
							<td>

							</td>

							<td>

							</td>
							<td>

							</td>
							<td>

							</td>
							<td>
								<input type="checkbox" name="" id=""
									checked={meningitis}
									className="w-[20px] h-[20px]" />
							</td>

						</tr>

					</tbody>

				</table>

			</div>
		</>
	)
}

export default VaccinationScheduleTable