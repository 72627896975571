import React, { useState, useEffect } from 'react'
import { AiOutlineEye } from 'react-icons/ai'
import { useSelector, useDispatch } from 'react-redux'
import { useStateContext } from '../../../state/context'
import { getAPatientVisitRecord } from '../../../state/actions/nurse.actions'
import { setNursesDeleteDetails } from '../../../state/actions/actions'

const VisitRecordsTable = () => {
    const { opdState, setOpdState } = useStateContext()
    const dispatch = useDispatch()
    const { modals, setModals } = useStateContext()
    const { set_patient_id } = useSelector((state) => state.root)
    const { all_patient_visit_records } = useSelector((state) => state.nurse)
    useEffect(() => {
        dispatch(getAPatientVisitRecord({ id: set_patient_id?.id }))
    }, [modals.showAddPatientVisitRecordModal, modals.showDeleteVisitRecordModal])


    return (
        <div className='flex flex-col'>
            <div className='flex justify-end space-x-2'>
                <button
                    type="button"
                    className=" px-6 py-2.5 bg-sky-600 text-white w-[100px] mb-2 font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                    onClick={() => (
                        setModals({
                            ...modals,
                            showAddPatientVisitRecordModal: !modals.showAddPatientVisitRecordModal

                        })
                    )}
                >
                    Add +
                </button>
                <button
                    type="button"
                    className=" px-6 py-2.5 bg-dark-green text-white w-[100px] mb-2 font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                    onClick={() => (
                        setOpdState({
                            ...opdState,
                            showPatientAntenatalRecords: false

                        })
                    )}
                >
                    Back
                </button>

            </div>
            <div>

                <div className='flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1'>

                    <h4 className='text-dark-blue text-[16px] font-semibold'>
                        Visit records - {set_patient_id?.first_name} {set_patient_id?.last_name} [{set_patient_id?.patient_number}]
                    </h4>
                </div>


                <table className='border rounded w-full cursor-default'>

                    <thead className=''>
                        <tr>
                            <th
                                scope='col'
                                className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                            >
                                S/N
                            </th>

                            <th
                                scope='col'
                                className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                            >
                                Fungus Height
                            </th>
                            <th
                                scope='col'
                                className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
                            >
                                Foetal Heart
                            </th>


                            <th
                                scope='col'
                                className='text-sm font-medium text-gray-900 py-4 text-left'
                            >
                                Urine
                            </th>
                            <th
                                scope='col'
                                className='text-sm font-medium text-gray-900 py-4 text-left'
                            >
                                Blood pressure
                            </th>
                            <th
                                scope='col'
                                className='text-sm font-medium text-gray-900 py-4 text-left'
                            >
                                Weight
                            </th>
                            <th
                                scope='col'
                                className='text-sm font-medium text-gray-900 py-4 text-left'
                            >
                                Return Date
                            </th>
                            <th
                                scope='col'
                                className='text-sm font-medium text-gray-900 py-4 text-left'
                            >
                                Actions
                            </th>

                        </tr>
                    </thead>


                    <tbody>

                        {all_patient_visit_records?.map((item, index) => (
                            <tr
                                className='relative'
                                key={index}

                            >
                                <td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
                                    {index + 1}
                                </td>
                                <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                    {item.height_fundus}          </td>
                                <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                    {item.foetal_heart}
                                </td>
                                <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                    {item.urine}
                                </td>
                                <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                    {item.blood_pressure} mmHg
                                </td>
                                <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                    {item.weight}
                                </td>
                                <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                    {item.return_date}
                                </td>


                                <td className=' text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                    <div className='flex space-x-4 relative '>
                                        <AiOutlineEye
                                            color='blue'
                                            className='cursor-pointer'

                                            size={35}
                                            onClick={() => {
                                                const data = {
                                                    id: item.id,
                                                    height_fundus: item.height_fundus,
                                                    weight: item.weight,
                                                    position_presentation: item.position_and_presentation,
                                                    pcv: item.pcv,
                                                    relation_presenting: item.relation_presenting_part_of_brim,
                                                    odema: item.odema,
                                                    foetal_heart: item.foetal_heart,
                                                    remark: item.remark,
                                                    urine: item.urine,
                                                    blood_pressure: item.blood_pressure,
                                                    return_date: item.return_date
                                                }
                                                dispatch(setNursesDeleteDetails({ data }))
                                                setModals({
                                                    ...modals,
                                                    showViewVisitRecordModal:
                                                        !modals.showViewVisitRecordModal,
                                                })
                                            }}

                                        />
                                       
                                    </div>
                                </td>
                            </tr>
                        ))}


                    </tbody>

                </table>
            </div>
        </div>
    )
}

export default VisitRecordsTable