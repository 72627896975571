import React, { Fragment, useEffect, useState, useRef, useMemo } from 'react'
import { toast } from 'react-toastify'
import { FaSave } from 'react-icons/fa'
import { Editor } from '@tinymce/tinymce-react'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../state/context'
import { DoctorPatientDiagnosisHistoryTable, DoctorPatientPerformanceHistoryTable, DoctorPatientPrescriptionsTable, DoctorPatientRadiologyTestsTable, DoctorPatientTestsTable } from '../../components'
import { createDiagnosis, getPatientDiagnosis, getPatientFileUploads, getPatientLabTests, getPatientOtherPrescriptions, getPatientPrescriptions, getPatientRadioTests, resetNewDiagnosis, updateDiagnosis } from '../../state/actions/doctor.actions'
import DoctorPatientFilesTable from '../../components/tables/DoctorPatientFilesTable'


const DentistManagePatient = () => {
    const dispatch = useDispatch()

    const editorRef = useRef(null)

    const { appState, setAppState, modals, setModals } = useStateContext()

    const { patient_update } = useSelector(state => state.root)
    const {
        patient_diagnoses, new_diagnosis,
        patient_prescriptions, patient_other_prescriptions,
        patient_laboratory_tests, patient_radiology_tests,
        patient_files, patient_transfer, labtest_update,
        radiologytest_update,
    } = useSelector(state => state.doctor)

    const [activeLink, setActiveLink] = useState('Diagnosis')
    const [formData, setFormData] = useState({ diagnosis_name: '', description: '' })

    useEffect(() => {
        if (patient_transfer?.id) {
            setAppState({ ...appState, showDoctorManagePatient: false })
        }
    }, [patient_transfer])

    useEffect(() => {
        dispatch(getPatientDiagnosis({ params: { id: patient_update?.pkid } }))

        if (appState.showDoctorPerformNewDiagnosis) {
            dispatch(getPatientDiagnosis({ params: { id: patient_update?.pkid } }))
        }

        // return () => {
        //     dispatch(resetNewDiagnosis())
        // }
    }, [patient_update, appState.showDoctorPerformNewDiagnosis])

    useEffect(() => {
        setFormData({
            ...formData,
            diagnosis_name: new_diagnosis?.diagnosis_name,
            description: new_diagnosis?.description
        })

        if (new_diagnosis?.id) {
            dispatch(getPatientPrescriptions({ params: { diagnosis: new_diagnosis?.pkid, patient: patient_update?.pkid } }))
            dispatch(getPatientOtherPrescriptions({ params: { diagnosis: new_diagnosis?.pkid, patient: patient_update?.pkid } }))
            dispatch(getPatientLabTests({ params: { diagnosis: new_diagnosis?.pkid, patient: patient_update?.pkid } }))
            dispatch(getPatientRadioTests({ params: { diagnosis: new_diagnosis?.pkid, patient: patient_update?.pkid } }))
            dispatch(getPatientFileUploads({ params: { diagnosis: new_diagnosis?.pkid, patient: patient_update?.pkid } }))
        }

        if (labtest_update?.id) {
            dispatch(getPatientLabTests({ params: { diagnosis: new_diagnosis?.pkid, patient: patient_update?.pkid } }))
        }
        if (radiologytest_update?.id) {
            dispatch(getPatientRadioTests({ params: { diagnosis: new_diagnosis?.pkid, patient: patient_update?.pkid } }))
        }
    }, [new_diagnosis, labtest_update, radiologytest_update])

    const handleChange = (e, name) => {
        setFormData({ ...formData, [name]: e.target.value })
    }

    const handleDiagnosisSubmit = (e) => {
        e.preventDefault()

        dispatch(createDiagnosis({ formData: { ...formData, patient: patient_update?.pkid }, toast }))
    }

    const handleDiagnosisUpdate = (e) => {
        e.preventDefault()

        dispatch(updateDiagnosis({ formData: { ...formData, patient: patient_update?.pkid, id: new_diagnosis?.id }, toast }))
    }

    return (
        <div>
            <div className="flex justify-between items-start gap-2">
                <div className="flex-[0.3]">
                    <div className="flex flex-col border rounded space-y-2">
                        <img
                            src={`${process.env.REACT_APP_API_ROOT}${patient_update?.profile_photo}`}
                            alt=""
                            className='rounded-t h-[20%]'
                        />

                        <div className="flex flex-col p-2 border- border-gray-400">
                            <p className='text-[14px]'>{patient_update?.details?.last_name} {patient_update?.details?.first_name}</p>

                            <div className="grid grid-cols-2">
                                <p className='text-[14px] text-gray-500'>Gender:</p>
                                <p className='text-[14px]'>{patient_update?.gender}</p>
                                <p className='text-[14px] text-gray-500'>Age:</p>
                                <p className='text-[14px]'>{parseInt(new Date().getFullYear()) - parseInt(patient_update?.date_of_birth?.split('-')[0])}</p>
                                {/* <p className='text-[14px] text-gray-500'>Height:</p>
                                <p className='text-[14px]'>6' 6</p>
                                <p className='text-[14px] text-gray-500'>Weight:</p>
                            <p className='text-[14px]'>66kg</p> */}
                            </div>
                        </div>

                        {/* <div className="flex flex-col p-2 border-b border-gray-400">
                            <p className='text-[14px]'>Allergies</p>

                            <div className="grid grid-cols-2">
                                <p className='text-[14px] text-gray-500'>Pencillin:</p>
                                <p className='text-[14px] text-orange-400'>High</p>
                                <p className='text-[14px] text-gray-500'>Morphine:</p>
                                <p className='text-[14px] text-sky-300'>Moderate</p>
                            </div>
                        </div>
                        <div className="flex flex-col p-2">
                            <p className='text-[14px]'>Notes</p>
                            <p className='text-[12px]'>Knee pain, vommitting, and headache during last treatment</p>
                        </div> */}
                    </div>
                </div>

                <div className="flex-[0.7]">
                    <div className="buttons">
                        {!appState.showDoctorPerformNewDiagnosis && (
                            <button
                                type="button"
                                className="z-[2] inline-block px-6 py-2 bg-white text-gray-600 border-t border-l border-r border-b border-b-white font-bold text-xs leading-tight uppercase rounded-t focus:outline-none focus:ring-0 transition duration-500 ease-in-out"
                                onClick={() => {

                                }}
                            >
                                History
                            </button>
                        )}
                        {appState.showDoctorPerformNewDiagnosis && (
                            <div className="flex space-x-5">
                                <button
                                    type="button"
                                    className={`${activeLink === 'Diagnosis' ? 'text-gray-800' : 'text-gray-400'} z-[2] inline-block px-6 py-2 bg-white  border-t border-l border-r border-b border-b-white font-bold text-xs leading-tight uppercase rounded-t focus:outline-none focus:ring-0 transition duration-500 ease-in-out`}
                                    onClick={(e) => {
                                        setActiveLink(e.target.textContent)
                                    }}
                                >
                                    Diagnosis
                                </button>
                                <button
                                    type="button"
                                    className={`${activeLink === 'Prescriptions' ? 'text-gray-800' : 'text-gray-400'} z-[2] inline-block px-6 py-2 bg-white  border-t border-l border-r border-b border-b-white font-bold text-xs leading-tight uppercase rounded-t focus:outline-none focus:ring-0 transition duration-500 ease-in-out`}
                                    disabled={new_diagnosis === null}
                                    onClick={(e) => {
                                        setActiveLink(e.target.textContent)
                                    }}
                                >
                                    Prescriptions
                                </button>
                                <button
                                    type="button"
                                    className={`${activeLink === 'Tests' ? 'text-gray-800' : 'text-gray-400'} z-[2] inline-block px-6 py-2 bg-white  border-t border-l border-r border-b border-b-white font-bold text-xs leading-tight uppercase rounded-t focus:outline-none focus:ring-0 transition duration-500 ease-in-out`}
                                    disabled={new_diagnosis === null}
                                    onClick={(e) => {
                                        setActiveLink(e.target.textContent)
                                    }}
                                >
                                    Tests
                                </button>
                                <button
                                    type="button"
                                    className={`${activeLink === 'Imaging' ? 'text-gray-800' : 'text-gray-400'} z-[2] inline-block px-6 py-2 bg-white  border-t border-l border-r border-b border-b-white font-bold text-xs leading-tight uppercase rounded-t focus:outline-none focus:ring-0 transition duration-500 ease-in-out`}
                                    disabled={new_diagnosis === null}
                                    onClick={(e) => {
                                        setActiveLink(e.target.textContent)
                                    }}
                                >
                                    Imaging
                                </button>
                                <button
                                    type="button"
                                    className={`${activeLink === 'Files' ? 'text-gray-800' : 'text-gray-400'} z-[2] inline-block px-6 py-2 bg-white  border-t border-l border-r border-b border-b-white font-bold text-xs leading-tight uppercase rounded-t focus:outline-none focus:ring-0 transition duration-500 ease-in-out`}
                                    disabled={new_diagnosis === null}
                                    onClick={(e) => {
                                        setActiveLink(e.target.textContent)
                                    }}
                                >
                                    Files
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="w-full border border-r-gray-600 border-b-gray-600 border-l-gray-600 rounded-tr rounded-br rounded-bl p-2 flex flex-col">
                        {!appState.showDoctorPerformNewDiagnosis && (
                            <Fragment>
                                <DoctorPatientDiagnosisHistoryTable
                                    title={'Diagnosis'}
                                    data={patient_diagnoses}
                                />

                                <DoctorPatientPerformanceHistoryTable
                                    title={'Initial History'}
                                    data={patient_diagnoses?.slice(0, 5)}
                                />
                            </Fragment>
                        )}

                        {appState.showDoctorPerformNewDiagnosis && (
                            <Fragment>
                                {activeLink === 'Diagnosis' && (
                                    <div className='space-y-2'>
                                        <div className="flex justify-end">
                                            {!new_diagnosis && (
                                                <button
                                                    type="button"
                                                    className="z-[2] inline-block px-2 py-2 bg-dark-yellow text-white font-bold text-xs leading-tight uppercase rounded focus:outline-none focus:ring-0 transition duration-500 ease-in-out"
                                                    onClick={handleDiagnosisSubmit}
                                                >
                                                    <span className="flex justify-between items-center">Save <FaSave size={15} className='ml-1' /></span>
                                                </button>
                                            )}

                                            {/* {new_diagnosis && (
                                                <button
                                                    type="button"
                                                    className="z-[2] inline-block px-2 py-2 bg-sky-500 text-white font-bold text-xs leading-tight uppercase rounded focus:outline-none focus:ring-0 transition duration-500 ease-in-out"
                                                    onClick={handleDiagnosisUpdate}
                                                >
                                                    <span className="flex justify-between items-center">Update <FaSave size={15} className='ml-1' /></span>
                                                </button>
                                            )} */}
                                        </div>
                                        <div className="space-y-4">
                                            <div>
                                                <input
                                                    type="text"
                                                    name="diagnosis_name"
                                                    placeholder="Enter Diagnosis Title..."
                                                    className="border-b border-sky-400 w-[300px] text-[12px] px-4 mt-2 hover:outline-none hover:border-b-2 focus:outline-none focus:border-sky-500 placeholder:text-[18px] transition-all ease-in-out duration-300"
                                                    value={formData.diagnosis_name}
                                                    onChange={(e) => handleChange(e, e.target.name)}
                                                />
                                            </div>
                                            <Editor
                                                apiKey={process.env.REACT_APP_TEXTEDITOR}
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                initialValue={`${new_diagnosis?.description ? new_diagnosis?.description : '<p>Start typing your diagnosis here...</p>'}`}
                                                init={{
                                                    height: 350,
                                                    menubar: false
                                                }}
                                                onEditorChange={(newValue, editor) => {
                                                    setFormData({ ...formData, description: editor.getContent() })
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}

                                {activeLink === 'Prescriptions' && (
                                    <div>
                                        <div className="flex justify-end space-x-4">
                                            <button
                                                type="button"
                                                className="z-[2] inline-block px-6 py-2 bg-sky-400 text-white font-bold text-xs leading-tight uppercase rounded focus:outline-none focus:ring-0 transition duration-500 ease-in-out"
                                                onClick={() => {
                                                    setModals({ ...modals, showDoctorAddOtherPrescriptionModal: !modals.showDoctorAddOtherPrescriptionModal })
                                                }}
                                            >
                                                Add Other
                                            </button>
                                            <button
                                                type="button"
                                                className="z-[2] inline-block px-6 py-2 bg-dark-blue text-white font-bold text-xs leading-tight uppercase rounded focus:outline-none focus:ring-0 transition duration-500 ease-in-out"
                                                onClick={() => {
                                                    setModals({ ...modals, showDoctorAddPrescriptionModal: !modals.showDoctorAddPrescriptionModal })
                                                }}
                                            >
                                                Add
                                            </button>
                                        </div>

                                        <DoctorPatientPrescriptionsTable
                                            title={'Prescriptions'}
                                            data={patient_prescriptions}
                                        />
                                        <DoctorPatientPrescriptionsTable
                                            title={'Other Prescriptions'}
                                            data={patient_other_prescriptions}
                                        />
                                    </div>
                                )}

                                {activeLink === 'Tests' && (
                                    <div>
                                        <div className="flex justify-end space-x-5">
                                            <button
                                                type="button"
                                                className="z-[2] inline-block px-6 py-2 bg-sky-400 text-white font-bold text-xs leading-tight uppercase rounded focus:outline-none focus:ring-0 transition duration-500 ease-in-out"
                                                onClick={() => {
                                                    setModals({ ...modals, showDoctorAddOtherTestModal: !modals.showDoctorAddOtherTestModal })
                                                }}
                                            >
                                                Add Other
                                            </button>
                                            <button
                                                type="button"
                                                className="z-[2] inline-block px-6 py-2 bg-dark-blue text-white font-bold text-xs leading-tight uppercase rounded focus:outline-none focus:ring-0 transition duration-500 ease-in-out"
                                                onClick={() => {
                                                    setModals({ ...modals, showDoctorAddTestModal: !modals.showDoctorAddTestModal })
                                                }}
                                            >
                                                Add
                                            </button>
                                        </div>

                                        <DoctorPatientTestsTable
                                            title={'Laboratory Tests'}
                                            data={patient_laboratory_tests?.filter((test) => test.is_local == true)}
                                        />
                                        <DoctorPatientTestsTable
                                            title={'Other Laboratory Tests'}
                                            data={patient_laboratory_tests?.filter((test) => test.is_local == false)}
                                        />
                                    </div>
                                )}

                                {activeLink === 'Imaging' && (
                                    <div>
                                        <div className="flex justify-end space-x-5">
                                            <button
                                                type="button"
                                                className="z-[2] inline-block px-6 py-2 bg-sky-400 text-white font-bold text-xs leading-tight uppercase rounded focus:outline-none focus:ring-0 transition duration-500 ease-in-out"
                                                onClick={() => {
                                                    setModals({ ...modals, showDoctorAddOtherRadiologyTestModal: !modals.showDoctorAddOtherRadiologyTestModal })
                                                }}
                                            >
                                                Add Other
                                            </button>
                                            <button
                                                type="button"
                                                className="z-[2] inline-block px-6 py-2 bg-dark-blue text-white font-bold text-xs leading-tight uppercase rounded focus:outline-none focus:ring-0 transition duration-500 ease-in-out"
                                                onClick={() => {
                                                    setModals({ ...modals, showDoctorAddRadiologyTestModal: !modals.showDoctorAddRadiologyTestModal })
                                                }}
                                            >
                                                Add
                                            </button>
                                        </div>

                                        <DoctorPatientRadiologyTestsTable
                                            title={'Radiology Tests'}
                                            data={patient_radiology_tests?.filter((test) => test.is_local == true)}
                                        />
                                        <DoctorPatientRadiologyTestsTable
                                            title={'Other Radiology Tests'}
                                            data={patient_radiology_tests?.filter((test) => test.is_local == false)}
                                        />
                                    </div>
                                )}

                                {activeLink === 'Files' && (
                                    <div>
                                        <div className="flex justify-end space-x-5">
                                            <button
                                                type="button"
                                                className="z-[2] inline-block px-6 py-2 bg-dark-blue text-white font-bold text-xs leading-tight uppercase rounded focus:outline-none focus:ring-0 transition duration-500 ease-in-out"
                                                onClick={() => {
                                                    setModals({ ...modals, showDoctorUploadPatientFileModal: !modals.showDoctorUploadPatientFileModal })
                                                }}
                                            >
                                                Upload
                                            </button>
                                        </div>

                                        <DoctorPatientFilesTable
                                            title={'File Uploads'}
                                            data={patient_files}
                                        />
                                    </div>
                                )}
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DentistManagePatient