/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react"
import { BiUserPlus } from "react-icons/bi"
import { BsPersonCheck } from "react-icons/bs"
import { FaFileInvoice, FaUserCircle } from "react-icons/fa"
import { MdOutlineMoreTime } from "react-icons/md"
import { AiFillEye, AiOutlineCaretDown } from "react-icons/ai"
import { getAllInvoice } from "../../state/actions/records.actions"
import { useSelector, useDispatch } from "react-redux"

import { PageTitle } from "../../components"
import { useStateContext } from "../../state/context"
import { setViewInoice } from "../../state/actions/actions"
import Pagination from "../../components/utils/Pagination"


const RecordsInvoices = (props) => {
	const dispatch = useDispatch()
	
	const { modals, setModals } = useStateContext()

	const { all_invoices } = useSelector((state) => state.records)

	const [currentData, setCurrentData] = useState([])

	useEffect(() => {
		dispatch(getAllInvoice())
	}, [])


	return (
		<div>
			<PageTitle
				type={'records-invoices'}
			/>

			<div className="space-y-5">
				<div className='flex justify-between items-center w-full px-1'>
					<div
						className="flex flex-col w-full cursor-pointer"
					>
						<div className="py-2">
							<div className="flex justify-start items-center space-x-4 bg-gray-200 p-1">
								<h4 className='text-dark-blue text-[14px] font-bold'>Invoices</h4>
								<FaUserCircle
									className='text-dark-blue'
									size={20}
								/>
							</div>
							<table className="border rounded w-full cursor-default">
								<thead className="">
									<tr>
										<th scope="col" className="text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left">
											S/N
										</th>
										<th scope="col" className="text-sm font-medium text-gray-900 pr-[4em] py-4 text-left">
											Patient ID
										</th>
										<th scope="col" className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left">
											Full name
										</th>
										<th scope="col" className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left">
											Date generated
										</th>
										<th scope="col" className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left">
											Invoice Number
										</th>
										<th scope="col" className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left">
											Status
										</th>
										<th scope="col" className="text-sm font-medium text-gray-900 py-4 text-left">
											Actions
										</th>
									</tr>
								</thead>
								<tbody>
									{currentData?.map((invoice, index) => (
										<tr className="" key={index}>
											<td className="text-[12px] text-gray-900 font-light py-2 pl-8 whitespace-nowrap">
												{index + 1}
											</td>
											<td className="py-2 whitespace-nowrap text-[12px] font-light text-gray-900">
												{invoice.patient_number}
											</td>
											<td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
												{invoice.patient.first_name} {invoice.patient.last_name}
											</td>
											<td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
												{invoice.date}
											</td>
											<td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
												{invoice.invoice_number}
											</td>
											<td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
												{invoice.is_paid ? "Paid" : "Not Paid"}
											</td>
											<td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
												<div className="relative">
													<button
														type="button"
														className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
														onClick={() => {
															const data = {
																invoice_number: invoice.invoice_number,
																patient_number: invoice.patient_number,
																patient: { first_name: invoice.patient.first_name, last_name: invoice.patient.last_name },
																generated_by: { first_name: invoice.generated_by.first_name, last_name: invoice.generated_by.last_name },
																purpose: invoice.billing_type,
																amount: invoice.amount

															}
															dispatch(setViewInoice({ data }))
															setModals({ ...modals, showViewInvoiceModal: !modals.showViewInvoiceModal })
														}}
													>
														View
													</button>
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</table>

							<Pagination
								data={all_invoices}
								currentData={currentData}
								setCurrentData={setCurrentData}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default RecordsInvoices
