import React, { useEffect } from 'react'
import { AiOutlineCaretDown } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { useStateContext } from '../../../state/context'
import { getAllPatientPerformanceData } from '../../../state/actions/nurse.actions'
import { setPatientID } from '../../../state/actions/actions'
import { useState } from 'react'

const AdmittedPatientInitialPerformanceTable = (props) => {
    const { modals, setModals } = useStateContext()
    const { opdState, setOpdState } = useStateContext()
    const [showDropDown, setShowDropdown] = useState(false)
    const dispatch = useDispatch()
    const { set_patient_id, set_manage_admitted_patient } = useSelector((state) => state.root)
    const { all_patient_performanceData } = useSelector((state) => state.nurse)


    useEffect(() => {
        const patient_id = set_patient_id

        dispatch(getAllPatientPerformanceData({ patient_id }))
    }, [set_patient_id, modals.showNurseAddPerformanceDataModal])


    return (
        <div>
            <div className='flex justify-end  space-x-2 my-2'>

                <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                    onClick={() => {
                        setModals({ ...modals, showNurseAddPerformanceDataModal: !modals.showNurseAddPerformanceDataModal })
                    }}
                >
                    Add Performance data
                </button>
                <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                    onClick={() => {
                        setShowDropdown(!showDropDown)
                    }}
                >
                    <p className='flex space-x-2'><p>Performance Chart </p> <span><AiOutlineCaretDown /></span></p>

                </button>
            </div>
            {showDropDown && (
                <div className='relative'>
                    <div className='absolute items-center px-auto right-0 z-[2] h-[250px] p-1 bg-white shadow-lg border border-gray-200 rounded w-[190px]'>

                        <button className='text-sm font-light mb-2  border-b w-[180px] py-1 hover:bg-blue-200 rounded-sm'
                            onClick={() => {
                                setModals({
                                    ...modals,
                                    showVitalScienceChartModal:
                                        !modals.showVitalScienceChartModal,
                                })
                                setShowDropdown(false)
                            }

                            }
                        >Vital Science chart</button>
                        <button className='text-sm font-light mb-2  border-b w-[180px] py-1 hover:bg-blue-200 rounded-sm'
                            onClick={() => {
                                setModals({
                                    ...modals,
                                    showTemperatureChartModal:
                                        !modals.showTemperatureChartModal,
                                })
                                setShowDropdown(false)
                            }

                            }
                        >Temperature chart</button>
                        <button className='text-sm font-light mb-2  border-b w-[180px] py-1 hover:bg-blue-200 rounded-sm'
                            onClick={() => {
                                setModals({
                                    ...modals,
                                    showPulseChartModal:
                                        !modals.showPulseChartModal,
                                })
                                setShowDropdown(false)
                            }

                            }
                        >Pulse chart</button>
                        <button className='text-sm font-light mb-2  border-b w-[180px] py-1 hover:bg-blue-200 rounded-sm'
                            onClick={() => {
                                setModals({
                                    ...modals,
                                    showBloodPressureChartModal:
                                        !modals.showBloodPressureChartModal,
                                })
                                setShowDropdown(false)
                            }

                            }
                        >Blood pressure chart</button>

                        <button className='text-sm font-light mb-2  border-b w-[180px] py-1 hover:bg-blue-200 rounded-sm'
                            onClick={() => {
                                setModals({
                                    ...modals,
                                    showRespiratoryChartModal:
                                        !modals.showRespiratoryChartModal,
                                })
                                setShowDropdown(false)
                            }

                            }
                        >Respiratory chart</button>



                    </div>
                </div>
            )}

            <div className='flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1'>
                <h4 className='text-dark-blue text-[16px] font-semibold'>
                    Initial Performance - {set_manage_admitted_patient?.first_name} {set_manage_admitted_patient?.last_name} [{set_manage_admitted_patient?.patient_number}]
                </h4>


            </div>
            <table className='border rounded w-full cursor-default'>
                <thead className=''>
                    <tr>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                        >
                            S/N
                        </th>

                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                        >
                            Temperature
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                        >
                            Pulse
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                        >
                            Respirations
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
                        >
                            Bp(sys/dia)

                        </th>

                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
                        >
                            Period

                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
                        >
                            Date

                        </th>

                    </tr>
                </thead>


                <tbody>

                    {all_patient_performanceData?.map((item, index) => (
                        <tr
                            className='relative'
                            key={index}
                        >
                            <td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
                                {index + 1}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.temperature}    </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.pulse}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.respiration}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.blood_pressure_sys} / {item.blood_pressure_dia} mmHg
                            </td>

                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.period}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.date}
                            </td>



                            <td className=' text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                <div className='flex space-x-4 relative '>


                                </div>
                            </td>
                        </tr>
                    ))}


                </tbody>

            </table>
        </div>
    )
}

export default AdmittedPatientInitialPerformanceTable