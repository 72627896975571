import { createAsyncThunk } from '@reduxjs/toolkit'

import {
    checkAdminPayStackPaymentStatus, checkFlutterWavePaymentStatus, checkPayStackPaymentStatus, fetchLaboratoryInvoices, fetchPharmacyInvoices,
    fetchRadiologyInvoices, fetchRecordsInvoices, generateAdminPayStackConfig, generateFlutterWaveConfig, generatePayStackConfig, patchLaboratoryInvoice,
    patchMultipleinvoices, patchPharmacyInvoice, patchRadiologyInvoice, patchRecordsInvoice, patchMultiplesInvoiceViaOther, patchMultipleInvoiceTypes, generateSquadCoConfig, checkSquadCoPaymentStatus
} from '../routes/collection.routes'


export const getLaboratoryInvoices = createAsyncThunk(
    'collection/getLaboratoryInvoices',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchLaboratoryInvoices(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getPharmacyInvoices = createAsyncThunk(
    'collection/getPharmacyInvoices',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPharmacyInvoices(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getRadiologyInvoices = createAsyncThunk(
    'collection/getRadiologyInvoices',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRadiologyInvoices(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const getRecordsInvoices = createAsyncThunk(
    'collection/getRecordsInvoices',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchRecordsInvoices(token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const setCurrentLabInvoice = createAsyncThunk(
    'collection/setCurrentLabInvoice',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const createPayStackConfig = createAsyncThunk(
    'collection/createPayStackConfig',
    async ({ formData }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generatePayStackConfig(formData, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const createSquadCoConfig = createAsyncThunk(
    'collection/createSquadCoConfig',
    async ({ formData }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateSquadCoConfig(formData, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const createFlutterWaveConfig = createAsyncThunk(
    'collection/createFlutterWaveConfig',
    async ({ formData }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateFlutterWaveConfig(formData, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const createAdminPayStackConfig = createAsyncThunk(
    'collection/createAdminPayStackConfig',
    async ({ formData }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateAdminPayStackConfig(formData, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const verifyAdminPayStackPayment = createAsyncThunk(
    'collection/verifyAdminPayStackPayment',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await checkAdminPayStackPaymentStatus(params, token)

            return data?.response?.data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const verifyPayStackPayment = createAsyncThunk(
    'collection/verifyPayStackPayment',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await checkPayStackPaymentStatus(params, token)

            return data?.response?.data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const verifySquadCoPayment = createAsyncThunk(
    'collection/verifySquadCoPayment',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await checkSquadCoPaymentStatus(params, token)

            return data?.response?.data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const verifyFlutterWavePayment = createAsyncThunk(
    'collection/verifyFlutterWavePayment',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await checkFlutterWavePaymentStatus(params, token)

            return data.data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const updateLabInvoice = createAsyncThunk(
    'collection/updateLabInvoice',
    async ({ formData, toast, navigate }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchLaboratoryInvoice(formData, token)

            toast.success('Invoice updated successfully')
            navigate('/dashboard')

            localStorage.removeItem('payment__invoice')
            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const updateLabInvoiceViaPOSTransaction = createAsyncThunk(
    'collection/updateLabInvoiceViaPOSTransaction',
    async ({ formData, toast, dispatch, setCurrentLabInvoice, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchLaboratoryInvoice(formData, token)

            toast.success('POS transaction processed successfully.')
            dispatch(setCurrentLabInvoice({ data: null }))
            setModals({ ...modals, showCollectionsLabPaymentModal: !modals.showCollectionsLabPaymentModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const updateLabInvoiceViaCash = createAsyncThunk(
    'collection/updateLabInvoiceViaCash',
    async ({ formData, toast, dispatch, setCurrentLabInvoice, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchLaboratoryInvoice(formData, token)

            toast.success('Cash transaction processed successfully.')
            dispatch(setCurrentLabInvoice({ data: null }))
            setModals({ ...modals, showCollectionsLabPaymentModal: !modals.showCollectionsLabPaymentModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const setCurrentPharmacyInvoice = createAsyncThunk(
    'collection/setCurrentPharmacyInvoice',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const updatePharmacyInvoice = createAsyncThunk(
    'collection/updatePharmacyInvoice',
    async ({ formData, toast, navigate }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchPharmacyInvoice(formData, token)

            toast.success('Invoice updated successfully')
            navigate('/dashboard')
            localStorage.removeItem('payment__invoice')
            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const updatePharmacyInvoiceViaPOSTransaction = createAsyncThunk(
    'collection/updatePharmacyInvoiceViaPOSTransaction',
    async ({ formData, toast, dispatch, setCurrentPharmacyInvoice, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchPharmacyInvoice(formData, token)

            toast.success('POS transaction processed successfully.')
            dispatch(setCurrentPharmacyInvoice({ data: null }))
            setModals({ ...modals, showCollectionsPharmacyPaymentModal: !modals.showCollectionsPharmacyPaymentModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const updateMultipleInvoicesViaOTHERTransaction = createAsyncThunk(
    'collection/updateMultipleInvoicesViaOTHERTransaction',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchMultiplesInvoiceViaOther(formData, token)

            toast.success('Invoices processed successfully.')
            setModals({ ...modals, showCollectionsMultiplePaymentModal: !modals.showCollectionsMultiplePaymentModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const updateMultipleInvoicesTypes = createAsyncThunk(
    'collection/updateMultipleInvoicesTypes',
    async ({ formData, toast, navigate }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchMultipleInvoiceTypes(formData, token)

            toast.success('Invoices processed successfully.')
            navigate('/dashboard')

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating multiple invoice types')
            return rejectWithValue(null)
        }
    }
)


export const updateMultipleInvoicesTypesViaOTHERTransaction = createAsyncThunk(
    'collection/updateMultipleInvoicesTypesViaOTHERTransaction',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchMultipleInvoiceTypes(formData, token)

            toast.success('Invoices processed successfully.')
            setModals({ ...modals, showCollectionsMultipleInvoiceTypePaymentModal: !modals.showCollectionsMultipleInvoiceTypePaymentModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating multiple invoice types')
            return rejectWithValue(null)
        }
    }
)


export const updatePharmacyInvoiceViaCash = createAsyncThunk(
    'collection/updatePharmacyInvoiceViaCash',
    async ({ formData, toast, dispatch, setCurrentPharmacyInvoice, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchPharmacyInvoice(formData, token)

            toast.success('Cash transaction processed successfully.')
            dispatch(setCurrentPharmacyInvoice({ data: null }))
            setModals({ ...modals, showCollectionsPharmacyPaymentModal: !modals.showCollectionsPharmacyPaymentModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const setCurrentRadiologyInvoice = createAsyncThunk(
    'collection/setCurrentRadiologyInvoice',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const updateRadiologyInvoice = createAsyncThunk(
    'collection/updateRadiologyInvoice',
    async ({ formData, toast, navigate }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchRadiologyInvoice(formData, token)

            toast.success('Invoice updated successfully')
            navigate('/dashboard')

            localStorage.removeItem('payment__invoice')
            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const updateRadiologyInvoiceViaPOSTransaction = createAsyncThunk(
    'collection/updateRadiologyInvoiceViaPOSTransaction',
    async ({ formData, toast, dispatch, setCurrentRadiologyInvoice, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchRadiologyInvoice(formData, token)

            toast.success('POS transaction processed successfully.')
            dispatch(setCurrentRadiologyInvoice({ data: null }))
            setModals({ ...modals, showCollectionRadiologyPaymentModal: !modals.showCollectionRadiologyPaymentModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const updateRadiologyInvoiceViaCash = createAsyncThunk(
    'collection/updateRadiologyInvoiceViaCash',
    async ({ formData, toast, dispatch, setCurrentRadiologyInvoice, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchRadiologyInvoice(formData, token)

            toast.success('Cash transaction processed successfully.')
            dispatch(setCurrentRadiologyInvoice({ data: null }))
            setModals({ ...modals, showCollectionRadiologyPaymentModal: !modals.showCollectionRadiologyPaymentModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const setCurrentRecordsInvoice = createAsyncThunk(
    'collection/setCurrentRecordsInvoice',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const updateRecordsInvoice = createAsyncThunk(
    'collection/updateRecordsInvoice',
    async ({ formData, toast, navigate }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchRecordsInvoice(formData, token)

            toast.success('Invoice updated successfully')
            navigate('/dashboard')

            localStorage.removeItem('payment__invoice')
            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const updateRecordsInvoiceViaPOSTransaction = createAsyncThunk(
    'collection/updateRecordsInvoiceViaPOSTransaction',
    async ({ formData, toast, dispatch, setCurrentRecordsInvoice, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchRecordsInvoice(formData, token)

            toast.success('POS transaction processed successfully.')
            dispatch(setCurrentRecordsInvoice({ data: null }))
            setModals({ ...modals, showCollectionRecordsPaymentModal: !modals.showCollectionRecordsPaymentModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)


export const updateRecordsInvoiceViaCash = createAsyncThunk(
    'collection/updateRecordsInvoiceViaCash',
    async ({ formData, toast, dispatch, setCurrentRecordsInvoice, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchRecordsInvoice(formData, token)

            toast.success('POS transaction processed successfully.')
            dispatch(setCurrentRecordsInvoice({ data: null }))
            setModals({ ...modals, showCollectionRecordsPaymentModal: !modals.showCollectionRecordsPaymentModal })

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)

export const setCurrentAllInvoices = createAsyncThunk(
    'collection/setCurrentAllInvoices',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)

export const setAccumulatedPharmacyInvoice = createAsyncThunk(
    'collection/setAccumulatedPharmacyInvoice',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)



export const UpdateMultipleInvoices = createAsyncThunk(
    'collection/UpdateMultipleInvoices',
    async ({ formData, toast, navigate }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchMultipleinvoices(formData, token)

            toast.success('Invoices updated successfully')
            navigate('/dashboard')

            localStorage.removeItem('multiple')
            localStorage.removeItem('multiple_patient_invoice')

            return data
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while updating invoice')
            return rejectWithValue(null)
        }
    }
)



export const setCurrentInvoice = createAsyncThunk(
    'collection/setCurrentInvoice',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)




