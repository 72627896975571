/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react'
import { FaPencilAlt } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { RiBodyScanFill, RiQrScan2Fill } from 'react-icons/ri'

import { PageTitle } from '../../components'
import { useStateContext } from '../../state/context'
import Pagination from '../../components/utils/Pagination'
import { setDeleteRadiologyCategory, setDeleteRadiologyInvestigationType } from '../../state/actions/actions'
import { getAllRadiologyCategoryTest, getAllRadiologyInvestigationTypes } from '../../state/actions/radiologist.actions'


const RadiologistInvestigations = () => {
	const dispatch = useDispatch()

	const { modals, setModals } = useStateContext()

	const { all_radiology_category_test, all_radiology_investigation_type } = useSelector((state) => state.radiologist)

	const [currentData, setCurrentData] = useState([])
	const [currentSamples, setCurrentSamples] = useState([])
	const [showInvestigationCategory, setShowInvestigationCategory] = useState(false)
	const [showInvestigationTypes, setShowInvestigationTypes] = useState(true)

	useEffect(() => {
		dispatch(getAllRadiologyCategoryTest())
		dispatch(getAllRadiologyInvestigationTypes())
	}, [modals.showAddRadiologyInvestigationCategoryModal,
	modals.showDeleteRadiologyCategoryTestModal,
	modals.showUpdateRadiologyCategoryTestModal,
	modals.showAddRadiologyInvestigationTypeModal,
	modals.showDeleteRadiologyInvestigationTypeModal,
	modals.showUpdateRadiologyInvestigationTypeModal
	])

	return (
		<div>
			<PageTitle type={'radiologist-investigations'} />
			<div className='flex flex-col w-full cursor-pointer'>
				<div className='py-2'>
					<div className='flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1'
						onClick={() => {
							setShowInvestigationCategory(!showInvestigationCategory)
						}}
					>
						<h4 className='text-dark-blue text-[14px] font-bold'>
							Investigations Categories
						</h4>
						<RiQrScan2Fill
							className='text-dark-blue'
							size={20}
						/>
						{/* <input type="text" name="filter" placeholder='Search equipment ' id="" className='ml-2 pl-4 text-sm rounded h-[30px] w-[400px]' onChange={(e) => handleChange(e, e.target.name)} /> */}
					</div>
					{showInvestigationCategory && (
						<Fragment>
							<table className='border rounded w-full cursor-default'>
								<thead className=''>
									<tr>
										<th
											scope='col'
											className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
										>
											S/N
										</th>

										<th
											scope='col'
											className='text-sm font-medium text-gray-900 pr-[4em] py-4 text-left'
										>
											Investigation category
										</th>
										<th
											scope='col'
											className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
										>
											Created by
										</th>


										<th
											scope='col'
											className='text-sm font-medium text-gray-900 py-4 text-left'
										>
											Actions
										</th>
									</tr>
								</thead>
								<tbody>
									{currentSamples?.map((category, index) => (
										<tr
											className='relative' key={index}

										>
											<td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
												{index + 1}
											</td>

											<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
												{category?.title}
											</td>
											<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
												{category?.created_by.first_name}  {category?.created_by?.last_name}
											</td>
											<td className=' text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
												<div className='flex space-x-4 relative '>
													<FaPencilAlt
														className='text-green-600 cursor-pointer '
														onClick={() => {
															const data = {
																cat_id: category.id,
																cat_name: category.title
															}
															dispatch(setDeleteRadiologyCategory({ data }))
															setModals({
																...modals,

																showUpdateRadiologyCategoryTestModal:
																	!modals.showUpdateRadiologyCategoryTestModal,
															})
														}
														}

														size={24} />

													<MdDelete
														className='text-dark-yellow cursor-pointer mr-4'
														onClick={() => {
															const data = {
																cat_id: category.id,
																cat_name: category.title
															}
															dispatch(setDeleteRadiologyCategory({ data }))
															setModals({
																...modals,
																showDeleteRadiologyCategoryTestModal:
																	!modals.showDeleteRadiologyCategoryTestModal,
															})
														}
														}
														size={24} />
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</table>

							<Pagination
								data={all_radiology_category_test}
								currentData={currentSamples}
								setCurrentData={setCurrentSamples}
							/>
						</Fragment>
					)}
				</div>
			</div>

			<div className='flex flex-col w-full cursor-pointer'>

				<div className='py-2'>
					<div
						className='flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1'
						onClick={() => setShowInvestigationTypes(!showInvestigationTypes)}
					>
						<h4 className='text-dark-blue text-[14px] font-bold'>
							Investigations Types
						</h4>
						<RiBodyScanFill
							className='text-dark-blue'
							size={20}
						/>
					</div>
					{showInvestigationTypes && (
						<Fragment>
							<table className='border rounded w-full cursor-default'>
								<thead className=''>
									<tr>
										<th
											scope='col'
											className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
										>
											S/N
										</th>

										<th
											scope='col'
											className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
										>
											Investigation Type
										</th>
										<th
											scope='col'
											className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
										>
											Created by
										</th>


										<th
											scope='col'
											className='text-sm font-medium text-gray-900 py-4 text-left'
										>
											Actions
										</th>
									</tr>
								</thead>
								<tbody>
									{currentData?.map((types, index) => (
										<tr className='relative' key={index}>
											<td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
												{index + 1}
											</td>
											<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
												{types.title}
											</td>
											<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
												{types.created_by?.first_name} {types.created_by?.last_name}
											</td>
											<td className=' text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
												<div className='flex space-x-4 relative '>
													<FaPencilAlt
														size={24}
														className='text-green-600 cursor-pointer '
														onClick={() => {
															const data = {
																title: types.title,
																category_id: types.investigation_category,
																investigation_id: types.id,
																cat_title: types.investigation_catergory,
																status: types.is_available

															}
															dispatch(setDeleteRadiologyInvestigationType({ data }))
															setModals({
																...modals,
																showUpdateRadiologyInvestigationTypeModal:
																	!modals.showUpdateRadiologyInvestigationTypeModal,
															})
														}}
													/>

													<MdDelete
														size={24}
														className='text-dark-yellow cursor-pointer mr-4'
														onClick={() => {
															const data = {
																title: types.title,
																category_id: types.investigation_category,
																investigation_id: types.id,
																status: types.is_available

															}
															dispatch(setDeleteRadiologyInvestigationType({ data }))
															setModals({
																...modals,
																showDeleteRadiologyInvestigationTypeModal:
																	!modals.showDeleteRadiologyInvestigationTypeModal,
															})
														}}
													/>
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</table>

							<Pagination
								data={all_radiology_investigation_type}
								currentData={currentData}
								setCurrentData={setCurrentData}
							/>
						</Fragment>
					)}
				</div>
			</div>

		</div>
	)
}

export default RadiologistInvestigations