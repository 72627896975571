/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../state/context'
import { GatewaySettingsTable, HospitalInformationTable, MonthlyRemittanceTable, PageTitle, SmsSubscriptionTable } from '../../components'
import { getGatewaySettings, getHospitalConfig, getMonthlyRemittances, getSMSBalance, getSmsConfig, getSubscriptionPlan, getSubscriptionPlans, setCurrentSubscriptionPlan } from '../../state/actions/admin.actions'


const Settings = () => {
    const dispatch = useDispatch()

    const { appState, setAppState, modals, setModals } = useStateContext()

    const { user } = useSelector(state => state.auth)
    const { gateway_settings, sms_subscriptions, subscription_plans, subscription_plan, monthly_remittances } = useSelector(state => state.admin)

    const [planExpDate, setPlanExpDate] = useState()
    const [enableRenewal, setEnableRenewal] = useState(false)
    const [activeLink, setActiveLink] = useState('Information')

    useEffect(() => {
        dispatch(getSMSBalance())
        dispatch(getSmsConfig())
        dispatch(getHospitalConfig())
        dispatch(getGatewaySettings())
        dispatch(getSubscriptionPlan())
        dispatch(getSubscriptionPlans())
        dispatch(getMonthlyRemittances())
    }, [])

    useEffect(() => {
        if (subscription_plan?.expiration_date) {
            const date = new Date(subscription_plan?.expiration_date)
            date.setMonth(date.getMonth() - 1)
            setPlanExpDate(date.toISOString().slice(0, 10))
        }
    }, [subscription_plan])

    useEffect(() => {
        const now = new Date()
        const futureTime = new Date(planExpDate)

        const timeDiffInMs = futureTime.getTime() - now.getTime();

        const diff_in_days = timeDiffInMs / (1000 * 3600 * 24)

        // console.log(futureTime, futureTime, diff_in_days)
        if (diff_in_days <= 30) {
            setEnableRenewal(true)
            // console.log('Date is older than 30 days');
        } else {
            setEnableRenewal(false)
            // console.log('Date is not older than 30 days');
        }
    }, [planExpDate])


    return (
        <div>
            <PageTitle
                type={'settings'}
            />

            <div className="flex flex-col items-center px-2">
                <div className="space-x-5 justify-between items-center border-b-2 border-gray-500 pb-2 w-full">
                    <button
                        type="button"
                        className={`${activeLink === 'Information' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => {
                            setActiveLink(e.target.textContent)
                            setAppState({ ...appState, showSettingsGateway: false, showSettingsSms: false })
                        }}
                    >
                        Information
                    </button>

                    <button
                        type="button"
                        className={`${activeLink === 'Gateway' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => {
                            setActiveLink(e.target.textContent)
                            setAppState({ ...appState, showSettingsGateway: true, showSettingsSms: false })
                        }}
                    >
                        Gateway
                    </button>


                    <button
                        type="button"
                        className={`${activeLink === 'SMS' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => {
                            setActiveLink(e.target.textContent)
                            setAppState({ ...appState, showSettingsGateway: false, showSettingsSms: true })
                        }}
                    >
                        SMS
                    </button>


                    {subscription_plan?.subscription_details?.title === 'FREE' && (
                        <button
                            type="button"
                            className={`${activeLink === 'Remittance' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                            onClick={(e) => {
                                setActiveLink(e.target.textContent)
                                setAppState({ ...appState, showSettingsGateway: false, showSettingsSms: false })
                            }}
                        >
                            Remittance
                        </button>
                    )}


                    <button
                        type="button"
                        className={`${activeLink === 'Subscription' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => {
                            setActiveLink(e.target.textContent)
                            setAppState({ ...appState, showSettingsGateway: false, showSettingsSms: false })
                        }}
                    >
                        Subscription
                    </button>
                </div>

                {activeLink === 'Information' && (
                    <HospitalInformationTable />
                )}

                {activeLink === 'Gateway' && (
                    <GatewaySettingsTable
                        data={gateway_settings}
                    />
                )}

                {activeLink === 'SMS' && (
                    <SmsSubscriptionTable
                        data={sms_subscriptions}
                    />
                )}

                {activeLink === 'Remittance' && (
                    <MonthlyRemittanceTable
                        data={monthly_remittances}
                    />
                )}

                {activeLink === 'Subscription' && (
                    <div className="w-full flex justify-center mt-[40px]">
                        <div className="flex space-x-5 items-start">

                            {subscription_plans?.filter(plan=> plan.pkid === subscription_plan?.subscription)?.map((plan, index) => (
                                <div className={`flex flex-col justify-between items-center w-[400px] border ${subscription_plan?.subscription === plan.pkid ? 'border-purple-400 inner__shadow__3' : 'border-slate-200 inner__shadow__1'} rounded-md shadow-lg hover:-translate-y-2 duration-500 ease-in-out py-4`}>
                                    <div className="text-center">
                                        <h1 className='text-[20px] text-slate-600'>{plan?.title} Plan</h1>
                                        {plan.title === 'FREE' && (
                                            <p className='text-[12px] text-slate-800'>Ideal solutions for SMALL hospitals</p>
                                        )}
                                        {plan.title === 'FOREVER' && (
                                            <p className='text-[12px] text-slate-800'>Ideal solutions for LARGE hospitals</p>
                                        )}


                                        {/* {subscription_plan?.subscription === plan.pkid && enableRenewal && plan.title !== 'FOREVER' && ( */}
                                        {subscription_plan?.subscription === plan.pkid && enableRenewal && (
                                            <div className="text-center mt-10">
                                                <button
                                                    type="button"
                                                    className="inline-block px-8 py-2 bg-purple-800 text-white font-medium text-sm leading-tight capitalize rounded-full shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                    onClick={() => {
                                                        dispatch(setCurrentSubscriptionPlan({ data: plan }))
                                                        setModals({ ...modals, showRenewSubscriptionPlanModal: !modals.showRenewSubscriptionPlanModal })
                                                    }}
                                                >
                                                    Renew
                                                </button>
                                            </div>
                                        )}

                                        {subscription_plan?.subscription !== plan.pkid && plan.title === 'FOREVER' && (
                                            <div className="text-center mt-10">
                                                <button
                                                    type="button"
                                                    className="inline-block px-8 py-2 bg-teal-600 text-white font-medium text-sm leading-tight capitalize rounded-full shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                    // disabled={user?.data?.subscription_plan === 'STARTER' ? true : false}
                                                    onClick={() => {
                                                        dispatch(setCurrentSubscriptionPlan({ data: plan }))
                                                        setModals({ ...modals, showUpgradeSubscriptionPlanModal: !modals.showUpgradeSubscriptionPlanModal })
                                                    }}
                                                >
                                                    Upgrade
                                                </button>
                                            </div>
                                        )}
                                        {subscription_plan?.subscription !== plan.pkid && plan.title === 'FREE' && (
                                            <div className="text-center mt-10">
                                                <button
                                                    type="button"
                                                    className="inline-block px-8 py-2 bg-sky-600 text-white font-medium text-sm leading-tight capitalize rounded-full shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                    // disabled={user?.data?.subscription_plan === 'STARTER' ? true : false}
                                                    onClick={() => {
                                                        dispatch(setCurrentSubscriptionPlan({ data: plan }))
                                                        setModals({ ...modals, showUpgradeSubscriptionPlanModal: !modals.showUpgradeSubscriptionPlanModal })
                                                    }}
                                                >
                                                    Downgrade
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Settings