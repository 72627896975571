/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import { useStateContext } from "../../../state/context"
import { setBedSpace } from "../../../state/actions/actions"
import { addBedSpaceToWard, updateBedSpace } from "../../../state/actions/admin.actions"

const AddBedSpaceModal = (props) => {
	const dispatch = useDispatch()

	const { modals, setModals } = useStateContext()
	const { wards } = useSelector(state => state.admin)
	const { bedspace_update } = useSelector(state => state.root)

	const [formData, setFormData] = useState({
		ward: "",
		bed_number: 0,
		name: ""
	})

	useEffect(() => {
		setFormData({
			...formData,
			ward: parseInt(formData.ward),
		})
	}, [formData.ward])

	useEffect(() => {
		setFormData({
			...formData,
			ward: bedspace_update?.ward.name,
			bed_number: bedspace_update?.bed_number,
		})
	}, [bedspace_update])


	const handleChange = (e, name) => {
		setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		const index = wards.findIndex(ward => ward.pkid === formData.ward)

		if (!formData.ward || !formData.bed_number) return toast.warning("Please fill all fields")
		dispatch(addBedSpaceToWard({ formData: { ...formData, name: wards[index].name }, toast, setModals: setModals, modals: modals }))
	}

	const handleUpdate = (e) => {
		e.preventDefault()

		const index = wards.findIndex(ward => ward.id === bedspace_update.ward.id)
		
		if (!formData.ward || !formData.bed_number) return toast.warning("Please fill all fields")
		dispatch(updateBedSpace({ formData: { ...formData, name: wards[index].name, id: bedspace_update.pkid }, toast, setModals: setModals, modals: modals }))
	}


	return (
		<div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
			<div className="bg-white w-[500px] rounded px-[30px] py-[20px]">
				<div className="flex flex-col space-y-5">
					<div className="flex justify-between items-center">
						<h4 className={`${modals.showAddBedSpaceModal ? 'text-dark-green' : 'text-sky-600'} text-[18px] font-medium`}>
							{modals.showAddBedSpaceModal ? 'Add Bed Space' : 'Update Bed Space'}
						</h4>
						<FaTimesCircle
							className="text-dark-yellow cursor-pointer"
							onClick={() => {
								dispatch(setBedSpace({ data: null }))
								modals.showAddBedSpaceModal && setModals({ ...modals, showAddBedSpaceModal: !modals.showAddBedSpaceModal })
								modals.showUpdateBedSpaceModal && setModals({ ...modals, showUpdateBedSpaceModal: !modals.showUpdateBedSpaceModal })
							}}
							size={20}
						/>
					</div>

					{modals.showAddBedSpaceModal && (
						<form onSubmit={handleSubmit}>
							<div className="relative">
								<select
									className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none " id="grid-state"
									name='ward'
									onChange={(e) => handleChange(e, e.target.name)}
								>
									<option >Ward</option>
									{wards?.map((item, index) => (
										<option value={item.pkid} key={`${item.id}`}>{item.name}</option>
									))}
								</select>
								<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
									<svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
								</div>
							</div>

							<div>
								<input
									type="text"
									name="bed_number"
									placeholder="Bed number"
									className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
									onChange={(e) => handleChange(e, e.target.name)}
								/>
							</div>

							<div className="mt-4">
								<button
									type="submit"
									className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
								>
									Submit
								</button>
							</div>
						</form>
					)}

					{modals.showUpdateBedSpaceModal && (
						<form onSubmit={handleUpdate}>
							<div className="flex flex-col mb-5">
								<label className="text-[10px] text-dark-blue font-bold mb-[-10px]">Ward</label>
								<input
									type="text"
									name="bed_number"
									disabled
									className="border rounded w-full h-5 px-4 py-5 mt-2 bg-gray-200 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
									value={bedspace_update?.ward.name}
									onChange={(e) => handleChange(e, e.target.name)}
								/>
							</div>

							<div className="flex flex-col">
								<label className="text-[10px] text-dark-blue font-bold mb-[-10px]">Bed Number</label>
								<input
									type="text"
									name="bed_number"
									placeholder="Bed number"
									className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
									value={formData.bed_number}
									onChange={(e) => handleChange(e, e.target.name)}
								/>
							</div>

							<div className="mt-4">
								<button
									type="submit"
									className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
								>
									Update
								</button>
							</div>
						</form>
					)}
				</div>
			</div>
		</div>
	)
}

export default AddBedSpaceModal
