import React, { useEffect, useState } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { useStateContext } from '../../../state/context'
import { toast } from 'react-toastify'
import { deleteAPatientFamilyFollowUpRecord } from '../../../state/actions/nurse.actions'
const NurseDeletePatientFollowUpRecords = () => {
    const dispatch = useDispatch()
    const { modals, setModals } = useStateContext()
    const { set_follow_up_record } = useSelector((state) => state.root)

    const handleSubmit = (e) => {
        e.preventDefault()
        const id = set_follow_up_record?.id
        dispatch(deleteAPatientFamilyFollowUpRecord({ id, toast, setModals, modals }))

    }
    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>

                            <>
                                <h4 className='text-dark-green text-[16px] font-semibold'>
                                    Medical follow up record
                                </h4>
                                <FaTimesCircle
                                    className='text-dark-yellow cursor-pointer'
                                    onClick={() => {
                                        setModals({
                                            ...modals,
                                            showViewPatientFollowUpRecordModal:
                                                !modals.showViewPatientFollowUpRecordModal,
                                        })
                                    }
                                    }
                                    size={20}
                                />
                            </>
                        </div>
                        <form

                        >
                            <div className='flex justify-between-items-center space-x-10'>
                                <div className='space-y-2 w-full'>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Method change</p>
                                            <input
                                                type="title"
                                                name="weight"
                                                disabled
                                                value={set_follow_up_record?.method_change}
                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"

                                            />

                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Weight</p>
                                            <input
                                                type="title"
                                                name="weight"
                                                disabled
                                                value={`${set_follow_up_record?.weight}kg`}
                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"

                                            />
                                        </div>

                                    </div>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Method Supplied</p>
                                            <input
                                                type="title"
                                                name="method_supplied"
                                                disabled

                                                value={set_follow_up_record?.method_supplied}
                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"

                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Pelvic infection</p>
                                            <input
                                                type="title"
                                                name="pelvic_infection"
                                                value={set_follow_up_record?.pelvic_infection}
                                                disabled
                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"

                                            />
                                        </div>

                                    </div>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Size/Quantity</p>
                                            <input
                                                type="title"
                                                name="brand_qty"

                                                value={set_follow_up_record?.brand_qty}
                                                disabled

                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"

                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Blood pressure</p>
                                            <input
                                                type="title"
                                                name="blood_pressure"
                                                placeholder="systolic/diastolic"
                                                value={set_follow_up_record?.blood_pressure}
                                                disabled

                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"

                                            />
                                        </div>

                                    </div>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Next appointment</p>
                                            <input
                                                type="date"
                                                name="next_appointment_date"

                                                value={set_follow_up_record?.appointment}
                                                disabled
                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"

                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Observations</p>
                                            <textarea type='text'
                                                name='observation'
                                                disabled
                                                value={set_follow_up_record?.observations}


                                                className='border text-sm rounded w-[250px] h-[100px] px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
                                                id="" cols="30" rows="20">

                                            </textarea>

                                        </div>

                                    </div>






                                </div>


                            </div>

                        </form>

                    </div>
                </div>

            </div>
        </div>
    )
}


export default NurseDeletePatientFollowUpRecords