import React, { useState, useEffect } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useStateContext } from '../../../state/context'
import { getALabBilling, generatePatientLabInvoice, removeSingleLabBilling, getAllLabBillings } from '../../../state/actions/laboratorist.actions'



import { toast } from 'react-toastify'

// showLaboratoryGenerateInvoiceModal
const LaboratoryGenerateInvoiceModal = () => {
    const { modals, setModals } = useStateContext()
    const { set_laboratory_reports } = useSelector((state) => state.root)
    const { all_billings, all_lab_category_test, single_lab_billing_details } = useSelector((state) => state.laboratorist)

    const [formData, setFormData] = useState({
        id: '',
        billing_type: '',
        description: '',
        amount: '',
        is_paid: false,
        patient_id: '',
        investigation_pkid: ''

    })

    const lab_billings = all_billings?.filter((e, index) => e?.billing_type === "laboratory")
    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))

    }
    const dispatch = useDispatch()
    const handleSubmit = (e) => {
        e.preventDefault()
        setFormData({
            ...formData, patient_id: set_laboratory_reports?.patient_id,
            billing_type: single_lab_billing_details?.name,
            amount: single_lab_billing_details?.price,
            description: single_lab_billing_details?.description,
            investigation_pkid: set_laboratory_reports?.investigation_pkid


        })
        dispatch(generatePatientLabInvoice({ formData, toast, setModals: setModals, modals: modals }))
        dispatch(removeSingleLabBilling({ data: null }))


    }
    useEffect(() => {

        setFormData({
            ...formData, patient_id: set_laboratory_reports?.patient_id,
            billing_type: single_lab_billing_details?.name,
            amount: single_lab_billing_details?.price,
            description: single_lab_billing_details?.description,
            investigation_pkid: set_laboratory_reports?.investigation_pkid


        })



    }, [set_laboratory_reports, single_lab_billing_details])

    return (
        <div className="absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center">
            <div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className='text-dark-green text-[15px] font-bold'>Generate Invoice -   {`${set_laboratory_reports?.first_name}
                                ${set_laboratory_reports?.last_name}`} [{set_laboratory_reports?.patient_no}] </h4>
                        <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {

                                const data = null

                                dispatch(removeSingleLabBilling({ data }))

                                setModals({ ...modals, showLaboratoryGenerateInvoiceModal: !modals.showLaboratoryGenerateInvoiceModal })

                            }}
                            size={20}
                        />
                    </div>


                    <form
                        onSubmit={handleSubmit}
                    >
                        <div className="w-full mb-6 md:mb-0">

                            <div className="relative w-full">
                                <select
                                    className="appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state"
                                    name='id'
                                    onChange={(e) => handleChange(e, e.target.name)}
                                    onClick={() => {

                                        const billing_id = formData?.id

                                        dispatch(getALabBilling({ billing_id }))
                                    }}

                                >
                                    <option value="###">Billing Type</option>
                                    {lab_billings?.map((billings, index) => (
                                        <option value={billings.id} key={index}>{billings.name}</option>
                                    ))}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                </div>
                            </div>
                        </div>

                        <div>
                            <input
                                type="number"
                                name="department_name"
                                placeholder="price"
                                value={single_lab_billing_details?.price}
                                disabled
                                className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                onChange={(e) => handleChange(e, e.target.name)}
                            />
                        </div>

                        <div>
                            <textarea
                                cols="30"
                                rows="7"
                                name="description"
                                disabled
                                value={single_lab_billing_details?.description}
                                placeholder='Description'
                                className="border rounded w-full px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue resize-none"
                                onChange={(e) => handleChange(e, e.target.name)}
                            >
                            </textarea>
                        </div>
                        <div className='mt-4'>
                            <button
                                type='submit'
                                className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >Generate</button>
                        </div>
                    </form>
                </div>


            </div>
        </div>
    )
}

export default LaboratoryGenerateInvoiceModal