import React from 'react'
import { setInpatientDetails } from '../../../state/actions/actions'
import { useStateContext } from '../../../state/context'
import { useDispatch } from 'react-redux'


const PatientWaitingAdmissionTable = (props) => {
    const { modals, setModals } = useStateContext()
    const dispatch = useDispatch()

    return (
        <tbody>
            {props.data?.map((item, index) => (
                <tr
                    className='relative'
                    key={index}

                >
                    <td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
                        {index + 1}
                    </td>
                    <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                        {item?.patient_profile?.patient_number}      </td>
                    <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                        {item?.patient?.first_name}    {item.patient?.last_name}
                    </td>
                    <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                        {item?.doctor?.first_name}    {item?.doctor?.last_name}
                    </td>
                    <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                        {item?.patient_profile?.gender}
                    </td>


                    <td className=' text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                        <div className='flex space-x-4 relative '>
                            <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => {
                                    setModals({
                                        ...modals,
                                        showAdmitPatientModal:
                                            !modals.showAdmitPatientModal,
                                    })
                                    const data = {
                                        inpatient_id: item?.id,
                                        first_name: item?.patient?.first_name,
                                        last_name: item?.patient?.last_name,
                                        patient_number: item?.patient_profile?.patient_number,
                                        patient_id: item?.patient?.id,


                                    }
                                    dispatch(setInpatientDetails({ data }))
                                }}
                            >
                                Admit
                            </button>


                        </div>
                    </td>
                </tr>
            ))}
        </tbody>
    )
}

export default PatientWaitingAdmissionTable