import React, { useState, useEffect } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { MdDelete, MdOutlineEdit } from 'react-icons/md'
import { AiFillEye } from 'react-icons/ai'
import { useStateContext } from '../../../state/context'
import { useSelector, useDispatch } from 'react-redux'
import { setFilteredSchedules, setScheduleDate, setScheduleEdit } from '../../../state/actions/actions'
import { createASchedule, fetchAllUsersByDepartment, getAllDepartments, setUsersByDeparmentToNull } from '../../../state/actions/admin.actions'
import { toast } from 'react-toastify'
import { updateASchedule } from '../../../state/actions/admin.actions'


const ListOfSchedulesByDateModal = () => {
    const { modals, setModals } = useStateContext()
    const { filtered_schedules } = useSelector(state => state.root)
    const date = filtered_schedules?.slice(0, 1)?.map((item) => (
        item.date
    ))

    const dispatch = useDispatch()
    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[500px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className='text-dark-green text-[14px] font-semibold'>Schedule list  for {date}</h4>
                        <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                dispatch(setFilteredSchedules({ data: null }))
                                setModals({ ...modals, showListOfSchedulesByDateModal: !modals.showListOfSchedulesByDateModal })
                            }}
                            size={20}
                        />
                    </div>

                    <form
                    // onSubmit={handleSubmit}
                    >

                        <div className="flex flex-col space-y-2 max-h-[200px] my-4 overflow-y-auto patient__prescriptions">
                            {filtered_schedules?.map((item, index) => (
                                <div className='flex justify-between space-x-2 border py-1 px-2 rounded-md'>
                                    <p className='text-gray-500 text-sm'>{item.schedule_title} - [{item.department} department]</p>
                                    <div className='flex space-x-2'>
                                        <AiFillEye className='hover:cursor-pointer' color='grey' size={20}
                                            onClick={() => {
                                                const data = {
                                                    id: item.id,
                                                    date: item.date,
                                                    members: item.members,
                                                    title: item.schedule_title,
                                                    time: item.time,
                                                    department: item.department,
                                                    label: item.label
                                                }
                                                dispatch(setScheduleEdit({ data }))
                                                // modals.showListOfSchedulesByDateModal && setModals({ ...modals, showListOfSchedulesByDateModal: !modals.showListOfSchedulesByDateModal })
                                                !modals.showViewScheduleModal && setModals({ ...modals, showViewScheduleModal: !modals.showViewScheduleModal })
                                            }}

                                        />
                                        <MdOutlineEdit className='hover:cursor-pointer' color='skyblue' size={20}
                                            onClick={() => {
                                                const data = {
                                                    id: item.id,
                                                    date: item.date,
                                                    members: item.members,
                                                    title: item.schedule_title,
                                                    time: item.time,
                                                    department: item.department,
                                                    label: item.label
                                                }
                                                dispatch(setScheduleEdit({ data }))
                                                // modals.showListOfSchedulesByDateModal && setModals({ ...modals, showListOfSchedulesByDateModal: !modals.showListOfSchedulesByDateModal })
                                                !modals.showEditAndDeleteScheduleModal && setModals({ ...modals, showEditAndDeleteScheduleModal: !modals.showEditAndDeleteScheduleModal })
                                            }}

                                        />
                                        <MdDelete className='hover:cursor-pointer' color='orange' size={20}

                                            onClick={() => {
                                                const data = {
                                                    id: item.id,
                                                    title: item.schedule_title,

                                                }
                                                dispatch(setScheduleEdit({ data }))
                                                // modals.showListOfSchedulesByDateModal && setModals({ ...modals, showListOfSchedulesByDateModal: !modals.showListOfSchedulesByDateModal })
                                                !modals.showDeleteScheduleModal && setModals({ ...modals, showDeleteScheduleModal: !modals.showDeleteScheduleModal })
                                            }}

                                        />

                                    </div>
                                </div>
                            ))}
                        </div>


                    </form>
                </div>


            </div>
        </div>
    )
}

export default ListOfSchedulesByDateModal