import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FaWallet, FaTrash } from 'react-icons/fa'

import { useStateContext } from '../../state/context'
import { setBilling } from '../../state/actions/actions'
import { FaPencilAlt } from 'react-icons/fa'
import PaginationButtons from '../utils/PaginationButtons'
import Pagination from '../utils/Pagination'


const InvoiceTable = ({ data, showList, setShowList, title }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()


    const [_data, setData] = useState([])
    const [currentData, setCurrentData] = useState([])

    useEffect(() => {
        setData(data)
    }, [data])

    return (
        <div className="flex justify-between items-center w-full">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="">
                    {/* <div
                        className="flex justify-start items-center space-x-4 bg-gray-200 p-1"
                        onClick={() => setShowList(!showList)}
                    >
                        <h4 className="text-dark-blue text-[14px] font-bold">
                            {title}
                        </h4>
                        <FaWallet className="text-dark-blue" size={20} />
                    </div> */}
                    <Fragment>
                        <table className="border rounded w-full cursor-default">
                            <thead className="bg-gray-100">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left"
                                    >
                                        Billing ID
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Type
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Amount
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Status
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((billing, index) => (
                                    <tr className="" key={billing.pkid}>
                                        <td
                                            className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900"
                                            value={billing.pkid}
                                        >
                                            {index + 1}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {billing.name}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {billing.price}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {billing.status ? "Active" : "Inactive"}
                                        </td>
                                        <td className=" flex text-2xl space-x-4 text-gray-900 font-semibold py-2 whitespace-nowrap">
                                            <FaPencilAlt
                                                size={20}
                                                className='text-green-600 cursor-pointer'
                                                onClick={() => {
                                                    dispatch(setBilling({ data: billing }))
                                                    setModals({ ...modals, showUpdateBillingModal: !modals.showUpdateBillingModal })
                                                }}
                                            />
                                            <FaTrash
                                                onClick={() => {
                                                    dispatch(setBilling({ data: billing }))
                                                    setModals({ ...modals, showDeleteBillingModal: !modals.showDeleteBillingModal })
                                                }}
                                                className="text-orange-400 cursor-pointer"
                                                size={20}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <Pagination
                            data={data}
                            currentData={currentData}
                            setCurrentData={setCurrentData}
                        />
                    </Fragment>
                </div>
            </div>
        </div>
    )
}

export default InvoiceTable