import React, { useState } from 'react'
import { useStateContext } from '../../../state/context'
import { FaTimesCircle } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { addAPatientFamilyFollowUpRecord } from '../../../state/actions/nurse.actions'
import { toast } from 'react-toastify'

const NurseAddFamilyPlanningRecordsModal = () => {
    const dispatch = useDispatch()
    const { set_patient_id } = useSelector((state) => state.root)
    const { modals, setModals } = useStateContext()

    const [formData, setFormData] = useState({

        method_change: '',
        method_supplied: '',
        brand_qty: '',
        weight: '',
        blood_pressure: '',
        next_appointment_date: '',
        pelvic_infection: '',
        observation: '',

    })
    const handleChange = (e, name) => { setFormData((prevState) => ({ ...prevState, [name]: e.target.value })) }

    const handleSubmit = (e) => {
        e.preventDefault()
        const id = set_patient_id?.id
        dispatch(addAPatientFamilyFollowUpRecord({ id, formData, toast, setModals, modals }))

    }

    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>

                            <>
                                <h4 className='text-dark-green text-[14px] font-semibold'>
                                    Add family planning follow up record
                                </h4>
                                <FaTimesCircle
                                    className='text-dark-yellow cursor-pointer'
                                    onClick={() => (
                                        setModals({
                                            ...modals,
                                            showAddFamilyPlanningRecords:
                                                !modals.showAddFamilyPlanningRecords,
                                        })
                                    )

                                    }
                                    size={20}
                                />
                            </>


                        </div>




                        <form
                            onSubmit={handleSubmit}
                        >


                            <div className='flex justify-between-items-center space-x-10'>
                                <div className='space-y-2 w-full'>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Method change</p>
                                            <select name="method_change" placeholder='Type' id="" className='border rounded mt-2 pl-2 w-[250px] h-[40px] focus:outline-none focus:ring-blue-500 '
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            >
                                                <option value="None">Select</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>


                                            </select>
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Weight</p>
                                            <input
                                                type="title"
                                                name="weight"
                                                // placeholder="Temperature"

                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                    </div>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Method Supplied</p>
                                            <input
                                                type="title"
                                                name="method_supplied"
                                                // placeholder="Temperature"

                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Pelvic infection</p>
                                            <input
                                                type="title"
                                                name="pelvic_infection"
                                                // placeholder="Temperature"

                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                    </div>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Size/Quantity</p>
                                            <input
                                                type="title"
                                                name="brand_qty"
                                                // placeholder="(weight in kg)"

                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Blood pressure</p>
                                            <input
                                                type="title"
                                                name="blood_pressure"
                                                placeholder="systolic/diastolic"

                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                    </div>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Next appointment</p>
                                            <input
                                                type="date"
                                                name="next_appointment_date"
                                                // placeholder="Temperature"
                                                min={new Date().toISOString().split('T')[0]}

                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Observations</p>
                                            <textarea type='text'
                                                name='observation'
                                                onChange={(e) => handleChange(e, e.target.name)}



                                                className='border rounded w-[250px] h-[100px] px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
                                                id="" cols="30" rows="20">

                                            </textarea>

                                        </div>

                                    </div>






                                </div>


                            </div>

                            <div className='mt-4 flex justify-center items-center'>
                                <button
                                    type='submit'
                                    className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
                                // onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>

                        </form>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default NurseAddFamilyPlanningRecordsModal