import React, { useState, useEffect } from 'react'
import { useStateContext } from '../../../state/context'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { bookAPatientForFamilyPlanning } from '../../../state/actions/nurse.actions'
import { toast } from 'react-toastify'


const NurseBookPatientForFamilyPlanningModal = () => {
    const { modals, setModals } = useStateContext()
    const dispatch = useDispatch()
    const { all_nurse_patients } = useSelector((state) => state.nurse)


    const handleChange = (e, name) => { setFormData((prevState) => ({ ...prevState, [name]: e.target.value })) }

    const [formData, setFormData] = useState({
        filter: 'None',
        id: '',
        date: ''
    })
    const filtered = all_nurse_patients?.filter((item) => {
        return item.patient_number === `${formData.filter}`

    })

    const handleSubmit = (e) => {
        e.preventDefault()
        const id = filtered[0]?.user_details?.id
        dispatch(bookAPatientForFamilyPlanning({ id, formData, toast, setModals, modals }))

    }

    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>

                            <>
                                <h4 className='text-dark-green text-[20px] font-bold'>
                                    Family planning appointment
                                </h4>
                                <FaTimesCircle
                                    className='text-dark-yellow cursor-pointer'
                                    onClick={() => (
                                        setModals({
                                            ...modals,
                                            showBookPatientForFamilyPlanningModal:
                                                !modals.showBookPatientForFamilyPlanningModal,
                                        })
                                    )

                                    }
                                    size={20}
                                />
                            </>


                        </div>




                        <form
                            onSubmit={handleSubmit}
                        >


                            <div className='flex justify-between-items-center space-x-10'>

                                <div className='space-y-2 w-full'>
                                    {filtered != '' && (

                                        <input
                                            type="title"
                                            name="filter"
                                            disabled
                                            value={`${filtered[0]?.first_name} ${filtered[0]?.last_name} - [${filtered[0]?.patient_number}]`}

                                            className="border bg-slate-300 rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        // onChange={(e) => handleChange(e, e.target.name)}
                                        />

                                    )}
                                    <input
                                        type="title"
                                        name="filter"
                                        placeholder="Enter patient number"

                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                    <p className='text-sm font-semibold'>Date of appointment </p>
                                    <input
                                        type="date"
                                        name="date"
                                        required

                                        min={new Date().toISOString().split('T')[0]}

                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />




                                </div>


                            </div>

                            <div className='mt-4 flex justify-center items-center'>
                                <button
                                    type='submit'
                                    className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
                                // onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>

                        </form>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default NurseBookPatientForFamilyPlanningModal