import React from 'react'
import { FaBed } from 'react-icons/fa'


function PatientList() {
    return (
        <div className="flex flex-col w-[450px]">
            <div className="py-2">
                <div className="flex justify-start items-center space-x-4 border-2 border-gray-200 rounded-t-lg p-1 w-full">
                    <h4 className='text-dark-blue text-[18px] font-bold'>Patients</h4>
                    <FaBed
                        className='text-dark-blue'
                    />
                </div>
                <table className="w-full border-l-2 border-r-2 border-b-2 rounded-b-md">
                    <thead className="border-1">
                        <tr>
                            <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-4 text-left">
                                Staff ID
                            </th>
                            <th scope="col" className="text-sm font-medium text-gray-900 mx-4 py-4 text-left">
                                Fullname
                            </th>
                            <th scope="col" className="text-sm font-medium text-gray-900 px-4 py-4 text-left">
                                Phone
                            </th>
                            <th scope="col" className="text-sm font-medium text-gray-900 px-4 py-4 text-left">
                                Gender
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="">
                            <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">1</td>
                            <td className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">
                                Mark
                            </td>
                            <td className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">
                                Otto
                            </td>
                            <td className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">
                                @mdo
                            </td>
                        </tr>
                        <tr className="bg-white border-b">
                            <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">2</td>
                            <td className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">
                                Jacob
                            </td>
                            <td className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">
                                Thornton
                            </td>
                            <td className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">
                                @fat
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default PatientList;