/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import { useStateContext } from "../../../state/context"
import { setCurrentPrescriptionInvoice, setCurrentPrescriptions } from "../../../state/actions/actions"
import { createMultiplePrescriptionsInvoice, getDrugPrices, getPaidPrescriptions, resetPaidPrescriptions } from "../../../state/actions/pharmacist.actions"


const PharmacistGenerateMultipleInvoicesModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { drugs_price, patient_drugdispensed, multiple_prescriptions } = useSelector(state => state.pharmacist)
    const { prescriptions_update, prescription_invoice_update } = useSelector(state => state.root)
    const [formData, setFormData] = useState([{}])
    const [total, setTotal] = useState(0)
    const [invoicePrescriptions, setInvoicePrescriptions] = useState([])

    useEffect(() => {
        const drugs = []

        multiple_prescriptions?.forEach(prescription => {
            drugs.push(prescription?.drugs)
        })

        const params = [...new Set(drugs)]


        dispatch(getDrugPrices({ params }))

        let prescriptions = []
        multiple_prescriptions?.forEach(prescription => {
            prescriptions.push({
                ...prescription,
                quantity: 0
            })
        })
        setInvoicePrescriptions(multiple_prescriptions)
    }, [multiple_prescriptions])


    useEffect(() => {
        if (prescription_invoice_update?.pkid) {
            const params = {
                patient: prescription_invoice_update?.invoice_patient?.pkid,
                diagnosis: prescription_invoice_update?.diagnosis
            }
            dispatch(getPaidPrescriptions({ params }))
        }
    }, [prescription_invoice_update, patient_drugdispensed])

    const handleChange = (e, prescription_id) => {
        if (e.target.value === "" || e.target.value === "0") return

        let index = invoicePrescriptions?.findIndex(prescription => prescription?.pkid === prescription_id)

        console.log(index, ':: index')
        let prescription
        let prescriptions = invoicePrescriptions.filter(prescription => prescription.pkid !== prescription_id)

        invoicePrescriptions.forEach((item, i) => {
            if (i === index) {
                prescription = {
                    ...item,
                    quantity: parseInt(e.target.value)
                }
            }
        })
        setInvoicePrescriptions([...prescriptions, prescription])
    }

    console.log(invoicePrescriptions, ':: invoicePrescriptions')

    const calcTotal = () => {
        let sum = 0

        console.log(drugs_price, ':: prices')
        invoicePrescriptions?.forEach(prescription => {
            drugs_price?.forEach(drug => {
                if (drug.drug_name === prescription.drugs) {
                    sum += drug.selling_price * parseInt(prescription.quantity)
                    console.log(sum, '::', prescription.quantity)
                }
            })
        })

        console.log(sum)
        setTotal(sum)
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        dispatch(createMultiplePrescriptionsInvoice({ formData: { ...formData, prescriptions: invoicePrescriptions, total }, toast, setModals, modals }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className={`w-[600px] bg-white px-[20px] py-[15px]`}>
                <div className="flex flex-col space-y-2">
                    <div className="flex justify-between items-center">
                        <h4 className={`text-sky-500 text-[18px] font-medium capitalize`}>
                            Generate Multiple Invoices
                        </h4>
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                dispatch(resetPaidPrescriptions())
                                dispatch(setCurrentPrescriptions({ data: null }))
                                dispatch(setCurrentPrescriptionInvoice({ data: null }))
                                setModals({ ...modals, showPharmacistGenerateMultipleInvoiceModal: !modals.showPharmacistGenerateMultipleInvoiceModal })
                            }}
                            size={20}
                        />
                    </div>

                    {/* user info */}
                    <div className="flex items-center space-x-5">
                        <img
                            src={`${process.env.REACT_APP_API_ROOT}${multiple_prescriptions && multiple_prescriptions[0]?.patient_prescribed?.profile_photo}`}
                            alt=""
                            className="w-[80px] h-[80px] rounded-[50%]"
                        />

                        <div className="flex flex-col space-y-1">
                            <p className="text-[14px] text-gray-600 font-bold">{multiple_prescriptions ? multiple_prescriptions[0]?.patient_prescribed?.last_name : ''} {multiple_prescriptions ? multiple_prescriptions[0]?.patient_prescribed?.first_name : ''}</p>
                            <p className="text-[12px] text-gray-300 font-medium">{multiple_prescriptions ? multiple_prescriptions[0]?.patient_id : ''}</p>
                        </div>
                    </div>

                    <form className="max-h-[450px] overflow-y-auto patient__prescriptions">
                        {multiple_prescriptions?.map((prescription, index) => (
                            <div
                                className={`border-b ${index === 0 ? 'pb-2' : 'py-2'}`}
                                key={`${prescription.id}`}
                            >

                                <label className="bg-sky-600 text-white px-[5px] py-[1px] text-[10px] rounded">Prescription {index + 1}</label>
                                <div className="flex justify-between">
                                    <div className="space-y-1 w-[48%]">
                                        <div className="">
                                            <label className="px-[5px] py-[1px] text-[10px] rounded">Drug</label>
                                            <input
                                                type="text"
                                                value={prescription?.drugs}
                                                disabled
                                                className="border rounded w-full px-4 py- mb-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            />
                                        </div>
                                        <div>
                                            <label className="px-[5px] py-[1px] text-[10px] rounded">Route</label>
                                            <input
                                                type="text"
                                                disabled
                                                value={prescription?.routes}
                                                className="border rounded w-full px-4 py- mb-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            />
                                        </div>
                                        {/* <div>
                                    <label className="px-[5px] py-[1px] text-[10px] rounded">Unit</label>
                                    <input
                                        type="text"
                                        value={prescriptions_update?.units}
                                        className="border rounded w-full px-4 py- mb-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        />
                                    </div> */}

                                        <div>
                                            <label className="px-[5px] py-[1px] text-[10px] rounded">Price</label>
                                            <input
                                                type="number"
                                                name="price"
                                                placeholder="Cost per unit"
                                                className="border rounded w-full px-4 py- mb-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                disabled
                                                value={drugs_price?.filter(a => a.drug_name === prescription?.drugs)[0]?.selling_price}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </div>
                                    </div>

                                    <div className="space-y-1 w-[48%]">
                                        <div>
                                            <label className="px-[5px] py-[1px] text-[10px] rounded">Frequency</label>
                                            <input
                                                type="text"
                                                disabled
                                                value={prescription?.frequency}
                                                className="border rounded w-full px-4 py- mb-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            />
                                        </div>

                                        <div>
                                            <label className="px-[5px] py-[1px] text-[10px] rounded">Duration/Times</label>
                                            <input
                                                type="text"
                                                disabled
                                                value={prescription?.duration + '/' + prescription?.duration_time}
                                                className="border rounded w-full px-4 py- mb-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            />
                                        </div>

                                        <div>
                                            <label className="px-[5px] py-[1px] text-[10px] rounded">Quantity</label>
                                            <input
                                                type="number"
                                                name=""
                                                min={"0"}
                                                placeholder="Quantity"
                                                className="border border-orange-500 rounded w-full px-4 py- mb-1 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, prescription.pkid)}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))}
                    </form>


                    <div className="flex justify-between">
                        <button
                            type="button"
                            className="w-[270px] inline-block px-6 py-2 border border-teal-500 text-teal-600 font-bold text-sm leading-tight uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-500 ease-in-out"
                            onClick={calcTotal}
                        >
                            &#x20A6; {total === 0 ? 'Subtotal' : `${total}`}
                        </button>

                        <button
                            type="submit"
                            onClick={handleSubmit}
                            disabled={total === 0}
                            className="inline-block w-[270px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight shadow-md transition duration-500 ease"
                        >
                            Submit
                        </button>
                    </div>


                    {/* <div className="mt-4 flex justify-start">
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default PharmacistGenerateMultipleInvoicesModal
