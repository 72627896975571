import { Fragment, useEffect, useState } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { setSupplyPoint } from '../../../state/actions/actions'
import { deleteSupplyPoint, getDrugs } from '../../../state/actions/pharmacist.actions'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { drug__frequency, drug__routes, drug__units } from '../../../data'
import { BsDash, BsDashCircle, BsDashSquare } from 'react-icons/bs'
import { createPatientOtherPrescription, createPatientPrescription } from '../../../state/actions/doctor.actions'


const DoctorAddOtherPrescriptionModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { patient_update } = useSelector(state => state.root)
    const { drugs } = useSelector(state => state.pharmacist)
    const { new_diagnosis } = useSelector(state => state.doctor)
    const [formData, setFormData] = useState([{
        drug: '', routes: '', units: '',
        frequency: '', duration: '', duration_time: '',
        strength: '', patient: patient_update?.details?.pkid,
        diagnosis: new_diagnosis?.pkid,
        other_prescription: true
    }])

    useEffect(() => {
        dispatch(getDrugs())
    }, [])

    const handleChange = (index, e) => {
        let data = [...formData]
        data[index][e.target.name] = e.target.value
        setFormData(data)
    }

    const addPrescription = (e) => {
        e.preventDefault()
        setFormData([
            ...formData,
            {
                drug: '', routes: '', units: '', frequency: '',
                duration: '', duration_time: '', strength: '',
                patient: patient_update?.details?.pkid,
                diagnosis: new_diagnosis?.pkid,
                other_prescription: true
            }
        ])
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        console.log(formData)

        dispatch(createPatientOtherPrescription({ formData, toast, setModals, modals }));
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[800px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className='text-sky-500 text-[20px] font-bold'>Add Prescription</h4>
                        <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                setModals({ ...modals, showDoctorAddOtherPrescriptionModal: !modals.showDoctorAddOtherPrescriptionModal })
                            }}
                            size={20}
                        />
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div
                            className={`space-y-2 ${formData.length > 2 && 'h-[450px] overflow-y-auto patient__prescriptions'}`}
                        >
                            {formData?.map((item, index) => (
                                <Fragment key={index}>
                                    <div className="flex justify-between items-center">
                                        <label
                                            className="bg-violet-600 text-white px-[5px] py-[1px] text-[10px] rounded"
                                        >Prescription: {index + 1}</label>

                                        {index !== 0 && (
                                            <BsDashSquare
                                                size={30}
                                                onClick={() => setFormData(formData.filter((data, _index) => (_index + 1) !== (index + 1)))}
                                                className="text-orange-500 px-[5px] py-[0px] text-[10px] rounded cursor-pointer"
                                            />
                                        )}
                                    </div>
                                    <div className={`flex justify-between items-start w-full ${index !== formData.length - 1 && 'pb-5 border-b-2 border-gray-600'}`}>
                                        <div className="space-y-2 w-[48%]">

                                            <div className="relative">
                                                <select
                                                    className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-1 px-4 pr-8 rounded leading-tight focus:outline-none "
                                                    name="drug"
                                                    onChange={(e) => handleChange(index, e)}
                                                >
                                                    <option value="">Drug</option>
                                                    {drugs?.map((drug, index) => (
                                                        <option
                                                            key={`${drug.id}`}
                                                            value={drug.pkid}
                                                        >
                                                            {drug.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                    <svg
                                                        className="fill-current h-4 w-4"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                    </svg>
                                                </div>
                                            </div>

                                            <div className="relative">
                                                <select
                                                    className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-1 px-4 pr-8 rounded leading-tight focus:outline-none "
                                                    name="routes"
                                                    onChange={(e) => handleChange(index, e)}
                                                >
                                                    <option>Route</option>
                                                    {drug__routes?.map((route, index) => (
                                                        <option
                                                            key={`${index}`}
                                                            value={route.value}
                                                        >{route.title}</option>
                                                    ))}
                                                </select>
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                    <svg
                                                        className="fill-current h-4 w-4"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                    </svg>
                                                </div>
                                            </div>

                                            <div>
                                                <input
                                                    type="text"
                                                    name="duration"
                                                    placeholder="Duration"
                                                    className="border rounded w-full h-5 px-4 py-3 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                    onChange={(e) => handleChange(index, e)}
                                                />
                                            </div>
                                        </div>

                                        <div className="space-y-2 w-[48%]">
                                            <div className="relative">
                                                <select
                                                    className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-1 px-4 pr-8 rounded leading-tight focus:outline-none "
                                                    name="units"
                                                    onChange={(e) => handleChange(index, e)}
                                                >
                                                    <option value="">Units</option>
                                                    {drug__units?.map((unit, index) => (
                                                        <option
                                                            key={`${index}`}
                                                            value={unit.value}
                                                        >{unit.title}</option>
                                                    ))}
                                                </select>
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                    <svg
                                                        className="fill-current h-4 w-4"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                    </svg>
                                                </div>
                                            </div>

                                            <div className="relative">
                                                <select
                                                    className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-1 px-4 pr-8 rounded leading-tight focus:outline-none "
                                                    name="frequency"
                                                    onChange={(e) => handleChange(index, e)}
                                                >
                                                    <option value="">Frequency</option>
                                                    {drug__frequency?.map((frequency, index) => (
                                                        <option
                                                            key={`${index}`}
                                                            value={frequency.value}
                                                        >{frequency.title}</option>
                                                    ))}
                                                </select>
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                    <svg
                                                        className="fill-current h-4 w-4"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                    </svg>
                                                </div>
                                            </div>

                                            <div>
                                                <input
                                                    type="text"
                                                    name="duration_time"
                                                    placeholder="Times"
                                                    className="border rounded w-full h-5 px-4 py-3 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                    onChange={(e) => handleChange(index, e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            ))}

                        </div>
                        <div className='flex justify-between mt-4'>
                            <button
                                type='submit'
                                className="inline-block w-[175px] px-6 py-2.5 border-2 border-dark-blue text-dark-blue font-medium text-xs leading-tight rounded shadow-md transition duration-500 ease"
                                onClick={addPrescription}
                            >Add</button>

                            <button
                                type='submit'
                                className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >Submit</button>
                        </div>

                    </form>
                </div>


            </div>
        </div>
    )
}
export default DoctorAddOtherPrescriptionModal
