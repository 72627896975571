/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { FaTimesCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux"

import { useStateContext } from '../../../state/context'
import { setDirectorate } from "../../../state/actions/actions";
import { createDirectorate, updateDirectorate } from "../../../state/actions/admin.actions";


const CreateDirectorateModal = (props) => {
	const dispatch = useDispatch()

	const { modals, setModals } = useStateContext()

	const [checked, setChecked] = useState(false);
	const [formData, setFormData] = useState({ directorate_name: "", description: "", status: false, });

	const { directorate_update } = useSelector(stata => stata.root)

	useEffect(() => {
		window.addEventListener('keydown', (e) => {
			if (e.key === 'Escape') {
				modals.showAddDirectorateModal && setModals({ ...modals, showAddDirectorateModal: !modals.showAddDirectorateModal })
				modals.showUpdateDirectorateModal && setModals({ ...modals, showUpdateDirectorateModal: !modals.showUpdateDirectorateModal })
			}
		})
	}, [])

	useEffect(() => {
		setChecked(directorate_update?.status ? directorate_update?.status : checked)
		setFormData({ ...formData, directorate_name: directorate_update?.directorate_name, description: directorate_update?.description, status: directorate_update?.status })
	}, [directorate_update])

	const handleChange = (e, name) => {
		setFormData((prevState) => ({ ...prevState, [name]: e.target.value }));
	};

	const handleCheckboxChange = () => {
		setChecked(!checked);
		setFormData({ ...formData, status: !checked });
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if(!formData.directorate_name || !formData.description) return toast.warning('Please fill all fields')
		dispatch(createDirectorate({ formData, toast, setModals: setModals, modals: modals }));
	};
	
	const handleUpdate = (e) => {
		e.preventDefault();
		
		if(!formData.directorate_name || !formData.description) return toast.warning('Please fill all fields')
		dispatch(updateDirectorate({ formData: { ...formData, id: directorate_update.id }, toast, setModals: setModals, modals: modals }));
	};
	return (
		<div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
			<div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
				<div className="flex flex-col space-y-5">
					<div className="flex justify-between items-center">
						<h4 className="text-dark-green text-[18px] font-medium">
							{modals.showUpdateDirectorateModal ? 'Update Directorate' : 'Create Directorate'}
						</h4>
						<FaTimesCircle
							className="text-dark-yellow cursor-pointer"
							onClick={() => {
								modals.showAddDirectorateModal && setModals({ ...modals, showAddDirectorateModal: !modals.showAddDirectorateModal })
								modals.showUpdateDirectorateModal && setModals({ ...modals, showUpdateDirectorateModal: !modals.showUpdateDirectorateModal })
							}}
							size={20}
						/>
					</div>

					{modals.showAddDirectorateModal && (
						<form onSubmit={handleSubmit}>
							<div>
								<input
									type="text"
									name="directorate_name"
									className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
									placeholder="Directorate name"
									onChange={(e) => handleChange(e, e.target.name)}
								/>
							</div>
							<div>
								<textarea
									name="description"
									cols="30"
									rows="7"
									className="border rounded w-full px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue resize-none"
									placeholder="Description"
									onChange={(e) => handleChange(e, e.target.name)}
								></textarea>
							</div>
							<div className="flex items-center space-x-3">
								<input
									className="rounded border-4 w-5 h-5 border-red-400"
									type="checkbox"
									name="status"
									checked={checked}
									// value={}
									onChange={handleCheckboxChange}
								/>
								<p className="text-[16px] text-gray-700 font-bold">Status</p>
							</div>

							<div className="mt-4">
								<button
									type="submit"
									className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
								>
									Submit
								</button>
							</div>
						</form>
					)}

					{modals.showUpdateDirectorateModal && (
						<form onSubmit={handleUpdate}>
							<div>
								<input
									type="text"
									name="directorate_name"
									className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
									placeholder="Directorate name"
									value={formData.directorate_name}
									onChange={(e) => handleChange(e, e.target.name)}
								/>
							</div>
							<div>
								<textarea
									name="description"
									cols="30"
									rows="7"
									className="border rounded w-full px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue resize-none"
									placeholder="Description"
									value={formData.description}
									onChange={(e) => handleChange(e, e.target.name)}
								></textarea>
							</div>
							<div className="flex items-center space-x-3">
								<input
									className="rounded border-4 w-5 h-5 border-red-400"
									type="checkbox"
									name="status"
									checked={checked}
									onChange={handleCheckboxChange}
								/>
								<p className="text-[16px] text-gray-700 font-bold">Status</p>
							</div>

							<div className="mt-4">
								<button
									type="submit"
									className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
								>
									Update
								</button>
							</div>
						</form>
					)}
				</div>
			</div>
		</div>
	);
};

export default CreateDirectorateModal;
