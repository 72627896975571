/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DoctorBookedApppointmentsTable, DoctorPatientAppointmentsTable, PageTitle } from '../../components'
import { getAppointments, getDoctorPatients } from '../../state/actions/doctor.actions'


const DoctorAppointments = () => {
	const dispatch = useDispatch()

	const { data } = useSelector(state => state.auth.user)
	const { doctor_patients, doctor_appointments, updated_appointment } = useSelector(state => state.doctor)

	const [activeLink, setActiveLink] = useState('Patients')
	const [showPatientsTable, setShowPatientsTable] = useState(true)
	const [showBookedAppointments, setShowBookedAppointments] = useState(false)
	const [showBookedForAppointments, setShowBookedForAppointments] = useState(false)
	const [filterState, setFilterState] = useState({ search: '', patients: [], booked: [], booked_tome: [] })

	const handlePatientsFilter = () => {
		const filtered = doctor_patients?.filter((patient =>
			!doctor_appointments?.find(pat => pat.patient.pkid === patient.details.pkid)
		))?.filter(item => {
			return item.patient_number.toLowerCase().includes(filterState.search.toLowerCase())
				|| item.details.first_name.toLowerCase().includes(filterState.search.toLowerCase())
				|| item.details.last_name.toLowerCase().includes(filterState.search.toLowerCase())
		})

		setFilterState({ ...filterState, patients: filtered })

		if (filterState.search === '') {
			setFilterState({
				...filterState, patients: doctor_patients?.filter((patient =>
					!doctor_appointments?.find(pat => pat.patient.pkid === patient.details.pkid)
				))
			})
		}
	}

	const handleBookedFilter = () => {
		const filtered = doctor_appointments?.filter(app => app.date === new Date().toISOString().slice(0, 10) && app.appointment_author === data?.pkid)
			?.filter(item => {
				return item.patient.patient_number.toLowerCase().includes(filterState.search.toLowerCase())
					|| item.patient.first_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| item.patient.last_name.toLowerCase().includes(filterState.search.toLowerCase())
			})

		setFilterState({ ...filterState, booked: filtered })

		if (filterState.search === '') {
			setFilterState({
				...filterState, booked: doctor_appointments?.filter(app => app.date === new Date().toISOString().slice(0, 10) && app.appointment_author === data?.pkid)
			})
		}
	}

	const handleBookedToMeFilter = () => {
		const filtered = doctor_appointments?.filter(app => app.date === new Date().toISOString().slice(0, 10) && app.appointment_author !== data?.pkid)
			?.filter(item => {
				return item.patient.patient_number.toLowerCase().includes(filterState.search.toLowerCase())
					|| item.patient.first_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| item.patient.last_name.toLowerCase().includes(filterState.search.toLowerCase())
			})

		setFilterState({ ...filterState, booked_tome: filtered })

		if (filterState.search === '') {
			setFilterState({
				...filterState, booked_tome: doctor_appointments?.filter(app => app.date === new Date().toISOString().slice(0, 10) && app.appointment_author !== data?.pkid)
			})
		}
	}

	useEffect(() => {
		dispatch(getAppointments())
		dispatch(getDoctorPatients({ doc_name: data?.pkid }))
	}, [data])

	useEffect(() => {
		if (updated_appointment?.id) {
			dispatch(getAppointments())
			dispatch(getDoctorPatients({ doc_name: data?.pkid }))
		}
	}, [updated_appointment])

	useEffect(() => {
		if (activeLink === 'Patients') {
			handlePatientsFilter()
		}
		if (activeLink === 'Booked') {
			handleBookedFilter()
		}
		if (activeLink === 'Booked To Me') {
			handleBookedToMeFilter()
		}
	}, [filterState.search])

	useEffect(() => {
		setFilterState({
			...filterState,
			patients: doctor_patients?.filter((patient =>
				!doctor_appointments?.find(pat => pat.patient.pkid === patient.details.pkid)
			)),
			booked: doctor_appointments?.filter(app => app.date === new Date().toISOString().slice(0, 10) && app.appointment_author === data?.pkid),
			booked_tome: doctor_appointments?.filter(app => app.date === new Date().toISOString().slice(0, 10) && app.appointment_author !== data?.pkid)
		})
	}, [doctor_appointments])

	return (
		<div>
			<PageTitle type={'doctor-appointments'} />

			<div className="space-y-2">
				<div className="flex w-full justify-between">
					<div className="space-x-5">
						<button
							type="button"
							className={`${activeLink === 'Patients' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => setActiveLink(e.target.textContent)}
						>
							Patients
						</button>
						<button
							type="button"
							className={`${activeLink === 'Booked' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => setActiveLink(e.target.textContent)}
						>
							Booked
						</button>
						<button
							type="button"
							className={`${activeLink === 'Booked To Me' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => setActiveLink(e.target.textContent)}
						>
							Booked To Me
						</button>
					</div>

					<input
						type="text"
						name="search"
						placeholder='Search'
						className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
						onChange={(e) => setFilterState({ ...filterState, [e.target.name]: e.target.value })}
					/>

				</div>
				{activeLink === 'Patients' && (
					<DoctorPatientAppointmentsTable
						title={'Patients'}
						data={filterState.patients}
						showList={showPatientsTable}
						setShowList={setShowPatientsTable}
					/>
				)}

				{activeLink === 'Booked' && (
					<DoctorBookedApppointmentsTable
						title={'Booked'}
						data={filterState.booked}
						showList={showBookedAppointments}
						setShowList={setShowBookedAppointments}
					/>
				)}

				{activeLink === 'Booked To Me' && (
					<DoctorBookedApppointmentsTable
						title={'Booked To Me'}
						data={filterState.booked_tome}
						showList={showBookedForAppointments}
						setShowList={setShowBookedForAppointments}
					/>
				)}


			</div>
		</div >
	)
}

export default DoctorAppointments