/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	getAllEmployees, getAllDepartments, getAllDirectorates,
	getAllBillings, getAllWards, getAllSentSMS,
	getAllReceivedSMS, getAllSentEmails, getAllReceivedEmails, getAllBedspaces, getOpdPrescriptions,
} from "../../state/actions/admin.actions";
import { EmployeeOverviewTable, PageTitle, SectionsSummary } from "../../components";


const AdminDashboard = () => {
	const dispatch = useDispatch()

	const { employees, opd_prescriptions } = useSelector(state => state.admin)

	const active_accountants = employees?.filter(employee => employee.user_details.is_accountant === true && employee.user_details.is_active === true).length
	const active_patients = employees?.filter(employee => employee.user_details.is_patient === true && employee.user_details.is_active === true).length
	const active_doctors = employees?.filter(employee => employee.user_details.is_doctor === true && employee.user_details.is_active === true).length
	const active_pharmacists = employees?.filter(employee => employee.user_details.is_pharmacist === true && employee.user_details.is_active === true).length
	const active_nurses = employees?.filter(employee => employee.user_details.is_nurse === true && employee.user_details.is_active === true).length
	const active_radiologists = employees?.filter(employee => employee.user_details.is_radiologist === true && employee.user_details.is_active === true).length
	const active_laboratorists = employees?.filter(employee => employee.user_details.is_laboratorist === true && employee.user_details.is_active === true).length
	const active_records = employees?.filter(employee => employee.user_details.is_records === true && employee.user_details.is_active === true).length
	const active_admins = employees?.filter(employee => employee.user_details.is_administrator === true && employee.user_details.is_active === true).length

	useEffect(() => {
		dispatch(getAllEmployees());

		// dispatch(getAllBillings());
		// dispatch(getAllWards());
		// dispatch(getAllBedspaces())
		// dispatch(getAllSentSMS());
		// dispatch(getAllReceivedSMS());
		// dispatch(getAllReceivedEmails());
		// dispatch(getAllSentEmails());
		// dispatch(getOpdPrescriptions());
	}, []);

	return (
		<div>
			<PageTitle type={"admin-dashboard"} />

			<SectionsSummary
				accountants={active_accountants}
				patients={active_patients}
				newpatients={active_patients}
				doctors={active_doctors}
				pharmacists={active_pharmacists}
				opd_prescriptions={opd_prescriptions}
				nurses={active_nurses}
				radiologists={active_radiologists}
				laboratorists={active_laboratorists}
				records={active_records}
				admins={active_admins}
			/>
		</div>
	);
};

export default AdminDashboard;
