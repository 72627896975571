/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Pagination from '../../utils/Pagination'
import { useStateContext } from '../../../state/context'
import { getAllBirthRecords } from '../../../state/actions/nurse.actions'
import { setBirthAndDeathRecordDetail } from '../../../state/actions/actions'


const BirthRecordsTable = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { all_birth_records } = useSelector((state) => state.nurse)

    const [currentData, setCurrentData] = useState([])

    useEffect(() => {
        dispatch(getAllBirthRecords())
    }, [modals.showNursesAddBirthRecordModal])

    return (
        <Fragment>
            <div className='flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1'>
                <h4 className='text-dark-blue text-[16px] font-semibold'>
                    Birth Records

                </h4>
            </div>
            <table className='border rounded w-full cursor-default'>
                <thead className=''>
                    <tr>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-1 py-4 text-left'
                        >
                            S/N
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-1 py-4 text-left'
                        >
                            Patient Number
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-1 py-4 text-left'
                        >
                            Patient Number
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-1 py-4 text-left'
                        >
                            Guardian Name
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-1 py-4 text-left'
                        >
                            Signature
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-1 py-4 text-left'
                        >
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {currentData?.map((item, index) => (
                        <tr
                            className='relative'
                            key={index}
                        >
                            <td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
                                {index + 1}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.patient_number}      </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.first_name} {item.last_name}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.guardian_name}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item?.record_addedby?.first_name} {item?.record_addedby?.last_name}
                            </td>
                            <td className=' text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                <div className='flex space-x-4 relative '>
                                    <button
                                        type="button"
                                        className="inline-block px-6 py-2.5 bg-blue-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                        onClick={() => {
                                            const data = {
                                                patient_number: item?.patient_number,
                                                first_name: item?.first_name,
                                                last_name: item?.last_name,
                                                time: item?.time,
                                                date: item?.date_of_birth,
                                                guardian_name: item?.guardian_name,
                                                address: item?.address,
                                                gender: item?.gender,
                                                phone_number: item?.phone_number
                                            }

                                            dispatch(setBirthAndDeathRecordDetail({ data }))
                                            setModals({ ...modals, showNurseViewBirthRecordModal: !modals.showNurseViewBirthRecordModal })
                                        }}
                                    >
                                        View Record
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <Pagination
                data={all_birth_records}
                currentData={currentData}
                setCurrentData={setCurrentData}
            />
        </Fragment>
    )
}

export default BirthRecordsTable