import { Fragment } from 'react'
import moment from 'moment'
import ReactEmoji from 'react-emoji'


const ChatBubble = ({ type, data, index }) => {
    const tConvert = (time) => {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? '  AM' : '  PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join('')// return adjusted time or original string
    }

    const now = moment(data?.time)

    return (
        <Fragment>
            {type === 'receiver' && (
                <Fragment>
                    {data?.chat_conversation_details?.conversation_type === 1 ? (
                        <div className={`message flex items-center ${index === 0 && 'mt-2'}`}>
                            {data?.message_type === 'text' && (
                                <div className="ml-5 px-4 max-w-[380px] bg-sky-800 rounded-[15px] shadow-sm pt-1">
                                    <p className='text-[12px] text-white font-normal leading-[16px]'>{ReactEmoji.emojify(data?.message)}</p>

                                    <div className="flex justify-end">
                                        <p className='text-[10px] text-white font-normal'>{now.format('LT')}</p>
                                    </div>
                                </div>
                            )}

                            {data?.message_type === 'image' && (
                                <div className='ml-5'>
                                    <img
                                        src={`${process.env.REACT_APP_API_ROOT}${data?.image}`}
                                        alt=""
                                        className='w-[250px] h-[150px]'
                                    />
                                    <div className="flex justify-end h-[30px]">
                                        <p className='text-[10px] text-slate-600 font-bold px-2 rounded-b-md'>{now.format('LT')}</p>
                                    </div>
                                </div>
                            )}

                            {data?.message_type === 'document' && (
                                <div className='ml-5'>
                                    <a
                                        href={`${process.env.REACT_APP_API_ROOT}${data?.file}`}
                                        target='_blank'
                                        className='text-blue-600 text-[10px]'
                                    >
                                        {data?.file_name}
                                    </a>

                                    <div className="flex justify-end h-[30px]">
                                        <p className='text-[10px] text-slate-600 font-bold px-2 rounded-b-md'>{now.format('LT')}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <Fragment>
                            {data?.message_type === 'admin' && (
                                <div className={`message flex items-center justify-center ${index === 0 && 'mt-2'}`}>

                                    <div className={`ml-5 px-2 max-w-[380px] bg-slate-600 rounded-[15px] shadow-sm p-1`}>
                                        <div>
                                            <p className='text-[10px] text-white font-normal leading-[16px]'>{ReactEmoji.emojify(data?.message)}</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {data?.message_type === 'text' && (
                                <div className={`message flex items-start ${index === 0 && 'mt-2'} -py-2 -space-x-1`}>
                                    <img
                                        src={`${process.env.REACT_APP_API_ROOT}${data?.sender_details?.profile_photo}`}
                                        alt="user"
                                        className='mr-2 w-[40px] h-[40px] rounded-[100%]'
                                    />
                                    <div className={`ml-5 px-2 max-w-[380px] ${data?.message_type === 'image' ? 'bg-transparent' : data?.message_type === 'document' ? 'bg-transparent' : 'bg-sky-800'} rounded-[15px] shadow-sm -space-y-2`}>
                                        <div className="justify-start text-white">
                                            <p className={`text-[10px] text-white ${data?.message_type === 'image' ? 'text-sky-800' : data?.message_type === 'document' ? 'text-sky-800' : 'text-white'}  font-medium`}> {data?.sender_details?.last_name} {data?.sender_details?.first_name}</p>
                                        </div>
                                        <div>
                                            <p className='text-[12px] text-white font-normal leading-[16px]'>{ReactEmoji.emojify(data?.message)}</p>

                                            <div className="flex justify-end">
                                                <p className='text-[10px] text-white font-normal'>{now.format('LT')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {data?.message_type === 'image' && (
                                <div className={`message flex items-start ${index === 0 && 'mt-2'} -py-2 -space-x-1`}>
                                    <img
                                        src={`${process.env.REACT_APP_API_ROOT}${data?.sender_details?.profile_photo}`}
                                        alt="user"
                                        className='mr-2 w-[40px] h-[40px] rounded-[100%]'
                                    />
                                    <div className={`ml-5 px-2 max-w-[380px] ${data?.message_type === 'image' ? 'bg-transparent' : data?.message_type === 'document' ? 'bg-transparent' : 'bg-sky-800'} rounded-[15px] shadow-sm -space-y-2`}>
                                        <div className="justify-start text-white">
                                            <p className={`text-[10px] text-white ${data?.message_type === 'image' ? 'text-sky-800' : data?.message_type === 'document' ? 'text-sky-800' : 'text-white'}  font-medium`}> {data?.sender_details?.last_name} {data?.sender_details?.first_name}</p>
                                        </div>
                                        <div>
                                            <img
                                                src={`${process.env.REACT_APP_API_ROOT}${data?.image}`}
                                                alt=""
                                                className='w-[250px] h-[150px]'
                                            />
                                            <div className="flex justify-end h-[30px]">
                                                <p className='text-[10px] text-slate-600 font-bold px-2 rounded-b-md'>{now.format('LT')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {data?.message_type === 'document' && (
                                <div className={`message flex items-start ${index === 0 && 'mt-2'} -py-2 -space-x-1`}>
                                    <img
                                        src={`${process.env.REACT_APP_API_ROOT}${data?.sender_details?.profile_photo}`}
                                        alt="user"
                                        className='mr-2 w-[40px] h-[40px] rounded-[100%]'
                                    />
                                    <div className={`ml-5 px-2 max-w-[380px] ${data?.message_type === 'image' ? 'bg-transparent' : data?.message_type === 'document' ? 'bg-transparent' : 'bg-sky-800'} rounded-[15px] shadow-sm -space-y-2`}>
                                        <div className="justify-start text-white">
                                            <p className={`text-[10px] text-white ${data?.message_type === 'image' ? 'text-sky-800' : data?.message_type === 'document' ? 'text-sky-800' : 'text-white'}  font-medium`}> {data?.sender_details?.last_name} {data?.sender_details?.first_name}</p>
                                        </div>
                                        <div className='ml-5'>
                                            <a
                                                href={`${process.env.REACT_APP_API_ROOT}${data?.file}`}
                                                target='_blank'
                                                className='text-blue-600 text-[10px]'
                                            >
                                                {data?.file_name}
                                            </a>

                                            <div className="flex justify-end h-[30px]">
                                                <p className='text-[10px] text-slate-600 font-bold px-2 rounded-b-md'>{now.format('LT')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </Fragment>
                    )}
                </Fragment >
            )
            }

            {
                type === 'sender' && (
                    <Fragment>
                        {data?.message_type === 'admin' && (
                            <div className={`flex justify-center items-center ${index === 0 && 'mt-2'}`}>
                                <div className="message flex items-start">
                                    <div className="mr-5 px-4 bg-slate-600 rounded-[15px] shadow-lg p-1">
                                        <p className='text-[10px] text-white font-normal leading-[16px] text-center'>{ReactEmoji.emojify(data?.message)}</p>
                                    </div>
                                </div>
                            </div>
                        )}

                        {data?.message_type === 'text' && (
                            <div className={`flex justify-end items-end ${index === 0 && 'mt-2'}`}>
                                <div className="message flex items-start">
                                    <div className="mr-5 px-4 max-w-[380px] bg-violet-800 border rounded-[15px] shadow-lg -space-y-2 pt-2">
                                        <p className='text-[12px] text-white font-normal leading-[16px]'>{ReactEmoji.emojify(data?.message)}</p>

                                        <div className="flex justify-end">
                                            <p className='text-[10px] text-white font-normal'>{now.format('LT')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {data?.message_type === 'image' && (
                            <div className={`flex justify-end items-end ${index === 0 && 'mt-2'}`}>
                                <div className="message flex items-start">
                                    <div className='mr-5'>
                                        <img
                                            src={`${process.env.REACT_APP_API_ROOT}${data?.image}`}
                                            alt=""
                                            className='w-[250px] h-[150px]'
                                        />
                                        <div className="flex justify-end h-[30px]">
                                            <p className='text-[10px] text-slate-600 font-bold px-2 rounded-b-md'>{now.format('LT')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {data?.message_type === 'document' && (
                            <div className={`flex justify-end items-end ${index === 0 && 'mt-2'}`}>
                                <div className="message flex items-start">
                                    <div className='mr-5'>
                                        <a
                                            href={`${process.env.REACT_APP_API_ROOT}${data?.file}`}
                                            target='_blank'
                                            className='text-blue-600 text-[10px]'
                                        >
                                            {data?.file_name}
                                        </a>

                                        <div className="flex justify-end h-[30px]">
                                            <p className='text-[10px] text-slate-600 font-bold px-2 rounded-b-md'>{now.format('LT')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        )}
                    </Fragment>


                )
            }
        </Fragment >
    )
}

export default ChatBubble
