/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import { useStateContext } from "../../../state/context"
import { createDrug, getDrugCategories, getSupplyPoints, updateDrug } from "../../../state/actions/pharmacist.actions"
import { setCurrentDrug } from "../../../state/actions/actions"
import ButtonLoader from "../../utils/ButtonLoader"


const PharmacistAddDrugModal = (props) => {
	const dispatch = useDispatch()

	const { modals, setModals } = useStateContext()

	const { drug_update } = useSelector(state => state.root)
	const { drugCategories, pharmacistLoading } = useSelector(state => state.pharmacist)

	const [checked, setChecked] = useState(false)
	const [formData, setFormData] = useState({
		drug_category: '',
		name: "",
		drug_substitute: "",
		quantity: "",
		alert_quantity: "",
		locked: false,
	})

	useEffect(() => {
		setChecked(drug_update?.locked ? drug_update?.locked : checked)
		setFormData({
			...formData,
			drug_category: drug_update?.drug_category.id,
			name: drug_update?.name,
			drug_substitute: drug_update?.drug_substitute,
			quantity: drug_update?.quantity,
			alert_quantity: drug_update?.alert_quantity,
			locked: drug_update?.locked
		})
	}, [drug_update?.id])

	useEffect(() => {
		dispatch(getDrugCategories())
		dispatch(getSupplyPoints())
	}, [])

	const handleChange = (e, name) => {
		setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		// if (!formData.drug_category || !formData.name || !formData.quantity || !formData.alert_quantity) return toast.warning('Please fill all fields')
		dispatch(createDrug({ formData: { ...formData, quantity: 0 }, toast, setModals, modals }))
	}

	const handleUpdate = (e) => {
		e.preventDefault()

		console.log(formData)
		dispatch(updateDrug({ formData: { ...formData, id: drug_update?.id }, toast, setModals, modals, dispatch, setCurrentDrug }))
	}

	return (
		<div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
			<div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
				<div className="flex flex-col space-y-5">
					<div className="flex justify-between items-center">
						<h4 className={`${drug_update ? 'text-sky-500' : 'text-dark-green'} text-[20px] font-medium capitalize`}>
							{modals.showPharmacistAddDrugModal ? 'Add Drug' : modals.showPharmacistUpdateDrugModal ? 'Update Drug' : 'View Drug'}
						</h4>
						<FaTimesCircle
							className="text-dark-yellow cursor-pointer"
							onClick={() => {
								dispatch(setCurrentDrug({ data: null }))
								modals.showPharmacistAddDrugModal && setModals({ ...modals, showPharmacistAddDrugModal: !modals.showPharmacistAddDrugModal })
								modals.showPharmacistUpdateDrugModal && setModals({ ...modals, showPharmacistUpdateDrugModal: !modals.showPharmacistUpdateDrugModal })
								modals.showPharmacistViewDrugModal && setModals({ ...modals, showPharmacistViewDrugModal: !modals.showPharmacistViewDrugModal })
							}}
							size={20}
						/>
					</div>

					{modals.showPharmacistAddDrugModal && (
						<form onSubmit={handleSubmit}>
							<div className="space-y-2">
								<div className="relative">
									<select
										className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none "
										name="drug_category"
										onChange={(e) => handleChange(e, e.target.name)}
									>
										<option value="">Drug Category</option>
										{drugCategories?.map((category, index) => (
											<option
												key={`${category.id}`}
												value={category.id}
											>{category.category}</option>
										))}
									</select>
									<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
										<svg
											className="fill-current h-4 w-4"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 20 20"
										>
											<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
										</svg>
									</div>
								</div>

								<div>
									<input
										type="text"
										name="name"
										placeholder="Medicine Name"
										className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
										onChange={(e) => handleChange(e, e.target.name)}
									/>
								</div>

								<div>
									<input
										type="text"
										name="drug_substitute"
										placeholder="Medicine Substitute"
										className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
										onChange={(e) => handleChange(e, e.target.name)}
									/>
								</div>

								<div>
									<input
										type="hidden"
										name="quantity"
										placeholder="Quantity"
										className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
										onChange={(e) => handleChange(e, e.target.name)}
									/>
								</div>

								<div>
									<input
										type="number"
										name="alert_quantity"
										placeholder="Alert Quantity"
										className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
										onChange={(e) => handleChange(e, e.target.name)}
									/>
								</div>

								{/* <div className="flex items-center space-x-3">
									<input
										className="rounded border-4 w-5 h-5 border-red-400"
										type="checkbox"
										name="locked"
										checked={checked}
										onChange={() => {
											setChecked(!checked);
											setFormData({ ...formData, locked: !checked });
										}}
									/>
									<p className="text-[12px] text-gray-700 font-bold">Lock</p>
								</div> */}
							</div>

							<div className="mt-4">
								<button
									type="submit"
									className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
								>
									{!pharmacistLoading ? (
										'Submit'
									) : (
										<ButtonLoader />
									)}
								</button>
							</div>
						</form>
					)}

					{modals.showPharmacistUpdateDrugModal && (
						<form onSubmit={handleUpdate}>
							<div className="space-y-2">
								<div className="relative">
									<select
										className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none "
										name="drug_category"
										onChange={(e) => handleChange(e, e.target.name)}
									>
										<option>{drug_update?.drug_category.category}</option>
										{drugCategories?.map((category, index) => (
											<option
												key={`${category.id}`}
												value={category.id}
											>{category.category}</option>
										))}
									</select>
									<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
										<svg
											className="fill-current h-4 w-4"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 20 20"
										>
											<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
										</svg>
									</div>
								</div>

								<div>
									<input
										type="text"
										name="name"
										placeholder="Medicine Name"
										className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
										value={formData?.name}
										onChange={(e) => handleChange(e, e.target.name)}
									/>
								</div>

								<div>
									<input
										type="text"
										name="drug_substitute"
										placeholder="Medicine Substitute"
										className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
										value={formData?.drug_substitute}
										onChange={(e) => handleChange(e, e.target.name)}
									/>
								</div>

								<div>
									<input
										type="hidden"
										name="quantity"
										placeholder="Quantity"
										className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
										value={formData?.quantity}
										onChange={(e) => handleChange(e, e.target.name)}
									/>
								</div>

								<div>
									<input
										type="number"
										name="alert_quantity"
										placeholder="Alert Quantity"
										className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
										value={formData?.alert_quantity}
										onChange={(e) => handleChange(e, e.target.name)}
									/>
								</div>

								{/* <div className="flex items-center space-x-3">
									<input
										className="rounded border-4 w-5 h-5 border-red-400"
										type="checkbox"
										name="locked"
										checked={formData?.locked}
										onChange={() => {
											setChecked(!checked);
											setFormData({ ...formData, locked: !checked });
										}}
									/>
									<p className="text-[12px] text-gray-700 font-bold">Lock</p>
								</div> */}
							</div>

							<div className="mt-4">
								<button
									type="submit"
									className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
								>
									{!pharmacistLoading ? (
										'Submit'
									) : (
										<ButtonLoader />
									)}
								</button>
							</div>
						</form>
					)}

					{modals.showPharmacistViewDrugModal && (
						<div>
							<div className="space-y-2">
								<div>
									<label className="bg-gray-500 text-white px-[5px] py-[1px] text-[10px] rounded mb-[-10px]">Medicine Name</label>
									<input
										type="text"
										name="name"
										placeholder="Medicine Name"
										className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
										disabled
										value={drug_update?.name}
										onChange={(e) => handleChange(e, e.target.name)}
									/>
								</div>

								<div>
									<label className="bg-gray-500 text-white px-[5px] py-[1px] text-[10px] rounded mb-[-10px]">Medicine Substitute</label>
									<input
										type="text"
										name="drug_substitute"
										placeholder="Medicine Substitute"
										className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
										disabled
										value={drug_update?.drug_substitute}
										onChange={(e) => handleChange(e, e.target.name)}
									/>
								</div>

								<div>
									<label className="bg-gray-500 text-white px-[5px] py-[1px] text-[10px] rounded mb-[-10px]">Quantity</label>
									<input
										type="number"
										name="quantity"
										placeholder="Quantity"
										className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
										disabled
										value={drug_update?.quantity}
										onChange={(e) => handleChange(e, e.target.name)}
									/>
								</div>

								<div>
									<label className="bg-gray-500 text-white px-[5px] py-[1px] text-[10px] rounded mb-[-10px]">Alert Quantity</label>
									<input
										type="number"
										name="alert_quantity"
										placeholder="Alert Quantity"
										className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
										disabled
										value={drug_update?.alert_quantity}
										onChange={(e) => handleChange(e, e.target.name)}
									/>
								</div>

								<div>
									<label className={`${drug_update?.locked ? 'bg-yellow-500' : 'bg-teal-500'} text-white px-[10px] py-[4px] text-[10px] rounded mb-[-10px]`}>Locked: {drug_update?.locked ? 'True' : 'False'}</label>
									{/* <input
										type="number"
										name="alert_quantity"
										placeholder="Alert Quantity"
										className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
										disabled
										value={drug_update.locked ? 'True' : 'False'}
										onChange={(e) => handleChange(e, e.target.name)}
									/> */}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default PharmacistAddDrugModal
