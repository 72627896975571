/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { CgPill } from 'react-icons/cg'
import { useDispatch, useSelector } from 'react-redux'

import Pagination from '../utils/Pagination'
import { setCurrentBinCard } from '../../state/actions/actions'
import { getDrugBinCards } from '../../state/actions/pharmacist.actions'


const PharmacistManageDrugTable = ({ showList, setShowList }) => {
    const dispatch = useDispatch()

    const { drug_update } = useSelector(state => state.root)
    const { drug_bincards, dispensed_drug } = useSelector(state => state.pharmacist)

    const [currentData, setCurrentData] = useState([])

    useEffect(() => {
        dispatch(getDrugBinCards({ id: drug_update?.pkid }))
    }, [drug_update, dispensed_drug])

    useEffect(() => {
        if (drug_bincards?.length > 0) {
            dispatch(setCurrentBinCard({ data: Object.keys(drug_bincards[0]).length > 0 ? drug_bincards[0] : null }))
        }
    }, [dispensed_drug, drug_bincards])

    return (
        <div className="flex justify-between items-center w-full">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="py-2">
                    <div
                        className="flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1"
                        onClick={() => setShowList(!showList)}
                    >
                        <h4 className="text-dark-blue text-[14px] font-bold">
                            {drug_update?.name}
                        </h4>
                        <div className='flex justify-between items-center space-x-5'>
                            <CgPill className="text-dark-blue" size={20} /> <span className="flex items-center justify-center bg-emerald-600 w-[80px] h-[22px] rounded-sm text-white text-[12px] p-1">{drug_bincards?.length > 0 ? drug_bincards[0]?.balance : 0} Left</span>
                        </div>
                    </div>
                    {showList && (
                        <table className="border rounded w-full cursor-default">
                            <thead className="text-[14px]">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-medium text-gray-900 pr-[2em] px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-medium text-gray-900 pr-[2em] px-4 py-4 text-left"
                                    >
                                        Date
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Receipt/Issued Voucher
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Received From/Issued To
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Quantity Received
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Quantity Issued
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Balance
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-[12px] font-medium text-gray-900 py-4 text-left"
                                    >
                                        Issued By
                                    </th>
                                </tr>
                            </thead>
                            <tbody className=''>
                                {currentData?.map((item, index) => (
                                    <tr
                                        key={`${item.id}-${index}`}
                                        className={`${item.quantity_received > 0 && 'bg-sky-50'} ${item.quantity_issued > 0 && 'bg-yellow-50'}`}
                                    >
                                        <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td
                                            className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900"
                                        // value={billing.pkid}
                                        >
                                            {item?.created_at}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item?.receipt_or_issue_voucher_number}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item?.received_from_or_issued_to}
                                        </td>
                                        <td className={`${item?.quantity_received > 0 ? 'font-medium text-sky-800' : 'font-light text-gray-900'} text-[12px] py-2 whitespace-nowrap`}>
                                            {item?.quantity_received}
                                        </td>
                                        <td className={`${item?.quantity_issued > 0 ? 'font-medium text-gray-800' : 'font-light text-gray-900'}  text-[12px] py-2 whitespace-nowrap`}>
                                            {item?.quantity_issued}
                                        </td>
                                        <td className="text-[12px] underline underline-offset-2 text-gray-900 font-bold py-2 whitespace-nowrap">
                                            {item?.balance}
                                        </td>
                                        <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                            Pharm. {item?.bincard_author.last_name} {item?.bincard_author.first_name}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    <Pagination
                        data={drug_bincards}
                        currentData={currentData}
                        setCurrentData={setCurrentData}
                    />
                </div>
            </div>
        </div >
    )
}

export default PharmacistManageDrugTable