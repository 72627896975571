/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AccountsInvoiceDiscountTable, PageTitle } from '../../components'
import { getLaboratoryNHISDiscounts, getNHISDiscounts, getPharmacyNHISDiscounts, getRadiologyNHISDiscounts, getRecordsNHISDiscounts } from '../../state/actions/accountant.actions'


const AccountsNHISDiscounts = () => {
	const dispatch = useDispatch()

	const { remitted_discount, nhis_discounts, pharmacy_nhis_discounts, records_nhis_discounts, laboratory_nhis_discounts, radiology_nhis_discounts } = useSelector((state) => state.accountant)

	const [activeLink, setActiveLink] = useState('All')
	// const [filterState, setFilterState] = useState({ search: '', })

	useEffect(() => {
		dispatch(getNHISDiscounts())
		dispatch(getPharmacyNHISDiscounts())
		dispatch(getRecordsNHISDiscounts())
		dispatch(getLaboratoryNHISDiscounts())
		dispatch(getRadiologyNHISDiscounts())
	}, [remitted_discount])

	return (
		<div>
			<PageTitle type={"accounts-NHIS-discounts"} />

			<div className="">
				<div className="flex space-x-5 justify-between items-center border-b-2 border-gray-500 w-full">
					<div className="space-x-5">
						<button
							type="button"
							className={`${activeLink === 'All' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => setActiveLink(e.target.textContent)}
						>
							All
						</button>

						<button
							type="button"
							className={`${activeLink === 'Pharmacy' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => setActiveLink(e.target.textContent)}
						>
							Pharmacy
						</button>

						<button
							type="button"
							className={`${activeLink === 'Records' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => setActiveLink(e.target.textContent)}
						>
							Records
						</button>

						<button
							type="button"
							className={`${activeLink === 'Laboratory' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => setActiveLink(e.target.textContent)}
						>
							Laboratory
						</button>

						<button
							type="button"
							className={`${activeLink === 'Radiology' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => setActiveLink(e.target.textContent)}
						>
							Radiology
						</button>
					</div>

					{/* <div>
						<input
							type="text"
							name="search"
							placeholder='Search'
							className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
						// onChange={(e) => setFilterState({ ...filterState, [e.target.name]: e.target.value })}
						/>
					</div> */}
				</div>

				{activeLink === 'All' && (
					<AccountsInvoiceDiscountTable
						data={nhis_discounts?.filter((item) => item?.is_paid === false)}
					/>
				)}

				{activeLink === 'Pharmacy' && (
					<AccountsInvoiceDiscountTable
						data={pharmacy_nhis_discounts?.filter((item) => item?.is_paid === false)}
					/>
				)}

				{activeLink === 'Records' && (
					<AccountsInvoiceDiscountTable
						data={records_nhis_discounts?.filter((item) => item?.is_paid === false)}
					/>
				)}

				{activeLink === 'Laboratory' && (
					<AccountsInvoiceDiscountTable
						data={laboratory_nhis_discounts?.filter((item) => item?.is_paid === false)}
					/>
				)}

				{activeLink === 'Radiology' && (
					<AccountsInvoiceDiscountTable
						data={radiology_nhis_discounts}
					/>
				)}

			</div>
		</div>
	)
}

export default AccountsNHISDiscounts