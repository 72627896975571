import React, { Fragment, useEffect } from 'react'
import { useStateContext } from '../../state/context'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllBillings } from '../../state/actions/admin.actions';
import { FaPencilAlt, FaTrash } from 'react-icons/fa'
import { setBilling } from '../../state/actions/actions';

const LaboratoryBillings = () => {

    const { modals, setModals } = useStateContext()
    const { billings } = useSelector(state => state.admin)
    const { user } = useSelector(state => state.auth)
    const lab_billings = billings?.filter(e => e?.billing_type === "laboratory")

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllBillings())

    }, [modals.showAddBillingModal])

    return (
        <div>
            <div className='flex justify-between mb-2'>
                <p className='text-[15px] italic '>Deparmental Billings</p>

                <div className="">
                    {user?.data?.is_department_head && <button
                        type="submit"
                        className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"

                        onClick={() => {
                            // dispatch(setBilling({ data: null }))
                            setModals({ ...modals, showAddBillingModal: !modals.showAddBillingModal })

                        }}
                    >
                        ADD BILLINGS
                    </button>}
                </div>
            </div>

            <div className="flex justify-between items-center w-full">

                <div
                    className="flex flex-col w-full cursor-pointer"
                >
                    <div className="">

                        <Fragment>

                            <table className="border-b border-l border-r rounded w-full cursor-default">
                                <thead className="">
                                    <tr className='bg-gray-100'>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[2em] px-4 py-4 text-left"
                                        >
                                            S/N
                                        </th>

                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Amount
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Date
                                        </th>
                                        {user?.data?.is_department_head && <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Actions
                                        </th>}
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                        >
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lab_billings?.map((item, index) => (
                                        <tr key={`${item.id}`}>
                                            <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                                {index + 1}
                                            </td>


                                            <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                {item.name}
                                            </td>
                                            <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                &#8358; {item.price}
                                            </td>
                                            <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                {item.created_at}
                                            </td>
                                            {user?.data?.is_department_head && <td className=" flex text-2xl space-x-4 text-gray-900 font-semibold py-2 whitespace-nowrap">
                                                <FaPencilAlt
                                                    size={20}
                                                    className='text-green-600 cursor-pointer'
                                                    onClick={() => {
                                                        dispatch(setBilling({ data: item }))
                                                        // modals.showAddBillingModal && setModals({ ...modals, showAddBillingModal: !modals.showAddBillingModal })
                                                        setModals({ ...modals, showUpdateBillingModal: !modals.showUpdateBillingModal })
                                                    }}
                                                />
                                                <FaTrash
                                                    onClick={() => {
                                                        dispatch(setBilling({ data: item }))
                                                        setModals({ ...modals, showDeleteBillingModal: !modals.showDeleteBillingModal })
                                                    }}
                                                    className="text-orange-400 cursor-pointer"
                                                    size={20}
                                                />
                                            </td>}

                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            {/* <Pagination
                            data={data}
                            currentData={currentData}
                            setCurrentData={setCurrentData}
                        /> */}
                        </Fragment>

                    </div>
                </div>
            </div >
        </div>
    )
}

export default LaboratoryBillings