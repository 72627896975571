import { createSlice } from '@reduxjs/toolkit'
import {
    getAllRadiologistEquipment, addEquipment,
    updateARadiologyEquipment, deleteARadiologyEquipment, getAllRadiologyReportRequest,
    uploadPatientRadiologyReport, getAllRadiologyInvoicesByMe, createARadiologyCategoryTest,
    getAllRadiologyCategoryTest, deleteARadiologyCategory, updateARadiologyCategoryTest,
    createARadiologyInvestigationType, getAllRadiologyInvestigationTypes,
    deletePatientRadiologyInvestigationType, updateARadiologyInvestigationType, getAllEmployeesForRadiologist, getAllRadiologyTypesByCategory
} from '../actions/radiologist.actions';




const radiologistSlice = createSlice({
    name: 'radiologist',
    initialState: {
        all_employees: null,
        all_radiologist_equipment: null,
        radiologistLoading: false,
        radiologistErrors: null,
        all_rad_report_requests: null,
        all_radiology_invoice_by_me: null,
        all_radiology_category_test: null,
        all_radiology_investigation_type: null,
        all_rad_types_by_category: null





    },
    extraReducers: {
        [getAllEmployeesForRadiologist.pending]: (state, action) => {
            state.radiologistLoading = true
        },
        [getAllEmployeesForRadiologist.fulfilled]: (state, action) => {
            state.radiologistLoading = false
            state.all_employees = action.payload

        },

        [getAllEmployeesForRadiologist.rejected]: (state, action) => {
            state.radiologistLoading = false
        },

        [getAllRadiologistEquipment.pending]: (state, action) => {
            state.radiologistLoading = true
        },
        [getAllRadiologistEquipment.fulfilled]: (state, action) => {
            state.radiologistLoading = false
            state.all_radiologist_equipment = action.payload

        },

        [getAllRadiologistEquipment.rejected]: (state, action) => {
            state.radiologistLoading = false
        },
        [addEquipment.pending]: (state, action) => {
            state.radiologistLoading = true
        },
        [addEquipment.fulfilled]: (state, action) => {
            state.radiologistLoading = false
            // state.all_radiologist_equipment = action.payload

        },

        [addEquipment.rejected]: (state, action) => {
            state.radiologistLoading = false
        },

        [updateARadiologyEquipment.pending]: (state, action) => {
            state.radiologistLoading = true
        },
        [updateARadiologyEquipment.fulfilled]: (state, action) => {
            state.radiologistLoading = false
            // state.all_radiologist_equipment = action.payload

        },

        [updateARadiologyEquipment.rejected]: (state, action) => {
            state.radiologistLoading = false
        },
        [deleteARadiologyEquipment.pending]: (state, action) => {
            state.radiologistLoading = true
        },
        [deleteARadiologyEquipment.fulfilled]: (state, action) => {
            state.radiologistLoading = false
            // state.all_radiologist_equipment = action.payload

        },

        [deleteARadiologyEquipment.rejected]: (state, action) => {
            state.radiologistLoading = false
        },
        [getAllRadiologyReportRequest.pending]: (state, action) => {
            state.radiologistLoading = true
        },
        [getAllRadiologyReportRequest.fulfilled]: (state, action) => {
            state.radiologistLoading = false
            state.all_rad_report_requests = action.payload

        },

        [getAllRadiologyReportRequest.rejected]: (state, action) => {
            state.radiologistLoading = false
        },
        [uploadPatientRadiologyReport.pending]: (state, action) => {
            state.radiologistLoading = true
        },
        [uploadPatientRadiologyReport.fulfilled]: (state, action) => {
            state.radiologistLoading = false
            // state.all_rad_report_requests = action.payload

        },

        [uploadPatientRadiologyReport.rejected]: (state, action) => {
            state.radiologistLoading = false
        },

        [getAllRadiologyInvoicesByMe.pending]: (state, action) => {
            state.radiologistLoading = true
        },
        [getAllRadiologyInvoicesByMe.fulfilled]: (state, action) => {
            state.all_radiology_invoice_by_me = false
            state.all_radiology_invoice_by_me = action.payload

        },

        [getAllRadiologyInvoicesByMe.rejected]: (state, action) => {
            state.radiologistLoading = false
        },

        [createARadiologyCategoryTest.pending]: (state, action) => {
            state.radiologistLoading = true
        },
        [createARadiologyCategoryTest.fulfilled]: (state, action) => {
            state.radiologistLoading = false
            // state.all_radiology_invoice_by_me = action.payload

        },

        [createARadiologyCategoryTest.rejected]: (state, action) => {
            state.radiologistLoading = false
        },

        [getAllRadiologyCategoryTest.pending]: (state, action) => {
            state.radiologistLoading = true
        },
        [getAllRadiologyCategoryTest.fulfilled]: (state, action) => {
            state.radiologistLoading = false
            state.all_radiology_category_test = action.payload

        },

        [getAllRadiologyCategoryTest.rejected]: (state, action) => {
            state.radiologistLoading = false
        },
        [deleteARadiologyCategory.pending]: (state, action) => {
            state.radiologistLoading = true
        },
        [deleteARadiologyCategory.fulfilled]: (state, action) => {
            state.radiologistLoading = false
            // state.all_radiology_category_test = action.payload

        },

        [deleteARadiologyCategory.rejected]: (state, action) => {
            state.radiologistLoading = false
        },
        [updateARadiologyCategoryTest.pending]: (state, action) => {
            state.radiologistLoading = true
        },
        [updateARadiologyCategoryTest.fulfilled]: (state, action) => {
            state.radiologistLoading = false
            // state.all_radiology_category_test = action.payload

        },

        [updateARadiologyCategoryTest.rejected]: (state, action) => {
            state.radiologistLoading = false
        },
        [createARadiologyInvestigationType.pending]: (state, action) => {
            state.radiologistLoading = true
        },
        [createARadiologyInvestigationType.fulfilled]: (state, action) => {
            state.radiologistLoading = false
            // state.all_radiology_category_test = action.payload

        },

        [createARadiologyInvestigationType.rejected]: (state, action) => {
            state.radiologistLoading = false
        },
        [getAllRadiologyInvestigationTypes.pending]: (state, action) => {
            state.radiologistLoading = true
        },
        [getAllRadiologyInvestigationTypes.fulfilled]: (state, action) => {
            state.radiologistLoading = false
            state.all_radiology_investigation_type = action.payload

        },

        [getAllRadiologyInvestigationTypes.rejected]: (state, action) => {
            state.radiologistLoading = false
        },
        [deletePatientRadiologyInvestigationType.pending]: (state, action) => {
            state.radiologistLoading = true
        },
        [deletePatientRadiologyInvestigationType.fulfilled]: (state, action) => {
            state.radiologistLoading = false
            // state.all_radiology_investigation_type = action.payload

        },

        [deletePatientRadiologyInvestigationType.rejected]: (state, action) => {
            state.radiologistLoading = false
        },
        [updateARadiologyInvestigationType.pending]: (state, action) => {
            state.radiologistLoading = true
        },
        [updateARadiologyInvestigationType.fulfilled]: (state, action) => {
            state.radiologistLoading = false
            // state.all_radiology_investigation_type = action.payload

        },

        [updateARadiologyInvestigationType.rejected]: (state, action) => {
            state.radiologistLoading = false
        },

        [getAllRadiologyTypesByCategory.pending]: (state, action) => {
            state.radiologistLoading = true
        },
        [getAllRadiologyTypesByCategory.fulfilled]: (state, action) => {
            state.radiologistLoading = false
            state.all_rad_types_by_category = action.payload

        },

        [getAllRadiologyTypesByCategory.rejected]: (state, action) => {
            state.radiologistLoading = false
        }







    }

})


export default radiologistSlice.reducer;