import { API } from "../config";

export const getEmployees = (token) => API.get('/radiologist/fetch_all_employees/', { headers: { Authorization: `Bearer ${token}` } })
export const getRadiologistEquipment = (token) => API.get('/radiologist/radiology_equipment/', { headers: { Authorization: `Bearer ${token}` } })
export const addRadiologyEquipment = (formData, token) => API.post('/radiologist/radiology_equipment/', formData, { headers: { Authorization: `Bearer ${token}` } })
export const updateRadiologyEquipment = (formData, token) => API.put(`/radiologist/radiology_equipment/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })
export const deleteRadiologyEquipment = (equipment_id, token) => API.delete(`/radiologist/radiology_equipment/${equipment_id}/`, { headers: { Authorization: `Bearer ${token}` } })
export const getRadiologistReportRequest = (token) => API.get('/radiologist/radiology_reports/', { headers: { Authorization: `Bearer ${token}` } })
export const generateRadiologyInvoice = (formData, token) => API.post(`/radiologist/radiology_invoice/${formData.patient_id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })
export const uploadRadiologyReport = (formData, token) => API.put(`/radiologist/radiology_reports/${formData.report_id}/`, formData, { headers: { Authorization: `Bearer ${token}`, "content-type": "multipart/form-data" } })
export const getRadiologyInvoicesByMe = (token) => API.get('/radiologist/invoice_by_me/', { headers: { Authorization: `Bearer ${token}` } })
export const getRadiologyCategoryTest = (token) => API.get('/radiologist/all_rad_investigation_cat/', { headers: { Authorization: `Bearer ${token}` } })
export const createRadiologyCategoryTest = (formData, token) => API.post('/radiologist/create_rad_investigation_cat/', formData, { headers: { Authorization: `Bearer ${token}` } })
export const deleteRadiologyCategoryTest = (cat_id, token) => API.delete(`/radiologist/rad_investigation_cat/${cat_id}/`, { headers: { Authorization: `Bearer ${token}` } })
export const updateRadiologyCategoryTest = (formData, token) => API.put(`/radiologist/rad_investigation_cat/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })
export const createRadiologyInvestigationType = (formData, token) => API.post('/radiologist/create_rad_investigation_type/', formData, { headers: { Authorization: `Bearer ${token}` } })
export const getRadiologyInvestigationTypes = (token) => API.get('/radiologist/all_rad_investigation_type/', { headers: { Authorization: `Bearer ${token}` } })
export const deleteRadiologyInvestigationType = (id, token) => API.delete(`/radiologist/rad_investigation_type/${id}/`, { headers: { Authorization: `Bearer ${token}` } })
export const updateRadiologyInvestigationType = (formData, token) => API.put(`/radiologist/rad_investigation_type/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })
export const fetchRadiologyTypesByCategory = (id, token) => API.get(`/radiologist/rad_types_by_cat/${id}/`, { headers: { Authorization: `Bearer ${token}` } })


// export const getAllDoctors = (token) => API.get('/records/doctors/', {headers: {Authorization: `Bearer ${token}`}})
// // "change patient doctor"
// all_rad_investigation_type

// export const AddPatient = (formData, token) => API.post('/records/patients/', formData, {headers: {Authorization: `Bearer ${token}`}})