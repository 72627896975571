/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useEffect } from "react";
import { FaTimesCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


import { useStateContext } from "../../state/context";
import { getSMSBalance, sendAnSMS } from "../../state/actions/admin.actions";
import { createUserNotification } from "../../state/actions/notifications.actions";
import ButtonLoader from "../utils/ButtonLoader";


const SendSmsModal = (props) => {
	const dispatch = useDispatch()

	const { modals, setModals, socket } = useStateContext()
	const { sms_receiver } = useSelector((state) => state.root);
	const { sms_subscriptions, adminLoading } = useSelector((state) => state.admin);

	const [formData, setFormData] = useState({ message: "", receiver: '', notification_recipient: '' });
	const [textLimit, setTextLimit] = useState(145);
	const [textCount, setTextCount] = useState(0);

	useEffect(()=>{
		dispatch(getSMSBalance())
	}, [])

	useEffect(() => {
		setFormData({ ...formData, receiver: sms_receiver?.id, notification_recipient: sms_receiver?.pkid })
	}, [sms_receiver])

	useEffect(() => {
		textCount === textLimit && toast.info("Text SMS character limit exceded!");
	}, [textCount])

	const handleChange = (e, name) => {
		setFormData((prevState) => ({ ...prevState, [name]: e.target.value }));
	}

	const handleSubmit = (e) => {
		e.preventDefault();

		const recipient_id = sms_receiver?.id;

		if (!formData.message) return toast.warning('All fields are required!')
		if (sms_subscriptions[0]?.balance <= 4.0) return toast.warning('Insufficient sms units')
		
		dispatch(sendAnSMS({ formData, toast, recipient_id, setModals, modals, socket, dispatch, createUserNotification }));
	}

	return (
		<div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
			<div className="bg-white w-[450px] rounded px-[30px] py-[20px]">
				<div className="flex flex-col space-y-5">
					<div className="flex justify-between items-center">
						<h4 className="text-dark-green text-[20px] font-bold">Send SMS</h4>
						<FaTimesCircle
							className="text-dark-yellow cursor-pointer"
							onClick={() => setModals({ ...modals, showSendSmsModal: !modals.showSendSmsModal })}
							size={20}
						/>
					</div>

					<form onSubmit={handleSubmit}>
						<div>
							<input
								type="text"
								name="department_name"
								placeholder=""
								value={sms_receiver?.first_name + "  " + sms_receiver?.last_name}
								className="border rounded w-full h-5 px-4 py-5 mt-2 bg-gray-300 placeholder:text-dark-blue hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
								disabled
							// onChange={(e) => handleChange(e, e.target.name)}
							/>
						</div>

						<div>
							<input
								type="text"
								value={sms_receiver?.phone}
								className="border rounded w-full h-5 px-4 py-5 mt-2 bg-sky-100 placeholder:text-dark-blue hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
								disabled
							// onChange={(e) => handleChange(e, e.target.name)}
							/>
						</div>

						<div className="relative">
							<textarea
								cols="30"
								rows="4"
								name="message"
								placeholder="Message"
								className="border rounded w-full px-4 py-2 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue resize-none"
								onChange={(e) => {
									setTextCount(e.target.value.length);
									handleChange(e, e.target.name);
								}}
								maxLength={textLimit}
							></textarea>
							<div className="absolute bottom-[-20px] right-0">
								<p className="bg-sky-400 rounded px-1 text-[14px] text-white">
									<span
										className={`${textCount === textLimit && "text-yellow-300 font-medium"
											}`}
									>
										{textCount}
									</span>{" "}
									/ {textLimit}
								</p>
							</div>
						</div>

						<div className="mt-8">
							<button
								type="submit"
								disabled={!sms_receiver?.phone}
								className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
							>
								{!adminLoading ? (
									'Send'
								) : (
									<ButtonLoader />
								)}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default SendSmsModal;
