import React, { useEffect } from 'react'
import { BsEye } from 'react-icons/bs'
import { useSelector, useDispatch } from 'react-redux'
import { useStateContext } from '../../../state/context'
import { getAPatientFamilyFollowUpRecords } from '../../../state/actions/nurse.actions'
import { setFollowUpRecords } from '../../../state/actions/actions'


const FollowUpRecordsTable = () => {
    const { modals, setModals } = useStateContext()
    const dispatch = useDispatch()
    const { set_patient_id } = useSelector((state) => state.root)
    const { all_patient_family_planning_follow_up_record } = useSelector((state) => state.nurse)

    useEffect(() => {
        const id = set_patient_id?.id

        dispatch(getAPatientFamilyFollowUpRecords({ id }))

    }, [set_patient_id])
    useEffect(() => {
        const id = set_patient_id?.id

        dispatch(getAPatientFamilyFollowUpRecords({ id }))

    }, [modals.showAddFamilyPlanningRecords])


    return (
        <>
            <div className='flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1'>
                <h4 className='text-dark-blue text-[16px] font-semibold'>
                    History  - {set_patient_id?.first_name} {set_patient_id?.last_name} - [{set_patient_id?.patient_number}]
                </h4>
            </div>
            <table className='border rounded w-full cursor-default'>
                <thead className=''>
                    <tr>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                        >
                            S/N
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                        >
                            Method Change
                        </th>

                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                        >
                            Method supplied
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
                        >
                            Size/Quantity
                        </th>

                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 py-4 text-left'
                        >
                            Blood pressure
                        </th>


                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 py-4 text-left'
                        >
                            Weight (kg)
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 py-4 text-left'
                        >
                            Next appointment
                        </th>

                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 py-4 text-left'
                        >
                            Actions
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {all_patient_family_planning_follow_up_record?.map((item, index) => (
                        <tr
                            className='relative'
                            key={index}

                        >
                            <td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
                                {index + 1}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.method_change}         </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.method_supplied}         </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.brand_qty}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.blood_pressure}mmHg
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.weight} kg
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.next_appointment_date}
                            </td>


                            <td className=' text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                <div className='flex space-x-4 relative '>
                                    <BsEye
                                        color='blue'
                                        className='hover:cursor-pointer'

                                        size={35}
                                        onClick={() => {
                                            const data = {
                                                method_change: item?.method_change,
                                                method_supplied: item?.method_supplied,
                                                brand_qty: item?.brand_qty,
                                                blood_pressure: item?.blood_pressure,
                                                weight: item?.weight,
                                                appointment: item?.next_appointment_date,
                                                observations: item?.observation,
                                                pelvic_infection: item?.pelvic_infection
                                            }
                                            dispatch(setFollowUpRecords({ data }))
                                            setModals({ ...modals, showViewPatientFollowUpRecordModal: !modals.showViewPatientFollowUpRecordModal })

                                        }}


                                    />

                                </div>
                            </td>
                        </tr>
                    ))}

                </tbody>


            </table>
        </>

    )
}

export default FollowUpRecordsTable