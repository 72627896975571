/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { FaTimesCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

import ButtonLoader from "../utils/ButtonLoader";
import { useStateContext } from "../../state/context";
import { sendAnEmailMessage } from "../../state/actions/admin.actions";
import { createUserNotification } from "../../state/actions/notifications.actions";
import { setEmailReceiver } from "../../state/actions/actions";

const SendMailModal = () => {
	const dispatch = useDispatch()
	// const navigate = useNavigate()

	const { modals, setModals, socket } = useStateContext()

	const { email_receiver } = useSelector((state) => state.root)
	const { adminLoading } = useSelector((state) => state.admin)

	const [formData, setFormData] = useState({
		message: "", receiver: '', sender: "",

		status: false, subject: "", patient_id: '',
		notification_recipient: "",

	});

	useEffect(() => {
		setFormData({ ...formData, receiver: email_receiver?.user_pkid, patient_id: email_receiver?.id, notification_recipient: email_receiver?.user_pkid, },)
	}, [email_receiver])

	const handleChange = (e, name) => {
		setFormData((prevState) => ({ ...prevState, [name]: e.target.value }));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// console.log(formData)
		dispatch(sendAnEmailMessage({ formData, toast, setModals, modals, dispatch, createUserNotification, socket }));
		// dispatch(setEmailReceiver({data:null}))
	};

	return (
		<div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
			<div className="bg-white w-[450px] rounded px-[30px] py-[20px]">
				<div className="flex flex-col space-y-5">
					<div className="flex justify-between items-center">
						<h4 className="text-dark-green text-[20px] font-bold">Send Mail</h4>
						<FaTimesCircle
							className="text-dark-yellow cursor-pointer"
							onClick={() => {
								dispatch(setEmailReceiver({ data: null }))
								setModals({ ...modals, showSendMailModal: !modals?.showSendMailModal })

							}}
							size={20}
						/>
					</div>

					<form onSubmit={handleSubmit}>
						<div>
							<input
								type="text"
								// name="department_name"
								placeholder="Dr. Kehinde Lamido"
								value={email_receiver?.first_name + " " + email_receiver?.last_name}
								className="border rounded w-full h-5 px-4 py-5 mt-2 bg-gray-300 placeholder:text-dark-blue hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
								disabled
								onChange={(e) => handleChange(e, e.target.name)}
							/>
						</div>
						<div>
							<input
								type="text"
								// name="department_name"
								placeholder={email_receiver?.email}
								value={email_receiver?.email}
								className="border rounded w-full h-5 px-4 py-5 mt-2 bg-gray-300 placeholder:text-dark-blue hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
								disabled
							// onChange={(e) => handleChange(e, e.target.name)}
							/>
						</div>
						<input
							type="hidden"
							name="receiver"
							value={email_receiver?.user_pkid}
							onChange={(e) => handleChange(e, e.target.name)}
						/>
						<div>
							<input
								type="text"
								name="subject"
								placeholder="Subject"
								className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
								onChange={(e) => handleChange(e, e.target.name)}
							/>
						</div>

						<div>
							<textarea
								cols="30"
								rows="5"
								name="message"
								placeholder="Message"
								className="border rounded w-full px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue resize-none"
								onChange={(e) => handleChange(e, e.target.name)}
							></textarea>
						</div>

						<div className="mt-4">
							<button
								type="submit"
								className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
							>
								{!adminLoading ? (
									'Send'
								) : (
									<ButtonLoader />
								)}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default SendMailModal;
