import { API } from "../config";


export const signIn = (formData) => API.post('/user/login/', formData)

export const activateUserAccount = (token) => API.put(`/administrator/activate_account/${token}/`, { 'Content-Type': 'application/json' })

export const getProfile = (token) => API.get('/profile/me/', { headers: { Authorization: `Bearer ${token}` } })

export const userDetails = (email, token) => API.get(`/user/details/${email}/`, { headers: { Authorization: `Bearer ${token}`, 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Headers': '*' } })

export const generateResetPasswordLink = (params) => API.get(`/user/reset_password/${params.email}/`)

export const patchUserPassword = (formData) => API.patch(`/user/reset_password/`, formData)

export const updatePassword = (formData, token) => API.patch('/user/update_password/', formData, { headers: { Authorization: `Bearer ${token}` } })

export const updatePasswordStatus = (token) => API.put('/user/password_status/', {}, { headers: { 'Authorization': `Bearer ${token}` } })

export const updateProfile = (formData, token) => API.patch(`/profile/update/`, formData, { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })

export const updateUserDetails = (formData, token) => API.patch(`/user/update_details/`, formData, { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })

export const patchProfilePhoto = (formData, token) => API.patch(`/profile/update/photo/`, formData, { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })

export const patchOnlineStatus = (formData, token) => API.patch(`/user/update_online_status/`, formData, { headers: { 'Authorization': `Bearer ${token}` } })

export const updateSocketId = (formData, token) => API.put('/user/update_socket_id/', formData, { headers: { 'Authorization': `Bearer ${token}` } })
