/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Modals, SideBar, TopBar } from '../../components'
import {
    AdminBedManager, AdminDashboard, AdminDepartments, AdminHumanResources,
    AdminInvoices, Chat, DoctorAdmissions, DoctorAppointments, DoctorDashboard,
    DoctorPatients, DoctorTeam, DoctorClinicalData, LaboratoristDashboard, LaboratoristEquipments,
    LaboratoristInvestigations, LaboratoristReports, Messages, Notifications,
    NurseAntenatal, NurseChildImmunization, NurseDashboard, NurseFamilyPlanning,
    NurseInpatients, NurseOPD, PatientAppointments, PatientDashboard, PatientPayments,
    PharmacistBillsDispense, PharmacistDashboard, PharmacistDrugs, PharmacistPrescriptions,
    PharmacistDrugsCategory, PharmacistSupplyPoint, PharmacistSupplyPoints, PharmacistClinicalData, Profile, RadiologistDashboard,
    RadiologistEquipments, RadiologistInvestigations, RadiologistReports, RadiologistBillings, RecordsDashboard, RecordsInvoices, RecordsPatients,
    RecordsRecords, RecordsWardsBeds, Schedule, Settings, SMS, RadiologistInvoices, LaboratoryInvoices,
    DoctorAssignedPatients, NurseBirthAndDeathRecord, CollectionsDashboard, CollectionsUnpaidInvoices, CollectionsPaidInvoices,
    AccountsDashboard, AccountsInvoices, AccountsNHISDiscounts, AccountsPaidNHISDiscounts, PatientMessages, PatientSMS,
    OphthalmologistDashboard, OphthalmologistTeam, OphthalmologistPatients, OphthalmologistAssignedPatients,
    OphthalmologistAdmissions, OphthalmologistAppointments, OtolaryngologistDashboard, OtolaryngologistTeam,
    OtolaryngologistPatients, OtolaryngologistAssignedPatients, OtolaryngologistAdmissions, OtolaryngologistAppointments,
    DentistDashboard, DentistTeam, DentistPatients, DentistAdmissions, DentistAppointments, DentistAssignedPatients,
    PhysiotherapistDashboard, PhysiotherapistTeam, PhysiotherapistPatients, PhysiotherapistAdmissions, PhysiotherapistAppointments, PhysiotherapistAssignedPatients
} from '..'
import { getHospitalConfig } from '../../state/actions/admin.actions'
import LaboratoryBillings from '../laboratorist/LaboratoryBillings'


const Dashboard = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [activeLink, setActiveLink] = useState('Admin Dashboard')

    const { user } = useSelector(state => state.auth)
    const { hospital_config } = useSelector(state => state.admin)

    useEffect(() => {
        dispatch(getHospitalConfig())
    }, [])

    useEffect(() => {
        document.title = hospital_config?.name
        let link = document.querySelector("link[rel~='icon']");

        link.href = `${process.env.REACT_APP_API_ROOT}${hospital_config?.logo}`;
    }, [hospital_config])

    useEffect(() => {
        if (!user) return navigate('/')

        if (user?.data.is_administrator) return setActiveLink('Admin Dashboard')
        if (user?.data.is_doctor) return setActiveLink('Doctor Dashboard')
        if (user?.data.is_ophthalmologists) return setActiveLink('Ophthalmologist Dashboard')
        if (user?.data.is_otolaryngologists) return setActiveLink('Otolaryngologists Dashboard')
        if (user?.data.is_dentist) return setActiveLink('Dentist Dashboard')
        if (user?.data.is_physiotherapists) return setActiveLink('Physiotherapist Dashboard')
        if (user?.data.is_nurse) return setActiveLink('Nurse Dashboard')
        if (user?.data.is_pharmacist) return setActiveLink('Pharmacist Dashboard')
        if (user?.data.is_radiologist) return setActiveLink('Radilogist Dashboard')
        if (user?.data.is_laboratorist) return setActiveLink('Laboratorist Dashboard')
        if (user?.data.is_records) return setActiveLink('Records Dashboard')
        if (user?.data.is_patient) return setActiveLink('Patient Dashboard')
        if (user?.data.is_accountant) return setActiveLink('Accounts Dashboard')
        if (user?.data.is_collections) return setActiveLink('Collections Dashboard')
    }, [user])

    return (
        <div className='relative flex flex-row font-poppins'>
            <Modals
                activeLink={activeLink}
                setActiveLink={setActiveLink}

            />

            {/* sidebar */}
            <SideBar
                activeLink={activeLink}
                setActiveLink={setActiveLink}
            />

            {/* body */}
            <div className="w-10 text-2xl font-semibold flex-1">
                <TopBar />
                <div className="p-2">
                    {/* admin */}
                    {activeLink === 'Admin Dashboard' && <AdminDashboard />}
                    {activeLink === 'Departments' && (<AdminDepartments />)}
                    {activeLink === 'Human Resources' && (<AdminHumanResources />)}
                    {activeLink === 'Invoices' && (<AdminInvoices />)}
                    {activeLink === 'Bed Manager' && (<AdminBedManager />)}

                    {/* doctor */}
                    {activeLink === 'Doctor Dashboard' && <DoctorDashboard />}
                    {activeLink === 'Team' && <DoctorTeam />}
                    {activeLink === 'Manage Patients' && <DoctorPatients />}
                    {activeLink === 'Assigned Patients' && <DoctorAssignedPatients />}
                    {activeLink === 'Admissions' && <DoctorAdmissions />}
                    {activeLink === 'Appointments' && <DoctorAppointments />}
                    {activeLink === 'Doctor Clinical Data' && <DoctorClinicalData />}



                    {/* ophthalmologist */}
                    {activeLink === 'Ophthalmologist Dashboard' && <DoctorDashboard />}
                    {activeLink === 'Ophthalmologist Team' && <DoctorTeam />}
                    {activeLink === 'Ophthalmologist Manage Patients' && <DoctorPatients />}
                    {activeLink === 'Ophthalmologist Assigned Patients' && <DoctorAssignedPatients />}
                    {activeLink === 'Ophthalmologist Admissions' && <DoctorAdmissions />}
                    {activeLink === 'Ophthalmologist Appointments' && <DoctorAppointments />}


                    {/* ophthalmologist */}
                    {activeLink === 'Otolaryngologists Dashboard' && <DoctorDashboard />}
                    {activeLink === 'Otolaryngologists Team' && <DoctorTeam />}
                    {activeLink === 'Otolaryngologists Manage Patients' && <DoctorPatients />}
                    {activeLink === 'Otolaryngologists Assigned Patients' && <DoctorAssignedPatients />}
                    {activeLink === 'Otolaryngologists Admissions' && <DoctorAdmissions />}
                    {activeLink === 'Otolaryngologists Appointments' && <DoctorAppointments />}


                    {/* dentist */}
                    {activeLink === 'Dentist Dashboard' && <DoctorDashboard />}
                    {activeLink === 'Dentist Team' && <DoctorTeam />}
                    {activeLink === 'Dentist Manage Patients' && <DentistPatients />}
                    {activeLink === 'Dentist Assigned Patients' && <DoctorAssignedPatients />}
                    {activeLink === 'Dentist Admissions' && <DoctorAdmissions />}
                    {activeLink === 'Dentist Appointments' && <DoctorAppointments />}


                    {/* physiotherapist */}
                    {activeLink === 'Physiotherapist Dashboard' && <DoctorDashboard />}
                    {activeLink === 'Physiotherapist Team' && <DoctorTeam />}
                    {activeLink === 'Physiotherapist Manage Patients' && <DentistPatients />}
                    {activeLink === 'Physiotherapist Assigned Patients' && <DoctorAssignedPatients />}
                    {activeLink === 'Physiotherapist Admissions' && <DoctorAdmissions />}
                    {activeLink === 'Physiotherapist Appointments' && <DoctorAppointments />}


                    {/* nurse */}
                    {activeLink === 'Nurse Dashboard' && <NurseDashboard />}
                    {activeLink === 'OPD' && <NurseOPD />}
                    {activeLink === 'Inpatients' && <NurseInpatients />}
                    {activeLink === 'Antenatal' && <NurseAntenatal />}
                    {activeLink === 'Family Planning' && <NurseFamilyPlanning />}
                    {activeLink === 'Child Immunization' && <NurseChildImmunization />}
                    {activeLink === 'Birth Records' && <NurseBirthAndDeathRecord />}


                    {/* pharmacist */}
                    {activeLink === 'Pharmacist Dashboard' && <PharmacistDashboard />}
                    {activeLink === 'Drugs Category' && <PharmacistDrugsCategory />}
                    {activeLink === 'Drugs' && <PharmacistDrugs />}
                    {activeLink === 'Prescriptions' && <PharmacistPrescriptions />}
                    {activeLink === 'Supply Point' && <PharmacistSupplyPoint />}
                    {activeLink === 'Supply Points' && <PharmacistSupplyPoints />}
                    {activeLink === 'Bills/Dispense' && <PharmacistBillsDispense />}
                    {activeLink === 'Clinical Data' && <PharmacistClinicalData />}



                    {/* Radiologist */}
                    {activeLink === 'Radiologist Dashboard' && <RadiologistDashboard />}
                    {activeLink === 'Radiology Equipments' && <RadiologistEquipments />}
                    {activeLink === 'Radiology Reports' && <RadiologistReports />}
                    {activeLink === 'Radiology Invoices' && <RadiologistInvoices />}
                    {activeLink === 'Radiology Investigations' && <RadiologistInvestigations />}
                    {activeLink === 'Radiology Billings' && <RadiologistBillings />}



                    {/* Laboratorist */}
                    {activeLink === 'Laboratorist Dashboard' && <LaboratoristDashboard />}
                    {activeLink === 'Laboratory Equipments' && <LaboratoristEquipments />}
                    {activeLink === 'Laboratory Reports' && <LaboratoristReports />}
                    {activeLink === 'Laboratory Investigations' && <LaboratoristInvestigations />}
                    {activeLink === 'Laboratory Invoices' && <LaboratoryInvoices />}
                    {activeLink === 'Laboratory Billings' && <LaboratoryBillings />}



                    {/* records */}
                    {activeLink === "Records Dashboard" && <RecordsDashboard />}
                    {activeLink === "Patients" && (<RecordsPatients />
                    )}
                    {activeLink === "Records Invoices" && (<RecordsInvoices />)}
                    {activeLink === "Records" && (<RecordsRecords />)}
                    {activeLink === "Wards/Beds" && (<RecordsWardsBeds />)}

                    {/* patient */}
                    {activeLink === 'Patient Dashboard' && <PatientDashboard />}
                    {activeLink === 'Payments' && <PatientPayments />}
                    {activeLink === 'Patient Appointments' && <PatientAppointments />}
                    {activeLink === 'Patient Message' && <PatientMessages />}
                    {activeLink === 'Patient SMS' && <PatientSMS />}

                    {/* collections */}
                    {activeLink === 'Collections Dashboard' && <CollectionsDashboard />}
                    {activeLink === 'Collections Invoices' && <CollectionsUnpaidInvoices />}
                    {activeLink === 'Collections Paid Invoices' && <CollectionsPaidInvoices />}

                    {/* accounts */}
                    {activeLink === 'Accounts Dashboard' && <AccountsDashboard />}
                    {activeLink === 'Accounts Invoices' && <CollectionsUnpaidInvoices />}
                    {activeLink === 'Accounts Paid Invoices' && <CollectionsPaidInvoices />}
                    {activeLink === 'Accounts Reports' && <AccountsInvoices />}
                    {activeLink === 'Accounts NHIS Discounts' && <AccountsNHISDiscounts />}
                    {activeLink === 'Accounts NHIS Paid Discounts' && <AccountsPaidNHISDiscounts />}


                    {/* general */}
                    {activeLink === 'Schedule' && <Schedule />}
                    {activeLink === 'Chat' && <Chat />}
                    {activeLink === 'Profile' && (<Profile />)}
                    {activeLink === 'Messages' && (<Messages />)}
                    {activeLink === 'SMS' && (<SMS />)}
                    {activeLink === 'Settings' && <Settings />}
                </div>
            </div>

        </div>
    )
}

export default Dashboard 