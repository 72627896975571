import React from 'react'
import { useDispatch } from 'react-redux'
import { FaUser } from 'react-icons/fa'

import UserPosition from '../utils/UserPosition'
import { useStateContext } from '../../state/context'
import { setSmsReceiver } from '../../state/actions/actions'
import PaginationButtons from '../utils/PaginationButtons'


const PatientSmsUsersTable = ({ data, 
    showList, setShowList, 
    // pages, currentPage, handlePagination
 }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    return (
        <div className="flex justify-between items-center w-full px-1">
            <div className="flex flex-col w-full cursor-pointer">
                <div className="py-2">
                    <div className="flex justify-between items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1">
                        <div
                            className="flex justify-start items-center space-x-4"
                            onClick={() => setShowList(!showList)}
                        >
                            <h4 className="text-dark-blue text-[14px] font-bold">
                                Personal Doctor
                            </h4>
                            <FaUser className="text-dark-blue" size={20} />
                        </div>

                        <div className="">
                            <input
                                type="text"
                                className="border rounded text-[12px] px-4 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                            />
                        </div>
                    </div>
                    {showList && (
                        <table className="border rounded w-full cursor-default">
                            <thead className="">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Full name
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Position
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Gender
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Phone
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((user, index) => (
                                    <tr className="" key={user.id}>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {user.first_name} {user.last_name}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            <UserPosition user={user} />
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {user.gender}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {user.patient_phone ? user.patient_phone : '-'}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            <button
                                                type="button"
                                                className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                                onClick={() => {
                                                    const data = {
                                                        first_name: user.first_name,
                                                        last_name: user.last_name,
                                                        id: user.user_id,
                                                        phone: user.patient_phone,
                                                    }
                                                    dispatch(setSmsReceiver({ data }))

                                                    setModals({ ...modals, showSendSmsModal: !modals?.showSendSmsModal })
                                                }}
                                            >
                                                Send SMS
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            {/* <PaginationButtons
                                pages={pages}
                                currentPage={currentPage}
                                handlePagination={handlePagination}
                            /> */}
                        </table>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PatientSmsUsersTable