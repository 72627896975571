import React, { useState, useEffect, Fragment } from 'react'
// import VideoCall from './VideoCall';
// import * as roomHandler from '../../config/groupcallConfig/roomHandler';
import Room from '../room/Room';
import { useDispatch, useSelector } from 'react-redux';
import ActiveRoomButton from './ActiveRoomButton';
import { useStateContext } from '../../state/context';

export let socket = 0

const SOCKET_URL = process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_CHAT_SERVER_DEV : process.env.REACT_APP_CHAT_SERVER_PROD


export const GroupCall = () => {
    const { modals, setModals } = useStateContext()
    const { isUserInRoom, activeRooms } = useSelector(state => state.room)
    const [isRoomMinimized, setIsRoomMinimized] = useState(true);
    const { user } = useSelector(state => state.auth)
    const roomResizeHandler = () => {
        setIsRoomMinimized(!isRoomMinimized);
    }
    const { me, setMe, getUserMedia, setGetUserMedia, name, setName, callAccepted, myVideo, userVideo, callEnded, stream, call, answerCall, leaveCall, isStreamSet, setIsStreamSet } = useStateContext()


    useEffect(() => {
        setGetUserMedia(!getUserMedia)

        return () => {
            setGetUserMedia(true)
        }
    }, [myVideo, userVideo])
    useEffect(() => {

    }, [isStreamSet])


    return (
        <div className='w-full'>

            <div>
                {!isUserInRoom && <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-dark-blue text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-blue active:shadow-lg transition duration-500 ease"
                    onClick={() => {

                        setModals({ ...modals, showCreateRoomModal: !modals.showCreateRoomModal })
                    }

                    }
                // onClick={() => roomHandler.createNewRoom()}
                >
                    Create room
                </button>
                }

            </div>


            {!isUserInRoom && <div className='flex-col w-[200px] h-full'>
                <h1 className='text-[13px] py-1'>Active rooms</h1>
                {activeRooms.map((room) => (
                    <Fragment>

                        {room?.invitees?.includes(user?.data?.pkid) &&
                            <ActiveRoomButton
                                roomId={room.roomId}
                                creatorId={room.roomCreator.userId}
                                roomName={room.roomName}
                                key={room.roomId}
                                amountOfParticipants={room.participants.length}
                                isUserInRoom={isUserInRoom}
                            />

                        }
                    </Fragment>
                ))}
            </div>}





            <div className=''>
                {isUserInRoom && <Room
                    isRoomMinimized={isRoomMinimized}
                    setIsRoomMinimized={setIsRoomMinimized}
                    roomResizeHandler={roomResizeHandler}

                />

                }
            </div>


        </div >
    )
}