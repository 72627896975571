import { API } from "../config";

export const getOpds = (token) => API.get('/nurse/see_injection_request/', { headers: { Authorization: `Bearer ${token}` } })

export const getPatientDiagnosis = (patient_pkid, token) => API.get(`/nurse/get_patient_diagnosis/${patient_pkid}/`, { headers: { Authorization: `Bearer ${token}` } })

export const getPatientDiagnosisPrescriptions = (id, token) => API.get(`/nurse/get_patient_diagnosis_prescriptions/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const updateOpdInjection = (formData, token) => API.put(`/nurse/see_injection_request/${formData.id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const addPerformanceData = (formData, token) => API.post(`/nurse/patient_performance/${formData.patient_id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const getPatientPerformanceData = (patient_id, token) => API.get(`/nurse/patient_performance/${patient_id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const nurseaddPatient = (formData, token) => API.post('/nurse/nurse_add_patient/', formData, { headers: { Authorization: `Bearer ${token}` } })

export const getDoctors = (token) => API.get('/nurse/all_doctors/', { headers: { Authorization: `Bearer ${token}` } })

export const getPatientsToBeAdmitted = (token) => API.get('/nurse/nurse_inpatient/', { headers: { Authorization: `Bearer ${token}` } })

export const getWards = (token) => API.get('/nurse/all_wards/', { headers: { Authorization: `Bearer ${token}` } })

export const getWardBedspaces = (ward_id, token) => API.get(`/nurse/all_ward_bedspace/${ward_id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const generateAdmitPatient = (formData, token) => API.put(`/nurse/nurse_inpatient/${formData.inpatient_id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const getAdmittedPatient = (token) => API.get('/nurse/all_admitted_patient/', { headers: { Authorization: `Bearer ${token}` } })

export const getPatients = (token) => API.get('/nurse/all_patient/', { headers: { Authorization: `Bearer ${token}` } })

export const bookPatientAntenatal = (id, formData, token) => API.post(`/nurse/antenatal_booking/${id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const getBookedAntenatals = (token) => API.get('/nurse/antenatal_booking/', { headers: { Authorization: `Bearer ${token}` } })

export const addPatientMedicalRecord = (formData, token) => API.post(`/nurse/patient_medical_record/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const getPatientMedicalRecord = (id, token) => API.get(`/nurse/patient_medical_record/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const addPatientPregnancyRecord = (formData, token) => API.post(`/nurse/pregnancy_record/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const getPatientPregnancyRecord = (id, token) => API.get(`/nurse/pregnancy_record/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const addPatientLabourRecord = (formData, token) => API.post(`/nurse/patient_labour_record/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const getPatientLabourRecord = (id, token) => API.get(`/nurse/patient_labour_record/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const deletePatientLabourRecord = (id, token) => API.delete(`/nurse/patient_labour_record/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const addPatientInvestigationRecord = (formData, token) => API.post(`/nurse/investigation_record/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const getPatientInvestigationRecord = (id, token) => API.get(`/nurse/investigation_records/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const deletePatientInvestigationRecord = (id, token) => API.delete(`/nurse/investigation_record/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const addPatientVisitRecord = (formData, token) => API.post(`/nurse/patient_visit_record/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const getPatientVisitRecord = (id, token) => API.get(`/nurse/patient_visit_record/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const deletePatientVisitRecord = (id, token) => API.delete(`/nurse/patient_visit_record/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const bookPatientForFamilyPlanning = (id, formData, token) => API.post(`/nurse/book_patient_family_planning/${id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const getFamilyPlanningBookings = (token) => API.get('/nurse/book_patient_family_planning/', { headers: { Authorization: `Bearer ${token}` } })

export const addPatientFamilyFollowUpRecord = (id, formData, token) => API.post(`/nurse/create_family_planning_follow_up_record/${id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })
export const getPatientFamilyFollowUpRecords = (id, token) => API.get(`/nurse/create_family_planning_follow_up_record/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const addPatientDailyReport = (formData, token) => API.post(`/nurse/patient_daily_report/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })
export const getPatientDailyReports = (id, token) => API.get(`/nurse/patient_daily_report/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const addPatientTreatmentReport = (formData, token) => API.post(`/nurse/patient_treatment_report/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const getPatientTreamentReports = (id, token) => API.get(`/nurse/patient_treatment_report/${id}/`, { headers: { Authorization: `Bearer ${token}` } })


export const bookPatientForImmunization = (id, formData, token) => API.post(`/nurse/book_patient_for_immunization/${id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const getPatientForImmunization = (token) => API.get(`/nurse/book_patient_for_immunization/`, { headers: { Authorization: `Bearer ${token}` } })

export const addVaccinationImmunizationForPatient = (formData, token) => API.post(`/nurse/vaccination_records/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const getPatientVaccinationRecords = (id, token) => API.get(`/nurse/get_vaccination_records/${id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const getProfiles = (token) => API.get(`/nurse/all_profiles/`, { headers: { Authorization: `Bearer ${token}` } })

export const addBirthRecord = (formData, token) => API.post(`/records/birth_record/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const getBirthRecords = (token) => API.get(`/records/birth_records/`, { headers: { Authorization: `Bearer ${token}` } })

export const getDeathRecords = (token) => API.get(`/records/death_records/`, { headers: { Authorization: `Bearer ${token}` } })

export const addDeathRecord = (formData, token) => API.post(`/records/death_record/${formData.patient_number}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const deletePatientFamilyFollowUpRecord = (id, token) => API.delete(`/nurse/create_family_planning_follow_up_record/${id}/`, { headers: { Authorization: `Bearer ${token}` } })




// export const deleteLaboratoryEquipment = (equipment_id, token) => API.delete(`/laboratorist/laboratory_equipments/${equipment_id}/`, { headers: { Authorization: `Bearer ${token}` } })
// export const getLaboratoryReport = (token) => API.get('/laboratorist/lab_reports/', { headers: { Authorization: `Bearer ${token}` } })
// export const getALabBillingDetails = (billing_id, token) => API.get(`/administrator/billings/${billing_id}/`, { headers: { Authorization: `Bearer ${token}` } })
// export const getAllLabBilling = (token) => API.get('/laboratorist/billings/', { headers: { Authorization: `Bearer ${token}` } })
// export const generateLabInvoice = (formData, token) => API.post(`/laboratorist/lab_invoice/${formData.patient_id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })
// export const uploadPatientLaboratoryReport = (formData, token) => API.put(`/laboratorist/lab_reports/${formData.report_id}/`, formData, { headers: { Authorization: `Bearer ${token}`, "content-type": "multipart/form-data" } })
// export const getInvoiceGeneratedByUser = (token) => API.get('/laboratorist/lab_invoice_by_me/', { headers: { Authorization: `Bearer ${token}` } })
// export const getLaboratoryInvestigationCategory = (token) => API.get('/laboratorist/all_lab_investigation_cat/', { headers: { Authorization: `Bearer ${token}` } })

// export const deleteLaboratoryCategoryTest = (cat_id, token) => API.delete(`/laboratorist/get_lab_investigation_cat/${cat_id}/`, { headers: { Authorization: `Bearer ${token}` } })
// export const updateLaboratoryCategoryTest = (formData, token) => API.put(`/laboratorist/get_lab_investigation_cat/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })
// export const getLaboratoryInvestigationTypes = (token) => API.get('/laboratorist/all_lab_investigation_type/', { headers: { Authorization: `Bearer ${token}` } })
// export const addLaboratoryInvestigationType = (formData, token) => API.post('/laboratorist/create_lab_investigation_type/', formData, { headers: { Authorization: `Bearer ${token}` } })
// export const updateLaboratoryInvestigationType = (formData, token) => API.put(`/laboratorist/lab_investigation_type/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })
// export const deleteLaboratoryInvestigationType = (investigation_id, token) => API.delete(`/laboratorist/lab_investigation_type/${investigation_id}/`, { headers: { Authorization: `Bearer ${token}` } })
// export const addLaboratoryInvestigationSamples = (formData, token) => API.post('/laboratorist/create_lab_sample/', formData, { headers: { Authorization: `Bearer ${token}` } })
// export const getLabSampleTest = (token) => API.get('/laboratorist/get_lab_samples/', { headers: { Authorization: `Bearer ${token}` } })
// export const updateLabSampleTest = (formData, token) => API.put(`/laboratorist/lab_sample_test/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })
// export const deleteLaboratorySampleTest = (id, token) => API.delete(`/laboratorist/lab_sample_test/${id}/`, { headers: { Authorization: `Bearer ${token}` } })
