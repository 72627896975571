import React from 'react'
import { useSelector } from 'react-redux'
import { GrFormNext } from 'react-icons/gr'
import { FaUserInjured } from 'react-icons/fa'
import { BsCardChecklist } from 'react-icons/bs'
import { GiDoctorFace, GiFirstAidKit } from 'react-icons/gi'


const DoctorSectionsSummary = () => {

    const { dashboard_data } = useSelector(state => state.doctor)

    return (
        <div className="flex flex-wrap justify-between items-center">
            <div className="border-t border-l border-r rounded w-[220px] cursor-pointer shadow-green-200 shadow-lg hover:shadow-green-300 hover:translate-x-1 ease-in-out duration-500">
                <div className="flex flex-col">
                    <div className="py-4 flex flex-col items-center">
                        <BsCardChecklist
                            className='text-green-600'
                        />

                        <p className='text-[18px] text-dark-blue'>Appointments</p>
                        <h4 className='text-[20px] text-dark-blue font-bold'>{dashboard_data?.appointments}</h4>
                    </div>

                    <div className="text-[14px] text-dark-blue font-normal flex justify-center items-center bg-green-200 w-full">
                        View Detailed Report <span className="flex space-x-[-10px]"><GrFormNext /><GrFormNext /><GrFormNext /></span>
                    </div>
                </div>
            </div>

            <div className="border-t border-l border-r rounded w-[220px] cursor-pointer shadow-yellow-200 shadow-lg hover:shadow-yellow-300 hover:translate-x-1 ease-in-out duration-500">
                <div className="flex flex-col">
                    <div className="py-4 flex flex-col items-center">
                        <FaUserInjured
                            className='text-yellow-600'
                        />

                        <p className='text-[18px] text-dark-blue'>Patients</p>
                        <h4 className='text-[20px] text-dark-blue font-bold'>{dashboard_data?.patients}</h4>
                    </div>

                    <div className="text-[14px] text-dark-blue font-normal flex justify-center items-center bg-yellow-200 w-full">
                        View Detailed Report <span className="flex space-x-[-10px]"><GrFormNext /><GrFormNext /><GrFormNext /></span>
                    </div>
                </div>
            </div>

            <div className="border-t border-l border-r rounded w-[220px] cursor-pointer shadow-indigo-200 shadow-lg hover:shadow-indigo-300 hover:translate-x-1 ease-in-out duration-500">
                <div className="flex flex-col">
                    <div className="py-4 flex flex-col items-center">
                        <GiDoctorFace
                            className='text-indigo-600'
                        />

                        <p className='text-[18px] text-dark-blue'>Admissions</p>
                        <h4 className='text-[20px] text-dark-blue font-bold'>{dashboard_data?.admissions}</h4>
                    </div>

                    <div className="text-[14px] text-dark-blue font-normal flex justify-center items-center bg-indigo-200 w-full">
                        View Detailed Report <span className="flex space-x-[-10px]"><GrFormNext /><GrFormNext /><GrFormNext /></span>
                    </div>
                </div>
            </div>

            <div className="border-t border-l border-r rounded w-[220px] cursor-pointer shadow-fuchsia-200 shadow-lg hover:shadow-fuchsia-300 hover:translate-x-1 ease-in-out duration-500">
                <div className="flex flex-col">
                    <div className="py-4 flex flex-col items-center">
                        <GiFirstAidKit
                            className='text-fuchsia-600'
                        />

                        <p className='text-[18px] text-dark-blue'>Medicine Shortage</p>
                        <h4 className='text-[20px] text-dark-blue font-bold'>{dashboard_data?.shortages}</h4>
                    </div>

                    <div className="text-[14px] text-dark-blue font-normal flex justify-center items-center bg-fuchsia-200 w-full">
                        View Detailed Report <span className="flex space-x-[-10px]"><GrFormNext /><GrFormNext /><GrFormNext /></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DoctorSectionsSummary