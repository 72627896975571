/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { admin__remittance__payment__methods } from '../../../data'
import { useStateContext } from '../../../state/context'
import { createAdminPayStackConfig, } from '../../../state/actions/collection.actions'
import { initializeZuumPayCredentials, updateWebHookPayment } from '../../../state/actions/admin.actions'
import { toast } from 'react-toastify'


const PayMonthlyRemittanceModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { access, data } = useSelector(state => state.auth.user)
    const { current_invoice, hospital_config } = useSelector(state => state.admin)
    const { paystack_config, collectionLoading, flutterwave_config } = useSelector(state => state.collection)

    const [formData, setFormData] = useState({ payment_method: '', email: '', amount: 0, phone: '', fullname: '', payment_type: 'remittance', reference: '', })

    useEffect(() => {
        setFormData({
            ...formData,
            email: data?.email,
            amount: current_invoice?.amount,
            phone: '09030391123',
            fullname: data?.last_name.concat(' ').concat(data?.first_name)
        })
    }, [data, current_invoice])

    useEffect(() => {
        const _data = {
            ...current_invoice,
            price: current_invoice?.amount,
            amount_paid: current_invoice?.amount + (current_invoice?.amount * 0.075),
        }
        if (paystack_config?.authorization_url) {
            const config = {
                ...formData,
                amount: parseInt(current_invoice?.amount) + parseInt(current_invoice?.amount * 0.075),
                organization: hospital_config?.name,
                payment_title: 'monthly_remittance',
                payment_author: data?.pkid,
                invoice: current_invoice?.pkid,
                reference: paystack_config?.reference,
                authorization: access,
                api_callback_url: '/administrator/monthly_remittances/'
            }
            // console.log(config, paystack_config)
            dispatch(initializeZuumPayCredentials({ formData: { ...config }, paystack_url: paystack_config?.authorization_url }))
            // localStorage.setItem('payment__invoice', JSON.stringify({ ..._data, type: 'monthly__remittance__payment' }))

            // window.location.href = paystack_config?.authorization_url
        }
    }, [paystack_config, flutterwave_config])

    const handleChange = (name, e) => {
        setFormData({ ...formData, [name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (formData.payment_method === 'paystack') {
            dispatch(createAdminPayStackConfig({ formData }))
        }

        if (formData.payment_method === 'transfer') {
            console.log(formData);

            dispatch(updateWebHookPayment({ formData, toast, modals, setModals }))
        }
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div
                className="absolute right-[4em] top-0 text-[60px] font-medium text-white cursor-pointer"
                onClick={() => {
                    setModals({ ...modals, showPayMonthlyRemittanceModal: !modals.showPayMonthlyRemittanceModal })
                }}
            >
                x
            </div>

            <div className="bg-white w-[500px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-2">
                    <div className="flex justify-between items-center">
                        <h4 className='text-sky-500 text-[20px] font-bold'>
                            {formData.payment_method ? formData.payment_method[0].toUpperCase().concat(formData.payment_method.slice(1, formData.payment_method.length)) : 'Payment Method'}
                        </h4>

                    </div>
                    <div className="flex flex-col items-end">
                        <div className="grid grid-cols-2">
                            <span className="text-gray-800 px-[10px] text-[10px] font-bold py-[1px] rounded">Price:</span>
                            <span className="text-gray-600 px-[10px] text-[10px] font-medium py-[1px] rounded">&#8358; {parseInt(current_invoice?.amount) + parseInt(current_invoice?.amount * 0.075)}</span>
                        </div>
                    </div>


                    {formData.payment_method !== 'transfer' && (
                        <form onSubmit={handleSubmit}>
                            <div className='space-y-2'>
                                <div className="relative">
                                    <select
                                        className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-1 px-4 pr-8 rounded leading-tight focus:outline-none "
                                        name="payment_method"
                                        onChange={(e) => handleChange(e.target.name, e)}
                                    >
                                        <option>Payment Method...</option>
                                        {admin__remittance__payment__methods?.map((type, index) => (
                                            <option
                                                key={`${type.id}-${index}`}
                                                value={type.value}
                                            >
                                                {type.title}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg
                                            className="fill-current h-4 w-4"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                        >
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-start mt-4'>
                                <button
                                    type='submit'
                                    className="inline-block w-full px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded-full shadow-md hover:scale-90 transition duration-500 ease"
                                >
                                    {!collectionLoading ? (
                                        'Submit'
                                    ) : (
                                        <svg className="flex justify-center w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
                                            viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                                            </path>
                                        </svg>
                                    )}
                                </button>
                            </div>
                        </form>
                    )}

                    {formData.payment_method === 'transfer' && (

                        <div>
                            <div>
                                <input
                                    type="text"
                                    name="reference"
                                    className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    placeholder="Enter reference code"
                                    onChange={(e) => handleChange(e.target.name, e)}
                                />
                            </div>
                            {[1].map((item, index) => (
                                <form key={item} onSubmit={handleSubmit}>
                                    {/* {index === 0 && (
                                        <div className='space-y-2'>
                                            <label className="bg-gray-600 text-white px-[5px] py-[1px] text-[10px] rounded">Bank</label>
                                            <div>
                                                <input
                                                    type="text"
                                                    disabled
                                                    value="Guaranty Trust Bank"
                                                    className="border rounded w-full h-5 px-4 py-5 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"

                                                />
                                            </div>
                                            <div>
                                                <input
                                                    type="text"
                                                    disabled
                                                    value="0741491748"
                                                    className="border rounded w-full h-5 px-4 py-5 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                />
                                            </div>
                                        </div>
                                    )} */}
                                    {index === 0 && (
                                        <div className='space-y-2 mt-5'>
                                            <label className="bg-gray-600 text-white px-[5px] py-[1px] text-[10px] rounded">Bank</label>
                                            <div>
                                                <input
                                                    type="text"
                                                    disabled
                                                    value="Wema Bank"
                                                    className="border rounded w-full h-5 px-4 py-5 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"

                                                />
                                            </div>
                                            <div>
                                                <input
                                                    type="text"
                                                    disabled
                                                    value="8206520935"
                                                    className="border rounded w-full h-5 px-4 py-5 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {/* {index === 1 && (
                                        <div className='space-y-2 mt-5'>
                                            <label className="bg-gray-600 text-white px-[5px] py-[1px] text-[10px] rounded">Bank</label>
                                            <div>
                                                <input
                                                    type="text"
                                                    disabled
                                                    value="FCMB Bank"
                                                    className="border rounded w-full h-5 px-4 py-5 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"

                                                />
                                            </div>
                                            <div>
                                                <input
                                                    type="text"
                                                    disabled
                                                    value="9236087019"
                                                    className="border rounded w-full h-5 px-4 py-5 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                />
                                            </div>
                                        </div>
                                    )} */}
                                    <div className='flex justify-start mt-4'>
                                        <button
                                            type='submit'
                                            className="inline-block w-full px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded-full shadow-md hover:scale-90 transition duration-500 ease"
                                        >

                                            Confirm payment

                                        </button>
                                    </div>
                                </form>
                            ))}
                        </div>
                    )}

                </div>
            </div>
        </div>
    )
}
export default PayMonthlyRemittanceModal