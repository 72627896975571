import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { MdOutlineFamilyRestroom } from 'react-icons/md'

import { useStateContext } from '../../state/context'
import { setCurrentPatient } from '../../state/actions/actions'
import Pagination from '../utils/Pagination'


const DoctorPatientDependantsTable = ({ data, showList, setShowList, title }) => {
    const dispatch = useDispatch()

    const { appState, setAppState } = useStateContext()

    const [currentData, setCurrentData] = useState([])

    return (
        <div className="flex justify-between items-center w-full">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="py-2">
                    <div
                        className="flex justify-start items-center space-x-4 bg-gray-200 p-1"
                        onClick={() => setShowList(!showList)}
                    >
                        <h4 className="text-dark-blue text-[14px] font-bold">
                            {title}
                        </h4>
                        <MdOutlineFamilyRestroom className="text-dark-blue" size={20} />
                    </div>
                    <Fragment>
                        <table className="border rounded w-full cursor-default">
                            <thead className="">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] py-4 text-left"
                                    >
                                        Patient ID
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Fullname
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Picture
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Gender
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentData?.map((patient, index) => (
                                    <tr key={`${patient.id}`}>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {patient.patient_number}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {patient.details.last_name} {patient.details.first_name}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light whitespace-nowrap">
                                            <img
                                                src={`${process.env.REACT_APP_API_ROOT}${patient.profile_photo}`}
                                                alt=""
                                                className='w-[50px] h-[50px] rounded-[100%]'
                                            />
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {patient.gender}
                                        </td>
                                        <td className=" flex text-2xl space-x-4 text-gray-900 font-semibold py-2 whitespace-nowrap">
                                            <button
                                                type="button"
                                                className="inline-block px-6 py-2 bg-purple-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                onClick={() => {
                                                    dispatch(setCurrentPatient({ data: { ...patient, is_assigned_patient: false } }))
                                                    setAppState({ ...appState, showDoctorManagePatient: !appState.showDoctorManagePatient, showDoctorPatientDependants: !appState.showDoctorPatientDependants })
                                                }}
                                            >
                                                Manage
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <Pagination
                            data={data}
                            currentData={currentData}
                            setCurrentData={setCurrentData}
                        />
                    </Fragment>
                </div>
            </div>
        </div >
    )
}

export default DoctorPatientDependantsTable