/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FaPencilAlt, FaTrash } from 'react-icons/fa'

import Pagination from '../utils/Pagination'
import { useStateContext } from '../../state/context'
import { setCurrentSupplyPoint, setSupplyPoint } from '../../state/actions/actions'


const PharmacistSupplyPointsTable = ({ data, showList, setShowList, title }) => {
    const dispatch = useDispatch()

    const { appState, setAppState, modals, setModals } = useStateContext()

    const [currentData, setCurrentData] = useState([])
    const [filterState, setFilterState] = useState({ search: '', supplypoints: [] })

    const handleFilter = () => {
        const filtered = data?.filter(supplypoint => {
            return supplypoint.name.toLowerCase().includes(filterState.search.toLowerCase())
        })

        setFilterState({ ...filterState, supplypoints: filtered })

        if (filterState.search === '') {
            setFilterState({ ...filterState, supplypoints: data })
        }
    }

    useEffect(() => {
        setFilterState({ ...filterState, supplypoints: data })
    }, [data])

    useEffect(() => {
        handleFilter()
    }, [filterState.search])

    return (
        <div className="flex justify-between items-center w-full px-1">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="py-2">
                    <div
                        className="flex justify-start items-center space-x-4 rounded-t-lg"
                    // onClick={() => setShowList(!showList)}
                    >
                        <input
                            type="text"
                            name="search"
                            placeholder='Search'
                            className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                            onChange={(e) => setFilterState({ ...filterState, [e.target.name]: e.target.value })}
                        />
                    </div>
                    {showList && (
                        <table className="border rounded w-full cursor-default">
                            <thead className="bg-gray-100">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] py-4 text-left"
                                    >
                                        Name
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Added By
                                    </th>

                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >

                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentData?.map((item, index) => (
                                    <tr key={`${item.id}`}>
                                        <td
                                            className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900"
                                        // value={billing.pkid}
                                        >
                                            {index + 1}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item?.name}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            Pharm. {item?.supplypoint_author?.first_name} {item?.supplypoint_author?.last_name}
                                        </td>
                                        <td className=" flex text-2xl space-x-4 text-gray-900 font-semibold py-2 whitespace-nowrap">
                                            <FaPencilAlt
                                                size={20}
                                                className='text-green-600 cursor-pointer'
                                                onClick={() => {
                                                    dispatch(setSupplyPoint({ data: item }))
                                                    setModals({ ...modals, showPharmacistUpdateSupplyPointModal: !modals.showPharmacistUpdateSupplyPointModal })
                                                }}
                                            />
                                            <FaTrash
                                                onClick={() => {
                                                    dispatch(setSupplyPoint({ data: item }))
                                                    setModals({ ...modals, showPharmacistDeleteSupplyPointModal: !modals.showPharmacistDeleteSupplyPointModal })
                                                }}
                                                className="text-orange-400 cursor-pointer"
                                                size={20}
                                            />
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            <button
                                                type="button"
                                                className="inline-block px-6 py-2 bg-purple-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                onClick={() => {
                                                    dispatch(setCurrentSupplyPoint({ data: item }))
                                                    setAppState({ ...appState, showSupplyPoint: !appState.showSupplyPoint })
                                                }}
                                            >
                                                Manage
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    <Pagination
                        data={filterState.supplypoints}
                        currentData={currentData}
                        setCurrentData={setCurrentData}
                    />
                </div>
            </div>
        </div >
    )
}

export default PharmacistSupplyPointsTable