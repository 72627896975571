/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FaTimesCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { useStateContext } from "../../../state/context";
import { setBilling, setCurrentPrescriptionInvoice, setCurrentPrescriptions } from "../../../state/actions/actions";
import { createPrescriptionsInvoice, dispenseDrug, getDrugPrices, getPaidPrescriptions, resetPaidPrescriptions } from "../../../state/actions/pharmacist.actions";


const PharmacistDrugDispenseModal = () => {
    const dispatch = useDispatch();

    const { modals, setModals } = useStateContext()

    const { user } = useSelector(state => state.auth)
    const { prescription_invoice_update, supplypoint_update } = useSelector(state => state.root)
    const { paid_prescriptions, supplypoint_inventory } = useSelector(state => state.pharmacist)

    useEffect(() => {
        dispatch(getPaidPrescriptions({ params: { prescription: prescription_invoice_update?.prescription } }))
    }, [prescription_invoice_update])

    const handleDispense = (e, params) => {
        e.preventDefault()

        console.log(params)
        dispatch(dispenseDrug({ formData: params, toast, modals, setModals }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className={`${modals.showPharmacistDispenseDrugModal ? 'w-[800px]' : 'w-[1200px]'} bg-white px-[20px] py-[15px]`}>
                <div className="flex flex-col space-y-2">
                    <div className="flex justify-between items-center">
                        <h4 className={`${modals.showPharmacistDispenseDrugModal ? 'text-sky-500' : 'text-dark-green'} text-[18px] font-medium capitalize`}>
                            {modals.showPharmacistDrugInvoiceModal ? 'Generate Invoice' : 'Drug Dispense'}
                        </h4>
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                dispatch(resetPaidPrescriptions())
                                dispatch(setCurrentPrescriptions({ data: null }))
                                dispatch(setCurrentPrescriptionInvoice({ data: null }))
                                modals.showPharmacistDrugInvoiceModal && setModals({ ...modals, showPharmacistDrugInvoiceModal: !modals.showPharmacistDrugInvoiceModal })
                                modals.showPharmacistDispenseDrugModal && setModals({ ...modals, showPharmacistDispenseDrugModal: !modals.showPharmacistDispenseDrugModal })
                            }}
                            size={20}
                        />
                    </div>

                    {/* user info */}
                    {modals.showPharmacistDispenseDrugModal && (
                        <div className="flex items-center space-x-5">
                            <img
                                src={`${process.env.REACT_APP_API_ROOT}${prescription_invoice_update?.invoice_patient?.profile_photo}`}
                                alt=""
                                className="w-[80px] h-[80px] rounded-[50%]"
                            />

                            <div className="flex flex-col space-y-1">
                                <p className="text-[14px] text-gray-600 font-bold">{prescription_invoice_update?.invoice_patient?.last_name} {prescription_invoice_update?.invoice_patient?.first_name}</p>
                                <p className="text-[12px] text-gray-300 font-medium">{prescription_invoice_update?.invoice_patient_id}</p>
                            </div>
                        </div>
                    )}

                    <table className="border rounded w-full cursor-default">
                        <thead className="">
                            <tr>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pl-2 pr-[2px] py-4 text-left bg-yellow-200"
                                >
                                    Route
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[2px] py-4 text-left bg-yellow-200"
                                >
                                    Drug
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[2px] py-4 text-left bg-yellow-200"
                                >
                                    Units
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[2px] py-4 text-left bg-yellow-200"
                                >
                                    Frequency
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 pr-[2px] py-4 text-left bg-yellow-200"
                                >
                                    Duration/Times
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 py-4 text-left bg-sky-200"
                                >
                                    Quantity
                                </th>
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 py-4 text-left bg-sky-200"
                                >

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {paid_prescriptions?.map((item, index) => (
                                <tr
                                    key={`${item.id}`}
                                >
                                    <td className="text-sm text-gray-900 font-light pl-2 py-2 whitespace-nowrap">
                                        {item.routes}
                                    </td>
                                    <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                        {item.drugs}
                                    </td>
                                    <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                        {item.units}
                                    </td>
                                    <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                        {item.frequency}
                                    </td>
                                    <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap bg-yellow-200">
                                        {item.duration}/{item.times}
                                    </td>
                                    <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap bg-sky-200">
                                        {item.drug_quantity}
                                    </td>
                                    <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap flex justify-end bg-sky-200">
                                        {supplypoint_inventory?.filter((inventory, index) => inventory.supplypoint_druginventory.bincard_drug.name === item.drugs)[0] && (
                                            <button
                                                type="submit"
                                                onClick={(e) => {
                                                    const params = {
                                                        quantity: item.drug_quantity,
                                                        drug: supplypoint_inventory?.filter((inventory, index) => inventory.supplypoint_druginventory.bincard_drug.name === item.drugs)[0]?.supplypoint_druginventory.bincard_drug.pkid,
                                                        supplypoint: supplypoint_update ? supplypoint_update?.pkid : user?.data?.assigned_supplypoint_details?.pkid,
                                                        prescription_id: item.pkid,
                                                    }
                                                    handleDispense(e, params)
                                                }}
                                                disabled={item.is_dispensed}
                                                className={`inline-block w-[100px] px-6 py-2.5 ${item.is_dispensed ? 'bg-teal-600' : 'bg-sky-600'} text-white font-medium text-xs leading-tight shadow-md hover:scale-[.98] transition duration-500 ease`}
                                            >
                                                {item.is_dispensed ? 'Dispensed' : 'Dispense'}
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    );
};

export default PharmacistDrugDispenseModal

