import React, { useState, useEffect } from 'react'
import { useStateContext } from '../../../state/context'
import { FaTimesCircle } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { addALaboratoryInvestigationType, updateALaboratoryInvestigationType } from '../../../state/actions/laboratorist.actions'

const LaboratoryAddInvestigationTypeModal = () => {
    const { modals, setModals } = useStateContext()
    const dispatch = useDispatch()
    const { all_lab_category_test, } = useSelector((state) => state.laboratorist)
    const { set_laboratory_investigation_type } = useSelector((state) => state.root)

    const [formData, setFormData] = useState({
        type_title: '',
        investigation_category: '',
        id: '',
        is_available: ''
    })
    const [checked, setChecked] = useState(false)
    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        // console.log(formData)
        if (!formData.type_title) return toast.warning('Title field is required!')
        { modals.showLaboratoryAddInvestigationType && (dispatch(addALaboratoryInvestigationType({ formData, toast, setModals: setModals, modals: modals }))) }
        { modals.showLaboratoryUpdateInvestigationType && (dispatch(updateALaboratoryInvestigationType({ formData, toast, setModals: setModals, modals: modals }))) }
    }

    const handleCheckboxChange = () => {
        setChecked(!checked);
        setFormData({ ...formData, is_available: !checked });
    };

    useEffect(() => {
        if (modals.showLaboratoryUpdateInvestigationType) {
            setFormData({
                ...formData, investigation_category: set_laboratory_investigation_type?.category_id,
                type_title: set_laboratory_investigation_type?.title,
                id: set_laboratory_investigation_type?.investigation_id
            })
        }

    }, [
        set_laboratory_investigation_type
    ])

    useEffect(() => {
        setChecked(set_laboratory_investigation_type?.status ? set_laboratory_investigation_type?.status : checked)

    }, [set_laboratory_investigation_type])


    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>

                            <>

                                {modals.showLaboratoryAddInvestigationType && (
                                    <h4 className='text-dark-green text-[20px] font-bold'>
                                        Add Investigation Type
                                    </h4>
                                )}
                                {modals.showLaboratoryUpdateInvestigationType && (
                                    <h4 className='text-dark-green text-[20px] font-bold'>
                                        Update Investigation Type - {set_laboratory_investigation_type?.title}
                                    </h4>
                                )}

                                <FaTimesCircle
                                    className='text-dark-yellow cursor-pointer'
                                    showLaboratoryUpdateInvestigationType
                                    onClick={() => {
                                        if (modals.showLaboratoryAddInvestigationType) {
                                            setModals({
                                                ...modals,
                                                showLaboratoryAddInvestigationType:
                                                    !modals.showLaboratoryAddInvestigationType,
                                            })
                                        }
                                        if (modals.showLaboratoryUpdateInvestigationType) {
                                            setModals({
                                                ...modals,
                                                showLaboratoryUpdateInvestigationType:
                                                    !modals.showLaboratoryUpdateInvestigationType,
                                            })

                                        }
                                    }





                                    }
                                    size={20}
                                />

                            </>

                        </div>




                        <form onSubmit={handleSubmit} >

                            {modals.showLaboratoryAddInvestigationType && (
                                <div className='flex justify-between-items-center space-x-10'>
                                    <div className='space-y-5 w-full'>
                                        <select name="investigation_category" placeholder='Type' id="" className='border rounded mt-2 pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 '
                                            onChange={(e) =>
                                                handleChange(e, e.target.name)
                                            }
                                        >
                                            <option value={null}>Select Category</option>
                                            {all_lab_category_test?.map((category, index) => (
                                                <option value={category.pkid}>{category.title}</option>
                                            ))}

                                        </select>

                                    </div>

                                </div>
                            )}
                            {modals.showLaboratoryUpdateInvestigationType && (
                                <div className='flex justify-between-items-center space-x-10'>
                                    <div className='space-y-5 w-full'>

                                    </div>

                                </div>
                            )}
                            <h4 className='text-sm font-semibold py-2'>Enter Title</h4>
                            <div className=''>

                                <input
                                    type='text'
                                    name='type_title'
                                    placeholder='Name'
                                    value={formData.type_title}


                                    className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
                                    onChange={(e) =>
                                        handleChange(e, e.target.name)
                                    }
                                />
                                <div className='flex space-x-2 mt-2'>
                                    <input type="checkbox"
                                        className='w-[20px] h-[20px]'
                                        checked={checked}
                                        onChange={handleCheckboxChange}
                                        name="is_available" id="" />
                                    <p>Status</p>
                                </div>



                            </div>



                            <div className='mt-4 flex justify-center items-center'>
                                <button
                                    type='submit'
                                    className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
                                // onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>

                        </form>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default LaboratoryAddInvestigationTypeModal