import { useEffect, useState } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { setSupplyPoint } from '../../../state/actions/actions'
import { deleteSupplyPoint } from '../../../state/actions/pharmacist.actions'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { cancelAdmitPatient, dischargeAdmittedPatient, requestAdmitPatient } from '../../../state/actions/doctor.actions'


const DoctorPatientAdmissionModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { patient_update } = useSelector(state => state.root)
    const { data } = useSelector(state => state.auth.user)
    const [formData, setFormData] = useState({ id: '', doctor: 0 })

    useEffect(() => {
        setFormData({
            ...formData,
            id: patient_update?.details?.id, doctor: data?.pkid
        })
    }, [patient_update])

    const handleSubmit = (e) => {
        e.preventDefault()

        console.log(formData)
        dispatch(requestAdmitPatient({ formData, toast, setModals, modals, }))
    }

    const handleCancel = (e) => {
        e.preventDefault()

        console.log(formData)
        dispatch(cancelAdmitPatient({ formData, toast, setModals, modals }))
    }

    const handleDischarge = (e) => {
        e.preventDefault()

        console.log(formData)
        dispatch(dischargeAdmittedPatient({ formData, toast, setModals, modals }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[500px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className={`${modals.showDoctorRequestPatientAdmissionModal ? 'text-dark-green' : 'text-dark-yellow'} text-[20px] font-bold`}>
                            {modals.showDoctorRequestPatientAdmissionModal ? 'Request Patient Admission' : modals.showDoctorDischargePatientAdmissionModal ? 'Discharge Patient' : 'Cancel Patient Admission'}
                        </h4>
                        <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                // dispatch(setSupplyPoint({ data: null }))
                                modals.showDoctorRequestPatientAdmissionModal && setModals({ ...modals, showDoctorRequestPatientAdmissionModal: !modals.showDoctorRequestPatientAdmissionModal })
                                modals.showDoctorCancelRequestPatientAdmissionModal && setModals({ ...modals, showDoctorCancelRequestPatientAdmissionModal: !modals.showDoctorCancelRequestPatientAdmissionModal })
                                modals.showDoctorDischargePatientAdmissionModal && setModals({ ...modals, showDoctorDischargePatientAdmissionModal: !modals.showDoctorDischargePatientAdmissionModal })
                            }}
                            size={20}
                        />
                    </div>

                    <div className="flex items-center space-x-5">
                        <img
                            src={`${process.env.REACT_APP_API_ROOT}${patient_update?.profile_photo}`}
                            alt=""
                            className="w-[80px] h-[80px] rounded-[50%]"
                        />

                        <div className="flex flex-col space-y-1">
                            <p className="text-[14px] text-gray-600 font-bold">{patient_update?.details?.last_name} {patient_update?.details?.first_name}</p>
                            <p className="text-[12px] text-gray-300 font-medium">{patient_update?.patient_number}</p>
                        </div>
                    </div>

                    {modals.showDoctorRequestPatientAdmissionModal && (
                        <form onSubmit={handleSubmit}>
                            <p className="text-left">Are you sure you want to request for <span className='font-bold italic'>bedspace allocation</span> for this patient?</p>

                            <div className='mt-4'>
                                <button
                                    type='submit'
                                    className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                >Submit</button>
                            </div>
                        </form>
                    )}

                    {modals.showDoctorCancelRequestPatientAdmissionModal && (
                        <form onSubmit={handleCancel}>
                            <p className="text-left">Are you sure you want to cancel your request for <span className='font-bold italic'>bedspace allocation</span> for this patient?</p>

                            <div className='mt-4'>
                                <button
                                    type='submit'
                                    className="inline-block w-[175px] px-6 py-2.5 bg-dark-yellow text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                >Cancel Request</button>
                            </div>
                        </form>
                    )}

                    {modals.showDoctorDischargePatientAdmissionModal && (
                        <form onSubmit={handleDischarge}>
                            <p className="text-left">Are you sure you want to <span className='font-bold italic'>discharge</span> this patient?</p>

                            <div className='mt-4'>
                                <button
                                    type='submit'
                                    className="inline-block w-[175px] px-6 py-2.5 bg-dark-yellow text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                >Discharge</button>
                            </div>
                        </form>
                    )}

                </div>
            </div>
        </div>
    )
}
export default DoctorPatientAdmissionModal
