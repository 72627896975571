/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CgPill } from 'react-icons/cg'
import { AiFillEye } from 'react-icons/ai'
import { FaPencilAlt, FaTrash } from 'react-icons/fa'

import { useStateContext } from '../../state/context'
import { setCurrentDrug } from '../../state/actions/actions'
import Pagination from '../utils/Pagination'


const PharmacistDrugsTable = ({ data, showList, setShowList, title, pages, currentPage, handlePagination }) => {
    const dispatch = useDispatch()

    const { appState, setAppState, modals, setModals } = useStateContext()

    const [currentData, setCurrentData] = useState([])
    const [filterState, setFilterState] = useState({ search: '', drugs: [] })

    const handleFilter = () => {
        const filtered = data?.filter(drug => {
            return drug.name.toLowerCase().includes(filterState.search.toLowerCase())
                || drug.drug_category.category.toLowerCase().includes(filterState.search.toLowerCase())
        })

        setFilterState({ ...filterState, drugs: filtered })

        if (filterState.search === '') {
            setFilterState({ ...filterState, drugs: data })
        }
    }

    useEffect(() => {
        setFilterState({ ...filterState, drugs: data })
    }, [data])

    useEffect(() => {
        handleFilter()
    }, [filterState.search])

    return (
        <div className="flex justify-between items-center w-full">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="py-2">
                    <div
                        className="flex justify-start items-center space-x-4 rounded-t-lg"
                    // onClick={() => setShowList(!showList)}
                    >
                        {/* <h4 className="text-dark-blue text-[14px] font-bold">
                            {title}
                        </h4>
                        <CgPill className="text-dark-blue" size={20} /> */}
                        <input
                            type="text"
                            name="search"
                            // disabled
                            placeholder='Search'
                            className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                            onChange={(e) => setFilterState({ ...filterState, [e.target.name]: e.target.value })}
                        />
                    </div>
                    {showList && (
                        <table className="border rounded w-full cursor-default">
                            <thead className="bg-gray-100">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] py-4 text-left"
                                    >
                                        Drug
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Category
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] py-4 text-left"
                                    >
                                        Bin Card Alert
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Locked
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        Manage
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentData?.map((item, index) => (
                                    <tr key={`${item.id}`}>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.name}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.drug_category.category}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap w-[150px] pr-4">
                                            <p className={`${item.quantity <= item.alert_quantity ? 'bg-gradient-to-r from-yellow-400 to-orange-300' : 'bg-gradient-to-r from-sky-300 to-teal-700'} flex justify-center px-10 py-[5px] font-bold text-white`}>{item.quantity}</p>
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.locked ? 'True' : 'False'}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            <button
                                                type="button"
                                                className="inline-block px-6 py-2 bg-purple-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                onClick={() => {
                                                    dispatch(setCurrentDrug({ data: item }))
                                                    // dispatch(getDrugBinCards({ id: item.pkid }))
                                                    setAppState({ ...appState, showDrugInventory: !appState.showDrugInventory })
                                                }}
                                            >
                                                Inventory
                                            </button>
                                        </td>
                                        <td className=" flex text-2xl space-x-4 text-gray-900 font-semibold py-2 whitespace-nowrap">
                                            <AiFillEye
                                                size={20}
                                                className='text-gray-600 cursor-pointer'
                                                onClick={() => {
                                                    dispatch(setCurrentDrug({ data: item }))
                                                    setModals({ ...modals, showPharmacistViewDrugModal: !modals.showPharmacistViewDrugModal })
                                                }}
                                            />
                                            <FaPencilAlt
                                                size={20}
                                                className='text-green-600 cursor-pointer'
                                                onClick={() => {
                                                    dispatch(setCurrentDrug({ data: item }))
                                                    setModals({ ...modals, showPharmacistUpdateDrugModal: !modals.showPharmacistUpdateDrugModal })
                                                }}
                                            />
                                            <FaTrash
                                                onClick={() => {
                                                    dispatch(setCurrentDrug({ data: item }))
                                                    setModals({ ...modals, showPharmacistDeleteDrugModal: !modals.showPharmacistDeleteDrugModal })
                                                }}
                                                className="text-orange-400 cursor-pointer"
                                                size={20}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    <Pagination
                        data={filterState.drugs}
                        currentData={currentData}
                        setCurrentData={setCurrentData}
                    />
                </div>
            </div>
        </div >
    )
}

export default PharmacistDrugsTable