import React, { useEffect, useState } from 'react'
import { useStateContext } from '../../../state/context'
import { FaTimesCircle } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { addABirthRecord } from '../../../state/actions/nurse.actions'



const NurseViewBirthRecordModal = () => {
    const { modals, setModals } = useStateContext()
    const { set_birth_and_death_record } = useSelector((state) => state.root)



    return (
        <div>
            {modals.showNurseViewBirthRecordModal && (
                <div>
                    <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                        <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                            <div className='flex justify-between items-center'>

                                <>
                                    <h4 className='text-dark-green text-[20px] font-bold'>
                                        Birth Record
                                    </h4>
                                    <FaTimesCircle
                                        className='text-dark-yellow cursor-pointer'
                                        onClick={() => (
                                            setModals({ ...modals, showNurseViewBirthRecordModal: !modals.showNurseViewBirthRecordModal })
                                        )}
                                        size={20}
                                    />
                                </>

                            </div>
                            <form

                            >
                                <div className='flex'>

                                    <input
                                        type='text'
                                        name='patient_number'
                                        placeholder='Enter patient Number'
                                        disabled
                                        value={set_birth_and_death_record?.patient_number}
                                        className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
                                    // onChange={(e) =>
                                    // handleChange(e, e.target.name)
                                    // }
                                    />



                                </div>


                                <div className='flex justify-between-items-center space-x-10'>
                                    <div className='space-y-5 w-full'>
                                        <div>
                                            <p className='text-sm font-semibold mt-1'>First name</p>
                                            <input
                                                type='text'
                                                name='first_name'
                                                placeholder='First name'
                                                disabled
                                                value={set_birth_and_death_record?.first_name}


                                                className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'

                                            />
                                        </div>

                                        <div>
                                            <p className='text-sm font-semibold'>Last name</p>
                                            <input
                                                type='text'
                                                name='last_name'
                                                placeholder='Last name'
                                                value={set_birth_and_death_record?.last_name}
                                                disabled

                                                className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'

                                            />
                                        </div>

                                        <div>
                                            <p className='text-sm font-semibold'>Date of Birth</p>
                                            <input
                                                type='date'
                                                disabled
                                                name='date_of_birth'
                                                value={set_birth_and_death_record?.date}
                                                className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'

                                            />
                                        </div>

                                        <div>
                                            <p className='text-sm font-semibold'>Time of Birth</p>
                                            <input
                                                type='time'
                                                name='time'
                                                placeholder='Time'
                                                disabled
                                                value={set_birth_and_death_record?.time}
                                                className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'

                                            />
                                        </div>
                                    </div>

                                    <div className='space-y-5 w-full'>
                                        <div>
                                            <p className='text-sm font-semibold mt-1'>Gender</p>
                                            <input
                                                type='text'
                                                name='gender'
                                                placeholder='Gender'
                                                value={set_birth_and_death_record?.gender}
                                                disabled
                                                className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'


                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Phone number</p>
                                            <input
                                                type='number'
                                                name='phone_number'
                                                disabled
                                                placeholder='Phone number'
                                                value={set_birth_and_death_record?.phone_number}
                                                className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'

                                            />
                                        </div>

                                        <div>
                                            <p className='text-sm font-semibold'>Address</p>
                                            <input
                                                type='text'
                                                name='address'
                                                placeholder='Address'
                                                value={set_birth_and_death_record?.address}
                                                className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
                                                disabled

                                            />
                                        </div>

                                        <div>
                                            <p className='text-sm font-semibold'>Guardian name</p>

                                            <input
                                                type='text'
                                                name='guardian_name'
                                                disabled
                                                placeholder='Enter Guardian name'
                                                value={set_birth_and_death_record?.guardian_name}
                                                className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'


                                            />
                                        </div>
                                    </div>
                                </div>


                            </form>
                        </div>

                    </div>
                </div>
            )}

            {modals.showNurseViewDeathRecordModal && (
                <div>
                    <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                        <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                            <div className='flex justify-between items-center'>

                                <>
                                    <h4 className='text-dark-green text-[20px] font-bold'>
                                        Death Record
                                    </h4>
                                    <FaTimesCircle
                                        className='text-dark-yellow cursor-pointer'
                                        onClick={() => (
                                            setModals({
                                                ...modals,
                                                showNurseViewDeathRecordModal:
                                                    !modals.showNurseViewDeathRecordModal,
                                            })
                                        )

                                        }
                                        size={20}
                                    />
                                </>

                            </div>
                            <form

                            >
                                <div className='flex'>

                                    <input
                                        type='text'
                                        name='patient_number'
                                        placeholder='Enter patient Number'
                                        disabled
                                        value={set_birth_and_death_record?.patient_number}
                                        className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
                                    // onChange={(e) =>
                                    // handleChange(e, e.target.name)
                                    // }
                                    />



                                </div>


                                <div className='flex justify-between-items-center space-x-10'>
                                    <div className='space-y-5 w-full'>
                                        <div>
                                            <p className='text-sm font-semibold mt-1'>First name</p>
                                            <input
                                                type='text'
                                                name='first_name'
                                                placeholder='First name'
                                                disabled
                                                value={set_birth_and_death_record?.first_name}


                                                className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'

                                            />
                                        </div>

                                        <div>
                                            <p className='text-sm font-semibold'>Last name</p>
                                            <input
                                                type='text'
                                                name='last_name'
                                                placeholder='Last name'
                                                value={set_birth_and_death_record?.last_name}
                                                disabled

                                                className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'

                                            />
                                        </div>

                                        <div>
                                            <p className='text-sm font-semibold'>Date of Birth</p>
                                            <input
                                                type='date'
                                                disabled
                                                name='date_of_birth'
                                                value={set_birth_and_death_record?.date}
                                                className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'

                                            />
                                        </div>

                                        <div>
                                            <p className='text-sm font-semibold'>Time of Birth</p>
                                            <input
                                                type='time'
                                                name='time'
                                                placeholder='Time'
                                                disabled
                                                value={set_birth_and_death_record?.time}
                                                className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'

                                            />
                                        </div>
                                    </div>

                                    <div className='space-y-5 w-full'>
                                        <div>
                                            <p className='text-sm font-semibold mt-1'>Gender</p>
                                            <input
                                                type='text'
                                                name='gender'
                                                placeholder='Gender'
                                                value={set_birth_and_death_record?.gender}
                                                disabled
                                                className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'


                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Phone number</p>
                                            <input
                                                type='number'
                                                name='phone_number'
                                                disabled
                                                placeholder='Phone number'
                                                value={set_birth_and_death_record?.phone_number}
                                                className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'

                                            />
                                        </div>

                                        <div>
                                            <p className='text-sm font-semibold'>Address</p>
                                            <input
                                                type='text'
                                                name='address'
                                                placeholder='Address'
                                                value={set_birth_and_death_record?.address}
                                                className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
                                                disabled

                                            />
                                        </div>

                                        <div>
                                            <p className='text-sm font-semibold'>Guardian name</p>

                                            <input
                                                type='text'
                                                name='guardian_name'
                                                disabled
                                                placeholder='Enter Guardian name'
                                                value={set_birth_and_death_record?.guardian_name}
                                                className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'


                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex'>

                                    <input
                                        type='text'
                                        name='patient_number'
                                        placeholder='Enter patient Number'
                                        disabled
                                        value={set_birth_and_death_record?.cause_of_death}
                                        className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
                                    // onChange={(e) =>
                                    // handleChange(e, e.target.name)
                                    // }
                                    />



                                </div>

                            </form>
                        </div>

                    </div>
                </div>

            )}


        </div>
    )
}

export default NurseViewBirthRecordModal