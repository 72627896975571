import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    getOpds, getPatientDiagnosis, getPatientDiagnosisPrescriptions, updateOpdInjection,
    addPerformanceData, getPatientPerformanceData, nurseaddPatient, getDoctors, getPatientsToBeAdmitted, getWards, getWardBedspaces, admitPatient, getAdmittedPatient,
    getPatients, bookPatientAntenatal, getBookedAntenatals, addPatientMedicalRecord, getPatientMedicalRecord, addPatientPregnancyRecord, getPatientPregnancyRecord,
    addPatientLabourRecord, getPatientLabourRecord, addPatientInvestigationRecord, getPatientInvestigationRecord, addPatientVisitRecord,
    getPatientVisitRecord, deletePatientLabourRecord, deletePatientInvestigationRecord, deletePatientVisitRecord, bookPatientForFamilyPlanning, getFamilyPlanningBookings,
    addPatientFamilyFollowUpRecord, getPatientFamilyFollowUpRecords, addPatientDailyReport, getPatientDailyReports, addPatientTreatmentReport,
    getPatientTreamentReports, bookPatientForImmunization, getPatientForImmunization, addVaccinationImmunizationForPatient, 
    getPatientVaccinationRecords, getProfiles, addBirthRecord, getBirthRecords, getDeathRecords, addDeathRecord, deletePatientFamilyFollowUpRecord, generateAdmitPatient
} from '../routes/nurse.routes'


export const getAllOpds = createAsyncThunk(
    'nurse/getAllOpds',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getOpds(token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllPatientDiagnosis = createAsyncThunk(
    'nurse/getPatientDiagnosis',
    async ({ patient_id }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getPatientDiagnosis(patient_id, token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)
export const getAllPatientDiagnosisPrescriptions = createAsyncThunk(
    'nurse/getPatientDiagnosisPrescriptions',
    async ({ id }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getPatientDiagnosisPrescriptions(id, token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const updatePatientOpdInjection = createAsyncThunk(
    'nurse/updatePatientOpdInjection',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access


            const { data } = await updateOpdInjection(formData, token)
            toast.success("OPD administered")
            setModals({ ...modals, showNurseAdministerOPDModal: !modals.showNurseAdministerOPDModal })

            return data
        } catch (error) {

            toast.warning("An error occurred while administering opd")
            return rejectWithValue(error.response.data)
        }
    }

)

export const addPatientPerformanceData = createAsyncThunk(
    'nurse/addPatientPerformanceData',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access


            const { data } = await addPerformanceData(formData, token)
            toast.success("Performance data added successfully")
            setModals({ ...modals, showNurseAddPerformanceDataModal: !modals.showNurseAddPerformanceDataModal })

            return data
        } catch (error) {

            toast.warning("An error occurred while adding patient performance data ")
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllPatientPerformanceData = createAsyncThunk(
    'nurse/getAllPatientPerformance',
    async ({ patient_id }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getPatientPerformanceData(patient_id, token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const nurseaddAPatient = createAsyncThunk(
    'nurse/nurseaddPatient',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access


            const { data } = await nurseaddPatient(formData, token)
            toast.success("Patient added successfully")
            setModals({ ...modals, showNurseAddPatientModal: !modals.showNurseAddPatientModal })

            return data
        } catch (error) {

            toast.warning("An error occurred while adding patient")
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllDoctors = createAsyncThunk(
    'nurse/getDoctors',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getDoctors(token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllPatientsToBeAdmitted = createAsyncThunk(
    'nurse/getPatientsToBeAdmitted',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getPatientsToBeAdmitted(token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllWards = createAsyncThunk(
    'nurse/getWards',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getWards(token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)
export const getAllWardBedspaces = createAsyncThunk(
    'nurse/getWardBedspaces',
    async ({ ward_id }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getWardBedspaces(ward_id, token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const admitAPatient = createAsyncThunk(
    'nurse/admitPatient',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access


            const { data } = await generateAdmitPatient(formData, token)
            toast.success("Patient admitted successfully")
            setModals({ ...modals, showAdmitPatientModal: !modals.showAdmitPatientModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occurred while admitting patient")
            return rejectWithValue(null)
        }
    }

)

export const getAllAdmittedPatient = createAsyncThunk(
    'nurse/getAdmittedPatient',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getAdmittedPatient(token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllPatients = createAsyncThunk(
    'nurse/getPatients',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getPatients(token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)


export const bookAPatientAntenatal = createAsyncThunk(
    'nurse/bookPatientAntenatal',
    async ({ id, formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access


            const { data } = await bookPatientAntenatal(id, formData, token)
            toast.success("Antenatal appointment booked successfully")
            setModals({ ...modals, showBookAntenatalModal: !modals.showBookAntenatalModal })

            return data
        } catch (error) {

            if (error.response.status == 403) {
                toast.warning("Patient is already booked for antenatal")
            } else {
                toast.warning("An error occurred while booking antenatal appointment patient")
            }
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllBookedAntenatals = createAsyncThunk(
    'nurse/getBookedAntenatals',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getBookedAntenatals(token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)


export const addAPatientMedicalRecord = createAsyncThunk(
    'nurse/addPatientMedicalRecord',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access


            const { data } = await addPatientMedicalRecord(formData, token)
            toast.success("Record added successfully")
            setModals({ ...modals, showAddPatientMedicalRecordModal: !modals.showAddPatientMedicalRecordModal })

            return data
        } catch (error) {

            toast.warning("An error occurred while adding patient record")
            return rejectWithValue(error.response.data)
        }
    }

)
export const getAPatientMedicalRecord = createAsyncThunk(
    'nurse/getPatientMedicalRecord',
    async ({ id }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getPatientMedicalRecord(id, token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const addAPatientPregnancyRecord = createAsyncThunk(
    'nurse/addPatientPregnancyRecord',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access


            const { data } = await addPatientPregnancyRecord(formData, token)
            toast.success("Pregancy record added successfully")
            setModals({ ...modals, showAddPatientPregnancyRecordModal: !modals.showAddPatientPregnancyRecordModal })

            return data
        } catch (error) {

            toast.warning("An error occurred while adding pregnancy record")
            return rejectWithValue(error.response.data)
        }
    }

)

export const getAPatientPregnancyRecord = createAsyncThunk(
    'nurse/getPatientPregnancyRecord',
    async ({ id }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getPatientPregnancyRecord(id, token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)
export const addAPatientLabourRecord = createAsyncThunk(
    'nurse/addPatientLabourRecord',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access


            const { data } = await addPatientLabourRecord(formData, token)
            toast.success("Labour data added successfully")
            setModals({ ...modals, showAddPatientLabourSummaryRecordModal: !modals.showAddPatientLabourSummaryRecordModal })

            return data
        } catch (error) {

            toast.warning("An error occurred while adding patient labour data ")
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAPatientLabourRecord = createAsyncThunk(
    'nurse/getPatientLabourRecord',
    async ({ id }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getPatientLabourRecord(id, token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const addAPatientInvestigationRecord = createAsyncThunk(
    'nurse/addPatientInvestigationRecord',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access


            const { data } = await addPatientInvestigationRecord(formData, token)
            toast.success("Investigation added successfully")
            setModals({ ...modals, showAddPatientInvestigationRecordModal: !modals.showAddPatientInvestigationRecordModal })

            return data
        } catch (error) {

            toast.warning("An error occurred while adding patient investigation ")
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAPatientInvestigationRecord = createAsyncThunk(
    'nurse/getPatientInvestigationRecord',
    async ({ id }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getPatientInvestigationRecord(id, token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const addAPatientVisitRecord = createAsyncThunk(
    'nurse/addPatientVisitRecord',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access


            const { data } = await addPatientVisitRecord(formData, token)
            toast.success("Visit record added successfully")
            setModals({ ...modals, showAddPatientVisitRecordModal: !modals.showAddPatientVisitRecordModal })

            return data
        } catch (error) {

            toast.warning("An error occurred while adding patient visit record ")
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAPatientVisitRecord = createAsyncThunk(
    'nurse/getPatientVisitRecord',
    async ({ id }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getPatientVisitRecord(id, token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const deleteAPatientLabourRecord = createAsyncThunk(
    'nurse/deletePatientLabourRecord',
    async ({ id, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access


            const { data } = await deletePatientLabourRecord(id, token)
            toast.success("Labour data deleted successfully")
            setModals({ ...modals, showDeleteLabourSummaryModal: !modals.showDeleteLabourSummaryModal })

            return data
        } catch (error) {

            toast.warning("An error occurred while deleting patient labour data ")
            return rejectWithValue(error.response.data)
        }
    }
)

export const deleteAPatientInvestigationRecord = createAsyncThunk(
    'nurse/deletePatientInvestigationRecord',
    async ({ id, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access


            const { data } = await deletePatientInvestigationRecord(id, token)
            toast.success("Investigation data deleted successfully")
            setModals({ ...modals, showDeleteInvestigationRecordModal: !modals.showDeleteInvestigationRecordModal })

            return data
        } catch (error) {

            toast.warning("An error occurred while deleting patient investigation data ")
            return rejectWithValue(error.response.data)
        }
    }
)

export const deleteAPatientVisitRecord = createAsyncThunk(
    'nurse/deletePatientVisitRecord',
    async ({ id, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access


            const { data } = await deletePatientVisitRecord(id, token)
            toast.success("Record deleted successfully")
            setModals({ ...modals, showDeleteVisitRecordModal: !modals.showDeleteVisitRecordModal })

            return data
        } catch (error) {

            toast.warning("An error occurred while deleting patient record ")
            return rejectWithValue(error.response.data)
        }
    }
)

export const bookAPatientForFamilyPlanning = createAsyncThunk(
    'nurse/bookPatientForFamilyPlanning',
    async ({ id, formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access


            const { data } = await bookPatientForFamilyPlanning(id, formData, token)
            toast.success("Patient booked successfully")
            setModals({ ...modals, showBookPatientForFamilyPlanningModal: !modals.showBookPatientForFamilyPlanningModal })

            return data
        } catch (error) {

            if (error.response.status == 403) {
                toast.warning("Patient is already booked")
            } else {
                toast.warning("An error occurred while booking a patient")
            }
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllFamilyPlanningBookings = createAsyncThunk(
    'nurse/getFamilyPlanningBookings',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getFamilyPlanningBookings(token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const addAPatientFamilyFollowUpRecord = createAsyncThunk(
    'nurse/addAPatientFamilyFollowUpRecord',
    async ({ id, formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access


            const { data } = await addPatientFamilyFollowUpRecord(id, formData, token)
            toast.success("Follow up record added successfully")
            setModals({ ...modals, showAddFamilyPlanningRecords: !modals.showAddFamilyPlanningRecords })

            return data
        } catch (error) {

            toast.warning("An error occurred while adding patient's record")
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAPatientFamilyFollowUpRecords = createAsyncThunk(
    'nurse/getPatientFamilyFollowUpRecords',
    async ({ id }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getPatientFamilyFollowUpRecords(id, token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)


export const deletePatientPerformanceData = createAsyncThunk(
    'nurse/deletePatientPerformanceData',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)

export const addAPatientDailyReport = createAsyncThunk(
    'nurse/addPatientDailyReport',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access


            const { data } = await addPatientDailyReport(formData, token)
            toast.success("Report added successfully")
            setModals({ ...modals, showAddAdmitedPatientDailyReportModal: !modals.showAddAdmitedPatientDailyReportModal })

            return data
        } catch (error) {

            toast.warning("An error occurred while adding patient's report")
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllPatientDailyReports = createAsyncThunk(
    'nurse/getAllPatientDailyReports',
    async ({ id }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getPatientDailyReports(id, token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const addAPatientTreatmentReport = createAsyncThunk(
    'nurse/addAPatientTreatmentReport',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access


            const { data } = await addPatientTreatmentReport(formData, token)
            toast.success("Treatment report added successfully")
            setModals({ ...modals, showAddAdmitedPatientTreatmentReportModal: !modals.showAddAdmitedPatientTreatmentReportModal })

            return data
        } catch (error) {

            toast.warning("An error occurred while adding patient's  treatment report")
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllPatientTreamentReports = createAsyncThunk(
    'nurse/getPatientTreamentReports',
    async ({ id }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getPatientTreamentReports(id, token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const deleteFamilyPlanningFollowUpRecord = createAsyncThunk(
    'nurse/deleteFamilyPlanningFollowUpRecord',
    async ({ data }, { rejectWithValue }) => {
        try {

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const bookAPatientForImmunization = createAsyncThunk(
    'nurse/bookPatientForImmunization',
    async ({ id, formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access


            const { data } = await bookPatientForImmunization(id, formData, token)
            toast.success("Patient booked successfully")
            setModals({ ...modals, showAddChildForImmunizationModal: !modals.showAddChildForImmunizationModal })

            return data
        } catch (error) {
            if (error.response.status == 403) {
                toast.warning("Patient already booked for immunization")
            }
            else {
                toast.warning("An error occurred while booking patient for immunization")
            }
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllPatientForImmunization = createAsyncThunk(
    'nurse/getPatientForImmunization',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getPatientForImmunization(token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)


export const createVaccinationImmunizationForPatient = createAsyncThunk(
    'nurse/addVaccinationImmunizationForPatient',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access


            const { data } = await addVaccinationImmunizationForPatient(formData, token)
            toast.success("Immunization  added successfully")
            setModals({ ...modals, showAddVaccinationRecordModal: !modals.showAddVaccinationRecordModal })

            return data
        } catch (error) {
            if (error.response.status == 403) {
                toast.warning("This Immunization vaccine already exist for this patient")
            }
            else {
                toast.warning("An error occurred while adding an Immunization for this patient")
            }

            return rejectWithValue(error.response.data)
        }
    }
)

export const getAPatientVaccinationRecords = createAsyncThunk(
    'nurse/getAPatientTreamentReports',
    async ({ id }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getPatientVaccinationRecords(id, token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const removePatientVaccinationRecords = createAsyncThunk(
    'nurse/removePatientVaccinationRecords',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllProfiles = createAsyncThunk(
    'nurse/getAllProfiles',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getProfiles(token)

            return data
        } catch (error) {
            console.log(error.response);
            return rejectWithValue(error.response.data)
        }
    }
)

export const addABirthRecord = createAsyncThunk(
    'nurse/addABirthRecord',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access


            const { data } = await addBirthRecord(formData, token)
            toast.success("Birth record added successfully")
            setModals({ ...modals, showAddBirthRecordModal: !modals.showAddBirthRecordModal })

            return data
        } catch (error) {

            
            if (error.response.status == 403) {
                toast.warning("Birth record already exist for this patient")
            }
            else {
                toast.warning("An error occurred while adding birth record")
            }

            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllBirthRecords = createAsyncThunk(
    'nurse/getBirthRecords',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getBirthRecords(token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)



export const getAllDeathRecords = createAsyncThunk(
    'nurse/getAllDeathRecord',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getDeathRecords(token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const addADeathRecord = createAsyncThunk(
    'nurse/addADeathRecord',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access


            const { data } = await addDeathRecord(formData, token)
            toast.success("Death record added successfully")
            setModals({ ...modals, showNursesAddDeathRecordModal: !modals.showNursesAddDeathRecordModal })

            return data
        } catch (error) {

            console.log(error.response)
            if (error.response.status == 403) {
                toast.warning("Death record already exist for this patient")
            }
            else {
                toast.warning("An error occurred while adding death record")
            }

            return rejectWithValue(error.response.data)
        }
    }
)



export const deleteAPatientFamilyFollowUpRecord = createAsyncThunk(
    'nurse/deleteFamilyPlanningFollowUpRecord',
    async ({ id, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await deletePatientFamilyFollowUpRecord(id, token)
            toast.success("Record deleted successfully")
            setModals({ ...modals, showDeletePatientFollowUpRecordModal: !modals.showDeletePatientFollowUpRecordModal })


            return data
        } catch (error) {
            toast.warning("Error occured while trying to delete this record")


            return rejectWithValue(error.response.data)
        }
    }
)
