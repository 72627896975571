/* eslint-disable no-unreachable */
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    fetchDrugBinCards, fetchDrugCategories, fetchDrugPrices, fetchDrugs, fetchPaidPrescriptions, fetchPharmacistProfiles, fetchPrescriptionInvoices, fetchPrescriptions, fetchSupplyPointBinCards, fetchSupplyPointInventories, fetchSupplyPointInventoryBinCards, fetchSupplyPoints, fetchSupplyPointStaff, generateDrug, generateDrugBinCard, generateDrugCategory,
    generateDrugDispense, generateInventoryForSupplyPoint, generatePrescriptionsInvoice, generateSupplyPoint, issueDrugOut, patchDrug, patchDrugCategory, patchDrugInventory, patchInventoryForSupplyPoint, patchPharmacistSupplyPoint, patchSupplyPoint, removeDrug, removeDrugCategory, removeSupplyPoint, stockDrugToSupplyPoint, fetchClinicalData, generateMultiplePrescriptionsInvoice, fetchMultiplePaidPrescriptions, generateMultipleDrugDispense, fetchDashboardData
} from '../routes/pharmacist.routes'


export const getDashboardData = createAsyncThunk(
    'pharmacist/getDashboardData',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchDashboardData(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)


export const getDrugCategories = createAsyncThunk(
    'pharmacist/getDrugCategories',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchDrugCategories(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)

export const createDrugCategory = createAsyncThunk(
    'pharmacist/createDrugCategory',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateDrugCategory(formData, token)

            toast.success('Drug category created successfully.')
            setModals({ ...modals, showPharmacistAddDrugCategoryModal: !modals.showPharmacistAddDrugCategoryModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occured while creating drug category.')
            return rejectWithValue(null)
        }
    }
)

export const updateDrugCategory = createAsyncThunk(
    'pharmacist/updateDrugCategory',
    async ({ formData, toast, setModals, modals, dispatch, setDrugCategory }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchDrugCategory(formData, token)

            toast.success('Drug category updated successfully.')
            setModals({ ...modals, showPharmacistUpdateDrugCategoryModal: !modals.showPharmacistUpdateDrugCategoryModal })
            dispatch(setDrugCategory({ data: null }))

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occured while updating drug category.')
            return rejectWithValue(null)
        }
    }
)

export const deleteDrugCategory = createAsyncThunk(
    'pharmacist/deleteDrugCategory',
    async ({ id, toast, setModals, modals, dispatch, setDrugCategory }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await removeDrugCategory(id, token)

            toast.success('Drug category deleted successfully.')
            setModals({ ...modals, showPharmacistUpdateDeleteCategoryModal: !modals.showPharmacistUpdateDeleteCategoryModal })
            dispatch(setDrugCategory({ data: null }))

            return id
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occured while deleting drug category.')
            return rejectWithValue(null)
        }
    }
)

export const getSupplyPoints = createAsyncThunk(
    'pharmacist/getSupplyPoints',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchSupplyPoints(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)

export const createSupplyPoint = createAsyncThunk(
    'pharmacist/createSupplyPoint',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateSupplyPoint(formData, token)

            toast.success('Supply point created successfully.')
            setModals({ ...modals, showPharmacistAddSupplyPointModal: !modals.showPharmacistAddSupplyPointModal })

            return data
        } catch (error) {
            console.log(error.response)

            if (error.response.data?.name) {
                toast.warning(error.response?.data?.name[0])
            } else {
                toast.warning('An error occured while creating supply point.')
            }

            return rejectWithValue(null)
        }
    }
)

export const updateSupplyPoint = createAsyncThunk(
    'pharmacist/updateSupplyPoint',
    async ({ formData, toast, setModals, modals, dispatch, setSupplyPoint }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchSupplyPoint(formData, token)

            toast.success('Supply point updated successfully.')
            setModals({ ...modals, showPharmacistUpdateSupplyPointModal: !modals.showPharmacistUpdateSupplyPointModal })
            dispatch(setSupplyPoint({ data: null }))

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occured while updating supply point.')
            return rejectWithValue(null)
        }
    }
)

export const deleteSupplyPoint = createAsyncThunk(
    'pharmacist/deleteSupplyPoint',
    async ({ id, toast, setModals, modals, dispatch, setSupplyPoint }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await removeSupplyPoint(id, token)

            toast.success('Supply point deleted successfully.')
            setModals({ ...modals, showPharmacistDeleteSupplyPointModal: !modals.showPharmacistDeleteSupplyPointModal })
            dispatch(setSupplyPoint({ data: null }))

            return id
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occured while deleting supply point.')
            return rejectWithValue(null)
        }
    }
)

export const getDrugs = createAsyncThunk(
    'pharmacist/getDrugs',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchDrugs(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)

export const createDrug = createAsyncThunk(
    'pharmacist/creatDrug',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateDrug(formData, token)

            toast.success('Medicine added successfully.')
            setModals({ ...modals, showPharmacistAddDrugModal: !modals.showPharmacistAddDrugModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occured while adding medicine.')
            return rejectWithValue(null)
        }
    }
)

export const updateDrug = createAsyncThunk(
    'pharmacist/updateDrug',
    async ({ formData, toast, setModals, modals, dispatch, setCurrentDrug }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchDrug(formData, token)

            toast.success('Medicine updated successfully.')
            setModals({ ...modals, showPharmacistUpdateDrugModal: !modals.showPharmacistUpdateDrugModal })
            dispatch(setCurrentDrug({ data: null }))

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occured while updating medicine.')
            return rejectWithValue(null)
        }
    }
)

export const deleteDrug = createAsyncThunk(
    'pharmacist/deleteDrug',
    async ({ id, toast, setModals, modals, dispatch, setCurrentDrug }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await removeDrug(id, token)

            toast.success('Drug deleted successfully.')
            setModals({ ...modals, showPharmacistDeleteDrugModal: !modals.showPharmacistDeleteDrugModal })
            dispatch(setCurrentDrug({ data: null }))

            return id
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occured while deleting drug.')
            return rejectWithValue(null)
        }
    }
)


export const getDrugBinCards = createAsyncThunk(
    'pharmacist/getDrugBinCards',
    async ({ id }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchDrugBinCards(id, token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)

export const createDrugBinCard = createAsyncThunk(
    'pharmacist/createDrugBinCard',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateDrugBinCard(formData, token)

            toast.success('Drug bin card added successfully.')
            setModals({ ...modals, showPharmacistBinCardModal: !modals.showPharmacistBinCardModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occured while adding bin card.')
            return rejectWithValue(null)
        }
    }
)



export const dispenseDrugToSupplyPoint = createAsyncThunk(
    'pharmacist/dispenseDrugToSupplyPoint',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            console.log(formData)
            const { data } = await stockDrugToSupplyPoint(formData, token)
            const req = await patchDrugInventory(formData, token)

            console.log(req.data)
            toast.success('Drug dispensed tp supply point successfully.')
            setModals({ ...modals, showPharmacistDrugSupplyPointModal: !modals.showPharmacistDrugSupplyPointModal })

            return { data, inventory: req.data }
        } catch (error) {
            console.log(error)
            toast.warning('An error occured while dispensing drug to supply point.')
            return rejectWithValue(null)
        }
    }
)



export const resetDrugBinCards = createAsyncThunk(
    'pharmacist/resetDrugBinCards',
    async (_, { rejectWithValue }) => {
        try {
            return null
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
)

export const getSupplyPointInventory = createAsyncThunk(
    'pharmacist/getSupplyPointInventory',
    async ({ supplypoint_name }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchSupplyPointInventories(supplypoint_name, token)

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
)

export const resetSupplyPointInventory = createAsyncThunk(
    'pharmacist/resetSupplyPointInventory',
    async (_, { rejectWithValue }) => {
        try {
            return null
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
)

export const getSupplyPointDrugBinCards = createAsyncThunk(
    'pharmacist/getSupplyPointDrugBinCards',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            console.log(params)
            const { data } = await fetchSupplyPointInventoryBinCards(params, token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(error)
        }
    }
)


export const resetSupplyPointDrugBinCards = createAsyncThunk(
    'pharmacist/resetSupplyPointDrugBinCards',
    async (_, { rejectWithValue }) => {
        try {
            return null
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
)


export const lendDrugToSupplyPoint = createAsyncThunk(
    'pharmacist/lendDrugToSupplyPoint',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await generateInventoryForSupplyPoint(formData, token)
            const { data } = await patchInventoryForSupplyPoint(formData, token)

            toast.success('Dispensed to supply point successfully.')
            setModals({ ...modals, showPharmacistLendRefundDrugModal: !modals.showPharmacistLendRefundDrugModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occured while dispensing drug to supply point.')
            return rejectWithValue(error)
        }
    }
)


export const issueOutDrug = createAsyncThunk(
    'pharmacist/issueOutDrug',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await issueDrugOut(formData, token)

            toast.success('Dispensed drug successfully.')
            setModals({ ...modals, showPharmacistIssueOutDrugModal: !modals.showPharmacistIssueOutDrugModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occured while issuing out drug.')
            return rejectWithValue(error)
        }
    }
)


export const getSupplyPointBinCards = createAsyncThunk(
    'pharmacist/getSupplyPointBinCards',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchSupplyPointBinCards(params, token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(error)
        }
    }
)


export const resetSupplyPointBinCards = createAsyncThunk(
    'pharmacist/resetSupplyPointBinCards',
    async (_, { rejectWithValue }) => {
        try {
            return null
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(error)
        }
    }
)


export const getPrescriptions = createAsyncThunk(
    'pharmacist/getPrescriptions',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPrescriptions(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)


export const getDrugPrices = createAsyncThunk(
    'pharmacist/getDrugPrices',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchDrugPrices(params, token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)


export const createPrescriptionsInvoice = createAsyncThunk(
    'pharmacist/createPrescriptionsInvoice',
    async ({ formData, toast, setModals, modals, dispatch, setCurrentPrescriptions }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generatePrescriptionsInvoice(formData, token)

            toast.success('Invoice generated successfully.')
            setModals({ ...modals, showPharmacistDrugInvoiceModal: !modals.showPharmacistDrugInvoiceModal })
            dispatch(setCurrentPrescriptions({ data: null }))

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occured while generating prescriptions invoice.')
            return rejectWithValue(null)
        }
    }
)


export const createMultiplePrescriptionsInvoice = createAsyncThunk(
    'pharmacist/createMultiplePrescriptionsInvoice',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateMultiplePrescriptionsInvoice(formData, token)

            toast.success('Multiple invoices generated successfully.')
            setModals({ ...modals, showPharmacistGenerateMultipleInvoiceModal: !modals.showPharmacistGenerateMultipleInvoiceModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occured while generating multiple prescriptions invoice.')
            return rejectWithValue(null)
        }
    }
)


export const getPrescriptionsInvoices = createAsyncThunk(
    'pharmacist/getPrescriptionsInvoices',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPrescriptionInvoices(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)


export const getPaidPrescriptions = createAsyncThunk(
    'pharmacist/getPaidPrescriptions',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPaidPrescriptions(params, token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)


export const getMultiplePaidPrescriptions = createAsyncThunk(
    'pharmacist/getMultiplePaidPrescriptions',
    async ({ formData }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchMultiplePaidPrescriptions(formData, token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)


export const resetPaidPrescriptions = createAsyncThunk(
    'pharmacist/resetPaidPrescriptions',
    async (_, { rejectWithValue }) => {
        try {
            return null
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)


export const setCurrentMultiplePrescriptions = createAsyncThunk(
    'pharmacist/setCurrentMultiplePrescriptions',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)



export const setCurrentMultipleInvoices = createAsyncThunk(
    'pharmacist/setCurrentMultipleInvoices',
    async ({ data }, { rejectWithValue }) => {
        try {
            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)


export const getPharmacistProfiles = createAsyncThunk(
    'pharmacist/getPharmacistProfiles',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchPharmacistProfiles(token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)


export const assignPharmacistToSupplyPoint = createAsyncThunk(
    'pharmacist/assignPharmacistToSupplyPoint',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchPharmacistSupplyPoint(formData, token)

            toast.success('Pharmacist assigned to supplypoint successfully.')

            modals.showPharmacistAssignSupplyPointModal && setModals({ ...modals, showPharmacistAssignSupplyPointModal: !modals.showPharmacistAssignSupplyPointModal })
            modals.showPharmacistSupplyPointAssignStaffModal && setModals({ ...modals, showPharmacistSupplyPointAssignStaffModal: !modals.showPharmacistSupplyPointAssignStaffModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occured while assigning pharmacist to supplypoint.')
            return rejectWithValue(null)
        }
    }
)


export const getSupplyPointStaff = createAsyncThunk(
    'pharmacist/getSupplyPointStaff',
    async ({ params }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchSupplyPointStaff(params, token)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)


export const resetSupplyPointStaff = createAsyncThunk(
    'pharmacist/resetSupplyPointStaff',
    async (_, { rejectWithValue }) => {
        try {
            return null
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)

export const dispenseDrug = createAsyncThunk(
    'pharmacist/dispenseDrug',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateDrugDispense(formData, token)

            toast.success('Drug dispensed successfully')
            setModals({ ...modals, showPharmacistDispenseDrugModal: !modals.showPharmacistDispenseDrugModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occured while dispensing drug')
            return rejectWithValue(null)
        }
    }
)

export const multipleDispenseDrug = createAsyncThunk(
    'pharmacist/multipleDispenseDrug',
    async ({ formData, toast, modals, setModals, dispatch, setCurrentMultipleInvoices }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await generateMultipleDrugDispense(formData, token)

            toast.success('Drugs dispensed successfully')
            
            setModals({ ...modals, showPharmacistDispenseMultipleInvoiceModal: !modals.showPharmacistDispenseMultipleInvoiceModal })
            
            dispatch(setCurrentMultipleInvoices({ data: null }))

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occured while dispensing multiple drugs')
            return rejectWithValue(null)
        }
    }
)


export const getClinicalData = createAsyncThunk(
    'pharmacist/getClinicalData',
    async ({ formData, toast, modals, setModals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchClinicalData(formData, token)
            // setModals({ ...modals, showPharmacistDispenseDrugModal: !modals.showPharmacistDispenseDrugModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occured while dispensing drug')
            return rejectWithValue(null)
        }
    }
)