import React, { Fragment } from 'react'
import DoctorAddOtherPrescriptionModal from '../../modals/doctor/DoctorAddOtherPrescriptionModal'
import DoctorAddOtherRadiologyTestModal from '../../modals/doctor/DoctorAddOtherRadiologyTestModal'
import DoctorAddOtherTestModal from '../../modals/doctor/DoctorAddOtherTestModal'
import DoctorAddPrescriptionModal from '../../modals/doctor/DoctorAddPrescriptionModal'
import DoctorAddRadiologyReportModal from '../../modals/doctor/DoctorAddRadiologyReportModal'
import DoctorAddRadiologyTestModal from '../../modals/doctor/DoctorAddRadiologyTestModal'
import DoctorAddTestModal from '../../modals/doctor/DoctorAddTestModal'
import DoctorAddTestReportModal from '../../modals/doctor/DoctorAddTestReportModal'
import DoctorBookAppointmentModal from '../../modals/doctor/DoctorBookAppointmentModal'

import DoctorCreateTeamModal from '../../modals/doctor/DoctorCreateTeamModal'
import DoctorDeleteTeamModal from '../../modals/doctor/DoctorDeleteTeamModal'
import DoctorPatientAdmissionModal from '../../modals/doctor/DoctorPatientAdmissionModal'
import DoctorTransferPatientModal from '../../modals/doctor/DoctorTransferPatientModal'
import DoctorUploadOtherRadiologyReportModal from '../../modals/doctor/DoctorUploadOtherRadiologyReportModal'
import DoctorUploadOtherTestReportModal from '../../modals/doctor/DoctorUploadOtherTestReportModal'
import DoctorUploadPatientFilesModal from '../../modals/doctor/DoctorUploadPatientFilesModal'
import DoctorViewFileModal from '../../modals/doctor/DoctorViewFileModal'
import DoctorViewRadiologyTestModal from '../../modals/doctor/DoctorViewRadiologyTestModal'
import DoctorViewTestModal from '../../modals/doctor/DoctorViewTestModal'


const DoctorFormModals = (props) => {
    return (
        <Fragment>
            {props.type === 'doctor__create__team' && (
                <DoctorCreateTeamModal />
            )}

            {props.type === 'doctor__update__team' && (
                <DoctorCreateTeamModal />
            )}

            {props.type === 'doctor__view__team' && (
                <DoctorCreateTeamModal />
            )}

            {props.type === 'doctor__delete__team' && (
                <DoctorDeleteTeamModal />
            )}

            {props.type === 'doctor__upload__patientfile' && (
                <DoctorUploadPatientFilesModal />
            )}

            {props.type === 'doctor__view__patientfile' && (
                <DoctorViewFileModal />
            )}

            {props.type === 'doctor__transfer__patient' && (
                <DoctorTransferPatientModal />
            )}

            {props.type === 'doctor__add__prescription' && (
                <DoctorAddPrescriptionModal />
            )}

            {props.type === 'doctor__add__otherprescription' && (
                <DoctorAddOtherPrescriptionModal />
            )}

            {props.type === 'doctor__add__test' && (
                <DoctorAddTestModal />
            )}

            {props.type === 'doctor__add__othertest' && (
                <DoctorAddOtherTestModal />
            )}

            {props.type === 'doctor__view__labtest' && (
                <DoctorViewTestModal />
            )}

            {props.type === 'doctor__write__labreport' && (
                <DoctorAddTestReportModal />
            )}

            {props.type === 'doctor__upload__other__labreport' && (
                <DoctorUploadOtherTestReportModal />
            )}

            {props.type === 'doctor__add__radiologytest' && (
                <DoctorAddRadiologyTestModal />
            )}

            {props.type === 'doctor__add__otherradiologytest' && (
                <DoctorAddOtherRadiologyTestModal />
            )}

            {props.type === 'doctor__view__radiologytest' && (
                <DoctorViewRadiologyTestModal />
            )}

            {props.type === 'doctor__write__radiologyreport' && (
                <DoctorAddRadiologyReportModal />
            )}

            {props.type === 'doctor__upload__other__radiologyreport' && (
                <DoctorUploadOtherRadiologyReportModal />
            )}

            {props.type === 'doctor__request__patientadmission' && (
                <DoctorPatientAdmissionModal />
            )}

            {props.type === 'doctor__cancel__patientadmission' && (
                <DoctorPatientAdmissionModal />
            )}

            {props.type === 'doctor__discharge__patient' && (
                <DoctorPatientAdmissionModal />
            )}

            {props.type === 'doctor__book__appointment' && (
                <DoctorBookAppointmentModal />
            )}

            {props.type === 'doctor__update__appointment' && (
                <DoctorBookAppointmentModal />
            )}
        </Fragment>
    )
}

export default DoctorFormModals