import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useStateContext } from '../../../state/context'
import { getAllPatientDailyReports } from '../../../state/actions/nurse.actions'

const AdmittedPatientsDailyReportTable = () => {
    const { set_patient_id, set_manage_admitted_patient } = useSelector((state) => state.root)
    const { all_patient_daily_report } = useSelector((state) => state.nurse)
    const { modals, setModals } = useStateContext()
    const dispatch = useDispatch()
    useEffect(() => {
        const id = set_patient_id
        dispatch(getAllPatientDailyReports({ id }))

    }, [modals.showAddAdmitedPatientDailyReportModal])


    return (
        <div>
            <div className='flex justify-end  space-x-2 my-2'>

                <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                    onClick={() => (
                        setModals({
                            ...modals,
                            showAddAdmitedPatientDailyReportModal:
                                !modals.showAddAdmitedPatientDailyReportModal,
                        })


                    )}
                >
                    Add report
                </button>
            </div>

            <div className='flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1'>
                <h4 className='text-dark-blue text-[16px] font-semibold'>
                    Daily Report - {set_manage_admitted_patient?.first_name} {set_manage_admitted_patient?.last_name} [{set_manage_admitted_patient?.patient_number}]
                </h4>


            </div>
            <table className='border rounded w-full cursor-default'>
                <thead className=''>
                    <tr>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                        >
                            S/N
                        </th>

                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                        >
                            Date & Time
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                        >
                            Report
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                        >
                            Signature
                        </th>


                    </tr>
                </thead>


                <tbody>

                    {all_patient_daily_report?.map((item, index) => (
                        <tr
                            className='relative'
                            key={index}
                        >
                            <td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
                                {index + 1}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.date}   ||   {item.time.slice(0, 8)}    </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.report}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item?.signature?.first_name} {item?.signature?.last_name}
                            </td>

                        </tr>
                    ))}


                </tbody>

            </table>
        </div>
    )
}

export default AdmittedPatientsDailyReportTable