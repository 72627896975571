/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FaTimesCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useStateContext } from "../../../state/context";
import { createWard, updateWard } from "../../../state/actions/admin.actions";
import { setWard } from "../../../state/actions/actions";

const AddWardModal = () => {
	const dispatch = useDispatch()

	const { modals, setModals } = useStateContext()
	const { ward_update } = useSelector(state => state.root)

	const [formData, setFormData] = useState({
		name: "",
		total_bed_space: 0,
	});

	useEffect(() => {
		setFormData({
			...formData,
			name: ward_update?.name,
			total_bed_space: ward_update?.total_bed_space
		})
	}, [ward_update])

	const handleChange = (e, name) => {
		setFormData((prevState) => ({ ...prevState, [name]: e.target.value }));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if(!formData.name) return toast.warning("Please fill all fields")
		dispatch(createWard({ formData, toast, setModals: setModals, modals: modals }));
	}

	const handleUpdate = (e) => {
		e.preventDefault();
		
		if(!formData.name) return toast.warning("Please fill all fields")
		dispatch(updateWard({ formData: { ...formData, id: ward_update.pkid }, toast, setModals: setModals, modals: modals }))
	}

	return (
		<div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
			<div className="bg-white w-[500px] rounded px-[30px] py-[20px]">
				<div className="flex flex-col space-y-5">
					<div className="flex justify-between items-center">
						<h4 className="text-dark-green text-[20px] font-bold">
							Create Patient Ward
						</h4>
						<FaTimesCircle
							className="text-dark-yellow cursor-pointer"
							onClick={() => {
								dispatch(setWard({ data: null }))
								modals.showCreateWardModal && setModals({ ...modals, showCreateWardModal: !modals.showCreateWardModal })
								modals.showUpdateWardModal && setModals({ ...modals, showUpdateWardModal: !modals.showUpdateWardModal })
							}}
							size={20}
						/>
					</div>

					{modals.showCreateWardModal && (
						<form onSubmit={handleSubmit}>
							<div>
								<input
									type="text"
									name="name"
									placeholder="Ward name"
									className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
									onChange={(e) => handleChange(e, e.target.name)}
								/>
							</div>

							<div>
								<input
									type="hidden"
									name="total_bed_space"
									placeholder="Ward capacity"
									className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
									onChange={(e) => handleChange(e, e.target.name)}
								/>
							</div>

							<div className="mt-4">
								<button
									type="submit"
									className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
								>
									Submit
								</button>
							</div>
						</form>
					)}

					{modals.showUpdateWardModal && (
						<form onSubmit={handleUpdate}>
							<div>
								<input
									type="text"
									name="name"
									placeholder="Ward name"
									className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
									value={formData.name}
									onChange={(e) => handleChange(e, e.target.name)}
								/>
							</div>

							<div>
								<input
									type="number"
									disabled
									name="total_bed_space"
									placeholder="Ward capacity"
									className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
									value={formData.total_bed_space}
									onChange={(e) => handleChange(e, e.target.name)}
								/>
							</div>

							<div className="mt-4">
								<button
									type="submit"
									className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
								>
									Update
								</button>
							</div>
						</form>
					)}
				</div>
			</div>
		</div>
	);
};

export default AddWardModal;
