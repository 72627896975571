import { createSlice } from "@reduxjs/toolkit"

import {
    getLaboratoryDailyReports, getLaboratoryInvoices, getLaboratoryMonthlyReports, getLaboratoryReportsAuthor,
    getLaboratoryReportsRange, getLaboratoryYearlyReports, getPharmacyDailyReports, getPharmacyInvoices,
    getPharmacyMonthlyReports, getPharmacyReportsAuthor, getPharmacyReportsRange, getPharmacyYearlyReports,
    getRadiologyDailyReports, getRadiologyInvoices, getRadiologyMonthlyReports, getRadiologyReportsAuthor,
    getRadiologyReportsRange, getRadiologyYearlyReports, getRecordsDailyReports, getRecordsInvoices, getRecordsMonthlyReports,
    getRecordsReportsAuthor, getRecordsReportsRange, getRecordsYearlyReports, getRecordsUnPaidReports, getLabUnPaidReports,
    getPharmacyUnPaidReports, getRadiologyUnPaidReports, getLabPaidReports, getPharmacyPaidReports, getRadiologyPaidReports,
    getRecordsPaidReports, getPharmacyNHISDiscounts, getRecordsNHISDiscounts, getLaboratoryNHISDiscounts, getRadiologyNHISDiscounts,
    setCurrentInvoice,
    getAllInvoices,
    getAllDailyReports,
    getAllMonthlyReports,
    getAllYearlyReports,
    getAllReportsRange,
    getAllReportsAuthor,
    getAllPaidReports,
    getAllUnpaidReports,
    setCurrentReportsType,
    getNHISDiscounts,
    setCurrentDiscounts,
    remitNHISDiscounts
} from "../actions/accountant.actions"


const accountsSlice = createSlice({
    name: 'accountant',
    initialState: {
        nhis_discounts: null,
        remitted_discount: null,
        all_invoices: null,
        all_reports: null,
        laboratory_invoices: null,
        laboratory_reports: null,
        pharmacy_invoices: null,
        pharmacy_reports: null,
        radiology_invoices: null,
        radiology_reports: null,
        records_invoices: null,
        records_reports: null,
        pharmacy_nhis_discounts: null,
        records_nhis_discounts: null,
        laboratory_nhis_discounts: null,
        radiology_nhis_discounts: null,
        inventory_report: null,
        current_invoice: null,
        current_discounts: null,
        reports_type: null,
        accountsLoading: null,
        accountsRequestStatus: null,

    },
    extraReducers: {
        [getAllInvoices.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getAllInvoices.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.all_invoices = action.payload
        },
        [getAllInvoices.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getLaboratoryInvoices.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getLaboratoryInvoices.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.laboratory_invoices = action.payload
        },
        [getLaboratoryInvoices.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getAllDailyReports.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getAllDailyReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.all_reports = action.payload
        },
        [getAllDailyReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getAllMonthlyReports.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getAllMonthlyReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.all_reports = action.payload
        },
        [getAllMonthlyReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getAllYearlyReports.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getAllYearlyReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.all_reports = action.payload
        },
        [getAllYearlyReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getAllReportsRange.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getAllReportsRange.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.all_reports = action.payload
        },
        [getAllReportsRange.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getAllReportsAuthor.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getAllReportsAuthor.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.all_reports = action.payload
        },
        [getAllReportsAuthor.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getAllPaidReports.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getAllPaidReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.all_reports = action.payload
        },
        [getAllPaidReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getAllUnpaidReports.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getAllUnpaidReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.all_reports = action.payload
        },
        [getAllUnpaidReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getLaboratoryDailyReports.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getLaboratoryDailyReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.laboratory_reports = action.payload
        },
        [getLaboratoryDailyReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getLaboratoryMonthlyReports.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getLaboratoryMonthlyReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.laboratory_reports = action.payload
        },
        [getLaboratoryMonthlyReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getLaboratoryYearlyReports.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getLaboratoryYearlyReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.laboratory_reports = action.payload
        },
        [getLaboratoryYearlyReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getLaboratoryReportsRange.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getLaboratoryReportsRange.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.laboratory_reports = action.payload
        },
        [getLaboratoryReportsRange.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getLaboratoryReportsAuthor.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getLaboratoryReportsAuthor.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.laboratory_reports = action.payload
        },
        [getLaboratoryReportsAuthor.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getPharmacyInvoices.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getPharmacyInvoices.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.pharmacy_invoices = action.payload
        },
        [getPharmacyInvoices.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getPharmacyDailyReports.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getPharmacyDailyReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.pharmacy_reports = action.payload
        },
        [getPharmacyDailyReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getPharmacyMonthlyReports.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getPharmacyMonthlyReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.pharmacy_reports = action.payload
        },
        [getPharmacyMonthlyReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getPharmacyYearlyReports.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getPharmacyYearlyReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.pharmacy_reports = action.payload
        },
        [getPharmacyYearlyReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getPharmacyReportsRange.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getPharmacyReportsRange.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.pharmacy_reports = action.payload
        },
        [getPharmacyReportsRange.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getPharmacyReportsAuthor.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getPharmacyReportsAuthor.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.pharmacy_reports = action.payload
        },
        [getPharmacyReportsAuthor.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getRadiologyInvoices.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getRadiologyInvoices.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.radiology_invoices = action.payload
        },
        [getRadiologyInvoices.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getRadiologyDailyReports.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getRadiologyDailyReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.radiology_reports = action.payload
        },
        [getRadiologyDailyReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getRadiologyMonthlyReports.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getRadiologyMonthlyReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.radiology_reports = action.payload
        },
        [getRadiologyMonthlyReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getRadiologyYearlyReports.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getRadiologyYearlyReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.radiology_reports = action.payload
        },
        [getRadiologyYearlyReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getRadiologyReportsRange.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getRadiologyReportsRange.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.radiology_reports = action.payload
        },
        [getRadiologyReportsRange.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getRadiologyReportsAuthor.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getRadiologyReportsAuthor.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.radiology_reports = action.payload
        },
        [getRadiologyReportsAuthor.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getRecordsInvoices.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getRecordsInvoices.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.records_invoices = action.payload
        },

        [getRecordsInvoices.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getRecordsDailyReports.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getRecordsDailyReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.records_reports = action.payload
        },

        [getRecordsDailyReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getRecordsMonthlyReports.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getRecordsMonthlyReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.records_reports = action.payload
        },

        [getRecordsMonthlyReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getRecordsYearlyReports.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getRecordsYearlyReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.records_reports = action.payload
        },

        [getRecordsYearlyReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getRecordsReportsRange.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getRecordsReportsRange.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.records_reports = action.payload
        },

        [getRecordsReportsRange.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getRecordsReportsAuthor.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getRecordsReportsAuthor.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.records_reports = action.payload
        },

        [getRecordsReportsAuthor.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getNHISDiscounts.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getNHISDiscounts.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.nhis_discounts = action.payload
        },
        [getNHISDiscounts.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getPharmacyNHISDiscounts.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getPharmacyNHISDiscounts.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.pharmacy_nhis_discounts = action.payload
        },
        [getPharmacyNHISDiscounts.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getRecordsNHISDiscounts.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getRecordsNHISDiscounts.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.records_nhis_discounts = action.payload
        },
        [getRecordsNHISDiscounts.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getLaboratoryNHISDiscounts.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getLaboratoryNHISDiscounts.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.laboratory_nhis_discounts = action.payload
        },
        [getLaboratoryNHISDiscounts.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getRadiologyNHISDiscounts.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getRadiologyNHISDiscounts.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.radiology_nhis_discounts = action.payload
        },
        [getRadiologyNHISDiscounts.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [setCurrentInvoice.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [setCurrentInvoice.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.current_invoice = action.payload
        },
        [setCurrentInvoice.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getRecordsUnPaidReports.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getRecordsUnPaidReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.records_reports = action.payload
        },

        [getRecordsUnPaidReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getLabUnPaidReports.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getLabUnPaidReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.laboratory_reports = action.payload
        },

        [getLabUnPaidReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },
        [getPharmacyUnPaidReports.pending]: (state, action) => {
            state.accountsLoading = true
        },
        [getPharmacyUnPaidReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.pharmacy_reports = action.payload
        },

        [getRadiologyUnPaidReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },
        [getRadiologyUnPaidReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.radiology_reports = action.payload
        },

        [getRadiologyUnPaidReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },


        [getRadiologyPaidReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },
        [getRadiologyPaidReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.radiology_reports = action.payload
        },

        [getRadiologyPaidReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getLabPaidReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },
        [getLabPaidReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.laboratory_reports = action.payload
        },

        [getLabPaidReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getPharmacyPaidReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },
        [getPharmacyPaidReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.pharmacy_reports = action.payload
        },

        [getPharmacyPaidReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [getRecordsPaidReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },
        [getRecordsPaidReports.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.records_reports = action.payload
        },

        [getRecordsPaidReports.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [setCurrentReportsType.rejected]: (state, action) => {
            state.accountsLoading = false
        },
        [setCurrentReportsType.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.reports_type = action.payload
        },

        [setCurrentReportsType.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [setCurrentDiscounts.rejected]: (state, action) => {
            state.accountsLoading = false
        },
        [setCurrentDiscounts.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.current_discounts = action.payload
        },

        [setCurrentDiscounts.rejected]: (state, action) => {
            state.accountsLoading = false
        },

        [remitNHISDiscounts.rejected]: (state, action) => {
            state.accountsLoading = false
        },
        [remitNHISDiscounts.fulfilled]: (state, action) => {
            state.accountsLoading = false
            state.remitted_discount = action.payload
        },

        [remitNHISDiscounts.rejected]: (state, action) => {
            state.accountsLoading = false
        },
    }
})


export default accountsSlice.reducer;