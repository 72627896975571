/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../state/context'
import { getDrugs } from '../../state/actions/pharmacist.actions'
import { PageTitle, PharmacistDrugsTable, PharmacistManageDrugTable } from '../../components'


const PharmacistDrugs = () => {
	const dispatch = useDispatch()

	const { appState, setAppState } = useStateContext()

	const { drugs } = useSelector(state => state.pharmacist)
	const [showList, setShowList] = useState(true)

	useEffect(() => {
		dispatch(getDrugs())
	}, [])

	useEffect(() => {
		dispatch(getDrugs())
	}, [appState.showDrugInventory])

	useEffect(() => {
		return () => {
			setAppState({ ...appState, showDrugInventory: false })
		}
	}, [])

	return (
		<div>
			<PageTitle type={'pharmacist-drugs'} />

			<div className="space-y-5">
				{!appState.showDrugInventory && (
					<PharmacistDrugsTable
						title={'Drugs'}
						data={drugs}
						showList={showList}
						setShowList={setShowList}
					/>
				)}

				{appState.showDrugInventory && (
					<PharmacistManageDrugTable
						title={'Manage Paracetamol'}
						showList={showList}
						setShowList={setShowList}
					/>
				)}
			</div>
		</div>
	)
}

export default PharmacistDrugs