/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import _ from "lodash"
import { useDispatch, useSelector } from "react-redux"

import { getAllWards } from "../../state/actions/admin.actions"
import { BedSpaceTable, PageTitle, PaginationButtons, WardTable } from "../../components"



const AdminBedManager = (props) => {
	const dispatch = useDispatch()

	const { wards, bedspaces, deletedBedSpace, updatedBedSpace, addedBedSpace } = useSelector((state) => state.admin)

	const [showWard, setShowWard] = useState(true)
	const [currentPage, setCurrentPage] = useState(1)
	const [wardNames, setWardNames] = useState([])
	const [paginatedData, setPaginatedData] = useState([])
	const [paginatedBedSpaces, setPaginatedBedSpaces] = useState([])

	const pageSize = 20
	const pageCount = wards ? Math.ceil(wards.length / pageSize) : 0
	const bedspaces_page_count = bedspaces ? Math.ceil(bedspaces.length / pageSize) : 0
	const pages = _.range(1, pageCount + 1)
	const bedspaces_pages = _.range(1, bedspaces_page_count + 1)

	useEffect(() => {
		dispatch(getAllWards());
	}, [])

	useEffect(() => {
		dispatch(getAllWards());
	}, [addedBedSpace, updatedBedSpace, deletedBedSpace])

	useEffect(() => {
		setPaginatedData(_(wards).slice(0).take(pageSize).value())
		setPaginatedBedSpaces(_(bedspaces).slice(0).take(pageSize).value())

		let wardnames = []
		wards?.forEach(ward => {
			wardnames.push(ward.name)
		})

		setWardNames([...wardnames])
	}, [wards, bedspaces])


	const handlePagination = (page) => {
		setCurrentPage(page)

		const startIndex = (page - 1) * pageSize
		const _data = _(wards).slice(startIndex).take(pageSize).value()
		setPaginatedData(_data)
	}


	return (
		<div>
			<PageTitle
				type={"admin-bedmanager"}
				showCreateWardModal={props.showCreateWardModal}
				setShowCreateWardModal={props.setShowCreateWardModal}
			/>

			<div className="space-y-5">
				<WardTable
					data={paginatedData}
					showList={showWard}
					setShowList={setShowWard}
					title={'Wards'}
					pages={pages}
					currentPage={currentPage}
					handlePagination={handlePagination}
				/>

				{wardNames?.sort().map((ward, index) => (
					<BedSpaceTable
						key={`${ward.id}-${index}`}
						ward={ward}
					/>
				))}
			</div>
		</div>
	)
}

export default AdminBedManager
