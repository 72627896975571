import React, { useState, useEffect } from 'react'
import { useStateContext } from '../../../state/context'
import { FaTimesCircle } from 'react-icons/fa'
import report from '../../../assets/images/user__potrait__1.jpg'
import { setRadiologyGenerateInvoice, setRadiologyViewReport } from '../../../state/actions/actions'
import { useDispatch, useSelector } from 'react-redux'
import { uploadPatientRadiologyReport } from '../../../state/actions/radiologist.actions'
import { toast } from 'react-toastify'


const RadiologyUploadReportModal = () => {
  const dispatch = useDispatch()
  const { set_radiology_generate_invoice, set_radiology_view_report } =
    useSelector((state) => state.root)
  const { modals, setModals } = useStateContext()
  const [formData, setFormData] = useState({
    id: '',
    report_id: '',
    image_report: {},
    photo_display: '',
    text_report: ''
  })
  
  const handleChange = (e, name) => {
    setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))

  }

  const handleImageChange = (e) => {
    
    setFormData({ ...formData, image_report: e.target.files[0], photo_display: URL.createObjectURL(e.target.files[0]) })

  };

  useEffect(() => {
    setFormData({
      ...formData, report_id: set_radiology_generate_invoice?.report_id,

    })
  }, [set_radiology_generate_invoice])
  
  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(formData)

    dispatch(uploadPatientRadiologyReport({ formData, toast, setModals: setModals, modals: modals }))
  }

  return (
    <div>
      <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
        <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
          <div className='flex flex-col space-y-5'>
            <div className='flex justify-between items-center'>


              {modals.showUploadReportModal && (
                <>
                  <h4 className='text-dark-green text-[16px] font-bold'>
                    Upload Investigation  - {set_radiology_generate_invoice?.first_name} {set_radiology_generate_invoice?.last_name} [{set_radiology_generate_invoice?.patient_no}]
                  </h4>
                  <FaTimesCircle
                    className='text-dark-yellow cursor-pointer'
                    onClick={() => {
                      dispatch(setRadiologyGenerateInvoice({ data: null }))
                      setModals({
                        ...modals,
                        showUploadReportModal:
                          !modals.showUploadReportModal,
                      })
                    }


                    }
                    size={20}
                  />
                </>
              )}
              {modals.showRadiologyViewReportModal && (
                <div className='flex flex-col'>
                  <div className='flex justify-between'>
                    <h4 className='text-dark-green text-[15px] font-bold'>
                      View Investigation - {set_radiology_view_report?.first_name} {set_radiology_view_report?.last_name} [{set_radiology_view_report?.patient_no}]
                    </h4>
                    <FaTimesCircle
                      className='text-dark-yellow cursor-pointer'
                      onClick={() => {

                        dispatch(setRadiologyViewReport({ data: null }))
                        setModals({
                          ...modals,
                          showRadiologyViewReportModal:
                            !modals.showRadiologyViewReportModal,
                        })
                      }

                      }
                      size={20}
                    />
                  </div>
                  <div className='mt-2'>
                    {modals.showRadiologyViewReportModal && (
                      <>
                        <div className='w-[500px] h-[200px] ml-4 mt-2 border rounded-lg bg-slate-300'>

                          <div className='flex space-x-2'>
                            <p className='text-lg font-semibold ml-2 my-2 italic'> Investgiation category: </p>
                            <p className='text-lg font-light ml-2 my-2'>{set_radiology_view_report?.test_cat}</p>
                          </div>
                          <div className='flex space-x-2'>
                            <p className='text-lg font-semibold ml-2 my-2 italic'> Investgiation type: </p>
                            <p className='text-lg font-light ml-2 my-2'>{set_radiology_view_report?.test_type}</p>
                          </div>



                        </div>

                        {/* <img src={`http://localhost:8000${set_radiology_view_report?.image}`} alt="" className='rounded-md' /> */}
                      </>
                    )}

                  </div>
                </div>

              )}





            </div>
            {formData.photo_display.length > 0 && (
              <img src={formData.photo_display} alt="" srcset="" className='w-full h-[200px]' />
            )}
            {modals.showUploadReportModal && (

              <form onSubmit={handleSubmit} >
                <div className=''>
                  <input
                    type='file'

                    placeholder='Upload'
                    name="image_report"


                    className='borde rounded h-[40px] mb-4 w-full px-4 py-8 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
                    onChange={handleImageChange}

                  />
                  <textarea className='w-full mt-1 border pt-4 pl-2' name="text_report" id="" cols="30" rows="5" placeholder='Add scan text report '
                    onChange={(e) =>
                      handleChange(e, e.target.name)
                    }
                  >
                  </textarea>
                </div>
                <div className='mt-4 flex justify-center items-center'>
                  <button
                    type='submit'
                    className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
                  >
                    Submit
                  </button>
                </div>

              </form>

            )}


          </div>
        </div>
      </div>
    </div>
  )
}

export default RadiologyUploadReportModal