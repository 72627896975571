/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { GifWallet } from '../assets'
import { verifyAdminPayStackPayment } from '../state/actions/collection.actions'
import { getHospitalConfig, sendSmsTopupNotification, ugradeSubscriptionPlan, updateMonthlyRemittance, updateSubscriptionPlan } from '../state/actions/admin.actions'


const AdminVerifyPayStackPayment = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const { hospital_config } = useSelector(state => state.admin)
    const { paystack_payment_status } = useSelector(state => state.collection)
    const payment__invoice = localStorage.getItem('payment__invoice') ? JSON.parse(localStorage.getItem('payment__invoice')) : null

    const [ref, setRef] = useState(null)

    useEffect(() => {
        dispatch(getHospitalConfig())
        const ref = window.location.href.split('reference=')[1]
        setRef(ref)
    }, [])

    useEffect(() => {
        document.title = hospital_config?.name
        let link = document.querySelector("link[rel~='icon']");

        link.href = `${process.env.REACT_APP_API_ROOT}${hospital_config?.logo}`;
    }, [hospital_config])

    useEffect(() => {
        dispatch(verifyAdminPayStackPayment({ params: { ref } }))
    }, [ref])


    useEffect(() => {
        if (payment__invoice?.type === 'sms_payment' && paystack_payment_status?.status === 'success') {
            const formData = {
                ...payment__invoice,
                payment_ref: ref
            }
            console.log(formData)
            dispatch(sendSmsTopupNotification({ formData, toast, navigate }))
        }

    }, [paystack_payment_status])

    useEffect(() => {
        if (payment__invoice.type === 'subscription__renewal__payment' && paystack_payment_status?.status === 'success') {
            const formData = {
                ...payment__invoice,
                amount_paid: payment__invoice?.amount_paid,
                mode_of_payment: paystack_payment_status?.channel,
                payment_reference: ref,
            }
            console.log(formData)
            dispatch(updateSubscriptionPlan({ formData, toast, navigate }))
        }
        if (payment__invoice.type === 'subscription__upgrade__payment' && paystack_payment_status?.status === 'success') {
            const formData = {
                ...payment__invoice,
                amount_paid: payment__invoice?.amount_paid,
                mode_of_payment: paystack_payment_status?.channel,
                payment_reference: ref,
            }
            console.log(formData)
            dispatch(ugradeSubscriptionPlan({ formData, toast, navigate }))
        }
        if (payment__invoice.type === 'monthly__remittance__payment' && paystack_payment_status?.status === 'success') {
            const formData = {
                ...payment__invoice,
                mode_of_payment: paystack_payment_status?.channel,
                payment_reference: paystack_payment_status?.reference,
            }
            console.log(formData)
            dispatch(updateMonthlyRemittance({ formData, toast, navigate }))
        }
    }, [paystack_payment_status?.status])

    return (
        <div className='h-screen flex justify-center items-center'>
            <img
                src={GifWallet}
                alt='wallet'
                className='w-1/2'
            />
        </div>
    )
}

export default AdminVerifyPayStackPayment