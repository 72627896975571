import { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AiOutlineDown } from 'react-icons/ai'
import { BsBagPlusFill, BsFillPlusCircleFill } from 'react-icons/bs'

import { useStateContext } from '../../state/context'
import {
    setCurrentBinCard, setCurrentDrug, setCurrentPatient,
    setCurrentSupplyPoint, setCurrentSupplyPointBinCard,
    setCurrentSupplyPointInventory
} from '../../state/actions/actions'
import { resetDrugBinCards, resetSupplyPointDrugBinCards, resetSupplyPointInventory } from '../../state/actions/pharmacist.actions'
import { deleteAssignedPatient, resetNewDiagnosis } from '../../state/actions/doctor.actions'



const PageTitle = ({ type, showDirectorateDropDown, setShowDirectorateDropDown, directorateDropdownItem, setDirectorateDropdownItem, showAddDirectorateModal, setShowAddDirectorateModal, showAddDepartmentModal, setShowAddDepartmentModal, ...props }) => {
    const dispatch = useDispatch()

    const { appState, setAppState, modals, setModals, chatState, setChatState, opdState, setOpdState } = useStateContext()

    const { user } = useSelector(state => state.auth)
    const { drug_update, supplypoint_update, patient_update } = useSelector(state => state.root)


    const handleShowModal = () => {
        directorateDropdownItem === 'Directorates' && setModals({ ...modals, showAddDirectorateModal: !modals.showAddDirectorateModal })
        directorateDropdownItem === 'Departments' && setModals({ ...modals, showAddDepartmentModal: !modals.showAddDepartmentModal })
    }

    return (
        <div className="flex justify-between items-center">
            <div className="flex flex-col space-y-[-13px]">
                {type === 'admin-dashboard' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Dashboard</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Quick overview of the inventory.</p>
                    </Fragment>
                )}


                {type === 'admin-departments' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Directorates</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Directorates management.</p>
                    </Fragment>
                )}

                {type === 'admin-humanresources' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Human Resources</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Human resources management.</p>
                    </Fragment>
                )}

                {type === 'admin-invoices' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Billings</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Billings management.</p>
                    </Fragment>
                )}

                {type === 'admin-bedmanager' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Bed Manager</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Patient ward management.</p>
                    </Fragment>
                )}

                {type === 'records-dashboard' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Records Dashboard</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Quick overview of the inventory.</p>
                    </Fragment>
                )}

                {type === 'records-patients' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Patients</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Patients management.</p>
                    </Fragment>
                )}

                {type === 'records-invoices' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Invoices</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Invoice management.</p>
                    </Fragment>
                )}

                {type === 'records-records' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Records</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Records management.</p>
                    </Fragment>
                )}

                {type === 'records-wards_beds' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Wards</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Wards management.</p>
                    </Fragment>
                )}

                {type === 'pharmacist-dashboard' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Dashboard</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Quick overview of the inventory.</p>
                    </Fragment>
                )}

                {type === 'radiologist-dashboard' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Dashboard</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Quick overview of the inventory.</p>
                    </Fragment>
                )}

                {type === 'radiologist-equipments' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Equipments</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Equipments management.</p>
                    </Fragment>
                )}

                {type === 'radiologist-reports' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Reports</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Reports management.</p>
                    </Fragment>
                )}

                {type === 'radiologist-investigations' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Investigations</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Investigations management.</p>
                    </Fragment>
                )}

                {type === 'radiologist-invoices' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Invoices</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Invoices management.</p>
                    </Fragment>
                )}


                {type === 'laboratorist-dashboard' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Dashboard</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Overview of the inventory.</p>
                    </Fragment>
                )}

                {type === 'laboratorist-equipments' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Equipments</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Equipments management.</p>
                    </Fragment>
                )}

                {type === 'laboratorist-reports' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Reports</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Reports management.</p>
                    </Fragment>
                )}

                {type === 'laboratorist-investigations' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Investigations</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Investigations management.</p>
                    </Fragment>
                )}

                {type === 'laboratorist-invoices' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Invoices</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Invoices management.</p>
                    </Fragment>
                )}

                {type === 'nurse-addPatient' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>OPD</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Out patient management</p>
                    </Fragment>
                )}

                {type === 'nurse_inpatient' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Inpatients</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>inpatient management</p>
                    </Fragment>
                )}

                {type === 'nurse_family_planning' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Family Planning</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Family planning management</p>
                    </Fragment>
                )}

                {type === 'nurse_child_immunization' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Child Immunization</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Immunization management</p>

                    </Fragment>
                )}

                {type === 'nurse_antenatal' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Antenatal</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Antenatal management</p>

                    </Fragment>
                )}

                {type === 'nurse_birth_death_records' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Birth Records</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Records management</p>

                    </Fragment>
                )}



                {type === 'pharmacist-drugs-category' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Drugs Category</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Drug category management.</p>
                    </Fragment>
                )}

                {type === 'pharmacist-drugs' && (
                    <Fragment>
                        {!appState.showDrugInventory && (
                            <Fragment>
                                <h1 className='text-[16px] text-dark-blue font-bold'>Drugs</h1>
                                <p className='text-[12px] text-gray-600 font-normal'>Drugs management.</p>
                            </Fragment>
                        )}
                        {appState.showDrugInventory && (
                            <Fragment>
                                <h1 className='text-[16px] text-dark-blue font-bold'>{drug_update?.name}</h1>
                                <p className='text-[12px] text-gray-600 font-normal'>{drug_update?.name} card management.</p>
                            </Fragment>
                        )}
                    </Fragment>
                )}

                {type === 'pharmacist-supply-point' && (
                    <Fragment>
                        <Fragment>
                            {!appState.showManageBinCard && (
                                <Fragment>
                                    <h1 className='text-[16px] text-dark-blue font-bold'>{user?.data?.assigned_supplypoint_details?.name}</h1>
                                    <p className='text-[12px] text-gray-600 font-normal'>{user?.data?.assigned_supplypoint_details?.name} supply point management.</p>
                                </Fragment>
                            )}
                            {/* <Fragment>
                                                                <h1 className='text-[16px] text-dark-blue font-bold'>{supplypoint_update?.name}</h1>
                                                                <p className='text-[12px] text-gray-600 font-normal'>{supplypoint_update?.name} supply point.</p>
                                                            </Fragment> */}
                            {appState.showManageBinCard && (
                                <Fragment>
                                    <h1 className='text-[16px] text-dark-blue font-bold'>Bin Card</h1>
                                    <p className='text-[12px] text-gray-600 font-normal'>Paracetamol bin card management.</p>
                                </Fragment>
                            )}
                        </Fragment>
                    </Fragment>
                )}

                {type === 'pharmacist-supply-points' && (
                    <Fragment>
                        {!appState.showSupplyPoint && (
                            <Fragment>
                                <h1 className='text-[16px] text-dark-blue font-bold'>Supply Points</h1>
                                <p className='text-[12px] text-gray-600 font-normal'>Supply points management.</p>
                            </Fragment>
                        )}
                        {appState.showSupplyPoint && (
                            <Fragment>
                                {!appState.showManageBinCard && (
                                    <Fragment>
                                        <h1 className='text-[16px] text-dark-blue font-bold'>{supplypoint_update?.name}</h1>
                                        <p className='text-[12px] text-gray-600 font-normal'>{supplypoint_update?.name} supply point.</p>
                                    </Fragment>
                                )}
                                {appState.showManageBinCard && (
                                    <Fragment>
                                        <h1 className='text-[16px] text-dark-blue font-bold'>Bin Card</h1>
                                        <p className='text-[12px] text-gray-600 font-normal'>Paracetamol bin card management.</p>
                                    </Fragment>
                                )}
                            </Fragment>
                        )}
                    </Fragment>
                )}

                {type === 'pharmacist-prescriptions' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Prescriptions</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Prescriptions management.</p>
                    </Fragment>
                )}

                {type === 'pharmacist-bills' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Bills | Dispense</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Bills and drugs dispense management.</p>
                    </Fragment>
                )}

                {type === 'doctor-dashboard' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Dashboard</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Quick overview of the inventory.</p>
                    </Fragment>
                )}

                {type === 'doctor-team' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Team</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Team management.</p>
                    </Fragment>
                )}

                {type === 'doctor-patients' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Patients</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Patients management.</p>
                    </Fragment>
                )}

                {type === 'doctor-assignedpatients' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Assigned Patients</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Assigned patients management.</p>
                    </Fragment>
                )}

                {type === 'doctor-admissions' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Patient Admissions</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Patient admissions management.</p>
                    </Fragment>
                )}

                {type === 'doctor-appointments' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Appointments</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Patient appointments management.</p>
                    </Fragment>
                )}

                {type === 'patient-payments' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Invoices</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Invoices management.</p>
                    </Fragment>
                )}

                {type === 'patient-appointments' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Appointments | Assignments</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Appointments and assignments management.</p>
                    </Fragment>
                )}

                {type === 'accounts-invoices' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Invoice Reports</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Invoice reports management.</p>
                    </Fragment>
                )}

                {type === 'accounts-NHIS-discounts' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>NHIS Discounts</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>NHIS Discounts management.</p>
                    </Fragment>
                )}

                {type === 'collections-unpaid-invoices' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Unpaid Invoices</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Unpaid invoices management.</p>
                    </Fragment>
                )}

                {type === 'collections-paid-invoices' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Paid Invoices</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Paid invoices management.</p>
                    </Fragment>
                )}

                {type === 'chat' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Chat</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Chat and video call communication.</p>
                    </Fragment>
                )}

                {type === 'messages' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Text SMS</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Text sms management.</p>
                    </Fragment>
                )}

                {type === 'emails' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Emails</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Email management.</p>
                    </Fragment>
                )}

                {type === 'schedule' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Schedules</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Schedule management.</p>
                    </Fragment>
                )}

                {type === 'profile' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Profile</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Profile management.</p>
                    </Fragment>
                )}

                {type === 'settings' && (
                    <Fragment>
                        <h1 className='text-[16px] text-dark-blue font-bold'>Settings</h1>
                        <p className='text-[12px] text-gray-600 font-normal'>Hospital dashboard management.</p>
                    </Fragment>
                )}

            </div>

            {type === 'admin-dashboard' && ''}
            {type === 'records-dashboard' && ''}

            {type === 'admin-departments' && (
                <div className="flex justify-between">
                    <div className="relative">
                        <button
                            type="button"
                            className="inline-block px-6 py-2.5 border-2 border-dark-green text-dark-green font-medium text-xs leading-tight uppercase rounded shadow-md hover:text-white hover:bg-dark-green hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease mr-2"
                            onClick={() => setShowDirectorateDropDown(!showDirectorateDropDown)}
                        >
                            <span className='flex justify-between items-center'>
                                {directorateDropdownItem}
                                <AiOutlineDown
                                    className='ml-2'
                                    size={15}
                                    style={{ 'transform': `${showDirectorateDropDown ? 'rotate(180deg)' : 'rotate(0deg)'}` }}
                                />
                            </span>
                        </button>

                        {showDirectorateDropDown && (
                            <div className="absolute top-[40px] right-2 w-[200px] flex flex-col items-start bg-white rounded shadow-lg p-5">
                                <p
                                    className='text-[14px] text-sky-500 cursor-pointer'
                                    onClick={() => {
                                        setShowDirectorateDropDown(!showDirectorateDropDown)
                                        setDirectorateDropdownItem(`${directorateDropdownItem === 'Departments' ? 'Directorates' : 'Departments'}`)
                                    }}
                                >
                                    {directorateDropdownItem === 'Directorates' ? 'Departments' : 'Directorates'}
                                </p>
                            </div>
                        )}
                    </div>
                    <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-dark-blue text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                        onClick={handleShowModal}
                    >
                        {directorateDropdownItem === 'Departments' ? 'Add Department' : 'Add Directorate'}
                    </button>
                </div>
            )}

            {type === 'admin-humanresources' && (
                <div className="flex items-center">
                    <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                        onClick={() => setModals({ ...modals, showAddEmployeeModal: !modals.showAddEmployeeModal })}
                    // onClick={() => props?.setShowAddEmployeeModal(!props?.showAddEmployeeModal)}
                    >
                        Add Employee
                    </button>
                </div>
            )}

            {type === 'admin-invoices' && (
                <div className="flex items-center">
                    <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                        onClick={() => setModals({ ...modals, showAddBillingModal: !modals.showAddBillingModal })}
                    >
                        Add billing type
                    </button>
                </div>
            )}

            {type === 'admin-bedmanager' && (
                <div className="flex justify-between items-center space-x-5">
                    <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                        onClick={() => setModals({ ...modals, showCreateWardModal: !modals?.showCreateWardModal })}
                    >
                        Create Ward
                    </button>

                    <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-dark-blue text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                        onClick={() => setModals({ ...modals, showAddBedSpaceModal: !modals?.showAddBedSpaceModal })}
                    >
                        Add Bed Space
                    </button>
                </div>
            )}

            {type === 'records-patients' && (
                <div className="flex items-center">
                    <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                        onClick={() => {
                            setModals({ ...modals, showRecordsAddPatientModal: !modals.showRecordsAddPatientModal })
                        }}
                    >
                        Add Patient
                    </button>
                </div>
            )}

            {type === 'records-records' && (
                <div className="flex justify-between space-x-5">
                    <div className="flex items-center">
                        <button
                            type="button"
                            className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                            onClick={() => setModals({ ...modals, showAddBirthRecordModal: !modals.showAddBirthRecordModal })}
                        >
                            Add Birth Record
                        </button>
                    </div>
                    <div className="flex items-center">
                        <button
                            type="button"
                            className="inline-block px-6 py-2.5 bg-dark-blue text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                            onClick={() => setModals({ ...modals, showAddDeathRecordModal: !modals.showAddDeathRecordModal })}
                        >
                            Add Death Record
                        </button>
                    </div>
                </div>
            )}

            {type === 'radiologist-equipments' && user?.data?.is_department_head && (
                <div className="flex items-start">
                    <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                        onClick={() => setModals({
                            ...modals,
                            showAddRadiologyEquipment:
                                !modals.showAddRadiologyEquipment,
                        })}
                    >
                        Add equipment
                    </button>
                </div>
            )}
            {type === 'radiologist-investigations' && user?.data?.is_department_head && (
                <div className="flex  justify-between  space-x-5t">
                    <button
                        type='button'
                        className='inline-block px-6 py-2.5 ml-2 bg-dark-blue text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease'
                        onClick={() => (
                            setModals({
                                ...modals,
                                showAddRadiologyInvestigationCategoryModal:
                                    !modals.showAddRadiologyInvestigationCategoryModal,
                            })
                        )

                        }
                    >
                        <span className='flex items-center justify-between'>
                            Add Investigation Category

                        </span>
                    </button>
                    <button
                        type='button'
                        className='inline-block px-6 py-2.5 ml-2 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease'
                        onClick={() => (
                            setModals({
                                ...modals,
                                showAddRadiologyInvestigationTypeModal:
                                    !modals.showAddRadiologyInvestigationTypeModal,
                            })
                        )

                        }
                    >
                        <span className='flex items-center justify-between'>
                            Add Investigation Type

                        </span>
                    </button>
                </div>
            )}

            {type === 'laboratorist-investigations' && user?.data?.is_department_head && (
                <div className="flex justify-between space-x-5">
                    <button
                        type='button'
                        className='inline-block px-6 py-2.5 ml-2 bg-dark-blue text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease'
                        onClick={() => (
                            setModals({ ...modals, showLaboratoryAddCategoryModal: !modals.showLaboratoryAddCategoryModal })
                        )}
                    >
                        Add Investigation Category
                    </button>
                    <button
                        type='button'
                        className='inline-block px-6 py-2.5 ml-2 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease'
                        onClick={() => (
                            setModals({ ...modals, showLaboratoryAddInvestigationType: !modals.showLaboratoryAddInvestigationType })
                        )}
                    >
                        Add Investigation Type
                    </button>
                    <button
                        type='button'
                        className='inline-block px-6 py-2.5 ml-2 bg-sky-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease'
                        onClick={() => (
                            setModals({ ...modals, showAddLaboratorySamplesModal: !modals.showAddLaboratorySamplesModal })
                        )}
                    >
                        Add samples
                    </button>


                </div>
            )}

            {type === 'laboratorist-equipments' && user?.data?.is_department_head && (
                <div className="flex items-start">
                    <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                        onClick={() => setModals({
                            ...modals,
                            showAddLaboratoryEquipmentModal:
                                !modals.showAddLaboratoryEquipmentModal,
                        })}
                    >
                        Add equipment
                    </button>
                </div>
            )}

            {type === 'pharmacist-drugs-category' && (
                <div className="flex items-center">
                    <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                        onClick={() => setModals({ ...modals, showPharmacistAddDrugCategoryModal: !modals.showPharmacistAddDrugCategoryModal })}
                    >
                        Add Drug Category
                    </button>
                </div>
            )}

            {type === 'pharmacist-drugs' && (
                <Fragment>
                    {appState.showDrugInventory && (
                        <div className="flex justify-between space-x-5">
                            <div className="flex items-center">
                                <button
                                    type="button"
                                    className="inline-block px-4 py-2.5 bg-sky-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:translate-x-1 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                    onClick={() => setModals({ ...modals, showPharmacistBinCardModal: !modals.showPharmacistBinCardModal })}
                                >
                                    <span className="flex justify-between items-center"><BsFillPlusCircleFill className='mr-2' /> Add To Bin Card</span>
                                </button>
                            </div>
                            <div className="flex items-center">
                                <button
                                    type="button"
                                    className="inline-block px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:translate-x-1 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                    onClick={() => setModals({ ...modals, showPharmacistDrugSupplyPointModal: !modals.showPharmacistDrugSupplyPointModal })}
                                >
                                    <span className="flex justify-between items-center"><BsBagPlusFill className='mr-2' />Release To Supply Point</span>
                                </button>
                            </div>
                            <div className="flex items-center">
                                <button
                                    type="button"
                                    className="inline-block px-6 py-2.5 bg-violet-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                    onClick={() => {
                                        dispatch(setCurrentDrug({ data: null }))
                                        dispatch(setCurrentBinCard({ data: null }))
                                        dispatch(resetDrugBinCards())
                                        setAppState({ ...appState, showDrugInventory: !appState.showDrugInventory })
                                    }}
                                >
                                    Exit
                                </button>
                            </div>
                        </div>
                    )}

                    {!appState.showDrugInventory && (
                        <div className="flex items-center">
                            <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => setModals({ ...modals, showPharmacistAddDrugModal: !modals.showPharmacistAddDrugModal })}
                            >
                                Add Drug
                            </button>
                        </div>
                    )}

                </Fragment>
            )}

            {type === 'pharmacist-supply-point' && (
                <Fragment>
                    {!appState.showManageBinCard && (
                        <div className="flex justify-between space-x-5 items-center">
                            <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-sky-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => setModals({ ...modals, showPharmacistBinCardSummaryModal: !modals.showPharmacistBinCardSummaryModal })}
                            >
                                Bin Card Summary
                            </button>
                            {/* <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-violet-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => {
                                    dispatch(setCurrentSupplyPoint({ data: null }))
                                    dispatch(resetSupplyPointInventory())
                                    setAppState({ ...appState, showSupplyPoint: !appState.showSupplyPoint })
                                }}
                            >
                                Exit
                            </button> */}
                        </div>
                    )}
                    {appState.showManageBinCard && (
                        <div className="flex justify-between space-x-5 items-center">
                            <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => setModals({ ...modals, showPharmacistLendRefundDrugModal: !modals.showPharmacistLendRefundDrugModal })}
                            >
                                Lend/Return To Supply Point
                            </button>
                            <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-violet-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => {
                                    dispatch(setCurrentSupplyPointBinCard({ data: null }))
                                    dispatch(resetSupplyPointDrugBinCards({ data: null }))
                                    dispatch(setCurrentSupplyPointInventory({ data: null }))
                                    // setAppState({ ...appState, showSupplyPoint: true })
                                    setAppState({ ...appState, showManageBinCard: !appState.showManageBinCard })
                                }}
                            >
                                Exit
                            </button>
                        </div>
                    )}
                </Fragment>
            )}

            {type === 'pharmacist-supply-points' && (
                <Fragment>
                    {!appState.showSupplyPoint && (
                        <div className="flex justify-between space-x-5 items-center">
                            <div className="flex items-center">
                                <button
                                    type="button"
                                    className="inline-block px-6 py-2.5 bg-dark-blue text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                    onClick={() => setModals({ ...modals, showPharmacistAssignSupplyPointModal: !modals.showPharmacistAssignSupplyPointModal })}
                                >
                                    Assign Pharmacist
                                </button>
                            </div>
                            <div className="flex items-center">
                                <button
                                    type="button"
                                    className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                    onClick={() => setModals({ ...modals, showPharmacistAddSupplyPointModal: !modals.showPharmacistAddSupplyPointModal })}
                                >
                                    Add Supply Point
                                </button>
                            </div>
                        </div>
                    )}
                    {appState.showSupplyPoint && (
                        <Fragment>
                            {!appState.showManageBinCard && (
                                <div className="flex justify-between space-x-5 items-center">
                                    <button
                                        type="button"
                                        className="inline-block px-6 py-2.5 bg-teal-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                        onClick={() => setModals({ ...modals, showPharmacistSupplyPointStaffModal: !modals.showPharmacistSupplyPointStaffModal })}
                                    >
                                        Staff
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-block px-6 py-2.5 bg-teal-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                        onClick={() => setModals({ ...modals, showPharmacistSupplyPointAssignStaffModal: !modals.showPharmacistSupplyPointAssignStaffModal })}
                                    >
                                        Assign Staff
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-block px-6 py-2.5 bg-sky-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                        onClick={() => setModals({ ...modals, showPharmacistBinCardSummaryModal: !modals.showPharmacistBinCardSummaryModal })}
                                    >
                                        Bin Card Summary
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-block px-6 py-2.5 bg-violet-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                        onClick={() => {
                                            dispatch(setCurrentSupplyPoint({ data: null }))
                                            dispatch(resetSupplyPointInventory())
                                            setAppState({ ...appState, showSupplyPoint: !appState.showSupplyPoint })
                                        }}
                                    >
                                        Exit
                                    </button>
                                </div>
                            )}
                            {appState.showManageBinCard && (
                                <div className="flex justify-between space-x-5 items-center">
                                    {/* <button
                                        type="button"
                                        className="inline-block px-6 py-2.5 bg-sky-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                        onClick={() => setModals({ ...modals, showPharmacistIssueOutDrugModal: !modals.showPharmacistIssueOutDrugModal })}
                                    >
                                        Issue Out
                                    </button> */}
                                    <button
                                        type="button"
                                        className="inline-block px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                        onClick={() => setModals({ ...modals, showPharmacistLendRefundDrugModal: !modals.showPharmacistLendRefundDrugModal })}
                                    >
                                        Lend/Return To Supply Point
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-block px-6 py-2.5 bg-violet-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                        onClick={() => {
                                            dispatch(setCurrentSupplyPointBinCard({ data: null }))
                                            dispatch(resetSupplyPointDrugBinCards({ data: null }))
                                            dispatch(setCurrentSupplyPointInventory({ data: null }))
                                            setAppState({ ...appState, showSupplyPoint: true })
                                            setAppState({ ...appState, showManageBinCard: !appState.showManageBinCard })
                                        }}
                                    >
                                        Exit
                                    </button>
                                </div>
                            )}
                        </Fragment>
                    )}
                </Fragment>
            )}

            {type === 'doctor-team' && (
                <div className="flex justify-between space-x-5 items-center">
                    {user?.data?.is_department_head && (
                        <div className="flex items-center">
                            <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => setModals({ ...modals, showDoctorCreateTeamModal: !modals.showDoctorCreateTeamModal })}
                            >
                                Create Team
                            </button>
                        </div>
                    )}
                </div>
            )}

            {type === 'doctor-patients' && (
                <Fragment>
                    {appState.showDoctorManagePatient && (
                        <div className="flex justify-between space-x-5 items-center">
                            <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => setModals({ ...modals, showDoctorTransferPatientModal: !modals.showDoctorTransferPatientModal })}
                            >
                                Transfer
                            </button>
                            <Fragment>
                                {!appState.showDoctorPerformNewDiagnosis && (
                                    <Fragment>
                                        <button
                                            type="button"
                                            className="inline-block px-6 py-2.5 bg-dark-blue text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                            onClick={() => setAppState({ ...appState, showDoctorPerformNewDiagnosis: !modals.showDoctorPerformNewDiagnosis })}
                                        >
                                            New Diagnosis
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-block px-6 py-2.5 bg-violet-800 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                            onClick={() => {
                                                dispatch(setCurrentPatient({ data: null }))
                                                dispatch(resetNewDiagnosis())
                                                setAppState({ ...appState, showDoctorManagePatient: !appState.showDoctorManagePatient })
                                            }}
                                        >
                                            Exit
                                        </button>
                                    </Fragment>
                                )}
                                {appState.showDoctorPerformNewDiagnosis && (
                                    <button
                                        type="button"
                                        className="inline-block px-6 py-2.5 bg-violet-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                        onClick={() => {
                                            dispatch(resetNewDiagnosis())
                                            setAppState({ ...appState, showDoctorPerformNewDiagnosis: false })
                                        }}
                                    >
                                        Complete Diagnosis
                                    </button>
                                )}
                            </Fragment>

                        </div>
                    )}

                    {!appState.showDoctorManagePatient && appState.showDoctorPatientDependants && (
                        <button
                            type="button"
                            className="inline-block px-6 py-2.5 bg-violet-800 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                            onClick={() => {
                                dispatch(setCurrentPatient({ data: null }))
                                dispatch(resetNewDiagnosis())
                                setAppState({ ...appState, showDoctorPatientDependants: !appState.showDoctorPatientDependants })
                            }}
                        >
                            Exit
                        </button>
                    )}
                </Fragment>
            )}

            {type === 'doctor-assignedpatients' && (
                <Fragment>
                    {appState.showDoctorManagePatient && (
                        <div className="flex justify-between space-x-5 items-center">
                            {/* <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => setModals({ ...modals, showDoctorTransferPatientModal: !modals.showDoctorTransferPatientModal })}
                            >
                                Transfer
                            </button> */}
                            <Fragment>
                                {!appState.showDoctorPerformNewDiagnosis && (
                                    <Fragment>
                                        <button
                                            type="button"
                                            className="inline-block px-6 py-2.5 bg-dark-blue text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                            onClick={() => setAppState({ ...appState, showDoctorPerformNewDiagnosis: !modals.showDoctorPerformNewDiagnosis })}
                                        >
                                            New Diagnosis
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-block px-6 py-2.5 bg-violet-800 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                            onClick={() => {
                                                dispatch(setCurrentPatient({ data: null }))
                                                dispatch(resetNewDiagnosis())
                                                setAppState({ ...appState, showDoctorManagePatient: !appState.showDoctorManagePatient })
                                            }}
                                        >
                                            Exit
                                        </button>
                                    </Fragment>
                                )}
                                {appState.showDoctorPerformNewDiagnosis && (
                                    <button
                                        type="button"
                                        className="inline-block px-6 py-2.5 bg-violet-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                        onClick={() => {
                                            console.log(patient_update)
                                            if (patient_update?.is_assigned_patient) {
                                                dispatch(deleteAssignedPatient({ id: patient_update?.record_pkid }))
                                            }

                                            dispatch(resetNewDiagnosis())
                                            setAppState({ ...appState, showDoctorPerformNewDiagnosis: false })
                                        }}
                                    >
                                        Complete Diagnosis
                                    </button>
                                )}
                            </Fragment>

                        </div>
                    )}
                </Fragment>
            )}

            {type === 'doctor-admissions' && (
                <Fragment>
                    {appState.showDoctorManagePatient && (
                        <div className="flex justify-between space-x-5 items-center">
                            {/* <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => setModals({ ...modals, showDoctorTransferPatientModal: !modals.showDoctorTransferPatientModal })}
                            >
                                Transfer
                            </button> */}
                            <Fragment>
                                {!appState.showDoctorPerformNewDiagnosis && (
                                    <Fragment>
                                        <button
                                            type="button"
                                            className="inline-block px-6 py-2.5 bg-dark-blue text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                            onClick={() => setAppState({ ...appState, showDoctorPerformNewDiagnosis: !modals.showDoctorPerformNewDiagnosis })}
                                        >
                                            New Diagnosis
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-block px-6 py-2.5 bg-violet-800 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                            onClick={() => {
                                                dispatch(setCurrentPatient({ data: null }))
                                                dispatch(resetNewDiagnosis())
                                                setAppState({ ...appState, showDoctorManagePatient: !appState.showDoctorManagePatient })
                                            }}
                                        >
                                            Exit
                                        </button>
                                    </Fragment>
                                )}
                                {appState.showDoctorPerformNewDiagnosis && (
                                    <button
                                        type="button"
                                        className="inline-block px-6 py-2.5 bg-violet-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                        onClick={() => {
                                            dispatch(resetNewDiagnosis())
                                            setAppState({ ...appState, showDoctorPerformNewDiagnosis: false })
                                        }}
                                    >
                                        Complete Diagnosis
                                    </button>
                                )}
                            </Fragment>

                        </div>
                    )}
                </Fragment>
            )}

            {type === 'profile' && (
                <div className="flex items-start">
                    <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-sky-800 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                        onClick={() => setModals({ ...modals, updatePasswordModal: !modals.updatePasswordModal })}
                    >
                        Change Password
                    </button>
                </div>
            )}

            {type === 'nurse-addPatient' && (
                <div className="flex items-center">
                    {opdState.showOpdTable && (
                        <button
                            type="button"
                            className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                            onClick={() => {
                                setModals({ ...modals, showNurseAddPatientModal: !modals.showNurseAddPatientModal })
                            }}
                        >
                            Add Patient
                        </button>
                    )}
                    {opdState.showPatientDiagnosisHistory && (
                        <div className='space-x-2'>
                            {opdState.showDiagnosisPrescription && (

                                <button
                                    type="button"
                                    className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                    onClick={() => {
                                        setOpdState({
                                            ...opdState, showOpdTable: false,
                                            showPatientDiagnosisHistory: true,
                                            showDiagnosisPrescription: false

                                        })
                                    }}
                                >
                                    Back
                                </button>
                            )}
                            {!opdState.showDiagnosisPrescription && (
                                <button
                                    type="button"
                                    className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                    onClick={() => {
                                        setModals({ ...modals, showNurseAddPerformanceDataModal: !modals.showNurseAddPerformanceDataModal })
                                    }}
                                >
                                    Add Performance data
                                </button>
                            )}
                            <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => {
                                    setOpdState({ ...opdState, showOpdTable: true, showDiagnosisPrescription: false, showPatientDiagnosisHistory: false })

                                }}
                            >
                                Exit
                            </button>
                        </div >
                    )}
                </div>
            )}

            {type === 'chat' && (
                <div className="flex items-center space-x-4">
                    {/* <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                    // onClick={() => props?.setShowCreateWardModal(!props?.showCreateWardModal)}
                    >
                        New Chat
                    </button> */}
                    {chatState?.showVideoCallUI && <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-indigo-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-blue active:shadow-lg transition duration-500 ease"
                        onClick={() => setChatState({ ...chatState, showGroupCallUI: !chatState?.showGroupCallUI })}
                    >
                        {!chatState.showGroupCallUI ? 'Group Call' : 'Single Call'}
                    </button>}
                    <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-dark-blue text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-blue active:shadow-lg transition duration-500 ease"
                        onClick={() => setChatState({ ...chatState, showVideoCallUI: !chatState?.showVideoCallUI })}
                    >
                        {!chatState?.showVideoCallUI ? 'Video Call' : 'Text Chat'}
                    </button>
                </div>
            )}

            {/* {type === 'schedule' && (
                <CalenderHeader
                    dateIndex={dateIndex}
                    setDateIndex={setDateIndex}
                    currentMonthIndex={currentMonthIndex}
                />
            )} */}

            {!opdState.showFamilyPlanningFollowUpRecords && type === 'nurse_family_planning' && (
                <div className="flex items-start">
                    <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                        onClick={() => setModals({
                            ...modals,
                            showBookPatientForFamilyPlanningModal:
                                !modals.showBookPatientForFamilyPlanningModal,
                        })}
                    >
                        Book appointment
                    </button>
                </div>
            )}

            {opdState.showFamilyPlanningFollowUpRecords && (

                type === 'nurse_family_planning' && (
                    <div className="flex items-start">
                        <button
                            type="button"
                            className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                            onClick={() => setModals({
                                ...modals,
                                showPatientFamilyPlanningCardModal:
                                    !modals.showPatientFamilyPlanningCardModal,
                            })}
                        >
                            Generate Card
                        </button>
                    </div>
                )

            )}

            {!opdState.showChildImmunizationRecords && (
                type === 'nurse_child_immunization' && (
                    <div className="flex items-start">
                        <button
                            type="button"
                            className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                            onClick={() => setModals({
                                ...modals,
                                showAddChildForImmunizationModal:
                                    !modals.showAddChildForImmunizationModal,
                            })}
                        >
                            Add patient +
                        </button>
                    </div>
                )
            )}

            {opdState.showChildImmunizationRecords && (
                type === 'nurse_child_immunization' && (
                    <div className="flex items-start">
                        <button
                            type="button"
                            className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                            onClick={() => setModals({
                                ...modals,
                                showImmunizationCardModal:
                                    !modals.showImmunizationCardModal,
                            })}
                        >
                            Generate card
                        </button>
                    </div>
                )
            )}

            {/* {!opdState.showPatientAntenatalRecords && type === 'nurse_antenatal' && (
                <div className="flex items-start">
                    <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                        onClick={() => setModals({
                            ...modals,
                            showBookAntenatalModal:
                                !modals.showBookAntenatalModal,
                        })}
                    >
                        Book patient +
                    </button>
                </div>
            )} */}

            {/* {opdState.showPatientAntenatalRecords && type === 'nurse_antenatal' && (
                <div className="flex items-start">
                    <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                    // onClick={() => setModals({
                    //     ...modals,
                    //     showBookAntenatalModal:
                    //         !modals.showBookAntenatalModal,
                    // })}
                    >
                        Generate Card
                    </button>
                </div>
            )} */}
            {!opdState.showPatientAntenatalRecords &&
                type === 'nurse_antenatal' && (
                    <div className="flex items-start">
                        <button
                            type="button"
                            className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                            onClick={() => setModals({
                                ...modals,
                                showBookAntenatalModal:
                                    !modals.showBookAntenatalModal,
                            })}
                        >
                            Book patient +
                        </button>
                    </div>
                )
            }
            {opdState.showPatientAntenatalRecords &&
                type === 'nurse_antenatal' && (
                    <div className="flex items-start">
                        <button
                            type="button"
                            className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                            onClick={() => setModals({
                                ...modals,
                                showGenerateAntenatalCardModal:
                                    !modals.showGenerateAntenatalCardModal,
                            })}
                        >
                            Generate Card
                        </button>
                    </div>
                )
            }

            {props.showBirthRecordTable &&
                (type === 'nurse_birth_death_records' && (
                    <div className="flex items-start">
                        <button
                            type="button"
                            className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                            onClick={() => {
                                setModals({ ...modals, showNurseAddPatientModal: !modals.showNurseAddPatientModal })
                            }}
                        >
                            Add Patient
                        </button>
                    </div>
                ))
            }

            {!props.showBirthRecordTable &&
                (type === 'nurse_birth_death_records' && (
                    <div className="flex items-start">
                        <button
                            type="button"
                            className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                            onClick={() => setModals({
                                ...modals,
                                showNursesAddDeathRecordModal:
                                    !modals.showNursesAddDeathRecordModal,
                            })}
                        >
                            Add Death Record
                        </button>
                    </div>
                ))
            }

            {type === 'settings' && (
                <Fragment>
                    {appState.showSettingsGateway && (
                        <div className="flex justify-between space-x-5 items-center">
                            <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-teal-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => setModals({ ...modals, showAdminAddGatewaySettingModal: !modals.showAdminAddGatewaySettingModal })}
                            >
                                Add Gateway
                            </button>
                        </div>
                    )}
                    {appState.showSettingsSms && (
                        <div className="flex justify-between space-x-5 items-center">
                            <button
                                type="button"
                                className="inline-block px-6 py-2.5 bg-teal-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                onClick={() => setModals({ ...modals, showTopUpSMSUnitsModal: !modals.showTopUpSMSUnitsModal })}
                            >
                                Topup
                            </button>
                        </div>
                    )}
                </Fragment>
            )}

        </div >
    )
}

export default PageTitle