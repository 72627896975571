/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PageTitle, PharmacistDrugsCategoryTable } from '../../components'
import { getDrugCategories } from '../../state/actions/pharmacist.actions'


const PharmcistDrugsCategory = () => {
	const dispatch = useDispatch()

	const { drugCategories } = useSelector(state => state.pharmacist)

	const [showList, setShowList] = useState(true)

	useEffect(() => {
		dispatch(getDrugCategories())
	}, [])

	return (
		<div>
			<PageTitle type={'pharmacist-drugs-category'} />

			<div className="space-y-5">
				<PharmacistDrugsCategoryTable
					data={drugCategories}
					title={'Drug Categories'}
					showList={showList}
					setShowList={setShowList}
				/>
			</div>
		</div>
	)
}

export default PharmcistDrugsCategory