import React, { Fragment, useState } from 'react'
import { FaBed } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import Pagination from '../../utils/Pagination'
import { useStateContext } from '../../../state/context'
import { setRecordsViewWardReport } from '../../../state/actions/actions'
import { useDispatch } from 'react-redux'

const WardReportTable = ({ ViewWardReportTable, setViewWardReportTable }) => {
    const { ward_report } = useSelector((state) => state.root)
    const { all_ward_reports } = useSelector((state) => state.records)
    const { modals, setModals } = useStateContext()
    const dispatch = useDispatch()

    const [currentData, setCurrentData] = useState([])

    return (
        <Fragment>
            <div className='py-2'>
                <button
                    type='button'
                    className='justify-end px-6 py-2.5 my-2 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease'
                    onClick={() => setViewWardReportTable(false)}
                >
                    Back
                </button>
                <div className='flex justify-between items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1'>
                    <div className='flex justify-start items-center space-x-4'>

                        <h4 className='text-dark-blue text-[14px] font-bold italic'>
                            {ward_report?.name} Ward report
                        </h4>
                        <FaBed className='text-dark-blue' size={20} />
                    </div>

                </div>
                <table className='border rounded w-full cursor-default'>
                    <thead className=''>
                        <tr>
                            <th
                                scope='col'
                                className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                            >
                                S/N
                            </th>
                            {/* <th
                                scope='col'
                                className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
                            >
                                Ward name
                            </th> */}
                            <th
                                scope='col'
                                className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
                            >
                                Reported by
                            </th>
                            <th
                                scope='col'
                                className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
                            >
                                Date
                            </th>
                            <th
                                scope='col'
                                className='text-sm font-medium text-gray-900 py-4 text-left'
                            >
                                View Report
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentData?.map((report, index) => (
                            <tr className='' key={index}>
                                <td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
                                    {index + 1}
                                </td>
                                {/* <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                    {report?.ward.name}
                                </td> */}
                                <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                    {report?.reported_by?.first_name} {report?.reported_by?.last_name}
                                </td>
                                <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                    {report?.created_at.slice(0, 10)}
                                </td>
                                {/* <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                    {report?.report}
                                </td> */}
                                <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'

                                >
                                    <button
                                        className={`bg-slate-600  text-white border border-slate-400 inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                                        onClick={() => {
                                            dispatch(setRecordsViewWardReport({ data: report }))
                                            setModals({
                                                ...modals,
                                                showViewWardReportModal:
                                                    !modals.showViewWardReportModal,
                                            })
                                        }


                                        }
                                    >
                                        View report
                                    </button>
                                </td>


                            </tr>
                        ))}
                    </tbody>
                </table>

                <Pagination
                    data={all_ward_reports}
                    currentData={currentData}
                    setCurrentData={setCurrentData}
                />
            </div>
        </Fragment>
    )
}

export default WardReportTable