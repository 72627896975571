/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FiRadio } from 'react-icons/fi'
import { FaPencilAlt, FaTrash } from 'react-icons/fa'

import { useStateContext } from '../../state/context'
import { setDirectorate } from '../../state/actions/actions'
import Pagination from '../utils/Pagination'


const AdminDirectoratesTable = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()
    const { directorates } = useSelector((state) => state.admin)

    const [data, setData] = useState([])
    const [currentData, setCurrentData] = useState([])
    const [config, setConfig] = useState({ filter_value: '' })

    useEffect(() => {
        setData(directorates)
    }, [directorates])

    useEffect(() => {
        if (config?.filter_value?.length > 0) {
            setData([...data?.filter(data => data?.directorate_name.includes(config?.filter_value))])
        } else {
            setData(directorates)
        }
    }, [config?.filter_value])


    return (
        <Fragment>
            <div className="flex justify-start items-center space-x-4 bg-gray-200 p-1">
                <h4 className="text-sidebar text-[14px] font-bold">
                    Directorates
                </h4>
                <FiRadio className="text-sidebar" size={20} />

                <div className="flex justify-end w-full">
                    <input
                        type="text"
                        name='filter_value'
                        placeholder='Search'
                        className="placeholder:text-[12px] text-[12px] font-normal px-4 outline-none active:outline-none focus:outline-none"
                        onChange={(e) => setConfig({ ...config, [e.target.name]: e.target.value })}
                    />
                </div>
            </div>
            <table className="w-full border border-gray-200">
                <thead className="">
                    <tr>
                        <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 pr-[8em] py-4 text-left"
                        >
                            S/N
                        </th>
                        <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 pr-[18em] py-4 text-left"
                        >
                            Name
                        </th>
                        <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 pr-[15em] py-4 text-left"
                        >
                            Status
                        </th>
                        <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 py-4 text-left"
                        >
                            
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {currentData?.map((item, index) => (
                        <tr key={`${item.id}`}>
                            <td className="px-1 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                {index + 1}
                            </td>
                            <td className="text-sm text-gray-900 font-semibold py-2 whitespace-nowrap">
                                {item.directorate_name}
                            </td>
                            <td className="text-sm text-gray-900 font-semibold py-2 whitespace-nowrap">
                                {item.status ? "Active" : "Inactive"}
                            </td>
                            <td className=" flex text-2xl space-x-4 text-gray-900 font-semibold py-2 whitespace-nowrap">
                                <FaPencilAlt
                                    size={20}
                                    className='text-green-600 cursor-pointer'
                                    onClick={() => {
                                        dispatch(setDirectorate({ data: item }))
                                        setModals({ ...modals, showUpdateDirectorateModal: !modals.showUpdateDirectorateModal })
                                    }}
                                />
                                <FaTrash
                                    onClick={() => {
                                        dispatch(setDirectorate({ data: item }))
                                        setModals({ ...modals, showDeleteDirectorateModal: !modals.showDeleteDirectorateModal })
                                    }}
                                    className="text-orange-400 cursor-pointer"
                                    size={20}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>


            <Pagination
                data={data}
                currentData={currentData}
                setCurrentData={setCurrentData}
            />
        </Fragment>
    )
}

export default AdminDirectoratesTable