import { useDispatch } from 'react-redux'
import { FaWallet } from 'react-icons/fa'
import { useStateContext } from '../../state/context'
import { setBilling, setCurrentPrescriptionInvoice, setCurrentPrescriptionInvoices } from '../../state/actions/actions'
import PaginationButtons from '../utils/PaginationButtons'
import { AiFillEye } from 'react-icons/ai'


const PharmacistBillsDispenseTable = ({ data, showList, setShowList, title, pages, currentPage, handlePagination }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    return (
        <div className="flex justify-between items-center w-full px-1">
            <div className="flex flex-col w-full cursor-pointer">
                <div className="py-2">
                    <div
                        className="flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1"
                        onClick={() => setShowList(!showList)}
                    >
                        <h4 className="text-dark-blue text-[14px] font-bold">
                            {title}
                        </h4>
                        <FaWallet className="text-dark-blue" size={20} />
                    </div>
                    {showList && (
                        <table className="border rounded w-full cursor-default">
                            <thead className="">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] py-4 text-left"
                                    >
                                        Invoice ID
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Patient ID
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Drugs
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Status
                                    </th>
                                    {/* <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >

                                    </th> */}
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((item, index) => (
                                    <tr
                                        key={`${item.id}`}
                                    >
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.invoice_number}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.invoice_patient_id}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.invoice_drugs.map(((item, index) => (<span key={index}>{item}{'  '}</span>)))}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.is_paid ? (
                                                <span className="bg-teal-500 rounded text-white text-[10px] px-3 py-1 font-medium">Paid</span>
                                            ) : (
                                                <span className="bg-gray-500 rounded text-white text-[10px] px-3 py-1 font-medium">Pending</span>
                                            )}

                                        </td>
                                        {/* <td className=" flex text-2xl space-x-4 text-gray-900 font-semibold py-2 whitespace-nowrap">
                                            {item.is_paid && (
                                                <button
                                                    type="button"
                                                    className="inline-block px-2 py-2 bg-yellow-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                    onClick={() => {
                                                        dispatch(setCurrentPrescriptionInvoice({ data: item }))
                                                        setModals({ ...modals, showPharmacistDispenseDrugModal: !modals.showPharmacistDispenseDrugModal })
                                                    }}
                                                >
                                                    Dispense
                                                </button>
                                            )}
                                        </td> */}
                                        <td>
                                            {item.is_paid && (
                                                <button
                                                    type="button"
                                                    className="inline-block px-4 py-2 bg-teal-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                    onClick={() => {
                                                        dispatch(setCurrentPrescriptionInvoice({ data: item }))
                                                        setModals({ ...modals, showPharmacistViewInvoiceModal: !modals.showPharmacistViewInvoiceModal })
                                                    }}
                                                >
                                                    View
                                                </button>
                                                // <AiFillEye
                                                //     size={20}
                                                //     className='text-green-600 cursor-pointer'
                                                //     onClick={() => {
                                                //     }}
                                                // />
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            {/* <PaginationButtons
                                pages={pages}
                                currentPage={currentPage}
                                handlePagination={handlePagination}
                            /> */}
                        </table>
                    )}
                </div>
            </div>
        </div >
    )
}

export default PharmacistBillsDispenseTable