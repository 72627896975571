import React, { Fragment } from 'react'
import PatientPayInvoiceModal from '../../modals/patient/PatientPayInvoiceModal'
import PatientPayMulitpleInvoiceModal from '../../modals/patient/PatientPayMulitpleInvoiceModal'

import PatientViewInvoiceModal from '../../modals/patient/PatientViewInvoiceModal'


const PatientFormModals = (props) => {
    return (
        <Fragment>
            {props.type === "patient__view__labinvoice" && (
                <PatientViewInvoiceModal />
            )}
            {props.type === "patient__pay__invoice" && (
                <PatientPayInvoiceModal />
            )}

            {props.type === 'patient__paymultiple__invoice__modal' && (
                <PatientPayMulitpleInvoiceModal />
            )}
        </Fragment>
    )
}


export default PatientFormModals