/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { getAllDepartments, getAllDirectorates } from "../../state/actions/admin.actions"
import { AdminDepartmentsTable, AdminDirectoratesTable, PageTitle } from "../../components"


const AdminDepartments = ({ showAddDirectorateModal, setShowAddDirectorateModal, showAddDepartmentModal, setShowAddDepartmentModal, ...props }) => {
	const dispatch = useDispatch()

	const [showDirectorateDropDown, setShowDirectorateDropDown] = useState(false)
	const [directorateDropdownItem, setDirectorateDropdownItem] = useState("Directorates")

	useEffect(() => {
		dispatch(getAllDirectorates())
		dispatch(getAllDepartments())
	}, [directorateDropdownItem, showDirectorateDropDown])


	return (
		<div>
			<PageTitle
				type={"admin-departments"}
				showDirectorateDropDown={showDirectorateDropDown}
				setShowDirectorateDropDown={setShowDirectorateDropDown}
				directorateDropdownItem={directorateDropdownItem}
				setDirectorateDropdownItem={setDirectorateDropdownItem}
				showAddDirectorateModal={showAddDirectorateModal}
				setShowAddDirectorateModal={setShowAddDirectorateModal}
				showAddDepartmentModal={showAddDepartmentModal}
				setShowAddDepartmentModal={setShowAddDepartmentModal}
			/>

			<div className="flex justify-between items-center w-full px-1">
				<div className="flex flex-col w-full">
					{directorateDropdownItem === 'Directorates' && (
						<AdminDirectoratesTable />
					)}
					{directorateDropdownItem === 'Departments' && (
						<AdminDepartmentsTable />
					)}
				</div>
			</div>
		</div>
	)
}

export default AdminDepartments
