import React, { Fragment } from 'react'

import RecordsWardModal from "../../modals/records/RecordsWardModal";
import RecordsAddPatientModal from "../../modals/records/RecordsAddPatientModal";
import RecordsAssignDoctorModal from "../../modals/records/RecordsAssignDoctorModal";
import RecordsGenerateCardModal from "../../modals/records/RecordsGenerateCardModal";
import RecordsBookAppointmentModal from "../../modals/records/RecordsBookAppointmentModal";
import RecordsGenerateInvoiceModal from "../../modals/records/RecordsGenerateInvoiceModal";
import { RecordsAddBirthModal, RecordsAddDeathModal, RecordsViewInvoiceModal } from "../..";
import RecordsViewWardReportModal from '../../modals/records/RecordsViewWardReportModal';


const RecordsFormModals = (props) => {
    return (
        <Fragment>
            {props.type === "records_add_patient" && (
                <RecordsAddPatientModal />
            )}
            {props.type === "records__generate__card" && (
                <RecordsGenerateCardModal />
            )}

            {props.type === "records__view__invoice" && (
                <RecordsViewInvoiceModal />
            )}

            {props.type === "records__assign__doctor" && (
                <RecordsAssignDoctorModal />
            )}


            {props.type === "records__book__appointment" && (
                <RecordsBookAppointmentModal />
            )}
            {props.type === "records__change__doctor" && (
                <RecordsBookAppointmentModal />
            )}

            {props.type === "records__generate__invoice" && (
                <RecordsGenerateInvoiceModal />
            )}

            {props.type === "records__add__birthrecord" && (
                <RecordsAddBirthModal />
            )}

            {props.type === "records__view__birthrecord" && (
                <RecordsAddBirthModal />
            )}

            {props.type === "records__add__deathrecord" && (
                <RecordsAddDeathModal />
            )}

            {props.type === "records__view__deathrecord" && (
                <RecordsAddDeathModal />
            )}

            {props.type === "records__view__ward" && (
                <RecordsWardModal />
            )}
            {props.type === "records__view__ward_report" && (
                <RecordsViewWardReportModal />
            )}

        </Fragment>
    )
}

export default RecordsFormModals