/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState, useRef } from 'react'
import { toast } from 'react-toastify'
import { GrWebcam } from 'react-icons/gr'
import { useNavigate } from 'react-router-dom'
import { AiOutlineCloudUpload, } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'

import { countries } from '../../data'
import { Logo } from '../../assets'
import { ButtonLoader, CapturePhotoModal } from '../../components'
import { useStateContext } from '../../state/context'
import { getUserProfile, updateUserProfile } from '../../state/actions/auth.actions'


const UpdateProfile = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const imageRef = useRef()

    const { modals, setModals } = useStateContext()
    const { profile, authLoading } = useSelector((state) => state.auth)

    const [formData, setFormData] = useState({
        // nhis_number: '',
        date_of_birth: '',
        marital_status: '',
        blood_group: '',
        genotype: '',
        country: '',
        state: '',
        nhis_number: '',
        staff_number: '',
        lga_or_province: '',
        patient_phone: '',
        address: '',
        next_kin_name: '',
        next_kin_phone_number: '',
        next_kin_address: '',
        gender: '',
        profile_photo: '',
        is_profile_updated: true,
        webcamcapture: false,
        imageupload: false,
    })

    useEffect(() => {
        dispatch(getUserProfile())
    }, [])

    useEffect(() => {
        if (profile?.is_profile_updated) {
            return navigate('/dashboard')
        }
    }, [profile])

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }

    const handleImageChange = (e) => {
        setFormData({ ...formData, profile_photo: e.target.files[0], photo_display: URL.createObjectURL(e.target.files[0]), imageupload: !formData.imageupload })
    };

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!formData.marital_status) return toast.warning('Marital status is required!')
        if (!formData.blood_group) return toast.warning('Blood group is required!')
        if (!formData.genotype) return toast.warning('Genotype is required!')
        if (!formData.country) return toast.warning('Country is required!')
        if (!formData.state) return toast.warning('State is required!')
        if (!formData.lga_or_province) return toast.warning('LGA or province is required!')
        if (!formData.date_of_birth) return toast.warning('Date of birth is required!')
        if (!formData.patient_phone) return toast.warning('Phone number is required!')
        if (!formData.staff_number) return toast.warning('Staff ID is required!')
        if (!formData.address) return toast.warning('Address is required!')
        if (!formData.next_kin_name) return toast.warning('Next of kin is required!')
        if (!formData.next_kin_phone_number) return toast.warning('Next of kin phone number is required!')
        if (!formData.next_kin_address) return toast.warning('Next of kin address is required!')
        if (!formData.gender) return toast.warning('Gender is required!')
        if (!formData.profile_photo) return toast.warning('Profile photo is required!')
        if (formData.profile_photo && typeof (formData.profile_photo) !== 'object') return toast.warning('Invalid profile photo!')

        dispatch(updateUserProfile({ formData, toast, navigate }))
    }

    return (
        <Fragment>
            {modals.showCapturePhotoModal && (
                <CapturePhotoModal
                    formData={formData}
                    setFormData={setFormData}
                />
            )}
            <div className="main__login relative min-h-screen text-gray-800 antialiased px-4 py-6 flex flex-col justify-center sm:py-0 font-poppins">
                {/* <div className="absolute right-[220px] top-[40px]">
                    <div className="w-[100px] h-[100px] bg-white  bg-opacity-50 backdrop-blur-sm rounded-full"></div>
                </div>
                <div className="absolute right-[250px] top-[60px]">
                    <div className="w-[100px] h-[100px] bg-white bg-opacity-50 backdrop-blur-sm rounded-full"></div>
                </div> */}
                <div className="relative py-3 w-full sm:w-[900px] mx-auto text-center sm:auto">
                    <div className="mt-4 bg-white shadow-md">
                        <div className="flex justify-between items-center py-4">
                            <div className="px-10">
                                <div className="space-y-10 mb-4">
                                    <div className="flex items-center space-x-[80px]">
                                        <img src={Logo} alt="logo" />
                                        <p className='font-bold text-[25px]'>Update Profile</p>
                                    </div>
                                </div>

                                <div className="flex justify-between w-full">
                                    <div className="space-y-2 mr-[48px] w-[50%]">
                                        <div className="mb-6 md:mb-0 w-full">
                                            <div className="relative">
                                                <select
                                                    className="appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state"
                                                    name='marital_status'
                                                    onChange={(e) => handleChange(e, e.target.name)}
                                                >
                                                    <option>Marital Status</option>
                                                    <option value={'Single'}>Single</option>
                                                    <option value={'Married'}>Married</option>
                                                    <option value={'Divorced'}>Divorced</option>
                                                    <option value={'Widowed'}>Widowed</option>
                                                </select>
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-6 md:mb-0 w-full">
                                            <div className="relative">
                                                <select
                                                    className="appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state"
                                                    name='blood_group'
                                                    onChange={(e) => handleChange(e, e.target.name)}
                                                >
                                                    <option>Blood Group</option>
                                                    <option value={'A+'}>A+</option>
                                                    <option value={'A-'}>A-</option>
                                                    <option value={'O+'}>0+</option>
                                                    <option value={'B+'}>B+</option>
                                                    <option value={'B-'}>B-</option>
                                                    <option value={'O+'}>O+</option>
                                                    <option value={'AB+'}>AB+</option>
                                                    <option value={'AB-'}>AB-</option>
                                                </select>
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-6 md:mb-0">
                                            <div className="relative">
                                                <select
                                                    className="appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state"
                                                    name='genotype'
                                                    onChange={(e) => handleChange(e, e.target.name)}
                                                >
                                                    <option>Genotype</option>
                                                    <option value={'AA'}>AA</option>
                                                    <option value={'AS'}>AS</option>
                                                    <option value={'AC'}>AC</option>
                                                    <option value={'SC'}>SC</option>
                                                    <option value={'CC'}>CC</option>
                                                    <option value={'SS'}>SS</option>
                                                </select>
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-6 md:mb-0">
                                            <div className="relative">
                                                <select
                                                    className="appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state"
                                                    name='country'
                                                    onChange={(e) => handleChange(e, e.target.name)}
                                                >
                                                    <option>Country</option>
                                                    {countries.map((country, index) => (
                                                        <option
                                                            key={index}
                                                            value={country.title}
                                                        >
                                                            {country.title}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                name="state"
                                                placeholder="State"
                                                className="border rounded w-full h-5 px-5 pl-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                name="lga_or_province"
                                                placeholder="LGA or Province"
                                                className="border rounded w-full h-5 px-5 pl-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                        <div className="relative">
                                            <label className='text-[10px] text-dark-blue absolute left-0 top-[-4px] font-bold'>Date of Birth</label>
                                            {/* <AiOutlineCalendar
                                                size={20}
                                                color={'rgba(40, 51, 66, 0.8)'}
                                                className='absolute top-[16px] left-3'
                                            /> */}
                                            <input
                                                type="date"
                                                name="date_of_birth"
                                                placeholder="Date of birth"
                                                className="border w-full rounded h-5 px-5 pl-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                        <div className="relative">
                                            {/* <AiOutlinePhone
                                                size={20}
                                                color={'rgba(40, 51, 66, 0.8)'}
                                                className='absolute top-[16px] left-3'
                                            /> */}
                                            <input
                                                type="text"
                                                name="patient_phone"
                                                placeholder="Phone Number"
                                                className="border rounded w-full h-5 px-5 pl-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                    </div>

                                    <div className="space-y-1 w-[50%]">
                                        <div className="relative">
                                            {/* <AiOutlineIdcard
                                                size={20}
                                                color={'rgba(40, 51, 66, 0.8)'}
                                                className='absolute top-[8px] left-3'
                                            /> */}
                                            <input
                                                type="text"
                                                name="nhis_number"
                                                placeholder="NHIS ID"
                                                className="border rounded w-full h-5 px-5 pl-4 py-5 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div className="relative">
                                            {/* <AiOutlineIdcard
                                                size={20}
                                                color={'rgba(40, 51, 66, 0.8)'}
                                                className='absolute top-[16px] left-3'
                                            /> */}
                                            <input
                                                type="text"
                                                name="staff_number"
                                                placeholder="Staff ID"
                                                className="border rounded w-full h-5 px-5 pl-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div className="relative">
                                            {/* <GoLocation
                                                size={20}
                                                color={'rgba(40, 51, 66, 0.8)'}
                                                className='absolute top-[16px] left-3'
                                            /> */}
                                            <input
                                                type="text"
                                                name="address"
                                                placeholder="Address"
                                                className="border rounded w-full h-5 px-5 pl-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div className="relative">
                                            {/* <AiOutlineUser
                                                size={20}
                                                color={'rgba(40, 51, 66, 0.8)'}
                                                className='absolute top-[16px] left-3'
                                            /> */}
                                            <input
                                                type="text"
                                                name="next_kin_name"
                                                placeholder="Next of kin"
                                                className="border rounded w-full h-5 px-5 pl-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div className="relative">
                                            {/* <AiOutlinePhone
                                                size={20}
                                                color={'rgba(40, 51, 66, 0.8)'}
                                                className='absolute top-[16px] left-3'
                                            /> */}
                                            <input
                                                type="text"
                                                name="next_kin_phone_number"
                                                placeholder="Next of kin phone"
                                                className="border rounded w-full h-5 px-5 pl-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div className="relative">
                                            {/* <GoLocation
                                                size={20}
                                                color={'rgba(40, 51, 66, 0.8)'}
                                                className='absolute top-[16px] left-3'
                                            /> */}
                                            <input
                                                type="text"
                                                name="next_kin_address"
                                                placeholder="Next of kin address"
                                                className="border rounded w-full h-5 px-5 pl-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div className="flex">
                                            <div className="flex items-center mr-10">
                                                <input
                                                    type="radio"
                                                    name='gender'
                                                    value={'Male'}
                                                    className="mr-2"
                                                    onChange={(e) => handleChange(e, e.target.name)}
                                                />
                                                <p>Male</p>
                                            </div>
                                            <div className="flex items-center ml-10">
                                                <input
                                                    type="radio"
                                                    name='gender'
                                                    value={'Female'}
                                                    className="mr-2"
                                                    onChange={(e) => handleChange(e, e.target.name)}
                                                />
                                                <p>Female</p>
                                            </div>
                                        </div>

                                        <div className="flex justify-between items-center">
                                            <div className="border-2 border-dashed rounded cursor-pointer">
                                                <div className="flex flex-col items-center justify-center py-4 px-2 space-y-3">

                                                    {typeof (formData.profile_photo) === 'object' && (
                                                        <Fragment>
                                                            <img
                                                                src={formData.photo_display}
                                                                className='w-[10em] h-[6.5em]'
                                                                alt=""
                                                                onClick={() => {
                                                                    setFormData({ ...formData, imageupload: !formData.imageupload })
                                                                    imageRef.current.click()
                                                                }}
                                                            />
                                                            <input
                                                                ref={imageRef}
                                                                type="file"
                                                                name='profile_photo'
                                                                accept="image/jpg, image/jpeg, image/png"
                                                                onChange={(e) => handleImageChange(e)}
                                                                className='hidden'
                                                            />
                                                        </Fragment>
                                                    )}

                                                    {typeof (formData.profile_photo) !== 'object' && (
                                                        <Fragment>
                                                            <div
                                                                className="flex flex-col justify-center items-center"
                                                                onClick={() => imageRef.current.click()}
                                                            >

                                                                <AiOutlineCloudUpload
                                                                    size={40}
                                                                />
                                                                <p className='text-[11px] text-gray-400'>Upload your profile image</p>
                                                                <input
                                                                    ref={imageRef}
                                                                    type="file"
                                                                    name='profile_photo'
                                                                    accept="image/jpg, image/jpeg, image/png"
                                                                    onChange={(e) => handleImageChange(e)}
                                                                    className='hidden'
                                                                />
                                                            </div>
                                                        </Fragment>
                                                    )}

                                                </div>
                                            </div>

                                            <p>OR</p>

                                            <div className="border-2 border-dashed rounded">
                                                <div
                                                    onClick={() => {
                                                        setFormData({ ...formData, webcamcapture: !formData.webcamcapture })
                                                        setModals({ ...modals, showCapturePhotoModal: !modals.showCapturePhotoModal })
                                                    }}
                                                    className="flex flex-col items-center justify-center py-4 px-2 space-y-3 cursor-pointer"
                                                >
                                                    {typeof (formData.profile_photo) === 'object' && (
                                                        <img
                                                            src={formData.photo_display}
                                                            className='w-[10em] h-[6.5em]'
                                                        />
                                                    )}
                                                    {typeof (formData.profile_photo) !== 'object' && (
                                                        <Fragment>
                                                            <GrWebcam
                                                                size={30}
                                                            />
                                                            <p className='text-[11px] text-gray-400'>Upload your profile image</p>
                                                        </Fragment>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="flex flex-col justify-between items-center space-y-5 mt-4">
                                    <button
                                        type="submit"
                                        className="mt-4 w-[375px] bg-dark-yellow text-white py-2 px-6 rounded-md hover:bg-dark-yellow"
                                        onClick={handleSubmit}
                                    >
                                        {!authLoading ? (
                                            'Update Profile'
                                        ) : (
                                            <ButtonLoader />
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default UpdateProfile