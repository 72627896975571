import React, { Fragment, useState } from 'react'
import { FaPencilAlt } from 'react-icons/fa'
import { FiUpload } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../state/context'


const HospitalInformationTable = ({ data }) => {
    const { modals, setModals } = useStateContext()

    const { hospital_config } = useSelector(state => state.admin)

    return (
        <div className="flex justify-between items-center w-full">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="">
                    <Fragment>
                        <table className="border-b border-l border-r rounded w-full cursor-default">
                            <thead className="">
                                <tr className='bg-gray-100'>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        Name
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        Email
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        Logo
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        Address
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* {currentData?.map((setting, index) => ( */}
                                <tr >
                                    <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                        1
                                    </td>
                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                        {hospital_config?.name}
                                    </td>
                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                        {hospital_config?.email}
                                    </td>
                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                        <img
                                            src={`${process.env.REACT_APP_API_ROOT}${hospital_config?.logo}`}
                                            alt=""
                                            className='w-[30px] h-[30px] rounded-full'
                                        />
                                    </td>
                                    <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                        {hospital_config?.address?.slice(0, 50)}...
                                    </td>
                                    <td className="flex space-x-4 text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                        <FiUpload
                                            size={20}
                                            className='text-yellow-500 cursor-pointer'
                                            onClick={() => {
                                                // dispatch(setCurrentLaboratoryData({ data: setting }))
                                                setModals({ ...modals, showAdminUpdateHospitalLogoModal: !modals.showAdminUpdateHospitalLogoModal })
                                            }}
                                        />
                                        <FaPencilAlt
                                            size={20}
                                            className='text-green-600 cursor-pointer'
                                            onClick={() => {
                                                // dispatch(setCurrentLaboratoryData({ data: setting }))
                                                setModals({ ...modals, showAdminUpdateHospitalInfoModal: !modals.showAdminUpdateHospitalInfoModal })
                                            }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </Fragment>
                </div>
            </div>
        </div >
    )
}

export default HospitalInformationTable
