import React, { useEffect } from 'react'
import { BsEye } from 'react-icons/bs'
import { useSelector, useDispatch } from 'react-redux'
import { useStateContext } from '../../../state/context'
import { getAPatientLabourRecord } from '../../../state/actions/nurse.actions'
import { setNursesDeleteDetails } from '../../../state/actions/actions'
import { setFollowUpRecords } from '../../../state/actions/actions'


const LabourSummaryTable = () => {
    const { opdState, setOpdState } = useStateContext()
    const dispatch = useDispatch()
    const { modals, setModals } = useStateContext()
    const { set_patient_id } = useSelector((state) => state.root)
    const { all_patient_labour_record, } = useSelector((state) => state.nurse)
    useEffect(() => {
        dispatch(getAPatientLabourRecord({ id: set_patient_id?.id }))
    }, [modals.showAddPatientLabourSummaryRecordModal, modals.showDeleteLabourSummaryModal])

    return (
        <div className='flex flex-col'>
            <div className='flex justify-end space-x-2'>
                <button
                    type="button"
                    className=" px-6 py-2.5 bg-sky-600 text-white w-[100px] mb-2 font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                    onClick={() => (
                        setModals({
                            ...modals,
                            showAddPatientLabourSummaryRecordModal: !modals.showAddPatientLabourSummaryRecordModal

                        })
                    )}
                >
                    Add +
                </button>
                <button
                    type="button"
                    className=" px-6 py-2.5 bg-dark-green text-white w-[100px] mb-2 font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                    onClick={() => (
                        setOpdState({
                            ...opdState,
                            showPatientAntenatalRecords: false

                        })
                    )}
                >
                    Back
                </button>

            </div>
            <div>

                <div className='flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1'>

                    <h4 className='text-dark-blue text-[16px] font-semibold'>
                        Labour summary - {set_patient_id?.first_name} {set_patient_id?.last_name} [{set_patient_id?.patient_number}]
                    </h4>
                </div>


                <table className='border rounded w-full cursor-default'>

                    <thead className=''>
                        <tr>
                            <th
                                scope='col'
                                className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                            >
                                S/N
                            </th>

                            <th
                                scope='col'
                                className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                            >
                                Type
                            </th>
                            <th
                                scope='col'
                                className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
                            >
                                Place
                            </th>


                            <th
                                scope='col'
                                className='text-sm font-medium text-gray-900 py-4 text-left'
                            >
                                Duration
                            </th>
                            <th
                                scope='col'
                                className='text-sm font-medium text-gray-900 py-4 text-left'
                            >
                                Alive or S.B
                            </th>
                            <th
                                scope='col'
                                className='text-sm font-medium text-gray-900 py-4 text-left'
                            >
                                Date
                            </th>
                            <th
                                scope='col'
                                className='text-sm font-medium text-gray-900 py-4 text-left'
                            >
                                Actions
                            </th>


                        </tr>
                    </thead>

                    <tbody>
                        {all_patient_labour_record?.map((item, index) => (
                            <tr
                                className='relative'
                                key={index}

                            >
                                <td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
                                    1
                                </td>
                                <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                    {item.type_of_labour}      </td>
                                <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                    {item.place}
                                </td>
                                <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                    {item.duration}
                                </td>
                                <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                    {item.baby_dead_alive}
                                </td>

                                <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                    {item.date}
                                </td>


                                <td className=' text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                    <div className='flex space-x-4 relative '>

                                        <BsEye
                                            color='blue'
                                            className='cursor-pointer'
                                            onClick={() => {
                                                const data = {
                                                    type_of_labour: item?.type_of_labour,
                                                    place: item?.place,
                                                    duration: item?.duration,
                                                    baby_dead_alive: item?.baby_dead_alive,
                                                    date: item?.date,
                                                    weight: item?.birth_weight,
                                                    complications: item?.complications,

                                                }
                                                dispatch(setFollowUpRecords({ data }))
                                                setModals({
                                                    ...modals,
                                                    showPatientLabourSummaryModal:
                                                        !modals.showPatientLabourSummaryModal,
                                                })

                                            }}
                                            size={35}

                                        />

                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </table>
            </div>
        </div>
    )
}

export default LabourSummaryTable