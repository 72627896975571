import React, { Fragment, useState, useEffect } from 'react'
import { FaPencilAlt } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import { useStateContext } from '../../../state/context'
import { useDispatch, useSelector } from 'react-redux'
import { setLabSampleTest } from '../../../state/actions/actions'
import Pagination from '../../../components/utils/Pagination'

const LaboratorySampleTestTable = ({ data }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    return (
        <tbody>
            {data?.map((test, index) => (
                <tr
                    className='relative' key={index}

                >
                    <td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
                        {index + 1}
                    </td>
                    <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                        {test.investigation_types}
                    </td>
                    <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                        {test.sample_type}
                    </td>
                    <td className=' text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                        <div className='flex space-x-4 relative '>
                            <FaPencilAlt
                                size={24}
                                className='text-green-600 cursor-pointer '
                                onClick={() => {
                                    const data = {
                                        title: test.sample_type,
                                        id: test.id

                                    }
                                    dispatch(setLabSampleTest({ data }))
                                    setModals({
                                        ...modals,
                                        showUpdateLaboratorySamplesModal:
                                            !modals.showUpdateLaboratorySamplesModal,
                                    })
                                }}
                            />

                            <MdDelete
                                size={24}
                                className='text-orange-600 cursor-pointer mr-4'
                                onClick={() => {
                                    const data = {
                                        title: test.sample_type,
                                        id: test.id


                                    }
                                    dispatch(setLabSampleTest({ data }))
                                    setModals({
                                        ...modals,
                                        showDeleteLaboratorySampleModal:
                                            !modals.showDeleteLaboratorySampleModal,
                                    })
                                }}
                            />
                        </div>
                    </td>
                </tr>
            ))}
        </tbody>

    )
}

export default LaboratorySampleTestTable