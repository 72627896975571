/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'

import Pagination from '../utils/Pagination'
import { useStateContext } from '../../state/context'
import { setCurrentPrescriptions } from '../../state/actions/actions'
import { createMultiplePrescriptionsInvoice, setCurrentMultiplePrescriptions } from '../../state/actions/pharmacist.actions'


const PharmacistSupplyPointPrescriptionsTable = ({ data, showList }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [currentData, setCurrentData] = useState([])
    const [accumulatedList, setAccumulatedList] = useState([])
    const [filterState, setFilterState] = useState({ search: '', prescriptions: [] })

    const handleFilter = () => {
        const filtered = data?.filter(item => {
            return item.patient_id.toLowerCase().includes(filterState.search.toLowerCase())
                || item.patient_prescribed.first_name.toLowerCase().includes(filterState.search.toLowerCase())
                || item.patient_prescribed.last_name.toLowerCase().includes(filterState.search.toLowerCase())
        })

        setFilterState({ ...filterState, prescriptions: filtered })

        if (filterState.search === '') {
            setFilterState({ ...filterState, prescriptions: data })
        }
    }

    useEffect(() => {
        setFilterState({ ...filterState, prescriptions: data })
    }, [data])

    useEffect(() => {
        handleFilter()
    }, [filterState.search])

    return (
        <div className="flex justify-between items-center w-full">
            <div className="flex flex-col w-full cursor-pointer">
                <div className="pb-2">
                    <div className="flex justify-between items-center space-x-4 rounded-t-lg">
                        <input
                            type="text"
                            name="search"
                            placeholder='Search'
                            className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                            onChange={(e) => setFilterState({ ...filterState, [e.target.name]: e.target.value })}
                        />

                        <button
                            type="button"
                            disabled={accumulatedList.length <= 0}
                            className="w-[150px]  mb-2 mt-2 inline-block px-6 py-2 bg-sky-500 text-white font-medium text-[10px] leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                            onClick={() => {
                                const filtered = currentData.filter(item => item.invoice_generated === false)

                                dispatch(setCurrentMultiplePrescriptions({ data: filtered }))
                                setModals({ ...modals, showPharmacistGenerateMultipleInvoiceModal: !modals.showPharmacistGenerateMultipleInvoiceModal })
                            }}
                        >
                            Multiple Invoice
                        </button>
                    </div>
                    {showList && (
                        <table className="border-l border-r border-b rounded w-full cursor-default">
                            <thead className="">
                                <tr className='bg-gray-100'>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >

                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] py-4 text-left"
                                    >
                                        Patient ID
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Patient Name
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] py-4 text-left"
                                    >
                                        Picture
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                    >
                                        Doctor
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >

                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        <input
                                            type="checkbox"
                                            onChange={() => {
                                                if (accumulatedList.length === 0) {
                                                    setAccumulatedList(currentData)
                                                } else {
                                                    setAccumulatedList([])
                                                }
                                            }}
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentData?.map((item, index) => (
                                    <tr
                                        key={`${index}`}
                                        className={`${item.nhis_id ? 'bg-indigo-40 text-gray-900' : 'text-gray-900'}`}
                                    >
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium ">
                                            {index + 1}
                                        </td>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium ">
                                            {!item.invoice_generated && (
                                                <input
                                                    type="checkbox"
                                                    checked={accumulatedList?.find((_item) => _item.id === item.id)}
                                                    onChange={(e) => {
                                                        const myIndex = accumulatedList.findIndex(e => e.id === item.id)
                                                        console.log(e.target.value)
                                                        if (myIndex > -1) {
                                                            setAccumulatedList([...accumulatedList.filter(inv => inv.pkid !== item.pkid)])
                                                        } else {
                                                            setAccumulatedList([...accumulatedList, item])
                                                        }
                                                    }}
                                                />
                                            )}
                                        </td>
                                        <td className={`text-sm  font-light py-2 whitespace-nowrap `}>
                                            {item.patient_id}{item.nhis_id && <span className="text-[10px] font-medium">(NHIS)</span>}
                                        </td>
                                        <td className="text-sm  font-light py-2 whitespace-nowrap">
                                            {item.patient_prescribed?.first_name} {item.patient_prescribed?.last_name}
                                        </td>
                                        <td className="text-sm  font-light py-2 whitespace-nowrap">
                                            <img
                                                src={`${process.env.REACT_APP_API_ROOT}${item.patient_prescribed.profile_photo}`}
                                                alt=""
                                                className='w-[40px] h-[40px] rounded-[50%] mt-[-5px]'
                                            />
                                        </td>
                                        <td className="text-sm  font-light py-2 whitespace-nowrap">
                                            {item?.author?.first_name} {item?.author?.last_name}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.invoice_generated ? (
                                                <div className="w-[100px] flex space-x-2 px-2 h-[25px] justify-center items-center rounded-full bg-gray-100">
                                                    <span className='w-2 h-2 rounded-full bg-teal-400'></span>
                                                    <p className='text-[10px]'>Generated</p>
                                                </div>
                                            ) : (
                                                <button
                                                    type="button"
                                                    className="inline-block px-4 py-2 bg-indigo-600 text-white font-medium text-xs leading-tight uppercase shadow-lg hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                    onClick={() => {
                                                        dispatch(setCurrentPrescriptions({ data: item }))
                                                        setModals({ ...modals, showPharmacistDrugInvoiceModal: !modals.showPharmacistDrugInvoiceModal })
                                                    }}
                                                >
                                                    Generate
                                                </button>
                                            )}
                                        </td>
                                        <td className={` flex text-2xl space-x-4 text-gray-900 font-semibold py-2 whitespace-nowrap`}>
                                            <button
                                                type="button"
                                                className={`inline-block px-4 py-2 border border-teal-600 text-teal-600 font-medium text-xs leading-tight uppercase shadow-lg hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out`}
                                                onClick={() => {
                                                    dispatch(setCurrentPrescriptions({ data: item }))
                                                    setModals({ ...modals, showPharmacistViewPrescriptionModal: !modals.showPharmacistViewPrescriptionModal })
                                                }}
                                            >
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    <Pagination
                        data={filterState.prescriptions}
                        currentData={currentData}
                        setCurrentData={setCurrentData}
                    />
                </div>
            </div>
        </div >
    )
}

export default PharmacistSupplyPointPrescriptionsTable