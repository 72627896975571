import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FaTimesCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { useStateContext } from "../../../state/context";
import { setCurrentPrescriptionInvoice, setCurrentPrescriptions } from "../../../state/actions/actions";
import { createPrescriptionsInvoice, dispenseDrug, getDrugPrices, getPaidPrescriptions, resetPaidPrescriptions } from "../../../state/actions/pharmacist.actions";


const PharmacistViewInvoiceModal = () => {
    const dispatch = useDispatch();

    const { modals, setModals } = useStateContext()

    const { prescriptions_update, prescription_invoice_update, supplypoint_update } = useSelector(state => state.root)
    const { drugs_price, paid_prescriptions, supplypoint_inventory, patient_drugdispensed } = useSelector(state => state.pharmacist)
    const [formData, setFormData] = useState({ price: 0, })
    const [total, setTotal] = useState(0)
    const [invoicePrescriptions, setInvoicePrescriptions] = useState([])

    useEffect(() => {
        const drugs = [], prescriptions = []
        prescriptions_update?.forEach((item) => {
            const drug = item.drugs
            const pkid = parseInt(item.pkid)

            drugs.push(drug)
            prescriptions.push(pkid)
        })

        const params = [...new Set(drugs)]
        setInvoicePrescriptions(prescriptions)

        dispatch(getDrugPrices({ params }))

    }, [prescriptions_update])

    useEffect(() => {
        if (prescription_invoice_update?.pkid) {
            const params = {
                prescription: prescription_invoice_update?.prescription,
            }
            dispatch(getPaidPrescriptions({ params }))
        }
    }, [prescription_invoice_update, patient_drugdispensed])

    const handleChange = (e, name) => {
        setFormData({ ...formData, [name]: e.target.value })
    }

    const calcTotal = () => {
        let sum = 0
        const drugs = Object.keys(formData).filter(item => !item.includes('price'))

        drugs.forEach((data, index) => {
            // console.log(formData[data])
            sum += drugs_price?.filter(a => a.drug_name === data)[0]?.selling_price * formData[data]
        })

        setTotal(sum)
    }


    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className={`w-[800px] bg-white px-[20px] py-[15px]`}>
                <div className="flex flex-col space-y-2">
                    <div className="flex justify-between items-center">
                        <h4 className={`text-sky-500 text-[20px] font-bold capitalize`}>
                            {modals.showPharmacistDrugInvoiceModal ? 'Generate Invoice' : modals.showPharmacistViewInvoiceModal ? 'View Invoice' : 'Drug Dispense'}
                        </h4>
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                dispatch(resetPaidPrescriptions())
                                dispatch(setCurrentPrescriptions({ data: null }))
                                dispatch(setCurrentPrescriptionInvoice({ data: null }))
                                modals.showPharmacistDrugInvoiceModal && setModals({ ...modals, showPharmacistDrugInvoiceModal: !modals.showPharmacistDrugInvoiceModal })
                                modals.showPharmacistDispenseDrugModal && setModals({ ...modals, showPharmacistDispenseDrugModal: !modals.showPharmacistDispenseDrugModal })
                                modals.showPharmacistViewInvoiceModal && setModals({ ...modals, showPharmacistViewInvoiceModal: !modals.showPharmacistViewInvoiceModal })
                            }}
                            size={20}
                        />
                    </div>

                    {/* tables */}
                    <Fragment>
                        <table className="border rounded w-full cursor-default">
                            <thead className="">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-sm font-bold text-gray-900 pr-[4em] pl-4 py-4 text-left bg-sky-200 border-b-2 border-white"
                                    >
                                        Drug
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-bold text-gray-900 pr-[4em] py-4 text-left bg-sky-200 border-b-2 border-white"
                                    >
                                        Route
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-bold text-gray-900 pr-[4em] py-4 text-left bg-yellow-200 border-b-2 border-white"
                                    >
                                        Units
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-bold text-gray-900 pr-[4em] py-4 text-left bg-yellow-200 border-b-2 border-white"
                                    >
                                        Frequency
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-bold text-gray-900 pr-[4em] py-4 text-left bg-purple-400 border-b-2 border-white"
                                    >
                                        Duration/Times
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {paid_prescriptions?.map((item, index) => (
                                    <tr key={`${item.id}`}>
                                        <td className="text-sm text-gray-900 font-light pl-4 py-2 whitespace-nowrap bg-sky-200">
                                            {item.drugs}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap bg-sky-200">
                                            {item.routes}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap bg-yellow-200">
                                            {item.units}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap bg-yellow-200">
                                            {item.frequency}
                                        </td>
                                        <td className="text-sm text-gray-900 font-bold italic py-2 whitespace-nowrap bg-purple-400">
                                            {item.duration}/{item.times}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Fragment>
                </div>
            </div>
        </div>
    );
};

export default PharmacistViewInvoiceModal