import { createSlice } from '@reduxjs/toolkit'

import {
    createAdminPayStackConfig,
    createFlutterWaveConfig,
    createPayStackConfig, getLaboratoryInvoices, getPharmacyInvoices, getRadiologyInvoices,
    getRecordsInvoices, setCurrentLabInvoice, setCurrentPharmacyInvoice, setCurrentRadiologyInvoice,
    setCurrentRecordsInvoice, updateLabInvoice, updateLabInvoiceViaCash, updateLabInvoiceViaPOSTransaction, updatePharmacyInvoice,
    updatePharmacyInvoiceViaCash, updatePharmacyInvoiceViaPOSTransaction, updateRadiologyInvoice, updateRadiologyInvoiceViaCash,
    updateRadiologyInvoiceViaPOSTransaction, updateRecordsInvoice, updateRecordsInvoiceViaCash, updateRecordsInvoiceViaPOSTransaction,
    verifyAdminPayStackPayment, verifyFlutterWavePayment, verifyPayStackPayment, setAccumulatedPharmacyInvoice, UpdateMultipleInvoices, setCurrentInvoice, updateMultipleInvoicesViaOTHERTransaction, setCurrentAllInvoices, updateMultipleInvoicesTypesViaOTHERTransaction, updateMultipleInvoicesTypes, createSquadCoConfig, verifySquadCoPayment,
} from '../actions/collection.actions'


const collectionSlice = createSlice({
    name: 'collection',
    initialState: {
        laboratory_invoices: null,
        laboratory_invoice: null,
        laboratory_invoice_update: null,
        pharmacy_invoices: null,
        pharmacy_invoice: null,
        pharmacy_invoice_update: null,
        radiology_invoices: null,
        radiology_invoice: null,
        radiology_invoice_update: null,
        records_invoices: null,
        records_invoice: null,
        records_invoice_update: null,
        paystack_config: null,
        squadco_config: null,
        flutterwave_config: null,
        paystack_payment_status: null,
        squadco_payment_status: null,
        flutterwave_payment_status: null,
        mutipleInvoices: null,
        inventory_report: null,
        set_accumulated_bills: null,
        current_invoice: null,
        all_current_invoices: null,
        collectionLoading: null,
        collectionRequestStatus: null,
    },
    extraReducers: {
        [getLaboratoryInvoices.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [getLaboratoryInvoices.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.laboratory_invoices = action.payload
        },
        [getLaboratoryInvoices.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [getPharmacyInvoices.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [getPharmacyInvoices.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.pharmacy_invoices = action.payload
        },
        [getPharmacyInvoices.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [getRadiologyInvoices.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [getRadiologyInvoices.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.radiology_invoices = action.payload
        },
        [getRadiologyInvoices.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [getRecordsInvoices.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [getRecordsInvoices.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.records_invoices = action.payload
        },
        [getRecordsInvoices.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [setCurrentLabInvoice.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [setCurrentLabInvoice.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.laboratory_invoice = action.payload
        },
        [setCurrentLabInvoice.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [createPayStackConfig.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [createPayStackConfig.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.paystack_config = action.payload
        },
        [createPayStackConfig.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [createSquadCoConfig.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [createSquadCoConfig.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.squadco_config = action.payload
        },
        [createSquadCoConfig.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [createFlutterWaveConfig.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [createFlutterWaveConfig.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.flutterwave_config = action.payload
        },
        [createFlutterWaveConfig.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [verifyPayStackPayment.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [verifyPayStackPayment.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.paystack_payment_status = action.payload
            state.squadco_payment_status = action.payload
        },
        [verifyPayStackPayment.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [verifySquadCoPayment.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [verifySquadCoPayment.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.squadco_payment_status = action.payload
        },
        [verifySquadCoPayment.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [verifyFlutterWavePayment.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [verifyFlutterWavePayment.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.flutterwave_payment_status = action.payload
        },
        [verifyFlutterWavePayment.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateLabInvoice.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateLabInvoice.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.laboratory_invoice_update = action.payload
        },
        [updateLabInvoice.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateLabInvoiceViaPOSTransaction.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateLabInvoiceViaPOSTransaction.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.laboratory_invoice_update = action.payload
        },
        [updateLabInvoiceViaPOSTransaction.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateLabInvoiceViaCash.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateLabInvoiceViaCash.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.laboratory_invoice_update = action.payload
        },
        [updateLabInvoiceViaCash.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [setCurrentPharmacyInvoice.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [setCurrentPharmacyInvoice.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.pharmacy_invoice = action.payload
        },
        [setCurrentPharmacyInvoice.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updatePharmacyInvoice.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updatePharmacyInvoice.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.pharmacy_invoice_update = action.payload
        },
        [updatePharmacyInvoice.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updatePharmacyInvoiceViaPOSTransaction.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updatePharmacyInvoiceViaPOSTransaction.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.pharmacy_invoice_update = action.payload
        },
        [updatePharmacyInvoiceViaPOSTransaction.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateMultipleInvoicesViaOTHERTransaction.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateMultipleInvoicesViaOTHERTransaction.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.pharmacy_invoice_update = action.payload
        },
        [updateMultipleInvoicesViaOTHERTransaction.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateMultipleInvoicesTypesViaOTHERTransaction.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateMultipleInvoicesTypesViaOTHERTransaction.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.pharmacy_invoice_update = action.payload
        },
        [updateMultipleInvoicesTypesViaOTHERTransaction.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateMultipleInvoicesTypes.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateMultipleInvoicesTypes.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.pharmacy_invoice_update = action.payload
        },
        [updateMultipleInvoicesTypes.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updatePharmacyInvoiceViaCash.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updatePharmacyInvoiceViaCash.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.pharmacy_invoice_update = action.payload
        },
        [updatePharmacyInvoiceViaCash.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [setCurrentRadiologyInvoice.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [setCurrentRadiologyInvoice.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.radiology_invoice = action.payload
        },
        [setCurrentRadiologyInvoice.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateRadiologyInvoice.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateRadiologyInvoice.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.radiology_invoice_update = action.payload
        },
        [updateRadiologyInvoice.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateRadiologyInvoiceViaCash.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateRadiologyInvoiceViaCash.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.radiology_invoice_update = action.payload
        },
        [updateRadiologyInvoiceViaCash.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateRadiologyInvoiceViaPOSTransaction.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateRadiologyInvoiceViaPOSTransaction.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.radiology_invoice_update = action.payload
        },
        [updateRadiologyInvoiceViaPOSTransaction.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [setCurrentRecordsInvoice.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [setCurrentRecordsInvoice.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.records_invoice = action.payload
        },
        [setCurrentRecordsInvoice.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateRecordsInvoice.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateRecordsInvoice.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.records_invoice_update = action.payload
        },
        [updateRecordsInvoice.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateRecordsInvoiceViaCash.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateRecordsInvoiceViaCash.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.records_invoice_update = action.payload
        },
        [updateRecordsInvoiceViaCash.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [updateRecordsInvoiceViaPOSTransaction.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [updateRecordsInvoiceViaPOSTransaction.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.records_invoice_update = action.payload
        },
        [updateRecordsInvoiceViaPOSTransaction.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [createAdminPayStackConfig.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [createAdminPayStackConfig.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.paystack_config = action.payload
        },
        [createAdminPayStackConfig.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [verifyAdminPayStackPayment.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [verifyAdminPayStackPayment.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.paystack_payment_status = action.payload
            state.squadco_payment_status = action.payload
        },
        [verifyAdminPayStackPayment.rejected]: (state, action) => {
            state.collectionLoading = false
        },
        [setAccumulatedPharmacyInvoice.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [setAccumulatedPharmacyInvoice.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.set_accumulated_bills = action.payload
        },
        [setAccumulatedPharmacyInvoice.rejected]: (state, action) => {
            state.collectionLoading = false
        },
        [UpdateMultipleInvoices.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [UpdateMultipleInvoices.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.mutipleInvoices = action.payload
        },
        [UpdateMultipleInvoices.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [setCurrentInvoice.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [setCurrentInvoice.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.current_invoice = action.payload
        },
        [setCurrentInvoice.rejected]: (state, action) => {
            state.collectionLoading = false
        },

        [setCurrentAllInvoices.pending]: (state, action) => {
            state.collectionLoading = true
        },
        [setCurrentAllInvoices.fulfilled]: (state, action) => {
            state.collectionLoading = false
            state.all_current_invoices = action.payload
        },
        [setCurrentAllInvoices.rejected]: (state, action) => {
            state.collectionLoading = false
        },
    }
})


export default collectionSlice.reducer