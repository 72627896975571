import React, { Fragment } from 'react'
import { useStateContext } from '../../../state/context'
import RadiologyAddEquipmentModal from '../../modals/radiology/RadiologyAddEquipmentModal'
import RadiologyDeleteEquipment from '../../modals/radiology/RadiologyDeleteEquipment'
import RadiologyUploadReportModal from '../../modals/radiology/RadiologyUploadReportModal'
import RadiologyAddInvestigationModal from '../../modals/radiology/RadiologyAddInvestigationModal'
import RadiologyAddInvestigationCategoryModal from '../../modals/radiology/RadiologyAddInvestigationCategoryModal'
import RadiologyAddInvestigationTypeModal from '../../modals/radiology/RadiologyAddInvestigationTypeModal'
import RadiologyUpdateInvestigationModal from '../../modals/radiology/RadiologyUpdateInvestigationModal'
import RadiologyViewInvoiceModal from '../../modals/radiology/RadiologyViewInvoiceModal'
import RadiologyGenerateInvoiceModal from '../../modals/radiology/RadiologyGenerateInvoiceModal'
import RadiologyDeleteInvestigationTypeModal from '../../modals/radiology/RadiologyDeleteInvestigationTypeModal'

const RadiologyFormModal = (props) => {
    const { modals, setModals } = useStateContext()
    return (
        <Fragment>
            {props.type === "radiology_add_equipment" && (
                <RadiologyAddEquipmentModal />)}

            {props.type === "radiology_update_equipment" && (
                <RadiologyAddEquipmentModal />)}

            {props.type === "radiology_delete_equipment" && (
                <RadiologyDeleteEquipment />)}
            {props.type === "radiology_upload_report" && (
                <RadiologyUploadReportModal />)}
            {props.type === "radiology_show_upload_report" && (
                <RadiologyUploadReportModal />)}
            {props.type === "radiology_add_investigation" && (
                <RadiologyAddInvestigationModal />)}
            {props.type === "radiology_add_investigation_category" && (
                <RadiologyAddInvestigationCategoryModal />)}
            {props.type === "radiology_update_category_test" && (
                <RadiologyAddInvestigationCategoryModal />)}
            {props.type === "radiology_add_investigation_type" && (
                <RadiologyAddInvestigationTypeModal />)}
            {props.type === "radiology_update_investigation" && (
                <RadiologyUpdateInvestigationModal />)}
            {props.type === "radiology_view_investigation" && (
                <RadiologyUpdateInvestigationModal />)}
            {props.type === "radiology_view_invoice" && (
                <RadiologyViewInvoiceModal />)}

            {props.type === "radiology_generate_invoice" && (
                <RadiologyGenerateInvoiceModal />)}
            {props.type === "radiology_delete_category_test" && (
                <RadiologyDeleteEquipment />)}

            {props.type === "radiology_delete_investigation_type" && (
                <RadiologyDeleteInvestigationTypeModal />)}
            {props.type === "radiology_update_investigation_type" && (
                <RadiologyAddInvestigationTypeModal />)}



        </Fragment>
    )
}



export default RadiologyFormModal

