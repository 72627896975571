/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../state/context'
import { getDoctorPatients, getWardBedSpaces } from '../../state/actions/doctor.actions'
import { DoctorPatientAdmissionsTable, DoctorWardsBedSpaceTable, PageTitle } from '../../components'
import DoctorManagePatient from './DoctorManagePatient'


const DoctorAdmissions = () => {
	const dispatch = useDispatch()

	const { appState, setAppState } = useStateContext()

	const { data } = useSelector(state => state.auth.user)
	const { doctor_patients, inpatient_request, ward_bedspaces } = useSelector(state => state.doctor)

	const [showWards, setShowWards] = useState(false)
	const [activeLink, setActiveLink] = useState('Patients')
	const [showPatientsTable, setShowPatientsTable] = useState(true)
	const [filterState, setFilterState] = useState({ search: '', patients: [], ward_bedspaces: [] })

	const handlePatientsFilter = () => {
		const filtered = doctor_patients
			?.filter(patient => patient.patient_doctor === data.pkid)
			?.filter(item => {
				return item.patient_number.toLowerCase().includes(filterState.search.toLowerCase())
					|| item.details.first_name.toLowerCase().includes(filterState.search.toLowerCase())
					|| item.details.last_name.toLowerCase().includes(filterState.search.toLowerCase())
			})

		setFilterState({ ...filterState, patients: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, patients: doctor_patients?.filter(patient => patient.patient_doctor === data.pkid) })
		}
	}

	const handleWardsFilter = () => {
		const filtered = ward_bedspaces?.filter(item => {
			return item.ward_name.toLowerCase().includes(filterState.search.toLowerCase())
				|| item.bed_number.toLowerCase().includes(filterState.search.toLowerCase())
		})

		setFilterState({ ...filterState, ward_bedspaces: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, ward_bedspaces })
		}
	}

	useEffect(() => {
		dispatch(getDoctorPatients({ doc_name: data?.pkid }))
		dispatch(getWardBedSpaces())

		if (inpatient_request?.id) {
			dispatch(getDoctorPatients({ doc_name: data?.pkid }))
		}

		return () => {
			setAppState({ ...appState, showDoctorManagePatient: false })
		}
	}, [inpatient_request])

	useEffect(() => {
		if (activeLink === 'Patients') {
			handlePatientsFilter()
		}
		if (activeLink === 'Wards | Bedspaces') {
			handleWardsFilter()
		}
	}, [filterState.search])

	useEffect(() => {
		setFilterState({ ...filterState, patients: doctor_patients?.filter(patient => patient.patient_doctor === data.pkid), ward_bedspaces })
	}, [doctor_patients, ward_bedspaces])


	return (
		<div>
			<PageTitle type={'doctor-admissions'} />

			{!appState.showDoctorManagePatient && (
				<div className="space-y-">
					<div className="flex w-full justify-between">
						<div className="space-x-5">
							<button
								type="button"
								className={`${activeLink === 'Patients' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
								onClick={(e) => setActiveLink(e.target.textContent)}
							>
								Patients
							</button>
							<button
								type="button"
								className={`${activeLink === 'Wards | Bedspaces' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
								onClick={(e) => setActiveLink(e.target.textContent)}
							>
								Wards | Bedspaces
							</button>
						</div>

						<input
							type="text"
							name="search"
							placeholder='Search'
							className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
							onChange={(e) => setFilterState({ ...filterState, [e.target.name]: e.target.value })}
						/>

					</div>
					{activeLink === 'Patients' && (
						<DoctorPatientAdmissionsTable
							title={'Patients'}
							data={filterState.patients}
							showList={showPatientsTable}
							setShowList={setShowPatientsTable}
						/>

					)}

					{activeLink === 'Wards | Bedspaces' && (
						<DoctorWardsBedSpaceTable
							title={'Wards | Bedspace'}
							data={filterState.ward_bedspaces}
							showList={showWards}
							setShowList={setShowWards}
						/>
					)}
				</div>
			)}

			{appState.showDoctorManagePatient && (
				<DoctorManagePatient />
			)}
		</div>
	)
}

export default DoctorAdmissions