import React from 'react'

const PaymentStatus = ({ status }) => {
    return (
        <div className={`flex space-x-2 px-2 h-[25px] justify-center items-center rounded-full bg-gray-100 ${status ? 'w-[60px]': 'w-[75px]'}`}>
            <span className={`w-2 h-2 rounded-full ${status ? 'bg-teal-600' : 'bg-orange-500'}`}></span>
            <p className='text-[10px]'>{status ? 'Paid' : 'Pending'}</p>
        </div>
    )
}

export default PaymentStatus