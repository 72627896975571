/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { FaBed } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

import { PageTitle } from '../../components'
import { useStateContext } from '../../state/context'
import { setWardReport } from '../../state/actions/actions'
import { getAllWardReports, getAllWards } from '../../state/actions/records.actions'
import WardReportTable from '../../components/tables/recordsTable/WardReportTable'
import Pagination from '../../components/utils/Pagination'


const RecordsWardsBeds = (props) => {
	const dispatch = useDispatch()

	const { modals, setModals } = useStateContext()
	const { all_wards } = useSelector((state) => state.records)

	const [currentData, setCurrentData] = useState([])
	const [viewWardReportTable, setViewWardReportTable] = useState(false)

	useEffect(() => {
		dispatch(getAllWards())
	}, [modals.showWardInfoModal])

	return (
		<div>
			<PageTitle type={'records-wards_beds'} />

			<div className='flex justify-between items-center w-full px-1'>
				<div className='flex flex-col w-full cursor-pointer'>
					{!viewWardReportTable && (
						<>
							<div className='py-2'>
								<div className='flex justify-between items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1'>
									<div className='flex justify-start items-center space-x-4'>
										<h4 className='text-dark-blue text-[14px] font-bold'>
											Wards
										</h4>
										<FaBed className='text-dark-blue' size={20} />
									</div>

								</div>
								<table className='border rounded w-full cursor-default'>
									<thead className=''>
										<tr>
											<th
												scope='col'
												className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
											>
												S/N
											</th>
											<th
												scope='col'
												className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
											>
												Ward name
											</th>
											<th
												scope='col'
												className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
											>
												Bed space
											</th>
											<th
												scope='col'
												className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
											>
												Publisher
											</th>
											<th
												scope='col'
												className='text-sm font-medium text-gray-900 py-4 text-left'
											>
												Actions
											</th>
										</tr>
									</thead>
									<tbody>
										{currentData?.map((wards, index) => (
											<tr className='' key={index}>
												<td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
													{index + 1}
												</td>
												<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
													{wards.name}
												</td>
												<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
													{wards.total_bed_space}
												</td>
												<td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
													{wards.publisher}
												</td>
												<td className='text-sm text-gray-900 space-x-2 font-light py-2 whitespace-nowrap'>
													<button
														type='button'
														className='inline-block px-6 py-2.5 bg-blue-600  text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease'
														onClick={() => {
															setModals({ ...modals, showWardInfoModal: !modals.showWardInfoModal })

															const data = {
																id: wards.id,
																pkid: wards.pkid,
																name: wards.name,
															}
															dispatch(setWardReport({ data }))
														}}
													>
														Add report
													</button>
													<button
														type='button'
														className='inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease'
														onClick={() => {
															const data = {
																id: wards.id,
																pkid: wards.pkid,
																name: wards.name,
															}

															setViewWardReportTable(true)
															
															dispatch(setWardReport({ data }))
															dispatch(getAllWardReports({ id: wards.id }))
														}}
													>
														View
													</button>
												</td>
											</tr>
										))}
									</tbody>
								</table>

								<Pagination
									data={all_wards}
									currentData={currentData}
									setCurrentData={setCurrentData}
								/>
							</div>
						</>
					)}
					{viewWardReportTable && (
						<WardReportTable
							viewWardReportTable={viewWardReportTable}
							setViewWardReportTable={setViewWardReportTable}
						/>
					)}
				</div>
			</div>
		</div>
	)
}

export default RecordsWardsBeds
