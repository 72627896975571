import React, { Fragment } from 'react'
import NurseAddBirthRecordModal from '../../modals/nurse/NurseAddBirthRecordModal'
import NurseAddChildForImmunizationModal from '../../modals/nurse/NurseAddChildForImmunizationModal'
import NurseAddDeathRecordModal from '../../modals/nurse/NurseAddDeathRecordModal'
import NurseAddFamilyPlanningRecordsModal from '../../modals/nurse/NurseAddFamilyPlanningRecordsModal'
import NurseAddInvestigationRecordModal from '../../modals/nurse/NurseAddInvestigationRecordModal'
import NurseAddPatientDailyReportModal from '../../modals/nurse/NurseAddPatientDailyReportModal'
import NurseAddPatientLabourSummaryRecordModal from '../../modals/nurse/NurseAddPatientLabourSummaryRecordModal'
import NurseAddPatientMedicalRecordModal from '../../modals/nurse/NurseAddPatientMedicalRecordModal'
import NurseAddPatientModal from '../../modals/nurse/NurseAddPatientModal'
import NurseAddPatientPerfomanceModal from '../../modals/nurse/NurseAddPatientPerfomanceModal'
import NurseAddPatientPregnancyRecordModal from '../../modals/nurse/NurseAddPatientPregnancyRecordModal'
import NurseAddPatientVisitRecordModal from '../../modals/nurse/NurseAddPatientVisitRecordModal'
import NurseAddVaccinationRecordsModal from '../../modals/nurse/NurseAddVaccinationRecordsModal'
import NurseAdministerOpdModal from '../../modals/nurse/NurseAdministerOpdModal'
import NurseAdmitPatientModal from '../../modals/nurse/NurseAdmitPatientModal'
import NurseBookAntenatalAppointment from '../../modals/nurse/NurseBookAntenatalAppointment'
import NurseBookPatientForFamilyPlanningModal from '../../modals/nurse/NurseBookPatientForFamilyPlanningModal'
import NurseDeleteLabourSummaryModal from '../../modals/nurse/NurseDeleteLabourSummaryModel'
import NurseDeletePatientFollowUpRecords from '../../modals/nurse/NurseDeletePatientFollowUpRecords'
import NurseGenerateAntenatalCardModal from '../../modals/nurse/NurseGenerateAntenatalCardModal'
import NurseImmunizationCardModal from '../../modals/nurse/NurseImmunizationCardModal'
import NursePatientPregnancyHistoryModal from '../../modals/nurse/NursePatientPregnancyHistoryModal'
import NurseAddPatientTreatmentReportModal from '../../modals/nurse/NursePatientTreatmentReportModal'
import NurseShowPatientLabourSummaryModal from '../../modals/nurse/NurseShowPatientLabourSummaryModal'
import NurseViewBirthRecordModal from '../../modals/nurse/NurseViewBirthRecordModal'
import NurseViewFamilyPlanningCardModal from '../../modals/nurse/NurseViewFamilyPlanningCardModal'
import NurseViewPatientMedicalHistoryModal from '../../modals/nurse/NurseViewPatientMedicalHistoryModal'
import NurseViewVisitRecordModal from '../../modals/nurse/NurseViewVisitRecordModal'
import PatientBloodPressureChartModal from '../../modals/nurse/PatientBloodPressureChartModal'
import PatientPulseChartModal from '../../modals/nurse/PatientPulseChartModal'
import PatientRespiratoryChartModal from '../../modals/nurse/PatientRespiratoryChartModal'
import PatientTemperatureChartModal from '../../modals/nurse/PatientTemperatureChartModal'
import PatientVitalScienceChartModal from '../../modals/nurse/PatientVitalScienceChartModal'

const NurseFormModal = (props) => {

    return (
        <Fragment>
            {props.type === "nurse_add_patient" && (
                <NurseAddPatientModal />)}
            {props.type === "nurse_add_perfomace_data" && (
                <NurseAddPatientPerfomanceModal />)}
            {props.type === "nurse_administer_opd" && (
                <NurseAdministerOpdModal />)}

            {props.type === "nurse_admit_patient" && (
                <NurseAdmitPatientModal />)}

            {props.type === "nurse_admit_patient" && (
                <NurseAdmitPatientModal />)}
            {props.type === "nurse_add_family_planning_records" && (
                <NurseAddFamilyPlanningRecordsModal />)}
            {props.type === "nurse_book_patient_for_family_planning" && (
                <NurseBookPatientForFamilyPlanningModal />)}
            {props.type === "nurse_add_child_for_immunization" && (
                <NurseAddChildForImmunizationModal />)}
            {props.type === "nurse_add_vaccination_record" && (
                <NurseAddVaccinationRecordsModal />)}
            {props.type === "nurse_book_antenatal" && (
                <NurseBookAntenatalAppointment />)}
            {props.type === "nurse_add_patient_meedical_record" && (
                <NurseAddPatientMedicalRecordModal />)}
            {props.type === "nurse_add_patient_pregnancy_record" && (
                <NurseAddPatientPregnancyRecordModal />)}
            {props.type === "nurse_add_patient_visit_record" && (
                <NurseAddPatientVisitRecordModal />)}
            {props.type === "nurse_add_investigation_record" && (
                <NurseAddInvestigationRecordModal />)}

            {props.type === "nurse_add_labour_summary_record" && (
                <NurseAddPatientLabourSummaryRecordModal />)}

            {props.type === "nurse_delete_labour_summary_record" && (
                <NurseDeleteLabourSummaryModal />)}

            {props.type === "nurse_delete_investigation__record" && (
                <NurseDeleteLabourSummaryModal />)}
            {props.type === "nurse_delete_visit__record" && (
                <NurseDeleteLabourSummaryModal />)}
            {props.type === "nurse_generate_antenatal__card" && (
                <NurseGenerateAntenatalCardModal />)}
            {props.type === "nurse_view_visit_record_modal" && (
                <NurseViewVisitRecordModal />)}

            {props.type === "nurse_add_admitted_patient_daily_report_modal" && (
                <NurseAddPatientDailyReportModal />)}
            {props.type === "nurse_add_admitted_patient_treatment_report_modal" && (
                <NurseAddPatientTreatmentReportModal />)}
            {props.type === "nurse_show_temperature_chart_modal" && (
                <PatientTemperatureChartModal />)}
            {props.type === "nurse_show_pulse_chart_modal" && (
                <PatientPulseChartModal />)}
            {props.type === "nurse_show_blood_pressure_chart_modal" && (
                <PatientBloodPressureChartModal />)}
            {props.type === "nurse_show_respiratory_chart_modal" && (
                <PatientRespiratoryChartModal />)}
            {props.type === "nurse_show_vital_chart_modal" && (
                <PatientVitalScienceChartModal />)}

            {props.type === "nurse_show_add_birth_record" && (
                <NurseAddBirthRecordModal />)}
            {props.type === "nurse_show_view_birth_record" && (
                <NurseViewBirthRecordModal />)}

            {props.type === "nurse_show_add_death_record" && (
                <NurseAddDeathRecordModal />)}
            {props.type === "nurse_show_view_death_record" && (
                <NurseViewBirthRecordModal />)}
            {props.type === "nurse_show_patient_view_follow_up_record" && (
                <NurseDeletePatientFollowUpRecords />)}
            {props.type === "nurse_show_immunization_card" && (
                <NurseImmunizationCardModal />)}
            {props.type === "nurse_show_patient_medical_history" && (
                <NurseViewPatientMedicalHistoryModal />)}
            {props.type === "nurse_show_patient_pregnancy_history_modal" && (
                <NursePatientPregnancyHistoryModal />)}
            {props.type === "nurse_show_patient_labour_summary" && (
                <NurseShowPatientLabourSummaryModal />)}
            {props.type === "nurse_show_patient_family_planing_card" && (
                <NurseViewFamilyPlanningCardModal />)}


            


        </Fragment>
    )
}


export default NurseFormModal