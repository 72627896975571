import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { FaTimesCircle } from 'react-icons/fa'

import { useStateContext } from '../../state/context'
import { updateProfilePassword } from '../../state/actions/auth.actions'


const UpdatePasswordModal = (props) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [formData, setFormData] = useState({ current_password: '', new_password: '', re_new_password: '' })

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        !formData.current_password && toast.warning('Enter your current password')
        !formData.new_password && toast.warning('Insert a new password')
        !formData.re_new_password[0] && toast.warning('Confirm your new password')
        if (formData.current_password && formData.new_password && formData.re_new_password) {
            dispatch(updateProfilePassword({ formData, toast, setModals, modals }))
        }
    }

    return (
        <div className="fixed grid h-screen w-full z-20 bg-[#11111180] place-items-center">
            <div className="bg-white rounded px-[30px] py-[20px] w-[450px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className='text-dark-blue text-[20px] font-bold'>Update Password</h4>
                        <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => setModals({ ...modals, updatePasswordModal: !modals.updatePasswordModal })}
                            size={20}
                        />
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div>
                            <input
                                type="password"
                                name="current_password"
                                placeholder="Old Password"
                                className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                onChange={(e) => handleChange(e, e.target.name)}
                            />
                        </div>
                        <div>
                            <input
                                type="password"
                                name="new_password"
                                placeholder="New Password"
                                className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                onChange={(e) => handleChange(e, e.target.name)}
                            />
                        </div>
                        <div>
                            <input
                                type="password"
                                name="re_new_password"
                                placeholder="Confirm New Password"
                                className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                onChange={(e) => handleChange(e, e.target.name)}
                            />
                        </div>

                        <div className='mt-4 flex justify-center items-center'>
                            <button
                                type='submit'
                                className="inline-block w-[175px] px-6 py-2.5 bg-dark-blue text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >
                                Update Password
                            </button>
                        </div>
                    </form>
                </div>


            </div>
        </div>
    )
}

export default UpdatePasswordModal