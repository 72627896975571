import React, { useEffect, useState } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useStateContext } from '../../../state/context'
import { useSelector, useDispatch } from 'react-redux'
import { updatePatientOpdInjection } from '../../../state/actions/nurse.actions'
import { toast } from 'react-toastify'
import { setAdministerOpd } from '../../../state/actions/actions'
import { getAllPatientDiagnosisPrescriptions } from '../../../state/actions/nurse.actions'

const NurseAdministerOpdModal = () => {
    const [formData, setFormData] = useState({
        id: '',
    })
    const dispatch = useDispatch()
    const { set_administer_opd, set_get_diagnosisPrescriptions } = useSelector((state) => state.root)

    useEffect(() => {
        setFormData({ ...formData, id: set_administer_opd?.id })
    }, [set_administer_opd])


    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(formData)
        setFormData({ ...formData, id: set_administer_opd?.id })

        dispatch(updatePatientOpdInjection({ formData, toast, setModals: setModals, modals: modals }))
        const id = set_get_diagnosisPrescriptions?.diagnosis_id
        dispatch(getAllPatientDiagnosisPrescriptions({ id }))


    }
    const { modals, setModals } = useStateContext()
    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='flex items-center pl-[50px] bg-white rounded  py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>
                            <>
                                <h4 className='text-dark-green text-[16px] font-semibold'>
                                    Administer injection
                                </h4>
                                <FaTimesCircle
                                    className='text-dark-yellow cursor-pointer'
                                    onClick={() => {
                                        setModals({
                                            ...modals,
                                            showNurseAdministerOPDModal:
                                                !modals.showNurseAdministerOPDModal,
                                        })
                                        dispatch(setAdministerOpd({ data: null }))
                                    }}
                                    size={20}
                                />
                            </>
                        </div>

                        <form onSubmit={handleSubmit}>
                            {/* <h4 className='text-sm font-semibold py-2'>Investigation category</h4> */}
                            <div className='flex justify-between-items-center space-x-8'>
                                <div className='space-y-2  w-full'>

                                    <div className='flex space-x-2' >
                                        <div>
                                            <p>Drug name</p>
                                            <input
                                                type="title"
                                                name="temperature"
                                                value={set_administer_opd?.drug}
                                                disabled

                                                className="border rounded flex-[0.5] px-1 w-[250px] h-5  py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            // onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div>
                                            <p>Frequency</p>
                                            <input
                                                type="title"
                                                name="temperature"
                                                value={set_administer_opd?.frequency}
                                                disabled

                                                className="border rounded flex-[0.5] h-5 px-1 w-[250px] py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            // onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex space-x-2' >

                                        <div>
                                            <p>Duration</p>
                                            <input
                                                type="title"
                                                name="temperature"
                                                value={set_administer_opd?.duration}
                                                disabled

                                                className="border rounded flex-[0.5] h-5 px-1 w-[250px] py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            // onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div>
                                            <p>Unit</p>
                                            <input
                                                type="title"
                                                name="temperature"
                                                value={set_administer_opd?.unit}
                                                disabled

                                                className="border rounded flex=[0.5] h-5 px-1 w-[250px] py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            // onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex space-x-2' >
                                        <div>
                                            <p>Dose</p>
                                            <input
                                                type="title"
                                                name="temperature"
                                                value={set_administer_opd?.dose}
                                                disabled

                                                className="border rounded flex-[0.5] h-5 px-1 w-[250px] py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            // onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div>
                                            <p>OPD Count</p>
                                            <input
                                                type="title"

                                                value={set_administer_opd?.opd_count}
                                                disabled

                                                className="border rounded flex-[0.5] h-5 px-1 w-[250px] py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            // onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                    </div>




                                </div>


                            </div>

                            <div className='mt-4 flex justify-center items-center'>
                                <button
                                    type='submit'
                                    className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
                                // onClick={handleSubmit}
                                >
                                    Add OPD
                                </button>
                            </div>

                        </form>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default NurseAdministerOpdModal