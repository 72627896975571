import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { MdFamilyRestroom } from 'react-icons/md'
import { FaPencilAlt, FaTrash } from 'react-icons/fa'

import Pagination from '../utils/Pagination'
import { setWard } from '../../state/actions/actions'
import { useStateContext } from '../../state/context'


const Icon = ({ title }) => (
    <Fragment>
        {title === 'Wards' && <MdFamilyRestroom className="text-dark-blue" size={20} />}
    </Fragment>
)

const WardTable = ({ showList, setShowList, data, title }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [_data, setData] = useState([])
    const [currentData, setCurrentData] = useState([])

    useEffect(() => {
        setData(data)
    }, [data])

    return (
        <div className="flex justify-between items-center w-full px-1">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="py-2">
                    <div
                        className="flex justify-start items-center space-x-4 bg-gray-200 p-1"
                        onClick={() => setShowList(!showList)}
                    >
                        <h4 className="text-dark-blue text-[14px] font-bold">{title}</h4>
                        <Icon title={title} />
                    </div>
                    {showList && (
                        <Fragment>

                            <table className="border rounded w-full cursor-default">
                                <thead className="">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left"
                                        >
                                            S/N
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[2em] py-4 text-left"
                                        >
                                            Ward Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[2em] py-4 text-left"
                                        >
                                            Bed Space
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Publisher
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 py-4 text-left"
                                        >
                                            
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData?.map((ward, index) => (
                                        <tr className="" key={ward.pkid}>
                                            <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {index + 1}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                {ward.name}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                {ward.total_bed_space}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                {ward.publisher}
                                            </td>
                                            <td className=" flex text-2xl space-x-4 text-gray-900 font-semibold py-2 whitespace-nowrap">
                                                <FaPencilAlt
                                                    size={20}
                                                    className='text-green-600 cursor-pointer'
                                                    onClick={() => {
                                                        dispatch(setWard({ data: ward }))
                                                        setModals({ ...modals, showUpdateWardModal: !modals.showUpdateWardModal })
                                                    }}
                                                />
                                                <FaTrash
                                                    onClick={() => {
                                                        dispatch(setWard({ data: ward }))
                                                        setModals({ ...modals, showDeleteWardModal: !modals.showDeleteWardModal })
                                                        // props?.setShowDeleteDirectorateModal(!props?.showDeleteDirectorateModal)
                                                    }}
                                                    className="text-orange-400 cursor-pointer"
                                                    size={20}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <Pagination
                                data={_data}
                                currentData={currentData}
                                setCurrentData={setCurrentData}
                            />
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    )
}

export default WardTable