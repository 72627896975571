import React from 'react'
import { useStateContext } from '../../state/context'
import SmallCalendar from './SmallCalendar'
import { useSelector } from 'react-redux'

const CalendarSideBar = ({ dateIndex, setDateIndex, currentMonthIndex, month }) => {
	const { modals, setModals } = useStateContext()

	const { user } = useSelector(state => state.auth)
	const { set_Schedule_date } = useSelector(state => state.root)

	return (
		<div>
			<SmallCalendar dateIndex={dateIndex} setDateIndex={setDateIndex} currentMonthIndex={currentMonthIndex} month={month} />

			{user?.data?.is_department_head && (
				<button className='mr-4 mt-10 px-2 rounded flex bg-indigo-800 text-white items-center text-[12px] shadow-md hover:shadow-2xl'
					onClick={() => {
						set_Schedule_date && setModals({ ...modals, showCreateScheduleModal: !modals.showCreateScheduleModal })
					}}
				>
					Create
				</button>
			)}
		</div>
	)
}

export default CalendarSideBar