import React from 'react'
import { useSelector } from 'react-redux'
import { useStateContext } from '../../../state/context'
import { FaTimesCircle } from 'react-icons/fa'

const NurseShowPatientLabourSummaryModal = () => {
    const { set_follow_up_record } = useSelector((state) => state.root)
    const { modals, setModals } = useStateContext()
    return (

        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>

                            <>
                                <h4 className='text-dark-green text-[14px] font-semibold'>
                                    Summary Labour Record
                                </h4>
                                <FaTimesCircle
                                    className='text-dark-yellow cursor-pointer'
                                    onClick={() => (
                                        setModals({
                                            ...modals,
                                            showPatientLabourSummaryModal:
                                                !modals.showPatientLabourSummaryModal,
                                        })
                                    )
                                    }
                                    size={20}
                                />
                            </>
                        </div>
                        <form

                        >
                            <div className='flex justify-between-items-center space-x-10'>
                                <div className='space-y-2 w-full'>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Type of labour</p>
                                            <input
                                                type="title"
                                                name="type_of_labour"
                                                disabled
                                                value={set_follow_up_record?.type_of_labour}
                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"

                                            />
                                        </div>

                                        <div>
                                            <p className='text-sm font-semibold'>Weight</p>
                                            <input
                                                type="title"
                                                name="birth_weight"
                                                disabled
                                                value={set_follow_up_record?.birth_weight}

                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"

                                            />
                                        </div>

                                    </div>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Place</p>
                                            <input
                                                type="title"
                                                name="place"
                                                disabled
                                                value={set_follow_up_record?.place}

                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"

                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Alive or S.B</p>
                                            <input
                                                type="title"
                                                name="baby_dead_alive"
                                                disabled
                                                value={set_follow_up_record?.baby_dead_alive}

                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"

                                            />
                                        </div>

                                    </div>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Duration</p>
                                            <input
                                                type="title"
                                                name="duration"
                                                disabled
                                                value={set_follow_up_record?.duration}
                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"

                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Date</p>
                                            <input
                                                type="date"
                                                disabled
                                                value={set_follow_up_record?.date}
                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"

                                            />
                                        </div>

                                    </div>
                                    <div className=' space-x-4'>

                                        <div>
                                            <p className='text-sm font-semibold'>Complications</p>
                                            <input
                                                type="title"
                                                name="complications"
                                                disabled
                                                value={set_follow_up_record?.complications}

                                                className="border rounded w-[500px] h-[100px] px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NurseShowPatientLabourSummaryModal