import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { FaTimesCircle } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { assignDoctor, } from '../../../state/actions/actions'
import { assignDoctorToPatient } from '../../../state/actions/records.actions'


const RecordsAssignDoctorModal = (props) => {
	const dispatch = useDispatch()

	const { modals, setModals } = useStateContext()

	const { assign_doctor } = useSelector((state) => state.root)
	const { doctors } = useSelector((state) => state.records)

	const [formData, setFormData] = useState({
		pat_id: '',
		doc_id: '',
		patient_doctor: '',
		id: '',
		date: '',
		time: ''
	})


	useEffect(() => {
		setFormData({
			...formData, id: assign_doctor?.patient_id,
			pat_id: assign_doctor?.patient_id,
			doc_id: '',
		})
	}, [assign_doctor])

	const handleChange = (e, name) => {
		setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		dispatch(assignDoctorToPatient({ formData, toast, setModals, modals, dispatch, assignDoctor }))
	}

	return (
		<div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
			<div className='bg-white rounded px-[30px] py-[20px] w-[500px]'>
				<div className='flex flex-col space-y-5'>
					<div className='flex justify-between items-center'>
						<h4 className='text-dark-green text-[20px] font-bold'>	Change Doctor</h4>

						<FaTimesCircle
							className='text-dark-yellow cursor-pointer'
							onClick={() => {
								setModals({ ...modals, showAssignDoctorModal: !modals.showAssignDoctorModal })
								dispatch(assignDoctor({ data: null }))
							}}
							size={20}
						/>
					</div>

					<form onSubmit={handleSubmit}>
						<div className='flex justify-between-items-center space-x-10'>
							<div className='space-y-5 w-full'>
								<div className='flex justify-between items-start'>
									<img src={`${process.env.REACT_APP_API_ROOT}${assign_doctor?.photo}`} alt="" className='h-[80px] w-[100px] mx-auto rounded-[50%] mr-2' />
									<div className='flex flex-col w-[400px] mt-2'>
										<div className='pl-2 py-2 mr-2 font-medium'>
											<h3> Name: {`${assign_doctor?.first_name} ${assign_doctor?.last_name}`}</h3>
											<h3 className='font-medium py-2'>Patient Number: {assign_doctor?.patient_no}</h3>
										</div>
									</div>
								</div>

								<div className='relative'>
									<select
										className='appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none '
										id='grid-state'
										name='patient_doctor'
										onChange={(e) =>
											handleChange(e, e.target.name)
										}
										onClick={() => {

										}}
									>
										<option>Doctor</option>
										{doctors?.map((doctor, index) => (
											<option
												value={`${doctor.pkid}`}
												key={index + 1}
											>
												{doctor.first_name}{' '}
												{doctor.last_name}
											</option>
										))}
									</select>
									<div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
										<svg
											className='fill-current h-4 w-4'
											xmlns='http://www.w3.org/2000/svg'
											viewBox='0 0 20 20'
										>
											<path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
										</svg>
									</div>
								</div>
							</div>
						</div>

						<div className='mt-4 flex justify-center items-center'>
							<button
								type='submit'
								className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
							>
								Submit
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}

export default RecordsAssignDoctorModal
