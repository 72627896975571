/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AiOutlineUser } from 'react-icons/ai'
import { MdKeyboardArrowDown, MdOutlineDateRange } from 'react-icons/md'

import { useStateContext } from '../../state/context'
import { getAllDailyReports, getAllInvoices, getAllMonthlyReports, getAllPaidReports, getAllReportsAuthor, getAllReportsRange, getAllUnpaidReports, getAllYearlyReports, getLaboratoryDailyReports, getLaboratoryInvoices, getLaboratoryMonthlyReports, getLaboratoryReportsAuthor, getLaboratoryReportsRange, getLaboratoryYearlyReports, getLabPaidReports, getLabUnPaidReports, getPaidInventoryReports, getPharmacyDailyReports, getPharmacyInvoices, getPharmacyMonthlyReports, getPharmacyPaidReports, getPharmacyReportsAuthor, getPharmacyReportsRange, getPharmacyUnPaidReports, getPharmacyYearlyReports, getRadiologyDailyReports, getRadiologyInvoices, getRadiologyMonthlyReports, getRadiologyPaidReports, getRadiologyReportsAuthor, getRadiologyReportsRange, getRadiologyUnPaidReports, getRadiologyYearlyReports, getRecordsDailyReports, getRecordsInvoices, getRecordsMonthlyReports, getRecordsPaidReports, getRecordsReportsAuthor, getRecordsReportsRange, getRecordsUnPaidReports, getRecordsYearlyReports, setCurrentReportsType, } from '../../state/actions/accountant.actions'
import { AccountsAllInvoicesTable, AccountsLaboratoryInvoicesTable, AccountsPharmacyInvoicesTable, AccountsRadiologyInvoicesTable, AccountsRecordsInvoicesTable, DatePicker, PageTitle } from '../../components'


const AccountsInvoices = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()
    const { all_invoices, laboratory_invoices, pharmacy_invoices, radiology_invoices, records_invoices } = useSelector(state => state.accountant)

    const [endDate, setEndDate] = useState(new Date())
    const [startDate, setStartDate] = useState(new Date())
    const [activeLink, setActiveLink] = useState('All')
    const [filterState, setFilterState] = useState({ display: false, filter: 'Day', staff_patient_email: '' })
    const [showTables, setShowTables] = useState({
        laboratory: true,
        pharmacy: true,
        radiology: true,
        records: true,
    })

    useEffect(() => {
        dispatch(getAllInvoices())
        dispatch(getLaboratoryInvoices())
        dispatch(getPharmacyInvoices())
        dispatch(getRadiologyInvoices())
        dispatch(getRecordsInvoices())
    }, [])

    const handleChange = (e, name) => {
        setFilterState({ ...filterState, [name]: e.target.value })
    }

    const generateReport = () => {
        const start_date = new Date(startDate).toISOString().slice(0, 10)
        const end_date = new Date(endDate).toISOString().slice(0, 10)

        if (activeLink === 'All') {
            if (filterState.filter === 'Day') {
                dispatch(getAllDailyReports({ params: { start_date } }))
                dispatch(setCurrentReportsType({ data: 'All Daily Reports' }))
                setModals({ ...modals, showAccountsViewAllReportsModal: !modals.showAccountsViewAllReportsModal })
            }

            if (filterState.filter === 'Month') {
                dispatch(getAllMonthlyReports({ params: { start_date } }))
                dispatch(setCurrentReportsType({ data: 'All Monthly Reports' }))
                setModals({ ...modals, showAccountsViewAllReportsModal: !modals.showAccountsViewAllReportsModal })
            }

            if (filterState.filter === 'Year') {
                dispatch(getAllYearlyReports({ params: { start_date } }))
                dispatch(setCurrentReportsType({ data: 'All Yearly Reports' }))
                setModals({ ...modals, showAccountsViewAllReportsModal: !modals.showAccountsViewAllReportsModal })
            }

            if (filterState.filter === 'Range') {
                dispatch(getAllReportsRange({ params: { start_date, end_date } }))
                dispatch(setCurrentReportsType({ data: `All Range Reports ${start_date}-${end_date}` }))
                setModals({ ...modals, showAccountsViewAllReportsModal: !modals.showAccountsViewAllReportsModal })
            }

            if (filterState.filter === 'Author') {
                console.log(filterState.staff_patient_email)
                dispatch(getAllReportsAuthor({ params: { author: filterState.staff_patient_email } }))
                dispatch(setCurrentReportsType({ data: `All Author Reports: ${filterState.staff_patient_email.split('@')[0]}` }))
                setModals({ ...modals, showAccountsViewAllReportsModal: !modals.showAccountsViewAllReportsModal })
            }
            if (filterState.filter === 'Paid') {
                dispatch(getAllPaidReports())
                dispatch(setCurrentReportsType({ data: `All Paid Reports` }))
                setModals({ ...modals, showAccountsViewAllReportsModal: !modals.showAccountsViewAllReportsModal })
            }

            if (filterState.filter === 'Unpaid') {
                dispatch(getAllUnpaidReports())
                dispatch(setCurrentReportsType({ data: `All Unpaid Reports` }))
                setModals({ ...modals, showAccountsViewAllReportsModal: !modals.showAccountsViewAllReportsModal })
            }
        }

        if (activeLink === 'Laboratory') {
            if (filterState.filter === 'Day') {
                dispatch(getLaboratoryDailyReports({ params: { start_date } }))
                setModals({ ...modals, showAccountsViewLabReportsModal: !modals.showAccountsViewLabReportsModal })
            }

            if (filterState.filter === 'Month') {
                dispatch(getLaboratoryMonthlyReports({ params: { start_date } }))
                setModals({ ...modals, showAccountsViewLabReportsModal: !modals.showAccountsViewLabReportsModal })
            }

            if (filterState.filter === 'Year') {
                dispatch(getLaboratoryYearlyReports({ params: { start_date } }))
                setModals({ ...modals, showAccountsViewLabReportsModal: !modals.showAccountsViewLabReportsModal })
            }

            if (filterState.filter === 'Range') {
                dispatch(getLaboratoryReportsRange({ params: { start_date, end_date } }))
                setModals({ ...modals, showAccountsViewLabReportsModal: !modals.showAccountsViewLabReportsModal })
            }

            if (filterState.filter === 'Author') {
                console.log(filterState.staff_patient_email)
                dispatch(getLaboratoryReportsAuthor({ params: { author: filterState.staff_patient_email } }))
                setModals({ ...modals, showAccountsViewLabReportsModal: !modals.showAccountsViewLabReportsModal })
            }
            if (filterState.filter === 'Paid') {
                dispatch(getLabPaidReports())
                setModals({ ...modals, showAccountsViewLabReportsModal: !modals.showAccountsViewLabReportsModal })
            }

            if (filterState.filter === 'Unpaid') {
                dispatch(getLabUnPaidReports())
                setModals({ ...modals, showAccountsViewLabReportsModal: !modals.showAccountsViewLabReportsModal })
            }
        }
        if (activeLink === 'Pharmacy') {
            if (filterState.filter === 'Day') {
                dispatch(getPharmacyDailyReports({ params: { start_date } }))
                setModals({ ...modals, showAccountsViewPharmacyReportsModal: !modals.showAccountsViewPharmacyReportsModal })
            }

            if (filterState.filter === 'Month') {
                dispatch(getPharmacyMonthlyReports({ params: { start_date } }))
                setModals({ ...modals, showAccountsViewPharmacyReportsModal: !modals.showAccountsViewPharmacyReportsModal })
            }

            if (filterState.filter === 'Year') {
                dispatch(getPharmacyYearlyReports({ params: { start_date } }))
                setModals({ ...modals, showAccountsViewPharmacyReportsModal: !modals.showAccountsViewPharmacyReportsModal })
            }

            if (filterState.filter === 'Range') {
                dispatch(getPharmacyReportsRange({ params: { start_date, end_date } }))
                setModals({ ...modals, showAccountsViewPharmacyReportsModal: !modals.showAccountsViewPharmacyReportsModal })
            }

            if (filterState.filter === 'Author') {
                dispatch(getPharmacyReportsAuthor({ params: { author: filterState.staff_patient_email } }))
                setModals({ ...modals, showAccountsViewPharmacyReportsModal: !modals.showAccountsViewPharmacyReportsModal })
            }
            if (filterState.filter === 'Paid') {
                dispatch(getPharmacyPaidReports())
                setModals({ ...modals, showCollectionsViewPharmacyReportsModal: !modals.showCollectionsViewPharmacyReportsModal })
            }

            if (filterState.filter === 'Unpaid') {
                dispatch(getPharmacyUnPaidReports())
                setModals({ ...modals, showCollectionsViewPharmacyReportsModal: !modals.showCollectionsViewPharmacyReportsModal })
            }
        }
        if (activeLink === 'Radiology') {
            if (filterState.filter === 'Day') {
                dispatch(getRadiologyDailyReports({ params: { start_date } }))
                setModals({ ...modals, showAccountsViewRadiologyReportsModal: !modals.showAccountsViewRadiologyReportsModal })
            }

            if (filterState.filter === 'Month') {
                dispatch(getRadiologyMonthlyReports({ params: { start_date } }))
                setModals({ ...modals, showAccountsViewRadiologyReportsModal: !modals.showAccountsViewRadiologyReportsModal })
            }

            if (filterState.filter === 'Year') {
                dispatch(getRadiologyYearlyReports({ params: { start_date } }))
                setModals({ ...modals, showAccountsViewRadiologyReportsModal: !modals.showAccountsViewRadiologyReportsModal })
            }

            if (filterState.filter === 'Range') {
                dispatch(getRadiologyReportsRange({ params: { start_date, endDate } }))
                setModals({ ...modals, showAccountsViewRadiologyReportsModal: !modals.showAccountsViewRadiologyReportsModal })
            }

            if (filterState.filter === 'Author') {
                dispatch(getRadiologyReportsAuthor({ params: { author: filterState.staff_patient_email } }))
                setModals({ ...modals, showAccountsViewRadiologyReportsModal: !modals.showAccountsViewRadiologyReportsModal })
            }
            if (filterState.filter === 'Paid') {
                dispatch(getRadiologyPaidReports())
                setModals({ ...modals, showAccountsViewRadiologyReportsModal: !modals.showAccountsViewRadiologyReportsModal })
            }

            if (filterState.filter === 'Unpaid') {
                dispatch(getRadiologyUnPaidReports())
                setModals({ ...modals, showAccountsViewRadiologyReportsModal: !modals.showAccountsViewRadiologyReportsModal })
            }
        }

        if (activeLink === 'Records') {
            if (filterState.filter === 'Day') {
                dispatch(getRecordsDailyReports({ params: { start_date } }))
                setModals({ ...modals, showAccountsViewRecordsReportsModal: !modals.showAccountsViewRecordsReportsModal })
            }

            if (filterState.filter === 'Month') {
                dispatch(getRecordsMonthlyReports({ params: { start_date } }))
                setModals({ ...modals, showAccountsViewRecordsReportsModal: !modals.showAccountsViewRecordsReportsModal })
            }

            if (filterState.filter === 'Year') {
                dispatch(getRecordsYearlyReports({ params: { start_date } }))
                setModals({ ...modals, showAccountsViewRecordsReportsModal: !modals.showAccountsViewRecordsReportsModal })
            }

            if (filterState.filter === 'Range') {
                dispatch(getRecordsReportsRange({ params: { start_date, end_date } }))
                setModals({ ...modals, showAccountsViewRecordsReportsModal: !modals.showAccountsViewRecordsReportsModal })
            }

            if (filterState.filter === 'Author') {
                dispatch(getRecordsReportsAuthor({ params: { author: filterState.staff_patient_email } }))
                setModals({ ...modals, showAccountsViewRecordsReportsModal: !modals.showAccountsViewRecordsReportsModal })
            }
            if (filterState.filter === 'Paid') {
                dispatch(getRecordsPaidReports())
                setModals({ ...modals, showAccountsViewRecordsReportsModal: !modals.showAccountsViewRecordsReportsModal })
            }

            if (filterState.filter === 'Unpaid') {
                dispatch(getRecordsUnPaidReports())
                setModals({ ...modals, showAccountsViewRecordsReportsModal: !modals.showAccountsViewRecordsReportsModal })
            }
        }
    }

    return (
        <div>
            <PageTitle type={'accounts-invoices'} />

            <div>
                <div className="flex space-x-5">
                    <button
                        type="button"
                        className={`${activeLink === 'All' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => setActiveLink(e.target.textContent)}
                    >
                        All
                    </button>
                    <button
                        type="button"
                        className={`${activeLink === 'Laboratory' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => setActiveLink(e.target.textContent)}
                    >
                        Laboratory
                    </button>
                    <button
                        type="button"
                        className={`${activeLink === 'Pharmacy' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => setActiveLink(e.target.textContent)}
                    >
                        Pharmacy
                    </button>
                    <button
                        type="button"
                        className={`${activeLink === 'Radiology' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => setActiveLink(e.target.textContent)}
                    >
                        Radiology
                    </button>
                    <button
                        type="button"
                        className={`${activeLink === 'Records' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => setActiveLink(e.target.textContent)}
                    >
                        Records
                    </button>
                </div>

                <div className="flex justify-between items-center border-b-2 border-gray-500 py-2">
                    <div className="flex items-center space-x-2 relative">
                        <div className="flex items-center space-x-2 bg-gray-200 px-2 rounded-lg cursor-pointer text-[16px] text-gray-500"
                            onClick={() => setFilterState({ ...filterState, display: !filterState.display })}
                        >
                            {filterState.filter !== 'Author' ? (
                                <MdOutlineDateRange
                                    className='text-gray-500'
                                />
                            ) : (
                                <AiOutlineUser
                                    className='text-gray-500'
                                />
                            )}
                            <p className='text-[14px]'>{filterState.filter}</p>
                            <MdKeyboardArrowDown
                                size={20}
                                className='text-gray-600'
                            />
                        </div>

                        {filterState.display && (
                            <div className="absolute top-5 bg-white rounded shadow-md">
                                <p
                                    className='text-[12px] text-gray-600 px-6 cursor-pointer bg-white hover:text-[14px] hover:bg-gray-100 hover:translate-x-1 duration-500 ease-in-out'
                                    onClick={(e) => setFilterState({ ...filterState, filter: e.target.textContent, display: false })}
                                >
                                    Day
                                </p>
                                <p
                                    className='text-[12px] text-gray-600 px-6 cursor-pointer bg-white hover:text-[14px] hover:bg-gray-100 hover:translate-x-1 duration-500 ease-in-out'
                                    onClick={(e) => setFilterState({ ...filterState, filter: e.target.textContent, display: false })}
                                >

                                    Month
                                </p>
                                <p
                                    className='text-[12px] text-gray-600 px-6 cursor-pointer bg-white hover:text-[14px] hover:bg-gray-100 hover:translate-x-1 duration-500 ease-in-out'
                                    onClick={(e) => setFilterState({ ...filterState, filter: e.target.textContent, display: false })}
                                >

                                    Year
                                </p>
                                <p
                                    className='text-[12px] text-gray-600 px-6 cursor-pointer bg-white hover:text-[14px] hover:bg-gray-100 hover:translate-x-1 duration-500 ease-in-out'
                                    onClick={(e) => setFilterState({ ...filterState, filter: e.target.textContent, display: false })}
                                >

                                    Range
                                </p>
                                <p
                                    className='text-[12px] text-gray-600 px-6 cursor-pointer bg-white hover:text-[14px] hover:bg-gray-100 hover:translate-x-1 duration-500 ease-in-out'
                                    onClick={(e) => setFilterState({ ...filterState, filter: e.target.textContent, display: false })}
                                >

                                    Author
                                </p>
                                <p
                                    className='text-[12px] text-gray-600 px-6 cursor-pointer bg-white hover:text-[14px] hover:bg-gray-100 hover:translate-x-1 duration-500 ease-in-out'
                                    onClick={(e) => setFilterState({ ...filterState, filter: e.target.textContent, display: false })}
                                >

                                    Paid
                                </p>
                                <p
                                    className='text-[12px] text-gray-600 px-6 cursor-pointer bg-white hover:text-[14px] hover:bg-gray-100 hover:translate-x-1 duration-500 ease-in-out'
                                    onClick={(e) => setFilterState({ ...filterState, filter: e.target.textContent, display: false })}
                                >

                                    Unpaid
                                </p>
                            </div>
                        )}

                        <DatePicker
                            type={filterState.filter === 'Day' ? 1 : filterState.filter === 'Month' ? 2 : filterState.filter === 'Year' ? 3 : filterState.filter === 'Range' ? 4 : 5}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                        />
                    </div>


                    <div className='space-x-4'>
                        {filterState.filter === 'Author' && (
                            <input
                                type="email"
                                name="staff_patient_email"
                                placeholder='Patient | Author Email Address'
                                className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                                onChange={(e) => handleChange(e, e.target.name)}
                            />
                        )}

                        <button
                            type="button"
                            className="w-[160px] inline-block px-6 py-2 bg-teal-600 text-white font-medium text-xs leading-tight uppercase shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                            onClick={generateReport}
                        >
                            Generate Report
                        </button>
                    </div>
                </div>

                {activeLink === 'All' && (
                    <AccountsAllInvoicesTable
                        showList={showTables.laboratory}
                        data={all_invoices}
                    />
                )}

                {activeLink === 'Laboratory' && (
                    <AccountsLaboratoryInvoicesTable
                        showList={showTables.laboratory}
                        data={laboratory_invoices}
                    />
                )}

                {activeLink === 'Pharmacy' && (
                    <AccountsPharmacyInvoicesTable
                        showList={showTables.pharmacy}
                        data={pharmacy_invoices}
                    />
                )}

                {activeLink === 'Radiology' && (
                    <AccountsRadiologyInvoicesTable
                        showList={showTables.radiology}
                        data={radiology_invoices}
                    />
                )}

                {activeLink === 'Records' && (
                    <AccountsRecordsInvoicesTable
                        showList={showTables.records}
                        data={records_invoices}
                    />
                )}

            </div>
        </div>
    )
}

export default AccountsInvoices