import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Editor } from '@tinymce/tinymce-react'

import NurseOtherPrescriptionTable from '../../components/tables/nursesTable/NurseOtherPrescriptionTable'
import NursePrescriptionTable from '../../components/tables/nursesTable/NursePrescriptionTable'


const NurseDiagnosisAndPrescriptionPage = () => {
    const editorRef = useRef(null)

    const [showDiagnosisTab, setShowDiagnosisTab] = useState(false)
    const { set_get_diagnosisPrescriptions } = useSelector((state) => state.root)
    return (
        <div className='flex flex-[0.7] flex-col cursor-pointer'>

            <div className='space-x-2'>
                <button
                    type="button"
                    className="inline-block px-6 py-2.5 border text-black font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                    onClick={() => {
                        setShowDiagnosisTab(false)
                    }}
                >
                    Diagnosis
                </button>
                <button
                    type="button"
                    className="inline-block px-6 py-2.5  border font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                    onClick={() => {
                        setShowDiagnosisTab(true)

                    }}
                >
                    Prescription
                </button>

            </div>
            {!showDiagnosisTab && (
                <>
                    <input type="text"
                        className='text-sm font-semibold my-2 py-4 border pl-2'
                        disabled
                        value={set_get_diagnosisPrescriptions?.title}
                    />
                    <Editor
                        disabled
                        apiKey={process.env.REACT_APP_TEXTEDITOR}
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={`${set_get_diagnosisPrescriptions?.description}`}
                        init={{
                            height: 400,
                            menubar: false
                        }}
                    // onEditorChange={(newValue, editor) => {
                    //     setFormData({ ...formData, description: editor.getContent() })
                    // }}
                    />


                </>
            )}

            {showDiagnosisTab && (
                <>
                    <div className='py-2'>

                        <NursePrescriptionTable />

                    </div>
                    <div className='py-2'>

                        <NurseOtherPrescriptionTable />
                    </div>
                </>)}

        </div>
    )
}

export default NurseDiagnosisAndPrescriptionPage