import React, { useState, useEffect } from 'react'
import { useStateContext } from '../../../state/context'
import { FaTimesCircle } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { addAPatientPregnancyRecord } from '../../../state/actions/nurse.actions'
import { toast } from 'react-toastify'


const NurseAddPatientPregnancyRecordModal = () => {
    const { modals, setModals } = useStateContext()
    const dispatch = useDispatch()
    const { set_patient_id } = useSelector((state) => state.root)
    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))

    }

    const [formData, setFormData] = useState({
        birth_date: '',
        duration: '',
        antenatal_complications: '',
        labor: '',
        baby_dead_alive: '',
        age_of_death: '',
        birth_weight: '',
        baby_ndd: '',
        id: '',



    })

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(formData);


        dispatch(addAPatientPregnancyRecord({ formData, toast, setModals, modals }))


    }

    useEffect(() => {
        setFormData({ ...formData, id: set_patient_id?.id })
    }, [])

    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>

                            <>
                                <h4 className='text-dark-green text-[14px] font-semibold'>
                                    Add pregnancy record - {set_patient_id?.first_name} {set_patient_id?.last_name} [{set_patient_id?.patient_number}]
                                </h4>
                                <FaTimesCircle
                                    className='text-dark-yellow cursor-pointer'
                                    onClick={() => (
                                        setModals({
                                            ...modals,
                                            showAddPatientPregnancyRecordModal:
                                                !modals.showAddPatientPregnancyRecordModal,
                                        })
                                    )

                                    }
                                    size={20}
                                />
                            </>


                        </div>




                        <form onSubmit={handleSubmit} >

                            {/* <h4 className='text-sm font-semibold py-2'>Investigation category</h4> */}
                            <div className='flex justify-between-items-center space-x-10'>
                                <div className='space-y-2 w-full'>
                                    <div className='flex space-x-4'>

                                        <div>
                                            <p className='text-sm font-semibold'>Date of birth</p>
                                            <input
                                                type="date"
                                                name="birth_date"

                                                className="border rounded w-[250px] h-5  bg-slate-300 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Alive or S.B</p>
                                            <select name="baby_dead_alive" placeholder='Type' id="" className='border   bg-slate-300 rounded mt-2 pl-2 w-[250px] h-[40px] focus:outline-none focus:ring-blue-500 '
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            >
                                                <option value="None">Select</option>
                                                <option value="Alive">Alive</option>
                                                <option value="Still Birth">Still birth</option>


                                            </select>
                                        </div>

                                    </div>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Duration</p>
                                            <input
                                                type="title"
                                                name="duration"
                                                // placeholder="Temperature"

                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Age at death</p>
                                            <input
                                                type="title"
                                                name="age_of_death"
                                                // placeholder="Temperature"

                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                    </div>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Antenatal complications</p>
                                            <input
                                                type="title"
                                                name="antenatal_complications"
                                                // placeholder="Temperature"

                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Birth weight</p>
                                            <input
                                                type="title"
                                                name="birth_weight"
                                                // placeholder="Temperature"

                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                    </div>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Labour</p>
                                            <input
                                                type="text"

                                                name="labor"


                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Baby NDD/D</p>
                                            <input
                                                type="text"
                                                name="baby_ndd"
                                                // placeholder="Temperature"
                                                // min={new Date().toISOString().split('T')[0]}

                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                    </div>






                                </div>


                            </div>

                            <div className='mt-4 flex justify-center items-center'>
                                <button
                                    type='submit'
                                    className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
                                // onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>

                        </form>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default NurseAddPatientPregnancyRecordModal