import { createSlice } from '@reduxjs/toolkit'
import {
    getAllOpds, getAllPatientDiagnosis, getAllPatientDiagnosisPrescriptions, getAllPatientPerformanceData, getAllDoctors,
    getAllPatientsToBeAdmitted, getAllWards, getAllWardBedspaces, getAllAdmittedPatient, getAllPatients, getAllBookedAntenatals,
    getAPatientMedicalRecord, getAPatientPregnancyRecord, getAPatientLabourRecord, getAPatientInvestigationRecord, getAPatientVisitRecord, getAllFamilyPlanningBookings,
    getAPatientFamilyFollowUpRecords, deletePatientPerformanceData, getAllPatientDailyReports, getAllPatientTreamentReports, deleteFamilyPlanningFollowUpRecord, getAllPatientForImmunization,
    getAPatientVaccinationRecords, removePatientVaccinationRecords, getAllProfiles, getAllBirthRecords, getAllDeathRecords, nurseaddAPatient
} from '../actions/nurse.actions'



const nurseSlice = createSlice({
    name: 'nurse',
    initialState: {
        all_opds: null,
        all_patient_diagnosis: null,
        all_patient_diagnosis_prescriptions: null,
        nurseLoading: false,
        nurseErrors: null,
        all_patient_performanceData: null,
        all_doctors: null,
        patients_to_be_admitted: null,
        all_wards: null,
        all_ward_bedspaces: null,
        all_admitted_patient: null,
        all_nurse_patients: null,
        all_booked_antenatals: null,
        all_patient_medical_record: null,
        all_patient_pregnancy_record: null,
        all_patient_labour_record: null,
        all_patient_investigation_record: null,
        all_patient_visit_records: null,
        all_family_planning_bookings: null,
        all_patient_family_planning_follow_up_record: null,
        all_patient_daily_report: null,
        all_patient_treatment_report: null,
        all_patient_for_immunization: null,
        vaccination_record_for_a_patient: null,
        all_profiles: null,
        all_birth_records: null,
        all_death_records: null,
        patient: null









    },
    extraReducers: {
        [getAllOpds.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAllOpds.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_opds = action.payload

        },

        [getAllOpds.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [getAllPatientDiagnosis.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAllPatientDiagnosis.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_patient_diagnosis = action.payload

        },

        [getAllPatientDiagnosis.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [getAllPatientDiagnosisPrescriptions.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAllPatientDiagnosisPrescriptions.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_patient_diagnosis_prescriptions = action.payload

        },

        [getAllPatientDiagnosisPrescriptions.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [getAllPatientPerformanceData.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAllPatientPerformanceData.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_patient_performanceData = action.payload

        },

        [getAllPatientPerformanceData.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [getAllDoctors.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAllDoctors.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_doctors = action.payload

        },

        [getAllDoctors.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [getAllPatientsToBeAdmitted.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAllPatientsToBeAdmitted.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.patients_to_be_admitted = action.payload

        },

        [getAllPatientsToBeAdmitted.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [getAllWards.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAllWards.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_wards = action.payload

        },

        [getAllWards.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [getAllWardBedspaces.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAllWardBedspaces.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_ward_bedspaces = action.payload

        },

        [getAllWardBedspaces.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [getAllAdmittedPatient.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAllAdmittedPatient.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_admitted_patient = action.payload

        },

        [getAllAdmittedPatient.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [getAllPatients.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAllPatients.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_nurse_patients = action.payload

        },

        [getAllPatients.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [getAllBookedAntenatals.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAllBookedAntenatals.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_booked_antenatals = action.payload

        },

        [getAllBookedAntenatals.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [getAPatientMedicalRecord.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAPatientMedicalRecord.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_patient_medical_record = action.payload

        },

        [getAPatientMedicalRecord.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [getAPatientPregnancyRecord.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAPatientPregnancyRecord.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_patient_pregnancy_record = action.payload

        },

        [getAPatientPregnancyRecord.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [getAPatientLabourRecord.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAPatientLabourRecord.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_patient_labour_record = action.payload

        },

        [getAPatientLabourRecord.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [getAPatientInvestigationRecord.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAPatientInvestigationRecord.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_patient_investigation_record = action.payload

        },

        [getAPatientInvestigationRecord.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [getAPatientVisitRecord.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAPatientVisitRecord.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_patient_visit_records = action.payload

        },

        [getAPatientVisitRecord.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [getAllFamilyPlanningBookings.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAllFamilyPlanningBookings.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_family_planning_bookings = action.payload

        },

        [getAllFamilyPlanningBookings.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [getAPatientFamilyFollowUpRecords.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAPatientFamilyFollowUpRecords.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_patient_family_planning_follow_up_record = action.payload

        },

        [getAPatientFamilyFollowUpRecords.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [deletePatientPerformanceData.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [deletePatientPerformanceData.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_patient_performanceData = null

        },

        [deletePatientPerformanceData.rejected]: (state, action) => {
            state.nurseLoading = false
        },

        [getAllPatientDailyReports.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAllPatientDailyReports.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_patient_daily_report = action.payload

        },

        [getAllPatientDailyReports.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [getAllPatientTreamentReports.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAllPatientTreamentReports.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_patient_treatment_report = action.payload

        },

        [getAllPatientTreamentReports.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [deleteFamilyPlanningFollowUpRecord.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [deleteFamilyPlanningFollowUpRecord.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_patient_family_planning_follow_up_record = null

        },

        [deleteFamilyPlanningFollowUpRecord.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [getAllPatientForImmunization.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAllPatientForImmunization.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_patient_for_immunization = action.payload

        },

        [getAllPatientForImmunization.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [getAPatientVaccinationRecords.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAPatientVaccinationRecords.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.vaccination_record_for_a_patient = action.payload

        },

        [getAPatientVaccinationRecords.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [removePatientVaccinationRecords.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [removePatientVaccinationRecords.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.vaccination_record_for_a_patient = null

        },

        [removePatientVaccinationRecords.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [getAllProfiles.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAllProfiles.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_profiles = action.payload

        },

        [getAllProfiles.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [getAllBirthRecords.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAllBirthRecords.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_birth_records = action.payload

        },

        [getAllBirthRecords.rejected]: (state, action) => {
            state.nurseLoading = false
        },
        [getAllDeathRecords.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [getAllDeathRecords.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.all_death_records = action.payload

        },

        [getAllDeathRecords.rejected]: (state, action) => {
            state.nurseLoading = false
        },

        [nurseaddAPatient.pending]: (state, action) => {
            state.nurseLoading = true
        },
        [nurseaddAPatient.fulfilled]: (state, action) => {
            state.nurseLoading = false
            state.patient = action.payload

        },

        [nurseaddAPatient.rejected]: (state, action) => {
            state.nurseLoading = false
        },









    }

})


export default nurseSlice.reducer;