/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { PageTitle, PatientLaboratoryInvoicesTable, PatientPharmacyInvoicesTable, PatientRadiologyInvoicesTable, PatientRecordsInvoicesTable } from '../../components'
import { getAllCurrentUserLabInvoices, getAllCurrentUserPharmacyInvoices, getAllCurrentUserRadiologyInvoices, getAllCurrentUserRecordsInvoices, getCurrentPatientProfile } from '../../state/actions/patient.actions'


const PatientPayments = () => {
    const dispatch = useDispatch()

    const { current_user_lab_invoices, current_user_radiology_invoices, current_user_pharmacy_invoices, current_user_records_invoices } = useSelector((state) => state.patient)
    
    const [activeLink, setActiveLink] = useState('Laboratory')
    const [showTables, setShowTables] = useState({
        laboratory: true,
        pharmacy: true,
        radiology: true,
        records: true
    })

    useEffect(() => {
        dispatch(getAllCurrentUserRecordsInvoices())
        dispatch(getCurrentPatientProfile())
        dispatch(getAllCurrentUserLabInvoices())
        dispatch(getAllCurrentUserRadiologyInvoices())
        dispatch(getAllCurrentUserPharmacyInvoices())
        dispatch(getAllCurrentUserRecordsInvoices())
    }, [])

    return (
        <div>
            <PageTitle type={'patient-payments'} />

            <div className="">
                <div className="flex space-x-5 items-center border-b-2 border-gray-500 pb-2 w-1/2">
                    <button
                        type="button"
                        className={`${activeLink === 'Laboratory' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => setActiveLink(e.target.textContent)}
                    >
                        Laboratory
                    </button>
                    <button
                        type="button"
                        className={`${activeLink === 'Pharmacy' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => setActiveLink(e.target.textContent)}
                    >
                        Pharmacy
                    </button>
                    <button
                        type="button"
                        className={`${activeLink === 'Radiology' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => setActiveLink(e.target.textContent)}
                    >
                        Radiology
                    </button>
                    <button
                        type="button"
                        className={`${activeLink === 'Records' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => setActiveLink(e.target.textContent)}
                    >
                        Records
                    </button>
                </div>

                {activeLink === 'Laboratory' && (
                    <PatientLaboratoryInvoicesTable
                        showList={showTables.laboratory}
                        data={current_user_lab_invoices}
                    />
                )}

                {activeLink === 'Pharmacy' && (
                    <PatientPharmacyInvoicesTable
                        showList={showTables.pharmacy}
                        data={current_user_pharmacy_invoices}
                    />
                )}

                {activeLink === 'Radiology' && (
                    <PatientRadiologyInvoicesTable
                        showList={showTables.radiology}
                        data={current_user_radiology_invoices}
                    />
                )}

                {activeLink === 'Records' && (
                    <PatientRecordsInvoicesTable
                        showList={showTables.records}
                        data={current_user_records_invoices}
                    />
                )}
            </div>
        </div>
    )
}

export default PatientPayments