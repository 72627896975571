/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FaUserInjured } from 'react-icons/fa'

import Pagination from '../utils/Pagination'
import { useStateContext } from '../../state/context'
import { setCurrentPatient } from '../../state/actions/actions'


const DoctorAssignedPatientsTable = ({ data, showList, setShowList, title }) => {
    const dispatch = useDispatch()

    const { appState, setAppState } = useStateContext()

    const [currentData, setCurrentData] = useState([])
    const [filterState, setFilterState] = useState({ search: '', patients: [] })

    const handleFilter = () => {
        const filtered = data
            ?.filter(item => {
                return item.details.profile.patient_number.toLowerCase().includes(filterState.search.toLowerCase())
                    || item.details.first_name.toLowerCase().includes(filterState.search.toLowerCase())
                    || item.details.last_name.toLowerCase().includes(filterState.search.toLowerCase())
            })

        setFilterState({ ...filterState, patients: filtered })

        if (filterState.search === '') {
            setFilterState({ ...filterState, patients: data })
        }
    }

    useEffect(() => {
        handleFilter()
    }, [filterState.search])

    useEffect(() => {
        setFilterState({ ...filterState, patients: data })
    }, [data])



    return (
        <div className="flex justify-between items-center w-full">
            <div className="flex flex-col w-full cursor-pointer">
                <div className="">
                    <div
                        className="flex justify-start items-center space-x-"
                    // onClick={() => setShowList(!showList)}
                    >
                        {/* <h4 className="text-dark-blue text-[14px] font-bold">
                            {title}
                        </h4> */}
                        {/* <FaUserInjured className="text-dark-blue" size={20} /> */}
                        <input
                            type="text"
                            name="search"
                            placeholder='Search'
                            className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                            onChange={(e) => setFilterState({ ...filterState, [e.target.name]: e.target.value })}
                        />
                    </div>

                    {showList && (
                        <Fragment>
                            <table className="border rounded w-full cursor-default">
                                <thead className="bg-gray-100">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[2em] px-4 py-4 text-left"
                                        >
                                            S/N
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[2em] py-4 text-left"
                                        >
                                            Patient ID
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                        >
                                            Fullname
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                        >
                                            Picture
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                        >
                                            Gender
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 py-4 text-left"
                                        >

                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData?.map((patient, index) => (
                                        <tr key={`${patient.id}`}>
                                            <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {index + 1}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                {patient.details.profile.patient_number}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                {patient.details.last_name} {patient.details.first_name}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light whitespace-nowrap">
                                                <img
                                                    src={`${process.env.REACT_APP_API_ROOT}${patient.details.profile.profile_photo}`}
                                                    alt=""
                                                    className='w-[50px] h-[50px] rounded-[100%]'
                                                />
                                            </td>
                                            <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                {patient.details.profile.gender}
                                            </td>
                                            <td className=" flex text-2xl space-x-4 text-gray-900 font-semibold py-2 whitespace-nowrap">
                                                <button
                                                    type="button"
                                                    className="inline-block px-6 py-2 bg-purple-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                    onClick={() => {
                                                        const data = {
                                                            record_pkid: patient.pkid,
                                                            is_assigned_patient: true,
                                                            id: patient.details.id,
                                                            pkid: patient.details.pkid,
                                                            user: patient.details.pkid,
                                                            gender: patient.details.profile.gender,
                                                            date_of_birth: patient.details.profile.date_of_birth,
                                                            patient_number: patient.details.profile.patient_number,
                                                            profile_photo: patient.details.profile.profile_photo,
                                                            details: {
                                                                id: patient.details.profile.id,
                                                                pkid: patient.details.profile.pkid,
                                                                first_name: patient.details.profile.first_name,
                                                                last_name: patient.details.profile.last_name,
                                                            },
                                                        }
                                                        dispatch(setCurrentPatient({ data: { ...data } }))
                                                        setAppState({ ...appState, showDoctorManagePatient: !appState.showDoctorManagePatient })
                                                    }}
                                                >
                                                    Manage
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <Pagination
                                data={filterState.patients}
                                currentData={currentData}
                                setCurrentData={setCurrentData}
                            />
                        </Fragment>
                    )}
                </div>
            </div>
        </div >
    )
}

export default DoctorAssignedPatientsTable
