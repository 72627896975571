import { createAsyncThunk } from '@reduxjs/toolkit'

import { signIn, updatePassword, updatePasswordStatus, getProfile, updateProfile, updateUserDetails, patchProfilePhoto, activateUserAccount, patchOnlineStatus, updateSocketId, generateResetPasswordLink, patchUserPassword } from '../routes/auth.routes'


export const activateAccount = createAsyncThunk(
    'auth/activateAccount',
    async ({ token, navigate, toast }, { rejectWithValue }) => {
        try {
            await activateUserAccount(token)

            localStorage.removeItem('his__user')

            toast.success('Account activated successfully')
            navigate('/')

            return null
        } catch (error) {
            toast.warning('Account activation failed, refer to your site administrator to proceed.')
            return rejectWithValue(error.response.data)
        }
    }
)

export const authLogin = createAsyncThunk(
    'auth/login',
    async ({ formData, navigate, toast }, { rejectWithValue }) => {
        try {
            const { data } = await signIn(formData)

            if (data.data.is_active) {
                toast.success('Login successfully.', { autoClose: 800 })
                navigate('/updatepassword')
                return data
            } else {
                toast.warning('Activate your account first.')
                navigate('/')
                return
            }
        } catch (error) {
            toast.warning('Login failed, validate your credentials and try again.')
            return rejectWithValue(error.response.data)
        }
    }
)

export const requestPasswordReset = createAsyncThunk(
    'auth/requestPasswordReset',
    async ({ params, navigate, toast }, { rejectWithValue }) => {
        try {
            await generateResetPasswordLink(params)

            toast.success('Password reset link generated succesfully, check your mailbox to procced.')
            navigate('/')

            return null
        } catch (error) {
            console.log(error)
            return rejectWithValue(null)
        }
    }
)


export const resetUserPassword = createAsyncThunk(
    'auth/resetUserPassword',
    async ({ formData, navigate, toast }, { rejectWithValue }) => {
        try {
            await patchUserPassword(formData)

            toast.success('Password updated successfully.')
            navigate('/')

            return null
        } catch (error) {
            console.log(error.response)
            if (error.response.data["message"] === "Password can not be used twice") {
                toast.warning("You can't use this password for the second time.")
            }
            if (error.response.data.error) {
                toast.warning(error.response.data.error)
            }
            return rejectWithValue(null)
        }
    }
)



export const updateUserPassword = createAsyncThunk(
    'auth/updatepassword',
    async ({ formData, navigate, toast }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            await updatePassword(formData, token)
            await updatePasswordStatus(token)

            localStorage.removeItem('his__user')
            toast.success('Password update successful.')
            window.location.reload()
            navigate('/')

            return null
        } catch (error) {
            if (error.response.data["message"] === "You can't use this password twice") {
                toast.warning("You can't use this password for the second time.")
                return rejectWithValue(null)
            } else {
                toast.warning('An error occured while updating password')
                return rejectWithValue(null)
            }
        }
    }
)

export const updateProfilePassword = createAsyncThunk(
    'auth/updateprofilepassword',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            await updatePassword(formData, token)

            toast.success('Password update successful.')
            setModals({ ...modals, updatePasswordModal: !modals.updatePasswordModal })

            return null
        } catch (error) {
            console.log(error.response);
            error.response.data.current_password[0] && toast.warning('Incorrect current password.')
            toast.warning('An error occured while updating password')
            return rejectWithValue(error.response.data)
        }
    }
)


export const getUserProfile = createAsyncThunk(
    'auth/getprofile',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            const response = await getProfile(token)

            return response.data.profile
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }
    }
)


export const profileUpdate = createAsyncThunk(
    'auth/profileUpdate',
    async ({ formData, toast }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            await updateUserDetails(formData, token)
            const { data } = await updateProfile(formData, token)

            toast.success('Successful.')

            return data.profile
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occured on profile update.')
            return rejectWithValue(error.response.data)
        }
    }
)

export const updateProfilePhoto = createAsyncThunk(
    'auth/updateprofile_photo',
    async ({ formData, toast, setModals, modals, type }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchProfilePhoto(formData, token)

            if (type === 'webcam') {
                toast.success('Profile update was successful.')
                modals.showWebCamModal && setModals({ ...modals, showWebCamModal: !modals.showWebCamModal })
            }
            if (type === 'upload') {
                toast.success('Profile update was successful.')
            }

            return data
        } catch (error) {
            console.log(error.response);
            toast.warning('An error occured on profile update.')
            return rejectWithValue(error.response.data)
        }
    }
)

export const updateUserProfile = createAsyncThunk(
    'auth/updateprofile',
    async ({ formData, toast, navigate }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await updateProfile(formData, token)

            toast.success('Profile update was successful.')

            localStorage.removeItem('his__user__profile')
            const profile_image = `${process.env.REACT_APP_API_ROOT}${data.profile.profile_photo}`
            localStorage.setItem('his__user__profile', JSON.stringify({ ...data.profile, profile_photo: profile_image }))
            
            navigate('/dashboard')
            window.location.reload()
            
            return null
        } catch (error) {
            console.log(error.response);
            toast.warning('An error occured on profile update.')
            return rejectWithValue(error.response.data)
        }
    }
)

export const updateOnlineStatus = createAsyncThunk(
    'auth/updateOnlineStatus',
    async ({ formData }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await patchOnlineStatus(formData, token)

            return data
        } catch (error) {
            console.log(error);
            return rejectWithValue(null)
        }
    }
)


export const userLogout = createAsyncThunk(
    'auth/logout',
    async ({ toast, navigate }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const formData = {
                status: false
            }

            const { data } = await patchOnlineStatus(formData, token)

            localStorage.removeItem('his__user')
            localStorage.removeItem('his__user__profile')

            toast.success('Successfully logged out.')
            navigate('/')

            return data
        } catch (error) {
            toast.warning('An error occured on logout.')
            return rejectWithValue(error)
        }
    }
)

export const updateUserSocketId = createAsyncThunk(
    'auth/UpdateSocketId',
    async ({ formData }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await updateSocketId(formData, token)

            return data
        } catch (error) {
            console.log(error);
            return rejectWithValue(null)
        }
    }
)
