import { API } from "../config"


export const fetchDashboardData = (token) => API.get('/doctor/dashboard/', { headers: { Authorization: `Bearer ${token}` } })


export const fetchDoctors = (token) => API.get('/doctor/all/', { headers: { Authorization: `Bearer ${token}` } })


export const fetchTeams = (token) => API.get('/doctor/teams/', { headers: { Authorization: `Bearer ${token}` } })


export const generateTeam = (formData, token) => API.post('/doctor/teams/', formData, { headers: { Authorization: `Bearer ${token}` } })


export const removeTeam = (id, token) => API.delete(`/doctor/teams/${id}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchTeam = (id, token) => API.get(`/doctor/team/${id}/`, { headers: { Authorization: `Bearer ${token}` } })


export const patchTeam = (formData, token) => API.patch(`/doctor/team/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchDoctorPatients = (doc_name, token) => API.get(`/doctor/patients/${doc_name}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchPatientDependants = (his_id, token) => API.get(`/doctor/patient_dependants/${his_id}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchPatientDiagnosis = (params, token) => API.get(`/doctor/diagnoses/${params.id}/`, { headers: { Authorization: `Bearer ${token}` } })


export const generatePatientDiagnosis = (formData, token) => API.post(`/doctor/diagnosis/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchPatientDiagnosis = (formData, token) => API.patch(`/doctor/diagnosis/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchPatientPrescriptions = (params, token) => API.get(`/doctor/patient_prescriptions/${params.diagnosis}/${params.patient}/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchPatientOtherPrescriptions = (params, token) => API.get(`/doctor/patient_prescriptions_other/${params.diagnosis}/${params.patient}/`, { headers: { Authorization: `Bearer ${token}` } })


export const generatePatientPrescription = (formData, token) => API.post(`/pharmacist/add_prescription/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchLaboratoryInvestigationTypes = (token) => API.get(`/doctor/lab_investigation_types/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchLaboratoryInvestigationCategories = (token) => API.get(`/doctor/lab_investigation_categories/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchLaboratoryInvestigationSamples = (token) => API.get(`/doctor/lab_investigation_samples/`, { headers: { Authorization: `Bearer ${token}` } })


export const generatePatientLaboratoryTest = (formData, token) => API.post(`/doctor/diagnosis_labtest/${formData.patient}/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchPatientLaboratoryTests = (params, token) => API.get(`/doctor/diagnosis_labtests/${params.patient}/${params.diagnosis}/`, { headers: { Authorization: `Bearer ${token}` } })


export const patchPatientLaboratoryTest = (formData, token) => API.patch(`/doctor/update_diagnosis_labtest/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchOtherPatientLaboratoryTest = (formData, token) => API.patch(`/doctor/uploadother_diagnosis_labtest/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })


export const fetchRadiologyTestTypes = (token) => API.get(`/doctor/radiology_investigation_types/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchRadiologyTestCategories = (token) => API.get(`/doctor/radiology_investigation_categories/`, { headers: { Authorization: `Bearer ${token}` } })


export const generatePatientRadiologyTest = (formData, token) => API.post(`/doctor/diagnosis_radiotest/${formData.patient}/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchPatientRadiologyTest = (formData, token) => API.patch(`/doctor/update_diagnosis_radiotest/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchOtherPatientRadiologyTest = (formData, token) => API.patch(`/doctor/uploadother_diagnosis_radiologytest/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })


export const fetchPatientRadiologyTests = (params, token) => API.get(`/doctor/patient_radiotests/${params.patient}/${params.diagnosis}/`, { headers: { Authorization: `Bearer ${token}` } })


export const generatePatientDiagnosisFile = (formData, token) => API.post(`/doctor/upload_patient_files/${formData.patient}/${formData.diagnosis}/`, formData, { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })


export const fetchPatientDiagnosisFiles = (params, token) => API.get(`/doctor/patient_files/${params.patient}/${params.diagnosis}/`, { headers: { Authorization: `Bearer ${token}` } })


export const patchPatientDoctor = (formData, token) => API.patch(`/doctor/transfer_patient/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const generateAdmitPatient = (formData, token) => API.patch(`/doctor/admit_patient/${formData.id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const removeAdmitPatient = (formData, token) => API.patch(`/doctor/discharge_patient/${formData.id}/${formData.doctor}/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const deleteAdmitPatient = (formData, token) => API.delete(`/doctor/discharge_patient/${formData.id}/${formData.doctor}/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchBedSpaces = (token) => API.get(`/doctor/bedspaces/`, { headers: { Authorization: `Bearer ${token}` } })


export const fetchDoctorAppointments = (token) => API.get(`/doctor/appointments_records/`, { headers: { Authorization: `Bearer ${token}` } })


export const generatePatientAppointment = (formData, token) => API.post(`/doctor/book_appointment/${formData.patient}/${formData.doctor}/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchPatientAppointment = (formData, token) => API.patch(`/doctor/book_appointment/${formData.patient}/${formData.doctor}/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const fetchDoctorAssignedPatients = (token) => API.get(`/doctor/assigned_patients/`, { headers: { Authorization: `Bearer ${token}` } })


export const removeDoctorAssignedPatient = (id, token) => API.delete(`/doctor/assigned_patients/${id}/`, { headers: { Authorization: `Bearer ${token}` } })