/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CollectionsAllInvoicesTable, CollectionsLaboratoryInvoicesTable, CollectionsPharmacyInvoicesTable, CollectionsRadiologyInvoicesTable, CollectionsRecordsInvoicesTable, PageTitle } from '../../components'
import { getLaboratoryInvoices, getPharmacyInvoices, getRadiologyInvoices, getRecordsInvoices } from '../../state/actions/collection.actions'
import { useStateContext } from '../../state/context'
import { setAccumulatedPharmacyInvoice } from '../../state/actions/collection.actions'
import { getAllInvoices } from '../../state/actions/accountant.actions'

const CollectionsUnpaidInvoices = () => {
    const dispatch = useDispatch()

    const { all_invoices } = useSelector(state => state.accountant)
    const {
        laboratory_invoices, pharmacy_invoices, radiology_invoices,
        records_invoices, laboratory_invoice_update, pharmacy_invoice_update,
        radiology_invoice_update, records_invoice_update
    } = useSelector(state => state.collection)

    const { modals, setModals } = useStateContext()

    const [activeLink, setActiveLink] = useState('All')
    const [showTables, setShowTables] = useState({ laboratory: true, pharmacy: true, radiology: true, records: true, })


    useEffect(() => {
        dispatch(getAllInvoices())
        dispatch(getLaboratoryInvoices())
        dispatch(getPharmacyInvoices())
        dispatch(getRadiologyInvoices())
        dispatch(getRecordsInvoices())
    }, [laboratory_invoice_update, pharmacy_invoice_update, radiology_invoice_update, records_invoice_update])

    return (
        <div>
            <PageTitle type={'collections-unpaid-invoices'} />



            <div className="">
                <div className="flex space-x-5 items-center border-b-2 border-gray-500 pb-2 w-1/2">
                    <button
                        type="button"
                        className={`${activeLink === 'All' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => setActiveLink(e.target.textContent)}
                    >
                        All
                    </button>
                    <button
                        type="button"
                        className={`${activeLink === 'Laboratory' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => setActiveLink(e.target.textContent)}
                    >
                        Laboratory
                    </button>
                    <button
                        type="button"
                        className={`${activeLink === 'Pharmacy' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => setActiveLink(e.target.textContent)}
                    >
                        Pharmacy
                    </button>
                    <button
                        type="button"
                        className={`${activeLink === 'Radiology' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => setActiveLink(e.target.textContent)}
                    >
                        Radiology
                    </button>
                    <button
                        type="button"
                        className={`${activeLink === 'Records' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                        onClick={(e) => setActiveLink(e.target.textContent)}
                    >
                        Records
                    </button>

                </div>

                {activeLink === 'All' && (
                    <CollectionsAllInvoicesTable
                        showList={showTables.laboratory}
                        // data={all_invoices}
                    data={all_invoices?.filter(invoice => invoice.is_paid === false)}

                    />
                )}

                {activeLink === 'Laboratory' && (
                    <CollectionsLaboratoryInvoicesTable
                        showList={showTables.laboratory}
                        data={laboratory_invoices?.filter(invoice => invoice.is_paid === false)}

                    />
                )}

                {activeLink === 'Pharmacy' && (
                    <CollectionsPharmacyInvoicesTable
                        showList={showTables.pharmacy}
                        data={pharmacy_invoices?.filter(invoice => invoice.is_paid === false)}


                    />
                )}

                {activeLink === 'Radiology' && (
                    <CollectionsRadiologyInvoicesTable
                        showList={showTables.radiology}
                        data={radiology_invoices?.filter(invoice => invoice.is_paid === false)}

                    />
                )}

                {activeLink === 'Records' && (
                    <CollectionsRecordsInvoicesTable
                        showList={showTables.records}
                        data={records_invoices?.filter(invoice => invoice.is_paid === false)}

                    />
                )}
            </div>
        </div>
    )
}

export default CollectionsUnpaidInvoices