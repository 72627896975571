/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { useState } from "react"
import { FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { getSupplyPointBinCards, resetSupplyPointBinCards } from "../../../state/actions/pharmacist.actions"

import { useStateContext } from "../../../state/context"
import Pagination from "../../utils/Pagination"


const PharmacistBinCardSummaryModal = () => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { drug_update } = useSelector(state => state.root)
    const { supplypoint_inventory, supplypoint_bincards } = useSelector(state => state.pharmacist)

    const [currentData, setCurrentData] = useState([])

    useEffect(() => {
        const params = []

        supplypoint_inventory?.forEach((item => {
            const spoint = item?.supplypoint
            const drug = item?.supplypoint_druginventory?.bincard_drug?.pkid

            params.push({ spoint, drug })
        }))
        dispatch(getSupplyPointBinCards({ params }))
    }, [drug_update])
    

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className="text-sky-500 text-[18px] font-medium capitalize">
                            Bin Card Summary
                        </h4>
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                dispatch(resetSupplyPointBinCards())
                                setModals({ ...modals, showPharmacistBinCardSummaryModal: !modals.showPharmacistBinCardSummaryModal })
                            }}
                            size={20}
                        />
                    </div>
                    <div className="max-h-[400px] overflow-y-auto patient__prescriptions">

                        <table className="border rounded w-full cursor-default">
                            <thead className="border-b-2 border-gray-200">
                                <tr>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] px-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] py-4 text-left"
                                    >
                                        Medicine
                                    </th>
                                    <th
                                        scope="col"
                                        className="border-b-2 border-b-sky-400 bg-sky-200 text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Quantity Received
                                    </th>
                                    <th
                                        scope="col"
                                        className="border-b-2 border-b-orange-400 bg-orange-200 text-sm font-medium text-gray-900 pr-[2em] py-4 text-left"
                                    >
                                        Quantity Issued
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentData?.map((item, index) => (
                                    <tr key={`${item.id}`}>
                                        <td className="px-4 py-2 whitespace-nowrap text-[10px] font-bold text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item?.supplypointbincard_inventory?.supplypoint_druginventory?.bincard_drug?.name}
                                        </td>
                                        <td className="bg-sky-200 text-sm text-gray-900 font-light pl-4 py-2 whitespace-nowrap">
                                            {item?.quantity_received}
                                            {/* {item?.supplypointbincard_inventory?.quantity_received} */}
                                        </td>
                                        <td className="bg-orange-200 text-sm text-gray-900 font-light pl-4 py-2 whitespace-nowrap">
                                            {item?.quantity_issued}
                                            {/* {item?.supplypointbincard_inventory?.quantity_issued} */}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <Pagination
                        data={supplypoint_bincards}
                        currentData={currentData}
                        setCurrentData={setCurrentData}
                    />
                </div>
            </div>
        </div>
    )
}

export default PharmacistBinCardSummaryModal
