/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { AiOutlineCaretDown } from 'react-icons/ai'
import { TbReportAnalytics } from 'react-icons/tb'
import { useDispatch, useSelector } from 'react-redux'
import PharmacistClinicalRepositoryDetailsTable from '../../components/tables/PhramacistClinicalRepositoryDetailsTable'
import { getClinicalData } from '../../state/actions/pharmacist.actions'
import Pagination from '../../components/utils/Pagination'
import { setCurrentClinicalDataRepo } from '../../state/actions/actions'



const PharmacistClinicalData = () => {
    const dispatch = useDispatch()

    const { clinical_data } = useSelector(state => state.pharmacist)

    const [data, setData] = useState([])
    const [currentData, setCurrentData] = useState([])
    const [formData, setFormData] = useState({ name: '', symptoms: '' })
    const [viewClinicalDataDetail, setViewClinicalDataDetail] = useState(false)


    useEffect(() => {
        if (clinical_data) {
            setData(clinical_data)
            console.log(data, 'this is data')
        }
    }, [clinical_data])

    const handleChange = (e, name) => {
        setFormData({ ...formData, [name]: e.target.value })
        console.log(formData);
    }

    const handleSubmit = () => {
        console.log(formData)
        dispatch(getClinicalData({ formData }))
    }

    return (
        <div>
            {/* <PageTitle type={'laboratorist-reports'} /> */}

            {!viewClinicalDataDetail && <div className='flex flex-col w-full cursor-pointer'>
                <div className='py-2'>
                    <div className='flex justify-start items-center space-x-4 bg-gray-200 p-1'>
                        <h4 className='text-dark-blue text-[14px] font-bold'>
                            Clinical Data Repository (CDR)
                        </h4>
                        <TbReportAnalytics
                            className='text-dark-blue'
                            size={20}
                        />
                        <input className='ml-14 px-3 w-[250px] font-light rounded-sm text-[12px] italic' type="search" name="name" placeholder='Enter diagnosis name' id=""
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        <input className='ml-14 px-3 w-[250px] font-light rounded-sm text-[12px] italic' type="search" name="symptoms" placeholder='Enter symptoms to search' id=""
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        <button type="submit"
                            className="inline-block px-4 py-2 border border-teal-600 text-teal-600 font-medium text-xs leading-tight uppercase shadow-md hover:shadow-lg hover:translate-x-1  hover:bg-blue-500 hover:text-white focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                            onClick={() => handleSubmit()}
                        >Search</button>
                    </div>
                    <table className='border rounded w-full cursor-default'>
                        <thead className=''>
                            <tr>
                                <th
                                    scope='col'
                                    className='text-[10px] font-medium text-gray-900 pr-[4em] px-4 text-left capitalize'
                                >
                                    S/N
                                </th>

                                <th
                                    scope='col'
                                    className='text-[10px] font-medium text-gray-900 pr-[5em] text-left capitalize'
                                >
                                    Diagnosis Name
                                </th>

                                <th
                                    scope='col'
                                    className='text-[10px] font-medium text-gray-900 text-left capitalize'
                                >
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.length > 0 && data?.map((item, index) => (
                                <tr
                                    className={`relative w-full `}

                                    key={index}
                                >
                                    <td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
                                        {index + 1}
                                    </td>
                                    <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                        {item.diagnosis_name}
                                    </td>
                                    <button
                                        type="button"
                                        className="inline-block px-4 py-2 border border-teal-600 text-teal-600 font-medium text-xs leading-tight uppercase shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                        onClick={() => {
                                            setViewClinicalDataDetail(!viewClinicalDataDetail)
                                            dispatch(setCurrentClinicalDataRepo({ data: item }))
                                            // setModals({ ...modals, showPharmacistViewInvoiceModal: !modals.showPharmacistViewInvoiceModal })

                                        }}
                                    >
                                        Details
                                    </button>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination
                        data={data}
                        currentData={currentData}
                        setCurrentData={setCurrentData}
                    />
                </div>
            </div>}
            {viewClinicalDataDetail && <>

                <PharmacistClinicalRepositoryDetailsTable
                    viewClinicalDataDetail={viewClinicalDataDetail}
                    setViewClinicalDataDetail={setViewClinicalDataDetail}
                />
            </>}
        </div>
    )
}

export default PharmacistClinicalData