import React, { useState, useEffect } from 'react'
import { useStateContext } from '../../../state/context'
import { FaTimesCircle } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { createARadiologyInvestigationType, updateARadiologyInvestigationType } from '../../../state/actions/radiologist.actions'
import { setDeleteRadiologyInvestigationType } from '../../../state/actions/actions'
import { toast } from 'react-toastify'

const RadiologyAddInvestigationTypeModal = () => {
    const { modals, setModals } = useStateContext()
    const dispatch = useDispatch()
    const { all_radiology_category_test } = useSelector((state) => state.radiologist)
    const { set_delete_radiology_investigation_type, } = useSelector((state) => state.root)


    const [formData, setFormData] = useState({
        title: '',
        investigation_category: '',
        id: '',
        is_available: true
    })
    const [checked, setChecked] = useState(false)

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        { modals.showAddRadiologyInvestigationTypeModal && (dispatch(createARadiologyInvestigationType({ formData, toast, setModals: setModals, modals: modals }))) }
        { modals.showUpdateRadiologyInvestigationTypeModal && (dispatch(updateARadiologyInvestigationType({ formData, toast, setModals: setModals, modals: modals }))) }
        dispatch(setDeleteRadiologyInvestigationType({ data: null }))

    }

    const handleCheckboxChange = () => {
        setChecked(!checked);
        setFormData({ ...formData, is_available: !checked });
    };

    useEffect(() => {
        if (modals.showUpdateRadiologyInvestigationTypeModal) {
            setFormData({
                ...formData, investigation_category: set_delete_radiology_investigation_type?.category_id,
                title: set_delete_radiology_investigation_type?.title,
                id: set_delete_radiology_investigation_type?.investigation_id
            })
        }

    }, [set_delete_radiology_investigation_type])

    useEffect(() => {
        setChecked(set_delete_radiology_investigation_type?.status ? set_delete_radiology_investigation_type?.status : checked)

    }, [set_delete_radiology_investigation_type])


    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>

                            <>

                                {modals.showAddRadiologyInvestigationTypeModal && (
                                    <h4 className='text-dark-green text-[20px] font-bold'>
                                        Add Investigation Type
                                    </h4>
                                )}
                                {modals.showUpdateRadiologyInvestigationTypeModal && (
                                    <h4 className='text-dark-green text-[20px] font-bold'>
                                        Update Investigation Type
                                    </h4>
                                )}

                                <FaTimesCircle
                                    className='text-dark-yellow cursor-pointer'
                                    showUpdateRadiologyInvestigationTypeModal
                                    onClick={() => {
                                        if (modals.showAddRadiologyInvestigationTypeModal) {
                                            setModals({
                                                ...modals, showAddRadiologyInvestigationTypeModal:
                                                    !modals.showAddRadiologyInvestigationTypeModal,
                                            })
                                        }
                                        if (modals.showUpdateRadiologyInvestigationTypeModal) {
                                            setModals({
                                                ...modals,
                                                showUpdateRadiologyInvestigationTypeModal:
                                                    !modals.showUpdateRadiologyInvestigationTypeModal,
                                            })

                                        }
                                    }





                                    }
                                    size={20}
                                />

                            </>

                        </div>




                        <form onSubmit={handleSubmit} >
                            {modals.showAddRadiologyInvestigationTypeModal && <div className=''>
                                <select name="investigation_category" placeholder='Type' id="" className='border rounded mt-2 pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 '
                                    onChange={(e) =>
                                        handleChange(e, e.target.name)
                                    }
                                    required
                                >
                                    <option value={formData.investigation_type}>Select Investigation category</option>
                                    {all_radiology_category_test?.map((type, index) => (
                                        <option value={type.pkid}>{type.title}</option>
                                    ))}

                                </select>

                                <input
                                    type='text'
                                    name='title'
                                    placeholder='Name'
                                    value={formData.title}


                                    className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
                                    onChange={(e) =>
                                        handleChange(e, e.target.name)
                                    }
                                />


                            </div>}

                            {modals.showUpdateRadiologyInvestigationTypeModal && <div className=''>
                                {/* <select name="investigation_category" placeholder='Type' id="" className='border rounded mt-2 pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 '
                                    onChange={(e) =>
                                        handleChange(e, e.target.name)
                                    }
                                    required
                                >
                                    <option value={formData.investigation_type}>Select Investigation category</option>
                                    {all_radiology_category_test?.map((type, index) => (
                                        <option value={type.pkid}>{type.title}</option>
                                    ))}

                                </select> */}

                                <input
                                    type='text'
                                    name='title'
                                    placeholder='Name'
                                    value={formData.title}


                                    className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
                                    onChange={(e) =>
                                        handleChange(e, e.target.name)
                                    }
                                />
                                <div className='flex space-x-2 mt-2'>
                                    <input type="checkbox"
                                        className='w-[20px] h-[20px]'
                                        checked={checked}
                                        onChange={handleCheckboxChange}
                                        name="is_available" id="" />
                                    <p>Status</p>
                                </div>



                            </div>}




                            <div className='mt-4 flex justify-center items-center'>
                                <button
                                    type='submit'
                                    className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
                                // onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>

                        </form>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default RadiologyAddInvestigationTypeModal