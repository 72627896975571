import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useStateContext } from '../../../state/context'
import { FaTimesCircle } from 'react-icons/fa'
import { addAPatientTreatmentReport } from '../../../state/actions/nurse.actions'
import { toast } from 'react-toastify'


const NurseAddPatientTreatmentReportModal = () => {
    const { all_nurse_patients } = useSelector((state) => state.nurse)
    const { set_patient_id } = useSelector((state) => state.root)
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({

        report: '',
        id: ''

    })
    const handleChange = (e, name) => { setFormData((prevState) => ({ ...prevState, [name]: e.target.value })) }
    const { modals, setModals } = useStateContext()

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(addAPatientTreatmentReport({ formData, toast, setModals, modals }))

    }
    useEffect(() => {
        setFormData({ ...formData, id: set_patient_id })
    }, [])


    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>

                            <>
                                <h4 className='text-dark-green text-[16px] font-semibold'>
                                    Add treatment report
                                </h4>
                                <FaTimesCircle
                                    className='text-dark-yellow cursor-pointer'
                                    onClick={() => (
                                        setModals({
                                            ...modals,
                                            showAddAdmitedPatientTreatmentReportModal:
                                                !modals.showAddAdmitedPatientTreatmentReportModal,
                                        })
                                    )}
                                    size={20}
                                />
                            </>


                        </div>




                        <form onSubmit={handleSubmit}>

                            {/* <h4 className='text-sm font-semibold py-2'>Investigation category</h4> */}
                            <div className='flex justify-between-items-center space-x-10'>
                                <div className='space-y-2 w-full'>

                                    <input
                                        type="title"
                                        name='report'

                                        // value={`${filtered[0]?.user_details?.first_name} ${filtered[0]?.user_details?.last_name} - [${filtered[0]?.patient_number}]`}
                                        placeholder="Enter report"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                        className="border rounded w-full h-[100px] px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"

                                    />

                                </div>
                            </div>

                            <div className='mt-4 flex justify-center items-center'>
                                <button
                                    type='submit'
                                    className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'

                                >
                                    Submit
                                </button>
                            </div>

                        </form>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default NurseAddPatientTreatmentReportModal