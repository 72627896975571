import React, { useState, useEffect, Fragment } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useStateContext } from '../../../state/context'
import { toast } from 'react-toastify'
import {
	AddDeathRecords,
	getADeathRecordData,
	removeSingleDeathRecord,
} from '../../../state/actions/records.actions'

const RecordsAddDeathModal = (props) => {
	const { modals, setModals } = useStateContext()
	const dispatch = useDispatch()

	const { show_death_records, set_single_birth_record } = useSelector((state) => state.root)

	const { all_profiles } = useSelector((state) => state.nurse)
	const [showDeathDetails, setshowDeathDetails] = useState(false)


	const [formData, setFormData] = useState({
		patient: '',
		patient_number: '',
		first_name: '',
		last_name: '',
		guardian_name: '',
		address: '',
		date_of_death: '',
		phone_number: '',
		time_of_death: '',
		cause_of_death: '',
		id: '',

	})


	const handleChange = (e, name) => {
		setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
	}


	const handleSubmit = (e) => {
		e.preventDefault()
		console.log(formData)
		dispatch(AddDeathRecords({ formData, toast, modals, setModals }))

	}
	const filtered = all_profiles?.filter((item) => {
		return item.patient_number === `${formData.patient_number}`
	})[0]


	useEffect(() => {

		setFormData({
			...formData, patient_number: filtered?.patient_number, first_name: filtered?.first_name,
			last_name: filtered?.last_name, address: filtered?.address, gender: filtered?.gender,
			phone_number: filtered?.patient_phone, id: filtered?.user_id

		})



	}, [filtered])

	return (
		<div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
			<div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
				<div className='flex flex-col space-y-5'>
					<div className='flex justify-between items-center'>
						<h4 className='text-dark-green text-[20px] font-bold'>
							{modals.showDeathRecordModal
								? 'Death Record'
								: 'Add Death Record'}
						</h4>
						<FaTimesCircle
							className='text-dark-yellow cursor-pointer'
							onClick={() => {
								modals.showDeathRecordModal &&
									setModals({
										...modals,
										showDeathRecordModal:
											!modals.showDeathRecordModal,
									})
								modals.showAddDeathRecordModal &&
									setModals({
										...modals,
										showAddDeathRecordModal:
											!modals.showAddDeathRecordModal,
									})
								const data = null
								dispatch(removeSingleDeathRecord({ data }))
							}}
							size={20}
						/>
					</div>
					{modals.showDeathRecordModal && (
						<form onSubmit={handleSubmit}>
							<div className='flex justify-between-items-center space-x-10'>
								<div className='space-y-5 w-full'>
									<div>
										<p className='text-sm font-semibold'>First name</p>
										<input
											type='text'
											name='first_name'
											placeholder='First name'
											value={
												show_death_records?.first_name
											}
											className='bg-slate-300 border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
											onChange={(e) =>
												handleChange(e, e.target.name)
											}
										/>
									</div>

									<div>
										<p className='text-sm font-semibold'>Last name</p>
										<input
											type='text'
											name='last_name'
											value={
												show_death_records?.last_name
											}
											placeholder='Last name'
											className='border bg-slate-300 rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
											onChange={(e) =>
												handleChange(e, e.target.name)
											}
										/>
									</div>

									<div>
										<p className='text-sm font-semibold'>Patient number</p>
										<input
											type='text'
											disabled
											name='date_of_death'
											value={show_death_records?.patient_number}

											className='border bg-slate-300 rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
											onChange={(e) =>
												handleChange(e, e.target.name)
											}
										/>
									</div>

									<div>
										<p className='text-sm font-semibold'>Time of death</p>
										<input
											type='time'
											name='time_of_death'
											placeholder='Time'
											value={show_death_records?.time}
											className='border bg-slate-300 rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
											onChange={(e) =>
												handleChange(e, e.target.name)
											}
										/>
									</div>

									<div className='mt-3'>
										<p className='text-sm font-semibold'>Gender</p>
										<input
											type='text'
											name='cause_of_death'
											placeholder='Cause of death'
											value={show_death_records?.gender}
											className='border bg-slate-300 rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
											onChange={(e) =>
												handleChange(e, e.target.name)
											}
										/>
									</div>
								</div>

								<div className='space-y-5 w-full'>
									<div>
										<p className='text-sm font-semibold'>Phone number</p>
										<input
											type='number'
											name='phone_number'
											placeholder='Phone number'
											value={
												show_death_records?.phone_number
											}
											className='border bg-slate-300 rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
											onChange={(e) =>
												handleChange(e, e.target.name)
											}
										/>
									</div>

									<div>
										<p className='text-sm font-semibold'>Address</p>
										<input
											type='text'
											name='address'
											value={show_death_records?.address}
											placeholder='Address'
											className='border bg-slate-300 rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
											onChange={(e) =>
												handleChange(e, e.target.name)
											}
										/>
									</div>

									<div>
										<p className='text-sm font-semibold'>Guardian</p>
										<input
											type='text'
											name='guardian_name'
											placeholder='Guardian'
											value={show_death_records?.guardian}
											className='border bg-slate-300 rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
											onChange={(e) =>
												handleChange(e, e.target.name)
											}
										/>
									</div>

									<div>
										<p className='text-sm font-semibold'>Date of death</p>
										<input
											type='date'
											name='gender'
											value={show_death_records?.date}
											placeholder='Gender'
											className='border bg-slate-300 rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
											onChange={(e) =>
												handleChange(e, e.target.name)
											}
										/>
									</div>

									<div className='mt-3'>
										<p className='text-sm font-semibold'>Cause of death</p>
										<input
											type='text'
											name='cause_of_death'
											placeholder='Cause of death'
											value={show_death_records?.cause_of_death}
											className='border bg-slate-300 rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
											onChange={(e) =>
												handleChange(e, e.target.name)
											}
										/>
									</div>


								</div>
							</div>

						</form>
					)}

					{modals.showAddDeathRecordModal && (
						<form
							onSubmit={handleSubmit}
						>
							<div className='flex'>
								<input
									type='text'
									name='patient_number'
									placeholder='Enter patient Number'

									className='border bg-slate-300 rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
									onChange={(e) =>
										handleChange(e, e.target.name)
									}
								/>



							</div>
							{filtered?.pkid && filtered?.patient_number && (
								<Fragment>
									<div className='flex justify-between-items-center space-x-10'>
										<div className='space-y-5 w-full'>
											<div>
												<input
													type='text'
													name='first_name'
													placeholder='First name'
													readOnly
													value={formData.first_name}
													onChange={(e) =>
														handleChange(
															e,
															e.target.name
														)
													}

													className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'

												/>
											</div>

											<div>
												<input
													type='text'
													name='last_name'
													placeholder='Last name'
													value={formData.last_name}
													readOnly

													className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
													onChange={(e) =>
														handleChange(
															e,
															e.target.name
														)
													}
												/>
											</div>

											<div>
												<p className='text-sm font-semibold'>Date of death</p>
												<input
													type='date'
													required
													name='date_of_death'
													placeholder='Date'
													className='border rounded w-full  h-5 px-4 py-5 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
													onChange={(e) =>
														handleChange(
															e,
															e.target.name
														)
													}
												/>
											</div>

											<div>
												<p className='text-sm font-semibold'>Time of death</p>
												<input
													type='time'
													name='time_of_death'
													placeholder='Time'
													required
													className='border rounded w-full  h-5 px-4 py-5 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
													onChange={(e) =>
														handleChange(
															e,
															e.target.name
														)
													}
												/>
											</div>
										</div>

										<div className='space-y-5 w-full'>
											<div>
												<input
													type='text'
													name='gender'
													placeholder='Gender'
													value={formData.gender}
													readOnly

													className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
													onChange={(e) =>
														handleChange(
															e,
															e.target.name
														)
													}
												/>
											</div>
											<div>
												<input
													type='number'
													name='phone_number'
													readOnly
													placeholder='Phone number'
													value={formData.phone_number}
													className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
													onChange={(e) =>
														handleChange(
															e,
															e.target.name
														)
													}
												/>
											</div>

											<div>
												<input
													type='text'
													name='address'
													placeholder='Address'
													value={formData.address}
													className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
													readOnly

												/>
											</div>

											<div>

												<input
													type='text'
													name='guardian_name'
													required
													placeholder='Enter Guardian name'
													className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
													onChange={(e) =>
														handleChange(
															e,
															e.target.name
														)
													}

												/>
											</div>
										</div>
									</div>

									<div className='flex'>
										<input
											type='text'
											name='cause_of_death'
											placeholder='Cause of Death'

											className='border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
											onChange={(e) =>
												handleChange(e, e.target.name)
											}
										/>
									</div>

									<div className='mt-4 flex justify-center items-center'>
										<button
											type='submit'
											className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'

										>
											Submit
										</button>
									</div>
								</Fragment>
							)}
						</form>
					)}
				</div>
			</div>
		</div>
	)
}

export default RecordsAddDeathModal
