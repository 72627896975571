import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AdmittedPatientInitialPerformanceTable from '../../components/tables/nursesTable/AdmittedPatientInitialPerformanceTable'
import { useStateContext } from '../../state/context'
import { setPatientID } from '../../state/actions/actions'
import { deletePatientPerformanceData } from '../../state/actions/nurse.actions'
import AdmittedPatientsDailyReportTable from '../../components/tables/nursesTable/AdmittedPatientsDailyReportTable'
import AdmittedPatientTreatmentTable from '../../components/tables/nursesTable/AdmittedPatientTreatmentTable'


const NurseAdmittedPatientManagementPage = () => {
    const [InitialPerformanceTable, setInitialPerformanceTable] = useState(true)
    const [dailyReportTable, setDailyReportTable] = useState(false)
    const [treatmentNoteTable, setTreatmentNoteTable] = useState(false)
    const [buttonColorIndex, setButtonColorIndex] = useState(0)

    const { modals, setModals } = useStateContext()
    const { opdState, setOpdState } = useStateContext()
    const dispatch = useDispatch()
    const { set_patient_id } = useSelector((state) => state.root)


    return (
        <div>
            <div className='py-2'>
                <div className='flex justify-between mb-2 item-center '>

                    <div className='space-x-2'>
                        <button
                            type="button"
                            className={`${buttonColorIndex == 0 ? "px-6 py-2.5 bg-slate-300 border text-black font-medium text-xs uppercase rounded shadow-md" : "px-6 py-2.5 bg-white border text-black font-medium text-xs uppercase rounded shadow-md"}`}
                            onClick={() => {
                                setButtonColorIndex(0)
                                setInitialPerformanceTable(true)
                                setDailyReportTable(false)
                                setTreatmentNoteTable(false)
                            }}
                        >
                            Initial Performance
                        </button>
                        <button
                            type="button"
                            className={`${buttonColorIndex == 1 ? "px-6 py-2.5 bg-slate-300 border text-black font-medium text-xs uppercase rounded shadow-md" : "px-6 py-2.5 bg-white border text-black font-medium text-xs uppercase rounded shadow-md"}`}
                            onClick={() => {
                                setButtonColorIndex(1)
                                setInitialPerformanceTable(false)
                                setDailyReportTable(true)
                                setTreatmentNoteTable(false)
                            }}
                        >
                            Daily report
                        </button>
                        <button
                            type="button"
                            className={`${buttonColorIndex == 2 ? "px-6 py-2.5 bg-slate-300 border text-black font-medium text-xs uppercase rounded shadow-md" : "px-6 py-2.5 bg-white border text-black font-medium text-xs uppercase rounded shadow-md"}`}
                            onClick={() => {
                                setButtonColorIndex(2)
                                setInitialPerformanceTable(false)
                                setDailyReportTable(false)
                                setTreatmentNoteTable(true)
                            }}
                        >
                            Patient treatment
                        </button>
                    </div>
                    <div>
                        <button
                            type="button"
                            className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                            onClick={() => {
                                setOpdState({ ...opdState, showAdmittedPatientManagement: false })
                                dispatch(setPatientID({ data: null }))
                            }}
                        >
                            Back
                        </button>
                    </div>
                </div>


                {InitialPerformanceTable && (
                    <AdmittedPatientInitialPerformanceTable
                        InitialPerformanceTable={InitialPerformanceTable}
                        setInitialPerformanceTable={setInitialPerformanceTable}
                        dailyReportTable={dailyReportTable}
                        setDailyReportTable={setDailyReportTable}
                        treatmentNoteTable={treatmentNoteTable}
                        setTreatmentNoteTable={setTreatmentNoteTable}
                        buttonColorIndex={buttonColorIndex}
                        setButtonColorIndex={setButtonColorIndex}
                    />)}
                {dailyReportTable && (
                    <AdmittedPatientsDailyReportTable
                        InitialPerformanceTable={InitialPerformanceTable}
                        setInitialPerformanceTable={setInitialPerformanceTable}
                        dailyReportTable={dailyReportTable}
                        setDailyReportTable={setDailyReportTable}
                        treatmentNoteTable={treatmentNoteTable}
                        setTreatmentNoteTable={setTreatmentNoteTable}
                        buttonColorIndex={buttonColorIndex}
                        setButtonColorIndex={setButtonColorIndex}
                    />)}
                {treatmentNoteTable && (
                    <AdmittedPatientTreatmentTable
                        InitialPerformanceTable={InitialPerformanceTable}
                        setInitialPerformanceTable={setInitialPerformanceTable}
                        dailyReportTable={dailyReportTable}
                        setDailyReportTable={setDailyReportTable}
                        treatmentNoteTable={treatmentNoteTable}
                        setTreatmentNoteTable={setTreatmentNoteTable}
                        buttonColorIndex={buttonColorIndex}
                        setButtonColorIndex={setButtonColorIndex}
                    />)}
            </div>
        </div>
    )
}

export default NurseAdmittedPatientManagementPage