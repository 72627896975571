/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { BsPersonPlus } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'

import { PageTitle } from '../../components'
import { useStateContext } from '../../state/context'
import AntenatalTable from '../../components/tables/nursesTable/AntenatalTable'
import { getAllPatients, getAllBookedAntenatals } from '../../state/actions/nurse.actions'
import PatientMedicalHistoryTable from '../../components/tables/nursesTable/PatientMedicalHistoryTable'
import Pagination from '../../components/utils/Pagination'

const NurseAntenatal = () => {
	const dispatch = useDispatch()

	const { modals, opdState, setOpdState } = useStateContext()

	const { all_booked_antenatals } = useSelector((state) => state.nurse)

	const [currentData, setCurrentData] = useState([])

	useEffect(() => {
		return () => {
			setOpdState({ ...opdState, showPatientAntenatalRecords: false })
		}
	}, [])

	useEffect(() => {
		dispatch(getAllPatients())
	}, [])

	useEffect(() => {
		dispatch(getAllBookedAntenatals())
	}, [modals.showBookAntenatalModal])

	return (
		<div className='flex flex-col w-full cursor-pointer'>

			<PageTitle type={"nurse_antenatal"} />
			<div></div>

			{!opdState.showPatientAntenatalRecords && (
				<div className='py-2'>
					<div className='flex justify-start items-center space-x-4 bg-gray-200 p-1'>
						<h4 className='text-dark-blue text-[14px] font-bold'>
							Patients
						</h4>
						<BsPersonPlus
							className='text-dark-blue'
							size={20}
						/>
					</div>
					<table className='border rounded w-full cursor-default'>
						<thead className=''>
							<tr>
								<th
									scope='col'
									className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
								>
									S/N
								</th>

								<th
									scope='col'
									className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
								>
									Patient Number
								</th>
								<th
									scope='col'
									className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
								>
									Full name
								</th>

								<th
									scope='col'
									className='text-sm font-medium text-gray-900 py-4 text-left'
								>
									Gender
								</th>
								<th
									scope='col'
									className='text-sm font-medium text-gray-900 py-4 text-left'
								>
									Date Booked
								</th>


								<th
									scope='col'
									className='text-sm font-medium text-gray-900 py-4 text-left'
								>
									Actions
								</th>
							</tr>
						</thead>
						<AntenatalTable data={currentData} />
					</table>

					<Pagination
						data={all_booked_antenatals}
						currentData={currentData}
						setCurrentData={setCurrentData}
					/>
				</div>
			)}
			{opdState.showPatientAntenatalRecords && (
				<PatientMedicalHistoryTable />
			)}



		</div>
	)
}

export default NurseAntenatal