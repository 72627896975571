import { FaSyringe, FaUserNurse, FaUserMd } from 'react-icons/fa'
import { FiRadio } from 'react-icons/fi'
import { MdLocalPharmacy, MdAdminPanelSettings } from 'react-icons/md'
import { RiHotelBedFill } from 'react-icons/ri'
import { FaWallet } from 'react-icons/fa'
import { SiBandlab } from 'react-icons/si'
import { BsFiles } from 'react-icons/bs'

const SectionsSummary = (props) => {
    return (
        <div className="flex flex-wrap justify-between items-center">
            <div className="cursor-pointer w-[180px] mb-10 p-2 hover:bg-gray-200 bg-white rounded-lg border-2 border-gray-50 hover:bg-opacity-50 hover:backdrop-blur-md hover:border-0  shadow-lg ease-in-out duration-300">
                <div className="flex justify-between items-center gap-5">
                    <h4 className='text-dark-green'>{props.patients ? props.patients : 0}</h4>
                    <RiHotelBedFill
                        className='text-dark-green'
                    />
                </div>
                <p className='text-dark-green text-[10px] mt-4'>Total patients</p>
            </div>

            <div className="cursor-pointer w-[180px] mb-10 p-2 hover:bg-gray-200 bg-white rounded-lg border-2 border-gray-50 hover:bg-opacity-50 hover:backdrop-blur-md hover:border-0  shadow-lg ease-in-out duration-300">
                <div className="flex justify-between items-center gap-5">
                    <h4 className='text-yellow-400'>{props.newpatients ? props.newpatients : 0}</h4>
                    <RiHotelBedFill
                        className='text-yellow-400'
                    />
                </div>
                <p className='text-yellow-400 text-[10px] mt-4'>New patients</p>
            </div>

            <div className="cursor-pointer w-[180px] mb-10 p-2 hover:bg-gray-200 bg-white rounded-lg border-2 border-gray-50 hover:bg-opacity-50 hover:backdrop-blur-md hover:border-0  shadow-lg ease-in-out duration-300">
                <div className="flex justify-between items-center gap-5">
                    <h4 className='text-dark-blue'>{props.doctors ? props.doctors : 0}</h4>
                    <FaUserMd
                        className='text-dark-blue'
                    />
                </div>
                <p className='text-dark-blue text-[10px] mt-4'>Active doctors</p>
            </div>

            <div className="cursor-pointer w-[180px] mb-10 p-2 hover:bg-gray-200 bg-white rounded-lg border-2 border-gray-50 hover:bg-opacity-50 hover:backdrop-blur-md hover:border-0  shadow-lg ease-in-out duration-300">
                <div className="flex justify-between items-center gap-5">
                    <h4 className='text-dark-yellow'>{props.pharmacists ? props.pharmacists : 0}</h4>
                    <MdLocalPharmacy
                        className='text-dark-yellow'
                    />
                </div>
                <p className='text-dark-yellow text-[10px] mt-4'>Active pharmacist</p>
            </div>

            <div className="cursor-pointer w-[180px] mb-10 p-2 hover:bg-gray-200 bg-white rounded-lg border-2 border-gray-50 hover:bg-opacity-50 hover:backdrop-blur-md hover:border-0  shadow-lg ease-in-out duration-300">
                <div className="flex justify-between items-center gap-5">
                    <h4 className='text-blue-400'>{props.nurses ? props.nurses : 0}</h4>
                    <FaUserNurse
                        className='text-blue-400'
                    />
                </div>
                <p className='text-blue-400 text-[10px] mt-4'>Active nurses</p>
            </div>

            <div className="cursor-pointer w-[180px] mb-10 p-2 hover:bg-gray-200 bg-white rounded-lg border-2 border-gray-50 hover:bg-opacity-50 hover:backdrop-blur-md hover:border-0  shadow-lg ease-in-out duration-300">
                <div className="flex justify-between items-center gap-5">
                    <h4 className='text-orange-400'>{props.laboratorists ? props.laboratorists : 0}</h4>
                    <SiBandlab
                        className='text-orange-400'
                    />
                </div>
                <p className='text-orange-400 text-[10px] mt-4'>Active laboratorist</p>
            </div>

            <div className="cursor-pointer w-[180px] mb-10 p-2 hover:bg-gray-200 bg-white rounded-lg border-2 border-gray-50 hover:bg-opacity-50 hover:backdrop-blur-md hover:border-0  shadow-lg ease-in-out duration-300">
                <div className="flex justify-between items-center gap-5">
                    <h4 className='text-purple-500'>{props.records ? props.records : 0}</h4>
                    <BsFiles
                        className='text-purple-500'
                    />
                </div>
                <p className='text-purple-500 text-[10px] mt-4'>Active records</p>
            </div>

            <div className="cursor-pointer w-[180px] mb-10 p-2 hover:bg-gray-200 bg-white rounded-lg border-2 border-gray-50 hover:bg-opacity-50 hover:backdrop-blur-md hover:border-0  shadow-lg ease-in-out duration-300">
                <div className="flex justify-between items-center gap-5">
                    <h4 className='text-yellow-600'>{props.accountants ? props.accountants : 0}</h4>
                    <FaWallet
                        className='text-yellow-600'
                    />
                </div>
                <p className='text-yellow-600 text-[10px] mt-4'>Active accountants</p>
            </div>

            <div className="cursor-pointer w-[180px] mb-10 p-2 hover:bg-gray-200 bg-white rounded-lg border-2 border-gray-50 hover:bg-opacity-50 hover:backdrop-blur-md hover:border-0  shadow-lg ease-in-out duration-300">
                <div className="flex justify-between items-center gap-5">
                    <h4 className='text-sky-500'>{props.opd_prescriptions}</h4>
                    <FaSyringe
                        className='text-sky-500'
                    />
                </div>
                <p className='text-sky-500 text-[10px] mt-4'>OPD patients</p>
            </div>

            <div className="cursor-pointer w-[180px] mb-10 p-2 hover:bg-gray-200 bg-white rounded-lg border-2 border-gray-50 hover:bg-opacity-50 hover:backdrop-blur-md hover:border-0  shadow-lg ease-in-out duration-300">
                <div className="flex justify-between items-center gap-5">
                    <h4 className='text-indigo-500'>{props.admins ? props.admins : 0}</h4>
                    <MdAdminPanelSettings
                        className='text-indigo-500'
                    />
                </div>
                <p className='text-indigo-500 text-[10px] mt-4'>Active admins</p>
            </div>

            <div className="cursor-pointer w-[180px] mb-10 p-2 hover:bg-gray-200 bg-white rounded-lg border-2 border-gray-50 hover:bg-opacity-50 hover:backdrop-blur-md hover:border-0  shadow-lg ease-in-out duration-300">
                <div className="flex justify-between items-center gap-5">
                    <h4 className='text-violet-700'>{props.radiologists ? props.radiologists : 0}</h4>
                    <FiRadio
                        className='text-violet-700'
                    />
                </div>
                <p className='text-violet-700 text-[10px] mt-4'>Active radiologist</p>
            </div>
        </div>
    )
}

export default SectionsSummary