/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BsPersonPlus } from 'react-icons/bs'

import { PageTitle } from '../../components'
import { useStateContext } from '../../state/context'
import { setImmunizationPatient } from '../../state/actions/actions'
import ChildImmunizationTable from '../../components/tables/nursesTable/ChildImmunizationTable'
import VaccinationRecordsTable from '../../components/tables/nursesTable/VaccinationRecordsTable'
import VaccinationScheduleTable from '../../components/tables/nursesTable/VaccinationScheduleTable'
import { getAllPatients, getAllPatientForImmunization } from '../../state/actions/nurse.actions'


const NurseChildImmunization = () => {
	const { opdState, setOpdState } = useStateContext()
	const { modals, setModals } = useStateContext()
	const dispatch = useDispatch()
	const [showVaccinationSchedule, setShowVaccinationSchedule] = useState(false)
	const [buttonColorIndex, setbuttonColorIndex] = useState(0)

	useEffect(() => {
		dispatch(getAllPatientForImmunization())
		dispatch(getAllPatients())

		return () => {
			setOpdState({ ...opdState, showChildImmunizationRecords: false })
		}
	}, [modals.showAddChildForImmunizationModal])
	
	return (
		<div className='flex flex-col w-full cursor-pointer'>

			<PageTitle type={"nurse_child_immunization"} />
			<div></div>
			{!opdState.showChildImmunizationRecords && (
				<div className='py-2'>
					<div className='flex justify-start items-center space-x-4 bg-gray-200 p-1'>
						<h4 className='text-dark-blue text-[14px] font-bold'>
							Patients
						</h4>
						<BsPersonPlus
							className='text-dark-blue'
							size={20}
						/>
						{/* <input type="text" name="filter" placeholder='Search' id="" className='ml-2 pl-4 text-sm rounded h-[30px] w-[400px]' onChange={(e) => handleChange(e, e.target.name)} /> */}
					</div>

					<ChildImmunizationTable />
				</div>
			)}
			{opdState.showChildImmunizationRecords && (
				<div className='py-2'>

					<div className='flex space-x-3'>
						<button
							type="button"
							className={`${buttonColorIndex == 0 ? 'inline-block px-6 py-2.5 my-4 border bg-slate-300 text-black font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0' : 'inline-block px-6 py-2.5 my-4 border bg-grey-400 text-black font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0'}`}
							
							onClick={() => {
								setShowVaccinationSchedule(false)
								setbuttonColorIndex(0)

							}
							}
						>
							Vaccination records
						</button>
						<button
							type="button"
							className={`${buttonColorIndex == 1 ? 'inline-block px-6 py-2.5 my-4 border bg-slate-300 text-black font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0' : 'inline-block px-6 py-2.5 my-4 border bg-grey-400 text-black font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0'}`}
							onClick={() => {
								setShowVaccinationSchedule(true)
								setbuttonColorIndex(1)

							}}
						>
							Vaccination schedule
						</button>
					</div>
					<div className='flex justify-between'>
						<button
							type="button"
							className="inline-block px-6 py-2.5 my-2 bg-sky-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
							onClick={() => (
								setModals({
									...modals,
									showAddVaccinationRecordModal:
										!modals.showAddVaccinationRecordModal,
								})
							)}
						>
							Add +
						</button>
						<button
							type="button"
							className="inline-block px-6 py-2.5 my-2 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
							onClick={() => {
								dispatch (setImmunizationPatient({data:null}))
								setOpdState({
									...opdState,
									showChildImmunizationRecords: false

								})
							}}
						>
							Back
						</button>
					</div>

					{showVaccinationSchedule == false && (

						<VaccinationRecordsTable />

					)}
					{showVaccinationSchedule && (

						<VaccinationScheduleTable />
					)}
				</div>
			)}

		</div>
	)
}

export default NurseChildImmunization