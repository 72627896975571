import React from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useStateContext } from '../../../state/context'


const RadiologyAddInvestigationModal = () => {
    const { modals, setModals } = useStateContext()
    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>

                            <>
                                <h4 className='text-dark-green text-[20px] font-bold'>
                                    Add new investigation
                                </h4>
                                <FaTimesCircle
                                    className='text-dark-yellow cursor-pointer'
                                    onClick={() => (
                                        setModals({
                                            ...modals,
                                            showAddRadiologyInvestigationModal:
                                                !modals.showAddRadiologyInvestigationModal,
                                        })
                                    )

                                    }
                                    size={20}
                                />
                            </>


                        </div>




                        <form >

                            <h4 className='text-sm font-semibold py-2'>Investigation category</h4>
                            <div className='flex justify-between-items-center space-x-10'>
                                <div className='space-y-2 w-full'>
                                    <select name="" placeholder='Type' id="" className='border rounded mt-2 pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 ' >
                                        <option value="">Equipment</option>
                                        <option value="">Reagent</option>

                                    </select>

                                    <h4 className='text-sm font-semibold py-2'>Investigation type</h4>
                                    <div>
                                        <select name="" placeholder='Type' id="" className='border rounded pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 ' >y
                                            <option value="">Available</option>
                                            <option value="">Unvailable</option>
                                            <option value="">Broken</option>
                                        </select>

                                    </div>


                                </div>


                            </div>
                            <div className='flex'>
                                <textarea type='text'
                                    name='patient_num'
                                    placeholder='Sample Types'


                                    className='border rounded w-full h-[200px] px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
                                    id="" cols="50" rows="20">

                                </textarea>



                            </div>



                            <div className='mt-4 flex justify-center items-center'>
                                <button
                                    type='submit'
                                    className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
                                // onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>

                        </form>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default RadiologyAddInvestigationModal