import React, { useState, useEffect } from 'react'
import { useStateContext } from '../../../state/context'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { addAPatientMedicalRecord, getAPatientMedicalRecord } from '../../../state/actions/nurse.actions'


const NurseAddPatientMedicalRecordModal = () => {
    const { modals, setModals } = useStateContext()
    const { set_patient_id } = useSelector((state) => state.root)
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({
        heart_disease: '',
        tuberculosis: '',
        kidney_disease: '',
        liver_disease: '',
        others: '',
        blood_transaction: '',
        date: '',
        id: '',



    })
    const handleChange = (e, name) => { setFormData((prevState) => ({ ...prevState, [name]: e.target.value })) }

    const handleSubmit = (e) => {
        e.preventDefault()

        console.log("data", formData);
        dispatch(addAPatientMedicalRecord({ formData, toast, setModals, modals }))


    }
    useEffect(() => {
        setFormData({ ...formData, id: set_patient_id?.id })
    }, [])
    // useEffect(() => {
    //     dispatch(getAPatientMedicalRecord({ id: set_patient_id?.id }))
    // }, [])

    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>

                            <>
                                <h4 className='text-dark-green text-[16px] font-semibold'>
                                    Add medical record - {set_patient_id?.first_name} {set_patient_id?.last_name} [{set_patient_id?.patient_number}]
                                </h4>
                                <FaTimesCircle
                                    className='text-dark-yellow cursor-pointer'
                                    onClick={() => (
                                        setModals({
                                            ...modals,
                                            showAddPatientMedicalRecordModal:
                                                !modals.showAddPatientMedicalRecordModal,
                                        })
                                    )

                                    }
                                    size={20}
                                />
                            </>


                        </div>




                        <form onSubmit={handleSubmit}>


                            <div className='flex justify-between-items-center space-x-10'>
                                <div className='space-y-2 w-full'>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Heart Diease</p>
                                            <select name="heart_disease" placeholder='Type' id="" className='border bg-slate-300 rounded mt-2 pl-2 w-[250px] h-[40px] focus:outline-none focus:ring-blue-500 '
                                                onChange={(e) => handleChange(e, e.target.name)}
                                            >   <option value="None selected">Select</option>
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>



                                            </select>
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Hypertension</p>
                                            <select name="hypertension" placeholder='Type' id="" className='border bg-slate-300 rounded mt-2 pl-2 w-[250px] h-[40px] focus:outline-none focus:ring-blue-500 '
                                                onChange={(e) => handleChange(e, e.target.name)} >
                                                <option value="None selected">Select</option>
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>


                                            </select>
                                        </div>

                                    </div>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Kidney Diease</p>
                                            <select name="kidney_disease" placeholder='Type' id="" className='border bg-slate-300 rounded mt-2 pl-2 w-[250px] h-[40px] focus:outline-none focus:ring-blue-500 '
                                                onChange={(e) => handleChange(e, e.target.name)}>
                                                <option value="None selected">Select</option>
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>


                                            </select>
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Tuberculosis</p>
                                            <select name="tuberculosis" placeholder='Type' id="" className='border bg-slate-300 rounded mt-2 pl-2 w-[250px] h-[40px] focus:outline-none focus:ring-blue-500 '
                                                onChange={(e) => handleChange(e, e.target.name)} >
                                                <option value="None selected">Select</option>
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>


                                            </select>
                                        </div>

                                    </div>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Liver Disease</p>
                                            <select name="liver_disease" placeholder='Type' id="" className='border bg-slate-300 rounded mt-2 pl-2 w-[250px] h-[40px] focus:outline-none focus:ring-blue-500 '
                                                onChange={(e) => handleChange(e, e.target.name)} >
                                                <option value="None selected">Select</option>
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>


                                            </select>
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Blood Transaction</p>
                                            <select name="blood_transaction" placeholder='Type' id="" className='border bg-slate-300 rounded mt-2 pl-2 w-[250px] h-[40px] focus:outline-none focus:ring-blue-500 '
                                                onChange={(e) => handleChange(e, e.target.name)} >
                                                <option value="None selected">Select</option>
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>


                                            </select>
                                        </div>

                                    </div>
                                    <div className=''>


                                        <p className='text-sm font-semibold'>Others</p>
                                        <textarea type='text'
                                            name='others'
                                            onChange={(e) => handleChange(e, e.target.name)}


                                            className='border rounded w-full text-sm h-[80px] px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
                                            id="" cols="30" rows="20">

                                        </textarea>


                                    </div>






                                </div>


                            </div>

                            <div className='mt-4 flex justify-center items-center'>
                                <button
                                    type='submit'
                                    className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
                                // onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>

                        </form>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default NurseAddPatientMedicalRecordModal