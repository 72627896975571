import React from 'react'
import { useStateContext } from '../../../state/context'
import { FaTimesCircle } from 'react-icons/fa'
import { Antigens } from '../../../data'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createVaccinationImmunizationForPatient } from '../../../state/actions/nurse.actions'
import { toast } from 'react-toastify'

const NurseAddVaccinationRecordsModal = () => {
    const { modals, setModals } = useStateContext()
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({
        antigen: '',
        date: '',
        patient: ''
    })

    const { all_nurse_patients, all_patient_for_immunization } = useSelector((state) => state.nurse)
    const { set_immunization_patient } = useSelector((state) => state.root)
    const handleChange = (e, name) => { setFormData((prevState) => ({ ...prevState, [name]: e.target.value })) }

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(createVaccinationImmunizationForPatient({ formData, toast, setModals, modals }))

    }
    useEffect(() => {

        setFormData({ ...formData, patient: set_immunization_patient?.patient_id })
    }, [])

    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>

                            <>
                                <h4 className='text-dark-green text-[16px] font-semibold'>
                                    Add vaccination record
                                </h4>
                                <FaTimesCircle
                                    className='text-dark-yellow cursor-pointer'
                                    onClick={() => (
                                        setModals({
                                            ...modals,
                                            showAddVaccinationRecordModal:
                                                !modals.showAddVaccinationRecordModal,
                                        })
                                    )

                                    }
                                    size={20}
                                />
                            </>


                        </div>




                        <form
                            onSubmit={handleSubmit}
                        >


                            <div className='flex justify-between-items-center space-x-10'>
                                <div className='space-y-2 w-full'>

                                    <div>
                                        <p className='text-sm font-semibold'>Drug administered</p>
                                        <select placeholder='Type'
                                            name='antigen'
                                            onChange={(e) => handleChange(e, e.target.name)}
                                            id="" className='border rounded mt-2 pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 ' >
                                            <option value="##"> Select Antigens</option>
                                            {Antigens?.map((item, index) => (
                                                <option value={item}>{item}</option>
                                            ))}


                                        </select>
                                    </div>
                                    <div>


                                        <p className='text-sm font-semibold'>Date administered</p>
                                        <input
                                            type="date"
                                            name="date"
                                            required


                                            className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                            onChange={(e) => handleChange(e, e.target.name)}
                                        />
                                    </div>





                                </div>


                            </div>

                            <div className='mt-4 flex justify-center items-center'>
                                <button
                                    type='submit'
                                    className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
                                // onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>

                        </form>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default NurseAddVaccinationRecordsModal