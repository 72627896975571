import { useState, useRef, useEffect } from 'react'
import { BiUserPlus } from 'react-icons/bi'
import { BsFillPencilFill } from 'react-icons/bs'
import { FaTimes } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

import Role from '../utils/Role'
import { useStateContext } from '../../state/context'
import { getChatConversations, updateGroupIcon } from '../../state/actions/chat.actions'


const ChatUserProfile = (props) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()
    const { current_chatuser, groupicon_update } = useSelector(state => state.chat)

    const FilePicker = useRef(null)
    const [config, setConfig] = useState({ showMembers: false, showImages: false, showDocuments: false })
    const [formData, setFormData] = useState({ conversation_icon: '', conversation_icon_preview: '' })

    useEffect(() => {
        dispatch(getChatConversations())
    }, [groupicon_update])


    const handleImageChange = (e) => {
        setFormData({ ...formData, conversation_icon: e.target.files[0], conversation_icon_preview: URL.createObjectURL(e.target.files[0]) })
    }

    const handleImageSubmit = (e) => {
        e.preventDefault()

        const data = {
            ...formData,
            pkid: current_chatuser?.pkid,
            conversation_name: current_chatuser?.conversation_name
        }

        dispatch(updateGroupIcon({ formData: data, setFormData }))
    }

    return (
        <div className={`${props.showUserProfile ? 'flex-[0.4]' : 'hidden'}`}>
            <div className="flex justify-between items-center">
                <p className="text-[12px] text-gray-500">Profile</p>

                <FaTimes
                    size={10}
                    className='text-yellow-500 cursor-pointer'
                    onClick={() => props.setShowUserProfile(!props.showUserProfile)}
                />
            </div>

            <div className="flex flex-col justify-center items-center space-y-3">
                <div className="relative">
                    <img
                        src={
                            formData.conversation_icon_preview ? formData.conversation_icon_preview : `${process.env.REACT_APP_API_ROOT}${current_chatuser?.profile_photo ? current_chatuser?.profile_photo : current_chatuser?.conversation_icon}`
                        }
                        alt="user"
                        className='mr-2 w-[180px] h-[180px] rounded-[100%] border'
                    />

                    <div className="absolute right-2 bottom-4 bg-blue-500 p-2 rounded-full">
                        <BsFillPencilFill
                            onClick={() => FilePicker.current.click()}
                            className='text-white cursor-pointer'
                        />
                    </div>
                    <input
                        type="file"
                        name="conversation_icon"
                        accept="image/.png, .jpg, .jpeg"
                        ref={FilePicker}
                        className='hidden'
                        onChange={handleImageChange}
                    />

                </div>

                {formData.conversation_icon_preview && (
                    <button
                        type="button"
                        className="inline-block px-6 py-2 bg-blue-800 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                        onClick={handleImageSubmit}
                    >
                        Update Group Icon
                    </button>
                )}

                {current_chatuser?.first_name && (
                    <div className='flex flex-col justify-center items-center -space-y-2'>
                        <p className="text-[12px] text-gray-600">{current_chatuser?.last_name} {current_chatuser?.first_name}</p>
                        <p className="text-[12px] text-gray-600 underline underline-offset-1">{current_chatuser?.email}</p>
                        <Role user={current_chatuser} type={'chat'} />
                    </div>
                )}

                {!current_chatuser?.first_name && (
                    <div className='flex flex-col justify-center items-center -space-y-2'>
                        <div className='flex justify-between items-center space-x-5'>
                            <p className="text-[12px] text-gray-600">{current_chatuser?.conversation_name}</p>

                            <BiUserPlus
                                size={30}
                                className='cursor-pointer text-teal-600'
                                onClick={() => {
                                    setModals({ ...modals, showAddGroupParticipantModal: !modals.showAddGroupParticipantModal })
                                }}
                            />
                        </div>

                        <div className="flex flex-col pt-2 w-[200px] justify-start">
                            <div
                                onClick={() => setConfig({ ...config, showMembers: !config.showMembers, showDocuments: false, showImages: false })}
                                className={`space-x-5 border-t border-b cursor-pointer `}
                            >
                                <h4 className='text-[12px] font-medium text-blue-500'>Members</h4>

                            </div>
                            <div className={`${config.showMembers && 'h-[180px] overflow-y-auto users'}`}>
                                {config.showMembers && current_chatuser?.conversation_members_details?.map((user) => (
                                    <div
                                        key={`${user.id}`}
                                        className="flex flex-col -space-y-4 "
                                    >
                                        <p className="text-[12px] text-gray-600">{user.last_name} {user.first_name}</p>
                                        <Role user={user} type={'chat'} />
                                    </div>
                                ))}
                            </div>

                            <div
                                onClick={() => setConfig({ ...config, showImages: !config.showImages, showDocuments: false, showMembers: false })}
                                className="flex space-x-5 border-b cursor-pointer"
                            >
                                <h4 className='text-[12px] font-medium text-blue-500'>Images</h4>
                            </div>
                            <div className={`${config.showImages && 'h-[180px] overflow-y-auto users'}`}>
                                {config.showImages && current_chatuser?.conversation_images?.filter(item => item.image !== null)?.map((_item) => (
                                    <div
                                        key={`${_item.id}`}
                                        className="flex justify-between"
                                    >
                                        {console.log(_item)}
                                        <img src={_item.image ? `${process.env.REACT_APP_API_ROOT}${_item.image}` : ''} className='w-[180px]' alt="" />
                                    </div>
                                ))}
                            </div>

                            <div
                                onClick={() => setConfig({ ...config, showDocuments: !config.showDocuments, showImages: false, showMembers: false })}
                                className="flex space-x-5 border-b cursor-pointer"
                            >
                                <h4 className='text-[12px] font-medium text-blue-500'>Documents</h4>
                            </div>
                            <div className={`${config.showDocuments && 'h-[180px] overflow-y-auto users'}`}>
                                {config.showDocuments && current_chatuser?.conversation_documents?.filter(item => item.file !== null)?.map((_item) => (
                                    <div
                                        key={`${_item.id}`}
                                        className="flex justify-between"
                                    >
                                        <a
                                            href={`${process.env.REACT_APP_API_ROOT}${_item?.file}`}
                                            target='_blank'
                                            className='text-blue-600 text-[10px]'
                                        >
                                            {_item?.file_name}
                                        </a>
                                    </div>
                                ))}
                            </div>

                        </div>

                    </div>
                )}
            </div>
        </div>
    )
}

export default ChatUserProfile