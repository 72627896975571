import { API } from "../config";

export const fetchDashboardInfo = (token) => API.get('/patient/patient_dashboard/', { headers: { Authorization: `Bearer ${token}` } })

export const getMyProfile = (token) => API.get('/patient/get_my_profile/', { headers: { Authorization: `Bearer ${token}` } })

export const getCurrentUserLabInvoices = (token) => API.get('/patient/patient_lab_invoice/', { headers: { Authorization: `Bearer ${token}` } })

export const getCurrentUserRadiologyInvoices = (token) => API.get('/patient/patient_radiology_invoice/', { headers: { Authorization: `Bearer ${token}` } })

export const getCurrentUserPharmacyInvoices = (token) => API.get('/patient/patient_pharmacy_invoice/', { headers: { Authorization: `Bearer ${token}` } })

export const getCurrentUserRecordsInvoices = (token) => API.get('/patient/patient_records_invoice/', { headers: { Authorization: `Bearer ${token}` } })

export const payForGeneratedInvoice = (formData, token) => API.post('/patient/pay/', formData, { headers: { Authorization: `Bearer ${token}` } })

export const verifyPayment = (formData, token) => API.get(`/patient/pay/${formData.ref}/`, { headers: { Authorization: `Bearer ${token}` } })

export const flutterwavePayment = (formData, token) => API.post('/patient/flutter_wave_payment/', formData, { headers: { Authorization: `Bearer ${token}` } })

export const verifyFlutterWavePayment = (transaction_id, token) => API.get(`/patient/flutter_wave_payment/verify/${transaction_id}/`, { headers: { Authorization: `Bearer ${token}` } })

export const changeInvoiceStatus = (formData, type, id, token) => API.put(`/patient/change_invoice_status/${type}/${id}/`, formData, { headers: { Authorization: `Bearer ${token}` } })

export const getCurrentUserAppointment = (token) => API.get(`/patient/current_patient_appointment/`, { headers: { Authorization: `Bearer ${token}` } })

export const getCurrentUserAssignment = (token) => API.get(`/patient/patient_assignment/`, { headers: { Authorization: `Bearer ${token}` } })


// export const getPatientDiagnosis = (patient_pkid, token) => API.get(`/nurse/get_patient_diagnosis/${patient_pkid}/`, { headers: { Authorization: `Bearer ${token}` } })

// export const getPatientDiagnosisPrescriptions = (id, token) => API.get(`/nurse/get_patient_diagnosis_prescriptions/${id}/`, { headers: { Authorization: `Bearer ${token}` } })
