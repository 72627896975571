import React, { useEffect } from 'react'
import image from '../../assets/images/user__potrait__3.jpg'
import { useStateContext } from '../../state/context'
import { FaDiagnoses } from 'react-icons/fa'
import NurseDiagnosisTable from '../../components/tables/nursesTable/NurseDiagnosisTable'
import NurseDiagnosisAndPrescriptionPage from './NurseDiagnosisAndPrescriptionPage'
import { useSelector } from 'react-redux'
import PatientInitialPerformnaceTable from '../../components/tables/nursesTable/PatientInitialPerformnaceTable'

const NursePatientDiagnosis = () => {
    const { opdState, setOpdState } = useStateContext()
    const { set_patient_diagnosis_details } = useSelector((state) => state.root)
    const { all_patient_diagnosis, all_patient_performanceData } = useSelector((state) => state.nurse
    )

    useEffect(() => {

    }, [all_patient_performanceData])

    return (
        <div className='flex space-x-4 ml-1'>

            <div className=' flex-[0.4] h-[600px] border rounded-lg pt-2 px-2'>
                <img src={`${process.env.REACT_APP_API_ROOT}${set_patient_diagnosis_details?.photo}`} alt="" srcset="" className='h-[250px]  w-full mx-auto rounded-lg mt-2' />
                <p className='text-[10px] font-bold mt-3 ml-4'>{set_patient_diagnosis_details?.first_name} {set_patient_diagnosis_details?.last_name}</p>
                <div className='flex justify-between px-4 pt-2'>
                    <p className='text-[10px] text-gray-300' > Gender :</p>
                    <p className='text-[10px]' >{set_patient_diagnosis_details?.gender}</p>
                </div>
                <div className='flex justify-between px-4 pt-2'>
                    <p className='text-[10px] text-gray-300' > Patient number :</p>
                    <p className='text-[10px]' >{set_patient_diagnosis_details?.patient_number}</p>
                </div>
                <div className='flex justify-between px-4 pt-2'>
                    <p className='text-[10px] text-gray-300' >Date of Birth :</p>
                    <p className='text-[10px]' >{set_patient_diagnosis_details?.dob}</p>
                </div>
                {/* <div className='flex justify-between px-4 pt-2'>
                    <p className='text-[10px] text-gray-300' > Weight :</p>
                    <p className='text-[10px]' >76kg</p>
                </div> */}
                <hr className='mt-4' />
                <p className='text-[10px] px-4 pt-2'>Hospital details</p>
                <div className='flex justify-between px-4 pt-2'>
                    <p className='text-[10px] text-gray-300' > Blood group :</p>
                    <p className='text-[10px]' >{set_patient_diagnosis_details?.blood_group}</p>
                </div>
                <div className='flex justify-between px-4 pt-2'>
                    <p className='text-[10px] text-gray-300' > Genotype :</p>
                    <p className='text-[10px]' >{set_patient_diagnosis_details?.genotype}</p>
                </div>
                {/* <hr className='mt-4' />
                <p className='text-[10px] px-4 pt-2'>Notes</p>
                <p className='text-sm px-4 pt-2 font-normal'>Hi this is the health insurance system profile for abel yakubu

                </p> */}
            </div>

            {!opdState.showDiagnosisPrescription && opdState.showPatientDiagnosisHistory && (
                <div className='flex flex-[0.6] flex-col cursor-pointer'>

                    <div className='py-2'>
                        <div className='flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1'>
                            <h4 className='text-dark-blue text-[16px] font-semibold'>
                                Diagnoses
                            </h4>
                            <FaDiagnoses
                                className='text-dark-blue'
                                size={20}
                            />
                        </div>
                        <table className='border rounded w-full cursor-default'>
                            <thead className=''>
                                <tr>
                                    <th
                                        scope='col'
                                        className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                                    >
                                        S/N
                                    </th>

                                    <th
                                        scope='col'
                                        className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                                    >
                                        Diagnosis
                                    </th>
                                    <th
                                        scope='col'
                                        className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
                                    >
                                        Date diagnosed
                                    </th>
                                    <th
                                        scope='col'
                                        className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
                                    >
                                        Actions
                                    </th>

                                </tr>
                            </thead>


                            <NurseDiagnosisTable data={all_patient_diagnosis} />

                        </table>
                    </div>
                    <div className='py-2'>

                        <PatientInitialPerformnaceTable />
                    </div>
                </div>
            )}
            {opdState.showDiagnosisPrescription && (
                <NurseDiagnosisAndPrescriptionPage />
            )}
        </div>
    )
}

export default NursePatientDiagnosis