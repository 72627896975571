import { useEffect } from 'react'
import Lottie from 'lottie-react'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import pharmacistJSON from '../../../assets/animations/pharmacist__1.json'
import { useStateContext } from '../../../state/context'
import { deleteSupplyPoint, getSupplyPointStaff, resetSupplyPointStaff } from '../../../state/actions/pharmacist.actions'


const PharmacistSupplyPointStaffModal = () => {
    const dispatch = useDispatch()

    const options = {
        // renderer: 'svg',
        loop: true,
        // autoplay: true,
        animationData: pharmacistJSON,
        // rendererSettings: {
        //     preserveAspectRatio: 'xMidYMid slice',
        // },
    }

    const { modals, setModals } = useStateContext()

    const { supplypoint_update } = useSelector((state) => state.root)
    const { supplypoint_staff } = useSelector((state) => state.pharmacist)

    useEffect(() => {
        dispatch(getSupplyPointStaff({ params: { spoint: supplypoint_update?.pkid } }))
    }, [supplypoint_update])
    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[500px] rounded px-[10px] py-[10px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className='text-sky-500 text-[20px] font-bold capitalize'>Staff List</h4>
                        <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                dispatch(resetSupplyPointStaff())
                                setModals({ ...modals, showPharmacistSupplyPointStaffModal: !modals.showPharmacistSupplyPointStaffModal })
                            }}
                            size={20}
                        />
                    </div>

                    {supplypoint_staff?.length > 0 && (
                        <div
                            className="rounded-t px-2 py-2"
                        >
                            {supplypoint_staff?.map((item, index) => (
                                <div
                                    key={`${item.id}`}
                                    className={`px-2 py-2 ${index === 0 && 'rounded-t'} ${index === supplypoint_staff?.length - 1 && 'rounded-b'} ${(index % 2) === 0 ? 'bg-sky-100' : 'bg-indigo-300'} hover:z-10 hover:scale-x-[1.02] hover:rounded hover:cursor-pointer ease-in-out duration-500`}
                                >
                                    <p className="text-sm font-bold">Pharm. {item.last_name} {item.first_name}</p>
                                    <p className="text-sm">{item.email}</p>
                                </div>
                            ))}
                        </div>
                    )}

                    {supplypoint_staff?.length === 0 && (
                        <div className='flex flex-col justify-center items-center'>
                            {/* <Lottie options={options} width={400} height={400} /> */}
                            <img
                                src="/src/assets/images/art__pharmacist__1.svg"
                                alt=""
                                className="w-[60%]"
                            />
                        </div>
                    )}
                </div>


            </div>
        </div>
    )
}
export default PharmacistSupplyPointStaffModal
