import React, { Fragment, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setPatientID } from '../../../state/actions/actions'
import { useStateContext } from '../../../state/context'
import Pagination from '../../utils/Pagination'

const FamilyPlanningTable = () => {
    const dispatch = useDispatch()
    
    const { opdState, setOpdState } = useStateContext()

    const { all_family_planning_bookings } = useSelector((state) => state.nurse)

    const [currentData, setCurrentData] = useState([])

    return (
        <Fragment>
            <table className='border rounded w-full cursor-default'>
                <thead className=''>
                    <tr>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                        >
                            S/N
                        </th>

                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                        >
                            Patient Number
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 pr-[5em] py-4 text-left'
                        >
                            Full name
                        </th>

                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 py-4 text-left'
                        >
                            Gender
                        </th>
                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 py-4 text-left'
                        >
                            Date Booked
                        </th>


                        <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 py-4 text-left'
                        >
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {currentData?.map((item, index) => (
                        <tr
                            className='relative'
                            key={index}

                        >
                            <td className='px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
                                {index + 1}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.patient_profile.patient_number}         </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.booked_patient.first_name} {item.booked_patient.last_name}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.patient_profile.gender}
                            </td>
                            <td className='text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                {item.date}
                            </td>
                            <td className=' text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                <div className='flex space-x-4 relative '>
                                    <button
                                        type="button"
                                        className="inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                                        onClick={() => {
                                            const data = {
                                                first_name: item.booked_patient.first_name,
                                                last_name: item.booked_patient.last_name,
                                                id: item.booked_patient.id,
                                                patient_number: item.patient_profile.patient_number,
                                                photo: item?.patient_profile?.photo,
                                                address: item?.patient_profile?.address,
                                            }

                                            dispatch(setPatientID({ data }))
                                            setOpdState({
                                                ...opdState,
                                                showFamilyPlanningFollowUpRecords: true

                                            })
                                        }}
                                    >
                                        Manage
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <Pagination
                data={all_family_planning_bookings}
                currentData={currentData}
                setCurrentData={setCurrentData}
            />
        </Fragment>
    )
}

export default FamilyPlanningTable