/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import Pagination from '../utils/Pagination'
import { useStateContext } from '../../state/context'
import { setCurrentPrescriptionInvoice } from '../../state/actions/actions'
import { setCurrentMultipleInvoices } from '../../state/actions/pharmacist.actions'


const PharmacistSupplyPointInvoicesTable = ({ data, showList }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [currentData, setCurrentData] = useState([])
    const [accumulatedList, setAccumulatedList] = useState([])
    const [filterState, setFilterState] = useState({ search: '', invoices: [] })

    const handleFilter = () => {
        const filtered = data?.filter(item => {
            return item.invoice_number.toLowerCase().includes(filterState.search.toLowerCase())
                || item.invoice_patient_id.toLowerCase().includes(filterState.search.toLowerCase())
                || item.invoice_patient.first_name.toLowerCase().includes(filterState.search.toLowerCase())
                || item.invoice_patient.last_name.toLowerCase().includes(filterState.search.toLowerCase())
                || item.invoice_drugs.toLowerCase().includes(filterState.search.toLowerCase())
        })

        setFilterState({ ...filterState, invoices: filtered })

        if (filterState.search === '') {
            setFilterState({ ...filterState, invoices: data })
        }
    }

    useEffect(() => {
        setFilterState({ ...filterState, invoices: data })
    }, [data])

    useEffect(() => {
        handleFilter()
    }, [filterState.search])


    return (
        <div className="flex justify-between items-center w-full">
            <div className="flex flex-col w-full cursor-pointer">
                <div className="pb-2">
                    <div
                        className="flex justify-between items-center space-x-4 rounded-t-lg"
                    // onClick={() => setShowList(!showList)}
                    >
                        <input
                            type="text"
                            name="search"
                            placeholder='Search'
                            className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
                            onChange={(e) => setFilterState({ ...filterState, [e.target.name]: e.target.value })}
                        />

                        <button
                            type="button"
                            disabled={accumulatedList.length < 1}
                            className="w-[150px]  mb-2 mt-2 inline-block px-6 py-2 bg-sky-500 text-white font-medium text-[10px] leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                            onClick={() => {
                                dispatch(setCurrentMultipleInvoices({ data: accumulatedList }))
                                setAccumulatedList([])
                                setModals({ ...modals, showPharmacistDispenseMultipleInvoiceModal: !modals.showPharmacistDispenseMultipleInvoiceModal })

                            }}
                        >
                            Multiple Dispense
                        </button>
                    </div>
                    {showList && (
                        <table className="border-b border-r border-l rounded w-full cursor-default">
                            <thead className="">
                                <tr className='bg-gray-100'>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pl-4 py-4 text-left"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >

                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[2em] py-4 text-left"
                                    >
                                        Invoice ID
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Patient ID
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Drugs
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                    >
                                        Status
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >

                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >

                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 py-4 text-left"
                                    >
                                        <input
                                            type="checkbox"
                                            onChange={() => {
                                                if (accumulatedList.length === 0) {
                                                    const filtered = currentData.filter(item => item.is_dispensed === false)
                                                    setAccumulatedList(filtered)
                                                } else {
                                                    setAccumulatedList([])
                                                }
                                            }}
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentData?.map((item, index) => (
                                    <tr
                                        key={`${item.id}`}
                                    >
                                        <td className="pl-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {item.is_paid && !item.is_dispensed && (
                                                <input
                                                    type="checkbox"
                                                    checked={accumulatedList?.find((_item) => _item.id == item.id)}
                                                    onChange={(e) => {
                                                        const myIndex = accumulatedList.findIndex(e => e.id === item.id)
                                                        console.log(e.target.value)
                                                        if (myIndex > -1) {
                                                            setAccumulatedList([...accumulatedList.filter(inv => inv.pkid !== item.pkid)])
                                                        } else {
                                                            setAccumulatedList([...accumulatedList, item])
                                                        }
                                                    }}
                                                />
                                            )}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.invoice_number}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.invoice_patient_id}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.invoice_drugs}
                                            {/* {item.invoice_drugs.map(((item, index) => (<span key={index}>{item[0].slice(0, 20)}...</span>)))} */}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                            {item.is_paid ? (
                                                <div className="w-[100px] flex space-x-2 px-2 h-[25px] justify-center items-center rounded-full bg-gray-100">
                                                    <span className='w-2 h-2 rounded-full bg-teal-400'></span>
                                                    <p className='text-[10px]'>Paid</p>
                                                </div>
                                            ) : (
                                                <div className="w-[100px] flex space-x-2 px-2 h-[25px] justify-center items-center rounded-full bg-gray-100">
                                                    <span className='w-2 h-2 rounded-full bg-orange-400'></span>
                                                    <p className='text-[10px]'>Pending</p>
                                                </div>
                                            )}

                                        </td>
                                        <td className=" flex text-2xl space-x-4 text-gray-900 font-semibold py-2 whitespace-nowrap">
                                            {item.is_paid && !item.is_dispensed && (
                                                <button
                                                    type="button"
                                                    className="inline-block px-2 py-2 bg-sky-600 text-white hover:bg-sky-400 hover:text-white font-medium text-xs leading-tight uppercase shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                    onClick={() => {
                                                        dispatch(setCurrentPrescriptionInvoice({ data: item }))
                                                        setModals({ ...modals, showPharmacistDispenseDrugModal: !modals.showPharmacistDispenseDrugModal })
                                                    }}
                                                >
                                                    Dispense
                                                </button>
                                            )}
                                            {item.is_paid && item.is_dispensed && (
                                                <button
                                                    disabled
                                                    type="button"
                                                    className="inline-block px-2 py-2 bg-teal-600 text-white hover:text-white font-medium text-xs leading-tight uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                >
                                                    Dispensed
                                                </button>
                                            )}
                                        </td>
                                        <td>
                                            {item.is_paid && (
                                                <button
                                                    type="button"
                                                    className="inline-block px-4 py-2 border border-teal-600 text-teal-600 font-medium text-xs leading-tight uppercase shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                    onClick={() => {
                                                        dispatch(setCurrentPrescriptionInvoice({ data: item }))
                                                        setModals({ ...modals, showPharmacistViewInvoiceModal: !modals.showPharmacistViewInvoiceModal })
                                                    }}
                                                >
                                                    View
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    <Pagination
                        data={filterState.invoices}
                        currentData={currentData}
                        setCurrentData={setCurrentData}
                    />
                </div>
            </div>
        </div >
    )
}

export default PharmacistSupplyPointInvoicesTable