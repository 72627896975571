import { createSlice } from '@reduxjs/toolkit'

import { InitializeAuth } from './'
import { authLogin, updateUserPassword, getUserProfile, userLogout, updateProfilePassword, updateUserProfile, profileUpdate, updateProfilePhoto, activateAccount, updateOnlineStatus, updateUserSocketId, requestPasswordReset, resetUserPassword } from '../actions/auth.actions'

let USERFROMLS = localStorage.getItem('his__user') ? JSON.parse(localStorage.getItem('his__user')) : null
const PROFILEFROMLS = localStorage.getItem('his__user__profile') ? JSON.parse(localStorage.getItem('his__user__profile')) : null


// InitializeAuth(USERFROMLS)


const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: USERFROMLS ? USERFROMLS : null,
        profile: PROFILEFROMLS ? PROFILEFROMLS : null,
        user_onlinestatus: null,
        user_socket_id: null,
        authErrors: null,
        authLoading: false
    },
    extraReducers: {
        [activateAccount.pending]: (state, action) => {
            state.authLoading = true
        },
        [activateAccount.fulfilled]: (state, action) => {
            state.authLoading = false
        },
        [activateAccount.pending]: (state, action) => {
            state.authLoading = true
        },
        [authLogin.fulfilled]: (state, action) => {
            state.authLoading = false
            state.user = action.payload
            localStorage.setItem('his__user', JSON.stringify({ ...action.payload }))
        },
        [authLogin.rejected]: (state, action) => {
            state.authLoading = false
            state.authErrors = action.payload
        },
        [requestPasswordReset.pending]: (state, action) => {
            state.authLoading = true
        },
        [requestPasswordReset.fulfilled]: (state, action) => {
            state.authLoading = false
        },
        [requestPasswordReset.rejected]: (state, action) => {
            state.authLoading = false
        },

        [resetUserPassword.pending]: (state, action) => {
            state.authLoading = true
        },
        [resetUserPassword.fulfilled]: (state, action) => {
            state.authLoading = false
        },
        [resetUserPassword.rejected]: (state, action) => {
            state.authLoading = false
        },

        [updateUserPassword.pending]: (state, action) => {
            state.authLoading = true
        },
        [updateUserPassword.fulfilled]: (state, action) => {
            state.authLoading = false
        },
        [updateUserPassword.rejected]: (state, action) => {
            state.authLoading = false
        },
        [profileUpdate.pending]: (state, action) => {
            state.authLoading = true
        },
        [profileUpdate.fulfilled]: (state, action) => {
            state.authLoading = false

            const userFromLS = JSON.parse(localStorage.getItem('his__user'))
            console.log(userFromLS);
            const details = { ...userFromLS, data: action.payload.user_details }
            state.user = details

            localStorage.removeItem('his__user')
            localStorage.setItem('his__user', JSON.stringify(details))

            const profileFromLS = JSON.parse(localStorage.getItem('his__user__profile'))
            console.log(profileFromLS)
            const profile = { ...action.payload, profile_photo: profileFromLS.profile_photo }
            state.profile = profile
            localStorage.removeItem('his__user__profile')
            localStorage.setItem('his__user__profile', JSON.stringify(profile))
        },
        [profileUpdate.rejected]: (state, action) => {
            state.authLoading = false
        },
        [updateUserProfile.pending]: (state, action) => {
            state.authLoading = true
        },
        [updateUserProfile.fulfilled]: (state, action) => {
            state.authLoading = false
        },
        [updateUserProfile.rejected]: (state, action) => {
            state.authLoading = false
        },
        [updateProfilePhoto.pending]: (state, action) => {
            state.authLoading = true
        },
        [updateProfilePhoto.fulfilled]: (state, action) => {
            state.authLoading = false
            console.log({ payload: action.payload });

            const profileFromLS = JSON.parse(localStorage.getItem('his__user__profile'))
            console.log(profileFromLS)
            const profile = { ...profileFromLS, profile_photo: '${process.env.REACT_APP_API_ROOT}'.concat(action.payload.profile_photo) }
            state.profile = profile
            localStorage.removeItem('his__user__profile')
            localStorage.setItem('his__user__profile', JSON.stringify(profile))

        },
        [updateProfilePhoto.rejected]: (state, action) => {
            state.authLoading = false
        },
        [updateProfilePassword.pending]: (state, action) => {
            state.authLoading = true
        },
        [updateProfilePassword.fulfilled]: (state, action) => {
            state.authLoading = false
        },
        [updateProfilePassword.rejected]: (state, action) => {
            state.authLoading = false
        },
        [getUserProfile.pending]: (state, action) => {
            state.authLoading = true
        },
        [getUserProfile.fulfilled]: (state, action) => {
            state.authLoading = false
            state.profile = action.payload
            localStorage.setItem('his__user__profile', JSON.stringify({ ...state.profile }))
        },
        [getUserProfile.rejected]: (state, action) => {
            state.authLoading = false
        },

        [updateOnlineStatus.pending]: (state, action) => {
            state.authLoading = true
        },
        [updateOnlineStatus.fulfilled]: (state, action) => {
            state.authLoading = false
            state.user_onlinestatus = action.payload
            state.user.data['is_online'] = action.payload.is_online

            const userFROMLS = localStorage.getItem('his__user') ? JSON.parse(localStorage.getItem('his__user')) : null
            userFROMLS.data['is_online'] = action.payload.is_online

            localStorage.removeItem('his__user')
            localStorage.setItem('his__user', JSON.stringify(userFROMLS))
        },
        [updateOnlineStatus.rejected]: (state, action) => {
            state.authLoading = false
        },

        [userLogout.pending]: (state, action) => {
            state.authLoading = true
        },
        [userLogout.fulfilled]: (state, action) => {
            state.authLoading = false
            state.user = null
            state.profile = null
            // state.user_onlinestatus = action.payload
        },
        [userLogout.rejected]: (state, action) => {
            state.authLoading = false
        },
        [updateUserSocketId.pending]: (state, action) => {
            state.authLoading = true
        },
        [updateUserSocketId.fulfilled]: (state, action) => {
            state.authLoading = false
            state.user_socket_id = action.payload

        },
        [updateUserSocketId.rejected]: (state, action) => {
            state.authLoading = false
        },
    }
})


export default authSlice.reducer;