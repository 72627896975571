import { setOpenRoom, setRoomDetails, setActiveRooms, } from '../../state/actions/roomActions';
import reducer from '../../state/store';
import { io } from 'socket.io-client'
import { useDispatch, useSelector } from 'react-redux';
import * as roomHandler from "./roomHandler";
import * as webRTCHandler from './webRTCHandler'

export let socket = 0

const SOCKET_URL = process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_CHAT_SERVER_DEV : process.env.REACT_APP_CHAT_SERVER_PROD

socket = io(SOCKET_URL)

let userDetails = {}

if (localStorage.getItem('pms__user')) {
    userDetails = JSON.parse(localStorage.getItem("pms__user"))['data']
}



const userData = {
    user_id: userDetails['id'],
    email: userDetails['email'],
    fullname: `${userDetails['first_name']} ${userDetails['last_name']}`,
    roomName: '',
    invitees: ''
}



export const joinRoom = (data) => {
    socket.emit('room-join', data);
    console.log(`client side ==> Emitted join a room data `, data);


}

export const createNewRoom = (data) => {
    reducer.dispatch(setOpenRoom(true, true))
    userData['roomName'] = data["roomName"]
    userData['invitees'] = data['invitees']
    console.log('userDataaaaaaaaaaaaaaaaaa', userData)

    socket.emit('room-create', (userData));

}

export const leaveRoom = (data) => {
    socket.emit('room-leave', data);
}


export const signalPeerData = (data) => {

    socket.emit('conn-signal', data);
}




socket.on('room-create', (data) => {
    console.log('socket connection listener')
    roomHandler.newRoomCreated(data);
    ;
    console.log(data);
});;


socket.on('active-rooms', data => {
    roomHandler.updateActiveRooms(data);
    console.log('active rooms emitted');


});


socket.on('conn-prepare', (data) => {
    console.log('prepare for incoming connextions', data);
    const { connUserSocketId } = data;
    webRTCHandler.prepareNewPeerConnection(connUserSocketId, false);
    socket.emit('conn-init', {
        connUserSocketId: connUserSocketId
    });

});

socket.on('conn-init', (data) => {
    const { connUserSocketId } = data;
    webRTCHandler.prepareNewPeerConnection(connUserSocketId, true);
});

socket.on('conn-signal', data => {
    webRTCHandler.handleSignalingData(data);
});


socket.on('room-participant-left', (data) => {
    console.log('user-left-room');
    webRTCHandler.handleParticipantLeftRoom(data);
})





