import { API } from "../config"


export const fetchLaboratoryInvoices = (token) => API.get('/collection/laboratory_invoices/', { headers: { Authorization: `Bearer ${token}` } })


export const fetchPharmacyInvoices = (token) => API.get('/collection/pharmacy_invoices/', { headers: { Authorization: `Bearer ${token}` } })


export const fetchRadiologyInvoices = (token) => API.get('/collection/radiology_invoices/', { headers: { Authorization: `Bearer ${token}` } })


export const fetchRecordsInvoices = (token) => API.get('/collection/records_invoices/', { headers: { Authorization: `Bearer ${token}` } })


export const generatePayStackConfig = (formData, token) => API.post('/collection/payments_paystack_initialize/', formData, { headers: { Authorization: `Bearer ${token}` } })


export const generateSquadCoConfig = (formData, token) => API.post('/collection/payments_squadco_initialize/', formData, { headers: { Authorization: `Bearer ${token}` } })


export const checkPayStackPaymentStatus = (params, token) => API.get(`/collection/payments_paystack_verify/${params.ref}`, { headers: { Authorization: `Bearer ${token}` } })


export const checkSquadCoPaymentStatus = (params, token) => API.get(`/collection/payments_squadco_verify/${params.ref}`, { headers: { Authorization: `Bearer ${token}` } })


export const patchLaboratoryInvoice = (formData, token) => API.patch(`/collection/payments_update_labinvoice/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const generateFlutterWaveConfig = (formData, token) => API.post('/collection/payments_flutterwave_initialize/', formData, { headers: { Authorization: `Bearer ${token}` } })


export const checkFlutterWavePaymentStatus = (params, token) => API.get(`/collection/payments_flutterwave_verify/${params.ref}`, { headers: { Authorization: `Bearer ${token}` } })


export const patchPharmacyInvoice = (formData, token) => API.patch(`/collection/payments_update_pharmacyinvoice/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchMultiplesInvoiceViaOther = (formData, token) => API.patch(`/collection/payments_update_multiple_pharmacyinvoice/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchMultipleInvoiceTypes = (formData, token) => API.patch(`/collection/payments_update_multiple_invoice_types/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchRadiologyInvoice = (formData, token) => API.patch(`/collection/payments_update_radiologyinvoice/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const patchRecordsInvoice = (formData, token) => API.patch(`/collection/payments_update_recordsinvoice/`, formData, { headers: { Authorization: `Bearer ${token}` } })


export const generateAdminPayStackConfig = (formData, token) => API.post('/collection/payments_admin_paystack_initialize/', formData, { headers: { Authorization: `Bearer ${token}` } })


export const checkAdminPayStackPaymentStatus = (params, token) => API.get(`/collection/payments_admin_paystack_verify/${params.ref}`, { headers: { Authorization: `Bearer ${token}` } })


export const patchMultipleinvoices = (formData, token) => API.patch(`/collection/update_multiple_pharmacy_invoice/`, formData, { headers: { Authorization: `Bearer ${token}` } })
