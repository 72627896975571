import React, { useState, useEffect } from 'react'
import InvestigationsTable from './InvestigationsTable'
import LabourSummaryTable from './LabourSummaryTable'
import PatientPregnancyRecordsTable from './PatientPregnancyRecordsTable'
import VisitRecordsTable from './VisitRecordsTable'
import { useStateContext } from '../../../state/context'
import { useSelector, useDispatch } from 'react-redux'
import PatientMedicalRecordDetailsTable from './PatientMedicalRecordDetailsTable'
import { getAPatientMedicalRecord } from '../../../state/actions/nurse.actions'

const PatientMedicalHistoryTable = () => {
    const [showMedicalHistoryTable, setShowMedicalHistoryTable] = useState(true)
    const [showPregnancyRecordsTable, setShowPregnancyRecordsTable] = useState(false)
    const [showVisitRecordsTable, setShowVisitRecordsTable] = useState(false)
    const [showInvestigationsTable, setShowInvestigationsTable] = useState(false)
    const [showLaboursummaryTable, setShowLaboursummaryTable] = useState(false)
    const [buttonColorIndex, setButtonColorIndex] = useState(0)

    const dispatch = useDispatch()

    const { opdState, setOpdState } = useStateContext()
    const { modals, setModals } = useStateContext()
    const { set_patient_id } = useSelector((state) => state.root)
    const { all_patient_medical_record } = useSelector((state) => state.nurse)

    useEffect(() => {
        dispatch(getAPatientMedicalRecord({ id: set_patient_id?.id }))
    }, [modals.showAddPatientMedicalRecordModal])

    return (
        <>
            <div className='flex space-x-3'>
                <button
                    type="button"
                    className={`${buttonColorIndex == 0 ? "mb-2 px-6 py-2.5 bg-slate-300 border text-black font-medium text-xs uppercase rounded shadow-md" : "mb-2 px-6 py-2.5 bg-white border text-black font-medium text-xs uppercase rounded shadow-md"}`}

                    onClick={() => {
                        setShowMedicalHistoryTable(true)
                        setShowPregnancyRecordsTable(false)
                        setShowVisitRecordsTable(false)
                        setShowInvestigationsTable(false)
                        setShowLaboursummaryTable(false)
                        setButtonColorIndex(0)

                    }
                    }
                >
                    Medical History
                </button>
                <button
                    type="button"
                    className={`${buttonColorIndex == 1 ? "mb-2 px-6 py-2.5 bg-slate-300 border text-black font-medium text-xs uppercase rounded shadow-md" : "mb-2 px-6 py-2.5 bg-white border text-black font-medium text-xs uppercase rounded shadow-md"}`}

                    onClick={() => {
                        setShowMedicalHistoryTable(false)
                        setShowPregnancyRecordsTable(true)
                        setShowVisitRecordsTable(false)
                        setShowInvestigationsTable(false)
                        setShowLaboursummaryTable(false)
                        setButtonColorIndex(1)


                    }}
                >
                    Pregnancy
                </button>
                <button
                    type="button"
                    className={`${buttonColorIndex == 2 ? "mb-2 px-6 py-2.5 bg-slate-300 border text-black font-medium text-xs uppercase rounded shadow-md" : "mb-2 px-6 py-2.5 bg-white border text-black font-medium text-xs uppercase rounded shadow-md"}`}

                    onClick={() => {
                        setShowMedicalHistoryTable(false)
                        setShowPregnancyRecordsTable(false)
                        setShowVisitRecordsTable(true)
                        setShowInvestigationsTable(false)
                        setShowLaboursummaryTable(false)
                        setButtonColorIndex(2)


                    }
                    }
                >
                    Visit records
                </button>
                <button
                    type="button"
                    className={`${buttonColorIndex == 3 ? "mb-2 px-6 py-2.5 bg-slate-300 border text-black font-medium text-xs uppercase rounded shadow-md" : "mb-2 px-6 py-2.5 bg-white border text-black font-medium text-xs uppercase rounded shadow-md"}`}

                    onClick={() => {
                        setShowMedicalHistoryTable(false)
                        setShowPregnancyRecordsTable(false)
                        setShowVisitRecordsTable(false)
                        setShowInvestigationsTable(true)
                        setShowLaboursummaryTable(false)
                        setButtonColorIndex(3)


                    }
                    }
                >
                    Investigations
                </button>
                <button
                    type="button"
                    className={`${buttonColorIndex == 4 ? "mb-2 px-6 py-2.5 bg-slate-300 border text-black font-medium text-xs uppercase rounded shadow-md" : "mb-2 px-6 py-2.5 bg-white border text-black font-medium text-xs uppercase rounded shadow-md"}`}

                    onClick={() => {
                        setShowMedicalHistoryTable(false)
                        setShowPregnancyRecordsTable(false)
                        setShowVisitRecordsTable(false)
                        setShowInvestigationsTable(false)
                        setShowLaboursummaryTable(true)
                        setButtonColorIndex(4)


                    }
                    }
                >
                    Labour summary
                </button>
            </div>


            {showMedicalHistoryTable && (


                <div className='flex flex-col'>
                    <div className='flex  justify-end space-x-2'>
                        <button
                            type="button"
                            className=" px-6 py-2.5 bg-sky-600 text-white w-[100px] mb-2 font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                            onClick={() => (
                                setModals({
                                    ...modals,
                                    showAddPatientMedicalRecordModal: !modals.showAddPatientMedicalRecordModal

                                })
                            )}
                        >
                            Add +
                        </button>
                        <button
                            type="button"
                            className=" px-6 py-2.5 bg-dark-green text-white w-[100px] mb-2 font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                            onClick={() => (
                                setOpdState({
                                    ...opdState,
                                    showPatientAntenatalRecords: false

                                })
                            )}
                        >
                            Back
                        </button>

                    </div>
                    <PatientMedicalRecordDetailsTable />
                </div>
            )}


            {showPregnancyRecordsTable && (
                <PatientPregnancyRecordsTable />
            )}
            {showVisitRecordsTable && (
                <VisitRecordsTable />
            )}
            {showInvestigationsTable && (
                <InvestigationsTable />
            )}
            {showLaboursummaryTable && (
                <LabourSummaryTable />
            )}
        </>
    )
}

export default PatientMedicalHistoryTable