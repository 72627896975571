/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../state/context'
import DoctorManagePatient from './DoctorManagePatient'
import { DoctorAssignedPatientsTable, DoctorPatientsTable, PageTitle } from '../../components'
import { getAssignedPatients, getDoctorPatients } from '../../state/actions/doctor.actions'


const DoctorAssignedPatients = () => {
    const dispatch = useDispatch()

    const { appState, setAppState } = useStateContext()

    const { data } = useSelector(state => state.auth.user)
    const { doctor_assignedpatients, patient_transfer } = useSelector(state => state.doctor)
    const [showList, setShowList] = useState(true)

    useEffect(() => {
        dispatch(getAssignedPatients())
        
        return () => {
			setAppState({ ...appState, showDoctorManagePatient: false })
		}
    }, [])

    return (
        <div>
            <PageTitle type={'doctor-assignedpatients'} />

            <div className="space-y-5">
                {!appState.showDoctorManagePatient && (
                    <div className="space-y-5">
                        <DoctorAssignedPatientsTable
                            title={'Patients'}
                            data={doctor_assignedpatients}
                            showList={showList}
                            setShowList={setShowList}
                        />
                    </div>
                )}

                {appState.showDoctorManagePatient && (
                    <DoctorManagePatient />
                )}
            </div>
        </div>
    )
}

export default DoctorAssignedPatients