/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { FaTimesCircle } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { useStateContext } from "../../../state/context"
import { dispenseDrugToSupplyPoint, getSupplyPoints } from "../../../state/actions/pharmacist.actions"
import ButtonLoader from "../../utils/ButtonLoader"


const PharmacistDrugSupplyPointModal = (props) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { drug_bincard, drug_update } = useSelector(state => state.root)
    const { supplyPoints, pharmacistLoading } = useSelector(state => state.pharmacist)
    const [formData, setFormData] = useState({
        supplypoint: "",
        receipt_or_issue_voucher_number: "",
        quantity_received: 0,
        supplypoint_druginventory: drug_bincard?.pkid,
        supplypoint_drug: drug_bincard?.bincard_drug,

        bincard_drug: drug_update?.pkid,
        new_balance: parseInt(drug_update?.quantity),
        drug_category: drug_update?.drug_category.id,
        name: drug_update?.name,
        drug_substitute: drug_update?.drug_substitute,
        quantity: drug_update?.quantity,
        alert_quantity: drug_update?.alert_quantity,
    })

    useEffect(() => {
        dispatch(getSupplyPoints())
    }, [])

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if(!formData.quantity_received || !formData.supplypoint) return toast.warning("Please fill all fields")

        const data = {
            ...formData,
            ...drug_bincard,
            supplypoint: formData.supplypoint,
            quantity_received: parseInt(formData.quantity_received),
            quantity_issued: 0,
        }
        dispatch(dispenseDrugToSupplyPoint({ formData: data, toast, setModals, modals }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className="text-sky-500 text-[18px] font-medium capitalize">
                            Release To Supply Point
                        </h4>
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                setModals({ ...modals, showPharmacistDrugSupplyPointModal: !modals.showPharmacistDrugSupplyPointModal })
                            }}
                            size={20}
                        />
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="space-y-2">
                            <div className="relative">
                                <select
                                    className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none " id="grid-state"
                                    name='supplypoint'
                                    onChange={(e) => handleChange(e, e.target.name)}
                                >
                                    <option >Supply Point</option>
                                    {supplyPoints?.map((item, index) => (
                                        <option
                                            key={`${item.id}`}
                                            value={item.pkid}
                                        >{item.name}</option>
                                    ))}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                </div>
                            </div>

                            {/* <div>
                                <input
                                    type="text"
                                    name="receipt_or_issue_voucher_number"
                                    placeholder="Receipt or issue voucher number"
                                    className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                />
                            </div> */}

                            <div>
                                <input
                                    type="number"
                                    name="quantity_received"
                                    placeholder="Quantity"
                                    className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                />
                            </div>
                        </div>

                        <div className="mt-4">
                            <button
                                type="submit"
                                className="inline-block w-[175px] px-6 py-2.5 bg-sky-500 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >
                                {!pharmacistLoading ? (
                                    'Submit'
                                ) : (
                                    <ButtonLoader />
                                )}
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    )
}

export default PharmacistDrugSupplyPointModal