import React, { useState } from 'react'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { verifyPaymentInvoice } from '../state/actions/patient.actions'
import { changeAnInvoiceStatus } from '../state/actions/patient.actions'
import { useNavigate } from 'react-router-dom'


const PaymentVerification = () => {
    const [invoiceData, setInvoiceData] = useState({
        type: '',
        id: '',

    })
    const [verifyPayment, setVerifyPayment] = useState(false)
    const [triggerInvoiceStatus, setTriggerInvoiceStatus] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { verified_payment } = useSelector(state => state.patient)

    const invoice_details = JSON.parse(localStorage.getItem("Invoice_details"))

    useEffect(() => {
        const ref = window.location.href.split('reference')[1].split('=')[1]
        const formData = {
            ref
        }

        dispatch(verifyPaymentInvoice({ formData, toast, triggerInvoiceStatus, setTriggerInvoiceStatus }))

    }, [])

    useEffect(() => {
        const formData = {

        }
        const type = invoice_details?.type
        const id = invoice_details?.invoice_id

        dispatch(changeAnInvoiceStatus({ formData, type, id }));
    }, [triggerInvoiceStatus])


    return (
        <div>
            
        </div>
    )
}

export default PaymentVerification