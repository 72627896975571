import React, { Fragment, useState } from 'react'
import 'tippy.js/dist/tippy.css'
import Tippy from '@tippyjs/react'
import { useDispatch } from 'react-redux'

import { useStateContext } from '../../state/context'
import { setCurrentAccountsInvoice, setCurrentAccountsPharmacyInvoice } from '../../state/actions/actions'
import Pagination from '../utils/Pagination'


const AccountsPharmacyInvoicesTable = ({ data, showList, setShowList, title, pages, currentPage, handlePagination }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [currentData, setCurrentData] = useState([])

    return (
        <div className="flex justify-between items-center w-full">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="">
                    {showList && (
                        <Fragment>
                            <table className="border-b border-l border-r rounded w-full cursor-default">
                                <thead className="">
                                    <tr className='bg-gray-100'>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[2em] px-4 py-4 text-left"
                                        >
                                            S/N
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Patient ID
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Invoice ID
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Drug
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Quantity
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Amount
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Date
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[1em] py-4 text-left"
                                        >
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData?.map((invoice, index) => (
                                        <tr key={`${invoice.id}`}>
                                            <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                                {index + 1}
                                            </td>
                                            <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                {/* {invoice.patient_details.patient_number} */}
                                                <Tippy content={`${invoice.patient_details?.last_name} ${invoice.patient_details?.first_name}`}>
                                                    <span>{invoice.patient_details.patient_number}</span>
                                                </Tippy>
                                            </td>
                                            <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                {invoice.invoice_number}
                                            </td>
                                            <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                {invoice.prescription_details.drugs}
                                            </td>
                                            <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                {invoice.prescription_details.drug_quantity}
                                            </td>
                                            <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                &#8358; {invoice.invoice_amount}
                                            </td>
                                            <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                {invoice.created_at.slice(0, 10)}
                                            </td>
                                            <td className="text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                <button
                                                    type="button"
                                                    className="w-[80px] inline-block px-6 py-2 bg-teal-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease-in-out"
                                                    onClick={() => {
                                                        dispatch(setCurrentAccountsPharmacyInvoice({ data: invoice }))
                                                        setModals({ ...modals, showAccountsViewPharmacyInvoiceModal: !modals.showAccountsViewPharmacyInvoiceModal })
                                                    }}
                                                >
                                                    View
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <Pagination
                                data={data}
                                currentData={currentData}
                                setCurrentData={setCurrentData}
                            />
                        </Fragment>
                    )}
                </div>
            </div>
        </div >
    )
}

export default AccountsPharmacyInvoicesTable