import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BsTelephoneOutboundFill } from 'react-icons/bs'
import { useStateContext } from '../../state/context'
import Role from '../utils/Role'
import { setCurrentChatConversation, setCurrentChatUser } from '../../state/actions/chat.actions'
import { setCallReceiver } from '../../state/actions/actions'


const VideoCallUser = ({ user, setName }) => {
    const dispatch = useDispatch()

    const { callUser, me, modals, setModals } = useStateContext()



    return (
        <div className='flex justify-between items-center p-2 bg-white border-b border-gray-200 cursor-pointer group'>
            <div className="relative w-full flex items-center group-hover:translate-x-1 duration-500 ease-in-out">
                <img
                    src={`${process.env.REACT_APP_API_ROOT}${user?.user_profile?.profile_photo}`}
                    alt="user"
                    className='mr-2 w-[50px] h-[50px] rounded-[100%]'
                />

                {!user?.is_online && (
                    <div className="absolute bottom-1 ml-10 flex justify-center items-center w-[12px] h-[12px] bg-slate-400 border-2 border-white rounded-full"></div>
                )}

                {user?.is_online && (
                    <div className="absolute bottom-1 ml-10 flex justify-center items-center w-[12px] h-[12px] bg-teal-400 border-2 border-white rounded-full"></div>
                )}

                <div className="flex flex-col -space-y-4">
                    <p className="text-[12px] text-gray-600">{user?.last_name} {user?.first_name}</p>
                    <Role user={user} type={'chat'} />
                </div>
            </div>
            <BsTelephoneOutboundFill
                onClick={() => {
                    const data = { fullname: `${user?.last_name} ${user?.first_name}` }
                    dispatch(setCallReceiver({ data: data }))
                    setModals({ ...modals, showCallNotification: !modals?.showCallNotification })

                    callUser(user?.socket_id)


                }}
                className='text-teal-400 cursor-pointer'
            />
        </div>
    )
}

export default VideoCallUser