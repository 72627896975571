import dayjs from 'dayjs'
import React from 'react'
import { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setFilteredSchedules, setScheduleEdit } from '../../state/actions/actions'
import { getAllSchedules } from '../../state/actions/admin.actions'
import { useStateContext } from '../../state/context'


const CalenderDay = ({ day, rowIndex }) => {
    const { all_schedules } = useSelector(state => state.admin)
    const [dayEvent, setDayEvent] = useState([])
    const { user } = useSelector(state => state.auth)
    const { modals, setModals } = useStateContext()
    const dispatch = useDispatch()
    const events = useMemo(() => {
        return all_schedules?.filter(
            (evt) => evt.date
        )
    })
    const date_events = events?.filter(
        (evt) => dayjs(evt.date).format("DD-MM-YY") === day.format("DD-MM-YY")
    )

    useEffect(() => {
        dispatch(getAllSchedules())

    }, [])

    useEffect(() => {


    }, [modals.showCreateScheduleModal, modals.showEditAndDeleteScheduleModal])



    function getCurrentDayClass() {
        return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY") ? 'bg-blue-600 text-white rounded-full w-7' : ''
    }

    return (
        <div className='border border-gray-200 flex flex-col rounded-sm h-[120px]'>
            <header className='flex flex-col items-center'>
                {rowIndex === 0 && (
                    <p className='text-sm mt-1'>{day.format('ddd').toUpperCase()}</p>
                )}
                <p className={`text-sm p-1 my-1 font-light text-center ${getCurrentDayClass()}`}>
                    {day.format('DD')}
                </p>
            </header>
            {date_events && date_events.map((e) => e?.members?.map((evt => evt.email === user.data.email && <p className='rounded-lg p-1 ml-1 mb-1 bg-indigo-500 w-[10px] h-[10px]'></p>)))}

            <div className='hover:cursor-pointer' onClick={() => {

            }}>
                {

                    date_events?.slice(0, 1)?.map((evt, idx) => (
                        <>
                            <div
                                onClick={() => {
                                    const data = date_events
                                    dispatch(setFilteredSchedules({ data }))
                                    setModals({ ...modals, showListOfSchedulesByDateModal: !modals.showListOfSchedulesByDateModal })


                                    // const data = {
                                    //     id: evt.id,
                                    //     date: evt.date,
                                    //     members: evt.members,
                                    //     title: evt.schedule_title,
                                    //     time: evt.time,
                                    //     department: evt.department,
                                    //     label: evt.label
                                    // }
                                    // dispatch(setScheduleEdit({ data }))

                                    // setModals({ ...modals, showEditDeleteOptionalModal: !modals.showEditDeleteOptionalModal })

                                }}

                                key={idx}
                                className={` mr-3 text-gray-600 text-sm rounded mt-0 truncate
                            ${evt.label === 'indigo' && `bg-${evt.label}-400`}
                            ${evt.label === 'green' && `bg-${evt.label}-300`}
                            ${evt.label === 'purple' && `bg-${evt.label}-400`}
                            ${!evt.label && 'bg-yellow-200'} rounded p-1 mr-3
                        `}

                            >
                                <p className="text-[8px] text-gray-600 text-sm  truncate">{evt.schedule_title}</p>

                            </div>
                            {date_events.length > 1 && <p className='text-[8px] '
                                onClick={() => {
                                    const data = date_events
                                    dispatch(setFilteredSchedules({ data }))
                                    setModals({ ...modals, showListOfSchedulesByDateModal: !modals.showListOfSchedulesByDateModal })
                                }

                                }


                            >
                                ......show more
                            </p>}

                        </>

                    ))


                }


            </div>



        </div >
    )
}

export default CalenderDay 