import { indexOf } from 'lodash';
import React from 'react'
import { useState } from 'react';

const RadiologyPagination = ({ postPerPage, totalPost, paginate }) => {
    const pageNumbers = []
    const [currentIndex, setCurrenIndex] = useState(0)

    for (let i = 1; i <= Math.ceil(totalPost / postPerPage); i++) {
        pageNumbers.push(i);
    }
    return (
        <div className='flex mt-1'>

            {pageNumbers.map((number, index) => (
                <button className={currentIndex == index ? 'bg-gray-400 px-4 px-y mr-2  border-solid rounded-sm' : 'px-4 px-y mr-2 bg-slate-300 border-black rounded-sm'}
                    onClick={() => {
                        setCurrenIndex(index)
                        console.log(currentIndex, index)
                        paginate(number)
                    }}
                >{number}</button>
            ))}

        </div>
    )
}

export default RadiologyPagination