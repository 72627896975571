/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { Fragment, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { FaTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import { useStateContext } from "../../../state/context"
import { createDrug, getDrugCategories, getSupplyPoints, updateDrug } from "../../../state/actions/pharmacist.actions"
import { setCurrentDrug, setCurrentTeam } from "../../../state/actions/actions"
import { TiCancel } from "react-icons/ti"
import { createTeam, getDoctors, getTeam, resetCurrentTeam, updateTeam } from "../../../state/actions/doctor.actions"
import { AiOutlinePlus } from "react-icons/ai"
import Role from "../../utils/Role"
import ButtonLoader from "../../utils/ButtonLoader"


const DoctorCreateTeamModal = (props) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { doctorteam_update } = useSelector(state => state.root)
    const { doctors, team, teams, doctorLoading } = useSelector(state => state.doctor)
    const [existingMembers, setExistingMembers] = useState([])
    const [filteredDoctors, setFilteredDoctors] = useState([])
    const [teamDoctors, setTeamDoctors] = useState([])
    const [nonTeamDoctors, setNonTeamDoctors] = useState([])

    const [formData, setFormData] = useState({
        name: "",
        team_lead: "",
        members: [],
        update_team_search: ''
    })


    useEffect(() => {
        dispatch(getDoctors())
    }, [])

    useEffect(() => {
        const members = []
        teams?.forEach(team => {
            team.members.forEach(member => members.push(member))
        })

        setExistingMembers(members)
    }, [teams])

    useEffect(() => {
        dispatch(getTeam({ id: doctorteam_update?.id }))
    }, [doctorteam_update])

    useEffect(() => {
        setFormData({
            ...formData,
            name: team?.name,
            team_lead: team?.team_lead?.email,
            members: team?.members
        })

        setNonTeamDoctors(
            doctors?.filter(doctor => {
                return !existingMembers?.find((member => member.pkid === doctor.pkid))
            })
        )
    }, [team])

    const filterDoctors = (value) => {
        const initials = doctors?.filter(doctor => {
            return !existingMembers?.find(member => doctor.profile.email === member.email)
        })

        const filtered = initials?.filter(doctor =>
            doctor?.profile?.email?.toLowerCase().includes(value)
        )

        setFilteredDoctors(filtered)
    }

    const filterNonTeamDoctors = (value) => {
        const filtered = nonTeamDoctors?.filter(doctor => doctor?.profile?.email.toLowerCase().includes(value))
        setNonTeamDoctors(filtered)
    }

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))

    }


    const handleSubmit = (e) => {
        e.preventDefault()

        console.log(formData);
        const members = []
        teamDoctors.forEach((doctor => members.push(doctor.pkid)))

        if (!members) return toast.warning('Team members is required')
        if (!formData.name || !formData.team_lead) return toast.warning('Team name and team lead is required')
        dispatch(createTeam({ formData: { ...formData, members }, toast, setModals, modals }))
    }

    const handleUpdate = (e) => {
        e.preventDefault()

        const members = []
        formData?.members.forEach(member => members.push(member.pkid))

        dispatch(updateTeam({ formData: { ...formData, id: team?.id, members }, toast, setModals, modals, dispatch, setCurrentTeam, resetCurrentTeam }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[614px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className={`${modals.showDoctorUpdateTeamModal ? 'text-sky-500' : modals.showDoctorViewTeamModal ? 'text-sky-500' : 'text-dark-green'} text-[18px] font-medium`}>
                            {modals.showDoctorCreateTeamModal ? 'Create Team' : modals.showDoctorUpdateTeamModal ? 'Update Team' : 'Team'}
                        </h4>
                        <FaTimesCircle
                            className="text-dark-yellow cursor-pointer"
                            onClick={() => {
                                dispatch(setCurrentTeam({ data: null }))
                                dispatch(resetCurrentTeam())
                                modals.showDoctorCreateTeamModal && setModals({ ...modals, showDoctorCreateTeamModal: !modals.showDoctorCreateTeamModal })
                                modals.showDoctorUpdateTeamModal && setModals({ ...modals, showDoctorUpdateTeamModal: !modals.showDoctorUpdateTeamModal })
                                modals.showDoctorViewTeamModal && setModals({ ...modals, showDoctorViewTeamModal: !modals.showDoctorViewTeamModal })
                            }}
                            size={20}
                        />
                    </div>

                    {modals.showDoctorCreateTeamModal && (
                        <form onSubmit={handleSubmit}>
                            <div className="space-y-2">
                                <div>
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="Team Name"
                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div>
                                    <input
                                        type="email"
                                        name="team_lead"
                                        placeholder="Team Lead Email"
                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div className="flex flex-col space-y-2 px-2 py-3 shadow-sky-200 shadow rounded">
                                    <div className="border-b border-gray-400 text-gray-600 px-[5px] py-[1px] text-[12px] font-bold">Members</div>
                                    <div className="flex justify-center items-center">
                                        <input
                                            type="text"
                                            placeholder="Doctor Email Address"
                                            className="border rounded w-full px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:border-blue-200 focus:border-2"
                                            onChange={(e) => filterDoctors(e.target.value.toLowerCase())}
                                        />
                                    </div>

                                    <div className="flex flex-col space-y-2 max-h-[250px] overflow-y-auto patient__prescriptions">
                                        {teamDoctors?.map((doctor, index) => (
                                            <div key={`${doctor.id}`} className="flex justify-between w-full">
                                                <div className="w-[90%] flex items-center space-x-4 border p-1 rounded-tl rounded-bl">
                                                    <img
                                                        src={`${process.env.REACT_APP_API_ROOT}${doctor?.profile?.profile_photo}`}
                                                        className="w-[40px] h-[40px] rounded-[50%]"
                                                        alt=""
                                                    />
                                                    <div className="flex flex-col -space-y-1">
                                                        <p className="text-[12px] text-gray-600 font-bold">{doctor.last_name} {doctor.first_name}</p>
                                                        <p className="text-[12px] text-gray-600">{doctor?.profile?.email}</p>
                                                        <Role user={doctor} />
                                                    </div>
                                                </div>

                                                <div
                                                    onClick={() => {
                                                        setFilteredDoctors([...filteredDoctors, doctor])
                                                        setTeamDoctors(teamDoctors.filter((user, uindex) => (uindex + 1) !== (index + 1)))
                                                    }}
                                                    className="w-[10%] flex items-center justify-center border-r border-t border-b p-1 rounded-tr rounded-br hover:cursor-pointer bg-yellow-400 ease-in-out duration-500">
                                                    <TiCancel
                                                        size={20}
                                                        className="text-white ease-in-out duration-500"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                        {filteredDoctors?.map((doctor, index) => (
                                            <div key={`${doctor.id}`} className="flex justify-between w-full">
                                                <div className="w-[90%] flex items-center space-x-4 border p-1 rounded-tl rounded-bl">
                                                    <img
                                                        src={`${process.env.REACT_APP_API_ROOT}${doctor?.profile?.profile_photo}`}
                                                        className="w-[40px] h-[40px] rounded-[50%]"
                                                        alt=""
                                                    />
                                                    <div className="flex flex-col -space-y-1">
                                                        <p className="text-[12px] text-gray-600 font-bold">{doctor.last_name} {doctor.first_name}</p>
                                                        <p className="text-[12px] text-gray-600">{doctor?.profile?.email}</p>
                                                        <Role user={doctor} />
                                                    </div>
                                                </div>

                                                <div
                                                    onClick={() => {
                                                        setTeamDoctors([...teamDoctors, doctor])
                                                        // const index = filterDoctors.findIndex((user) => user.members.)
                                                        setFilteredDoctors(filteredDoctors.filter((user, uindex) => (uindex + 1) !== (index + 1)))
                                                    }}
                                                    className="w-[10%] flex items-center justify-center border-r border-t border-b p-1 rounded-tr rounded-br hover:cursor-pointer hover:bg-green-400 ease-in-out duration-500">
                                                    <AiOutlinePlus
                                                        size={20}
                                                        className="text-teal-600 hover:text-white ease-in-out duration-500"
                                                    />
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>

                            </div>

                            <div className="mt-4">
                                {!doctorLoading ? (
                                    <button
                                        type="submit"
                                        className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                    >
                                        Create
                                    </button>
                                ) : (
                                    <button
                                        className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                    >
                                        <ButtonLoader />
                                    </button>
                                )}
                            </div>
                        </form>
                    )}

                    {modals.showDoctorUpdateTeamModal && (
                        <form onSubmit={handleUpdate}>
                            <div className="space-y-2">
                                <div>
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="Team Name"
                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        value={formData.name}
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div>
                                    <input
                                        type="text"
                                        name="team_lead"
                                        placeholder="Team Lead"
                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        value={formData.team_lead}
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />
                                </div>

                                <div className="flex flex-col space-y-2 px-2 py-3 shadow-sky-200 shadow rounded">
                                    <div className="border-b border-gray-400 text-gray-600 px-[5px] py-[1px] text-[12px] font-bold">Members</div>
                                    <div className="flex justify-center items-center">
                                        <input
                                            type="text"
                                            name="update_team_search"
                                            placeholder="Email address"
                                            className="border rounded w-full px-4 py-1 mt-2 hover:outline-none focus:outline-none focus:border-blue-200 focus:border-2"
                                            // onChange={(e) => handleChange(e, e.target.name)}
                                            onChange={(e) => filterNonTeamDoctors(e.target.value.toLowerCase())}
                                        />
                                    </div>

                                    <div className="flex flex-col space-y-2 max-h-[250px] overflow-y-auto patient__prescriptions">
                                        {formData.members?.map((doctor, index) => (
                                            <div key={`${doctor.id}`} className={`flex justify-between w-full ${doctor.id === team?.team_lead.id && 'bg-gray-400 rounded-tl rounded-bl'}`}>
                                                <div className="w-[90%] flex items-center space-x-4 border p-1 rounded-tl rounded-bl">
                                                    <img
                                                        src={`${process.env.REACT_APP_API_ROOT}${doctor?.profile?.profile_photo}`}
                                                        className="w-[40px] h-[40px] rounded-[50%]"
                                                        alt=""
                                                    />
                                                    <div className="flex flex-col space-y-1">
                                                        <p className={`text-[12px] ${doctor.id === team?.team_lead.id ? 'text-white' : 'text-gray-600'} font-bold`}>{doctor.last_name} {doctor.first_name}</p>
                                                        <p className={`text-[12px] ${doctor.id === team?.team_lead.id ? 'text-white' : 'text-gray-600'}`}>{doctor?.email ? doctor?.email : doctor?.profile?.email}</p>
                                                        <Role user={doctor} type={"banner"} />
                                                        {/* {team?.members?.map((member) => (
                                                            <Role user={member} />
                                                        ))}
                                                        {console.log(doctor, '::')} */}
                                                    </div>
                                                </div>

                                                <div
                                                    onClick={() => {
                                                        setNonTeamDoctors([...nonTeamDoctors, doctor])
                                                        setFormData({ ...formData, members: formData?.members?.filter((user, uindex) => (uindex + 1) !== (index + 1)) })
                                                    }}
                                                    className="w-[10%] flex items-center justify-center border-r border-t border-b p-1 rounded-tr rounded-br hover:cursor-pointer bg-yellow-400 ease-in-out duration-500">
                                                    <TiCancel
                                                        size={20}
                                                        className="text-white ease-in-out duration-500"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                        {nonTeamDoctors?.map((doctor, index) => (
                                            <div key={`${doctor.id}`} className="flex justify-between w-full">
                                                <div className="w-[90%] flex items-center space-x-4 border p-1 rounded-tl rounded-bl">
                                                    <img
                                                        src={`${process.env.REACT_APP_API_ROOT}${doctor?.profile?.profile_photo}`}
                                                        className="w-[40px] h-[40px] rounded-[50%]"
                                                        alt=""
                                                    />
                                                    <div className="flex flex-col -space-y-1">
                                                        <p className="text-[12px] text-gray-600 font-bold">{doctor.last_name} {doctor.first_name}</p>
                                                        <p className="text-[12px] text-gray-600">{doctor?.profile?.email ? doctor?.profile?.email : doctor?.email}</p>
                                                        <Role user={doctor} />
                                                    </div>
                                                </div>

                                                <div
                                                    onClick={() => {
                                                        setFormData({ ...formData, members: [...formData.members, doctor] })
                                                        setNonTeamDoctors(nonTeamDoctors.filter((user, uindex) => (uindex + 1) != (index + 1)))
                                                    }}
                                                    className="w-[10%] flex items-center justify-center border-r border-t border-b p-1 rounded-tr rounded-br hover:cursor-pointer hover:bg-green-400 ease-in-out duration-500">
                                                    <AiOutlinePlus
                                                        size={20}
                                                        className="text-teal-600 hover:text-white ease-in-out duration-500"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>

                            <div className="mt-4">
                                {!doctorLoading ? (
                                    <button
                                        type="submit"
                                        className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                    >
                                        Update
                                    </button>
                                ) : (
                                    <button
                                        type="submit"
                                        className="inline-block w-[175px] px-6 py-2.5 bg-sky-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                                    >
                                        <ButtonLoader />
                                    </button>
                                )}
                            </div>
                        </form>
                    )}

                    {modals.showDoctorViewTeamModal && (
                        <div className="space-y-2">
                            <div>
                                <input
                                    type="text"
                                    name="name"
                                    disabled
                                    placeholder="Team Name"
                                    className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    value={formData.name}
                                // onChange={(e) => handleChange(e, e.target.name)}
                                />
                            </div>

                            {/* <div>
                                <input
                                    type="text"
                                    name="drug_substitute"
                                    placeholder="Team Lead"
                                    className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                    value={formData.team_lead}
                                    onChange={(e) => handleChange(e, e.target.name)}
                                />
                            </div> */}

                            <div className="flex flex-col space-y-2 px-2 py-3 shadow-sky-200 shadow rounded">
                                <div className="border-b border-gray-400 text-gray-600 px-[5px] py-[1px] text-[12px] font-bold">Members</div>

                                <div className="flex flex-col space-y-2">
                                    <div className="flex flex-wrap justify-between w-full">
                                        {formData.members?.map((member, index) => (
                                            <div
                                                key={`${member.id}`}
                                                className={`w-[45%] flex items-center space-x-4 border p-1 rounded ${(index + 1) > 2 && 'mt-2'} ${member.id === team.team_lead.id && 'bg-sky-200'}`}
                                            >
                                                <img
                                                    src={`${process.env.REACT_APP_API_ROOT}${member.profile.profile_photo}`}
                                                    className="w-[40px] h-[40px] rounded-[50%]"
                                                    alt=""
                                                />
                                                <div className="flex flex-col space-y-1">
                                                    <p className="text-[12px] text-gray-600 font-bold">{member.last_name} {member.first_name}</p>
                                                    <p className="text-[12px] text-gray-600">{member.email}</p>
                                                    <Role user={member} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                    )}
                </div>
            </div>
        </div >
    )
}

export default DoctorCreateTeamModal
