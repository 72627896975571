import { Fragment } from 'react'

import AddBillingModal from '../../modals/admin/AddBillingModal'
import AddWardModal from '../../modals/admin/AddWardModal'
import CreateDepartmentModal from '../../modals/admin/CreateDepartmentModal'
import CreateDirectorateModal from '../../modals/admin/CreateDirectorateModal'
import CreateEmployeeModal from '../../modals/admin/CreateEmployeeModal'
import { DeleteDirectorateModal, DeleteDepartmentModal, DeleteEmployeeModal, DeleteBillingModal, DeleteWardModal, AddBedSpaceModal, DeleteBedSpaceModal } from '../..'
import CreateScheduleModal from '../../modals/admin/CreateScheduleModal'
import EditAndDeleteScheduleModal from '../../modals/admin/EditAndDeleteScheduleModal'
import ListOfSchedulesByDateModal from '../../modals/admin/ListOfSchedulesByDateModal'
import DeleteScheduleModal from '../../modals/admin/DeleteScheduleModal'
import EditDeleteOptionalModal from '../../modals/admin/EditDeleteOptionalModal'
import UpdateHospitalInfoModal from '../../modals/admin/UpdateHospitalInfoModal'
import UpdateHospitalLogoModal from '../../modals/admin/UpdateHospitalLogoModal'
import AddGatewaySettingModal from '../../modals/admin/AddGatewaySettingModal'
import TopUpSMSUnitsModal from '../../modals/admin/TopUpSMSUnitsModal'
import RenewSubscriptionPlanModal from '../../modals/admin/RenewSubscriptionPlanModal'
import UpgradeSubscriptionPlanModal from '../../modals/admin/UpgradeSubscriptionPlanModal'
import PayMonthlyRemittanceModal from '../../modals/admin/PayMonthlyRemittanceModal'


const AdminFormModals = (props) => {
    return (
        <Fragment>
            {props.type === 'add__directorate__modal' && (
                <CreateDirectorateModal />
            )}

            {props.type === 'update__directorate__modal' && (
                <CreateDirectorateModal />
            )}

            {props.type === 'delete__directorate__modal' && (
                <DeleteDirectorateModal />
            )}

            {props.type === 'add__department__modal' && (
                <CreateDepartmentModal />
            )}

            {props.type === 'update__department__modal' && (
                <CreateDepartmentModal />
            )}

            {props.type === 'delete__department__modal' && (
                <DeleteDepartmentModal />
            )}

            {props.type === 'add__employee__modal' && (
                <CreateEmployeeModal />
            )}

            {props.type === 'update__employee__modal' && (
                <CreateEmployeeModal />
            )}

            {props.type === 'delete__employee__modal' && (
                <DeleteEmployeeModal />
            )}

            {props.type === 'add__billing__modal' && (
                <AddBillingModal />
            )}

            {props.type === 'update__billing__modal' && (
                <AddBillingModal />
            )}

            {props.type === 'delete__billing__modal' && (
                <DeleteBillingModal />
            )}

            {props.type === 'add__ward__modal' && (
                <AddWardModal />
            )}

            {props.type === 'update__ward__modal' && (
                <AddWardModal />
            )}

            {props.type === 'delete__ward__modal' && (
                <DeleteWardModal />
            )}

            {props.type === 'add__bedspace__modal' && (
                <AddBedSpaceModal />
            )}

            {props.type === 'update__bedspace__modal' && (
                <AddBedSpaceModal />
            )}

            {props.type === 'delete__bedspace__modal' && (
                <DeleteBedSpaceModal />
            )}
            {props.type === 'create_scheduleModal' && (
                <CreateScheduleModal />
            )}
            {props.type === 'edit_delete_scheduleModal' && (
                <EditAndDeleteScheduleModal />
            )}
            {props.type === 'list_of_Schedules_by_date' && (
                <ListOfSchedulesByDateModal />
            )}

            {props.type === 'delete_schedule_modal' && (
                <DeleteScheduleModal />
            )}
            {props.type === 'edit_delete_optional_modal' && (
                <EditDeleteOptionalModal />
            )}
            {props.type === 'view_schedule_modal' && (
                <EditAndDeleteScheduleModal />
            )}

            {props.type === 'update__hospital__info__modal' && (
                <UpdateHospitalInfoModal />
            )}

            {props.type === 'update__hospital__logo__modal' && (
                <UpdateHospitalLogoModal />
            )}

            {props.type === 'add__gateway__setting__modal' && (
                <AddGatewaySettingModal />
            )}

            {props.type === 'update__gateway__setting__modal' && (
                <AddGatewaySettingModal />
            )}

            {props.type === 'topup__sms__units__modal' && (
                <TopUpSMSUnitsModal />
            )}

            {props.type === 'renew__subscriptionplan' && (
                <RenewSubscriptionPlanModal />
            )}

            {props.type === 'upgrade__subscriptionplan' && (
                <UpgradeSubscriptionPlanModal />
            )}

            {props.type === 'pay__monthly__remittance__modal' && (
                <PayMonthlyRemittanceModal />
            )}
        </Fragment>
    )
}

export default AdminFormModals