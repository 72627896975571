import { createSlice } from '@reduxjs/toolkit'
import { getCurrentPatientProfile, getAllCurrentUserLabInvoices, getAllCurrentUserRadiologyInvoices,
     getAllCurrentUserPharmacyInvoices, getAllCurrentUserRecordsInvoices, verifyPaymentInvoice, getCurrentUserAllAppointment, getCurrentUserAllAssignment, getDashboardInfo} from '../actions/patient.actions'

const patientSlice = createSlice({
    name: 'patient',
    initialState: {
        dashboard_info: null,
        current_patient_profile: null,
        current_user_lab_invoices: null,
        current_user_radiology_invoices: null,
        current_user_pharmacy_invoices: null,
        current_user_records_invoices: null,
        verified_payment: null,
        patientLoading: false,
        patientErrors: null,
        current_user_appointment:null,
        current_user_assignment:null
    },
    extraReducers: {
        [getDashboardInfo.pending]: (state, action) => {
            state.patientLoading = true
        },
        [getDashboardInfo.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.dashboard_info = action.payload

        },
        [getDashboardInfo.rejected]: (state, action) => {
            state.patientLoading = false
        },

        [getCurrentPatientProfile.pending]: (state, action) => {
            state.patientLoading = true
        },
        [getCurrentPatientProfile.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.current_patient_profile = action.payload

        },
        [getCurrentPatientProfile.rejected]: (state, action) => {
            state.patientLoading = false
        },

        [getAllCurrentUserLabInvoices.pending]: (state, action) => {
            state.patientLoading = true
        },
        [getAllCurrentUserLabInvoices.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.current_user_lab_invoices = action.payload

        },
        [getAllCurrentUserLabInvoices.rejected]: (state, action) => {
            state.patientLoading = false
        },

        [getAllCurrentUserRadiologyInvoices.pending]: (state, action) => {
            state.patientLoading = true
        },
        [getAllCurrentUserRadiologyInvoices.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.current_user_radiology_invoices = action.payload

        },
        [getAllCurrentUserRadiologyInvoices.rejected]: (state, action) => {
            state.patientLoading = false
        },

        [getAllCurrentUserPharmacyInvoices.pending]: (state, action) => {
            state.patientLoading = true
        },
        [getAllCurrentUserPharmacyInvoices.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.current_user_pharmacy_invoices = action.payload

        },
        [getAllCurrentUserPharmacyInvoices.rejected]: (state, action) => {
            state.patientLoading = false
        },
        [getAllCurrentUserRecordsInvoices.pending]: (state, action) => {
            state.patientLoading = true
        },
        [getAllCurrentUserRecordsInvoices.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.current_user_records_invoices = action.payload

        },
        [getAllCurrentUserRecordsInvoices.rejected]: (state, action) => {
            state.patientLoading = false
        },

        [verifyPaymentInvoice.pending]: (state, action) => {
            state.patientLoading = true
        },
        [verifyPaymentInvoice.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.verified_payment = action.payload

        },
        [verifyPaymentInvoice.rejected]: (state, action) => {
            state.patientLoading = false
        },
        [getCurrentUserAllAppointment.pending]: (state, action) => {
            state.patientLoading = true
        },
        [getCurrentUserAllAppointment.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.current_user_appointment = action.payload

        },
        [getCurrentUserAllAppointment.rejected]: (state, action) => {
            state.patientLoading = false
        },
        [getCurrentUserAllAssignment.pending]: (state, action) => {
            state.patientLoading = true
        },
        [getCurrentUserAllAssignment.fulfilled]: (state, action) => {
            state.patientLoading = false
            state.current_user_assignment = action.payload

        },
        [getCurrentUserAllAssignment.rejected]: (state, action) => {
            state.patientLoading = false
        },
    
    
    }

})


export default patientSlice.reducer;