import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { FaUserCircle } from 'react-icons/fa'
import { AiOutlineCaretDown } from 'react-icons/ai'

import { useStateContext } from '../../state/context'
import { assignDoctor } from '../../state/actions/actions'
import { setRecordsGenerateCard } from '../../state/actions/actions'
import Pagination from '../utils/Pagination'


const RecordsPatientsTable = ({ data, showActionsDropdown, setShowActionsDropdown, currentPatientIndex, setCurrentPatientIndex }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const [currentData, setCurrentData] = useState([])

    return (
        <div className='flex justify-between items-center w-full px-1'>
            <div className='flex flex-col w-full cursor-pointer'>
                <div className='py-2'>
                    <div className='flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1'>
                        <h4 className='text-dark-blue text-[14px] font-bold'>
                            Patients
                        </h4>
                        <FaUserCircle
                            className='text-dark-blue'
                            size={20}
                        />
                        {/* <input type="text" name="filter" placeholder='Search patient ' id="" className='ml-2 pl-4 text-sm rounded h-[30px] w-[400px]' onChange={(e) => handleChange(e, e.target.name)} /> */}
                    </div>
                    <table className='border rounded w-full cursor-default'>
                        <thead className=''>
                            <tr>
                                <th
                                    scope='col'
                                    className='text-sm font-medium text-gray-900 px-4 py-4 text-left'
                                >
                                    S/N
                                </th>
                                <th
                                    scope='col'
                                    className='text-sm font-medium text-gray-900 px-4 py-4 text-left'
                                >
                                    Patient ID
                                </th>
                                <th
                                    scope='col'
                                    className='text-sm font-medium text-gray-900 py-4 text-left'
                                >
                                    Full name
                                </th>
                                <th
                                    scope='col'
                                    className='text-sm font-medium text-gray-900 py-4 text-left'
                                >
                                    Patient Doctor
                                </th>
                                <th
                                    scope='col'
                                    className='text-sm font-medium text-gray-900 py-4 text-left'
                                >
                                    Phone
                                </th>
                                <th
                                    scope='col'
                                    className='text-sm font-medium text-gray-900 py-4 text-left'
                                >
                                    
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentData?.map((patient, index) => (
                                <tr
                                    // className='relative '
                                    className={`${patient?.nhis_number ? 'bg-indigo-40 text-gray-900' : 'text-gray-900'} relative`}
                                    key={`${patient.id}`}
                                >
                                    <td className='px-4 py-2 whitespace-nowrap text-sm font-medium '>
                                        {index + 1}
                                    </td>
                                    <td className='text-sm  font-light py-2 whitespace-nowrap'>
                                        {patient.patient_number} <span className="font-medium text-[10px]">{patient?.nhis_number && '(NHIS)'}</span>
                                    </td>
                                    <td className='text-sm  font-light py-2 whitespace-nowrap'>
                                        {patient.first_name}{' '}
                                        {patient.last_name}
                                    </td>
                                    <td className='text-sm  font-light py-2 whitespace-nowrap'>
                                        {patient?.patient_doctor?.first_name} {patient?.patient_doctor?.last_name}
                                    </td>
                                    <td className='text-sm  font-light py-2 whitespace-nowrap'>
                                        {patient.patient_phone}
                                    </td>
                                    <td className=' text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                        <div className='relative'>
                                            <button
                                                type='button'
                                                className='inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease'
                                                onClick={() => {
                                                    setShowActionsDropdown(!showActionsDropdown)

                                                    setCurrentPatientIndex(patient.pkid)
                                                }}
                                            >
                                                <span className='flex items-center justify-between'>
                                                    Actions <AiOutlineCaretDown />
                                                </span>
                                            </button>

                                            {showActionsDropdown && currentPatientIndex === patient.pkid && (
                                                <div className='absolute z-20 top-[20px] left-[-40px] p-1 bg-white shadow-lg border border-gray-200 rounded w-[150px]'>
                                                    <div className='flex flex-col justify-start space-y-5'>
                                                        <button
                                                            onClick={() => {

                                                                setShowActionsDropdown(!showActionsDropdown)
                                                                setModals({ ...modals, showChangeDoctorModal: !modals.showChangeDoctorModal })

                                                                const data = {
                                                                    photo: patient.profile_photo,
                                                                    patient_id: patient.user_id,
                                                                    first_name: patient.user_details.first_name,
                                                                    last_name: patient.user_details.last_name,
                                                                    patient_no: patient.patient_number,
                                                                    doctor: patient.patient_doctor,
                                                                    patient_pkid: patient.user_details.pkid
                                                                }

                                                                dispatch(assignDoctor({ data }))
                                                            }}
                                                            className='hover:bg-green-200 hover:text-gray-600 rounded p-2'
                                                        >
                                                            Assign Doctor
                                                        </button>
                                                        <button
                                                            onClick={() => {
                                                                setShowActionsDropdown(!showActionsDropdown)
                                                                setModals({ ...modals, showAssignDoctorModal: !modals.showAssignDoctorModal })

                                                                const data = {
                                                                    photo: patient.profile_photo,
                                                                    patient_id: patient.user_id,
                                                                    first_name: patient.user_details.first_name,
                                                                    last_name: patient.user_details.last_name,
                                                                    patient_no: patient.patient_number,
                                                                    doctor: patient.patient_doctor
                                                                }

                                                                dispatch(assignDoctor({ data }))
                                                            }}
                                                            className='hover:bg-green-200 hover:text-gray-600 rounded p-2'
                                                        >
                                                            Change Doctor
                                                        </button>

                                                        <button
                                                            onClick={() => {
                                                                setShowActionsDropdown(!showActionsDropdown)
                                                                setModals({ ...modals, showBookAppointmentModal: !modals.showBookAppointmentModal })

                                                                const data = {
                                                                    photo: patient.profile_photo,
                                                                    patient_id: patient.user_id,
                                                                    first_name: patient.user_details.first_name,
                                                                    last_name: patient.user_details.last_name,
                                                                    patient_no: patient.patient_number,
                                                                    doctor: patient.patient_doctor,
                                                                    patient_pkid: patient.user_details.pkid
                                                                }

                                                                dispatch(assignDoctor({ data }))
                                                            }}
                                                            className='hover:bg-green-200 hover:text-gray-600 rounded p-2'
                                                        >
                                                            Book Appointment
                                                        </button>

                                                        <button
                                                            onClick={() => {
                                                                const data = {
                                                                    photo: patient.profile_photo,
                                                                    patient_id: patient.user_id,
                                                                    first_name: patient.user_details.first_name,
                                                                    last_name: patient.user_details.last_name,
                                                                    patient_no: patient.patient_number,
                                                                    doctor: patient.patient_doctor
                                                                }

                                                                dispatch(setRecordsGenerateCard({ data }))

                                                                setShowActionsDropdown(!showActionsDropdown)

                                                                setModals({ ...modals, showGenerateCardModal: !modals.showGenerateCardModal })
                                                            }}
                                                            className='hover:bg-green-200 hover:text-gray-600 rounded p-2'
                                                        >
                                                            Generate Card
                                                        </button>

                                                        <button
                                                            onClick={() => {
                                                                const data = {
                                                                    photo: patient.profile_photo,
                                                                    patient_id: patient.user_id,
                                                                    first_name: patient.user_details.first_name,
                                                                    last_name: patient.user_details.last_name,
                                                                    patient_no: patient.patient_number,
                                                                    doctor: patient.patient_doctor
                                                                }
                                                                dispatch(assignDoctor({ data }))
                                                                setShowActionsDropdown(!showActionsDropdown)
                                                                setModals({ ...modals, showGenerateInvoiceModal: !modals.showGenerateInvoiceModal })
                                                            }}
                                                            className='hover:bg-green-200 hover:text-gray-600 rounded p-2'
                                                        >
                                                            Generate Invoice
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <Pagination
                        data={data}
                        currentData={currentData}
                        setCurrentData={setCurrentData}
                    />
                </div>
            </div>
        </div>
    )
}

export default RecordsPatientsTable