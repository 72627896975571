import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import {
    getMyProfile, getCurrentUserLabInvoices, getCurrentUserRadiologyInvoices, getCurrentUserPharmacyInvoices,
    getCurrentUserRecordsInvoices, payForGeneratedInvoice, verifyPayment, flutterwavePayment, changeInvoiceStatus, verifyFlutterWavePayment,
    getCurrentUserAppointment, getCurrentUserAssignment, fetchDashboardInfo
} from '../routes/patient.routes'


export const getDashboardInfo = createAsyncThunk(
    'patient/getDashboardInfo',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await fetchDashboardInfo(token)

            return data
        } catch (error) {
            console.log(error.response);
            return rejectWithValue(error.response.data)
        }
    }
)


export const getCurrentPatientProfile = createAsyncThunk(
    'patient/getCurrentPatientProfile',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getMyProfile(token)

            return data
        } catch (error) {
            console.log(error.response);
            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllCurrentUserLabInvoices = createAsyncThunk(
    'patient/getCurrentUserLabInvoices',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getCurrentUserLabInvoices(token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllCurrentUserRadiologyInvoices = createAsyncThunk(
    'patient/getAllCurrentUserRadiologyInvoices',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getCurrentUserRadiologyInvoices(token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllCurrentUserPharmacyInvoices = createAsyncThunk(
    'patient/getCurrentUserPharmacyInvoices',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getCurrentUserPharmacyInvoices(token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const getAllCurrentUserRecordsInvoices = createAsyncThunk(
    'patient/getCurrentUserRecordsInvoices',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getCurrentUserRecordsInvoices(token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const payForAGeneratedInvoice = createAsyncThunk(
    'patient/payAForGeneratedInvoice',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await payForGeneratedInvoice(formData, token)
            const authorization_url = data['response']['data'].authorization_url
            window.location.href = authorization_url
            console.log(data)

            // toast.success('Payment Successful.')
            setModals({ ...modals, showPatientPayForInvoicesModal: !modals.showPatientPayForInvoicesModal })

            return data
        } catch (error) {
            console.log(error.response)
            toast.warning('An error occured while making payment.')
            return rejectWithValue(null)
        }
    }
)


export const verifyPaymentInvoice = createAsyncThunk(
    'patient/verifyPaymentInvoice',
    async ({ formData, toast, triggerInvoiceStatus, setTriggerInvoiceStatus }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await verifyPayment(formData, token)
            localStorage.setItem("mode_of_payment", JSON.stringify(data.response['data'].channel))
            toast.success('Payment verified successfully.')
            setTriggerInvoiceStatus(!triggerInvoiceStatus)

            return data.response.data
        } catch (error) {

            toast.warning('An error occured while verifying payment.')
            return rejectWithValue(null)
        }
    }
)

export const flutterwavePatientPayment = createAsyncThunk(
    'patient/flutterwavePayment',
    async ({ formData, toast, setModals, modals }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await flutterwavePayment(formData, token)
            window.location.href = data

            setModals({ ...modals, showPatientPayForInvoicesModal: !modals.showPatientPayForInvoicesModal })


            return data.response.data
        } catch (error) {

            toast.warning('An error occured while making payment.')
            return rejectWithValue(null)
        }
    }
)



export const changeAnInvoiceStatus = createAsyncThunk(
    'patient/changeInvoiceStatus',
    async ({ formData, type, id }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access
            formData['mode_of_payment'] = JSON.parse(localStorage.getItem("mode_of_payment"))
            const { data } = await changeInvoiceStatus(formData, type, id, token)
            
            return data
        } catch (error) {
            console.log(error.response)

            return rejectWithValue(null)
        }
    }
)


export const verifyPatientFlutterWavePayment = createAsyncThunk(
    'patient/verifyPatientFlutterWavePayment',
    async ({ transaction_id, toast, triggerInvoiceStatus, setTriggerInvoiceStatus }, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await verifyFlutterWavePayment(transaction_id, token)
            const mode_of_payment = localStorage.setItem("mode_of_payment", JSON.stringify(data['data'].payment_type))
            
            if (data.status == "success") {
                toast.success("Payment verified")
                setTriggerInvoiceStatus(true)
            } else {
                toast.warning("Payment verification failed")
                window.location.href = 'http://localhost:3000/dashboard'
            }
            console.log(data);
            return data
        } catch (error) {
            console.log(error.response);
            return rejectWithValue(error.response.data)
        }
    }
)


export const getCurrentUserAllAppointment = createAsyncThunk(
    'patient/getCurrentUserAppointment',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getCurrentUserAppointment(token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)

export const getCurrentUserAllAssignment = createAsyncThunk(
    'patient/getCurrentUserAssignment',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const token = state.auth.user.access

            const { data } = await getCurrentUserAssignment(token)

            return data
        } catch (error) {

            return rejectWithValue(error.response.data)
        }
    }
)
