import { API } from '../config'

let USERFROMLS = localStorage.getItem('his__user') ? JSON.parse(localStorage.getItem('his__user')) : null

export const InitializeAuth = async (token) => {
    try {
        const access__token = JSON.parse(atob(token.access.split(".")[1]));
        const refresh__token = JSON.parse(atob(token.refresh.split(".")[1]));

        if (((access__token.exp * 1000) < Date.now()) && ((refresh__token.exp * 1000) < Date.now())) {
            localStorage.removeItem('his__user')
            localStorage.removeItem('his__user__profile')

            return window.location.reload()
        }

        if (((refresh__token.exp * 1000) < Date.now())) {
            localStorage.removeItem('his__user')
            localStorage.removeItem('his__user__profile')

            return window.location.reload()
        }

        if (((access__token.exp * 1000) < Date.now())) {
            const { data } = await API.post('/auth/jwt/refresh/', { refresh: token.refresh })

            USERFROMLS = { ...USERFROMLS, access: data.access }

            localStorage.setItem('his__user', JSON.stringify({ ...USERFROMLS }))

            return window.location.reload()
        }

    } catch (e) {
        return null;
    }
}