import { Fragment } from 'react'
import AccountsRemitDiscountsModal from '../../modals/accounts/AccountsRemitDiscountsModal'
import AccountsViewAllReportsModal from '../../modals/accounts/AccountsViewAllReportsModal'
import AccountsViewDiscountInvoiceModal from '../../modals/accounts/AccountsViewDiscountInvoiceModal'

import AccountsViewInvoiceModal from '../../modals/accounts/AccountsViewInvoiceModal'
import AccountsViewLaboratoryReportsModal from '../../modals/accounts/AccountsViewLaboratoryReportsModal'
import AccountsViewPharmacyInvoiceModal from '../../modals/accounts/AccountsViewPharmacyInvoiceModal'
import AccountsViewPharmacyReportsModal from '../../modals/accounts/AccountsViewPharmacyReportsModal'
import AccountsViewRadiologyReportsModal from '../../modals/accounts/AccountsViewRadiologyReportsModal'
import AccountsViewRecordsReportsModal from '../../modals/accounts/AccountsViewRecordsReportsModal'


const AccountsFormModals = (props) => {
    return (
        <Fragment>
            {props.type === 'accounts__view__labinvoice' && (
                <AccountsViewInvoiceModal />
            )}

            {props.type === 'accounts__view__pharmacyinvoice' && (
                <AccountsViewPharmacyInvoiceModal />
            )}

            {props.type === 'accounts__view__labreports' && (
                <AccountsViewLaboratoryReportsModal />
            )}

            {props.type === 'accounts__view__allreports__modal' && (
                <AccountsViewAllReportsModal />
            )}

            {props.type === 'accounts__view__pharmarcyreports' && (
                <AccountsViewPharmacyReportsModal />
            )}

            {props.type === 'accounts__view__radiologyreports' && (
                <AccountsViewRadiologyReportsModal />
            )}

            {props.type === 'accounts__view__recordsreports' && (
                <AccountsViewRecordsReportsModal />
            )}

            {props.type === 'accounts__view__discountinvoice__modal' && (
                <AccountsViewDiscountInvoiceModal />
            )}

            {props.type === 'remit__multiple__discounts__modal' && (
                <AccountsRemitDiscountsModal />
            )}

        </Fragment>
    )
}

export default AccountsFormModals
