import { Fragment } from 'react'
import Lottie from 'lottie-react'

import loadingJSON from '../../assets/animations/chat__1.json'
import loadingJSON1 from '../../assets/animations/loading__3.json'
import loadingJSON2 from '../../assets/animations/chat__2.json'


const ChatAnimation = ({ type }) => {
    const options = {
        // renderer: 'svg',
        loop: true,
        // autoplay: true,
        animationData: loadingJSON,
        // rendererSettings: {
        //     preserveAspectRatio: 'xMidYMid slice',
        // },
    }
   
    const options1 = {
        // renderer: 'svg',
        loop: true,
        // autoplay: true,
        animationData: loadingJSON1,
        // rendererSettings: {
        //     preserveAspectRatio: 'xMidYMid slice',
        // },
    }

    const options2 = {
        // renderer: 'svg',
        loop: true,
        // autoplay: true,
        animationData: loadingJSON2,
        // rendererSettings: {
        //     preserveAspectRatio: 'xMidYMid slice',
        // },
    }
    return (
        <Fragment>
            {type === 0 && (
                <Lottie options={options} width={500} height={500} />
            )}
            
            {type === 1 && (
                <Lottie options={options1} width={600} height={600} />
            )}

            {type === 2 && (
                <Lottie options={options2} width={250} height={250} />
            )}
        </Fragment>
    )
}

export default ChatAnimation