/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useStateContext } from '../../state/context'
import { getPrescriptions, getPrescriptionsInvoices, getSupplyPoints } from '../../state/actions/pharmacist.actions'
import { NDHPharmacistSupplyPointTable, PageTitle, PharmacistDrugBinCardTable, PharmacistSupplyPointInvoicesTable, PharmacistSupplyPointPrescriptionsTable, PharmacistSupplyPointsTable, PharmacistSupplyPointTable } from '../../components'


const PharmacistSupplyPoint = () => {
    const dispatch = useDispatch()

    const { appState, setAppState } = useStateContext()

    const { supplyPoints, prescriptions, prescriptions_invoices, prescriptions_invoice, patient_drugdispensed } = useSelector(state => state.pharmacist)
    const [showList, setShowList] = useState(true)
    const [showPrescriptions, setShowPrescriptions] = useState(true)
    const [showInvoices, setShowInvoices] = useState(true)
    const [activeLink, setActiveLink] = useState('Catalog')

    useEffect(() => {
        dispatch(getSupplyPoints())
        dispatch(getPrescriptions())
        dispatch(getPrescriptionsInvoices())

        return () => {
            setActiveLink('Catalog')
            setAppState({ ...appState, showManageBinCard: false })
        }
    }, [])

    useEffect(() => {
        dispatch(getPrescriptions())
        dispatch(getPrescriptionsInvoices())
    }, [prescriptions_invoice, patient_drugdispensed])

    return (
        <div>
            <PageTitle type={'pharmacist-supply-point'} />

            <div className="space-y-5">
                <Fragment>
                    {!appState.showManageBinCard && (
                        <div className="">
                            <div className="flex space-x-5 items-center border-b-2 border-gray-500 pb-2 w-1/2">
                                <button
                                    type="button"
                                    className={`${activeLink === 'Catalog' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                                    onClick={(e) => setActiveLink(e.target.textContent)}
                                >
                                    Catalog
                                </button>
                                <button
                                    type="button"
                                    className={`${activeLink === 'Prescriptions' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                                    onClick={(e) => setActiveLink(e.target.textContent)}
                                >
                                    Prescriptions
                                </button>
                                <button
                                    type="button"
                                    className={`${activeLink === 'Invoices' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
                                    onClick={(e) => setActiveLink(e.target.textContent)}
                                >
                                    Invoices
                                </button>
                            </div>

                            {activeLink === 'Catalog' && (
                                <NDHPharmacistSupplyPointTable
                                    data={[]}
                                    title={'Catalog'}
                                    showList={showList}
                                    setShowList={setShowList}
                                />
                            )}

                            {activeLink === 'Prescriptions' && (
                                <PharmacistSupplyPointPrescriptionsTable
                                    data={prescriptions}
                                    title={'Prescriptions'}
                                    showList={showPrescriptions}
                                    setShowList={setShowPrescriptions}
                                />
                            )}

                            {activeLink === 'Invoices' && (
                                <PharmacistSupplyPointInvoicesTable
                                    data={prescriptions_invoices}
                                    title={'Invoices'}
                                    showList={showInvoices}
                                    setShowList={setShowInvoices}
                                />
                            )}
                        </div>
                    )}
                    {appState.showManageBinCard && (
                        <PharmacistDrugBinCardTable
                            data={[]}
                            title={'Paracetamol Bin Card'}
                            showList={showList}
                            setShowList={setShowList}
                        />
                    )}
                </Fragment>
            </div>
        </div>
    )
}

export default PharmacistSupplyPoint