/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'
import { FaBed, FaPencilAlt, FaTrash } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

import { API } from '../../state/config'
import { useStateContext } from '../../state/context'
import { setBedSpace } from '../../state/actions/actions'
import Pagination from '../utils/Pagination'


const BedSpaceTable = ({ ward, title }) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()
    const { user } = useSelector(state => state.auth)
    const { addedBedSpace, updatedBedSpace, deletedBedSpace } = useSelector(state => state.admin)

    const [bedSpaces, setBedSpaces] = useState([])
    const [showList, setShowList] = useState(false)
    const [currentData, setCurrentData] = useState([])

    useEffect(() => {
        const fetchBeds = async () => {
            const { data } = await API.get(`/administrator/bed_space/${ward}/`, { headers: { Authorization: `Bearer ${user?.access}` } })
            return setBedSpaces([...data])
        }
        fetchBeds()
    }, [ward, addedBedSpace, updatedBedSpace, deletedBedSpace])



    return (
        <div className="flex justify-between items-center w-full px-1">
            <div
                className="flex flex-col w-full cursor-pointer"
            >
                <div className="py-2">
                    <div
                        className="flex justify-start items-center space-x-4 bg-gray-200 p-1"
                        onClick={() => setShowList(!showList)}
                    >
                        <h4 className="text-dark-blue text-[14px] font-bold">{ward}</h4>
                        <FaBed className="text-dark-blue" size={20} />
                    </div>
                    {showList && (
                        <Fragment>
                            <table className="border rounded w-full cursor-default">
                                <thead className="">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left"
                                        >
                                            S/N
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Ward Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Bed Number
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 pr-[5em] py-4 text-left"
                                        >
                                            Allocated
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-sm font-medium text-gray-900 py-4 text-left"
                                        >
                                            
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData?.map((bedspace, index) => (
                                        <tr className="" key={`${bedspace.id}`}>
                                            <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {index + 1}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                {bedspace.ward.name}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                {bedspace.bed_number}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light py-2 whitespace-nowrap">
                                                {bedspace.allocated ? 'Occupied' : 'Available'}
                                            </td>
                                            <td className=" flex text-2xl space-x-4 text-gray-900 font-semibold py-2 whitespace-nowrap">
                                                <FaPencilAlt
                                                    size={20}
                                                    className='text-green-600 cursor-pointer'
                                                    onClick={() => {
                                                        dispatch(setBedSpace({ data: bedspace }))
                                                        setModals({ ...modals, showUpdateBedSpaceModal: !modals.showUpdateBedSpaceModal })
                                                    }}
                                                />
                                                <FaTrash
                                                    onClick={() => {
                                                        dispatch(setBedSpace({ data: bedspace }))
                                                        setModals({ ...modals, showDeleteBedSpaceModal: !modals.showDeleteBedSpaceModal })
                                                    }}
                                                    className="text-orange-400 cursor-pointer"
                                                    size={20}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <Pagination
                                data={bedSpaces}
                                currentData={currentData}
                                setCurrentData={setCurrentData}
                            />
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    )
}

export default BedSpaceTable