/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { InvoiceTable, PageTitle } from "../../components"
import { getAllBillings } from "../../state/actions/admin.actions"


const AdminInvoices = (props) => {
	const dispatch = useDispatch()

	const { billings } = useSelector((state) => state.admin)

	const [activeLink, setActiveLink] = useState('Billings')
	const [showBillings, setShowBillings] = useState(true)
	const [showActiveBillings, setShowActiveBillings] = useState(false)
	const [showInactiveBillings, setShowInactiveBillings] = useState(false)
	const [filterState, setFilterState] = useState({ search: '', billings: [], active_billings: [], inactive_billings: [] })


	const handleBillingsFilter = () => {
		const filtered = billings
			?.filter(bill => {
				return bill.name.toLowerCase().includes(filterState.search.toLowerCase())
					|| bill.price == filterState.search
			})

		setFilterState({ ...filterState, billings: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, billings: billings })
		}
	}


	const handleActiveBillingsFilter = () => {
		const filtered = billings
			?.filter(bill => {
				return bill.name.toLowerCase().includes(filterState.search.toLowerCase())
					|| bill.price == filterState.search
			})

		setFilterState({ ...filterState, active_billings: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, active_billings: billings?.filter(bill => bill.status === true) })
		}
	}

	const handleInactiveBillingsFilter = () => {
		const filtered = billings
			?.filter(bill => {
				return bill.name.toLowerCase().includes(filterState.search.toLowerCase())
					|| bill.price == filterState.search
			})

		setFilterState({ ...filterState, inactive_billings: filtered })

		if (filterState.search === '') {
			setFilterState({ ...filterState, inactive_billings: billings?.filter(bill => bill.status === false) })
		}
	}

	useEffect(()=>{
		dispatch(getAllBillings());
	}, [])

	useEffect(() => {
		setFilterState({
			...filterState,
			billings: billings,
			active_billings: billings?.filter(bill => bill.status === true),
			inactive_billings: billings?.filter(bill => bill.status === false),
		})
	}, [billings])

	useEffect(() => {
		if (activeLink === 'Billings') {
			handleBillingsFilter()
		}
		if (activeLink === 'Active') {
			handleActiveBillingsFilter()
		}
		if (activeLink === 'In Active') {
			handleInactiveBillingsFilter()
		}
	}, [filterState.search])

	return (
		<div>
			<PageTitle
				type={"admin-invoices"}
				showAddBillingModal={props.showAddBillingModal}
				setShowAddBillingModal={props.setShowAddBillingModal}
			/>

			<div className="space-y-">
				<div className="flex space-x-5 justify-between items-center border-b-2 border-gray-500 pb-2 w-full">
					<div className="space-x-5">
						<button
							type="button"
							className={`${activeLink === 'Billings' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => setActiveLink(e.target.textContent)}
						>
							Billings
						</button>

						<button
							type="button"
							className={`${activeLink === 'Active' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => setActiveLink(e.target.textContent)}
						>
							Active
						</button>

						<button
							type="button"
							className={`${activeLink === 'In Active' ? 'bg-slate-600 text-white' : 'text-slate-500 border border-slate-400'} inline-block px-6 py-2 font-medium text-xs leading-tight uppercase shadow-md hover:bg-slate-400 hover:text-white hover:shadow-lg hover:translate-x-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease`}
							onClick={(e) => setActiveLink(e.target.textContent)}
						>
							In Active
						</button>
					</div>

					<div>
						<input
							type="text"
							name="search"
							// disabled
							placeholder='Search'
							className="border border-gray-400 w-[220px] px-4 mt-2 text-[12px] font-medium text-gray-600 placeholder:text-[12px] hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue"
							onChange={(e) => setFilterState({ ...filterState, [e.target.name]: e.target.value })}
						/>
					</div>
				</div>

				{activeLink === 'Billings' && (
					<InvoiceTable
						data={filterState.billings}
						title={'Billings'}
						showList={showBillings}
						setShowList={setShowBillings}
					/>
				)}

				{activeLink === 'Active' && (
					<InvoiceTable
						data={filterState.active_billings}
						title={'Active Billings'}
						showList={showActiveBillings}
						setShowList={setShowActiveBillings}
					/>
				)}

				{activeLink === 'In Active' && (
					<InvoiceTable
						data={filterState.inactive_billings}
						title={'Inactive Billings'}
						showList={showInactiveBillings}
						setShowList={setShowInactiveBillings}
					/>
				)}
			</div>
		</div>
	)
}

export default AdminInvoices
