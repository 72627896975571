import React, { useEffect, useState } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useStateContext } from '../../../state/context'
import { useDispatch, useSelector } from 'react-redux'
import { getAllWards } from '../../../state/actions/nurse.actions'
import { getAllWardBedspaces, admitAPatient } from '../../../state/actions/nurse.actions'
import { setInpatientDetails } from '../../../state/actions/actions'
import { toast } from 'react-toastify'

const NurseAdmitPatientModal = () => {

    const dispatch = useDispatch()
    const { modals, setModals } = useStateContext()

    const [formData, setFormData] = useState({
        ward: '',
        bed_space: '',
        patient_id: '',
        inpatient_id: '',
    })

    const { all_wards, all_ward_bedspaces } = useSelector((state) => state.nurse)
    const { set_inpatient_details } = useSelector((state) => state.root)

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }
    useEffect(() => {
        dispatch(getAllWards())
    }, [])
    
    useEffect(() => {
        setFormData({ ...formData, patient_id: set_inpatient_details?.patient_id, inpatient_id: set_inpatient_details?.inpatient_id })

    }, [set_inpatient_details])
    const handleSubmit = (e) => {
        e.preventDefault()
        // console.log(formData);
        dispatch(admitAPatient({ formData, toast, setModals, modals }))

    }

    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>

                            <>
                                <h4 className='text-dark-green text-[16px] font-semibold'>
                                    Admit patient to ward - [{set_inpatient_details?.patient_number}]
                                </h4>
                                <FaTimesCircle
                                    className='text-dark-yellow cursor-pointer'
                                    onClick={() => {
                                        setModals({
                                            ...modals,
                                            showAdmitPatientModal:
                                                !modals.showAdmitPatientModal,
                                        })
                                        dispatch(setInpatientDetails({ data: null }))
                                    }

                                    }
                                    size={20}
                                />
                            </>


                        </div>




                        <form onSubmit={handleSubmit}>

                            {/* <h4 className='text-sm font-semibold py-2'>Investigation category</h4> */}
                            <div className='flex justify-between-items-center space-x-8'>
                                <div className='space-y-2  w-full'>




                                    <input
                                        type="title"
                                        name="temperature"
                                        value={`${set_inpatient_details?.first_name} ${set_inpatient_details?.last_name}`}
                                        disabled

                                        className="border rounded w-full h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                        onChange={(e) => handleChange(e, e.target.name)}
                                    />


                                    <select name="ward" placeholder='Type' id="" className='border rounded mt-2 pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 '
                                        onChange={(e) =>{
                                            handleChange(e, e.target.name)
                                            dispatch(getAllWardBedspaces({ ward_id: e.target.value }))
                                        }}
                                        onClick={(e) => {
                                            const ward_id = e.target.value
                                            // dispatch(getAllWardBedspaces({ ward_id }))


                                        }}

                                    >
                                        <option value="">Select Ward</option>
                                        {all_wards?.map((ward, index) => (
                                            <option value={ward.pkid} key={index}>{ward.name}</option>
                                        ))}


                                    </select>
                                    <select name="bed_space" placeholder='Type' id="" className='border rounded mt-2 pl-2 w-full h-[40px] focus:outline-none focus:ring-blue-500 '
                                        onChange={(e) =>
                                            handleChange(e, e.target.name)
                                        }
                                    >
                                        <option value="">Choose bed Number</option>
                                        {all_ward_bedspaces?.map((bed, index) => (
                                            <option value={bed.pkid} key={index}>{bed.bed_number}</option>
                                        ))}


                                    </select>




                                </div>


                            </div>

                            <div className='mt-4 flex justify-center items-center'>
                                <button
                                    type='submit'
                                    className='inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
                                // onClick={handleSubmit}
                                >
                                    Admit
                                </button>
                            </div>

                        </form>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default NurseAdmitPatientModal