/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import Lottie from 'lottie-react'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import loadingJSON from '../../assets/animations/loading__2.json'
import { activateAccount } from '../../state/actions/auth.actions'
import { getHospitalConfig } from '../../state/actions/admin.actions'
import { setCurrentPatienFile } from '../../state/actions/actions'


const ActivateAccount = () => {
    const { token } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const options = {
        loop: true,
        animationData: loadingJSON,
    }

    const { hospital_config } = useSelector(state => state.admin)

    useEffect(() => {
        dispatch(getHospitalConfig())

        const ref = window.location.href.split('reference=')[1]
        setCurrentPatienFile(ref)
    }, [])

    useEffect(() => {
        document.title = hospital_config?.name
        let link = document.querySelector("link[rel~='icon']");

        link.href = `${process.env.REACT_APP_API_ROOT}${hospital_config?.logo}`;
    }, [hospital_config])

    useEffect(() => {
        setTimeout(() => {
            dispatch(activateAccount({ token, navigate, toast }))
        }, 5000)
    }, [token])

    return (
        <div className='h-screen flex justify-center items-center'>
            <Lottie options={options} width={600} height={600} />
        </div>
    )
}

export default ActivateAccount