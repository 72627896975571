/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import { useStateContext } from '../../../state/context'
import { setSupplyPoint } from '../../../state/actions/actions'
import { deleteSupplyPoint } from '../../../state/actions/pharmacist.actions'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { createOtherPatientLabTest, createPatientLabTest, getLabInvestigationCategories, getLabInvestigationSamples, getLabInvestigationTypes } from '../../../state/actions/doctor.actions'


const DoctorAddTestModal = () => {
    const dispatch = useDispatch();

    const { modals, setModals } = useStateContext()

    const { patient_update } = useSelector(state => state.root)
    const { new_diagnosis } = useSelector(state => state.doctor)

    const { lab_investigation_types, lab_investigation_categories, lab_investigation_samples } = useSelector(state => state.doctor)
    const [formData, setFormData] = useState({ sample_type: 0, category: 0, investigation_sample: '', patient: patient_update?.user, diagnosis: new_diagnosis?.pkid, is_local: true })
    const [configs, setConfigs] = useState({ investigation_types: [], investigation_samples: [], })

    useEffect(() => {
        dispatch(getLabInvestigationTypes())
        dispatch(getLabInvestigationCategories())
        dispatch(getLabInvestigationSamples())
    }, [])

    useEffect(() => {
        if (parseInt(formData.category) >= 1) {
            const cats = lab_investigation_types?.filter(cat => cat.investigation_category === parseInt(formData.category))

            setConfigs({
                ...configs,
                investigation_types: cats,
            })

        }
    }, [formData.category])

    useEffect(() => {
        const samples = lab_investigation_samples?.filter(samp => samp.investigation_type === parseInt(formData.sample_type))

        setConfigs({
            ...configs,
            investigation_samples: samples,
        })
    }, [formData.sample_type])

    const handleChange = (e, name) => {
        setFormData({ ...formData, [name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        dispatch(createPatientLabTest({ formData, toast, setModals, modals }))
    }

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div className="bg-white w-[500px] rounded px-[30px] py-[20px]">
                <div className="flex flex-col space-y-5">
                    <div className="flex justify-between items-center">
                        <h4 className={`${modals.showDoctorAddTestModal ? 'text-dark-green' : 'text-sky-500'} text-[20px] font-bold`}>
                            {modals.showDoctorAddTestModal ? 'Add Test' : 'Add Other Test'}
                        </h4>
                        <FaTimesCircle
                            className='text-dark-yellow cursor-pointer'
                            onClick={() => {
                                modals.showDoctorAddTestModal && setModals({ ...modals, showDoctorAddTestModal: !modals.showDoctorAddTestModal })
                            }}
                            size={20}
                        />
                    </div>


                    <form onSubmit={handleSubmit}>
                        <div className="space-y-2">
                            <div className="relative">
                                <select
                                    className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none "
                                    name="category"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                >
                                    <option>Category</option>
                                    {lab_investigation_categories?.map((cat) => (
                                        <option
                                            key={`${cat.id}`}
                                            value={cat.pkid}
                                        >
                                            {cat.title}
                                        </option>
                                    ))}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                        className="fill-current h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                </div>
                            </div>

                            <div className="relative">
                                <select
                                    className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none "
                                    name="sample_type"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                >
                                    <option>Test Type</option>
                                    {configs.investigation_types?.map((type) => (
                                        <option
                                            key={`${type.id}`}
                                            value={`${type.pkid}`}
                                        >
                                            {type.type_title}
                                        </option>
                                    ))}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                        className="fill-current h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                </div>
                            </div>

                            <div className="relative">
                                <select
                                    className="appearance-none w-full bg-gray-300 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none "
                                    name="investigation_sample"
                                    onChange={(e) => handleChange(e, e.target.name)}
                                >
                                    <option>Sample</option>
                                    {configs?.investigation_samples?.map((sample) => (
                                        <option
                                            key={`${sample.id}`}
                                            value={sample.pkid}
                                        >
                                            {sample.sample_type}
                                        </option>
                                    ))}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                        className="fill-current h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                </div>
                            </div>

                        </div>

                        <div className='mt-4'>
                            <button
                                type='submit'
                                className="inline-block w-[175px] px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease"
                            >Submit</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    )
}
export default DoctorAddTestModal
