import React, { Fragment } from "react"

import FormModal from "./FormModal"
import { useStateContext } from '../../state/context'

const Modals = ({ activeLink, setActiveLink }) => {
    const { modals } = useStateContext()
    return (
        <Fragment>
            {modals.showAddDirectorateModal && (
                <FormModal
                    type={'add__directorate__modal'}
                />
            )}

            {modals.showUpdateDirectorateModal && (
                <FormModal
                    type={'update__directorate__modal'}
                />
            )}

            {modals.showDeleteDirectorateModal && (
                <FormModal
                    type={'delete__directorate__modal'}
                />
            )}

            {modals.showAddDepartmentModal && (
                <FormModal
                    type={'add__department__modal'}
                />
            )}

            {modals.showUpdateDepartmentModal && (
                <FormModal
                    type={'update__department__modal'}
                />
            )}

            {modals.showDeleteDepartmentModal && (
                <FormModal
                    type={'delete__department__modal'}
                />
            )}

            {modals.showAddEmployeeModal && (
                <FormModal
                    type={'add__employee__modal'}
                />
            )}

            {modals.showUpdateEmployeeModal && (
                <FormModal
                    type={'update__employee__modal'}
                />
            )}


            {modals.showDeleteEmployeeModal && (
                <FormModal
                    type={'delete__employee__modal'}
                />
            )}

            {modals.showAddBillingModal && (
                <FormModal
                    type={'add__billing__modal'}
                />
            )}

            {modals.showUpdateBillingModal && (
                <FormModal
                    type={'update__billing__modal'}
                />
            )}

            {modals.showDeleteBillingModal && (
                <FormModal
                    type={'delete__billing__modal'}
                />
            )}

            {modals.showCreateWardModal && (
                <FormModal
                    type={'add__ward__modal'}
                />
            )}

            {modals.showUpdateWardModal && (
                <FormModal
                    type={'update__ward__modal'}
                />
            )}

            {modals.showDeleteWardModal && (
                <FormModal
                    type={'delete__ward__modal'}
                />
            )}

            {modals.showAddBedSpaceModal && (
                <FormModal
                    type={'add__bedspace__modal'}
                />
            )}

            {modals.showUpdateBedSpaceModal && (
                <FormModal
                    type={'update__bedspace__modal'}
                />
            )}

            {modals.showDeleteBedSpaceModal && (
                <FormModal
                    type={'delete__bedspace__modal'}
                />
            )}

            {modals.showSendSmsModal && (
                <FormModal
                    type={'send__sms__modal'}
                />
            )}

            {modals.showSendMailModal && (
                <FormModal
                    type={'send__mail__modal'}
                />
            )}

            {modals.showAdminUpdateHospitalInfoModal && (
                <FormModal
                    type={'update__hospital__info__modal'}
                />
            )}

            {modals.showAdminUpdateHospitalLogoModal && (
                <FormModal
                    type={'update__hospital__logo__modal'}
                />
            )}

            {modals.showAdminAddGatewaySettingModal && (
                <FormModal
                    type={'add__gateway__setting__modal'}
                />
            )}

            {modals.showAdminUpdateGatewaySettingModal && (
                <FormModal
                    type={'update__gateway__setting__modal'}
                />
            )}

            {modals.showTopUpSMSUnitsModal && (
                <FormModal
                    type={"topup__sms__units__modal"}
                />
            )}

            {modals.showRenewSubscriptionPlanModal && (
                <FormModal
                    type={"renew__subscriptionplan"}
                />
            )}

            {modals.showUpgradeSubscriptionPlanModal && (
                <FormModal
                    type={"upgrade__subscriptionplan"}
                />
            )}

            {modals.showPayMonthlyRemittanceModal && (
                <FormModal
                    type={"pay__monthly__remittance__modal"}
                />
            )}


            {modals.showAccountsRemitDiscountsModal && (
                <FormModal
                    type={"remit__multiple__discounts__modal"}
                />
            )}


            {modals.showRecordsAddPatientModal && (
                <FormModal
                    type={"records_add_patient"}
                />
            )}

            {modals.showAssignDoctorModal && (
                <FormModal
                    type={"records__assign__doctor"}
                />
            )}
            {modals.showChangeDoctorModal && (
                <FormModal
                    type={"records__change__doctor"}
                />
            )}

            {modals.showBookAppointmentModal && (
                <FormModal
                    type={"records__book__appointment"}
                />
            )}

            {modals.showGenerateCardModal && (
                <FormModal
                    type={"records__generate__card"}
                />
            )}

            {modals.showGenerateInvoiceModal && (
                <FormModal
                    type={"records__generate__invoice"}
                />
            )}

            {modals.showViewInvoiceModal && (
                <FormModal
                    type={"records__view__invoice"}
                />
            )}

            {modals.showAddBirthRecordModal && (
                <FormModal
                    type={"records__add__birthrecord"}
                />
            )}

            {modals.showAddDeathRecordModal && (
                <FormModal
                    type={"records__add__deathrecord"}
                />
            )}

            {modals.showBirthRecordModal && (
                <FormModal
                    type={"records__view__birthrecord"}
                />
            )}

            {modals.showDeathRecordModal && (
                <FormModal
                    type={"records__view__deathrecord"}
                />
            )}

            {modals.showWardInfoModal && (
                <FormModal
                    type={"records__view__ward"}
                />
            )}
            {modals.showViewWardReportModal && (
                <FormModal
                    type={"records__view__ward_report"}
                />
            )}

            {modals.showPharmacistAddDrugCategoryModal && (
                <FormModal
                    type={"pharmacist__add__drugcategory"}
                />
            )}

            {modals.showPharmacistUpdateDrugCategoryModal && (
                <FormModal
                    type={"pharmacist__update__drugcategory"}
                />
            )}

            {modals.showPharmacistUpdateDeleteCategoryModal && (
                <FormModal
                    type={"pharmacist__delete__drugcategory"}
                />
            )}

            {modals.showPharmacistAddDrugModal && (
                <FormModal
                    type={"pharmacist__add__drug"}
                />
            )}

            {modals.showPharmacistUpdateDrugModal && (
                <FormModal
                    type={"pharmacist__update__drug"}
                />
            )}

            {modals.showPharmacistViewDrugModal && (
                <FormModal
                    type={"pharmacist__view__drug"}
                />
            )}

            {modals.showPharmacistAssignSupplyPointModal && (
                <FormModal
                    type={"pharmacist__assign__supplypoint__to__user"}
                />
            )}

            {modals.showPharmacistSupplyPointAssignStaffModal && (
                <FormModal
                    type={"pharmacist__supplypoint__assignstaff"}
                />
            )}

            {modals.showPharmacistAddSupplyPointModal && (
                <FormModal
                    type={"pharmacist__add__supplypoint"}
                />
            )}

            {modals.showPharmacistUpdateSupplyPointModal && (
                <FormModal
                    type={"pharmacist__update__supplypoint"}
                />
            )}

            {modals.showPharmacistDeleteSupplyPointModal && (
                <FormModal
                    type={"pharmacist__delete__supplypoint"}
                />
            )}

            {modals.showPharmacistSupplyPointStaffModal && (
                <FormModal
                    type={"pharmacist__view__supplypoint__staff"}
                />
            )}

            {modals.showPharmacistDeleteDrugModal && (
                <FormModal
                    type={"pharmacist__delete__drug"}
                />
            )}

            {modals.showPharmacistBinCardModal && (
                <FormModal
                    type={"pharmacist__drug__bincard"}
                />
            )}

            {modals.showPharmacistBinCardSummaryModal && (
                <FormModal
                    type={"pharmacist__drug__bincard__summary"}
                />
            )}

            {modals.showPharmacistIssueOutDrugModal && (
                <FormModal
                    type={"pharmacist__issueout__drug"}
                />
            )}

            {modals.showPharmacistLendRefundDrugModal && (
                <FormModal
                    type={"pharmacist__lend__refund__drug"}
                />
            )}

            {modals.showPharmacistDrugSupplyPointModal && (
                <FormModal
                    type={"pharmacist__drug__supplypoint"}
                />
            )}

            {modals.showPharmacistDrugInvoiceModal && (
                <FormModal
                    type={"pharmacist__generate__prescription__invoice"}
                />
            )}

            {modals.showPharmacistDispenseDrugModal && (
                <FormModal
                    type={"pharmacist__dispense__drug"}
                />
            )}

            {modals.showPharmacistViewPrescriptionModal && (
                <FormModal
                    type={"pharmacist__view__prescription"}
                />
            )}

            {modals.showPharmacistViewInvoiceModal && (
                <FormModal
                    type={"pharmacist__view__invoice"}
                />
            )}

            {modals.showPharmacistDispenseMultipleInvoiceModal && (
                <FormModal
                    type={"pharmacist__dispense__multipleinvoice__modal"}
                />
            )}

            {modals.showPharmacistGenerateMultipleInvoiceModal && (
                <FormModal
                    type={"pharmacist__generate__multipleinvoice__modal"}
                />
            )}

            {modals.showDoctorCreateTeamModal && (
                <FormModal
                    type={"doctor__create__team"}
                />
            )}

            {modals.showDoctorUpdateTeamModal && (
                <FormModal
                    type={"doctor__update__team"}
                />
            )}

            {modals.showDoctorViewTeamModal && (
                <FormModal
                    type={"doctor__view__team"}
                />
            )}

            {modals.showDoctorDeleteTeamModal && (
                <FormModal
                    type={"doctor__delete__team"}
                />
            )}

            {modals.showDoctorUploadPatientFileModal && (
                <FormModal
                    type={"doctor__upload__patientfile"}
                />
            )}

            {modals.showDoctorViewPatientFileModal && (
                <FormModal
                    type={"doctor__view__patientfile"}
                />
            )}

            {modals.showDoctorTransferPatientModal && (
                <FormModal
                    type={"doctor__transfer__patient"}
                />
            )}

            {modals.showDoctorAddPrescriptionModal && (
                <FormModal
                    type={"doctor__add__prescription"}
                />
            )}

            {modals.showDoctorAddOtherPrescriptionModal && (
                <FormModal
                    type={"doctor__add__otherprescription"}
                />
            )}

            {modals.showDoctorAddTestModal && (
                <FormModal
                    type={"doctor__add__test"}
                />
            )}

            {modals.showDoctorAddOtherTestModal && (
                <FormModal
                    type={"doctor__add__othertest"}
                />
            )}

            {modals.showDoctorViewLabTestModal && (
                <FormModal
                    type={"doctor__view__labtest"}
                />
            )}

            {modals.showDoctorWriteLabReportModal && (
                <FormModal
                    type={"doctor__write__labreport"}
                />
            )}

            {modals.showDoctorUploadOtherLabReportModal && (
                <FormModal
                    type={"doctor__upload__other__labreport"}
                />
            )}

            {modals.showDoctorAddRadiologyTestModal && (
                <FormModal
                    type={"doctor__add__radiologytest"}
                />
            )}

            {modals.showDoctorAddOtherRadiologyTestModal && (
                <FormModal
                    type={"doctor__add__otherradiologytest"}
                />
            )}

            {modals.showDoctorViewRadiologyTestModal && (
                <FormModal
                    type={"doctor__view__radiologytest"}
                />
            )}

            {modals.showDoctorWriteRadiologyReportModal && (
                <FormModal
                    type={"doctor__write__radiologyreport"}
                />
            )}

            {modals.showDoctorUploadOtherRadiologyReportModal && (
                <FormModal
                    type={"doctor__upload__other__radiologyreport"}
                />
            )}


            {modals.showDoctorRequestPatientAdmissionModal && (
                <FormModal
                    type={"doctor__request__patientadmission"}
                />
            )}

            {modals.showDoctorCancelRequestPatientAdmissionModal && (
                <FormModal
                    type={"doctor__cancel__patientadmission"}
                />
            )}

            {modals.showDoctorDischargePatientAdmissionModal && (
                <FormModal
                    type={"doctor__discharge__patient"}
                />
            )}

            {modals.showDoctorBookAppointmentModal && (
                <FormModal
                    type={"doctor__book__appointment"}
                />
            )}

            {modals.showDoctorUpdateAppointmentModal && (
                <FormModal
                    type={"doctor__update__appointment"}
                />
            )}

            {modals.updatePasswordModal && (
                <FormModal
                    type={"profile__update__password"}
                />
            )}


            {modals.showWebCamModal && (
                <FormModal
                    type={"profile__photo__webcam"}
                />
            )}

            {modals.showAddRadiologyEquipment && (
                <FormModal
                    type={"radiology_add_equipment"}
                />
            )}
            {modals.showUpdateRadiologyEquipment && (
                <FormModal
                    type={"radiology_update_equipment"}
                />
            )}
            {modals.showDeleteRadiologyEquipment && (
                <FormModal
                    type={"radiology_delete_equipment"}
                />
            )}
            {modals.showUploadReportModal && (
                <FormModal
                    type={"radiology_upload_report"}
                />
            )}
            {modals.showRadiologyViewReportModal && (
                <FormModal
                    type={"radiology_show_upload_report"}
                />
            )}
            {modals.showAddRadiologyInvestigationModal && (
                <FormModal
                    type={"radiology_add_investigation"}
                />
            )}
            {modals.showAddRadiologyInvestigationCategoryModal && (
                <FormModal
                    type={"radiology_add_investigation_category"}
                />
            )}
            {modals.showAddRadiologyInvestigationTypeModal && (
                <FormModal
                    type={"radiology_add_investigation_type"}
                />
            )}
            {modals.showUpdateRadiologyInvestigationModal && (
                <FormModal
                    type={"radiology_update_investigation"}
                />
            )}
            {modals.showViewRadiologyInvestigationModal && (
                <FormModal
                    type={"radiology_view_investigation"}
                />
            )}
            {modals.showViewRadiologyInvoice && (
                <FormModal
                    type={"radiology_view_invoice"}
                />
            )}
            {modals.showRadiologyGenerateInvoiceModal && (
                <FormModal
                    type={"radiology_generate_invoice"}
                />
            )}
            {modals.showDeleteRadiologyCategoryTestModal && (
                <FormModal
                    type={"radiology_delete_category_test"}
                />
            )}
            {modals.showUpdateRadiologyCategoryTestModal && (
                <FormModal
                    type={"radiology_update_category_test"}
                />
            )}
            {modals.showDeleteRadiologyInvestigationTypeModal && (
                <FormModal
                    type={"radiology_delete_investigation_type"}
                />
            )}
            {modals.showUpdateRadiologyInvestigationTypeModal && (
                <FormModal
                    type={"radiology_update_investigation_type"}
                />
            )}
            {modals.showAddLaboratoryEquipmentModal && (
                <FormModal
                    type={"laboratory_add_equipment"}
                />
            )}
            {modals.showUpdateLaboratoryEquipmentModal && (
                <FormModal
                    type={"laboratory_update_equipment"}
                />
            )}
            {modals.showDeleteLaboratoryEquipmentModal && (
                <FormModal
                    type={"laboratory_delete_equipment"}
                />
            )}
            {modals.showLaboratoryGenerateInvoiceModal && (
                <FormModal
                    type={"laboratory_generate_invoice"}
                />
            )}

            {modals.showLaboratoryUploadReportModal && (
                <FormModal
                    type={"laboratory_upload_report"}
                />
            )}
            {modals.showAddLaboratoryTextReportModal && (
                <FormModal
                    type={"laboratory_upload_report"}
                />
            )}
            {modals.showLaboratoryViewReportModal && (
                <FormModal
                    type={"laboratory_view_report"}
                />
            )}
            {modals.showViewLaboratoryInvoiceModal
                && (
                    <FormModal
                        type={"laboratory_view_invoice"}
                    />
                )}
            {modals.showLaboratoryAddCategoryModal
                && (
                    <FormModal
                        type={"laboratory_add_category_modal"}
                    />
                )}
            {modals.showLaboratoryUpdateCategoryModal
                && (
                    <FormModal
                        type={"laboratory_update_category_modal"}
                    />
                )}
            {modals.showLaboratoryDeleteCategoryModal
                && (
                    <FormModal
                        type={"laboratory_delete_category_modal"}
                    />
                )}
            {modals.showLaboratoryAddInvestigationType
                && (
                    <FormModal
                        type={"laboratory_add_investigation_type"}
                    />
                )}
            {modals.showLaboratoryUpdateInvestigationType
                && (
                    <FormModal
                        type={"laboratory_update_investigation_type"}
                    />
                )}
            {modals.showDeleteLaboratoryInvestigationTypeModal
                && (
                    <FormModal
                        type={"laboratory_delete_investigation_type"}
                    />
                )}
            {modals.showAddLaboratorySamplesModal
                && (
                    <FormModal
                        type={"laboratory_add_sample_type"}
                    />
                )}
            {modals.showUpdateLaboratorySamplesModal
                && (
                    <FormModal
                        type={"laboratory_update_sample_type"}
                    />
                )}
            {modals.showDeleteLaboratorySampleModal
                && (
                    <FormModal
                        type={"laboratory_delete_sample_type"}
                    />
                )}
            {modals.showNurseAddPatientModal
                && (
                    <FormModal
                        type={"nurse_add_patient"}
                    />
                )}
            {modals.showNurseAddPerformanceDataModal
                && (
                    <FormModal
                        type={"nurse_add_perfomace_data"}
                    />
                )}
            {modals.showNurseAdministerOPDModal
                && (
                    <FormModal
                        type={"nurse_administer_opd"}
                    />
                )}
            {modals.showAdmitPatientModal
                && (
                    <FormModal
                        type={"nurse_admit_patient"}
                    />
                )}
            {modals.showAddFamilyPlanningRecords
                && (
                    <FormModal
                        type={"nurse_add_family_planning_records"}
                    />
                )}
            {modals.showBookPatientForFamilyPlanningModal
                && (
                    <FormModal
                        type={"nurse_book_patient_for_family_planning"}
                    />
                )}
            {modals.showAddChildForImmunizationModal
                && (
                    <FormModal
                        type={"nurse_add_child_for_immunization"}
                    />
                )}
            {modals.showAddVaccinationRecordModal
                && (
                    <FormModal
                        type={"nurse_add_vaccination_record"}
                    />
                )}
            {modals.showBookAntenatalModal
                && (
                    <FormModal
                        type={"nurse_book_antenatal"}
                    />
                )}

            {modals.showAddPatientMedicalRecordModal

                && (
                    <FormModal
                        type={"nurse_add_patient_meedical_record"}
                    />
                )}
            {modals.showAddPatientPregnancyRecordModal

                && (
                    <FormModal
                        type={"nurse_add_patient_pregnancy_record"}
                    />
                )}
            {modals.showAddPatientVisitRecordModal

                && (
                    <FormModal
                        type={"nurse_add_patient_visit_record"}
                    />
                )}
            {modals.showAddPatientInvestigationRecordModal

                && (
                    <FormModal
                        type={"nurse_add_investigation_record"}
                    />
                )}
            {modals.showAddPatientLabourSummaryRecordModal

                && (
                    <FormModal
                        type={"nurse_add_labour_summary_record"}
                    />
                )}
            {modals.showDeleteLabourSummaryModal

                && (
                    <FormModal
                        type={"nurse_delete_labour_summary_record"}
                    />
                )}
            {modals.showDeleteInvestigationRecordModal

                && (
                    <FormModal
                        type={"nurse_delete_investigation__record"}
                    />
                )}
            {modals.showDeleteVisitRecordModal

                && (
                    <FormModal
                        type={"nurse_delete_visit__record"}
                    />
                )}
            {modals.showGenerateAntenatalCardModal

                && (
                    <FormModal
                        type={"nurse_generate_antenatal__card"}
                    />
                )}
            {modals.showViewVisitRecordModal

                && (
                    <FormModal
                        type={"nurse_view_visit_record_modal"}
                    />
                )}

            {modals.showAddAdmitedPatientDailyReportModal

                && (
                    <FormModal
                        type={"nurse_add_admitted_patient_daily_report_modal"}
                    />
                )}
            {modals.showAddAdmitedPatientTreatmentReportModal

                && (
                    <FormModal
                        type={"nurse_add_admitted_patient_treatment_report_modal"}
                    />
                )}
            {modals.showTemperatureChartModal

                && (
                    <FormModal
                        type={"nurse_show_temperature_chart_modal"}
                    />
                )}
            {modals.showPulseChartModal

                && (
                    <FormModal
                        type={"nurse_show_pulse_chart_modal"}
                    />
                )}
            {modals.showBloodPressureChartModal

                && (
                    <FormModal
                        type={"nurse_show_blood_pressure_chart_modal"}
                    />
                )}
            {modals.showRespiratoryChartModal

                && (
                    <FormModal
                        type={"nurse_show_respiratory_chart_modal"}
                    />
                )}
            {modals.showVitalScienceChartModal

                && (
                    <FormModal
                        type={"nurse_show_vital_chart_modal"}
                    />
                )}
            {modals.showNursesAddBirthRecordModal

                && (
                    <FormModal
                        type={"nurse_show_add_birth_record"}
                    />
                )}
            {modals.showNursesAddDeathRecordModal

                && (
                    <FormModal
                        type={"nurse_show_add_death_record"}
                    />
                )}
            {modals.showNurseViewBirthRecordModal

                && (
                    <FormModal
                        type={"nurse_show_view_birth_record"}
                    />
                )}
            {modals.showNurseViewDeathRecordModal

                && (
                    <FormModal
                        type={"nurse_show_view_death_record"}
                    />
                )}
            {modals.showViewPatientFollowUpRecordModal

                && (
                    <FormModal
                        type={"nurse_show_patient_view_follow_up_record"}
                    />
                )}
            {modals.showImmunizationCardModal

                && (
                    <FormModal
                        type={"nurse_show_immunization_card"}
                    />
                )}
            {modals.showPatientMedicalHistoryModal

                && (
                    <FormModal
                        type={"nurse_show_patient_medical_history"}
                    />
                )}
            {modals.showPatientPregnancyHistoryModal
                && (
                    <FormModal
                        type={"nurse_show_patient_pregnancy_history_modal"}
                    />
                )}
            {modals.showPatientLabourSummaryModal

                && (
                    <FormModal
                        type={"nurse_show_patient_labour_summary"}
                    />
                )}

            {modals.showPatientFamilyPlanningCardModal && (
                <FormModal
                    type={"nurse_show_patient_family_planing_card"}
                />
            )}

            {modals.showPatientViewLabInvoiceModal && (
                <FormModal
                    type={"patient__view__labinvoice"}
                />
            )}

            {modals.showPatientPayForInvoicesModal && (
                <FormModal
                    type={"patient__pay__invoice"}
                />
            )}

            {modals.showAccountsViewLabInvoiceModal && (
                <FormModal
                    type={"accounts__view__labinvoice"}
                />
            )}

            {modals.showCreateScheduleModal && (
                <FormModal
                    type={'create_scheduleModal'}
                />
            )
            }
            {modals.showEditAndDeleteScheduleModal && (
                <FormModal
                    type={'edit_delete_scheduleModal'}
                />
            )
            }
            {modals.showListOfSchedulesByDateModal && (
                <FormModal
                    type={'list_of_Schedules_by_date'}
                />
            )}
            {modals.showDeleteScheduleModal && (
                <FormModal
                    type={'delete_schedule_modal'}
                />
            )}
            {modals.showEditDeleteOptionalModal && (
                <FormModal
                    type={'edit_delete_optional_modal'}
                />
            )}

            {modals.showViewScheduleModal && (
                <FormModal
                    type={'view_schedule_modal'}
                />
            )}

            {modals.showAccountsViewPharmacyInvoiceModal && (
                <FormModal
                    type={"accounts__view__pharmacyinvoice"}
                />
            )}

            {modals.showAccountsViewAllReportsModal && (
                <FormModal
                    type={"accounts__view__allreports__modal"}
                />
            )}

            {modals.showAccountsViewLabReportsModal && (
                <FormModal
                    type={"accounts__view__labreports"}
                />
            )}

            {modals.showAccountsViewPharmacyReportsModal && (
                <FormModal
                    type={"accounts__view__pharmarcyreports"}
                />
            )}

            {modals.showAccountsViewRadiologyReportsModal && (
                <FormModal
                    type={"accounts__view__radiologyreports"}
                />
            )}

            {modals.showAccountsViewRecordsReportsModal && (
                <FormModal
                    type={"accounts__view__recordsreports"}
                />
            )}

            {modals.showAccountsViewDiscountInvoiceModal && (
                <FormModal
                    type={"accounts__view__discountinvoice__modal"}
                />
            )}

            {modals.showCollectionsLabPaymentModal && (
                <FormModal
                    type={"collections__laboratory__paymentmethod"}
                />
            )}

            {modals.showCollectionsPharmacyPaymentModal && (
                <FormModal
                    type={"collections__pharmacy__paymentmethod"}
                />
            )}

            {modals.showCollectionRadiologyPaymentModal && (
                <FormModal
                    type={"collections__radiology__paymentmethod"}
                />
            )}

            {modals.showCollectionRecordsPaymentModal && (
                <FormModal
                    type={"collections__records__paymentmethod"}
                />
            )}

            {modals.showCollectionsViewLabInvoiceModal && (
                <FormModal
                    type={"collections__view__labinvoice"}
                />
            )}

            {modals.showCollectionsViewPharmacyInvoiceModal && (
                <FormModal
                    type={"collections__view__pharmacyinvoice"}
                />
            )}

            {modals.showCollectionsViewRadiologyInvoiceModal && (
                <FormModal
                    type={"collections__view__radiologyinvoice"}
                />
            )}

            {modals.showCollectionsViewRecordsInvoiceModal && (
                <FormModal
                    type={"collections__view__recordsinvoice"}
                />
            )}

            {modals.showCollectionsMultiplePaymentModal && (
                <FormModal
                    type={"collections__pay__multiple_invoice"}
                />
            )}

            {modals.showCollectionsMultipleInvoiceTypePaymentModal && (
                <FormModal
                    type={"collections__pay__multiple__invoice__type__modal"}
                />
            )}

            {modals.showCollectionPrintInvoiceModal && (
                <FormModal
                    type={"collections__print__invoice"}
                />
            )}

            {modals.showCollectionPrintMultipleReceiptModal && (
                <FormModal
                    type={"collections__print__multiple__invoices"}
                />
            )}

            {modals.showCollectionPrintMultipleReceiptTypesModal && (
                <FormModal
                    type={"collections__print__multiple__types__invoices__modal"}
                />
            )}

            {modals.showPatientMultiplePaymentModal && (
                <FormModal
                    type={"patient__paymultiple__invoice__modal"}
                />
            )}


            {modals.showChatCreateGroupModal && (
                <FormModal
                    type={"chat__creategroup"}
                />
            )}

            {modals.showAddGroupParticipantModal && (
                <FormModal
                    type={"chat__addgroup_participant"}
                />
            )}
            {modals.showIncomingCallModal && (
                <FormModal
                    activeLink={activeLink}
                    setActiveLink={setActiveLink}
                    type={"show_incoming_call"}
                />
            )}

            {modals.showCreateRoomModal && (
                <FormModal
                    type={"create_room_modal"}
                />
            )}
            {modals.showCallNotification && (
                <FormModal

                    type={"show_call_notifications"}
                />
            )}


        </Fragment>
    )
}




export default Modals;

