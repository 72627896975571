import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useStateContext } from '../../../state/context'
import { getAPatientPregnancyRecord } from '../../../state/actions/nurse.actions'
import { BsEye } from 'react-icons/bs'
import { setFollowUpRecords } from '../../../state/actions/actions'


const PatientPregnancyRecordsTable = (props) => {
    const { opdState, setOpdState } = useStateContext()
    const { modals, setModals } = useStateContext()
    const { set_patient_id } = useSelector((state) => state.root)
    const { all_patient_pregnancy_record } = useSelector((state) => state.nurse)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAPatientPregnancyRecord({ id: set_patient_id?.id }))
    }, [modals.showAddPatientPregnancyRecordModal])

    return (
        <div className='flex flex-col'>
            <div className='flex  justify-end space-x-2'>
                <button
                    type="button"
                    className=" px-6 py-2.5 bg-sky-600 text-white w-[100px] mb-2 font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                    onClick={() => (
                        setModals({
                            ...opdState,
                            showAddPatientPregnancyRecordModal: !modals.showAddPatientPregnancyRecordModal

                        })
                    )}
                >
                    Add +
                </button>
                <button
                    type="button"
                    className=" px-6 py-2.5 bg-dark-green text-white w-[100px] mb-2 font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease"
                    onClick={() => (
                        setOpdState({
                            ...opdState,
                            showPatientAntenatalRecords: false

                        })
                    )}
                >
                    Back
                </button>

            </div>
            <div>

                <div className='flex justify-start items-center space-x-4 bg-gray-200 border-2 border-gray-200 rounded-t-lg p-1'>

                    <h4 className='text-dark-blue text-[16px] font-semibold'>
                        Pregnancy History - {set_patient_id?.first_name} {set_patient_id?.last_name} [{set_patient_id?.patient_number}]
                    </h4>
                </div>


                <table className='border rounded w-full cursor-default'>

                    <thead className=''>
                        <tr>
                            <th
                                scope='col'
                                className='text-[10px] font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                            >
                                S/N
                            </th>

                            <th
                                scope='col'
                                className='text-[10px] font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                            >
                                Birth Date
                            </th>

                            <th
                                scope='col'
                                className='text-[10px] font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                            >
                                Duration
                            </th>
                            <th
                                scope='col'
                                className='text-[10px] font-medium text-gray-900 pr-[5em] py-4 text-left'
                            >
                                Complications
                            </th>
                            <th
                                scope='col'
                                className='text-[10px] font-medium text-gray-900 pr-[5em] py-4 text-left'
                            >
                                Labour
                            </th>
                            <th
                                scope='col'
                                className='text-[10px] font-medium text-gray-900 pr-[5em] py-4 text-left'
                            >
                                Alive or S.B
                            </th>
                            <th
                                scope='col'
                                className='text-[10px] font-medium text-gray-900 pr-[5em] py-4 text-left'
                            >
                                Age at death
                            </th>
                            <th
                                scope='col'
                                className='text-[10px] font-medium text-gray-900 pr-[5em] py-4 text-left'
                            >
                                Birth weight
                            </th>


                            <th
                                scope='col'
                                className='text-[10px] font-medium text-gray-900 py-4 text-left'
                            >
                                Baby NDD
                            </th>
                            <th
                                scope='col'
                                className='text-[10px] font-medium text-gray-900 py-4 text-left'
                            >
                                Action
                            </th>
                        </tr>

                    </thead>


                    <tbody>
                        {all_patient_pregnancy_record?.map((item, index) => (
                            <tr key={index}>
                                <td
                                    scope='col'
                                    className='text-sm font-light text-gray-900 pr-[4em] px-4 py-4 text-left'
                                >
                                    {index + 1}
                                </td>

                                <td
                                    scope='col'
                                    className='text-sm font-light text-gray-900 pr-[4em] px-4 py-4 text-left'
                                >
                                    {item.birth_date}
                                </td>
                                <td
                                    scope='col'
                                    className='text-sm font-light text-gray-900 pr-[5em] py-4 text-left'
                                >
                                    {item.duration}
                                </td>


                                <td
                                    scope='col'
                                    className='text-sm font-light text-gray-900 py-4 text-left'
                                >
                                    {item.antenatal_complications}
                                </td>
                                <td
                                    scope='col'
                                    className='text-sm font-light text-gray-900 py-4 text-left'
                                >
                                    {item.labor}
                                </td>
                                <td
                                    scope='col'
                                    className='text-sm font-light text-gray-900 py-4 text-left'
                                >
                                    {item.baby_dead_alive}
                                </td>
                                <td
                                    scope='col'
                                    className='text-sm font-light text-gray-900 py-4 text-left'
                                >
                                    {item.age_of_death}
                                </td>
                                <td
                                    scope='col'
                                    className='text-sm font-light text-gray-900 py-4 text-left'
                                >
                                    {item.birth_weight}
                                </td>
                                <td
                                    scope='col'
                                    className='text-sm font-light text-gray-900 py-4 text-left'
                                >
                                    {item.baby_ndd}
                                </td>
                                <td className=' text-sm text-gray-900 font-light py-2 whitespace-nowrap'>
                                    <div className='flex space-x-4 relative '>
                                        <BsEye
                                            color='blue'
                                            className='hover:cursor-pointer ml-4'

                                            size={35}
                                            onClick={() => {
                                                const data = {
                                                    birth_date: item?.birth_date,
                                                    duration: item?.duration,
                                                    antenatal_complications: item?.antenatal_complications,
                                                    labor: item?.labor,
                                                    baby_dead_alive: item?.baby_dead_alive,
                                                    age_of_death: item?.age_of_death,
                                                    birth_weight: item?.birth_weight,
                                                    baby_ndd: item?.baby_ndd
                                                }
                                                dispatch(setFollowUpRecords({ data }))
                                                setModals({ ...modals, showPatientPregnancyHistoryModal: !modals.showPatientPregnancyHistoryModal })

                                            }}


                                        />

                                    </div>
                                </td>

                            </tr>
                        ))}
                    </tbody>

                </table>
            </div>
        </div>
    )
}

export default PatientPregnancyRecordsTable