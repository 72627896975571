import React from 'react'
import { useStateContext } from '../../../state/context'
import { useSelector } from 'react-redux'
import { FaTimesCircle } from 'react-icons/fa'

const NursePatientPregnancyHistoryModal = () => {
    const { modals, setModals } = useStateContext()
    const { set_follow_up_record } = useSelector((state) => state.root)

    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>

                            <>
                                <h4 className='text-dark-green text-[14px] font-semibold'>
                                    Pregnancy Record History
                                </h4>
                                <FaTimesCircle
                                    className='text-dark-yellow cursor-pointer'
                                    onClick={() => (
                                        setModals({
                                            ...modals,
                                            showPatientPregnancyHistoryModal:
                                                !modals.showPatientPregnancyHistoryModal,
                                        })
                                    )

                                    }
                                    size={20}
                                />
                            </>
                        </div>

                        <form

                        >
                            <div className='flex justify-between-items-center space-x-10'>
                                <div className='space-y-2 w-full'>
                                    <div className='flex space-x-4'>

                                        <div>
                                            <p className='text-sm font-semibold'>Date of birth</p>
                                            <input
                                                type="date"
                                                name="birth_date"
                                                value={set_follow_up_record?.birth_date}
                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                // onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Alive or S.B</p>
                                            <input
                                                type="text"
                                                name="baby_dead_alive"
                                                value={set_follow_up_record?.baby_dead_alive}
                                                className="border rounded w-[250px] h-5   px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                // onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                    </div>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Duration</p>
                                            <input
                                                type="title"
                                                name="duration"
                                                value={set_follow_up_record?.duration}
                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                // onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Age at death</p>
                                            <input
                                                type="title"
                                                name="age_of_death"
                                                value={set_follow_up_record?.age_of_death}
                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                // onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                    </div>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Antenatal complications</p>
                                            <input
                                                type="title"
                                                name="antenatal_complications"
                                                value={set_follow_up_record?.antenatal_complications}
                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                // onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Birth weight</p>
                                            <input
                                                type="title"
                                                name="birth_weight"
                                                value={set_follow_up_record?.birth_weight}
                                                disabled
                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                // onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                    </div>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Labour</p>
                                            <input
                                                type="text"
                                                value={set_follow_up_record?.labor}
                                                disabled
                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                // onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Baby NDD/D</p>
                                            <input
                                                type="text"
                                                name="baby_ndd"
                                                value={set_follow_up_record?.baby_ndd}
                                                disabled
                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"
                                                // onChange={(e) => handleChange(e, e.target.name)}
                                            />
                                        </div>

                                    </div>






                                </div>


                            </div>
                        </form>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default NursePatientPregnancyHistoryModal