import React from 'react'
import { useSelector } from 'react-redux'
import { useStateContext } from '../../../state/context'
import { FaTimesCircle } from 'react-icons/fa'

const NurseViewPatientMedicalHistoryModal = () => {
    const { set_follow_up_record } = useSelector((state) => state.root)
    const { modals, setModals } = useStateContext()
    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[600px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>

                            <>
                                <h4 className='text-dark-green text-[16px] font-semibold'>
                                    Medical record  history
                                </h4>
                                <FaTimesCircle
                                    className='text-dark-yellow cursor-pointer'
                                    onClick={() => (
                                        setModals({
                                            ...modals,
                                            showPatientMedicalHistoryModal:
                                                !modals.showPatientMedicalHistoryModal,
                                        })
                                    )

                                    }
                                    size={20}
                                />
                            </>


                        </div>




                        <form

                        >


                            <div className='flex justify-between-items-center space-x-10'>
                                <div className='space-y-2 w-full'>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Heart Diease</p>
                                            <input
                                                type="text"
                                                name="next_appointment_date"

                                                value={set_follow_up_record?.heart_disease}
                                                disabled
                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"

                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Hypertension</p>
                                            <input
                                                type="text"
                                                name="next_appointment_date"

                                                value={set_follow_up_record?.hypertension}
                                                disabled
                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"

                                            />
                                        </div>

                                    </div>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Kidney Diease</p>
                                            <input
                                                type="text"
                                                name="next_appointment_date"

                                                value={set_follow_up_record?.kidney_disease}
                                                disabled
                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"

                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Tuberculosis</p>
                                            <input
                                                type="text"
                                                name="next_appointment_date"

                                                value={set_follow_up_record?.tuberculosis}
                                                disabled
                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"

                                            />
                                        </div>

                                    </div>
                                    <div className='flex space-x-4'>
                                        <div>
                                            <p className='text-sm font-semibold'>Liver Disease</p>
                                            <input
                                                type="text"
                                                name="next_appointment_date"

                                                value={set_follow_up_record?.liver_disease}
                                                disabled
                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"

                                            />
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>Blood Transaction</p>
                                            <input
                                                type="text"
                                                name="next_appointment_date"

                                                value={set_follow_up_record?.blood_transaction}
                                                disabled
                                                className="border rounded w-[250px] h-5 px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded"

                                            />
                                        </div>

                                    </div>
                                    <div className=''>
                                        <p className='text-sm font-semibold'>Others</p>
                                        <textarea type='text'
                                            name='others'
                                            value={set_follow_up_record?.others}
                                            disabled
                                            className='border rounded w-full text-sm h-[80px] px-4 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-blue-500 focus:ring-blue 5ounded'
                                            id="" cols="30" rows="20">
                                        </textarea>``
                                    </div>
                                </div>
                            </div>

                        </form>

                    </div>
                </div>

            </div>
        </div>
    )
}
export default NurseViewPatientMedicalHistoryModal