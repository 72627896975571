import React, { useRef, useState, useEffect, Fragment } from "react"
import { useDispatch, useSelector, } from "react-redux"

import { Logo } from '../../../assets'
import { useStateContext } from "../../../state/context"
import { setCurrentPharmacyInvoice, setAccumulatedPharmacyInvoice } from "../../../state/actions/collection.actions"
import { useReactToPrint } from 'react-to-print'


const CollectionsPrintMultipleInvoiceTypesModal = (props) => {
    const dispatch = useDispatch()

    const { modals, setModals } = useStateContext()

    const { hospital_config } = useSelector(state => state.admin)
    const { all_current_invoices } = useSelector(state => state.collection)

    const cardRef = useRef()
    const [total, setTotal] = useState(0)


    useEffect(() => {
        let _total = 0, sum = 0
        all_current_invoices?.forEach(invoice => {
            if (invoice?.invoice_type === 'Prescription') {
                _total = _total + parseInt(invoice.invoice_amount)
            } else {
                sum = sum + parseInt(invoice.amount)
            }
        })
        // if (all_current_invoices?.invoice_type === 'radiology') {
        //     all_current_invoices?.invoices?.forEach((e, index) => {
        //         _total = _total + parseInt(e.amount)
        //     })
        // }
        // if (all_current_invoices?.invoice_type === 'records') {
        //     all_current_invoices?.invoices?.forEach((e, index) => {
        //         _total = _total + parseInt(e.amount)
        //     })
        // }

        setTotal(_total + sum)

    }, [all_current_invoices])

    const handlePrint = useReactToPrint({
        content: () => cardRef.current,
    })

    return (
        <div className="fixed grid h-screen w-full z-10 bg-[#11111180] place-items-center">
            <div
                className="absolute right-[4em] top-0 text-[60px] font-medium text-white cursor-pointer"
                onClick={() => {
                    dispatch(setAccumulatedPharmacyInvoice({ data: null }))
                    setModals({ ...modals, showCollectionPrintMultipleReceiptTypesModal: !modals.showCollectionPrintMultipleReceiptTypesModal })
                }}
            >
                x
            </div>

            <div className="bg-white w-[400px] rounded-t"
                ref={cardRef}

            >
                <div className="h-[5px] bg-indigo-800 rounded-t"></div>
                <div className="flex justify-between space-y-5 px-[10px]">
                    <div className="flex flex-col items-center space-y-2">
                        <img
                            src={hospital_config ? `${process.env.REACT_APP_API_ROOT}${hospital_config?.logo}` : Logo}
                            alt="logo"
                            className="w-[50px]"
                        />
                        <p className="text-[20px] text-gray-800 font-bold font-montserrat">{hospital_config?.name}</p>
                    </div>

                    <div>
                        <p className="text-[12px] text-gray-600 font-medium">
                            {hospital_config?.address}
                        </p>
                        <p className="text-[12px] text-gray-600 font-medium">
                            {hospital_config?.email}
                        </p>
                        <p className="text-[12px] text-gray-600 font-medium">
                            {hospital_config?.phone}
                        </p>
                    </div>
                </div>

                <div className="space-y-4 px-[10px] mt-5">
                    <div className="flex justify-start space-x-4">
                        <p className="text-[12px] font-medium text-gray-500">
                            <span className="text-gray-800">Subject:</span>  Receipt
                        </p>
                        <p className="text-[12px] font-medium text-gray-500">
                            <span className="text-gray-800">Date:</span> {new Date().toISOString().slice(0, 10)}
                        </p>

                    </div>

                    <div className="max-h-[400px] overflow-y-auto patient__prescriptions">
                        <table className="rounded w-full cursor-default">
                            <thead className="">
                                <tr className='bg-yellow-100'>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-2 text-left"
                                    >
                                        Item
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-2 text-left"
                                    >
                                        Quantity
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-2 text-left"
                                    >
                                        Invoice No
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 pr-[1em] py-2 text-left"
                                    >
                                        Amount
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {all_current_invoices?.map((item, index) => (
                                    <Fragment key={`${item.id}`}>
                                        {item?.invoice_type === 'Laboratory' && (
                                            <tr>
                                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                    {item?.billing_type}
                                                </td>
                                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                    {`01`}
                                                </td>
                                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap bg-yellow-200">
                                                    {item?.invoice_number}
                                                </td>
                                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap bg-yellow-200">
                                                    {item?.amount}
                                                </td>
                                            </tr>
                                        )}
                                        {item?.invoice_type === 'Prescription' && (
                                            <tr>
                                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                    {item?.prescription_details?.drugs}
                                                </td>
                                                <td className="border-t border-b text-[12px] text-gray-900 font-light pl-5 py-2 whitespace-nowrap">
                                                    {item?.prescription_details?.drug_quantity}
                                                </td>
                                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap bg-yellow-200">
                                                    {item?.invoice_number}
                                                </td>
                                                <td className="border-t border-b text-[12px] text-gray-900 font-light pl-2 py-2 whitespace-nowrap bg-yellow-200">
                                                    {item?.invoice_amount}
                                                </td>
                                            </tr>
                                        )}
                                        {item?.invoice_type === 'Radiology' && (
                                            <tr>
                                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                    {item?.billing_type}
                                                </td>
                                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                    {`01`}
                                                </td>
                                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap bg-yellow-200">
                                                    {item?.invoice_number}
                                                </td>
                                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap bg-yellow-200">
                                                    {item?.amount}
                                                </td>
                                            </tr>
                                        )}
                                        {item?.invoice_type === 'Records' && (
                                            <tr>
                                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                    {item?.billing_type}
                                                </td>
                                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                                    {`01`}
                                                </td>
                                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap bg-yellow-200">
                                                    {item?.invoice_number}
                                                </td>
                                                <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap bg-yellow-200">
                                                    {item?.amount}
                                                </td>
                                            </tr>
                                        )}
                                    </Fragment>
                                ))}

                                <tr>
                                    <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                    </td>
                                    <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                    </td>
                                    <td className="border-t border-b text-[12px] text-gray-900 font-bold py-2 whitespace-nowrap bg-yellow-200">
                                        Tax: &#8358;
                                    </td>
                                    <td className="border-t border-b text-[12px] text-gray-900 font-medium underline underline-offset-1 pl-2 py-2 whitespace-nowrap bg-yellow-200">
                                        {/* {pharmacy_invoice?.invoice_amount} */}0.00
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                    </td>
                                    <td className="border-t border-b text-[12px] text-gray-900 font-light py-2 whitespace-nowrap">
                                    </td>
                                    <td className="border-t border-b text-[14px] text-white font-bold py-2 whitespace-nowrap bg-teal-900">
                                        Total:
                                    </td>
                                    <td className="border-t border-b text-[14px] text-white font-bold underline underline-offset-1 py-2 whitespace-nowrap bg-teal-900">
                                        ₦  {total}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {/* <div className="flex flex-col place-content-end w-1/2 italic text-[10px]">
                        <p className="underline font-bold">Terms And Conditions.</p>
                        <p>All payments finalized have no return policy.</p>
                    </div> */}

                    <div className="flex flex-col place-content-end w-1/2 italic text-[10px]">
                        <p className="underline font-bold">Thanks, for your patronage.</p>

                    </div>
                </div>

            </div>
            <button
                type='button'
                className='inline-block px-6 py-2.5 bg-dark-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-green active:shadow-lg transition duration-500 ease'
                onClick={handlePrint}
            >
                Print
            </button>
        </div >
    )

}

export default CollectionsPrintMultipleInvoiceTypesModal
