import React from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useStateContext } from '../../../state/context'
import { useSelector } from 'react-redux'

const NurseViewFamilyPlanningCardModal = () => {
    const { modals, setModals } = useStateContext()
    const { set_patient_id } = useSelector((state) => state.root)


    return (
        <div>
            <div className='absolute grid h-screen w-full z-20 bg-[#11111180] place-items-center'>
                <div className='bg-white rounded px-[30px] py-[20px] w-[1200px]'>
                    <div className='flex flex-col space-y-5'>
                        <div className='flex justify-between items-center'>

                            <>
                                <h4 className='text-dark-green text-[16px] font-light'>
                                    Family Planning Card
                                </h4>
                                <FaTimesCircle
                                    className='text-dark-yellow cursor-pointer'
                                    onClick={() => (
                                        setModals({ ...modals, showPatientFamilyPlanningCardModal: !modals.showPatientFamilyPlanningCardModal })
                                    )}
                                    size={20}
                                />
                            </>


                        </div>
                        <div className=' flex space-x-4 width-full bg-slate-300 h-[50px] pl-2 pt-2 rounded-md'>
                            <img src={`${process.env.REACT_APP_API_ROOT}${set_patient_id?.photo}`} alt="" srcset="" className='h-[30px] w-[30px] mt-1 mb-2' />
                            <div className='flex flex-col'>
                                <p className='text-[10px] font-semibold text-blue-500 '>{set_patient_id?.first_name} {set_patient_id?.last_name} [{set_patient_id?.patient_number}]</p>
                                <p>Family Planning Card</p>
                            </div>

                        </div>



                        <form >
                            <div className=''>
                                <div className='flex justify-between-items-center space-x-4'>
                                    <div className='w-full'>
                                        <table className='border border-stone-800  rounded w-full cursor-default'>
                                            <thead className=''>
                                                <tr>
                                                    <th
                                                        scope='col'
                                                        className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                                                    >
                                                        Date of Next Visit
                                                    </th>

                                                    <th
                                                        scope='col'
                                                        className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                                                    >

                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr className='relative h-[400px] border'>

                                                </tr>
                                            </tbody>

                                        </table>

                                    </div>
                                    <div className='w-full'>
                                        <table className='border border-stone-800  rounded w-full cursor-default'>
                                            <thead className=''>
                                                <tr>
                                                    <th
                                                        scope='col'
                                                        className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                                                    >
                                                        Date of Next Visit
                                                    </th>

                                                    <th
                                                        scope='col'
                                                        className='text-sm font-medium text-gray-900 pr-[4em] px-4 py-4 text-left'
                                                    >

                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr
                                                    className='relative h-[400px] border border-stone-800 border-solid'
                                                >

                                                </tr>


                                            </tbody>

                                        </table>


                                    </div>
                                </div>
                            </div>


                        </form>

                    </div>
                    <div className='mt-4 flex justify-center items-center'>
                        <button
                            type='submit'
                            className='inline-block w-[175px] px-6 py-2.5 bg-blue-400 text-white font-medium text-xs leading-tight rounded shadow-md hover:scale-90 transition duration-500 ease'
                            onClick={() => {
                                // print()
                            }}
                        >
                            Print
                        </button>
                    </div>
                </div>

            </div>
        </div>
    )
}


export default NurseViewFamilyPlanningCardModal